import axios from "axios";

import { config } from "../config/envConfig";
import { refreshAccess } from "../utils/cookies";

import { ITransaction } from "src/models/ITransaction";
import { RequestError, isRequestError } from "src/models/RequestError";
import { DEFAULT_TIMEOUT } from "../utils/AxiosUtil";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import {
  ACCOUNT_PAYMENT_SUBSCRIPTION_INVALID_PROMO_CODE,
  ACCOUNT_PAYMENT_SUBSCRIPTION_PROMO_CODE,
  SUBSCRIPTION_PAYMENT_UPGRADE_SCREEN_PAYMENT_FAIL,
  SUBSCRIPTION_PAYMENT_UPGRADE_SCREEN_PAYMENT_SUCCESS,
} from "src/utils/analytics";
import { getPaymentAmountWrapper } from "../utils/payment";

export interface TransactionDetails {
  subtotal: number;
  promoPercentageOff: number;
  promoDiscount: number;
  tax: number;
  total: number;
  currency?: string;
}
export interface PurchaseRequest {
  userId: number;
  product: string;
  campaignCode?: string;
  paymentId?: string;
  sourceType?: string;
}
export interface CommitRequestWrapper {
  paymentDetails: TransactionDetails;
  request: PurchaseRequest;
  sourceType?: string;
}
const { purchaseUrl } = config.environment;

const purchaseInstance = axios.create({
  baseURL: purchaseUrl,
  timeout: DEFAULT_TIMEOUT,
  validateStatus(status) {
    return status >= 200 && status < 299;
  },
});

export const calculateTax = async (
  request: PurchaseRequest
): Promise<TransactionDetails | RequestError> => {
  purchaseInstance.defaults.validateStatus = (status) =>
    status >= 200 && status < 500;
  const cookie = await refreshAccess();
  const response = await purchaseInstance.post(
    `/v1/purchase/calculate`,
    request,
    {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    }
  );
  if (
    response.status >= 200 &&
    response.status < 299 &&
    !isRequestError(response.data)
  ) {
    if (request.campaignCode) {
      sendAmplitudeEvent(ACCOUNT_PAYMENT_SUBSCRIPTION_PROMO_CODE, {
        "Promo Code": request.campaignCode,
        sku: request.product,
        Source: request.sourceType,
        "Subscription Amount": response.data.subtotal,
        Currency: response.data.currency,
      });
    } else if (response.status === 457) {
      sendAmplitudeEvent(ACCOUNT_PAYMENT_SUBSCRIPTION_INVALID_PROMO_CODE, {
        "Promo Code": request.campaignCode,
        sku: request.product,
        Source: request.sourceType,
      });
    }
  }
  return response.data;
};
export const commitPayment = async (
  requestWrapper: CommitRequestWrapper
): Promise<TransactionDetails | RequestError> => {
  const cookie = await refreshAccess();
  purchaseInstance.defaults.timeout = 50000;

  const response = await purchaseInstance.post(
    `/v1/purchase/commit`,
    requestWrapper.request,
    {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    }
  );
  if (
    response.status >= 200 &&
    response.status < 299 &&
    !isRequestError(response.data)
  ) {
    const purchased: TransactionDetails = response.data;
    sendAmplitudeEvent(SUBSCRIPTION_PAYMENT_UPGRADE_SCREEN_PAYMENT_SUCCESS, {
      "Subscription Amount": purchased.subtotal,
      "Paid Total": purchased.total,
      sku: requestWrapper.request.product,
      Source: requestWrapper.request.sourceType,
      "Discount Amount": purchased.promoDiscount,
      "Discount Code": requestWrapper.request.campaignCode ?? "",
      Type: "New Purchase",
      Currency: purchased.currency,
    });
  } else {
    sendAmplitudeEvent(SUBSCRIPTION_PAYMENT_UPGRADE_SCREEN_PAYMENT_FAIL, {
      "Promo Code": requestWrapper.request.campaignCode,
      sku: requestWrapper.request.product,
      ...getPaymentAmountWrapper(requestWrapper.paymentDetails),
      Source: requestWrapper.request.sourceType,
      HttpStatus: response.status,
      "Upgrade Request": requestWrapper.request,
      "Error Message": response.data?.error.message,
      "Subscription Amount": requestWrapper.paymentDetails.subtotal,
      Type: "New Purchase",
    });
  }
  return response.data;
};

export const getTransactionHistory = async (
  userId: number
): Promise<ITransaction[]> => {
  const accessToken = await refreshAccess();
  const response = await purchaseInstance.get(
    `/v1/transactions/${userId}?_t=${+new Date()}`,
    {
      headers: {
        Authorization: `${accessToken}`,
        accept: "application/json",
      },
    }
  );
  return response.data;
};
