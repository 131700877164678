import { makeStyles } from "@material-ui/core";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { colors } from "../../config/colorConfig";

interface OverlaySpinnerProps {
  customStyle?: React.CSSProperties;
}

export const OverlaySpinner = (props: OverlaySpinnerProps) => {
  const styles = makeStyles((theme) => ({
    noScroll: {
      overflow: "hidden",
    },
  }));
  const classes = styles();
  React.useEffect(() => {
    document.body.classList.add(classes.noScroll);
    return () => {
      document.body.classList.remove(classes.noScroll);
    };
  }, []);
  return (
    <div
      data-testid="spinner"
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 9999999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: colors["--text-invert"],
        ...props.customStyle,
      }}
    >
      <ClipLoader
        data-testid="progressValidationSpinner"
        size="45px"
        color={colors["--accent-violet"]}
        loading
      />
    </div>
  );
};
