import React from "react";
import { Typography, Dialog, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import closeImg from "../images/close.png";
import { colors } from "../config/colorConfig";

interface SubscriptionDialogProps {
  open: boolean;
  title: string;
  isTitleLarge?: boolean;
  setClose: () => void;
  children: React.ReactElement;
  width?: number;
  fontSize?: number;
  handleCloseEvent?: () => void;
  handlesOwnTitle?: boolean;
  titleWithImage?: React.ReactElement;
  removeTabIndex?: boolean;
  isSpinnerActive?: boolean;
  minWidth?: number;
}

export const SubscriptionDialog = (
  props: SubscriptionDialogProps
): JSX.Element => {
  const dialogBoxStyles = makeStyles((theme) => ({
    popUp: {
      width: props.width,
      maxWidth: "100%",
      display: "flex !important",
      alignItems: "center",
      margin: "auto",
    },
    overrideBoxShadow: {
      "&:focus": {
        boxShadow: "none",
      },
    },
    popUpTitle: {
      margin: `${theme.spacing(6)}px ${theme.spacing(6)}px ${theme.spacing(
        3
      )}px ${theme.spacing(6)}px`,
      fontWeight: 500,
      fontSize: props.isTitleLarge ? 20 : 16,
      color: `${colors["--ui-slate"]}`,
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
    },
    largePopupTitleWrap: {
      paddingBottom: theme.spacing(1),
      border: `1px solid ${colors["--ui-grey-light"]}`,
      marginBottom: theme.spacing(4),
      width: props.minWidth,
    },
    popupPaper: {
      borderRadius: 3,
      boxShadow: "none",
      margin: theme.spacing(4),
      maxWidth: "none",
      overflow: props.isSpinnerActive ? "hidden" : "auto",
    },
    closeX: {
      margin: `${theme.spacing(6)}px`,
      lineHeight: "24px",
      position: "absolute",
      cursor: "pointer",
      fontSize: "24px",
      right: 0,
    },
    closeIconX: {
      height: "20px",
      width: "20px",
    },
    wrapperDiv: {
      margin: `0px ${theme.spacing(6)}px`,
    },

    popUpSubTitle: {
      margin: `${theme.spacing(6)}px ${theme.spacing(6)}px ${theme.spacing(
        3
      )}px ${theme.spacing(6)}px`,
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
      color: `${colors["--primary-midnight"]}`,
    },
    spanItems: {
      display: "flex",
      alignItems: "center",
      marginRight: "16px",
    },
    buttonFields: {
      width: "27%",
    },
    cancelLink: {
      cursor: "pointer",
      fontWeight: 500,
      marginLeft: 55,
      fontSize: 16,
      lineHeight: "20px",
      color: `${colors["--primary-teal"]} !important`,
    },
    spinnerPaper: {
      backgroundColor: "transparent",
      boxShadow: "none",
      overflow: "hidden",
    },
    spinnerBox: {
      paddingBottom: `${theme.spacing(6)}px`,
      alignSelf: "center",
    },
  }));
  const handleCancelKeyPress = () => props.setClose();
  const dialogRef = React.useRef<HTMLElement>();
  const classes = dialogBoxStyles();
  const handleClose = () => {
    props.setClose();
    if (props.handleCloseEvent) {
      props.handleCloseEvent();
    }
  };
  React.useEffect(() => {
    if (props.removeTabIndex && props.open) {
      setTimeout(() => {
        if (dialogRef?.current) {
          dialogRef.current
            .querySelector(".subscription-dialog-container")
            ?.removeAttribute("tabindex");
        }
      });
    }
  });
  return (
    <div>
      <Dialog
        classes={{
          root: clsx(classes.popUp, classes.overrideBoxShadow),
          container: clsx(
            classes.overrideBoxShadow,
            "subscription-dialog-container"
          ),
          paper: clsx(classes.popupPaper),
        }}
        ref={dialogRef}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!props.handlesOwnTitle && (
          <>
            <div
              className={clsx(classes.closeX)}
              onClick={handleClose}
              onKeyDown={handleCancelKeyPress}
              role="button"
              tabIndex={-1}
            >
              <img
                className={clsx(classes.closeIconX)}
                src={closeImg}
                data-testid="closeIcon"
                srcSet={`${closeImg} 2x,${closeImg} 3x`}
                alt=""
              />
            </div>
            {!props.titleWithImage ? (
              <div
                className={
                  props.isTitleLarge ? clsx(classes.largePopupTitleWrap) : ""
                }
              >
                <Typography
                  id="alert-dialog-titleaa"
                  classes={{ root: clsx(classes.popUpTitle) }}
                  data-testid="alert-dialog-titleaa"
                >
                  {props.title}
                </Typography>
              </div>
            ) : (
              props.titleWithImage
            )}
          </>
        )}
        <div>{props.children}</div>
      </Dialog>
    </div>
  );
};
SubscriptionDialog.defaultProps = {
  width: 800,
};
