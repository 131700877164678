import { TransactionDetails } from "src/services/purchaseService";
import { PaymentDetails } from "../models/IPaymentMethodResponse";

export const getAmountWithoutTax = (paymentDetails: PaymentDetails) =>
  paymentDetails.subtotal -
  (paymentDetails.promoPercentage &&
  paymentDetails.promoSavings &&
  paymentDetails.promoPercentage > 0
    ? paymentDetails.promoSavings
    : 0);
// This need to delete once refactor the code using getPaymentAmountWrapper
export const getEventAmountValues = (paymentDetails: PaymentDetails) => ({
  "Amount Before Tax": getAmountWithoutTax(paymentDetails),
  Tax: paymentDetails.tax,
  "Promo discount": paymentDetails.promoSavings ?? 0,
  "Total Amount": paymentDetails.total,
});
export const getPaymentAmountWrapper = (
  paymentDetails: TransactionDetails
) => ({
  "Amount Before Tax": getAmountWithoutTax(paymentDetails),
  Tax: paymentDetails.tax,
  "Promo discount": paymentDetails.promoDiscount ?? 0,
  "Total Amount": paymentDetails.total,
});
export const formatToDollar = (amount: number | undefined): string => {
  if (amount === undefined) {
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};
