import { Dispatch, MouseEvent, ReactElement, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "src/config/colorConfig";
import { TextField } from "src/components/custom/TextField";
import { strings } from "src/utils/Strings";
import { Button } from "./custom/Button";
import { licenseTheme } from "../styles/licenseTheme";
import {
  LICENSE_MINIMUM,
  LicenseFormAction,
} from "src/container/LicenseCodeForm";
import { QueryStatus } from "@tanstack/react-query";
import {
  GROUP_LICENSING_SELECT_SUBSCRIPTIONS_CLICK,
  GroupLicenseSegmentPayload,
} from "../utils/analytics";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px 24px 24px 24px",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "16px",
    },
    width: "100%",
  },
  quantitySelection: {
    display: "flex",
    width: "344px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  quantitySelectionLabel: {
    alignSelf: "stretch",
    textAlign: "center",
    fontFamily: "PT Serif",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
  },
  quantitySelectionForm: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "2px",
    alignSelf: "stretch",
  },
  tiers: {
    display: "flex",
    width: "336px",
    padding: "24px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    borderRadius: "4px",
    background: "var(--ui-grey-ultra-light, #F6F7FA)",
    boxShadow: "0px 1px 3px 0px rgb",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  tierHeader: {
    borderBottom: "2px solid var(--primary-splash, #73CBDD)",
    display: "flex",
    paddingBottom: "0px",
    justifyContent: "space-between",
    alignItems: "flex-end",
    alignSelf: "stretch",
  },
  tierHeaderText: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
  },
  tierRow: {
    borderBottom: "1px solid var(--primary-splash, #73CBDD)",
    display: "flex",
    padding: "4px 0px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
  },
  tierRowText: {
    textAlign: "right",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
  },
  buttonWrapper: {
    width: "100%",
  },
}));

interface LicenseSelectSubscriptionProps {
  purchaseQuantity: number | undefined;
  formDispatch: Dispatch<LicenseFormAction>;
  refetch: () => void;
  calculateStatus: QueryStatus;
}

export const LicenseSelectSubscription = (
  props: LicenseSelectSubscriptionProps
): ReactElement => {
  const classes = useStyles(licenseTheme);
  const [enableButton, setEnableButton] = useState(true);
  const [validateOnChange, setValidateOnChange] = useState(true);

  const validateLicenseCount = (data: string, eventType?: string) => {
    if (eventType === "blur") {
      setValidateOnChange(true);
    }
    if (data && (validateOnChange || eventType === "blur")) {
      const quantity = Number(data);
      if (!isNaN(quantity) && quantity >= LICENSE_MINIMUM) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
        return strings.license_count_string;
      }
    }
  };

  const calculateAmount = (event: MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const licenseCount = formData.get("licenseCount");
    if (typeof licenseCount === "string") {
      if (props.calculateStatus === "error") {
        props.refetch();
      }
      const segmentPayload: GroupLicenseSegmentPayload = {
        "Purchase Quantity": parseInt(licenseCount),
      };
      sendAmplitudeEvent(
        GROUP_LICENSING_SELECT_SUBSCRIPTIONS_CLICK,
        segmentPayload
      );
      props.formDispatch({
        type: "continue",
        quantity: parseInt(licenseCount),
        calculateStatus: props.calculateStatus,
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.quantitySelection}>
        <Typography className={classes.quantitySelectionLabel}>
          {strings.license_count}
        </Typography>
        <form
          className={classes.quantitySelectionForm}
          data-testid="updateAccountForm"
          onSubmit={calculateAmount}
        >
          <TextField
            name="licenseCount"
            testId="licenseCount"
            helpTestId="licenseCountHelp"
            label="Enter amount"
            isNewDesign={true}
            validation={validateLicenseCount}
            value={props.purchaseQuantity}
          />
          <Box className={classes.buttonWrapper}>
            <Button
              name="Continue"
              id="selectContinue"
              testId="selectContinue"
              type="submit"
              backgroundColor={colors["--primary-tealnight"]}
              hoverForeground={colors["--primary-tealnight"]}
              disabled={!enableButton}
              width="100%"
            />
          </Box>
        </form>
      </Box>
      <Box className={classes.tiers}>
        <Box className={classes.tierHeader}>
          <Typography className={classes.tierHeaderText}>
            Number of subscriptions
          </Typography>
          <Typography className={classes.tierHeaderText}>Discount</Typography>
        </Box>
        <Box className={classes.tierRow}>
          <Typography className={classes.tierRowText}>2-10</Typography>
          <Typography className={classes.tierRowText}>10%</Typography>
        </Box>
        <Box className={classes.tierRow}>
          <Typography className={classes.tierRowText}>11-20</Typography>
          <Typography className={classes.tierRowText}>15%</Typography>
        </Box>
        <Box className={classes.tierRow}>
          <Typography className={classes.tierRowText}>21-30</Typography>
          <Typography className={classes.tierRowText}>20%</Typography>
        </Box>
      </Box>
    </Box>
  );
};
