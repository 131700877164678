import { isOfType } from "src/utils/typeguard";

export interface IPrimaryPaymentInformation {
  id: string;
  accountHolder?: string;
  billingAddress1?: string;
  billingAddress2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  cardNumber?: string;
  expirationDate?: string;
}

export interface PrimaryPaymentMethodResponse {
  status: number;
  data: IPrimaryPaymentInformation;
  done: boolean;
}
export interface PaymentMethodResponse {
  status: number;
  data: IPrimaryPaymentInformation;
}
export interface IPaymentMethodVid {
  paymentMethodVid: string;
}
export function isPrimaryPaymentInformation(
  obj: unknown
): obj is IPrimaryPaymentInformation {
  const response = obj as IPrimaryPaymentInformation;
  return isOfType<IPrimaryPaymentInformation>(response, [
    "id",
    "cardNumber",
    "accountHolder",
    "expirationDate",
    "billingAddress1",
    "city",
    "state",
    "postalCode",
  ]);
}

export function isPaymentMethodResponse(
  obj: unknown
): obj is PaymentMethodResponse {
  const response = obj as PaymentMethodResponse;
  return (
    isOfType<PaymentMethodResponse>(response, ["data"]) &&
    isPrimaryPaymentInformation(response.data)
  );
}
