import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../../config/colorConfig";
import { Container } from "./Container";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = (props: LayoutProps) => {
  const styles = makeStyles(() => ({
    layoutWrapper: {
      background: colors["--ui-grey-ultra-light"],
    },
  }));
  const classes = styles(props);
  return (
    <div data-testid="layout" className={clsx(classes.layoutWrapper)}>
      <Container>{props.children}</Container>
    </div>
  );
};
