import { Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from "react";
import { colors } from "src/config/colorConfig";
import { IPrimaryPaymentInformation } from "src/models/PrimaryPaymentMethodResponse";
import { VindiciaError } from "src/models/VindiciaError";
import { globalDialogStyles } from "src/styles/globalDialogStyles";
import { licenseStyles } from "src/styles/licenseStyles";
import { strings } from "src/utils/Strings";
import VindiciaFormWrapper from "vindicia-pmt-react";
import { config } from "../config/envConfig";
import { licenseTheme } from "../styles/licenseTheme";
import { LicensePaymentAddressFields } from "./LicensePaymentAddressFields";
import { Button } from "./custom/Button";
import { LoadingEpocLogo } from "./custom/LoadingEpocLogo";
import { customVindiciaStyle } from "./vindicia/customVindiciaStyle";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import { ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_CLOSE_CANCEL } from "../utils/analytics";
import { useDispatch } from "react-redux";
import { sendSuccessSnackBarWithTitleMessage } from "src/actions/snackBarActions";
import { SnackBarType } from "src/utils/SnackBarType";

interface UserSubscriptionBillingAndPaymentProps {
  paymentMethodDetails?: IPrimaryPaymentInformation;
  vindicia: {
    setup: () => void;
    destroy: () => void;
    isValid: () => boolean;
    resetCompleteStatus: () => void;
    clearData: () => void;
  };
  userId?: string;
  vindiciaFieldsSpinner: boolean;

  setSubmitProgress: Dispatch<SetStateAction<boolean>>;
  paymentSuccess: (vindiciaRes: VindiciaPaymentResponse) => void;
  paymentFailed: (error: VindiciaError) => void;
  submitProgress: boolean;
  isFetching: boolean;
  isPrimaryPaymentRefecting: boolean;

  setUpdatePaymentDialog?: (open: boolean) => void;
  isCalculatedResponseError: boolean;
  promoCode?: string;
}

interface VindiciaPaymentResponse {
  detail: {
    isValid: boolean;
    vid: string;
  };
}

export const UserSubscriptionBillingAndPayment: React.FC<
  UserSubscriptionBillingAndPaymentProps
> = (props: UserSubscriptionBillingAndPaymentProps) => {
  const [values, setValues] = useState({
    isTest: false,
    isValidName: true,
    isAddressValid: false,
    disabledPayment: true,
  });

  const nameValidation = () => {
    const accountHolder = document.getElementById("vin_account_holder");
    if (accountHolder instanceof HTMLInputElement) {
      const data = accountHolder.value;

      if (data) {
        setValues({ ...values, isValidName: true });
      } else {
        setValues({ ...values, isValidName: false });
      }
    }
  };

  useEffect(() => {
    if (props.paymentMethodDetails?.accountHolder) {
      document
        ?.getElementById("vin_account_holder")
        ?.setAttribute("value", props.paymentMethodDetails?.accountHolder);
      setValues({ ...values, isValidName: true });
    }
  }, []);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  function getS4(): string {
    return ((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }
  const guid = `${getS4() + getS4()}-${getS4()}`.toLowerCase();

  const classes = globalDialogStyles(licenseTheme);
  const clx = licenseStyles(licenseTheme);
  const isMobileScreen = window.innerWidth < 600;
  const dispatchRedux = useDispatch();
  const paymentSubmit = () => {
    props.setSubmitProgress(true);
    return true;
  };
  const handleCancel = () => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_CLOSE_CANCEL
    );
    if (props.setUpdatePaymentDialog) props.setUpdatePaymentDialog(false);
  };
  return (
    <div className={clsx(clx.contentWrapper)}>
      {props.vindiciaFieldsSpinner ? (
        <div className={clsx(clx.billingWrapper)}>
          <LoadingEpocLogo />
        </div>
      ) : (
        <VindiciaFormWrapper
          vindicia={props.vindicia ?? {}}
          options={{
            vindiciaAuthId: config.environment.vindiciaAuthId,
            vindiciaServer: config.environment.vindiciaServer,
            vindiciaRestServer: config.environment.vindiciaRestServer,
            sessionId: guid,
            hmac: config.environment.vindiciaHmac,
          }}
          fields={[
            {
              type: "cardNumber",
              placeholder: strings.card_number,
              autocomplete: "cc-number",
              formatinput: true,
              maskinput: true,
            },
            {
              type: "expirationDate",
              placeholder: isMobileScreen
                ? strings.mmyy
                : strings.expiration_dateMMYY,
              format: "MM/YY",
              autocomplete: "cc-exp",
              formatinput: true,
              maskinput: true,
            },
            {
              type: "cvn",
              inputType: "password",
              placeholder: isMobileScreen
                ? strings.sec_code
                : strings.security_code,
              autocomplete: "cc-csc",
            },
          ]}
          styles={customVindiciaStyle}
          onVindiciaFieldEvent={() => forceUpdate()}
          onSubmitEvent={paymentSubmit}
          onSubmitCompleteEvent={props.paymentSuccess}
          onSubmitCompleteFailedEvent={props.paymentFailed}
        >
          <Typography
            id="alert-dialog-subTitle1"
            classes={{
              root: clsx(clx.cardSubTitle, clx.primaryMidnight),
            }}
            data-testid="alert-dialog-subTitle1"
          >
            {strings.enter_payment_information}
          </Typography>
          <div className={clsx(clx.contentBorder)}>
            <div>
              <label
                className={clsx(classes.vindiciaLabel, clx.vindiciaLableMargin)}
                htmlFor="vin_credit_card_account"
              >
                <span className={clsx(clx.asterisk, clx.primaryOrange)}>*</span>
                {strings.card_number}
                <div id="vin_credit_card_account" />
              </label>
            </div>
            <div>
              <label
                className={clsx(classes.vindiciaLabel, clx.vindiciaLableMargin)}
                htmlFor="vin_account_holder"
              >
                <span className={clsx(clx.asterisk, clx.primaryOrange)}>*</span>
                {strings.name_on_card}
                <div data-testid="vin_account_holder">
                  <input
                    className={clsx(
                      classes.customInputField,
                      !values.isValidName ? classes.invalidInput : ""
                    )}
                    type="text"
                    name="vin_account_holder"
                    id="vin_account_holder"
                    placeholder={strings.name_on_card}
                    onChange={nameValidation}
                  />
                </div>
              </label>
            </div>

            <div className={clsx(clx.splitPmtField)}>
              <div className={clsx(clx.flexSpaceOnCardItems)}>
                <label
                  className={clsx(
                    classes.vindiciaLabel,
                    clx.vindiciaLableMargin
                  )}
                  htmlFor="vin_credit_card_expiration_date"
                >
                  <span className={clsx(clx.asterisk, clx.primaryOrange)}>
                    *
                  </span>
                  {strings.expiration_date}
                  <div id="vin_credit_card_expiration_date" />
                </label>
              </div>
              <div className={clsx(clx.flexSpaceOnCardItems)}>
                <label
                  className={clsx(
                    classes.vindiciaLabel,
                    clx.vindiciaLableMargin
                  )}
                  htmlFor="vin_credit_card_cvn"
                >
                  <span className={clsx(clx.asterisk, clx.primaryOrange)}>
                    *
                  </span>
                  {strings.security_code}
                  <div id="vin_credit_card_cvn" />
                </label>
              </div>
              <input
                id="vin_billing_address_country"
                name="vin_billing_address_country"
                value="US"
                type="hidden"
              />
              <input
                id="vin_ignore_avs_policy"
                name="vin_ignore_avs_policy"
                value="1"
                type="hidden"
              />
            </div>

            <Typography
              id="alert-dialog-subTitle1"
              classes={{
                root: clsx(clx.cardSubTitle, clx.primaryMidnight),
              }}
              data-testid="alert-dialog-subTitle1"
            >
              {strings.enter_billing_address}
            </Typography>

            <LicensePaymentAddressFields
              paymentMethodDetails={props.paymentMethodDetails}
              setAddressValid={(val: boolean) =>
                setValues({ ...values, isAddressValid: val })
              }
            />

            <div className={clx.pmtButtonDiv}>
              <Button
                name={strings.continue}
                id="createPayment"
                type="submit"
                width={
                  props.setUpdatePaymentDialog === undefined ? "264px" : "150px"
                }
                backgroundColor={colors["--primary-tealnight"]}
                hoverForeground={colors["--primary-tealnight"]}
                disabled={
                  !props.vindicia?.isValid() ||
                  !values.isAddressValid ||
                  !values.isValidName
                }
                inProgress={
                  props.isFetching ||
                  props.isPrimaryPaymentRefecting ||
                  props.submitProgress
                }
                data-testid="createPayment"
              />
              {props.setUpdatePaymentDialog !== undefined && (
                <Button
                  width="150px"
                  backgroundColor={colors["--pure-white"]}
                  hoverForeground={colors["--pure-white"]}
                  foregroundColor={colors["--primary-tealnight"]}
                  onClick={handleCancel}
                  testId="cancel"
                  name="Cancel"
                  displayText="Cancel"
                />
              )}
            </div>
          </div>
        </VindiciaFormWrapper>
      )}
    </div>
  );
};
