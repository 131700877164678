import { RequestError } from "./RequestError";

export const ERROR_PASSWORD_RESET_TOO_MANY_RESETS = new RequestError(
  "tooManyResets",
  "Too many resend attempts. Please contact support."
);
export const ERROR_PASSWORD_RESET_INVALID_EMAIL = new RequestError(
  "invalidEmail",
  "No account exists for the requested email address."
);
export const ERROR_PASSWORD_RESET_CODE_INVALID = new RequestError(
  "resetCodeExpired",
  "Oops, that link has expired."
);
export const ERROR_PASSWORD_RESET_CODE_USED = new RequestError(
  "resetCodeUsed",
  "Your password has already been reset."
);
export const ERROR_PASSWORD_SAME = new RequestError(
  "samePassword",
  "New password cannot be the same as current password."
);
export const ERROR_PASSWORD_INVALID = new RequestError(
  "badPassword",
  "Password did not meet length or complexity requirements."
);
export const ERROR_PASSWORD_RESET_ENTERPRISE_USER_DISALLOWED = new RequestError(
  "isEnterpriseUser",
  "Password Reset is Not Supported for Enterprise Users On This Platform."
);
//  INTERNAL CODE - should not be returned to the client
export const ERROR_PASSWORD_RESET_CODE_NOT_FOUND = new RequestError(
  "resetCodeNotFound",
  "Internal error - Reset code was not found in the database."
);
export const INVALID_PARAMS_ERROR = new RequestError(
  "invalidParams",
  "Your request has invalid parameters"
);
export const UNAUTHORIZED_ERROR = new RequestError(
  "unauthorized",
  "Unauthorized operation"
);
export const SERVICE_INTERNAL_ERROR = new RequestError(
  "serviceError",
  "Internal Service error"
);
export const ERROR_TIMEOUT = new RequestError(
  "timeout",
  "Request timed out, please retry."
);
export const CANCEL_SUBSCRIPTION_FAILED = new RequestError(
  "CANNOT_CANCEL",
  "Failed to cancel subscription"
);
export const FAILED_PAYMENT_METHOD_UPDATE = new RequestError(
  "updateUserPaymentMethodFailed",
  "User payment method update Failed"
);
export const INVALID_NPI_ERROR = new RequestError(
  "invalidNPI",
  "NPI number is not correct"
);
