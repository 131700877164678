import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRoot } from "../withRoot";
import { DropDownMenu, negativeToEmpty } from "./custom/DropDownMenu";
import { countriesAction } from "../actions/demographicsActions";
import { IdTextTuple } from "../models/IdTextTuple";
import { strings } from "../utils/Strings";
import { graduationYearCount } from "../config/accountCreationConfig";
import { TextField } from "./custom/TextField";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import {
  CREATE_ACCOUNT_COUNTRY_CLICK,
  CREATE_ACCOUNT_COUNTRY_CLICK_EVENT_ID,
  CREATE_ACCOUNT_GRADUATION_YEAR_CLICK,
  CREATE_ACCOUNT_GRADUATION_YEAR_CLICK_EVENT_ID,
  CREATE_ACCOUNT_ZIP_CODE_CLICK,
  CREATE_ACCOUNT_ZIP_CODE_CLICK_EVENT_ID,
} from "../utils/analytics";
import { isEUCountry, isZipCodeEnabled } from "../utils/accountCreationUtil";
import { CountriesResponse } from "../models/CountriesResponse";
import { CountryTuple } from "../models/CountryTuple";
import { Occupation } from "../models/AccountCreationFormValues";
import { config } from "../config/envConfig";
import { ValidateZipCode } from "../utils/validation";

interface AccountCreationState {
  accountCreation: DropdownValuesResponse;
}

interface DropdownValuesResponse {
  countriesResponse: CountriesResponse;
}

interface SubFormProps {
  setValid: (isValid: boolean) => void;
  occupation: Occupation;
  setZipCode: (zipCode: string) => void;
  setGraduationYear: (year: number) => void;
  setCountry: (country: CountryTuple) => void;
  setIsStudent: (isStudent: boolean) => void;
  zipCode: string;
  graduationYear: number;
  country: CountryTuple;
  isStudent: boolean;
  setIsZipValid: (isValid: boolean) => void;
  suppressCountryDropDown?: boolean;
}
const CreateAccountSubForm = (props: SubFormProps) => {
  const empty: IdTextTuple[] = [];
  let graduationDropdown = null;
  const {
    country,
    setCountry,
    zipCode,
    setZipCode,
    graduationYear,
    setGraduationYear,
    isStudent,
    setIsStudent,
    suppressCountryDropDown,
  } = props;
  const dispatch = useDispatch();
  const state = useSelector(
    (accountCreationState: AccountCreationState) =>
      accountCreationState.accountCreation
  );
  const graduationFlag = config.environment.isGraduationYearEnabled;
  const handleCountryChange = (selectedCountry: string) => {
    sendAmplitudeEvent(
      CREATE_ACCOUNT_COUNTRY_CLICK,
      { "Event Id": CREATE_ACCOUNT_COUNTRY_CLICK_EVENT_ID },
      null
    );
    const countryObj: CountryTuple | undefined =
      state?.countriesResponse?.data.find(
        (item: CountryTuple) => item.iso3166alpha2 === selectedCountry
      );
    const data = {
      iso3166alpha2: selectedCountry,
      name: countryObj?.name ?? "",
      id: countryObj?.id ?? -1,
    };
    setCountry(data);
    return isEUCountry(selectedCountry) ? strings.eu_unavailable : null;
  };
  const handleZipCodeChange = (data: string) => {
    let response = null;
    if (ValidateZipCode(data)) {
      response = strings.invalid_zip_code;
    }
    props.setIsZipValid(!response);
    setZipCode(data);
    return response;
  };
  const handleYearChange = (year: number) => {
    sendAmplitudeEvent(
      CREATE_ACCOUNT_GRADUATION_YEAR_CLICK,
      { "Event Id": CREATE_ACCOUNT_GRADUATION_YEAR_CLICK_EVENT_ID },
      null
    );
    setGraduationYear(year);
    return null;
  };
  const getGraduationYearList = () => {
    const currentYear = new Date().getFullYear();
    const list = [];
    for (let i = 0; i <= graduationYearCount; i += 1) {
      list.push({ year: currentYear + i });
    }
    return list;
  };
  const onZipCodeFocus = () => {
    sendAmplitudeEvent(
      CREATE_ACCOUNT_ZIP_CODE_CLICK,
      { "Event Id": CREATE_ACCOUNT_ZIP_CODE_CLICK_EVENT_ID },
      null
    );
  };
  if (isStudent) {
    graduationDropdown = (
      <DropDownMenu
        id="graduationYear"
        name={strings.expected_graduation_year}
        testId="graduationYear"
        helpTestId="graduationYearHelp"
        noBottomMargin
        validation={handleYearChange}
        value={negativeToEmpty(graduationYear)}
        idValue="year"
        field="year"
        minWidth="343px"
        maxWidth="343px"
        list={{ data: getGraduationYearList() }}
      />
    );
  }
  React.useEffect(() => {
    const studentCheck =
      props.occupation.text.toLowerCase().indexOf("student") !== -1;
    setIsStudent(studentCheck && graduationFlag);
    if (!studentCheck) {
      setGraduationYear(-1);
    }
  }, [props.occupation.id]);
  React.useEffect(() => {
    dispatch(countriesAction());
  }, []);
  React.useEffect(() => {
    let isAllValid = true;
    if (!country.iso3166alpha2) {
      isAllValid = false;
    } else if (isEUCountry(country.iso3166alpha2)) {
      isAllValid = false;
    } else if (isZipCodeEnabled(country.iso3166alpha2) && !zipCode) {
      isAllValid = false;
    } else if (isStudent && graduationYear === -1) {
      isAllValid = false;
    }
    props.setValid(isAllValid);
  });
  return (
    <>
      {graduationFlag && graduationDropdown}
      {!suppressCountryDropDown && (
        <DropDownMenu
          id="country"
          name={
            isStudent
              ? strings.country_of_residence
              : strings.country_of_practice
          }
          testId="country"
          helpTestId="countryHelp"
          countryIndex="country"
          noBottomMargin
          validation={handleCountryChange}
          value={negativeToEmpty(country.iso3166alpha2)}
          idValue="iso3166alpha2"
          field="name"
          minWidth="343px"
          maxWidth="343px"
          isRequiredField
          list={state?.countriesResponse ?? { data: empty }}
          isNewDesign={true}
        />
      )}
      {isZipCodeEnabled(country.iso3166alpha2) ? (
        <TextField
          name="zipCode"
          testId="zipCode"
          label={isStudent ? strings.zip_code : strings.practicing_zip_code}
          helpTestId="zipCodeHelp"
          value={zipCode}
          onClickField={onZipCodeFocus}
          validation={handleZipCodeChange}
          isNewDesign={true}
        />
      ) : null}
    </>
  );
};
export const CreateAccountSubFormWith = withRoot(CreateAccountSubForm);
