import React from "react";
import { Grid, makeStyles, Snackbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { colors } from "../config/colorConfig";
import { strings } from "../utils/Strings";
import { withRoot } from "../withRoot";
import {
  CURRENT_USER_ID,
  SHOW_WELCOME_BANNER,
  SESSION_BANNER,
} from "../utils/localStorageKeys";
import closeIcon from "../images/icons-streamline-regular-24-pt-close.svg";
import checkGreen from "../images/icons-streamline-regular-24-pt-check-circle-filled-green.svg";
import epocratesPlus from "../images/epocrates-plus-icon.svg";
import epocratesGraphic from "../images/epocrates-graphic.svg";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import {
  ACCOUNT_BANNER_CLOSE_CLICK,
  ACCOUNT_BANNER_CLOSE_CLICK_EVENT_ID,
  ACCOUNT_BANNER_LEARN_MORE_CLICK,
  ACCOUNT_BANNER_LEARN_MORE_CLICK_EVENT_ID,
  ACCOUNT_BANNER_VIEW,
  ACCOUNT_BANNER_VIEW_EVENT_ID,
} from "../utils/analytics";

interface ProfileBannerProps {
  type: string;
  isPremiumUser?: boolean;
  name: string;
  occupation: string;
}

export const ProfileBanner = (props: ProfileBannerProps) => {
  const isFreeUser = !props.isPremiumUser;
  const [isClosed, setIsClosed] = React.useState<boolean>(false);
  const [bannerType, setBannerType] = React.useState<string>(props.type);
  const ProfileBannerStyle = makeStyles((theme) => ({
    wrapper: {
      backgroundColor: colors["--primary-midnight"],
      position: "relative",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(6),
      padding: `${theme.spacing(4)}px 0`,
      minHeight: 80,
      display: "flex",
      alignItems: "center",
    },
    image: {
      backgroundImage: `url(${epocratesGraphic})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "85%",
      position: "absolute",
      top: 0,
      left: 0,
      height: 80,
      width: "100%",
    },
    bannerTitle: {
      fontFamily: "Metric",
      fontSize: 20,
      fontWeight: 500,
      marginBottom: theme.spacing(1),
    },
    bannerContent: {
      display: "flex",
      alignItems: "center",
    },
    contentWrapper: {
      [theme.breakpoints.down("xs")]: {
        padding: "0 10px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: `0 ${theme.spacing(6)}px`,
      flexBasis: "100%",
      zIndex: 1,
    },
    bannerInfoWrapper: {
      [theme.breakpoints.down("xs")]: {
        flexBasis: "75%",
      },

      color: colors["--text-invert"],
      marginLeft: theme.spacing(4),
    },
    bannerDesc: {
      margin: 0,
    },
    arrowIcon: {
      width: 32,
      height: 32,
    },
    learnMoreHyperlink: {
      [theme.breakpoints.down("xs")]: {
        marginRight: theme.spacing(2),
      },
      marginRight: theme.spacing(10),
      color: colors["--text-invert"],
      fontSize: 14,
      fontWeight: 600,
      "&:hover": {
        color: colors["--text-invert"],
        textDecoration: "none",
      },
    },
    epocratesPlusIcon: {
      width: 40,
      height: 32,
    },
    closeIcon: {
      cursor: "pointer",
    },
    bannerSnackbar: {
      position: "static",
      display: "block",
      [theme.breakpoints.down("md")]: {
        paddingLeft: 16,
      },
    },
    bannerInteractionLinks: {
      [theme.breakpoints.down("xs")]: {
        flexBasis: 115,
      },
      display: "flex",
    },
  }));
  const classes = ProfileBannerStyle(props);
  let bgImage = null;
  let icon = null;
  let bannerDescription = null;
  let learnMoreLink = null;
  let bannerTitle = null;
  let bannerLinksClass = null;
  const userId = localStorage.getItem(CURRENT_USER_ID);
  const learnMoreClickHandler = () => {
    localStorage.setItem(SESSION_BANNER, JSON.stringify(false));
    const eventProperties = {
      "Event ID": ACCOUNT_BANNER_LEARN_MORE_CLICK_EVENT_ID,
      "Banner Type": bannerType,
    };
    sendAmplitudeEvent(
      ACCOUNT_BANNER_LEARN_MORE_CLICK,
      eventProperties,
      userId
    );
  };
  React.useEffect(() => {
    const show = localStorage.getItem(SHOW_WELCOME_BANNER);
    const eventProperties: Record<string, string> = {
      "Event ID": ACCOUNT_BANNER_VIEW_EVENT_ID,
    };
    if (show && JSON.parse(show)) {
      eventProperties["Banner Type"] = strings.welcome_banner_type;
      setBannerType(strings.welcome_banner_type);
    } else {
      eventProperties["Banner Type"] = strings.upgrade_banner_type;
    }
    sendAmplitudeEvent(ACCOUNT_BANNER_VIEW, eventProperties, userId);
  }, []);
  const closeBanner = () => {
    const eventProperties = {
      "Event ID": ACCOUNT_BANNER_CLOSE_CLICK_EVENT_ID,
      "Banner Type": bannerType,
    };
    localStorage.setItem(SHOW_WELCOME_BANNER, JSON.stringify(false));
    sendAmplitudeEvent(ACCOUNT_BANNER_CLOSE_CLICK, eventProperties, userId);
    if (
      bannerType === strings.welcome_banner_type &&
      isFreeUser &&
      !(window.location.href.indexOf("subscription") > 0)
    ) {
      setBannerType(strings.upgrade_banner_type);
    } else {
      localStorage.setItem(SESSION_BANNER, JSON.stringify(false));
      setIsClosed(true);
    }
    if (window.location.href.indexOf("subscription") > 0) {
      localStorage.setItem(SESSION_BANNER, JSON.stringify(true));
    }
  };

  React.useEffect(() => {
    if (bannerType === strings.welcome_banner_type) {
      setTimeout(() => {
        localStorage.setItem(SHOW_WELCOME_BANNER, JSON.stringify(false));
      }, 1000);
    }
  }, [bannerType]);
  switch (bannerType) {
    case strings.welcome_banner_type:
      bgImage = <div className={clsx(classes.image)} />;
      icon = (
        <img className={clsx(classes.arrowIcon)} src={checkGreen} alt="" />
      );
      bannerTitle = `${strings.welcome_banner_title}${
        props.name && `, ${props.name}`
      }${props.occupation && `, ${props.occupation}`}`;
      break;
    case strings.upgrade_banner_type:
      icon = (
        <img
          className={clsx(classes.epocratesPlusIcon)}
          src={epocratesPlus}
          alt=""
        />
      );
      bannerDescription = (
        <p data-testid="bannerDesc" className={clsx(classes.bannerDesc)}>
          {strings.unlock_plus_banner_description}
        </p>
      );
      learnMoreLink = (
        <Link
          onClick={learnMoreClickHandler}
          data-testid="learnMoreLink"
          to="/account/subscription"
          className={clsx(classes.learnMoreHyperlink)}
        >
          {strings.learn_more_button_label}
        </Link>
      );
      bannerTitle = strings.unlock_plus_banner_title;
      bannerLinksClass = classes.bannerInteractionLinks;
      break;
    default:
      return <></>;
  }

  if (
    isClosed ||
    (bannerType === strings.upgrade_banner_type && props.isPremiumUser) ||
    localStorage.getItem(SESSION_BANNER) === "false"
  ) {
    return <></>;
  }
  return (
    <Grid item lg={12} md={12} xs={12}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open
        className={clsx(classes.bannerSnackbar)}
      >
        <div data-testid="bannerWrapper" className={clsx(classes.wrapper)}>
          {bgImage}
          <div className={clsx(classes.contentWrapper)}>
            <div className={clsx(classes.bannerContent)}>
              {icon}
              <div className={clsx(classes.bannerInfoWrapper)}>
                <h4
                  data-testid="bannerTitle"
                  className={clsx(classes.bannerTitle)}
                >
                  {bannerTitle}
                </h4>
                {bannerDescription}
              </div>
            </div>
            <div className={clsx(bannerLinksClass)}>
              {learnMoreLink}
              <img
                data-testid="closeButton"
                alt=""
                className={clsx(classes.closeIcon)}
                onClick={closeBanner}
                onKeyDown={closeBanner}
                role="presentation"
                src={closeIcon}
              />
            </div>
          </div>
        </div>
      </Snackbar>
    </Grid>
  );
};
ProfileBanner.defaultProps = {
  isPremiumUser: false,
};

export const ProfileBannerWithRoot = withRoot(ProfileBanner);
