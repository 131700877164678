import { AuthRequest } from "../models/AuthRequest";
import * as types from "./index";
import { VerifyRequest } from "../models/VerifyRequest";
import { AuthorizationCodeRedeemRequest } from "../models/AuthorizationCodeRedeemRequest";

export const loginUserAction = (authRequest: AuthRequest) => ({
  type: types.LOGIN_USER,
  authRequest,
});
export const verifyUserAction = (verifyRequest: VerifyRequest) => ({
  type: types.VERIFY_USER,
  verifyRequest,
});
export const authCodeRedeemUserAction = (
  authorizationCodeRedeemRequest: AuthorizationCodeRedeemRequest
) => ({
  type: types.AUTH_CODE_REDEEM_USER,
  authorizationCodeRedeemRequest,
});
export const requireManualLoginAction = () => ({
  type: types.REQUIRE_USER_MANUAL_LOGIN,
});
