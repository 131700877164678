import * as types from "../actions";
import { SnackBarType } from "../utils/SnackBarType";
import { ISnackBarResponse } from "../models/SnackBarResponse";

const initialState: ISnackBarResponse = {
  isSnackBarOpen: false,
  message: "",
  messageType: SnackBarType.OTHER,
  link: false,
  title: "",
  autoHideDuration: 15000,
};

interface ISnackBarReducer {
  type: string;
  message: string;
  messageType: SnackBarType;
  title?: string;
  link?: boolean;
}

export function sendSnackBarMessage(
  state = initialState,
  action: ISnackBarReducer
): ISnackBarResponse {
  switch (action.type) {
    case types.SET_SNACKBAR_MESSAGE:
      return {
        ...state,
        isSnackBarOpen: true,
        message: action.message,
        messageType: action.messageType,
        link: action.link,
        title: action.title,
      };
    case types.CLOSE_SNACKBAR:
      return { ...state, isSnackBarOpen: false };
    case types.TIMEOUT_SNACKBAR:
      return { ...state, autoHideDuration: 15000 };
    case types.SUCCESS_SNACKBAR_MESSAGE:
      return {
        ...state,
        isSnackBarOpen: true,
        message: action.message,
        messageType: action.messageType,
        title: action.title,
        autoHideDuration: 5000,
        link: false,
      };
    default:
      return { ...state };
  }
}
