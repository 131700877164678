import LocalizedStrings from "react-localization";
export const strings = new LocalizedStrings({
  en: {
    login_signin_title: "Sign in to your account",
    sign_in_title: "Sign in",
    sign_in_with_email: "Sign in with email",
    sign_in_with_otp: "Sign in with a one-time code",
    resend_otc: "Resend one-time code",
    otc_tip: "Tip: Make sure to check your inbox and spam folders",
    send_code: "Send code",
    cancel: "Cancel",
    login_error_message:
      "Check that you've entered your email address and password correctly",
    login_contact_system_administrator: "Please contact system administrator.",
    internal_service_error: "Internal error please contact Epocrates support",
    unknown_error: "Unknown error please contact Epocrates support.",
    invalid_request_params: "Invalid parameters request",
    login_email: "Email",
    confirm_email: "Confirm Email",
    login_password: "Password",
    login_forgot_password: "Forgot your password?",
    login_dont_have_account: "Don't have an account?",
    login_signup: "Sign Up",
    login_stay_signed_in: "Stay signed in",
    login_remember_me: "Remember me",
    password_reset_success_message: "Password changed successfully",
    email_blank_message: "Please Enter Valid Email",
    password_blank_message: "Please Enter Password",
    email_change_message:
      "Changes to your email will be saved in your epocrates account",
    invalid_reset_code:
      "Reset link is invalid or no longer active. Please submit your request again.",
    reset_code_used:
      "Reset has already been done using this code, please try again.",
    invalid_email:
      "No account exists for the requested email address or username.",
    invalid_password: "Password used is invalid, please try again.",
    same_password: "Password used is the same as old, please try again.",
    too_many_reset_attempts:
      "Too many resend attempts. Please try again later.",
    profile_title: "Confirm account details",
    pick_occupation: "Pick from list of occupations",
    profile_email: "Email address",
    first_name: "First Name",
    last_name: "Last Name",
    Promo: "Enter discount code",
    Date_of_birth: "Date of birth",
    occupation: "Occupation",
    specialty: "Specialty",
    degree: "Degree",
    npi: "NPI",
    me_number: "ME Number",
    country_of_practice: "Country Of Practice",
    country_of_residence: "Country Of Residence",
    practicing_zip_code: "Practicing Zip Code",
    zip_code: "Zip code",
    cme_profile_title:
      "Please confirm your profile information to ensure CME certificate accuracy.",
    invalid_first_name:
      "Please enter valid first name, should be minimum 1 and maximum 200 characters long",
    invalid_last_name:
      "Please enter valid last name, should be minimum 1 and maximum 200 characters long",
    no_number_in_name: "Please enter valid name, should not contain digits",
    license_count_string: "Please enter a valid quantity greater than one",
    invalid_dob: "Please enter valid Data of Birth",
    invalid_country: "Please select Country Of Practice",
    invalid_occupation: "Please select valid Occupation",
    invalid_specialty: "Please select valid Specialty",
    invalid_npi: "Please Enter 10 digit valid NPI ",
    invalid_zip_code: "Please Enter valid Zip Code",
    create_account_title_1: "Quick and free sign-up",
    create_account_subtitle_1: "Enter your email address to create an account",
    create_account_title_2: "Get clinical decision support",
    create_account_subtitle_2:
      "epocrates delivers the most current safety, diagnostic, and treatment information, right when you need it",
    create_account_title_3: "Find what you need, faster",
    create_account_subtitle_3:
      "On average, providers report saving 20 minutes or more a day with epocrates*",
    footer_survey: "* 2019 HCP survey",
    create_your_account: "Create your epocrates account",
    continue: "Continue",
    create_account: "Create Account",
    create_account_message_title: "The #1 medical reference for clinicians¹",
    create_account_message_1:
      "Access the most recent information and resources designed to help you make more informed clinical decisions.",
    create_account_message_2: "¹ DRG Taking the Pulse Survey, 2020",
    blank_field_message: "This field cannot be blank",
    email_format_invalid: "Email format is invalid",
    name_format_invalid:
      "Name format must be alphabetic, . , & / - are allowed",
    different_email_message: "Email ids entered cannot be different",
    password_min_chars: "Must be minimum of 8 characters",
    password_upper_lower_case: "Must contain upper & lower case",
    password_one_number: "Must contain at least one number",
    password_no_email: "Cannot contain your email",
    expected_graduation_year: "Expected Graduation Year",
    claim_your_profile: "Claim your profile",
    claim_description:
      "By claiming your profile, you confirm this information is accurate.",
    claim_dont_see: "I don’t see my information here",
    npi_screen_title: "A little more about you",
    npi_screen_subTitle:
      "Please make any necessary changes below. You can update these fields in your profile.",
    create_account_error_message: "Something went wrong, please ",
    eu_unavailable:
      "epocrates is unavailable to citizens of or those located in the EU",
    profile: "Profile",
    paymentSubscription: "Payment & Subscription",
    emailPreferences: "Email Preferences",
    cancel_subscription: "Cancel Subscription",
    cancel_confirmation:
      "Are you sure you want to cancel your epocrates+ Subscription? ",
    please_wait: "Please Wait",
    confirm_cancellation: "Are you sure you want to cancel?",
    subscription_cancelled: "Subscription Cancelled",
    process_cancellation: "Processing cancellation...",
    contact_support: " Help & Feedback",
    no_cancel: "Nevermind, I don’t want to cancel",
    yes_cancel: "Yes, I want to cancel",
    cancel_subText: "Are you experiencing a specific issue? ",
    promo_checkbox_label: "Do you have a promo code?",
    welcome_banner_title: "Welcome to epocrates",
    unlock_plus_banner_title: "Tap into the power of epocrates+",
    unlock_plus_banner_description:
      "Unlock premium clinical decision support for diseases and more.",
    learn_more_button_label: "Learn More",
    welcome_banner_type: "Welcome",
    upgrade_banner_type: "Plus Upgrade",
    upgrade_banner_label: "Upgrade",
    subscription_internal_error:
      "Failed to cancel subscription, Please try later",
    transaction_internal_error:
      "Sorry we are having trouble loading the transaction history",
    cancel_subscription_snackbar_title:
      "There was an error cancelling your subscription",
    cancel_subscription_success_snackbar_title:
      "Subscription cancelled successfully",
    unsubscribe: "Unsubscribe from all",
    email_preference_update_success: "Email Preferences Updated successfully",
    email_preference_update_failed: "Email Preferences Update failed",
    payment_method_success_message: "Successfully updated payment information",
    payment_method_failed_message: `Please try again. If issue persists, please click link to contact support.`,
    payment_failed_title: "Failed to process payment",
    payment_update_failed_title: "Unable to update payment information",
    calculate_failed_title: "Failed to process Order summary",
    profile_update_success: "Profile information changed successfully",
    epocrates_domain: ".epocrates.com",
    change_password_success: "Password changed successfully",
    confirm_password_mismatch: "Passwords don't match",
    password_mismatch_error: "Your current password does not match our records",
    current_password_mismatch_error_code: "currentPasswordMismatch",
    same_password_error_code: "samePassword",
    change_password_modal_title: "Change password",
    change_subscription: "Upgrade to Epocrates+",
    confirm_payment_billing_information:
      "Confirm payment & billing information",
    confirm_payment: "Confirm Payment",
    order_summary: "Order summary",
    complete_purchase: "Complete Purchase",
    enter_payment_information: "Enter payment information",
    enter_billing_address: "Enter billing address",
    payment_information: "Payment information",
    name_on_card: "Name on card",
    card_number: "Card number",
    exp_date: "Exp. Date",
    security_code: "Security code",
    sec_code: "Sec. Code",
    expiration_date: "Expiration date",
    expiration_dateMMYY: "Expiration date MM/YY",
    mmyy: "MM/YY",
    address: "Address",
    aptSuiteEtcOptional: "Apt, Suite, etc. (optional)",
    addressLine2Optional: "Address line 2 (optional)",
    addressLine2: "Address line 2",
    aptSuiteEtc: "Apt, Suite, etc.",
    optional: "optional",
    city: "City",
    state: "State",
    enter_name_on_card: "Enter name on card",
    enter_zip_code: "Enter zip code",
    enter_card_number: "Enter card number",
    date_format: "MM/YY",
    will_renew_on: "Will renew on",
    // primary_payment_information_error:
    //   "Cannot load payment information, please try again or contact support",
    primary_payment_information_error:
      "epocrates Plus is currently unavailable. We sincerely apologize for the inconvenience and thank you for your patience",
    due_today: "Due Today",
    excluding_tax: "excluding tax",
    total_before_tax_discount: "Total Before Tax/Discount",
    promo_applied: "Promo Code Applied",
    estimated_tax: "Estimated Tax Collected",
    grand_total: "Grand Total",
    yearly_subscription_sub_text:
      "Your subscription will start today and will automatically renew annually until cancelled. You will be charged today and on each renewal date hereafter. Prices subject to change. All prices shown are in USD. epocrates only accepts credit cards from the United States. You may cancel this subscription at any time by visiting the Payment & Subscriptions page in Account. If you cancel your subscription within 14 days after your annual renewal date, we will refund your subscription amount for that year, provided you request a refund by contacting our",
    monthly_subscription_sub_text:
      "Your subscription will start today and will automatically renew monthly until cancelled. You will be charged today and on each renewal date hereafter. Prices subject to change. All prices shown are in USD. epocrates only accepts credit cards from the United States. You may cancel this subscription at any time by visiting the payment & subscriptions page in account or by contacting",
    calculate_payment_error: "Error calculating payment",
    campaign_code_error:
      "Please try again. Contact support if the issue persists",
    invalid_promo_code: "Invalid promo code",
    commit_payment_error: "Error submitting payment",
    commit_payment_success: "Account upgraded successfully",
    change_email_modal_title: "Change email",
    new_email_label: "New email",
    new_confirm_email_label: "Confirm new email",
    change_email_success_title_text: "Code invalid",
    change_email_success: "Email changed successfully",
    input_type_email: "email",
    input_type_confirmEmail: "confirmEmail",
    system_error: "System Error",
    view_details: "View Details",
    close_details: "Close Details",
    upsell_screen: "Upsell Screen",
    account_screen: "Account Page",
    // product_list_error: "Unexpected error getting product list",
    product_list_error:
      "epocrates Plus is currently unavailable. We sincerely apologize for the inconvenience and thank you for your patience",
    standard_features: "Includes access to our standard features",
    all_features: "Includes access to all of our features",
    only_android_ios: "*Only available on iOS and Android",
    failure_subText: "Are you continuing to experience issues with payment?",
    payment_failure_subText:
      "Unfortunately there was an issue processing your payment information, please press Continue to try again",
    payment_failure: "Payment Failure",
    change_not_saved: "Change not saved",
    save_or_cancel:
      "You made a change to your profile information without saving. Would you like to save your change or cancel?",
    update_payment_method: "Update payment method",
    update_payment: "Update Payment",
    upgrade_epocrates_plus: "Upgrade to epocrates+",
    back: "Back",
    add_payment_method: "Add payment method",
    plan_card_title: "Your Plan",
    profile_tab: "profile",
    subscription_tab: "subscription",
    email_preferences_tab: "email",
    devices_tab: "devices",
    devices: "Devices",
    comparison_chart_feature_list_header: "What you get:",
    upgrade_card_title: "Upgrade to epocrates+",
    plan_type_annual: "Annual",
    plan_type_month: "Monthly",
    welcome_epocrates_plus: "Welcome to epocrates+",
    thank_you_for_joining: "Thank you for joining!",
    tips_to_get_started: "Here are a few tips to help you get started:",
    iphone: "iphone",
    ipad: "ipad",
    ipod: "ipod",
    android: "android",
    annual_subscription_price: "$174.99",
    monthly_subscription_price: "$16.99",
    google_platform: "Google",
    iTunes: "iTunes",
    google_play_store: "Google Play Store",
    iOS_app_store: "Apple App Store",
    manage_subscription: "Manage your subscription on the",
    whoops: "Whoops!",
    payment_failed: "We are unable to process your payment at this time.",
    failed_payment_instructions1:
      "Please refresh the page in a few minutes and try again.",
    failed_payment_instructions2: "If the issue persists, please contact our",
    support_team: "Support Team",
    your_plan: "Your Plan",
    payment_method_invalid: "Payment Method Invalid",
    auth_failed: "Your payment could not be authorized",
    failed_auth_instructions1:
      "Please make sure your card is valid & that your information is correct.",
    payment_method: "Payment method",
    billing_address: "Billing address",
    contact_support_for_refund: "epocrates+ is only available in the US",
    no_subspecialty: "No subspecialty",
    error_while_loading:
      "Error while loading the page. Please reload the page.",
    if_issue_persists: "if the issue persists.",
    reload: "Reload",
    sorry: "Sorry",
    surveyTitle: "Why are you considering cancelling your subscription?",
    surveySubtitle: "Select all that apply:",
    surveyCheckbox1: "It's too expensive",
    surveyCheckbox2: "I don't use epocrates+ enough",
    surveyCheckbox3: "Some features I need are missing",
    surveyCheckbox4: "I found a better alternative",
    surveyCheckbox5: "Technical Issues",
    surveyCheckbox6: "Content Issues",
    surveyCheckbox7: "Other",
    surveyDisclaimer:
      "By completing the survey, you agree to let epocrates store and use your input to improve the app and overall experience.",
    enterprise_title: "epocrates+ Group",
    enterprise_text: "Interested in epocrates+ for your team or organization?",
    select_license: "Select Subscriptions",
    promo_code_success: "Discount applied successfully",
    license_redeem_string: "Please enter a valid subscription code",
    license_code_error_title_text: "Code invalid",
    license_code_error_text:
      "The code you entered is incorrect or invalid. Please try again. Visit Help & Feedback if issue persists.",
    calculate_code_error_text: "The code you entered is incorrect or invalid.",
    agreement_error_text:
      "Please confirm you understand you will not get charged until after your 14-day trial ends.",
    license_discount_text:
      "The more Enterprise subscriptions you purchase, the greater the discount",
    license_count: "How many subscriptions would you like?",
    group_purchase_confirmation_title: "Thank you for your order!",
    group_purchase_confirmation_content:
      "Forward the email that contains your unique group subscription code to members of your group so they can access all that epocrates+ has to offer.",
    group_purchase_confirmation_notes_title:
      "You will be receiving two (2) emails regarding your purchase:",
    agreementCheckbox:
      "By checking this box, I confirm that I have read and agree to the Epocrates Services Agreement on behalf of my organization",

    one_time_code_sent: "One time code sent",
    one_time_code_sent_message:
      "We have sent your one-time code to the requested email",
    one_time_code_resent: "One time code resent",
    one_time_code_resent_message:
      "We have resent your one-time code to the requested email",
    finish_your_account: "Finish creating your account",
    // SEO
    account_profile_meta_description:
      "Manage your epocrates account with ease. Access a comprehensive account summary to enhance your medical resources. Elevate your healthcare practice with convenient tools for efficient account management.",
    subscription_meta_description:
      "Streamline your medical resources in one place by upgrading to epocrates plus. Add essential clinical tools to your cart for improved patient care. Elevate your practice with trusted medical solutions available at your fingertips.",
    create_account_meta_description:
      "Experience epocrates Registration for streamlined access to essential medical resources. Elevate your practice with expert insights and clinical tools. Join our community for a trial of improved healthcare decision-making.",

    guest_account_information:
      "This will be a new account, you can finish setting it up after your purchase",
    existing_account_not_logged_in:
      "To continue with this account, you will need to sign in",
    existing_account_not_logged_in_button_text: "Sign in to continue",
    email_tooltip:
      "The email you enter here will be the one your code is sent to",
    // Device Management
    max_device_threshold: "4",
    sign_out_device_confirmation:
      "Are you sure you want to sign out of this device?",
    sign_in_device_intimation:
      "You will be required to sign back in on this device in the future to use epocrates.",
    sign_out_all_device_confirmation:
      "Are you sure you want to sign out of all devices?",
    sign_in_all_device_intimation:
      "You will be signed out of this session and will be required to sign back in on any future device to use epocrates.",
    app_device_error_title: "No devices found",
    app_device_error_description:
      "Either you haven’t logged into an epocrates app before\nor your apps need to be updated to view here",
    devices_info_tooltip_content:
      "Your account is limited to four devices. On the sign in of a fifth device, your least active device will be signed out.",
    sign_out_title: "You’ve been signed out",
    all_devices_sign_out_description:
      "All devices using your login have been signed out and will require you to sign in again.",
    device_forced_sign_out:
      "The device limit has been reached and you were automatically signed out when a new device signed in.",
    error_title: "Sorry, we can’t complete that action right now",
    DISEASES: "Diseases",
    LABS: "Labs",
    HERBS_AND_SUPPLEMENTS: "Herbs & Supplements",
    IDTX: "IDTX",
    ICD_10: "ICD-10",
    yearly_additional_description:
      "Get epocrates+ for one, two, or three years",
    epocrates_group_additional_description:
      "Get discounts when buying for your group or team",
  },
});
