import {
  ERROR_PASSWORD_RESET_CODE_INVALID,
  ERROR_PASSWORD_RESET_CODE_NOT_FOUND,
  ERROR_PASSWORD_RESET_CODE_USED,
  ERROR_PASSWORD_RESET_INVALID_EMAIL,
  ERROR_PASSWORD_RESET_TOO_MANY_RESETS,
  ERROR_PASSWORD_SAME,
  ERROR_PASSWORD_INVALID,
  INVALID_PARAMS_ERROR,
  SERVICE_INTERNAL_ERROR,
} from "../models/ServiceErrorCodes";
import { LOGIN_FAILED } from "../actions";
import { strings } from "./Strings";

export const passwordResetErrorMessage = (errorObject?: any): string => {
  let message = "";
  switch (errorObject?.error?.code) {
    case ERROR_PASSWORD_RESET_CODE_INVALID.error.code:
    case ERROR_PASSWORD_RESET_CODE_NOT_FOUND.error.code:
      message = strings.invalid_reset_code;
      break;
    case ERROR_PASSWORD_RESET_CODE_USED.error.code:
      message = strings.reset_code_used;
      break;
    case ERROR_PASSWORD_RESET_INVALID_EMAIL.error.code:
      message = strings.invalid_email;
      break;
    case ERROR_PASSWORD_INVALID.error.code:
      message = strings.invalid_password;
      break;
    case ERROR_PASSWORD_SAME.error.code:
      message = strings.same_password;
      break;
    case ERROR_PASSWORD_RESET_TOO_MANY_RESETS.error.code:
      message = strings.too_many_reset_attempts;
      break;
    default:
      message = strings.unknown_error;
  }
  return message;
};

export const loginErrorMessage = (errorObject?: any): string => {
  let message = "";
  switch (errorObject?.error?.code) {
    case LOGIN_FAILED:
      message = strings.login_error_message;
      break;
    case INVALID_PARAMS_ERROR.error.code:
      message = strings.invalid_request_params;
      break;
    case SERVICE_INTERNAL_ERROR.error.code:
      message = strings.internal_service_error;
      break;
    default:
      message = strings.unknown_error;
  }
  return message;
};
