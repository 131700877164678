import React from "react";
import {
  makeStyles,
  DialogContentText,
  Link,
  TextareaAutosize,
} from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../config/colorConfig";
import { strings } from "../utils/Strings";
import { Button } from "./custom/Button";
import { config } from "../config/envConfig";
import { SubscriptionDialog } from "./SubscriptionDialog";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import {
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_NEVERMIND_QUESTIONS,
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CLOSE,
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CONTACT_SUPPORT,
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FEEDBACK_CONTINUE,
} from "../utils/analytics";
import { SubscriptionCancellationConfirmation } from "./SubscriptionCancellationConfirmation";

interface SubscriptionCancellationFeedbackProps {
  subscriptionId: string;
  open: boolean;
  cancelReasons: string;
  sku: string;
  setCancelFeedBackModal: (cancelFeedBackModal: boolean) => void;
  subscriptionEndDate: string;
}

export const SubscriptionCancellationFeedback = (
  props: SubscriptionCancellationFeedbackProps
): JSX.Element => {
  const dialogBoxStyles = makeStyles((theme) => ({
    contentAlignment: {
      margin: `${theme.spacing(4)}px ${theme.spacing(6)}px 0px ${theme.spacing(
        6
      )}px`,
    },
    popUpText: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "28px",
      color: colors["--ui-midnight"],
    },
    popUpSubText: {
      fontSize: "16px",
      color: colors["--ui-slate"],
    },

    cancelLink: {
      color: `${colors["--primary-teal"]}`,
      fontWeight: 500,
      fontSize: "16px",
      whiteSpace: "nowrap",
      "&:hover": {
        textDecoration: "none",
        color: `${colors["--primary-teal"]}`,
      },
    },
    cancelSubtext: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      color: colors["--primary-midnight"],
    },
    formGroup: {
      marginTop: `${theme.spacing(2)}px`,
    },
    noCancel: {
      marginTop: `${theme.spacing(4)}px`,
      backgroundColor: `${colors["--text-invert"]}`,
      color: `${colors["--primary-midnight"]}`,
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 500,
      border: `solid 1px ${colors["--ui-slate"]}`,
      "&:disabled": {
        backgroundColor: `${colors["--ui-grey-semi"]}`,
        color: `${colors["--ui-grey-med"]}`,
        border: "none",
      },
    },
    switchColor: {
      "&.Mui-checked": {
        color: `${colors["--primary-teal"]}`,
      },
    },
    formControl: {
      height: 28,
    },
    ButtonRow: {
      display: "flex",
      alignItems: "left",
      marginTop: `${theme.spacing(4)}px`,
    },
    subFormButtonFields: {
      flexGrow: 0,
      minWidth: 180,
      marginRight: `${theme.spacing(2)}px`,
    },
    contactLink: {
      marginTop: `${theme.spacing(4)}px`,
      marginBottom: `${theme.spacing(4)}px`,
    },
    textAreaDiv: {
      margin: `${theme.spacing(4)}px 0px`,
    },
    textArea: {
      padding: `${theme.spacing(3)}px`,
      borderColor: `${colors["--ui-gray"]}`,
      borderWidth: 2,
    },
  }));

  const [feedBackText, setFeedBackText] = React.useState("");
  const [cancelConfirmation, setCancelConfirmation] = React.useState(false);

  const handleClose = () => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CLOSE,
      {
        sku: props.sku,
      }
    );
    props.setCancelFeedBackModal(false);
  };
  const handleNevermindEvent = () => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_NEVERMIND_QUESTIONS,
      {
        sku: props.sku,
      }
    );
    props.setCancelFeedBackModal(false);
  };
  const handleCancelSupportEvent = () => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CONTACT_SUPPORT,
      {
        sku: props.sku,
      }
    );
    props.setCancelFeedBackModal(false);
  };

  const handleContinue = (event: React.MouseEvent<HTMLButtonElement>) => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FEEDBACK_CONTINUE,
      {
        sku: props.sku,
        "Feedback Text": feedBackText,
      }
    );
    props.setCancelFeedBackModal(false);

    setCancelConfirmation(true);
  };
  const feedBackChangeEvent = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFeedBackText(event.target.value);
  };
  const classes = dialogBoxStyles();
  const { supportUrl } = config.environment;
  const feedBackDialog = (
    <SubscriptionDialog
      open={props.open}
      setClose={handleClose}
      handleCloseEvent={handleClose}
      width={600}
      isTitleLarge
      title={strings.cancel_subscription}
    >
      <div className={clsx(classes.contentAlignment)}>
        <DialogContentText
          classes={{ root: clsx(classes.popUpText) }}
          id="alert-dialog-description"
          data-testid="alert-dialog-description"
        >
          {strings.surveyTitle}
        </DialogContentText>
        <DialogContentText
          classes={{ root: clsx(classes.popUpSubText) }}
          id="alert-dialog-description"
        >
          <form>
            <div className={clsx(classes.textAreaDiv)}>
              <TextareaAutosize
                placeholder="Leave your feedback here"
                id="feebBackText"
                data-testid="feebBackText"
                className={clsx(classes.textArea)}
                style={{ width: 520, height: 280 }}
                value={feedBackText}
                onChange={feedBackChangeEvent}
              ></TextareaAutosize>
            </div>
          </form>
        </DialogContentText>

        <div className={clsx(classes.ButtonRow)}>
          <div className={clsx(classes.subFormButtonFields)}>
            <Button
              name="Continue"
              id="continue"
              type="button"
              fontFamily="Metric"
              onClick={handleContinue}
              data-testid="continue"
            />
          </div>
          <div className={clsx(classes.subFormButtonFields)}>
            <Button
              name="Never Mind"
              id="nevermind"
              type="button"
              onClick={handleNevermindEvent}
              backgroundColor={`${colors["--text-invert"]}`}
              foregroundColor={`${colors["--ui-slate"]}`}
              hoverForeground={`${colors["--text-invert"]}`}
              fontFamily="Metric"
              data-testid="nevermind"
            />
          </div>
        </div>
        <div className={clsx(classes.contactLink)}>
          <span>{strings.cancel_subText}</span>
          <Link
            classes={{
              root: clsx(classes.cancelLink),
            }}
            href={supportUrl}
            onClick={() => {
              handleCancelSupportEvent();
            }}
            data-testid="cancelSupportLink"
          >
            {strings.contact_support}
          </Link>
        </div>
      </div>
    </SubscriptionDialog>
  );
  return (
    <>
      {feedBackDialog}
      <SubscriptionCancellationConfirmation
        open={cancelConfirmation}
        setCancelConfirmation={setCancelConfirmation}
        subscriptionId={props.subscriptionId}
        cancelReasons={props.cancelReasons}
        feedBack={feedBackText}
        subscriptionEndDate={props.subscriptionEndDate}
        sku={props.sku}
      />
    </>
  );
};
