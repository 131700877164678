import axios from "axios";
import { config } from "../config/envConfig";
import { sendAmplitudeEventData } from "../utils/AmplitudeUtil";
import {
  CREATE_NEW_PASSWORD_ERROR,
  FORGOT_PASSWORD_RESET_ERROR,
  FORGOT_PASSWORD_RESET_ERROR_EVENT_ID,
} from "../utils/analytics";
import { ERROR_TIMEOUT } from "../models/ServiceErrorCodes";
import { CURRENT_USER_NAME } from "../utils/localStorageKeys";

export const passwordResetService = (request: any) => {
  const { authenticationUrl } = config.environment;

  const instance = axios.create({
    baseURL: authenticationUrl,
    timeout: 60000,
    validateStatus(status) {
      return status >= 200 && status < 500;
    },
  });

  return instance
    .post("/v1/password/reset", request)
    .then((response: any) => response)
    .catch((error: any) => {
      sendAmplitudeEventData(
        localStorage.getItem(CURRENT_USER_NAME) ||
          "passwordResetService Request Error",
        CREATE_NEW_PASSWORD_ERROR,
        FORGOT_PASSWORD_RESET_ERROR_EVENT_ID,
        "internalError",
        error?.message || error?.code,
        JSON.stringify(error)
      );
      if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
        return { status: 500, data: { ERROR_TIMEOUT } };
      }
      return error.response || { status: 500, data: {} };
    });
};
export const forgotPasswordService = (request: any) => {
  const { authenticationUrl } = config.environment;
  const instance = axios.create({
    baseURL: authenticationUrl,
    timeout: 60000,
    validateStatus(status) {
      return status >= 200 && status < 500;
    },
  });
  return instance
    .post("/v1/password/reset/email", request)
    .then((response: any) => response)
    .catch((error: any) => {
      sendAmplitudeEventData(
        localStorage.getItem(CURRENT_USER_NAME) ||
          "forgotPasswordService Request Error",
        FORGOT_PASSWORD_RESET_ERROR,
        FORGOT_PASSWORD_RESET_ERROR_EVENT_ID,
        "internalError",
        error?.message || error?.code,
        JSON.stringify(error)
      );
      if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
        return { status: 500, data: { ERROR_TIMEOUT } };
      }
      return error.response || { status: 500, data: {} };
    });
};
export const validateResetCodeService = (request: any) => {
  const { authenticationUrl } = config.environment;
  const instance = axios.create({
    baseURL: authenticationUrl,
    timeout: 60000,
    validateStatus(status) {
      return status >= 200 && status < 500;
    },
  });
  return instance
    .post("/v1/password/reset/validate", request)
    .then((response: any) => response)
    .catch((error: any) => {
      sendAmplitudeEventData(
        localStorage.getItem(CURRENT_USER_NAME) ||
          "validateResetCodeService Request Error",
        CREATE_NEW_PASSWORD_ERROR,
        FORGOT_PASSWORD_RESET_ERROR_EVENT_ID,
        "internalError",
        error?.message || error?.code,
        JSON.stringify(error)
      );
      if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
        return { status: 500, data: { ERROR_TIMEOUT } };
      }
      return error.response || { status: 500, data: {} };
    });
};
