import {
  EmailPreference,
  EmailPreferenceResponse,
} from "src/models/EmailPreference";
import {
  GET_EMAIL_PREFERENCE_SUCCESS,
  GET_EMAIL_PREFERENCE_ERROR,
  EMAIL_PREFERENCE_UPDATE_SUCCESS,
  EMAIL_PREFERENCE_UPDATE_ERROR,
  EMAIL_PREFERENCE_UPDATE_INPROGRESS,
  TIMEOUT_SNACKBAR,
} from "../actions";

interface EmailPreferenceActionType {
  type: string;
  emailPreferences?: EmailPreference[];
  responseStatus: number;
}

const initialState = {
  responseStatus: 0,
  email_preference_progress: false,
};

export function emailPreference(
  state = initialState,
  action: EmailPreferenceActionType
): EmailPreferenceResponse {
  const { emailPreferences, responseStatus, type } = action;
  switch (type) {
    case GET_EMAIL_PREFERENCE_SUCCESS:
      return {
        ...state,
        responseStatus,
        emailPreferences,
      };

    case GET_EMAIL_PREFERENCE_ERROR:
      return {
        ...state,
        responseStatus,
        emailPreferences,
      };

    case EMAIL_PREFERENCE_UPDATE_SUCCESS:
      return {
        ...state,
        responseStatus,
        email_preference_progress: false,
      };

    case EMAIL_PREFERENCE_UPDATE_ERROR:
      return {
        ...state,
        responseStatus,
        email_preference_progress: false,
      };

    case EMAIL_PREFERENCE_UPDATE_INPROGRESS:
      return { ...state, email_preference_progress: true };

    case TIMEOUT_SNACKBAR:
      return { ...state, autoHideDuration: 15000 };
    default:
      return state;
  }
}
