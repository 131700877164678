import axios from "axios";
import {
  IPaymentMethodVid,
  IPrimaryPaymentInformation,
} from "src/models/PrimaryPaymentMethodResponse";
import { config } from "../config/envConfig";
import { DEFAULT_TIMEOUT } from "../utils/AxiosUtil";
import { refreshAccess } from "../utils/cookies";
import { CURRENT_USER_ID } from "src/utils/localStorageKeys";

const { purchaseUrl } = config.environment;
const purchaseInstance = axios.create({
  baseURL: purchaseUrl,
  timeout: DEFAULT_TIMEOUT,
  validateStatus(status) {
    return status >= 200 && status < 299;
  },
});
export const associateV2Payment = async (
  request: IPaymentMethodVid
): Promise<IPrimaryPaymentInformation> => {
  const cookie = await refreshAccess();
  const userId = localStorage.getItem(CURRENT_USER_ID);
  const response = await purchaseInstance.put(
    `/v2/paymentmethods/user/${userId}`,
    request,
    {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    }
  );
  return response.data;
};
export const associatePayment = async (
  request: IPaymentMethodVid
): Promise<void> => {
  const cookie = await refreshAccess();
  const userId = localStorage.getItem(CURRENT_USER_ID);
  const response = await purchaseInstance.put(
    `/v1/paymentmethods/user/${userId}`,
    request,
    {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    }
  );
  return response.data;
};
export const getPrimaryPaymentMethod = async (
  userId: number,
  isDeviceEnabled?: boolean
): Promise<IPrimaryPaymentInformation> => {
  const cookie = await refreshAccess(isDeviceEnabled);
  const response = await purchaseInstance.get(
    `/v1/paymentmethods/primary/user/${userId}`,
    {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    }
  );
  return response.data;
};
