import { OptimizelyDecision, useDecision } from "@optimizely/react-sdk";
import React, {
  MutableRefObject,
  createContext,
  useRef,
  useState,
} from "react";
import { Product } from "src/services/subscriptionService";
import { deviceManagementEnabledFlag } from "src/utils/optimizelyFlag";

export enum PageType {
  ManageAccount = "ManageAccount",
  SelectSubscription = "SelectSubscription",
  PaymentBilling = "PaymentBilling",
  PaymentConfirmation = "PaymentConfirmation",
  SubLandingPage = "SubLandingPage",
}

export interface SubscriptionContextProps {
  isPlusUser: boolean;
  setPlusUser: React.Dispatch<React.SetStateAction<boolean>>;
  showSelection: boolean;
  setShowSelection: React.Dispatch<React.SetStateAction<boolean>>;
  currentPage: PageType;
  setCurrentPage: React.Dispatch<React.SetStateAction<PageType>>;
  deviceManagementDecision: OptimizelyDecision | undefined;
  sourceType: string;
  userNavigation: PageType[];
  setUserNavigation: React.Dispatch<React.SetStateAction<PageType[]>>;
  initialProductSku: MutableRefObject<Product | undefined>;
}

export const SubscriptionContext = createContext<SubscriptionContextProps>({
  isPlusUser: false,
  setPlusUser: () => {
    // Intentionally left blank
  },
  showSelection: true,
  setShowSelection: () => {
    // Intentionally left blank
  },
  currentPage: PageType.ManageAccount,
  setCurrentPage: () => {
    // Intentionally left blank
  },
  deviceManagementDecision: undefined,
  sourceType: "Account Page",
  userNavigation: [],
  setUserNavigation: () => {
    // Intentionally left blank
  },
  initialProductSku: {
    current: undefined,
  },
});

export const SubscriptionContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isPlusUser, setPlusUser] = useState(false);
  const [showSelection, setShowSelection] = useState(true);
  const [currentPage, setCurrentPage] = useState(PageType.ManageAccount);
  const [deviceManagementDecision] = useDecision(deviceManagementEnabledFlag);
  const initialProductSku = useRef<Product | undefined>();
  const queryParams = new URLSearchParams(window.location.search);
  const sourceType = queryParams.has("upsellSku")
    ? "Upsell Screen"
    : "Account Page";
  const [userNavigation, setUserNavigation] = useState<PageType[]>([]);
  return (
    <SubscriptionContext.Provider
      value={{
        isPlusUser,
        setPlusUser,
        showSelection,
        setShowSelection,
        currentPage,
        setCurrentPage,
        deviceManagementDecision,
        sourceType,
        userNavigation,
        setUserNavigation,
        initialProductSku,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
