import * as React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Checkbox from "@material-ui/core/Checkbox";
import { colors } from "../../config/colorConfig";
import { checkboxBackgroundImage } from "../../config/commonStyleConfig";

export const CheckBox = (props: CheckBoxProperties) => {
  const styles = makeStyles({
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: 3,
      width: 20,
      height: 20,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: colors["--icon-background-color"],
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: colors["--background-hover"],
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: colors["--background"],
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 20,
        height: 20,
        backgroundImage: checkboxBackgroundImage,
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: colors["--input-background-hover"],
      },
    },
  });

  const classes = styles(props);
  return (
    <Checkbox
      className={classes.root}
      name={props.name}
      checked={props.checked}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      onChange={props.onChange}
    />
  );
};

export interface CheckBoxProperties {
  name: string;
  labelPlacement: string;
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}
