import React from "react";
import { makeStyles, Typography, Paper, Box } from "@material-ui/core";
import clsx from "clsx";
import { ApplicationAltTitle } from "./ApplicationAltTitle";
import { colors } from "src/config/colorConfig";

export const LoginPasswordPageBody = (props: any) => {
  const bodyWrapper = makeStyles((theme) => ({
    box: {
      marginTop: "64px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(8)}px ${theme.spacing(12)}px ${theme.spacing(
        12
      )}px`,
      [theme.breakpoints.down("xs")]: {
        padding: `${theme.spacing(8)}px ${theme.spacing(4)}px ${theme.spacing(
          12
        )}px`,
        width: "100%",
      },
      flexGrow: 1,
    },
    paper: {
      [theme.breakpoints.up("xs")]: {
        width: "450px",
      },
      marginTop: theme.spacing(16),
      display: "flex",
      flexDirection: "column",
      padding: "40px",
      [theme.breakpoints.down("xs")]: {
        padding: `${theme.spacing(8)}px ${theme.spacing(4)}px ${theme.spacing(
          10
        )}px`,
        width: "100%",
      },
    },
    title: {
      paddingBottom: props.titleBottomSpace
        ? theme.spacing(props.titleBottomSpace)
        : theme.spacing(4),
      color: `${colors["--primary-midnight"]}`,
      fontWeight: 600,
      fontSize: 24,
      textAlign: props.titleAlign ? props.titleAlign : "center",
      fontFamily: "Source Sans Pro",
    },
    failure: {
      alignContent: "left",
      color: "#ff0000",
    },
  }));
  const classes = bodyWrapper(props);
  const validationErrors = (
    <>
      <Typography
        data-testid="errorMessage"
        variant="body2"
        className={clsx(classes.failure)}
      >
        {props.validationError}
      </Typography>
    </>
  );
  return (
    <Box
      className={clsx(classes.box)}
      zIndex="drawer"
      data-testid="loginpasswordpagebody"
    >
      <ApplicationAltTitle />
      <Paper className={clsx(classes.paper)} elevation={0}>
        <Typography
          data-testid="title"
          variant="h6"
          className={clsx(classes.title)}
        >
          {props.title}
        </Typography>
        {props.titleText ? props.titleText : null}
        {props.validationError ? validationErrors : null}
        {props.formBody}
        {props.additionalInfo ? props.additionalInfo : null}
        {props.cmeAccountRegistrationInfo
          ? props.cmeAccountRegistrationInfo
          : null}
      </Paper>
    </Box>
  );
};
