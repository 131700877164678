import { useEffect } from "react";
import withWidth from "@material-ui/core/withWidth";
import { colors } from "../config/colorConfig";
import { withRoot } from "../withRoot";
import { strings } from "../utils/Strings";
import keyImage from "../images/key.svg";
import clsx from "clsx";
import { makeStyles, Typography } from "@material-ui/core";
import {
  getToolsAndLocation,
  sendAmplitudeEvent,
} from "src/utils/AmplitudeUtil";
import {
  Authentication_Landing_Screen_Email_Sign_In_Click,
  Authentication_Landing_Screen_OTC_Sign_In_Click,
  CLICK_CREATE_ACCOUNT,
  CLICK_SIGN_IN,
} from "src/utils/analytics";
import { Link } from "react-router-dom";
import { SnackBarType } from "src/utils/SnackBarType";
import { sendSuccessSnackBarMessage } from "src/actions/snackBarActions";
import { store } from "src/store/configureStore";
import { generateProfileSignoutConfirmedAmplitude } from "src/utils/cookies";

const QRSignInForm = () => {
  const style = makeStyles((theme) => ({
    signInSection: {
      "& > a, & > div": {
        marginBottom: theme.spacing(6),
      },
    },
    signInButtonLink: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "48px",
      width: "100%",
      fontFamily: "Source Sans Pro",
      fontWeight: 600,
      fontSize: 18,
      borderRadius: 3,
      textTransform: "none",
      cursor: "pointer",
      backgroundColor: colors["--text-invert"],
      textDecoration: "none",
      color: colors["--primary-midnight"],
      border: `solid 1px ${colors["--primary-midnight"]}`,
      boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
      },
      "&:hover": {
        textDecoration: "none",
        color: "inherit",
      },
    },
    signInWithEmailButton: {
      backgroundColor: colors["--primary-tealnight"],
      color: colors["--text-invert"],
      border: "none",
      "&:hover": {
        color: colors["--text-invert"],
      },
    },
    key: {
      marginRight: "8px",
      marginLeft: "8px",
    },
    borderText: {
      borderTop: `solid 1px ${colors["--ui-gray"]}`,
      height: "10px",
      width: "100%",
      position: "relative",
      marginTop: "32px",
      "&:before": {
        content: '"or"',
        fontSize: 18,
        fontWeight: 600,
        color: colors["--ui-gray"],
        padding: "4px",
        display: "block",
        margin: "auto",
        textAlign: "center",
        marginTop: "-2px",
        position: "absolute",
        top: "-15px",
        background: colors["--text-invert"],
        left: "50%",
        marginLeft: "-15px",
      },
    },
    createAccountSection: {
      "& > p, & > a , & > div": {
        marginBottom: theme.spacing(6),
      },
    },
    box: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px ${theme.spacing(
        6
      )}px`,
      width: "360px",
    },
    paper: {
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.up("md")]: {
        width: "50%",
      },
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing(8)}px ${theme.spacing(14)}px ${theme.spacing(
        10
      )}px`,
    },
    title: {
      color: colors["--title"],
      fontFamily: "Source Sans Pro",
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "32px",
      textAlign: "center",
      marginBottom: theme.spacing(6),
    },
  }));
  const classes = style();
  const toolsLocationObj = getToolsAndLocation();

  const onSignInView = () => {
    const eventProperties = {
      Location: toolsLocationObj?.location,
      Tools: toolsLocationObj?.tools,
    };
    sendAmplitudeEvent(CLICK_SIGN_IN, eventProperties);
  };
  const queryParams = new URLSearchParams(window.location.search);
  const referNext = queryParams.get("refernext") ?? undefined;
  const createAccountURL = referNext
    ? `/createaccount?refernext=${referNext}`
    : "/createaccount";

  useEffect(() => {
    onSignInView();

    // To handle token failure from EOL due to max device
    // This session value is set by EOL on token failure due to device limit
    const logoutReason = sessionStorage.getItem("logoutReason");
    if (logoutReason === "MaxDeviceLimitExceeded") {
      const message = strings.device_forced_sign_out;
      const category = SnackBarType.INFO.displayValue;
      const title = strings.sign_out_title;
      store.dispatch(sendSuccessSnackBarMessage(title, message, category));
      sessionStorage.removeItem("logoutReason");
      generateProfileSignoutConfirmedAmplitude("Automatic Signout");
    }
  }, []);

  const onSignInWithOtcClick = () => {
    const eventProperties = {
      Location: toolsLocationObj?.location,
      Tools: toolsLocationObj?.tools,
    };
    sendAmplitudeEvent(
      Authentication_Landing_Screen_OTC_Sign_In_Click,
      eventProperties
    );
  };

  const onSignInWithEmailClick = () => {
    const eventProperties = {
      Location: toolsLocationObj?.location,
      Tools: toolsLocationObj?.tools,
    };
    sendAmplitudeEvent(
      Authentication_Landing_Screen_Email_Sign_In_Click,
      eventProperties
    );
  };

  const onCreateAccountClick = () => {
    const eventProperties = {
      Location: "Sign in landing screen",
    };
    sendAmplitudeEvent(CLICK_CREATE_ACCOUNT, eventProperties);
  };

  const signInSection = (
    <div className={clsx(classes.signInSection)}>
      <Link
        className={clsx(
          classes.signInButtonLink,
          classes.signInWithEmailButton
        )}
        to={`/login/email${window.location.search}`}
        data-testid="signinwithemail"
        rel="noreferrer"
        onClick={onSignInWithEmailClick}
      >
        {strings.sign_in_with_email}
      </Link>
      <div>
        <a
          className={clsx(classes.signInButtonLink)}
          href={`/login/otc${window.location.search}`}
          data-testid="signinwithotc"
          onClick={onSignInWithOtcClick}
        >
          <img
            className={clsx(classes.key)}
            data-testid="key"
            src={keyImage}
            alt=""
          />
          {strings.sign_in_with_otp}
        </a>
      </div>
    </div>
  );

  const createAccountSection = (
    <div className={clsx(classes.createAccountSection)}>
      <div className={clsx(classes.borderText)}></div>
      <Link
        className={clsx(classes.signInButtonLink)}
        to={createAccountURL}
        onClick={onCreateAccountClick}
        data-testid="createaccountbutton"
        rel="noreferrer"
      >
        {strings.create_account}
      </Link>
    </div>
  );

  const bodyTitle = strings.sign_in_title;
  return (
    <>
      <Typography
        data-testid="title"
        variant="h6"
        className={clsx(classes.title)}
      >
        {bodyTitle}
      </Typography>
      {signInSection}
      {createAccountSection}
    </>
  );
};

export const QRSignInFormWith = withWidth()(withRoot(QRSignInForm));
