import React from "react";
import { makeStyles } from "@material-ui/core";
import { colors } from "../../config/colorConfig";

interface DividerProps {
  children: React.ReactNode;
}

export const Divider = (props: DividerProps) => {
  const styles = makeStyles(() => ({
    dividerContainer: {
      display: "flex",
      alignItems: "center",
    },
    dividerBoder: {
      borderBottom: `1px solid ${colors["--ui-gray"]}`,
      width: "100%",
    },
    dividerContent: {
      fontWeight: 600,
      fontSize: 18,

      padding: "0 8px 0 8px",
      color: colors["--ui-gray"],
    },
  }));
  const classes = styles(props);
  return (
    <div className={classes.dividerContainer}>
      <div className={classes.dividerBoder} />
      <span className={classes.dividerContent}>{props.children}</span>
      <div className={classes.dividerBoder} />
    </div>
  );
};
