import axios from "axios";
import { config } from "../config/envConfig";
import { refreshAccess } from "../utils/cookies";

const { profileUrl, cmeApiUrl } = config.environment;

export const demographicsService = (path: string): any => {
  const instance = axios.create({
    baseURL: profileUrl,
    timeout: 30000,
  });

  return instance
    .get(path)
    .then((response: any) => response)
    .catch((error: any) => {
      console.log(error);
      return error.response || { status: 500, data: {} };
    });
};

export const degreesService = async (): Promise<any> => {
  const instance = axios.create({
    baseURL: cmeApiUrl,
    timeout: 30000,
  });
  const cookie = await refreshAccess();

  return instance
    .get("/demographics/degrees", {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    })
    .then((response: any) => response)
    .catch((error: any) => {
      console.log(error);
      return error.response || { status: 500, data: {} };
    });
};
