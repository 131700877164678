import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
  ProfileEmailResponse,
  verifyEmailAddress,
} from "src/services/accountProfileService";

export const VerifyEmailQuery = (
  emailAddress: string
): UseQueryResult<ProfileEmailResponse> =>
  useQuery<ProfileEmailResponse>(
    ["emailAddress", { emailAddress }],
    () => verifyEmailAddress(emailAddress),
    {
      enabled: emailAddress !== "",
      retry: 4,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
