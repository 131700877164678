import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  makeStyles,
  Theme,
  FormHelperText,
} from "@material-ui/core";
import clsx from "clsx";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { colors } from "../../config/colorConfig";

interface State {
  password: string;
  showPassword: boolean;
  validationError: string;
  isError: boolean;
}

const PasswordField = (props: any) => {
  const passwordFieldStyle = makeStyles((theme: Theme) => ({
    textField: {
      width: "100%",
      color: colors["--ui-slate"],
    },
    label: {
      color: colors["--ui-grey-semi"],
    },
    input: {
      paddingBottom: "1px",
      marginBottom: "1px",
      color: colors["--ui-slate"],
    },
    iconButtonRoot: {
      color: colors["--ui-slate"],
    },
    iconButtonDisabled: {
      color: "grey",
    },
  }));
  const classes = passwordFieldStyle();
  const [values, setValues] = React.useState({
    showPassword: false,
    password: "",
    validationError: "",
    isError: false,
  });

  React.useEffect(() => {
    if (props.validationError) {
      setValues({
        ...values,
        validationError: props.validationError,
        isError: true,
      });
    } else {
      setValues({
        ...values,
        validationError: "",
        isError: false,
      });
    }
  }, [props.validationError]);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      // If there an input validation
      if (props.validateInput) {
        const onChangeValidateInput = props.validateInput;
        const validationMsg = onChangeValidateInput(event.target.value);
        if (validationMsg) {
          setValues({
            ...values,
            validationError: validationMsg,
            password: event.target.value,
            isError: true,
          });
        } else {
          setValues({
            ...values,
            validationError: "",
            password: event.target.value,
            isError: false,
          });
        }
      } else {
        setValues({ ...values, password: event.target.value });
      }
    };
  const handleKeyUpEvent = () => {
    if (props.validateInput) {
      const onChangeValidateInput = props.validateInput;
      const validationMsg = onChangeValidateInput(values.password);
      if (validationMsg) {
        setValues({
          ...values,
          validationError: validationMsg,
          isError: true,
        });
      } else {
        setValues({
          ...values,
          validationError: "",
          isError: false,
        });
      }
    }
  };
  React.useEffect(() => {
    if (props.defaultValue) {
      setValues({
        ...values,
        password: props.defaultValue,
      });
      handleChange(props.name);
    }
  }, []);
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <FormControl
        variant="outlined"
        required
        fullWidth
        className={clsx(classes.input, props.customClass ?? "")}
      >
        {props.isNewDesign ? null : (
          <InputLabel
            data-testid={props.labelTestId}
            htmlFor={props.labelHtmlFor}
            color="primary"
            className={clsx(classes.label)}
          >
            {props.labelTitle}
          </InputLabel>
        )}
        <OutlinedInput
          error={values.isError}
          id={props.name}
          data-testid={props.name}
          name={props.name}
          inputProps={{
            classes: { input: classes.input },
            maxLength: 100,
            "data-testid": props.inputTestId,
            "aria-label": props.name,
          }}
          placeholder={props.placeholerText}
          className={clsx(classes.textField)}
          onClick={props.onClickEvent}
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          onKeyUp={handleKeyUpEvent}
          onChange={handleChange(props.name)}
          onPaste={(event) => event.preventDefault()}
          autoComplete={props.noAutoComplete ? "new-password" : "on"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disabled={!values.password && !values.isError}
                color={!values.isError ? "primary" : "secondary"}
                classes={{
                  root: "icon-button-root",
                  disabled: "icon-button-disabled",
                }}
                aria-label={props.ariaLabel}
                onClick={handleClickShowPassword}
                data-testid={props.iconTestId}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={props.labelWidth}
        />
      </FormControl>
      <FormHelperText
        data-testid={props.helpTestId}
        style={{
          color: colors["--failure"],
          paddingBottom: "1px",
          paddingTop: "1px",
          paddingLeft: "14px",
          marginTop: "1px",
        }}
      >
        {values.validationError}
      </FormHelperText>
    </>
  );
};
export const Password = React.memo(PasswordField);
