import { strings } from "../utils/Strings";
import { config } from "./envConfig";

export interface SidebarLinkType {
  id: string;
  name: string;
  showUpgrade?: boolean;
  display?: boolean;
}

export const leftSidebarLinks: Array<SidebarLinkType> = [
  {
    id: "profile",
    name: strings.profile,
    display: true,
  },
  {
    id: "subscription",
    name: strings.paymentSubscription,
    showUpgrade: true,
    display: true,
  },
  {
    id: "email",
    name: strings.emailPreferences,
    display: config.environment.isEmailPreferenceEnabled,
  },
  {
    id: strings.devices_tab,
    name: strings.devices,
    display: true,
  },
];
