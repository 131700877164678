import { formatToDollar } from "../utils/payment";
import { LicensePurchaseResponse } from "../services/licenseService";
import { OrderSummaryWindow, TableRowData } from "./OrderSummaryWindow";

interface LicensePaymentSummaryProps {
  purchaseQuantity: number;
  calculateLicenseResponse: LicensePurchaseResponse | undefined;
  hideDisplayTitle?: boolean;
}
export const LicensePaymentSummary = (
  props: LicensePaymentSummaryProps
): JSX.Element => {
  const mainArrayData: Array<TableRowData> = [];

  mainArrayData.push({
    label: `Yearly subscriptions (${props.purchaseQuantity}):`,
    value: formatToDollar(props.calculateLicenseResponse?.data?.msrpTotal),
  });
  mainArrayData.push({
    label: `Your savings (${props.calculateLicenseResponse?.data?.promoPercentageOff}% discount):`,
    value: `-${formatToDollar(
      props.calculateLicenseResponse?.data?.promoDiscount
    )}`,
  });
  mainArrayData.push({
    label: `Total before tax:`,
    value: formatToDollar(props.calculateLicenseResponse?.data?.subtotal),
  });
  mainArrayData.push({
    label: `Estimated tax collected:`,
    value: formatToDollar(props.calculateLicenseResponse?.data?.tax),
  });

  const summaryArrayData: Array<TableRowData> = [];
  summaryArrayData.push({
    label: `Order total:`,
    value: formatToDollar(props.calculateLicenseResponse?.data?.total),
  });

  const orderProps = {
    displayDiscountBar: false,
    mainTableArray: mainArrayData,
    summaryTableArray: summaryArrayData,
    hideDisplayTitle: props.hideDisplayTitle ?? false,
  };
  return OrderSummaryWindow(orderProps);
};
