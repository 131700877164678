import { makeStyles } from "@material-ui/core";
import { colors } from "../config/colorConfig";

export const licenseStyles = makeStyles((theme) => ({
  contentWrapper: {
    margin: ` ${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  cardSubTitle: {
    fontSize: 20,
    fontWeight: 700,
    margin: ` ${theme.spacing(2)}px 0px`,
  },
  primaryMidnight: {
    color: `${colors["--primary-midnight"]}`,
  },
  uiGray: {
    color: `${colors["--ui-gray"]}`,
  },
  primaryOrange: {
    color: `${colors["--primary-orange"]}`,
  },
  contentBorder: {
    width: "488px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  splitPmtField: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexSpaceOnCardItems: {
    flexBasis: "calc(50% - 8px)",
  },
  pmtButtonDiv: {
    display: "flex",
    justifyContent: "left",
    margin: `${theme.spacing(4)}px 0px`,
    gap: "48px",
  },
  asterisk: {
    marginRight: 4,
    top: -10,
    left: -10,
  },
  requiredField: {
    fontSize: 12,
    fontWeight: 600,
  },
  vindiciaLableMargin: {
    marginTop: `${theme.spacing(2)}px`,
  },
  overlay: {
    zIndex: 1401,
  },

  box: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px ${theme.spacing(
      6
    )}px`,
    [theme.breakpoints.down("md")]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(
        6
      )}px`,
    },
  },
  paper: {
    padding: "40px 0",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "24px 0",
    },
    [theme.breakpoints.up("md")]: {
      width: 350,
      height: 240,
    },
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    paddingBottom: theme.spacing(8),
    color: colors["--title"],
    fontWeight: 300,
    fontSize: 24,
    textAlign: "center",
  },
  contentWrapper1: {
    display: "flex",
  },
  loginFormWrapper: {
    width: "40%",
    padding: "0 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0 16px",
    },
  },
  billingWrapper: {
    height: 460,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fontWeight400: {
    fontWeight: 400,
  },
  fontSize24: {
    fontSize: 24,
    lineHeight: "32px",
  },
}));
