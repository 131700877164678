import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import clsx from "clsx";
import { ClipLoader } from "react-spinners";
import Typography from "@material-ui/core/Typography";
import { colors } from "../config/colorConfig";
import { setCookie } from "../utils/cookies";
import { Footer } from "./custom/Footer";
import { LoginPasswordPageBody } from "./custom/LoginPasswordPageBody";
import { Button } from "./custom/Button";
import "../styles/style.css";
import { config } from "../config/envConfig";

export const ProgressPage = (props: any) => {
  const { inProgress, bodyTitle } = props;
  useEffect(() => {
    setCookie("sessionTimer", 24, "sessionTimer");
  });

  const style = makeStyles((theme) => ({
    form: {
      width: "100%",
    },
  }));

  const handleSubmit = async (formEvent: any): Promise<void> => {
    formEvent.preventDefault();

    if (config.environment.legacyLoginToggle) {
      const url = `${config.environment.baseEpocratesUrl}/account.do`;
      window.location.replace(url);
    } else {
      props.history.push("/login");
    }
  };

  const classes = style(props);

  const form = (
    <form className={clsx(classes.form)} onSubmit={handleSubmit}>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        data-testid="continue"
        color="primary"
        id="continue"
        name="Back to Login"
        backgroundColor={colors["--primary-tealnight"]}
        height={48}
        fontFamily={"Source Sans Pro"}
        fontSize={18}
        hoverForeground={colors["--primary-tealnight"]}
      />
    </form>
  );
  const inProgressContent = (
    <>
      <div style={{ paddingBottom: "24px", alignSelf: "center" }}>
        <ClipLoader
          data-testid="progressValidationSpinner"
          size="45px"
          color={colors["--accent-violet"]}
          loading={inProgress}
        />
      </div>
    </>
  );
  const messageContent = (
    <>
      <div style={{ paddingBottom: "24px" }}>
        <Typography
          data-testid="progressValidationMessage"
          variant="body1"
          line-height={1.25}
        >
          Unexpected error, please try again.
        </Typography>
      </div>
    </>
  );
  return (
    <>
      <LoginPasswordPageBody
        data-testid="progressValidationBody"
        title={bodyTitle}
        titleText={inProgress ? inProgressContent : messageContent}
        formBody={form}
      />
    </>
  );
};
