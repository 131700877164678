import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import {
  oneTimeCodeService,
  verifyOneTimeCodeService,
} from "src/services/authenticationService";

export const OneTimeCodeQuery = (
  email: string
): UseQueryResult<AxiosResponse> => {
  return useQuery<AxiosResponse>(
    ["oneTimeCode", { email }],
    () => oneTimeCodeService(email),
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const VerifyOneTimeCodeQuery = (
  email: string,
  otc: string
): UseQueryResult<AxiosResponse> => {
  return useQuery<AxiosResponse>(
    ["oneTimeCode", { email, otc }],
    () => verifyOneTimeCodeService(email, otc),
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
