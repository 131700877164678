import {
  Avatar,
  Card,
  CardHeader,
  Collapse,
  Link,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import { useContext } from "react";
import { colors } from "src/config/colorConfig";
import { PageType, SubscriptionContext } from "src/context/SubscriptionContext";
import { licenseStyles } from "src/styles/licenseStyles";
import { licenseTheme } from "src/styles/licenseTheme";

interface CardProps {
  title: string;
  number?: number;
  children?: React.ReactElement;
  expanded: boolean;
  arrowIcon?: boolean;
  editAction?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiCard-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiCardHeader-root": {
      padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    },
  },
  card: {
    textAlign: "left",
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.up("lg")]: {
      width: "760px",
    },
    [theme.breakpoints.down(600)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  ellipseNumber: {
    background: colors["--primary-off-white"],
    color: colors["--primary-midnight"],
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 32,
    fontFamily: "PT Serif",
  },
  title: {
    color: colors["--ui-gray"],
    fontSize: 24,
    fontWeight: 600,
  },
  supportLink: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24,
    textDecoration: "underline",
    "&:hover": {
      color: colors["--primary-tealnight"],
    },
    color: colors["--primary-tealnight"],
  },
}));
export const CollapsibleCard: React.FC<CardProps> = (props: CardProps) => {
  const classes = useStyles(licenseTheme);
  const clx = licenseStyles(licenseTheme);
  const { setCurrentPage } = useContext(SubscriptionContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          props.number && (
            <Avatar aria-label="recipe" className={classes.ellipseNumber}>
              {props.number}
            </Avatar>
          )
        }
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "40px",
              alignItems: "center",
            }}
          >
            <Typography className={classes.title}>{props.title} </Typography>
            {props.number &&
              (!props.expanded ? (
                <Link
                  className={clsx(classes.supportLink)}
                  href="#"
                  rel="noreferrer"
                  id="editForm"
                  tabIndex={0}
                  data-testid={`editFormLink-${props.number}`}
                  onClick={props.editAction}
                >
                  Edit
                </Link>
              ) : (
                !isMobile && (
                  <div>
                    <Typography className={clsx(clx.requiredField, clx.uiGray)}>
                      {" "}
                      <span className={clsx(clx.asterisk, clx.primaryOrange)}>
                        *
                      </span>{" "}
                      Indicates a required field
                    </Typography>
                  </div>
                )
              ))}
          </div>
        }
      />
      <Collapse in={props.expanded}>{props.children}</Collapse>
    </Card>
  );
};
