export function isOfType<T>(obj: any, properties: Array<keyof T>): obj is T {
  if (obj === null || obj === undefined) {
    return false;
  }
  let isType = true;
  properties?.forEach((property) => {
    isType &&= (obj as T)[property] !== undefined;
  });
  return isType;
}
