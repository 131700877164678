import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { setCookie } from "../utils/cookies";
import { LoginPasswordPageBody } from "./custom/LoginPasswordPageBody";
import { Button } from "./custom/Button";
import "../styles/style.css";
import { config } from "../config/envConfig";
import { environment } from "../utils/environment";
import { colors } from "src/config/colorConfig";

interface State {
  counter: number;
}

export const ErrorPage = (props: any) => {
  const { errorMessage, bodyTitle } = props;
  const [values, setValues] = React.useState<State>({
    counter: 0,
  });
  function Bomb() {
    setValues({ counter: 0 });
    throw new Error("💥 CABOOM 💥");
  }
  const handleClick = () => {
    setValues({ counter: values.counter + 1 });
  };
  if (values.counter === 2) {
    Bomb();
  }
  useEffect(() => {
    setCookie("sessionTimer", 24, "sessionTimer");
  });

  const style = makeStyles((theme) => ({
    form: {
      width: "100%",
    },
  }));

  const handleSubmit = async (formEvent: any): Promise<void> => {
    formEvent.preventDefault();

    if (config.environment.legacyLoginToggle) {
      const url = `${config.environment.baseEpocratesUrl}/account.do`;
      window.location.replace(url);
    } else {
      props.history.push("/login");
    }
  };

  const classes = style(props);

  const showButton =
    environment() !== "PROD" ? (
      <button
        data-testid="testErrorHandling"
        type="submit"
        onClick={handleClick}
      >
        {values.counter}
      </button>
    ) : (
      ""
    );
  const form = (
    <>
      <form className={clsx(classes.form)} onSubmit={handleSubmit}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          data-testid="continue"
          color="primary"
          id="continue"
          name="Back to Login"
          backgroundColor={colors["--primary-tealnight"]}
          height={48}
          fontFamily={"Source Sans Pro"}
          fontSize={18}
          hoverForeground={colors["--primary-tealnight"]}
        />
      </form>
      {showButton}
    </>
  );
  const messageContent = (
    <>
      <div style={{ paddingBottom: "24px" }}>
        <Typography
          data-testid="validationErrorMessage"
          variant="body1"
          line-height={1.25}
          style={{ width: "100%" }}
        >
          {errorMessage}
        </Typography>
      </div>
    </>
  );
  return (
    <>
      <LoginPasswordPageBody
        data-testid="validationErrorBody"
        title={bodyTitle}
        titleText={messageContent}
        formBody={form}
      />
    </>
  );
};
