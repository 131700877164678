import { CountryTuple } from "./CountryTuple";

export interface AccountCreationFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  occupation: Occupation;
  country: CountryTuple;
  zipCode?: string;
  graduationYear?: number;
  testFormValues?: boolean;
}
export interface Occupation {
  id: number;
  text: string;
}

export function isOccupation(obj: unknown): obj is Occupation {
  const occ = obj as Occupation;
  if (occ === null || occ === undefined) {
    return false;
  }
  return typeof occ.id === "number" && typeof occ.text === "string";
}
