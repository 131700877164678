import { makeStyles, Typography, Paper, Box } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../../../src/config/colorConfig";

interface QRLoginPasswordPageBodyProps {
  titleBottomSpace?: number;
  titleAlign?: string;
  title: string;
  titleText?: string;
  progressButton: boolean;
  formBody: string | JSX.Element;
  additionalInfo: string | JSX.Element;
  validationError?: string | JSX.Element;
}

export const QRLoginPasswordPageBody = (
  props: QRLoginPasswordPageBodyProps
) => {
  const bodyWrapper = makeStyles((theme) => ({
    box: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px ${theme.spacing(
        6
      )}px`,
    },
    paper: {
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
      [theme.breakpoints.up("md")]: {
        width: "80%",
      },
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing(8)}px ${theme.spacing(14)}px ${theme.spacing(
        10
      )}px`,
    },
    title: {
      paddingBottom: props.titleBottomSpace
        ? theme.spacing(props.titleBottomSpace)
        : theme.spacing(3),
      color: colors["--title"],
      fontFamily: "Source Sans Pro",
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "32px",
      textAlign: "center",
    },
    failure: {
      alignContent: "left",
      color: "#ff0000",
    },
  }));
  const classes = bodyWrapper();
  const validationErrors = (
    <>
      <Typography
        data-testid="errorMessage"
        variant="body2"
        className={clsx(classes.failure)}
      >
        {props.validationError}
      </Typography>
    </>
  );
  return (
    <>
      <Typography
        data-testid="title"
        variant="h6"
        className={clsx(classes.title)}
      >
        {props.title}
      </Typography>
      {props.titleText ? props.titleText : null}
      {props.validationError ? validationErrors : null}
      {props.formBody}
      {props.additionalInfo ? props.additionalInfo : null}
    </>
  );
};
