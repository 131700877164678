import { put, call, CallEffect, PutEffect } from "redux-saga/effects";
import { AxiosPromise, AxiosResponse } from "axios";
import * as types from "../actions";
import { SnackBarType } from "../utils/SnackBarType";
import {
  accountProfileService,
  updateAccountProfileService,
} from "../services/accountProfileService";
import { AccountProfileResponse } from "../models/AccountProfileResponse";
import { httpConstants } from "../utils/httpConstants";
import { strings } from "../utils/Strings";
import { AccountUpdateRequest } from "../models/AccountUpdateRequest";
import {
  ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER,
  ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER_EVENT_ID,
} from "../utils/analytics";
import {
  sendAmplitudeEvent,
  updateUserPropertiesForSegment,
} from "../utils/AmplitudeUtil";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";

export interface UpdateSagaReq {
  type: string;
  updateRequest: AccountUpdateRequest;
}

export function* getUserDetailSaga(isDeviceEnabled?: boolean): Generator<
  | CallEffect<AxiosPromise<any>>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  try {
    yield put({ type: types.RETRIEVE_USER_INPROGRESS });
    const response = yield call(accountProfileService, { isDeviceEnabled });
    let accountProfileResponse: AccountProfileResponse;
    if (!response) {
      yield put({ type: types.RETRIEVE_USER_SYSTEM_ERROR });
      return;
    }
    if (typeof response.data === "object") {
      accountProfileResponse = response.data;
    } else {
      accountProfileResponse = {} as any;
    }
    accountProfileResponse.status = response.status;
    accountProfileResponse.done = false;

    if (response.status === httpConstants.OK) {
      const userId = localStorage.getItem("currentUserId");
      updateUserPropertiesForSegment(
        userId,
        accountProfileResponse.userInformation
      );
      yield put({ type: types.RETRIEVE_USER_SUCCESS, accountProfileResponse });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    } else if (response.status === httpConstants.UNAUTHORIZED) {
      yield put({ type: types.REQUIRE_USER_MANUAL_LOGIN });
    } else if (
      response.status >= httpConstants.BAD_REQUEST &&
      response.status < httpConstants.INTERNAL_SERVER_ERROR
    ) {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: "Profile not found",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.RETRIEVE_USER_ERROR, accountProfileResponse });
    } else {
      yield put({
        type: types.RETRIEVE_USER_SYSTEM_ERROR,
        accountProfileResponse,
      });
    }
  } catch (error) {
    yield put({ type: types.RETRIEVE_USER_ERROR, error });
  }
}

export function* updateUserDetailSaga(request: UpdateSagaReq): Generator<
  | CallEffect<AxiosResponse<any>>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  try {
    yield put({ type: types.UPDATE_USER_INPROGRESS });

    const response = yield call(updateAccountProfileService, request);
    if (!response) {
      yield put({ type: types.UPDATE_SYSTEM_ERROR });
      return;
    }
    if (response.status === httpConstants.OK) {
      yield put({
        type: types.UPDATE_USER_SUCCESS,
        accountUpdateResponse: response,
      });
      yield put({ type: types.TIMEOUT_SNACKBAR });
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: strings.profile_update_success,
        messageType: SnackBarType.SUCCESS.displayValue,
      });
      const bannerEventProperties = {
        "Event ID": ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER_EVENT_ID,
        "Banner Type": "Profile",
      };
      sendAmplitudeEvent(
        ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER,
        bannerEventProperties,
        localStorage.getItem(CURRENT_USER_ID)
      );
    } else if (response.status === httpConstants.UNAUTHORIZED) {
      yield put({ type: types.REQUIRE_USER_MANUAL_LOGIN });
    } else if (
      response.status >= httpConstants.BAD_REQUEST &&
      response.status < httpConstants.INTERNAL_SERVER_ERROR
    ) {
      yield put({
        type: types.UPDATE_USER_ERROR,
        accountUpdateError: response,
      });
    } else {
      yield put({
        type: types.UPDATE_SYSTEM_ERROR,
        accountUpdateError: response,
      });
    }
  } catch (error) {
    yield put({ type: types.UPDATE_USER_ERROR, accountUpdateError: error });
  }
}
