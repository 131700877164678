import React, { useEffect } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { colors } from "src/config/colorConfig";
import clsx from "clsx";

interface State {
  validationError: string;
  isError: boolean;
}

export const negativeToEmpty = (value: any) => {
  if (!value) {
    return "";
  }
  if (typeof value === "number" && value < 0) {
    return "";
  }
  return value;
};
export const DropDownMenu = (props: any) => {
  const style = makeStyles((theme) => ({
    formControl: {
      marginBottom: props.noBottomMargin ? 0 : theme.spacing(4),
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
    label: {
      color: colors["--ui-grey-semi"],
    },
  }));
  const classes = style(props);
  const [values, setValues] = React.useState<State>({
    validationError: "",
    isError: false,
  });
  const validateSelect = (event: any) => {
    const { validation } = props;
    if (props.validation) {
      const validMessage = validation(event.target.value);
      if (validMessage) {
        setValues({ ...values, validationError: validMessage, isError: true });
      } else {
        setValues({ ...values, validationError: "", isError: false });
      }
    }
  };

  useEffect(() => {
    if (props.validationError) {
      setValues({
        ...values,
        validationError: props.validationError,
        isError: true,
      });
    } else {
      setValues({ ...values, validationError: "", isError: false });
    }
  }, [props.validationError]);

  let items = [];
  if (
    props.default !== undefined &&
    !props.list.data.some(
      (item: any) => item[props.field || "text"] === props.default
    )
  ) {
    items.push(
      <MenuItem value={-1} key={-1}>
        <em>{props.default}</em>
      </MenuItem>
    );
  }
  items = items.concat(
    props.list.data.map((item: any, index: number) => (
      <MenuItem key={props.idValue} value={item[props.idValue || "id"]}>
        {item[props.field || "text"] === props.default ? (
          <em>{item[props.field || "text"]}</em>
        ) : (
          item[props.field || "text"]
        )}
      </MenuItem>
    ))
  );

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          data-testid="inputLabelTest"
          id="inputLabel"
          className={clsx(classes.label)}
        >
          {props.name}
          {!props.isNewDesign ? (props.isRequiredField ? " *" : "") : ""}
        </InputLabel>
        <Select
          labelId="helperLabel"
          error={values.isError}
          id={props.id}
          value={props.value}
          onChange={validateSelect}
          data-testid={props.testId}
          name={props.name}
          label={
            <div>
              {props.name}
              {props.isRequiredField ? " *" : ""}
            </div>
          }
          disabled={props.disabled}
          required={props.isNewDesign && props.isRequiredField}
        >
          {items}
        </Select>
        <FormHelperText
          data-testid={props.helpTestId}
          style={{ color: "#ff0000", paddingLeft: "14px" }}
        >
          {values.validationError || " "}
        </FormHelperText>
      </FormControl>
    </>
  );
};
