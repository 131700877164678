import * as React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

export function DashboardListItems(props: any) {
  return (
    <List component="nav">
      <ListItem button data-testid="dashboard" component="a" href="/dashboard">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText data-testid="dashboard-nav-title" primary="Dashboard" />
      </ListItem>
      <ListItem
        button
        data-testid="userprofile"
        component="a"
        href="/user_profile"
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText data-testid="profile-nav-title" primary="User Profile" />
      </ListItem>
      <ListItem button data-testid="logout" component="a" href="/login">
        <ListItemIcon>
          <PowerSettingsNewIcon />
        </ListItemIcon>
        <ListItemText data-testid="logout-nav-title" primary="Logout" />
      </ListItem>
    </List>
  );
}
