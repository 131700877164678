import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Button as MaterialButton,
} from "@material-ui/core";
import { Prompt } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { TextField } from "./custom/TextField";
import { strings } from "../utils/Strings";
import { DropDownMenu, negativeToEmpty } from "./custom/DropDownMenu";
import { Button } from "./custom/Button";
import {
  specialtiesAction,
  subSpecialtiesAction,
} from "../actions/demographicsActions";
import {
  getUserDetailsAction,
  updateUserDetailsAction,
  updateButtonDisabled,
  resetUpdateProfileResponse,
} from "../actions/profileActions";
import { DemographicsResponse } from "../models/DemographicsResponse";
import {
  AccountProfileResponse,
  UserInformation,
} from "../models/AccountProfileResponse";
import { AccountUpdateRequest } from "../models/AccountUpdateRequest";
import { colors } from "../config/colorConfig";
import {
  hasNumberInString,
  isProperNameLength,
  ValidateZipCode,
} from "../utils/validation";
import { graduationYearCount } from "../config/accountCreationConfig";
import { withRoot } from "../withRoot";
import { ErrorMessageWith } from "./ErrorMessage";
import {
  isEUCountry,
  isZipCodeEnabled,
  isNpiVisible,
  isInternationalUser,
} from "../utils/accountCreationUtil";
import { IdTextTuple } from "../models/IdTextTuple";
import { ErrorValues } from "../models/ErrorValues";
import {
  sendAmplitudeEvent,
  sendAmplitudeEventData,
  updateUserPropertiesForSegment,
} from "../utils/AmplitudeUtil";
import {
  ACCOUNT_PROFILE_UPDATE_CLICK_EVENT_ID,
  ACCOUNT_PROFILE_UPDATE_SUCCESS_EVENT_ID,
  ACCOUNT_PROFILE_UPDATE_FAIL_EVENT_ID,
  ACCOUNT_PROFILE_UPDATE_CLICK,
  ACCOUNT_PROFILE_UPDATE_SUCCESS,
  ACCOUNT_PROFILE_UPDATE_FAIL,
  ACCOUNT_CHANGE_PASSWORD_CLICK,
  ACCOUNT_CHANGE_PASSWORD_CLICK_EVENT_ID,
  ACCOUNT_CHANGE_EMAIL_CLICK_EVENT_ID,
  ACCOUNT_CHANGE_EMAIL_CLICK,
} from "../utils/analytics";
import { httpConstants } from "../utils/httpConstants";
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import { ChangeEmailDialog } from "./ChangeEmailDialog";
import {
  clearZipCodeValidationAction,
  zipCodeValidationAction,
} from "../actions/accountCreationActions";
import { AccountCreationState } from "./CreateAccountForm";
import {
  ContinueWithoutSaveDialogWithRoot as ContinueWithoutSaveDialog,
  PromptDialogInfo,
} from "./ContinueWithoutSaveDialog";
import { config } from "../config/envConfig";
import { CURRENT_USER_ID } from "src/utils/localStorageKeys";
import { ContactSupportForRefund } from "./ContactSupportForRefund";
import { Helmet } from "react-helmet-async";
import { UseQueryResult } from "@tanstack/react-query";
import { Product } from "src/services/subscriptionService";
import { ButtonBase } from "@mui/material";

interface AccountUpdateState {
  accountProfile: AccountProfileValues;
}

interface Message {
  code: string;
  message: string;
}
interface ErrorMessage {
  error: Message;
}
interface AccountUpdateErrorType {
  data: Array<ErrorMessage>;
  status: number;
}

interface CountryId {
  iso3166alpha2?: string;
  id?: number;
  text?: string;
}

interface AccountProfileValues {
  occupationsResponse: DemographicsResponse;
  countriesResponse: DemographicsResponse;
  specialtiesResponse: DemographicsResponse;
  subSpecialtiesResponse: DemographicsResponse;
  accountProfileResponse: AccountProfileResponse;
  accountUpdateResponse: Record<string, unknown>;
  accountUpdateError: AccountUpdateErrorType;
  userProfile_progress: boolean;
  updateUserCompleted: boolean;
  promptDialogOpen: PromptDialogInfo;
}
interface AccountProfileProps {
  subscriptionListProgress: boolean;
  handleRoute: (path: string) => void;
  isPremium: boolean | null;
  userProductsQuery: UseQueryResult<Product[]>;
}

const defaultUserDetail: UserInformation = {
  userId: -1,
  email: "",
  password: "",
  occupation: {
    id: -1,
    text: "",
  },
  profileOccupation: {
    id: -1,
    text: "",
  },
  firstName: "",
  lastName: "",
  addresses: {
    work: {
      country: "",
      zipcode: "",
      state: "",
    },
  },
  npiNum: 0,
  specialty: {
    id: -1,
    text: "",
  },
  subSpecialty: {
    id: -1,
    text: "",
  },
  profileSpecialty: {
    id: -1,
    text: "",
  },
  profileSubSpecialty: {
    id: -1,
    text: "",
  },
};
const AccountProfile = (props: AccountProfileProps) => {
  const defaultPromptDialogInfo: PromptDialogInfo = {
    open: false,
    nextLocation: "",
  };
  const graduationFlag = config.environment.isGraduationYearEnabled;
  const [userDetail, setUserDetail] =
    useState<UserInformation>(defaultUserDetail);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isCancelEnabled, setIsCancelEnabled] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isUpdateProgress, setIsUpdateProgress] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [promptDialogInfo, setPromptDialogInfo] = useState<PromptDialogInfo>(
    defaultPromptDialogInfo
  );
  const [isNavBlocked, setIsNavBlocked] = useState(false);
  const defaultValidationStates = {
    firstName: false,
    lastName: false,
    occupation: false,
    specialty: false,
    subSpecialty: false,
    npi: false,
    country: false,
    zipcode: false,
  };
  const defaultError = {
    counter: 0,
    errorMessages: [],
    errorStatus: 0,
  };
  const defaultInputError = {
    firstName: false,
    lastName: false,
    npiNum: false,
    zipcode: false,
    country: false,
  };
  const [validationStates, setValidationStates] = useState<
    Record<string, boolean>
  >(defaultValidationStates);
  const [specialtyError, setSpecialtyError] = useState<string>("");
  const [errorValues, setErrorValues] = useState<ErrorValues>(defaultError);
  const [showZipcode, setShowZipcode] = useState(false);
  const [inputError, setInputError] =
    useState<Record<string, boolean>>(defaultInputError);
  const [showNpi, setShowNpi] = useState(true);
  const [showContactSupportModal, setShowContactSupportModal] = useState(false);
  const [subspecialtyDisabled, setSubspecialtyDisabled] = useState(false);
  const [cancelClick, setCancelClick] = useState(false);
  const styles = makeStyles((theme) => ({
    rightPaneWrapper: {
      padding: theme.spacing(6),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(4),
      },
    },
    profileHeader: {
      display: "flex",
      alignItems: "center",
    },
    profileHeaderIcon: {
      width: 56,
      height: 56,
      backgroundColor: colors["--accent-violet"],
      padding: theme.spacing(4),
      borderRadius: "50%",
      flexShrink: 0,
    },
    profileHeaderDetail: {
      marginLeft: 16,
    },
    profileHeaderName: {
      fontSize: 24,
      fontWeight: 300,
      lineHeight: 1.08,
      color: colors["--price"],
      marginBottom: 4,
    },
    profileHeaderOccupation: {
      fontSize: 14,
      lineHeight: 1.43,
      color: "#a2a9b4",
    },
    profileBody: {
      marginTop: 40,
      "& h6": {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.38,
        color: "#1e3554",
        marginBottom: 16,
      },
    },
    profileLabel: {
      fontSize: 16,
      lineHeight: 1.25,
      color: colors["--ui-slate"],
      width: 166,
      [theme.breakpoints.down("sm")]: {
        width: 175,
      },
    },
    profileInput: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.25,
      color: colors["--ui-slate"],
      [theme.breakpoints.down("sm")]: {
        marginBottom: 8,
      },
    },
    profileChange: {
      color: colors["--primary-teal"],
      "& button": {
        border: "none",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.43,
        "&:focus": {
          outline: "none",
        },
      },
    },
    profileDetail: {
      display: "flex",
      marginTop: 20,
      marginBottom: 20,
    },
    profileWidth: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
      "& span": {
        border: "none",
        [theme.breakpoints.down("xs")]: {
          direction: "rtl",
        },
      },
    },
    profileEnd: {
      display: "flex",
      justifyContent: "flex-end",
    },
    profileButtons: {
      display: "flex",
    },
    profileCancel: {
      textTransform: "capitalize",
      padding: 10,
      marginLeft: 14,
      fontFamily: "Metric",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.25,
      color: colors["--primary-teal"],
      "&:hover": {
        backgroundColor: colors["--text-invert"],
      },
      "&:focus": {
        outline: "none",
      },
    },
    noSpecialty: {
      display: "block",
      margin: "auto",
      width: "100%",
      marginLeft: 16,
    },
  }));
  const classes = styles();
  const dispatch = useDispatch();
  const state = useSelector(
    (accountUpdateState: AccountUpdateState) =>
      accountUpdateState.accountProfile
  );
  const zipCodeState = useSelector((reduxState: AccountCreationState) => ({
    zipCodeValidationResponse:
      reduxState.accountCreation?.zipCodeValidationResponse,
    zipCode_validation_progress:
      reduxState.accountCreation?.zipCode_validation_progress,
  }));
  const getCountryId = (selectedCountry: string) => {
    const countryObj: IdTextTuple = state?.countriesResponse?.data.find(
      (item: CountryId) => item.iso3166alpha2 === selectedCountry
    ) as CountryId;
    return countryObj?.id;
  };

  const updateProfileRequest = () => {
    let updateRequest: AccountUpdateRequest = {
      firstName: userDetail.firstName,
      lastName: userDetail.lastName,
      email: userDetail.email,
      profileOccupation: userDetail.profileOccupation?.id,
      addresses: userDetail.addresses,
      npiNum: userDetail.npiNum,
      profileSpecialty:
        userDetail.profileSpecialty?.id === -1
          ? 0
          : userDetail.profileSpecialty?.id,
      profileSubSpecialty:
        userDetail.profileSubSpecialty?.id === -1
          ? 0
          : userDetail.profileSubSpecialty?.id,
    };
    let occupationChange = false;
    const getCompareResults = (key: string) => {
      const obj = state?.accountProfileResponse?.userInformation[
        key as keyof AccountUpdateRequest
      ] as IdTextTuple;
      switch (key) {
        case "profileOccupation":
          occupationChange =
            obj.id !== updateRequest[key as keyof AccountUpdateRequest];
          return obj.id === updateRequest[key as keyof AccountUpdateRequest];
        case "profileSpecialty":
        case "profileSubSpecialty":
          return obj.id === updateRequest[key as keyof AccountUpdateRequest];
        default:
          return _.isEqual(
            state?.accountProfileResponse?.userInformation[
              key as keyof AccountUpdateRequest
            ],
            updateRequest[key as keyof AccountUpdateRequest]
          );
      }
    };

    for (const key in updateRequest) {
      if (
        state?.accountProfileResponse?.userInformation[
          key as keyof AccountUpdateRequest
        ] &&
        getCompareResults(key)
      ) {
        delete updateRequest[key as keyof AccountUpdateRequest];
      }
    }
    if (occupationChange) {
      updateRequest = {
        ...updateRequest,
        profileSpecialty:
          userDetail.profileSpecialty?.id === -1
            ? 0
            : userDetail.profileSpecialty?.id,
        profileSubSpecialty:
          userDetail.profileSubSpecialty?.id === -1
            ? 0
            : userDetail.profileSubSpecialty?.id,
      };
    }
    dispatch(updateUserDetailsAction(_.pickBy(updateRequest)));
  };

  const openPromptDialog = (location: string) => {
    if (isNavBlocked && location !== "/account/profile") {
      setPromptDialogInfo({ open: true, nextLocation: location });
      return false;
    }
    return true;
  };

  const closePromptDialog = () => {
    setPromptDialogInfo(defaultPromptDialogInfo);
  };

  const updateButtonState = (value: boolean) => {
    setIsButtonDisabled(value);
    dispatch(updateButtonDisabled(value));
    setIsNavBlocked(!value);
  };

  React.useEffect(() => {
    if (userDetail.addresses && userDetail.profileOccupation) {
      const showNpiValue = isNpiVisible(
        userDetail.addresses?.work?.country,
        userDetail.profileOccupation?.text
      );
      setShowNpi(showNpiValue);
      const errorCodes = (errorValues?.errorMessages || [])
        .map(({ error }: ErrorMessage) => error.code)
        .join(", ");
      if (!showNpiValue) {
        if (errorCodes.includes("invalidNPI")) {
          setErrorValues(defaultError);
        }
        setUserDetail({
          ...userDetail,
          npiNum: 0,
        });
      }
      if (!showZipcode && errorCodes.includes("invalidZIP")) {
        setErrorValues(defaultError);
        setUserDetail({
          ...userDetail,
          addresses: {
            ...userDetail.addresses,
            work: { ...userDetail.addresses.work, zipcode: "", state: "" },
          },
        });
      }
    }
  }, [userDetail.profileOccupation, userDetail.addresses.work]);

  React.useEffect(() => {
    if (window) {
      if (!isButtonDisabled) {
        window.onbeforeunload = () => true;
      } else {
        window.onbeforeunload = null;
      }
    }
  }, [isButtonDisabled]);

  React.useEffect(() => {
    if (state?.promptDialogOpen?.open) {
      openPromptDialog(state?.promptDialogOpen?.nextLocation);
    }
  }, [state.promptDialogOpen]);

  const isStudentCheck = () =>
    state.accountProfileResponse?.userInformation?.profileOccupation?.text
      ?.toLowerCase()
      .indexOf("student") !== -1;

  useEffect(() => {
    if (state.accountProfileResponse?.userInformation) {
      setIsStudent(isStudentCheck());
      setUserDetail(state.accountProfileResponse.userInformation);
    }
  }, [state.accountProfileResponse?.userInformation]);

  useEffect(() => {
    if (userDetail?.addresses) {
      if (state?.countriesResponse) {
        if (isZipCodeEnabled(userDetail.addresses?.work?.country)) {
          setShowZipcode(true);
        } else {
          setShowZipcode(false);
        }
      }
    }
  }, [userDetail?.addresses, state.countriesResponse]);

  useEffect(() => {
    if (
      state.userProfile_progress ||
      zipCodeState.zipCode_validation_progress
    ) {
      setIsUpdateProgress(true);
    }
    if (state.updateUserCompleted) {
      setIsUpdateProgress(false);
      updateButtonState(true);
      if (promptDialogInfo.open) {
        setTimeout(() => props.handleRoute(promptDialogInfo.nextLocation), 0);
        closePromptDialog();
      }
      setValidationStates(defaultValidationStates);
      setErrorValues(defaultError);
    }
  }, [
    state.userProfile_progress,
    state.updateUserCompleted,
    zipCodeState.zipCode_validation_progress,
  ]);

  useEffect(() => {
    if (state.accountUpdateResponse) {
      if (
        props.isPremium &&
        isInternationalUser(userDetail.addresses.work.country) &&
        !isInternationalUser(
          state.accountProfileResponse?.userInformation?.addresses?.work
            ?.country
        )
      ) {
        setShowContactSupportModal(true);
      }
      dispatch(getUserDetailsAction());
      const eventObj = {
        "Event Id": ACCOUNT_PROFILE_UPDATE_SUCCESS_EVENT_ID,
        Occupation: userDetail.occupation.text,
        Specialty: !userDetail.specialty?.id ? "" : userDetail.specialty?.text,
        ProfileSpecialty: !userDetail.profileSpecialty?.id
          ? ""
          : userDetail.profileSpecialty?.text,
        SubSpecialty: !userDetail.subSpecialty?.id
          ? ""
          : userDetail.subSpecialty?.text,
        ProfileSubSpecialty: !userDetail.profileSubSpecialty?.id
          ? ""
          : userDetail.profileSubSpecialty?.text,
        NPI: !userDetail.npiNum ? "" : userDetail.npiNum,
        Country: userDetail.addresses.work.country,
        Zip:
          showZipcode && userDetail.addresses?.work?.zipcode
            ? userDetail.addresses.work.zipcode
            : "",
      };
      const userId = localStorage.getItem(CURRENT_USER_ID);
      updateUserPropertiesForSegment(userId, userDetail);
      sendAmplitudeEvent(ACCOUNT_PROFILE_UPDATE_SUCCESS, eventObj);
      dispatch(resetUpdateProfileResponse());
      props.userProductsQuery.refetch();
      setIsCancelEnabled(false);
    }
  }, [state.accountUpdateResponse]);

  useEffect(() => {
    if (state.accountUpdateError) {
      let amplitudeErrorMsg = "";
      setIsUpdateProgress(false);
      updateButtonState(false);
      if (promptDialogInfo.open) {
        closePromptDialog();
      }
      setErrorValues({
        counter: errorValues.counter + 1,
        errorMessages: state.accountUpdateError?.data,
        errorStatus: state.accountUpdateError?.status,
      } as ErrorValues);
      if (
        state.accountUpdateError.status === httpConstants.INTERNAL_SERVER_ERROR
      ) {
        setErrorValues({
          counter: errorValues.counter + 1,
          errorMessages: [],
          errorStatus: state.accountUpdateError.status,
        });
        amplitudeErrorMsg = "Network Error";
      }
      amplitudeErrorMsg = (state.accountUpdateError?.data || [])
        .map(({ error }: ErrorMessage) => error.message)
        .join(", ");
      const eventObj = {
        "Event Id": ACCOUNT_PROFILE_UPDATE_FAIL_EVENT_ID,
        "Error Message": amplitudeErrorMsg,
      };
      sendAmplitudeEvent(ACCOUNT_PROFILE_UPDATE_FAIL, eventObj);
      setIsCancelEnabled(false);
    }
  }, [state.accountUpdateError]);

  React.useEffect(() => {
    if (
      zipCodeState.zipCodeValidationResponse?.status !== httpConstants.OK &&
      zipCodeState.zipCodeValidationResponse?.data
    ) {
      setIsUpdateProgress(false);
      updateButtonState(false);
      if (promptDialogInfo.open) {
        closePromptDialog();
      }
      setErrorValues({
        counter: errorValues.counter + 1,
        errorMessages: [zipCodeState.zipCodeValidationResponse.data],
        errorStatus: zipCodeState.zipCodeValidationResponse.status,
      });
      const eventObj = {
        "Event Id": ACCOUNT_PROFILE_UPDATE_FAIL_EVENT_ID,
      };
      sendAmplitudeEvent(ACCOUNT_PROFILE_UPDATE_FAIL, eventObj);
    } else if (
      zipCodeState.zipCodeValidationResponse?.status === httpConstants.OK
    ) {
      dispatch(clearZipCodeValidationAction());
      updateProfileRequest();
    }
  }, [zipCodeState?.zipCodeValidationResponse]);

  const validateSingleInput = (data: string, type: string) => {
    let response = null;
    switch (type) {
      case "firstName":
      case "lastName":
        if (hasNumberInString(data)) {
          response = strings.no_number_in_name;
        } else if (!isProperNameLength(data)) {
          response =
            type === "firstName"
              ? strings.invalid_first_name
              : strings.invalid_last_name;
        }
        break;
      case "npiNum":
        if (
          data &&
          (String(data).length !== 10 || Number.isNaN(Number(data)))
        ) {
          response = strings.invalid_npi;
        }
        break;
      case "zipcode":
        if (ValidateZipCode(data)) {
          response = strings.invalid_zip_code;
        }
        break;
      default:
        response = null;
    }
    return response;
  };

  const validateFirstName = (data: string) => {
    const validationResponse = validateSingleInput(data, "firstName");
    if (data === state.accountProfileResponse?.userInformation.firstName) {
      setIsCancelEnabled(false);
    } else {
      setIsCancelEnabled(true);
    }
    if (
      !validationResponse &&
      data !== state?.accountProfileResponse?.userInformation?.firstName
    ) {
      setValidationStates({ ...validationStates, firstName: true });
      setUserDetail({
        ...userDetail,
        firstName: data,
      });
    } else {
      setValidationStates({ ...validationStates, firstName: false });
    }
    if (validationResponse) {
      setInputError({ ...inputError, firstName: true });
    } else {
      setInputError({ ...inputError, firstName: false });
    }
    return validationResponse;
  };

  const validateLastName = (data: string) => {
    const validationResponse = validateSingleInput(data, "lastName");
    if (data === state.accountProfileResponse?.userInformation.lastName) {
      setIsCancelEnabled(false);
    } else {
      setIsCancelEnabled(true);
    }
    if (
      !validationResponse &&
      data !== state?.accountProfileResponse?.userInformation?.lastName
    ) {
      setValidationStates({ ...validationStates, lastName: true });
      setUserDetail({
        ...userDetail,
        lastName: data,
      });
    } else {
      setValidationStates({ ...validationStates, lastName: false });
    }
    if (validationResponse) {
      setInputError({ ...inputError, lastName: true });
    } else {
      setInputError({ ...inputError, lastName: false });
    }
    return validationResponse;
  };

  const validateNpi = (data: number) => {
    const validationResponse = validateSingleInput(data.toString(), "npiNum");
    if (data === state.accountProfileResponse?.userInformation.npiNum) {
      setIsCancelEnabled(false);
    } else {
      setIsCancelEnabled(true);
    }
    if (
      !validationResponse &&
      data &&
      data !== state.accountProfileResponse?.userInformation?.npiNum
    ) {
      setValidationStates({ ...validationStates, npi: true });
      setUserDetail({
        ...userDetail,
        npiNum: data,
      });
    } else {
      setUserDetail({
        ...userDetail,
        npiNum: data,
      });
      setValidationStates({ ...validationStates, npi: false });
    }

    if (validationResponse) {
      setInputError({ ...inputError, npiNum: true });
    } else {
      setInputError({ ...inputError, npiNum: false });
    }
    return validationResponse;
  };

  const handleSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendAmplitudeEventData(
      null,
      ACCOUNT_PROFILE_UPDATE_CLICK,
      ACCOUNT_PROFILE_UPDATE_CLICK_EVENT_ID
    );
    if (
      state.specialtiesResponse?.data.length > 0 &&
      userDetail?.profileSpecialty?.id === -1
    ) {
      setSpecialtyError(strings.invalid_specialty);
      if (promptDialogInfo.open) {
        closePromptDialog();
      }
      return "";
    }

    const countryId = getCountryId(userDetail.addresses?.work?.country);
    dispatch(
      zipCodeValidationAction({
        countryId: countryId as number,
        zipCode:
          showZipcode && userDetail.addresses?.work?.zipcode
            ? userDetail.addresses.work.zipcode
            : "",
      })
    );
    if (!showZipcode) {
      updateProfileRequest();
    }
    return "";
  };

  const handleOccupationChange = (data: number) => {
    const occupationName = state.occupationsResponse.data.find(
      (occupation) => occupation.id === data
    );
    setSpecialtyError("");
    setIsCancelEnabled(true);
    if (occupationName?.text?.toLowerCase().indexOf("student") !== -1) {
      setIsStudent(true);
    } else {
      setIsStudent(false);
    }

    if (
      state.accountProfileResponse?.userInformation?.profileOccupation?.id ===
      data
    ) {
      setValidationStates({
        ...validationStates,
        occupation: false,
        specialty: false,
        subSpecialty: false,
      });
      setUserDetail({
        ...userDetail,
        profileOccupation:
          occupationName || defaultUserDetail.profileOccupation,
        profileSpecialty:
          state.accountProfileResponse.userInformation.profileSpecialty,
        profileSubSpecialty:
          state.accountProfileResponse.userInformation.profileSubSpecialty,
      });
    } else {
      setValidationStates({
        ...validationStates,
        occupation: true,
        specialty: false,
        subSpecialty: false,
      });
      setUserDetail({
        ...userDetail,
        profileOccupation:
          occupationName || defaultUserDetail.profileOccupation,
        profileSpecialty: defaultUserDetail.profileSpecialty,
        profileSubSpecialty: defaultUserDetail.profileSubSpecialty,
      });
    }
    dispatch(specialtiesAction(data));
  };

  const handleSpecialtyInput = (data: number) => {
    const specialtyName = state.specialtiesResponse.data.find(
      (specialty) => specialty.id === data
    );
    setValidationStates({
      ...validationStates,
      specialty: !(
        state.accountProfileResponse?.userInformation.profileSpecialty?.id ===
        data
      ),
    });
    setUserDetail({
      ...userDetail,
      profileSpecialty: specialtyName || defaultUserDetail.profileSpecialty,
      profileSubSpecialty: defaultUserDetail.profileSubSpecialty,
    });
    setSpecialtyError("");
    dispatch(
      subSpecialtiesAction({
        occupationId: userDetail.profileOccupation.id || -1,
        specialtyId: data,
      })
    );
    setSubspecialtyDisabled(false);
  };

  const handleSubSpecialtyInput = (data: number) => {
    const subSpecialtyName = state.subSpecialtiesResponse.data.find(
      (subSpecialty) => subSpecialty.id === data
    );
    if (
      state.accountProfileResponse?.userInformation.profileSpecialty?.id ===
        data &&
      state.accountProfileResponse?.userInformation.profileOccupation?.id ===
        userDetail?.profileOccupation?.id
    ) {
      setValidationStates({ ...validationStates, subSpecialty: false });
    } else {
      setValidationStates({ ...validationStates, subSpecialty: true });
    }

    setUserDetail({
      ...userDetail,
      profileSubSpecialty:
        subSpecialtyName || defaultUserDetail.profileSubSpecialty,
    });
  };

  const handleCountryChange = (selectedCountry: string) => {
    if (isEUCountry(selectedCountry)) {
      setValidationStates({ ...validationStates, country: false });
      setUserDetail({
        ...userDetail,
        addresses: {
          ...userDetail.addresses,
          work: {
            ...userDetail.addresses.work,
            country: selectedCountry,
          },
        },
      });
      setInputError({ ...inputError, country: true });
      return strings.eu_unavailable;
    }

    if (
      state.accountProfileResponse?.userInformation.addresses.work.country ===
      selectedCountry
    ) {
      setValidationStates({ ...validationStates, country: false });
    } else {
      setValidationStates({ ...validationStates, country: true });
    }

    if (isZipCodeEnabled(selectedCountry)) {
      setValidationStates({
        ...validationStates,
        country: !inputError.zipcode,
      });
      setShowZipcode(true);
    } else {
      setShowZipcode(false);
    }

    setUserDetail({
      ...userDetail,
      addresses: {
        ...userDetail.addresses,
        work: {
          ...userDetail.addresses.work,
          country: selectedCountry,
        },
      },
    });
    setInputError({ ...inputError, country: false, zipcode: false });
    setIsCancelEnabled(true);
    return "";
  };

  const handleZipCodeChange = (data: string) => {
    const validationResponse = validateSingleInput(data, "zipcode");
    if (
      data ===
      state.accountProfileResponse?.userInformation.addresses.work.zipcode
    ) {
      setIsCancelEnabled(false);
    } else {
      setIsCancelEnabled(true);
    }
    if (
      !validationResponse &&
      data &&
      state.accountProfileResponse?.userInformation.addresses.work.zipcode !==
        data
    ) {
      setValidationStates({ ...validationStates, zipcode: true });
      setUserDetail({
        ...userDetail,
        addresses: {
          ...userDetail.addresses,
          work: { ...userDetail.addresses.work, zipcode: data },
        },
      });
    } else {
      setValidationStates({ ...validationStates, zipcode: false });
      setUserDetail({
        ...userDetail,
        addresses: {
          ...userDetail.addresses,
          work: { ...userDetail.addresses.work, zipcode: data },
        },
      });
    }

    if (validationResponse) {
      setInputError({ ...inputError, zipcode: true });
    } else {
      setInputError({ ...inputError, zipcode: false });
    }

    return validationResponse;
  };

  const getGraduationYearList = () => {
    const currentYear = new Date().getFullYear();
    const list = [];
    for (let i = 0; i < graduationYearCount; i += 1) {
      list.push({ year: currentYear + i });
    }
    return list;
  };

  const cancelClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(updateButtonDisabled(true));
    dispatch(
      specialtiesAction(
        state.accountProfileResponse.userInformation?.profileOccupation?.id ||
          -1
      )
    );
    dispatch(
      subSpecialtiesAction({
        occupationId:
          state.accountProfileResponse?.userInformation?.profileOccupation
            ?.id || -1,
        specialtyId:
          state.accountProfileResponse?.userInformation?.profileSpecialty?.id ||
          -1,
      })
    );

    const student =
      state.accountProfileResponse?.userInformation?.profileOccupation?.text
        ?.toLowerCase()
        .indexOf("student") !== -1;
    setIsStudent(student);
    setUserDetail(state.accountProfileResponse?.userInformation);
    setValidationStates(defaultValidationStates);
    setErrorValues(defaultError);
    setShowZipcode((zipcode) => zipcode);
    setSpecialtyError("");
    setIsCancelEnabled(false);
    setInputError(defaultInputError);
    setCancelClick(true);
  };

  useEffect(() => {
    if (cancelClick) {
      setCancelClick(false);
    }
  }, [cancelClick]);

  const changeEmailClickHandler = () => {
    sendAmplitudeEventData(
      null,
      ACCOUNT_CHANGE_EMAIL_CLICK,
      ACCOUNT_CHANGE_EMAIL_CLICK_EVENT_ID
    );
    setChangeEmailModal(true);
  };

  const changePasswordClickHandler = () => {
    sendAmplitudeEventData(
      null,
      ACCOUNT_CHANGE_PASSWORD_CLICK,
      ACCOUNT_CHANGE_PASSWORD_CLICK_EVENT_ID
    );
    setChangePasswordModal(true);
  };

  useEffect(() => {
    const enabled = Object.keys(validationStates).find(
      (key) => validationStates[key] === true
    );
    const inputValidation = Object.keys(inputError).find(
      (key) => inputError[key] === true
    );
    if (enabled && !inputValidation) {
      updateButtonState(false);
    } else {
      updateButtonState(true);
    }
  }, [validationStates]);

  useEffect(() => {
    if (
      validationStates.occupation &&
      !validationStates.specialty &&
      state.specialtiesResponse?.data.length > 0
    ) {
      updateButtonState(true);
      setSubspecialtyDisabled(true);
    } else {
      setSubspecialtyDisabled(false);
      setIsCancelEnabled(false);
    }
  }, [state.specialtiesResponse]);

  const handleContinueWithoutSave = () => {
    updateButtonState(true);
    setTimeout(() => props.handleRoute(promptDialogInfo.nextLocation), 0);
    closePromptDialog();
  };

  let element = null;
  if (
    userDetail &&
    state.specialtiesResponse &&
    !props.subscriptionListProgress
  ) {
    element = (
      <div>
        <div className={clsx(classes.profileHeader)}>
          <div className={clsx(classes.profileHeaderIcon)}>
            <img
              /* eslint-disable @typescript-eslint/no-var-requires */
              src={`${
                require("../images/icons-streamline-regular-24-pt-account.svg")
                  .default
              }`}
              /* eslint-enable @typescript-eslint/no-var-requires */
              alt=""
            />
          </div>
          <div
            className={
              state?.accountProfileResponse?.userInformation?.specialty?.text
                ? clsx(classes.profileHeaderDetail)
                : clsx(classes.profileHeaderDetail, classes.noSpecialty)
            }
          >
            <Typography
              data-testid="profileHeaderName"
              className={clsx(classes.profileHeaderName)}
            >
              {state?.accountProfileResponse?.userInformation?.firstName}{" "}
              {state?.accountProfileResponse?.userInformation?.lastName},{" "}
              {
                state?.accountProfileResponse?.userInformation
                  ?.profileOccupation.text
              }
            </Typography>
            {!isStudentCheck() && (
              <Typography
                data-testid="profileHeaderId"
                className={clsx(classes.profileHeaderOccupation)}
              >
                {
                  state?.accountProfileResponse?.userInformation
                    ?.profileSpecialty?.text
                }{" "}
                {state?.accountProfileResponse?.userInformation?.npiNum
                  ? `NPI: ${state?.accountProfileResponse?.userInformation?.npiNum}`
                  : ""}
              </Typography>
            )}
            {isStudentCheck() && (
              <Typography
                data-testid="profileHeaderId"
                className={clsx(classes.profileHeaderOccupation)}
              >
                {state?.accountProfileResponse?.userInformation
                  ?.profileSpecialty?.text
                  ? state?.accountProfileResponse?.userInformation
                      ?.profileSpecialty?.text
                  : "Student"}
              </Typography>
            )}
          </div>
        </div>
        <form data-testid="updateAccountForm" onSubmit={handleSubmit}>
          <div className={clsx(classes.profileBody)}>
            <h6>Profile</h6>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  testId="firstName"
                  data-testid="firstName"
                  label={strings.first_name}
                  helpTestId="firstNameHelp"
                  validation={validateFirstName}
                  value={userDetail?.firstName}
                  isNewDesign={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastName"
                  testId="lastName"
                  data-testid="lastName"
                  label={strings.last_name}
                  helpTestId="lastNameHelp"
                  validation={validateLastName}
                  value={userDetail?.lastName}
                  isNewDesign={true}
                />
              </Grid>
            </Grid>
            <div className={clsx(classes.profileDetail)}>
              <Typography className={clsx(classes.profileLabel)}>
                Email address
              </Typography>
              <div className={clsx(classes.profileWidth)}>
                <Typography
                  component={"span"}
                  data-testid="emailHelperId"
                  className={clsx(classes.profileEnd, classes.profileInput)}
                >
                  {userDetail?.email.toLowerCase()}
                </Typography>
                <div
                  className={clsx(classes.profileEnd, classes.profileChange)}
                >
                  <ButtonBase
                    focusRipple
                    data-testid="changeEmailLinkBtn"
                    onClick={changeEmailClickHandler}
                  >
                    Change email
                  </ButtonBase>
                  <ChangeEmailDialog
                    open={changeEmailModal}
                    setChangeEmailModal={setChangeEmailModal}
                    email={userDetail?.email}
                  />
                </div>
              </div>
            </div>
            <div className={clsx(classes.profileDetail)}>
              <Typography className={clsx(classes.profileLabel)}>
                Password
              </Typography>
              <div className={clsx(classes.profileWidth)}>
                <Typography
                  component={"span"}
                  className={clsx(classes.profileEnd, classes.profileInput)}
                >
                  ********
                </Typography>
                <div
                  className={clsx(classes.profileEnd, classes.profileChange)}
                >
                  <ButtonBase
                    focusRipple
                    data-testid="changePasswordLinkBtn"
                    onClick={changePasswordClickHandler}
                  >
                    Change password
                  </ButtonBase>
                  <ChangePasswordDialog
                    open={changePasswordModal}
                    setChangePasswordModal={setChangePasswordModal}
                    email={userDetail?.email}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={clsx(classes.profileBody)}>
            <h6>Occupation</h6>
            <Grid container spacing={6} wrap="wrap">
              <Grid item xs={12} sm={6}>
                <DropDownMenu
                  id="occupation"
                  name="Occupation"
                  testId="occupation"
                  data-testid="occupation"
                  noBottomMargin="true"
                  validation={handleOccupationChange}
                  value={negativeToEmpty(userDetail?.profileOccupation?.id)}
                  minWidth="343px"
                  maxWidth="343px"
                  isRequiredField
                  list={state?.occupationsResponse || { data: [] }}
                />
              </Grid>
              {!isStudent
                ? showNpi &&
                  !cancelClick && (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="NPI"
                        testId="npi"
                        label="NPI"
                        helpTestId="npiHelp"
                        data-testid="npi"
                        isRequired="false"
                        validation={validateNpi}
                        value={userDetail?.npiNum ? userDetail?.npiNum : ""}
                      />
                    </Grid>
                  )
                : graduationFlag && (
                    <Grid item xs={12} sm={6}>
                      <DropDownMenu
                        id="graduationYear"
                        name={strings.expected_graduation_year}
                        testId="graduationYear"
                        helpTestId="graduationYearHelp"
                        noBottomMargin="true"
                        data-testid="graduationYear"
                        // validation={handleYearChange}
                        // value={negativeToEmpty(graduationYear)}
                        idValue="year"
                        field="year"
                        minWidth="343px"
                        maxWidth="343px"
                        list={{ data: getGraduationYearList() }}
                      />
                    </Grid>
                  )}
              {state.specialtiesResponse?.data.length > 0 && (
                <Grid item xs={12} sm={6}>
                  <DropDownMenu
                    id="specialty"
                    name="Specialty"
                    testId="specialty"
                    helpTestId="specialtyHelp"
                    data-testid="specialty"
                    validation={handleSpecialtyInput}
                    validationError={specialtyError}
                    list={state?.specialtiesResponse || { data: [] }}
                    value={negativeToEmpty(
                      userDetail?.profileSpecialty
                        ? userDetail?.profileSpecialty.id
                        : -1
                    )}
                    isRequiredField
                    noBottomMargin="true"
                  />
                </Grid>
              )}
              {state.specialtiesResponse?.data.length > 0 &&
                state.subSpecialtiesResponse?.data.length > 0 && (
                  <Grid item xs={12} sm={6}>
                    <DropDownMenu
                      id="subSpecialty"
                      name="Subspecialty"
                      testId="subSpecialty"
                      helpTestId="subSpecialtyHelp"
                      data-testid="subSpecialty"
                      validation={handleSubSpecialtyInput}
                      value={negativeToEmpty(
                        userDetail?.profileSubSpecialty
                          ? userDetail?.profileSubSpecialty.id
                          : state.subSpecialtiesResponse?.data.find(
                              ({ text }) => text === strings.no_subspecialty
                            )?.id ?? -1
                      )}
                      list={state?.subSpecialtiesResponse || { data: [] }}
                      noBottomMargin="true"
                      disabled={subspecialtyDisabled}
                    />
                  </Grid>
                )}
              <Grid item xs={12} sm={6}>
                <DropDownMenu
                  id="country"
                  name={
                    isStudent
                      ? strings.country_of_residence
                      : strings.country_of_practice
                  }
                  testId="country"
                  helpTestId="countryHelp"
                  countryIndex="country"
                  noBottomMargin="true"
                  validation={handleCountryChange}
                  value={negativeToEmpty(userDetail?.addresses?.work.country)}
                  idValue="iso3166alpha2"
                  field="name"
                  minWidth="343px"
                  maxWidth="343px"
                  list={state?.countriesResponse || { data: [] }}
                  isRequiredField
                />
              </Grid>
              {showZipcode && !cancelClick && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="zipCode"
                    testId="zipCode"
                    label={
                      isStudent ? strings.zip_code : strings.practicing_zip_code
                    }
                    helpTestId="zipCodeHelp"
                    value={
                      userDetail?.addresses?.work?.zipcode
                        ? userDetail?.addresses.work.zipcode
                        : ""
                    }
                    validation={handleZipCodeChange}
                  />
                </Grid>
              )}
            </Grid>
          </div>
          <div className={clsx(classes.profileBody, classes.profileButtons)}>
            <Button
              name="Update"
              id="update"
              type="submit"
              data-testid="update"
              width="95px"
              fontFamily="Metric"
              disabled={isButtonDisabled}
              inProgress={isUpdateProgress}
            />
            {(!isButtonDisabled || isCancelEnabled) && (
              <MaterialButton
                className={clsx(classes.profileCancel)}
                onClick={cancelClickHandler}
                data-testid="cancel"
                name="Cancel"
              >
                Cancel
              </MaterialButton>
            )}
          </div>
        </form>
        {errorValues && errorValues.errorMessages?.length > 0 && (
          <ErrorMessageWith errorValues={errorValues} />
        )}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={strings.account_profile_meta_description}
        />
      </Helmet>
      <Prompt message={(location) => openPromptDialog(location.pathname)} />
      <ContinueWithoutSaveDialog
        info={promptDialogInfo}
        isUpdateProgress={isUpdateProgress}
        setClose={closePromptDialog}
        handleContinueWithoutSave={handleContinueWithoutSave}
        handleContinueWithSave={handleSubmit}
      />
      <div className={clsx(classes.rightPaneWrapper)}>{element}</div>
      <ContactSupportForRefund
        showModal={showContactSupportModal}
        setShowModal={setShowContactSupportModal}
      />
    </>
  );
};

export const AccountProfileWithRoot = withRoot(AccountProfile);
