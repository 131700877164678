import { Dispatch, useEffect, useState } from "react";
import { Typography, Link, Box, Checkbox } from "@material-ui/core";
import clsx from "clsx";
import { Button } from "./custom/Button";
import { strings } from "../utils/Strings";
import { globalDialogStyles } from "../styles/globalDialogStyles";
import moment from "moment";
import { CardNumber } from "./custom/CardNumber";
import {
  LicensePurchaseCommitPayload,
  LicensePurchaseResponse,
  commitLicensePurchase,
} from "../services/licenseService";
import { IPrimaryPaymentInformation } from "../models/PrimaryPaymentMethodResponse";
import { LicensePaymentSummary } from "./LicensePaymentSummary";
import { licenseTheme } from "../styles/licenseTheme";
import { licenseStyles } from "../styles/licenseStyles";
import { colors } from "../config/colorConfig";
import { useMutation } from "@tanstack/react-query";
import { LicenseFormAction } from "../container/LicenseCodeForm";
import { sendAmplitudeEvent } from "src/utils/AmplitudeUtil";
import {
  GROUP_LICENSING_PAYMENT_CONFIRMATION_CLICK,
  GROUP_LICENSING_PAYMENT_FAILURE,
  GROUP_LICENSING_PAYMENT_SUCCESS,
  GroupLicenseSegmentPayload,
} from "src/utils/analytics";
import { isLicensePurchaseDetails } from "../models/LicensePurchaseDetails";
import { useDispatch } from "react-redux";
import { sendSuccessSnackBarWithLinkMessage } from "../actions/snackBarActions";
import { SnackBarType } from "../utils/SnackBarType";

interface LicensePaymentConfirmationProps {
  userId?: string;
  newEmail?: string;
  productSku: string;
  paymentMethodDetails?: IPrimaryPaymentInformation;
  confirmButtonSpinner: boolean;
  setConfirmButtonSpinner: (val: boolean) => void;
  setPaymentConfirmScreen: (paymentConfirmScreen: boolean) => void;
  calculateLicenseResponse: LicensePurchaseResponse | undefined;
  purchaseQuantity: number;
  institution: string;
  paymentDetailRequest: string;
  setPaymentDetailRequest: (paymentDetailRequest: string) => void;
  formDispatch: Dispatch<LicenseFormAction>;
}

export const LicensePaymentConfirmation = (
  props: LicensePaymentConfirmationProps
): JSX.Element => {
  const classes = globalDialogStyles();
  const clx = licenseStyles(licenseTheme);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [confirmButtonSpinner, setConfirmButtonSpinner] = useState(false);
  const [commitQueryFail, setCommitQueryFail] = useState(false);
  const dispatchRedux = useDispatch();
  let segmentPayload: GroupLicenseSegmentPayload = {
    "Purchase Type": "Group Licensing",
    "Purchase Quantity": props.purchaseQuantity,
    "Institution Name": props.institution,
  };
  const commitQuery = useMutation(commitLicensePurchase, {
    onSuccess: (response) => {
      setConfirmButtonSpinner(false);
      if (response.status >= 200 && response.status <= 299) {
        if (isLicensePurchaseDetails(response.data)) {
          segmentPayload = {
            ...segmentPayload,
            "Group Code": response.data.licenseCode,
            Price: response.data.total,
          };
          sendAmplitudeEvent(GROUP_LICENSING_PAYMENT_SUCCESS, segmentPayload);
          props.formDispatch({
            type: "continue",
            groupCode: response.data.licenseCode,
          });
        } else {
          setCommitQueryFail(true);
        }
      } else {
        setCommitQueryFail(true);
      }
    },
    onError: (error) => {
      setConfirmButtonSpinner(false);
      setCommitQueryFail(true);
    },
  });
  useEffect(() => {
    if (commitQueryFail) {
      segmentPayload = {
        ...segmentPayload,
        Price: props.calculateLicenseResponse?.data?.total,
      };
      sendAmplitudeEvent(GROUP_LICENSING_PAYMENT_FAILURE, segmentPayload);
      dispatchRedux(
        sendSuccessSnackBarWithLinkMessage(
          strings.payment_failed_title,
          SnackBarType.ERROR.displayValue
        )
      );
    }
  }, [commitQueryFail]);

  const handlePurchase = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const segmentPayload: GroupLicenseSegmentPayload = {
      "Purchase Type": "Group Licensing",
      "Purchase Quantity": props.purchaseQuantity,
    };
    sendAmplitudeEvent(
      GROUP_LICENSING_PAYMENT_CONFIRMATION_CLICK,
      segmentPayload
    );
    if (props.paymentMethodDetails?.id) {
      const purchasePayload: LicensePurchaseCommitPayload = {
        userId: Number(props.userId),
        sku: props.productSku,
        quantity: props.purchaseQuantity,
        institution: props.institution,
        paymentId: props.paymentMethodDetails.id,
      };

      setConfirmButtonSpinner(true);
      await commitQuery.mutateAsync(purchasePayload);
    } else {
      setCommitQueryFail(true);
    }
  };

  const paymentConfirmation = (
    <>
      {props.paymentMethodDetails && (
        <div className={classes.popupPaymentDetailsResponsive}>
          <div className={classes.popupPaymentDetailsRow}>
            <p className={classes.popupPaymentDetailsRowLabel}>
              {strings.payment_method}
            </p>
            <div
              className={clsx(
                classes.popupPaymentDetailsRowValue,
                classes.popupPaymentDetailsCCNumber
              )}
            >
              <CardNumber cardNumber={props.paymentMethodDetails?.cardNumber} />{" "}
              {moment(props.paymentMethodDetails?.expirationDate).format(
                "MM/YY"
              )}
            </div>
            <div>
              <Link
                className={clsx(classes.supportLink)}
                href="#"
                rel="noreferrer"
                id="enterpriseLearnMore"
                data-testid="editCreditCard"
                tabIndex={0}
                onClick={() => props.setPaymentConfirmScreen(false)}
              >
                Edit
              </Link>
            </div>
          </div>
          {props.paymentMethodDetails?.billingAddress1 ? (
            <div className={classes.popupPaymentDetailsRow}>
              <p className={classes.popupPaymentDetailsRowLabel}>
                {strings.billing_address}
              </p>
              <p className={classes.popupPaymentDetailsRowValue}>
                {props.paymentMethodDetails.billingAddress1}
                {props.paymentMethodDetails.billingAddress2
                  ? ` ${props.paymentMethodDetails.billingAddress2}`
                  : null}
                , {props.paymentMethodDetails.city},{" "}
                {props.paymentMethodDetails.state}{" "}
                {props.paymentMethodDetails.postalCode}
              </p>
              <div>
                <Link
                  className={clsx(classes.supportLink)}
                  href="#"
                  rel="noreferrer"
                  id="enterpriseLearnMore"
                  data-testid="editBillingAddress"
                  tabIndex={0}
                  onClick={() => props.setPaymentConfirmScreen(false)}
                >
                  Edit
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );

  return (
    <>
      <div className={clsx(classes.wrapperDiv)}>
        <Typography
          id="alert-dialog-subTitle1"
          classes={{
            root: clsx(clx.cardSubTitle, clx.primaryMidnight),
          }}
          data-testid="alert-dialog-subTitle1"
        >
          {strings.confirm_payment_billing_information}
        </Typography>
        {paymentConfirmation}
        <div className={clsx(classes.licenseSummary)}>
          <LicensePaymentSummary
            purchaseQuantity={props.purchaseQuantity}
            calculateLicenseResponse={props.calculateLicenseResponse}
          />
        </div>
        <Box className={clsx(classes.textArea, classes.fontText)}>
          <strong>EPOCRATES SERVICES AGREEMENT</strong>
          <br />
          IMPORTANT – PLEASE READ CAREFULLY THE TERMS OF THIS EPOCRATES SERVICES
          AGREEMENT. <br />
          This Agreement is a binding, contractual agreement between You/Client
          and Epocrates (as defined below). <br /> <br />
          <strong> Section 1. Defined Terms.</strong>
          <br />
          <strong>“Agreement”</strong> means this Epocrates Services Agreement
          and each Proposal. <br />
          <strong>"Annual Service Fee"</strong> means the annual service fee for
          the Epocrates Services as identified in the ordering workflow on
          epocrates.com or affiliated site (“Ordering Workflow”).
          <br />
          <strong>“Applicable Law”</strong> means all applicable federal, state,
          and local laws and regulations.
          <br />
          <strong>“Client”</strong> means the organization identified as
          “Company” within the Ordering Workflow.
          <br />
          <strong>“Confidential Information”</strong> means information that is
          disclosed by one Party (on behalf of itself or its affiliates) to the
          other and that the receiving Party knows is confidential to the
          disclosing Party or that is of such a nature that someone familiar
          with the type of business of the disclosing Party would reasonably
          understand is confidential to it. Without limitation, Confidential
          Information includes financial and other business information of
          either Party. Notwithstanding the foregoing, Confidential Information
          does not include information that the receiving Party can demonstrate:
          (i) is in the public domain or is generally publicly known through no
          improper action or inaction by the receiving Party; (ii) was
          rightfully in the receiving Party’s possession or known by it prior to
          receipt from the disclosing Party; (iii) is rightfully disclosed
          without restriction to the receiving Party by a third party without
          violation of obligation to the disclosing Party; or (iv) is
          independently developed for the receiving Party by third parties
          without use of the Confidential Information of the disclosing Party.
          <br />
          <strong>“Effective Date”</strong> means the date Client completes its
          purchase via the Ordering Workflow.
          <br />
          <strong>“Eligible Subscribers”</strong> means those users granted
          access to epocrates+ via Epocrates Services provided hereunder.
          Eligible Subscribers must be, and Client warrants that all Eligible
          Subscribers are, employees of Client that have been granted access to
          epocrates+ by Client in its exercise of reasonable discretion and with
          respect to which Client has obtained reasonable assurances that they
          will comply with the access and use terms and the confidentiality
          terms in this Agreement, and the Terms of Use.
          <br />
          <strong>“Terms of Use”</strong> means the click-through terms and
          conditions governing use of epocrates+.
          <br />
          <strong>“Epocrates”</strong> is Epocrates, LLC., a Delaware limited
          liability company with an office located at 80 Guest Street, Boston,
          MA 02135.
          <br />
          <strong>“Epocrates Platform”</strong> means, collectively, the
          internet-based Epocrates medical reference mobile application and
          website.
          <br />
          <strong>“epocrates+”</strong> is the enhanced user-level access to the
          Epocrates Platform that enables users to access additional content,
          such as: medical news; medical calculators; reference tables, clinical
          practice guidelines; drug content including drug monographs for brand,
          generic, alternative/herbal, and OTC medications; drug-drug
          interaction check; pill ID; health plan formularies; disease content
          including symptom review; infectious disease treatment selector
          (IDTx); laboratory reference; and ICD-10 coding; differential
          diagnosis, exam, and treatment; each as may be modified or removed by
          Epocrates from time to time.
          <br />
          <strong>“Epocrates Services”</strong> as used herein means Epocrates’
          provisioning of license keys, access codes, or a similar mechanism
          enabling Client to grant epocrates+ access to Eligible Subscribers.
          <br />
          <strong>“Notice”</strong> is defined in Section 12 of this Agreement.
          <br />
          <strong>“Party”</strong> means Epocrates or Client. “Parties” means
          Epocrates and Client.
          <br />
          <strong>“Proposal”</strong> means the order information page within
          the Ordering Workflow displaying the service, fees, and number of
          subscriptions being purchased pursuant to these terms.
          <br />
          <strong>“License Code”</strong> means the unique code contained in the
          onboarding instructions email allowing Eligible Subscribers to access
          the Epocrates Services, which will be delivered to Client on or
          shortly after the Effective Date.
          <br />
          <strong>"Service Term"</strong> means the 12-month period beginning on
          the date the License Code is first redeemed to enable use of the
          Epocrates Services.
          <br />
          <strong>Section 2. Epocrates Services and Payment.</strong>
          <br />
          (a) Epocrates will provide Epocrates Services and epocrates+ as
          described in this Agreement. The Parties agree to perform their
          respective obligations as set forth in this Agreement.
          <br />
          (b) On the Effective Date, Epocrates shall invoice Client, and Client
          shall pay, the Annual Service Fee in the amount set forth on the
          Proposal. Client will pay all invoices via the Ordering Workflow. The
          Annual Service Fee is non-refundable.
          <br />
          (c) Client must redeem the License Code within 90 days of the
          Effective Data. If Client does not redeem the License Code within 90
          days of the Effective Date, such License Code will automatically
          expire; in such an event, Client may request, and Epocrates may in its
          discretion issue, a new License Code, provided that Epocrates is not
          obligated to issue a new License Code.
          <br />
          <strong>Section 3. Term and Termination.</strong>
          <br />
          (a) The term of this Agreement will begin on the Effective Date and
          will continue until the last day of the Service Term, unless
          terminated earlier in accordance with this Agreement.
          <br />
          (b) Either Party may terminate this Agreement immediately upon Notice
          to the other if the other Party breaches any material term or
          condition of this Agreement.
          <br />
          <strong>Section 4. Confidential Information.</strong> Each Party will
          take reasonable steps and exercise reasonable care to hold any
          Confidential Information in confidence and not use it or disclose it
          to any other person or entity, except (i) as permitted under this
          Agreement or as reasonably necessary for the performance or
          enforcement of this Agreement; (ii) as agreed in writing by the other
          Party; (iii) for the Party’s proper management and administration
          (provided that it obtains reasonable assurances from all recipients
          that they will keep the information confidential and use it only for
          the purpose of its disclosure); or (iv) as required by law.
          <br />
          <strong>Section 5. Restrictions</strong> Client and Eligible
          Subscribers may not use, copy, modify, or transfer Epocrates Services
          or epocrates+ or any copies thereof, in whole or in part, except as
          expressly provided in this Agreement or clearly permitted by
          Epocrates. Client may not reverse engineer, disassemble, decompile, or
          translate Epocrates Services or epocrates+, or otherwise attempt to
          derive the source code of Epocrates Services or epocrates+, or
          authorize any third party to do any of the foregoing. Client is
          prohibited from developing, selling or distributing applications that
          are capable of launching, being launched from, or are otherwise
          integrated with, Epocrates Services or epocrates+ without the express
          written consent of Epocrates. Client may not rent, lease, loan, resell
          for profit, distribute, sublicense or use in a time-sharing
          arrangement Epocrates Services or epocrates+, or any part thereof.
          Each Eligible Subscriber will be required to click to agree to be
          bound by the applicable Terms of Use prior to using epocrates+. Use of
          or access to Epocrates Services or epocrates+ not in accordance with
          the terms of this Agreement is strictly prohibited. Epocrates may, in
          its sole discretion, limit or suspend permission to access or use the
          Epocrates Services or epocrates+ immediately if the terms of this
          Section 5 are violated. Client agrees that such violation would cause
          Epocrates irreparable and immediate harm and that Epocrates is
          entitled to injunctive relief to prevent such violation.
          <br />
          <strong>Section 6. Ownership</strong> Epocrates Services and
          epocrates+ are licensed, not sold, to Client for use only under the
          terms of this Agreement, and Epocrates reserves all rights not
          expressly granted to Client. For the avoidance of doubt, Epocrates
          Services and epocrates+ and the information contained in Epocrates
          Services and epocrates+ are the property of Epocrates or its
          licensor(s) and are protected by copyright and other intellectual
          property laws in the United States and other countries.
          <br />
          <strong>Section 7. Content Maintained by Epocrates.</strong> Client
          acknowledges and agrees that: (i) Epocrates or its third party
          licensors may, from time to time, elect to update information
          contained in Epocrates Services and epocrates+, but Epocrates does not
          warrant or guarantee that any product descriptions or other
          information contained in or accessed through Epocrates Services and
          epocrates+ will be updated at any time during the term of this
          Agreement; (ii) Epocrates does not assume, and expressly disclaims,
          any obligation to obtain and include any information in Epocrates
          Services and epocrates+; (iii) Epocrates Services and epocrates+
          include information provided to Epocrates by its licensors or other
          third parties, and Epocrates does not assume and expressly disclaims
          any responsibility for the accuracy of such third party content; (iv)
          Epocrates is not advocating the use of any product described in
          Epocrates Services and epocrates+, nor is Epocrates responsible for
          misuse of a product or procedure due to typographical or other errors
          in Epocrates Services and epocrates+, user negligence, or otherwise;
          and (v) Client acknowledges that Epocrates Services and epocrates+ are
          not intended to be (nor should they be used as) a substitute for the
          exercise of professional judgment. The content in Epocrates Services
          and epocrates+ was developed for use in the United States, and neither
          Epocrates nor its content providers make any representation concerning
          the content when used in any other country. While information in
          Epocrates Services and epocrates+ has been obtained from sources
          believed to be reliable, neither Epocrates nor its content providers
          warrant the accuracy of codes, prices, or other data contained in
          Epocrates Services and epocrates+. Epocrates does not give medical
          advice, nor provide medical or diagnostic services. Neither Epocrates
          nor its content providers guarantee that the content contained in
          epocrates+ covers all possible uses, directions, precautions, drug
          interactions, or adverse effects that may be associated with any
          treatments. Neither Epocrates nor its content providers assume any
          liability or responsibility for damage or injury (including death) to
          any persons arising from use of any product, information, idea, or
          instruction contained in the content or services provided under this
          Agreement. Use of and access to Epocrates Services and epocrates+,
          including, but not limited to, clinical information, is at the sole
          risk and responsibility of Client and any practitioner or health care
          provider or facility using data provided by Epocrates as part of
          Epocrates Services or epocrates+.
          <br />
          <strong>Section 8. Compliance.</strong> Each Party will comply with
          Applicable Law.
          <br />
          <strong>Section 9. Warranties and Limitations.</strong>
          <br />
          (a) Except as otherwise expressly provided herein, Epocrates
          undertakes no obligation to provide error-free or fault-free items or
          services, and Epocrates Services and epocrates+ are provided “as is”
          with all faults and defects.
          <br />
          (b) EXCEPT AS EXPRESSLY PROVIDED HEREIN, EPOCRATES AND ITS AFFILIATES,
          AGENTS, AND LICENSORS (i) CANNOT AND DO NOT WARRANT THE ACCURACY,
          COMPLETENESS, CURRENCY, OR NON-INFRINGEMENT OF ANY EPOCRATES SERVICES
          OR EPOCRATES+ PROVIDED HEREUNDER; AND (ii) EXPRESSLY DISCLAIM ALL
          REPRESENTATIONS AND WARRANTIES OF ANY KIND OR NATURE, EXPRESS OR
          IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), WITH RESPECT TO ANY
          SERVICE OR ITEM PROVIDED HEREUNDER, INCLUDING, BUT NOT LIMITED TO, ANY
          WARRANTY OF MERCHANTABILITY, TITLE, NON- INFRINGEMENT, OR FITNESS FOR
          A PARTICULAR PURPOSE AND ANY WARRANTY ARISING FROM CONDUCT, COURSE OF
          DEALING, CUSTOM, OR USAGE IN TRADE.
          <br />
          (c) No claim against Epocrates of any kind under any circumstances
          will be filed more than 1 year after Client knows of, or in the
          exercise of reasonable care could know of, such claim or an act or
          omission of Epocrates that would give rise to such claim.
          <br />
          <strong>
            Epocrates’ cumulative, aggregate liability in connection with or
            arising in any way or in any degree from this Agreement, from
            Epocrates Services or epocrates+, or otherwise from the acts or
            omissions of Epocrates under any and all legal theories will not
            exceed the Annual Service Fees paid by Client hereunder.
            Notwithstanding anything to the contrary in this agreement,
            Epocrates will not be liable under any legal theory for indirect,
            exemplary, punitive, special, incidental, or consequential damages
            or losses; lost profits or business opportunities; or the cost of
            procurement of substitute items or services. The exclusions stated
            in this paragraph also apply to Epocrates’ suppliers.
          </strong>
          Client hereby acknowledges that the remedies set forth above are
          reasonable and will not fail of their essential purpose.
          <br />
          <strong>Section 10. Force Majeure.</strong> No failure, delay, or
          default in performance of any obligation under this Agreement (other
          than payment obligations) will constitute a breach of this Agreement
          if it is caused by strike, fire, shortage of materials, act of a
          public authority, civil disorder, riot, vandalism, war, severe
          weather, natural disaster or other act of god; terrorism; acts or
          omissions of carriers, transmitters, telephone companies, Internet
          service providers, hackers, or other cause that is beyond the
          reasonable control of the Party otherwise chargeable, for so long as
          such cause continues and for a reasonable period of time thereafter.
          <br />
          <strong>Section 11. Choice of Law; Forum.</strong> Any dispute arising
          out of or relating to this Agreement, including any conduct related to
          this Agreement following termination hereof (each, a “Dispute”) will
          be governed exclusively by the laws of the Commonwealth of
          Massachusetts, without regard to its conflicts of laws principles. The
          Federal District Court for the District of Massachusetts or the
          business litigation section of the state superior court of
          Massachusetts will be the exclusive venue for any resolution of any
          Dispute. The Parties hereby submit to and consent irrevocably to the
          jurisdiction of such courts for these purposes.{" "}
          <strong>
            The Parties hereby irrevocably waive any and all right to trial by
            jury in any legal proceeding arising out of or related to this
            Agreement.
          </strong>
          <br />
          <strong>Section 12. Notice.</strong> “Notice” under this Agreement
          will mean written notification addressed to Client or Epocrates. With
          respect to Client, notice must be sent to the individual identified in
          the Ordering Workflow at the address listed therein. With respect to
          Notice to Epocrates, Notice must be sent to the address set forth in
          Section 1 above. Notice must be (i) delivered by hand; (ii) sent by
          traceable nationwide parcel delivery service, overnight or next
          business day service; or (iii) sent by certified United States mail.
          Properly mailed Notice will be deemed given 3 days after the date of
          mailing, and other Notice will be deemed made when received. A Party
          may change its address for notice purposes by providing Notice of such
          change to the other Party.
          <br />
          <strong>Section 13. Taxes and Duties.</strong> The Annual Service Fees
          do not include taxes and other similar charges. Client is responsible
          for, and, to the extent applicable, shall pay Epocrates for, all
          taxes, including sales, use, value-added, general services, gross
          receipts, excise, personal property, or other federal, state, or local
          taxes, import duties, or any similar assessments based on the
          provision of Epocrates Services, epocrates+, or other services
          provided under this Agreement, and any penalties, interest, and
          collection or withholding costs associated with any of the foregoing
          items, excluding taxes on Epocrates’ net income and net worth. Client
          shall reimburse Epocrates for any fines, penalties, taxes, and other
          charges, including expenses incurred by Epocrates, due to Client’s
          submission of invalid information.
          <br />
          <strong>Section 14. Miscellaneous.</strong> This Agreement constitutes
          the entire agreement between the Parties relating to Epocrates
          Services and epocrates+ and supersedes all prior agreements,
          understandings, and representations relating to Epocrates Services and
          epocrates+. Any additional or conflicting terms and conditions
          (preprinted or otherwise) stated in a Client purchase order or similar
          form, attached thereto, or otherwise incorporated by reference shall
          be void and of no effect. No change in this Agreement will be
          effective or binding unless signed by Client and a duly authorized
          officer of Epocrates. Neither Party will assign this Agreement without
          the written consent of the other, provided that either Party may
          assign this Agreement with no less than 90 days prior Notice as part
          of a corporate reorganization, consolidation, merger, change of
          control with respect to its outstanding stock, or sale of
          substantially all of its assets, and provided further that the
          assigning Party and the assignee will remain liable for any
          unperformed obligations under this Agreement arising prior to the
          effective date of any such transaction. Epocrates may, without
          providing notice to or obtaining the consent of Client, assign this
          Agreement to its parent company. This Agreement will be binding on the
          Parties and their successors and permitted assigns. Nothing contained
          in this Agreement will be construed to create a joint venture,
          partnership, or like relationship between the Parties, and their
          relationship is and will remain that of independent Parties to a
          contractual service relationship. In no event will either Party be
          liable for the debts or obligations of the other Party. Client may not
          use Epocrates logos, copyrights, trademarks, service marks, or
          tradenames to advertise, market, promote, or publicize in any manner
          its use of and access to Epocrates Services and epocrates+ without the
          express written consent of Epocrates in each instance. Client
          authorizes Epocrates to use Client’s name, logo, or other identifying
          mark in advertising promotions or other commercial materials for the
          sole and limited purpose of promoting, marketing, or advertising
          Epocrates Services and epocrates+, in whole or in part, without
          review, approval, credit, or attribution, notification, or payment
          from or to Client, unless prohibited by law. Except as explicitly set
          forth herein, none of the provisions of this Agreement will be for the
          benefit of or enforceable by any third party. Section titles are for
          convenience only and will not affect the meaning of this Agreement. No
          failure by a Party to insist upon the strict performance of any term
          or condition of this Agreement or to exercise any right or remedy
          hereunder will constitute a waiver. In connection with Epocrates
          Services, a copy of a signed document sent by PDF or telephone fax
          will be deemed an original in the hands of the recipient. If any term
          or provision of this Agreement is invalid, illegal or unenforceable,
          such invalidity, illegality or unenforceability shall not affect any
          other term or provision of this Agreement or invalidate or render
          unenforceable such other term or provision. The following portions of
          this Agreement will survive termination and continue in force:
          Sections 4 through 7, 9, and 11 through 14.
        </Box>
        <div className={clsx(classes.flexContainer)}>
          <Checkbox
            name="checkAgreement"
            classes={{
              colorSecondary: clsx(classes.checkBoxChecked),
            }}
            checked={agreementChecked}
            data-testid="checkAgreement"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAgreementChecked(event.target.checked)
            }
          />
          <Typography className={clsx(classes.fontText, classes.agreementText)}>
            {strings.agreementCheckbox}
          </Typography>
        </div>
        <div className={clsx(classes.ButtonLink)}>
          <div className={clsx(classes.subFormButtonFields)}>
            <Button
              name={strings.confirm_payment}
              id="commitPurchase"
              type="button"
              width="264px"
              disabled={!agreementChecked}
              backgroundColor={colors["--primary-tealnight"]}
              hoverForeground={colors["--primary-tealnight"]}
              onClick={handlePurchase}
              inProgress={confirmButtonSpinner}
              data-testid="continueTest"
            />
          </div>
        </div>
      </div>
    </>
  );
};
