import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
  V2Subscription,
  getUserV2Subscriptions,
} from "src/services/subscriptionService";

export const RetriveUserSubscription = (
  userId: number,
  isDeviceEnabled?: boolean
): UseQueryResult<V2Subscription[]> =>
  useQuery<V2Subscription[]>(
    ["userId", { userId }],
    () => getUserV2Subscriptions(userId, isDeviceEnabled),
    {
      enabled: userId !== undefined && userId !== -1,
      retry: 3,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
