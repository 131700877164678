import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import brandLogoImage from "../../images/brand-logo-secondary-midnight.svg";

export const ApplicationAltTitle = () => {
  const applicationStyle = makeStyles((theme) => ({
    applicationTitle: {
      width: "340px",
      height: "78px",
      backgroundImage: `url(${brandLogoImage})`,
      backgroundSize: "cover",
      [theme.breakpoints.down("xs")]: {
        width: "224px",
        height: "52px",
      },
      [theme.breakpoints.up("md")]: {},
      backgroundRepeat: "no-repeat",
    },
  }));
  const classes = applicationStyle();
  return (
    <a
      data-testid="online_redirect"
      href="https://www.epocrates.com/online"
      aria-label="visit epocrates online"
    >
      <div className={clsx(classes.applicationTitle)} />
    </a>
  );
};
