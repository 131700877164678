import { makeStyles } from "@material-ui/core";
import { colors } from "../config/colorConfig";

export const globalDialogStyles = makeStyles((theme) => ({
  paymentLink: {},
  popUpPrice: {
    marginTop: `${theme.spacing(4)}px`,
    fontWeight: 300,
    fontSize: "28px",
    lineHeight: "30px",
    color: `${colors["--primary-midnight"]}`,
  },
  popUpSmallPrice: {
    marginTop: `${theme.spacing(2)}px`,
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "20px",
    color: `${colors["--primary-midnight"]}`,
  },
  popUpSmallPromoPrice: {
    marginTop: `${theme.spacing(2)}px`,
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "20px",
    color: `${colors["--primary-orange"]}`,
  },
  popUpSmallTitle: {
    marginTop: `${theme.spacing(2)}px`,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: `${colors["--primary-midnight"]}`,
  },
  popUpSmallBigPrice: {
    marginTop: `${theme.spacing(2)}px`,
    fontWeight: 300,
    fontSize: "28px",
    lineHeight: "30px",
    color: `${colors["--primary-midnight"]}`,
  },
  popUpSubTitle: {
    margin: `${theme.spacing(6)}px ${theme.spacing(6)}px ${theme.spacing(
      3
    )}px ${theme.spacing(6)}px`,
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
    color: `${colors["--primary-midnight"]}`,
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  popUpTotalTitle: {
    marginTop: `${theme.spacing(4)}px`,
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
    color: `${colors["--primary-midnight"]}`,
    "& span": {
      fontSize: 14,
      lineHeight: 1.43,
      fontWeight: 400,
    },
  },
  popUpSmallTotalTitle: {
    marginTop: `-${theme.spacing(1)}px`,
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
    color: `${colors["--primary-midnight"]}`,
  },
  grid: {
    width: "85%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  popUpText: {
    margin: `0px ${theme.spacing(6)}px 0px ${theme.spacing(6)}px`,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: `${colors["--ui-midnight"]}`,
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  popUpTextGray: {
    margin: `0px ${theme.spacing(6)}px ${theme.spacing(6)}px ${theme.spacing(
      6
    )}px`,
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: `${colors["--ui-slate"]}`,
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  popUpSubText: {
    margin: `${theme.spacing(4)}px 0px 0px ${theme.spacing(6)}px`,
    fontSize: "16px",
    color: `${colors["--ui-slate"]}`,
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  popUpSubSubText: {
    marginTop: `${theme.spacing(4)}px`,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: `${colors["--ui-slate"]}`,
  },
  popupCommonText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.25,
    margin: `${theme.spacing(2)}px ${theme.spacing(6)}px 0`,
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  popUpForm: {
    display: "flex",
    flexDirection: "column",
  },
  popupPaymentDetailsResponsive: {
    border: `solid 2px ${colors["--ui-grey-light"]}`,
    borderRadius: "4px",
    padding: `${theme.spacing(2)}px 0`,
    width: "712px",
    fontFamily: "Source Sans Pro",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      maxWidth: "712px",
    },
  },
  popupPaymentDetailsRow: {
    display: "flex",
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
    borderBottom: `1px solid ${colors["--ui-grey-light"]}`,
    alignItems: "center",
    "&:last-child": {
      borderBottom: "none",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  popupPaymentDetailsRowLabel: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.25,
    color: colors["--primary-midnight"],
    margin: 0,
    flexShrink: 0,
    width: 90,
    [theme.breakpoints.down("xs")]: {
      width: 75,
    },
  },
  popupPaymentDetailsRowValue: {
    margin: 0,
    flexGrow: 1,
  },
  popupPaymentDetailsCCNumber: {
    display: "flex",
    alignItems: "center",
  },
  wrapperDiv: {
    margin: `0px ${theme.spacing(6)}px`,
    [theme.breakpoints.down("xs")]: {
      margin: "0 16px",
    },
  },
  wrapperDivTop32: {
    paddingTop: theme.spacing(8),
  },
  wrapperDivTop24: {
    paddingTop: theme.spacing(6),
  },
  wrapperDivTop16: {
    paddingTop: theme.spacing(4),
  },
  wrapperDivTop8: {
    paddingTop: theme.spacing(2),
  },
  pmtFieldWrapper: {
    display: "flex",
    alignItems: "flex-start",
    margin: `${theme.spacing(6)}px 0px`,
    marginLeft: "-12px",
    minHeight: 83,
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      marginBottom: theme.spacing(4),
    },
  },
  pmtFieldFirstChild: {
    width: "54%",
    paddingLeft: "12px",
  },
  pmtFieldSecondChild: {
    width: "23%",
    paddingLeft: "12px",
  },
  pmtMobileFull: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
  },
  pmtMobileHalf: {
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  pmtMobile60: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  pmtMobile40: {
    [theme.breakpoints.down("xs")]: {
      width: "40%",
    },
  },
  pmtMobile35: {
    [theme.breakpoints.down("xs")]: {
      width: "35%",
    },
  },
  pmtMobile30: {
    [theme.breakpoints.down("xs")]: {
      width: "30%",
    },
  },
  pmtMobile25: {
    [theme.breakpoints.down("xs")]: {
      width: "25%",
    },
  },
  formFields: {
    margin: `${theme.spacing(2)}px 0px`,
    "& input": {
      width: "50%",
    },
  },
  splitSubFormFields: {
    display: "flex",
    alignItems: "flex-start",
    margin: `${theme.spacing(2)}px 0px`,
    marginLeft: "-24px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(3),
    },
  },
  customInputField: {
    margin: "8px 0px 5px 0px",
  },
  invalidInput: {
    border: "1.5px solid #ff0000",
  },
  validInput: {
    border: "1.5px solid #228b22",
  },

  subFormFields: {
    width: "33.3%",
    flexShrink: 0,
    flexGrow: 0,
    paddingLeft: "24px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  buttonFields: {
    width: "27%",
  },
  spanItems: {
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
  },
  ButtonLink: {
    display: "flex",
    alignItems: "center",
    margin: `${theme.spacing(6)}px 0px ${theme.spacing(6)}px -${theme.spacing(
      6
    )}px`,
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(3),
    },
  },
  vindiciaLabel: {
    marginBottom: 0,
    width: "100%",
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 500,
    color: colors["--primary-midnight"],
  },
  subFormButtonFields: {
    flexGrow: 0,
    minWidth: 170,
    paddingLeft: 24,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  subFormButtonFieldsSmall: {
    width: "33.3%",
    flexShrink: 0,
    flexGrow: 1,
    paddingLeft: "24px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  cancelButton: {
    flexGrow: 0,
    paddingLeft: 24,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  expDate: {
    width: "28%",
    flexGrow: 0,
  },
  cardCVV: {
    flexGrow: 1,
  },
  cancelLink: {
    color: colors["--title-black"],
    "&:hover": {
      backgroundColor: colors["--text-invert"],
    },
    "&:focus": {
      outline: "none",
    },
  },
  toastText: {
    color: colors["--ui-slate"],
    width: "400px",
    marginRight: theme.spacing(10),
  },
  supportLink: {
    color: colors["--primary-teal"],
    "&:hover": {
      backgroundColor: colors["--text-invert"],
    },
    "&:focus": {
      outline: "none",
    },
  },
  cancel: {
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: 500,
    color: colors["--ui-blue-light"],
    padding: 0,
    minWidth: "auto",
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(3),
    },
  },
  spinnerPaper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  spinnerBox: {
    paddingBottom: `${theme.spacing(6)}px`,
    alignSelf: "center",
  },
  noLeftRightMargin: {
    marginLeft: 0,
    marginRight: 0,
  },
  noTopMargin: {
    marginTop: 0,
  },
  fontWeightNormal: {
    fontWeight: 400,
  },
  topMargin8: {
    marginTop: theme.spacing(2),
  },
  fontText: {
    fontFamily: "Source Sans Pro",
    fontSize: 12,
    fontWeight: 400,
    color: colors["--primary-midnight"],
  },
  textArea: {
    marginTop: theme.spacing(6),
    width: "712px",
    height: "200px",
    overflowY: "scroll",
    textAlign: "left",
    border: "1px solid #ccc",
    whiteSpace: "pre-line",
    borderRadius: 4,
    padding: 4,
    [theme.breakpoints.down(1200)]: {
      maxWidth: "712px",
      width: "100%",
    },
  },
  subTextArea: {
    marginTop: theme.spacing(6),
    width: "660px",
    height: "64px",
    overflowY: "scroll",
    textAlign: "left",
    border: "1px solid #ccc",
    whiteSpace: "pre-line",
    borderRadius: 4,
    padding: 4,
  },
  flexContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  agreementText: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  checkBoxChecked: {
    color: `${colors["--ui-gray"]} !important`,
  },
  errorText: {
    color: `${colors["--error"]} !important`,
  },
  licenseSummary: {
    width: 300,
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(6),
    [theme.breakpoints.down(390)]: {
      paddingRight: theme.spacing(3),
      width: "100%",
    },
  },
  overlay: {
    zIndex: 1401,
  },
  cardLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
