import { Card } from "@mui/material";
import { styled } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { colors } from "../config/colorConfig";
import { Button } from "./custom/Button";
import { UseQueryResult } from "@tanstack/react-query";
import { PageType, SubscriptionContext } from "src/context/SubscriptionContext";
import { Product } from "src/services/subscriptionService";
import { sendAmplitudeEvent } from "src/utils/AmplitudeUtil";
import { ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CONTINUE_CLICK } from "src/utils/analytics";

const CircleDiv = styled("div")({
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",
});

const InnerCircleDev = styled("div")({
  width: "18px",
  height: "18px",
  borderRadius: "50%",
  backgroundColor: `${colors["--primary-tealnight"]}`,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  transition: "opacity 0.3s ease",
});

const ShortDescription = styled("div")({
  fontFamily: "Source Sans Pro",
  fontWeight: 700,
  margin: "4px 0px",
  fontSize: "20px",
  lineHeight: "24px",
  color: colors["--primary-midnight"],
});
const PriceDiv = styled("div")({
  fontFamily: "PT Serif",
  fontWeight: 700,
  margin: "4px 0px",
  fontSize: "24px",
  lineHeight: "32px",
  color: colors["--primary-midnight"],
});
const DescriptionDiv = styled("div")({
  fontFamily: "Source Sans Pro",
  fontWeight: 400,
  margin: "4px 0px",
  fontSize: "18px",
  lineHeight: "24px",
  color: colors["--primary-midnight"],
});
const TagDiv = styled("div")({
  backgroundColor: colors["--mid-off-white"],
  alignContent: "center",
  padding: "0px 5px",
  borderRadius: "5px",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "16px",
  fontFamily: "Source Sans Pro",
});
const SelectCard = styled(Card)(({ theme }) => ({
  padding: "16px",
  transition: "transform 0.3s",
  "&:hover": {
    transform: "scale(1.01)",
  },
  [theme.breakpoints.only("xs")]: {
    height: "196px",
  },
}));

interface UserSelectSubscriptionProps {
  deviceManagementDecision?: { enabled: boolean };
  setSelectProduct: (request: Product) => void;
  selectProduct: Product | undefined;
  productQueryResponse: UseQueryResult<Product[], unknown>;
  setPromoCode: (promoCode: string) => void;
  promoCode: string;
}
export const UserSelectSubscription = (props: UserSelectSubscriptionProps) => {
  const { data: userProducts, isSuccess: isSuccessUserProducts } =
    props.productQueryResponse;
  const [filteredUserProducts, setFilteredUserProducts] = useState<Product[]>(
    []
  );
  const [buttonTitle, setButtonTitle] = useState<string>(
    "Continue with yearly"
  );
  const { setCurrentPage, setUserNavigation, userNavigation } =
    useContext(SubscriptionContext);

  //display to add space after 1
  const quantityToAlphabetic = {
    [1 as number]: "one ",
    [2 as number]: "two ",
    [3 as number]: "three ",
    [4 as number]: "four ",
  };

  const onCardClick = (product: Product) => {
    product.trial_period?.unit === "WEEK"
      ? setButtonTitle(`Continue with yearly + two week trial`)
      : setButtonTitle(
          `Continue with ${
            quantityToAlphabetic[product.billing_period.quantity]
          }${product.billing_period.unit.toLowerCase()}`
        );
    props.setSelectProduct(product);
  };

  const onClickToPurchase = () => {
    setCurrentPage(PageType.PaymentBilling);
    setUserNavigation([...userNavigation, PageType.PaymentBilling]);
    sendAmplitudeEvent(ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CONTINUE_CLICK, {
      "Promo Code": props.promoCode,
      sku: props.selectProduct?.platform_sku,
    });
  };

  useEffect(() => {
    if (isSuccessUserProducts) {
      const filteredProducts = userProducts.filter(
        (product) =>
          product.billing_period.unit !== "MONTH" &&
          product.billing_period.unit !== "DAY"
      );
      const sortedProducts = filteredProducts.sort(
        (product1, product2) => product1.display_order - product2.display_order
      );
      setFilteredUserProducts(sortedProducts);
      if (
        !props.selectProduct ||
        props.selectProduct.billing_period.unit === "MONTH"
      ) {
        props.setSelectProduct(sortedProducts[0]);
      }
    }
  }, [isSuccessUserProducts]);

  return (
    <>
      {filteredUserProducts.map((product) => (
        <>
          <SelectCard
            data-testid="selectedCard"
            sx={{
              boxShadow: 4,
              border:
                props.selectProduct &&
                props.selectProduct.platform_sku === product.platform_sku
                  ? "1px solid black"
                  : "none",
            }}
            onClick={() => onCardClick(product)}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TagDiv>{product.tag_description}</TagDiv>
              <CircleDiv
                data-testid="outerCircle"
                sx={{
                  border:
                    props.selectProduct &&
                    props.selectProduct.platform_sku === product.platform_sku
                      ? `3px solid ${colors["--primary-midnight"]}`
                      : `2px solid ${colors["--ui-grey-med"]}`,
                }}
              >
                {props.selectProduct &&
                  props.selectProduct.platform_sku === product.platform_sku && (
                    <InnerCircleDev data-testid="selectedCircle" />
                  )}
              </CircleDiv>
            </div>
            <ShortDescription data-testid="shortDescription">
              {product.short_description}
            </ShortDescription>
            <PriceDiv data-testid="priceValue">
              {`$${product.price?.amount}/${
                product.billing_period.quantity > 1
                  ? quantityToAlphabetic[product.billing_period.quantity]
                  : ""
              }${product.billing_period.unit.toLowerCase()}${
                product.billing_period.quantity > 1 ? "s" : ""
              }`}
            </PriceDiv>
            <DescriptionDiv data-testid="addtionalDescription">
              {product.additional_description}
            </DescriptionDiv>
          </SelectCard>
          <div style={{ padding: "12px" }} />
        </>
      ))}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "12px",
        }}
      >
        <Button
          name={buttonTitle}
          fontSize={18}
          fontFamily="Source Sans Pro"
          id="selectedContinue"
          testId="selectedContinue"
          backgroundColor={colors["--primary-tealnight"]}
          hoverForeground={colors["--primary-tealnight"]}
          width={343}
          onClick={onClickToPurchase}
        />
      </div>
      <div style={{ height: "40px" }}></div>
    </>
  );
};
