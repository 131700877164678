import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { authCodeRedeemUserAction } from "src/actions/authenticationActions";
import { AuthorizationCodeRedeemRequest } from "src/models/AuthorizationCodeRedeemRequest";
import { ProgressPage } from "./ProgressPage";
import { useDispatch } from "react-redux";
import { getCookie } from "src/utils/cookies";

interface AuthenticationReduxState {
  attemptingAuthorizationCodeRedemption: boolean;
  completedAuthorizationCodeRedemptionAttempt: boolean;
  completedAuthorizationCodeRedemptionAttemptFailed: boolean;
}

interface AuthorizationDevicesRouteProps {
  authorizationCode: string;
  state: AuthenticationReduxState;
  component: React.JSXElementConstructor<any>;
}

export const AuthorizationDevicesRoute = (
  props: AuthorizationDevicesRouteProps
) => {
  const { authorizationCode, state, component: Component } = props;
  const dispatch = useDispatch();
  const accessToken = getCookie("accessToken");

  useEffect(() => {
    // Redeems the accessToken & userId from authroization code
    if (
      authorizationCode &&
      !state.completedAuthorizationCodeRedemptionAttempt &&
      !state.attemptingAuthorizationCodeRedemption
    ) {
      const authorizationCodeRedeemRequest: AuthorizationCodeRedeemRequest = {
        authorizationCode,
      };
      dispatch(authCodeRedeemUserAction(authorizationCodeRedeemRequest));
    }
  }, [state]);

  if (
    (authorizationCode || state.attemptingAuthorizationCodeRedemption) &&
    !state.completedAuthorizationCodeRedemptionAttempt
  ) {
    const progress = (
      <>
        <ProgressPage
          data-testid="progressValidationProfile"
          bodyTitle
          inProgress
        />
      </>
    );
    return <Route render={() => progress} />;
  }

  if (
    authorizationCode &&
    accessToken &&
    !state.attemptingAuthorizationCodeRedemption &&
    !state.completedAuthorizationCodeRedemptionAttemptFailed
  ) {
    return (
      <Route
        path="/account/devices"
        render={() => (
          <Component {...props} authorizationCode={authorizationCode} />
        )}
      />
    );
  }
  return (
    <Redirect
      to={{
        pathname: "/login",
        search: `?refernext=${window.location.origin}${window.location.pathname}`,
      }}
    />
  );
};
