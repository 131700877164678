import { put, call, CallEffect, PutEffect } from "redux-saga/effects";
import * as types from "../actions";
import { npiService } from "../services/npiService";
import {
  NpiLookupResponse,
  NpiTupleFromResponse,
} from "../models/NpiLookupResponse";
import { AxiosResponse } from "axios";

export function* npiLookupSaga(request: any): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  try {
    yield put({ type: types.GET_NPI_LIST_PROGRESS });
    const response = yield call(npiService, request.npiLookupRequest);
    if (response?.data?.results?.length) {
      response.data.results = response.data.results.map(
        (item: NpiTupleFromResponse) => {
          const specialtyId = parseInt(item.specialtyId as string);
          return {
            ...item,
            specialtyId: !Number.isNaN(specialtyId) ? specialtyId : null,
          };
        }
      );
    }
    const npiLookupResponse: NpiLookupResponse = {
      data: response.data,
      status: response.status,
      done: false,
    };
    yield put({ type: types.GET_NPI_LIST_SUCCESS, npis: npiLookupResponse });
  } catch (error) {
    yield put({ type: types.GET_NPI_LIST_ERROR, error });
  }
}
