import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

export const Container = (props: any) => {
  const styles = makeStyles((theme) => ({
    containerWrapper: {
      width: 1140,
      maxWidth: "100%",
      margin: "auto",
    },
  }));
  const classes = styles(props);
  return (
    <div data-testid="container" className={clsx(classes.containerWrapper)}>
      {props.children}
    </div>
  );
};
