import * as React from "react";
import clsx from "clsx";
import { List, ListItem, Typography, makeStyles } from "@material-ui/core";
import { withRoot } from "../withRoot";
import { colors } from "../config/colorConfig";
import { Footer } from "./custom/Footer";
import { leftNavContent } from "../config/accountCreationConfig";
import { strings } from "../utils/Strings";
import { ApplicationAltTitle } from "./custom/ApplicationAltTitle";

const CreateAccountLeftNav = () => {
  const leftPaneStyle = makeStyles((theme) => ({
    wrapper: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      backgroundRepeat: "no-repeat",
      width: "50%",
      flexGrow: 0,
      flexShrink: 0,
      position: "relative",
    },
    container: {
      width: "100%",
      height: "782px",
      margin: "auto 0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "80px",
      },
    },
    title: {
      color: colors["--text-invert"],
      fontFamily: "PT Serif",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      marginTop: "56px",
    },
    message: {
      color: colors["--text-invert"],
      fontFamily: "Source Sans Pro",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "32px",
    },
    messageBottom: {
      color: colors["--text-invert"],
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      marginTop: "24px",
    },
  }));
  const classes = leftPaneStyle();
  return (
    <div className={clsx(classes.wrapper)}>
      <div className={clsx(classes.container)}>
        <ApplicationAltTitle />
        <div className={clsx(classes.title)} data-testid="title">
          {strings.create_account_message_title}
        </div>
        <div className={clsx(classes.message)} data-testid="message">
          {strings.create_account_message_1}
        </div>
        <div className={clsx(classes.messageBottom)}>
          {strings.create_account_message_2}
        </div>
      </div>
    </div>
  );
};
export const CreateAccountLeftNavWith = withRoot(CreateAccountLeftNav);
