import React, { ReactElement } from "react";
import { makeStyles, Box } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../../config/colorConfig";
import epocratesBgNew from "../../images/epocrates-bg-new@1x.png";

interface LoginPasswordBackgroundProps {
  pageBody: ReactElement;
}

const loginPasswordBackground = (props: LoginPasswordBackgroundProps) => {
  const backgroundStyle = makeStyles((theme) => ({
    mainBox: {
      backgroundImage: `url(${epocratesBgNew})`,
      backgroundRepeat: "no-repeat",
      background: colors["--primary-midnight"],
      backgroundSize: "cover",
      [theme.breakpoints.up("md")]: {},
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    box: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
  }));
  const classes = backgroundStyle(props);
  return (
    <Box
      className={clsx(classes.mainBox)}
      display="flex"
      minHeight="100vh"
      minWidth="100h"
      data-testid="AppBackground"
      bgcolor={colors["--primary-midnight"]}
    >
      {props.pageBody}
    </Box>
  );
};

export const AppAltBackground = React.memo(loginPasswordBackground);
