import { createTheme } from "@material-ui/core";
import { colors } from "src/config/colorConfig";

export const licenseTheme = createTheme({
  typography: {
    fontFamily: ["Source Sans Pro, Arial, sans-serif"].join(","),
    h2: {
      fontSize: "32px",
      fontWeight: 600,
      lineHeight: "40px",
      color: "#06162D",
    },
    subtitle1: {
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: "#06162D",
    },
  },
  spacing: 4,
  overrides: {
    MuiCheckbox: {
      root: {
        padding: 0,
      },
      colorSecondary: {
        muiChecked: {
          color: colors["--ui-gray"],
        },
      },
    },
    MuiButton: {
      label: {
        fontFamily: "Source Sans Pro",
      },
    },
    MuiLink: {
      root: {
        fontFamily: "Source Sans Pro",
      },
    },
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: colors["--ui-gray"],
          borderWidth: 1,
        },
        "not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: colors["--ui-gray"],
          "@media (hover: none)": {
            borderColor: colors["--ui-gray"],
          },
        },
        "&$focused $notchedOutline": {
          borderColor: colors["--ui-gray"],
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: colors["--ui-slate"],
        },
        color: colors["--ui-slate"],
      },
    },
    MuiTooltip: {
      tooltip: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "var(--primary-off-white, #EFF7FE)",
        width: 240,
        height: 70,
        radius: 4,
        padding: "8px, 16px, 8px, 16px",
        gap: 8,
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      },
      arrow: {
        "&:before": {
          border: "1px solid #E6E8ED",
        },
        color: "var(--primary-off-white, #EFF7FE)",
      },
    },
  },
});
