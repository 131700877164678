import {
  Footer,
  Navigation,
  getCookie,
  setAmplitudeCallback,
} from "@athena/epoc-frontend-library/dist";
import {
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Theme,
  ThemeProvider,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { OptimizelyDecision, useDecision } from "@optimizely/react-sdk";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import React, { useContext, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { ContentCardWithRoot } from "src/components/ContentCard";
import { DevicesWithRoot } from "src/components/Devices";
import { SignOutModal } from "src/components/SignOutModal";
import { Button } from "src/components/custom/Button";
import { Device } from "src/models/Device";
import { RetriveDevicesList } from "src/queries/GetDevicesListQuery";
import { RetriveUserProducts } from "src/queries/GetUserProducts";
import { RetriveUserSubscription } from "src/queries/GetUserSubscription";
import { GetPrimaryPaymentMethod } from "src/queries/PaymentMethodDetailQuery";
import { TransactionTypes } from "src/queries/TransactionTypesQuery";
import { updateDevicesList } from "src/services/deviceService";
import { licenseTheme } from "src/styles/licenseTheme";
import { DeviceStatus } from "../components/Devices";
import {
  notificationCenterFlag,
  recentlyViewedFlag,
  savedEnabledFlag,
  webNavigationRedesignFlag,
  notificationBadgeFlag,
  hnsLandingFlag,
} from "src/utils/optimizelyFlag";
import {
  countriesAction,
  occupationsAction,
  specialtiesAction,
  subSpecialtiesAction,
} from "../actions/demographicsActions";
import { getUserDetailsAction, promptDialog } from "../actions/profileActions";
import {
  sendSuccessSnackBarMessage,
  sendSuccessSnackBarWithLinkMessage,
} from "../actions/snackBarActions";

import { AccountEmailPreferenceWithRoot as AccountEmailPreference } from "../components/AccountEmailPreference";
import { AccountProfileWithRoot } from "../components/AccountProfile";
import { ErrorScreentWithRoot as ErrorScreen } from "../components/ErrorScreen";
import { ProfileBanner } from "../components/ProfileBanner";
import { UserSubscriptionRightPaneV2 } from "../components/UserSubscriptionRightPaneV2";
import { Layout } from "../components/custom/Layout";
import { OverlaySpinner } from "../components/custom/OverlaySpinner";
import {
  LeftPane,
  RightPane,
  TwoPanesResp,
} from "../components/custom/TwoPanesResp";
import { Desktop, TabletMobile } from "../components/custom/responsive";
import { colors } from "../config/colorConfig";
import { boxShadow } from "../config/commonStyleConfig";
import {
  SidebarLinkType,
  leftSidebarLinks,
} from "../config/manageAccountConfig";
import keyboardArrowRightBlue from "../images/icons-streamline-regular-24-pt-keyboard-arrow-right-blue.svg";
import keyboardArrowRight from "../images/icons-streamline-regular-24-pt-keyboard-arrow-right.svg";
import { EmailPreferenceState } from "../models/EmailPreference";
import { PurchaseSubscriptionState } from "../models/PurchaseSubscriptionResponse";
import {
  sendAmplitudeEvent,
  sendLibraryAmplitudeData,
} from "../utils/AmplitudeUtil";
import { SnackBarType } from "../utils/SnackBarType";
import { strings } from "../utils/Strings";
import { isInternationalUser } from "../utils/accountCreationUtil";
import { SubscriptionContext } from "src/context/SubscriptionContext";
import {
  ACCOUNT_NAVIGATION_TAB_CLICK,
  ACCOUNT_NAVIGATION_TAB_CLICK_EVENT_ID,
  DEVICE_SIGNOUT_CANCEL,
  EOL_EXTRA_ITEMS_CLICK,
  PROFILE_SIGN_OUT_CONFIRMATION_CLICK,
  PROFILE_SIGN_OUT_CONFIRMED,
} from "../utils/analytics";
import { httpConstants } from "../utils/httpConstants";
import { CURRENT_USER_ID, CUSTOMER_NAME } from "../utils/localStorageKeys";
import { withRoot } from "../withRoot";

const DEFAULT_DEVICE_MANAGEMENT_VALUE = false;

const updateDevicesStatus = (allDevices: Device[]): Device[] => {
  return allDevices.map((data) => {
    return { ...data, deviceStatus: DeviceStatus.pendingManualSignout };
  });
};

const getTabfromPath = (path: string) => {
  switch (path) {
    case "/account/subscription":
      return strings.subscription_tab;
    case "/account/email":
      return strings.email_preferences_tab;
    case "/account/devices":
      return strings.devices_tab;
    default:
      return strings.profile_tab;
  }
};

interface IProps extends RouteComponentProps {
  deviceManagementDecision: OptimizelyDecision;
  authorizationCode: string;
  vindicia: {
    setup: () => void;
    destroy: () => void;
    isValid: () => boolean;
    resetCompleteStatus: () => void;
    clearData: () => void;
  };
  vindiciaFieldsSpinner: boolean;
}

const ManageAccount = (props: IProps) => {
  const { pathname } = useLocation();
  const [savedDecision] = useDecision(savedEnabledFlag);
  const [webNavDecision] = useDecision(webNavigationRedesignFlag);
  const [recentlyViewedDecision] = useDecision(recentlyViewedFlag);
  const [notificationDecision] = useDecision(notificationCenterFlag);
  const [hnsDecision] = useDecision(hnsLandingFlag);
  const isNotificationEnabled = notificationDecision.enabled;
  const isHnsEnabled = hnsDecision.enabled;
  const [notificationBadgeDecision] = useDecision(notificationBadgeFlag);
  const isNotificationBadgeEnabled = notificationBadgeDecision.enabled;
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const { setPlusUser } = useContext(SubscriptionContext);
  const [userIdValue, setUserIdValue] = useState(-1);
  setAmplitudeCallback(sendLibraryAmplitudeData);
  const dispatch = useDispatch();
  const emailPreferenceValues = useSelector(
    (emailPreferenceState: EmailPreferenceState) =>
      emailPreferenceState.emailPreference
  );
  const state = useSelector((reduxState: RootStateOrAny) => ({
    accountProfile: reduxState.accountProfile.accountProfileResponse,
    systemError: reduxState.accountProfile.system_error,
    isUpdateButtonDisabled: reduxState.accountProfile.isUpdateButtonDisabled,
    verify_progress: reduxState.accountProfile.verify_progress,
    specialtiesResponse: reduxState.accountProfile.specialtiesResponse,
    subSpecialtiesResponse: reduxState.accountProfile.subSpecialtiesResponse,
    countriesResponse: reduxState.accountProfile.countriesResponse,
    occupationsResponse: reduxState.accountProfile.occupationsResponse,
    specialties_progress: reduxState.accountProfile.specialties_progress,
    subSpecialties_progress: reduxState.accountProfile.subSpecialties_progress,
  }));
  const userId = localStorage.getItem(CURRENT_USER_ID);
  const activeTab = getTabfromPath(props.location.pathname);

  const devicesListQuery = RetriveDevicesList(
    Number(localStorage.getItem(CURRENT_USER_ID) ?? -1)
  );

  const userProductsQuery = RetriveUserProducts(
    Number(localStorage.getItem(CURRENT_USER_ID) ?? -1),
    props.deviceManagementDecision?.enabled ?? DEFAULT_DEVICE_MANAGEMENT_VALUE
  );

  const userSubscriptionQuery = RetriveUserSubscription(
    Number(localStorage.getItem(CURRENT_USER_ID) ?? -1),
    props.deviceManagementDecision?.enabled ?? DEFAULT_DEVICE_MANAGEMENT_VALUE
  );
  const {
    data: primaryPaymentResponse,
    isFetching: isPrimaryPaymentLoading,
    error: primaryMethodError,
    isFetched: isPrimaryPaymentFetched,
  } = GetPrimaryPaymentMethod(
    Number(localStorage.getItem(CURRENT_USER_ID) ?? -1),
    props.deviceManagementDecision?.enabled ?? DEFAULT_DEVICE_MANAGEMENT_VALUE
  );

  const onProfileSignOut = (devices: Device[]) => {
    const allDeviceType = devices.reduce((deviceTypeList: string[], device) => {
      let parsedDeviceName = "";
      const deviceName = device.deviceFamily.split(" ").at(0) ?? "";
      if (device.platform === "Apps") {
        parsedDeviceName = deviceName.includes("App")
          ? deviceName
          : `${deviceName} App`;
      } else {
        parsedDeviceName = deviceName;
      }
      if (!deviceTypeList.includes(parsedDeviceName)) {
        deviceTypeList.push(parsedDeviceName);
      }
      return deviceTypeList;
    }, []);
    const properties = {
      "Epoc Device ID": getCookie("ebi"),
      Browser: allDeviceType.join(", "),
      "Reason for Signout": "Device Manual Signout",
      Location: "Devices",
    };
    sendAmplitudeEvent(PROFILE_SIGN_OUT_CONFIRMED, properties, null);
  };

  const onProfileSignOutConfirmationClick = (allDevices: Device[]) => {
    const allDeviceType = allDevices.reduce(
      (deviceTypeList: string[], device) => {
        let parsedDeviceName = "";
        const deviceName = device.deviceFamily.split(" ").at(0) ?? "";
        if (device.platform === "Apps") {
          parsedDeviceName = deviceName.includes("App")
            ? deviceName
            : `${deviceName} App`;
        } else {
          parsedDeviceName = deviceName;
        }
        if (!deviceTypeList.includes(parsedDeviceName)) {
          deviceTypeList.push(parsedDeviceName);
        }
        return deviceTypeList;
      },
      []
    );
    const properties = {
      "Section Name": "All Devices",
      Browser: allDeviceType.join(", "),
      Location: "Devices",
    };
    sendAmplitudeEvent(PROFILE_SIGN_OUT_CONFIRMATION_CLICK, properties, null);
  };

  const onExtraItemClick = (name: string) => {
    const properties = {
      "Item name": name,
      Location: "Devices",
    };
    sendAmplitudeEvent(EOL_EXTRA_ITEMS_CLICK, properties, null);
  };

  // To make revenuecat call to find out platform (IAP or not)
  TransactionTypes(
    Number(userId),
    props.deviceManagementDecision?.enabled ?? DEFAULT_DEVICE_MANAGEMENT_VALUE
  );

  const [sideNavExpandedState, setSideNavExpandedState] = React.useState(
    getCookie("expanded") === "true"
  );
  const [isPremiumUser, setPremiumUser] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    const properties = {
      "Section Name": "All Devices",
    };
    setOpen(false);
    sendAmplitudeEvent(DEVICE_SIGNOUT_CANCEL, properties, null);
  };

  React.useEffect(() => {
    if (
      !state.accountProfile ||
      state.accountProfile.validation_done === false
    ) {
      dispatch(
        getUserDetailsAction(
          props.deviceManagementDecision?.enabled ??
            DEFAULT_DEVICE_MANAGEMENT_VALUE
        )
      );
    }

    if (
      !state.countriesResponse ||
      state.countriesResponse.status !== httpConstants.OK
    ) {
      dispatch(countriesAction());
    }
    if (
      !state.occupationsResponse ||
      state.occupationsResponse.status !== httpConstants.OK
    ) {
      dispatch(occupationsAction());
    }
  }, []);

  React.useEffect(() => {
    if (
      state.accountProfile?.userInformation &&
      !(state.specialtiesResponse?.data.length >= 0)
    ) {
      if (!state.specialties_progress) {
        dispatch(
          specialtiesAction(
            state.accountProfile.userInformation?.profileOccupation?.id || -1
          )
        );
      }
      if (
        !(state.subSpecialtiesResponse?.data.length >= 0) &&
        !state.subSpecialties_progress
      ) {
        dispatch(
          subSpecialtiesAction({
            occupationId:
              state.accountProfile?.userInformation?.profileOccupation?.id ||
              -1,
            specialtyId:
              state.accountProfile?.userInformation?.profileSpecialty?.id || -1,
          })
        );
      }
    }
  }, [
    state.accountProfile?.userInformation,
    state.specialtiesResponse?.data?.length,
    state.subSpecialtiesResponse?.data?.length,
    state.subSpecialties_progress,
    state.specialties_progress,
  ]);

  const updateDevicesMutation = useMutation(updateDevicesList, {
    onSuccess: (__, variables) => {
      const message = strings.all_devices_sign_out_description;
      const category = SnackBarType.INFO.displayValue;
      const title = strings.sign_out_title;
      onProfileSignOut(variables.devices);
      dispatch(sendSuccessSnackBarMessage(title, message, category));
      props.history.push("/logout?location=Devices");
    },
    onError: (__) => {
      const category = SnackBarType.ERROR.displayValue;
      const title = strings.error_title;
      dispatch(sendSuccessSnackBarWithLinkMessage(title, category));
      setOpen(false);
    },
  });

  const showSpinner = () => {
    if (activeTab === strings.profile_tab) {
      let isSubSpecialtyRequired = false;
      if (state.accountProfile?.userInformation?.profileSpecialty?.id) {
        isSubSpecialtyRequired = true;
      }
      return (
        !state.accountProfile?.userInformation ||
        !(state.specialtiesResponse?.data.length >= 0) ||
        (isSubSpecialtyRequired &&
          !(state.subSpecialtiesResponse?.data.length >= 0)) ||
        (userSubscriptionQuery.isLoading &&
          !userSubscriptionQuery.isRefetching) ||
        (devicesListQuery.isLoading && !devicesListQuery.isRefetching)
      );
    } else {
      return (
        isPrimaryPaymentLoading ||
        (userSubscriptionQuery.isLoading &&
          !userSubscriptionQuery.isRefetching) ||
        (userProductsQuery.isFetching && !userProductsQuery.isRefetching) ||
        (devicesListQuery.isLoading && !devicesListQuery.isRefetching)
      );
    }
  };
  React.useEffect(() => {
    if (
      userSubscriptionQuery.data?.length &&
      userSubscriptionQuery.data.length > 0
    ) {
      setPremiumUser(true);
      setPlusUser(true);
    }
  }, [userSubscriptionQuery.dataUpdatedAt]);
  const firstName = state.accountProfile?.userInformation?.firstName ?? "";
  const lastName = state.accountProfile?.userInformation?.lastName ?? "";
  const email = state.accountProfile?.userInformation?.email;
  const occupation =
    state.accountProfile?.userInformation?.profileOccupation?.text ?? "";
  const fullName = `${firstName} ${lastName}`;
  localStorage.setItem(CUSTOMER_NAME, fullName);

  const isUpdateButtonDisabled = state.isUpdateButtonDisabled ?? true;
  // International Premium user -  Payment info. and subscription details
  // will not be shown on subscripton page and incase of
  // expired/auto renewed subscription, the user will need to contact support
  const internationalUser = isInternationalUser(
    state.accountProfile?.userInformation?.addresses?.work.country
  );
  const styles = makeStyles((theme) => ({
    leftPaneTitle: {
      fontSize: 24,
      fontWeight: 300,
      lineHeight: 1.08,
      color: colors["--title"],
      paddingLeft: 24,
      paddingBottom: 24,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        paddingBottom: theme.spacing(2),
      },
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
      borderTop: `solid 1px ${colors["--ui-grey-light"]}`,
    },
    listItem: {
      padding: 0,
      borderBottom: `solid 1px ${colors["--ui-grey-light"]}`,
    },
    listItemLink: {
      display: "flex",
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
      width: "100%",
      cursor: "pointer",
      "&:hover, &.active": {
        backgroundColor: colors["--ui-grey-light"],
        "& $primary": {
          color: colors["--primary-teal"],
          fontWeight: 500,
        },
        "& $listItemText": {
          backgroundImage: `url(${keyboardArrowRightBlue})`,
        },
      },
    },
    listItemText: {
      margin: 0,
      background: `right center no-repeat url(${keyboardArrowRight})`,
      backgroundSize: 20,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    upgradeText: {
      borderRadius: 3,
      padding: "1px 8px",
      background: colors["--primary-teal"],
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.17,
      textTransform: "uppercase",
      marginRight: theme.spacing(7.5),
      color: colors["--text-invert"],
    },
    primary: {
      fontSize: 16,
      lineHeight: 1.25,
      color: colors["--ui-gray"],
    },
    selectInputWrap: {
      boxShadow,
      borderRadius: 3,
      backgroundColor: colors["--text-invert"],
      width: "100%",
      "&:before": {
        content: "none",
      },
      "&.Mui-focused": {
        backgroundColor: colors["--text-invert"],
        "&:after": {
          content: "none",
        },
      },
    },
    selectInput: {
      padding: `${theme.spacing(2.5)}px ${theme.spacing(4)}px`,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.25,
      color: colors["--link-blue"],
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& $upgradeText": {
        marginRight: theme.spacing(1),
      },
      "&:focus": {
        background: "none",
      },
    },
    mobileDropdownItem: {
      display: "flex",
      justifyContent: "space-between",
      "& $upgradeText": {
        marginRight: 0,
      },
    },
    selectIcon: {
      color: colors["--ui-midnight"],
    },
    accountWrapper: {
      '& header[class*="headerWrapper"]': {
        [theme.breakpoints.between("sm", "md")]: {
          padding: "0 24px",
        },
      },
    },
    rightPaneContent: {
      boxShadow,
      backgroundColor: colors["--text-invert"],
      borderRadius: 4,
      [theme.breakpoints.down("md")]: {
        marginLeft: 16,
      },
    },
    expandedNavigation: {
      marginLeft: "auto",
      width: "calc(100% - 222px)",
    },
    collapsedNavigation: {
      marginLeft: "auto",
      width: "calc(100% - 72px)",
    },
    contentBodyWrapper: {
      marginLeft: "auto",
    },
    expandedNav: {
      width: "calc(100% - 222px)",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    collapsedNav: {
      width: "calc(100% - 72px)",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    actionLink: {
      color: colors["--primary-teal"],
      "&:hover": {
        textDecoration: "none",
      },
    },
    signOutAllButton: {
      [theme.breakpoints.down("md")]: {
        marginLeft: 16,
      },
    },
  }));
  const classes = styles(props);
  const handleRoute = (path: string) => {
    if (path && path.includes("online")) {
      window.location.href = path;
    } else {
      props.history.push(path);
    }
  };
  const handleWindowRoute = (path: string) => {
    if (!isUpdateButtonDisabled) {
      dispatch(promptDialog(true, path));
    } else {
      window.location.href = path;
    }
  };
  const handleActiveTab = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const linkValue = event.target.value;
    const activeLink = leftSidebarLinks
      .filter((linkDetail) => linkValue === linkDetail.id)
      .at(0);
    const eventProperties = {
      "Event ID": ACCOUNT_NAVIGATION_TAB_CLICK_EVENT_ID,
      "Navigation Tab": activeLink?.name ?? "",
    };
    sendAmplitudeEvent(ACCOUNT_NAVIGATION_TAB_CLICK, eventProperties, userId);
    handleRoute(`/account/${linkValue}`);
  };

  const handleSidebarLinkClick = (id: string, name: string) => {
    const eventProperties = {
      "Event ID": ACCOUNT_NAVIGATION_TAB_CLICK_EVENT_ID,
      "Navigation Tab": name,
    };
    sendAmplitudeEvent(ACCOUNT_NAVIGATION_TAB_CLICK, eventProperties, userId);
    handleRoute(`/account/${id}`);
  };

  const webDevicesList = devicesListQuery?.data?.Web ?? [];
  const mobileDevicesList = devicesListQuery?.data?.Apps ?? [];

  const handleSignAllOutClick = () => {
    if (webDevicesList.length === 0 && mobileDevicesList.length === 0) {
      return;
    }
    const allDevices = webDevicesList.concat(mobileDevicesList);
    const updatedDevicesList = updateDevicesStatus(allDevices);
    const userId = Number(localStorage.getItem(CURRENT_USER_ID) ?? -1);
    onProfileSignOutConfirmationClick(allDevices);
    updateDevicesMutation.mutate({ userId, devices: updatedDevicesList });
  };

  if (
    (state.accountProfile?.status >= httpConstants.BAD_REQUEST &&
      state.accountProfile?.status < httpConstants.INTERNAL_SERVER_ERROR) ||
    state.systemError ||
    emailPreferenceValues?.responseStatus ===
      httpConstants.INTERNAL_SERVER_ERROR ||
    userSubscriptionQuery.isError ||
    (activeTab === strings.subscription_tab && primaryMethodError)
  ) {
    return <ErrorScreen code={httpConstants.INTERNAL_SERVER_ERROR} />;
  }
  const spinnerElement = showSpinner() ? <OverlaySpinner /> : null;

  const showBanner = localStorage.getItem("showWelcomeBanner") === "true";
  const profileContent = (
    <div
      data-testid="right-pane-content"
      className={clsx(classes.rightPaneContent)}
    >
      <AccountProfileWithRoot
        subscriptionListProgress={userSubscriptionQuery.isFetching}
        handleRoute={handleRoute}
        isPremium={isPremiumUser}
        userProductsQuery={userProductsQuery}
      />
    </div>
  );
  const subScriptionContent = (
    <div data-testid="right-pane-content">
      <ThemeProvider theme={licenseTheme}>
        <UserSubscriptionRightPaneV2
          history={props.history}
          isPremium={isPremiumUser ?? false}
          internationalUser={internationalUser}
          userSubscriptionQuery={userSubscriptionQuery}
          userProductsQuery={userProductsQuery}
          primaryPaymentResponse={primaryPaymentResponse}
          isPrimaryPaymentFetched={isPrimaryPaymentFetched}
          deviceManagementDecision={props.deviceManagementDecision}
          vindiciaFieldsSpinner={props.vindiciaFieldsSpinner}
          vindicia={props.vindicia}
        />
      </ThemeProvider>
    </div>
  );

  const DeviceManagementContent = (
    <>
      <div
        data-testid="right-pane-content"
        className={clsx(classes.rightPaneContent)}
      >
        <ContentCardWithRoot
          title="Devices"
          action={
            <a
              href="https://www.epocrates.com/termsofuse"
              className={clsx(classes.actionLink)}
              onClick={() => onExtraItemClick("Terms of Use")}
            >
              View Terms of Use
            </a>
          }
        >
          {!devicesListQuery.isLoading && (
            <DevicesWithRoot
              mobileDevicesList={mobileDevicesList}
              webDevicesList={webDevicesList}
              refetch={devicesListQuery.refetch}
            />
          )}
        </ContentCardWithRoot>
      </div>
      {(webDevicesList.length > 0 || mobileDevicesList.length > 0) && (
        <div className={clsx(classes.signOutAllButton)}>
          <Button
            name="Sign out of all devices"
            variant="outlined"
            width={isMobile ? "100%" : isTablet ? 343 : 254}
            maxWidth="100%"
            backgroundColor="transparent"
            foregroundColor={colors["--primary-midnight"]}
            hoverForeground="transparent"
            border={`1.5px solid ${colors["--primary-midnight"]}`}
            fontFamily="Metric"
            onClick={handleOpen}
          />
          <SignOutModal
            open={open}
            handleClose={handleClose}
            handleSignAllOutClick={handleSignAllOutClick}
            title={strings.sign_out_all_device_confirmation}
            description={strings.sign_in_all_device_intimation}
          />
        </div>
      )}
    </>
  );
  const userDetails = {
    isRegistered: true,
    isPremium: isPremiumUser,
    userInitials:
      firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase(),
    firstName: firstName,
    lastName: lastName,
    userId: localStorage.getItem(CURRENT_USER_ID)?.toString() ?? "-1",
  };
  const header = (
    <Navigation
      sideNavigationEnabled={webNavDecision.enabled}
      savedEnabled={savedDecision.enabled}
      expanded={true}
      userDetails={userDetails}
      setSideNavExpandedState={setSideNavExpandedState}
      isRegistered
      isPremium={!!isPremiumUser}
      firstName={firstName}
      lastName={lastName}
      email={email}
      handleRoute={handleRoute}
      handleWindowRoute={handleWindowRoute}
      isHnSEnabled={isHnsEnabled}
      recentlyViewedEnabled={recentlyViewedDecision.enabled}
      isNotificationEnabled={isNotificationEnabled}
      notificationVariation={
        isNotificationBadgeEnabled
          ? notificationBadgeDecision.variationKey!
          : "Off"
      }
      pathnameState={pathname}
    />
  );

  const getLeftPaneStyles = () => {
    let rightPaneWrapper = "";
    if (webNavDecision.enabled) {
      if (sideNavExpandedState) {
        rightPaneWrapper = classes.expandedNav;
      } else {
        rightPaneWrapper = classes.collapsedNav;
      }
    }
    return rightPaneWrapper;
  };

  return (
    <div data-testid="account-wrapper">
      {spinnerElement}
      <div
        id="content-body-wrapper"
        className={clsx(classes.contentBodyWrapper, clsx(getLeftPaneStyles()))}
      >
        {header}
        <Layout>
          <TwoPanesResp>
            <LeftPane>
              <Typography
                data-testid="account-title"
                className={clsx(classes.leftPaneTitle)}
              >
                Account
              </Typography>
              <Desktop>
                <List
                  data-testid="sidebarList"
                  classes={{ root: clsx(classes.list) }}
                >
                  {leftSidebarLinks.map(
                    ({ id, name, showUpgrade, display }: SidebarLinkType) =>
                      display &&
                      (id !== strings.devices_tab ||
                        (props.deviceManagementDecision?.enabled ??
                          props.authorizationCode)) && (
                        <ListItem
                          key={id}
                          classes={{ root: clsx(classes.listItem) }}
                        >
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
                          <a
                            onClick={() => handleSidebarLinkClick(id, name)}
                            onKeyDown={() => handleSidebarLinkClick(id, name)}
                            className={clsx(
                              classes.listItemLink,
                              id === activeTab ? "active" : null
                            )}
                          >
                            <ListItemText
                              classes={{
                                root: clsx(classes.listItemText),
                                primary: clsx(classes.primary),
                                secondary: clsx(classes.upgradeText),
                              }}
                              primary={
                                internationalUser &&
                                id === strings.subscription_tab
                                  ? strings.your_plan
                                  : name
                              }
                              secondary={
                                showUpgrade &&
                                !isPremiumUser &&
                                !internationalUser
                                  ? strings.upgrade_banner_label
                                  : null
                              }
                            />
                          </a>
                        </ListItem>
                      )
                  )}
                </List>
              </Desktop>
              <TabletMobile>
                <Select
                  id="activeTab"
                  className={classes.selectInputWrap}
                  data-testid="activeTab"
                  variant="filled"
                  onChange={handleActiveTab}
                  value={activeTab}
                  classes={{
                    root: classes.selectInput,
                    icon: clsx(classes.selectIcon),
                  }}
                >
                  {leftSidebarLinks.map(
                    ({ id, name, showUpgrade, display }: SidebarLinkType) =>
                      display &&
                      (id !== strings.devices_tab ||
                        (props.deviceManagementDecision?.enabled ??
                          props.authorizationCode)) && (
                        <MenuItem
                          classes={{ root: clsx(classes.mobileDropdownItem) }}
                          key={id}
                          value={id}
                        >
                          {internationalUser && id === strings.subscription_tab
                            ? strings.your_plan
                            : name}{" "}
                          {showUpgrade &&
                          !isPremiumUser &&
                          !internationalUser ? (
                            <span className={clsx(classes.upgradeText)}>
                              {strings.upgrade_banner_label}
                            </span>
                          ) : null}
                        </MenuItem>
                      )
                  )}
                </Select>
              </TabletMobile>
            </LeftPane>

            <RightPane>
              {activeTab === strings.profile_tab &&
                !internationalUser &&
                typeof isPremiumUser === "boolean" && (
                  <ProfileBanner
                    type={strings.upgrade_banner_type}
                    isPremiumUser={isPremiumUser}
                    name={fullName}
                    occupation={occupation}
                  />
                )}
              {activeTab === strings.profile_tab && profileContent}
              {activeTab === strings.subscription_tab &&
                showBanner &&
                typeof isPremiumUser === "boolean" && (
                  <ProfileBanner
                    type={strings.welcome_banner_type}
                    isPremiumUser={isPremiumUser}
                    name={fullName}
                    occupation={occupation}
                  />
                )}
              {activeTab === strings.subscription_tab && subScriptionContent}
              {activeTab === strings.email_preferences_tab && (
                <div
                  data-testid="right-pane-content"
                  className={clsx(classes.rightPaneContent)}
                >
                  <AccountEmailPreference
                    emailPreferenceValues={emailPreferenceValues}
                  />
                </div>
              )}
              {activeTab === strings.devices_tab && DeviceManagementContent}
            </RightPane>
          </TwoPanesResp>
        </Layout>
        <Footer
          isPremium={!!isPremiumUser}
          handleWindowRoute={handleWindowRoute}
        />
      </div>
    </div>
  );
};

export const ManageAccountWithRoot = withRoot(ManageAccount);
