import {
  QueryClient,
  UseQueryResult,
  useMutation,
} from "@tanstack/react-query";
import { memo, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CURRENT_USER_ID } from "src/utils/localStorageKeys";
import {
  sendSuccessSnackBarWithLinkMessage,
  sendSuccessSnackBarMessage,
} from "../actions/snackBarActions";
import { PaymentMethodStateResponse } from "../models/IPaymentMethodResponse";
import { IPrimaryPaymentInformation } from "../models/PrimaryPaymentMethodResponse";
import { VindiciaError } from "../models/VindiciaError";
import { SnackBarType } from "../utils/SnackBarType";
import { strings } from "../utils/Strings";
import { PageType, SubscriptionContext } from "src/context/SubscriptionContext";
import { RetriveUserSubscription } from "src/queries/GetUserSubscription";
import { GetTransactionHistory } from "src/queries/PurchaseQueries";
import { associatePayment } from "src/services/paymentMethodService";
import {
  TransactionDetails,
  commitPayment,
} from "src/services/purchaseService";
import { Product } from "src/services/subscriptionService";
import { UserSubscriptionBillingAndPayment } from "./UserSubscriptionBillingAndPayment";
import { UserSubscriptionPaymentConfirmation } from "./UserSubscriptionPaymentConfirmation";
import { SUBSCRIPTION_PAYMENT_UPGRADE_SCREEN_PAYMENT_FAIL } from "src/utils/analytics";
import { sendAmplitudeEvent } from "src/utils/AmplitudeUtil";
import { RequestError, isRequestError } from "src/models/RequestError";
import { userEntitlementService } from "src/services/userEntitlementService";

const TIMEOUT_DELAY = 2000;
export interface VindiciaPaymentResponse {
  detail: {
    isValid: boolean;
    vid: string;
  };
}
export interface PaymentMethodState {
  paymentMethod: PaymentMethodStateResponse;
}
export interface PMTResponse {
  detail: {
    isValid: boolean;
    vid: string;
  };
}

interface UserSubscriptionPurchaseContainerProps {
  vindicia: {
    setup: () => void;
    destroy: () => void;
    isValid: () => boolean;
    resetCompleteStatus: () => void;
    clearData: () => void;
  };
  product: Product;
  vindiciaFieldsSpinner: boolean;

  vindiciaPaymentFail: boolean;
  setVindiciaPaymentFail: (vindiciaPaymentFail: boolean) => void;

  calculateLicenseResponse: TransactionDetails | undefined | RequestError;
  setShowPaymentScreen: (bool: boolean) => void;
  showPaymentScreen: boolean;
  paymentMethodId: string;
  setPaymentMethodId: (paymentMethodId: string) => void;
  primaryPaymentResponse: UseQueryResult<IPrimaryPaymentInformation>;
  setUpdatePaymentDialog?: (open: boolean) => void;
  promoCode: string;
  setPromoCode: (promoCode: string) => void;
  selectProduct: Product | undefined;
  isPromocodeError?: boolean;
  isCalculateRequestFetching?: boolean;
}

export const UserSubscriptionPurchaseContainer = memo(
  (props: UserSubscriptionPurchaseContainerProps): JSX.Element => {
    const [submitProgress, setSubmitProgress] = useState(false);
    const [confirmButtonSpinner, setConfirmButtonSpinner] = useState(false);
    const userId = Number(localStorage.getItem(CURRENT_USER_ID) ?? -1);
    const [paymentKey, setPaymentKey] = useState(1);
    const dispatchRedux = useDispatch();
    const {
      deviceManagementDecision,
      setCurrentPage,
      isPlusUser,
      setPlusUser,
    } = useContext(SubscriptionContext);
    const transactionHistoryQuery = GetTransactionHistory(userId);
    const userSubscriptionQuery = RetriveUserSubscription(
      userId,
      deviceManagementDecision?.enabled ?? false
    );
    const queryClient = new QueryClient();
    const [isCommitSuccess, setIsCommitSuccess] = useState(false);
    const associatePaymentMethodQuery = useMutation(associatePayment, {
      onSuccess: (data) => {
        // have to use from parent peoperty to avoid infinite loop
        props.primaryPaymentResponse.refetch();
        if (props.setUpdatePaymentDialog) {
          props.setUpdatePaymentDialog(false);
          dispatchRedux(
            sendSuccessSnackBarMessage(
              "",
              strings.payment_method_success_message,
              SnackBarType.SUCCESS.displayValue
            )
          );
        }
      },
      onError: (error) => {
        setSubmitProgress(false);
        dispatchRedux(
          sendSuccessSnackBarWithLinkMessage(
            strings.error_title,
            SnackBarType.ERROR.displayValue
          )
        );
      },
    });

    const usePmtSuccess = (pmtResponse: PMTResponse) => {
      if (pmtResponse?.detail?.isValid) {
        if (!associatePaymentMethodQuery.isLoading) {
          associatePaymentMethodQuery.mutateAsync({
            paymentMethodVid: pmtResponse?.detail?.vid,
          });
        }
      }
      setSubmitProgress(false);
    };

    const paymentFailed = (error: VindiciaError) => {
      setPaymentKey(paymentKey + 1);
      sendAmplitudeEvent(SUBSCRIPTION_PAYMENT_UPGRADE_SCREEN_PAYMENT_FAIL);
      setSubmitProgress(false);
      dispatchRedux(
        sendSuccessSnackBarWithLinkMessage(
          strings.payment_failed_title,
          SnackBarType.ERROR.displayValue
        )
      );
    };

    const commitQuery = useMutation(commitPayment, {
      onSuccess: (response) => {
        if (isRequestError(response)) {
          setIsCommitSuccess(false);
          setConfirmButtonSpinner(false);
          dispatchRedux(
            sendSuccessSnackBarWithLinkMessage(
              strings.payment_failed_title,
              SnackBarType.ERROR.displayValue
            )
          );
        } else {
          queryClient.fetchQuery(["userEntitlements"], userEntitlementService); // update account type
          setIsCommitSuccess(true);
          userSubscriptionQuery.refetch();
        }
      },
      onError: (error) => {
        setConfirmButtonSpinner(false);
        dispatchRedux(
          sendSuccessSnackBarWithLinkMessage(
            strings.payment_failed_title,
            SnackBarType.ERROR.displayValue
          )
        );
      },
    });

    const subscriptionListActionWithDelay = () => {
      setTimeout(() => {
        userSubscriptionQuery.refetch();
      }, TIMEOUT_DELAY);
    };

    function editCard() {
      props.setShowPaymentScreen(true);
      setPaymentKey(paymentKey + 1);
    }

    useEffect(() => {
      if (isCommitSuccess) {
        if (
          userSubscriptionQuery.data?.length &&
          userSubscriptionQuery.data.length > 0
        ) {
          transactionHistoryQuery.refetch();
          setConfirmButtonSpinner(false);
          setPlusUser(true);
          setCurrentPage(PageType.ManageAccount);
        } else {
          subscriptionListActionWithDelay();
        }
      }
    }, [userSubscriptionQuery.dataUpdatedAt]);

    const subscriptionPayment = (
      <UserSubscriptionBillingAndPayment
        vindicia={props.vindicia}
        paymentMethodDetails={props.primaryPaymentResponse.data}
        vindiciaFieldsSpinner={props.vindiciaFieldsSpinner}
        key={paymentKey}
        submitProgress={submitProgress}
        setSubmitProgress={setSubmitProgress}
        paymentSuccess={usePmtSuccess}
        paymentFailed={paymentFailed}
        isFetching={associatePaymentMethodQuery.isLoading}
        isPrimaryPaymentRefecting={props.primaryPaymentResponse.isRefetching}
        setUpdatePaymentDialog={props.setUpdatePaymentDialog}
        isCalculatedResponseError={props.isPromocodeError ?? false}
        promoCode={props.promoCode}
      />
    );
    const paymentConfirmation = (
      <UserSubscriptionPaymentConfirmation
        product={props.product}
        paymentMethodDetails={props.primaryPaymentResponse.data}
        confirmButtonSpinner={confirmButtonSpinner}
        setConfirmButtonSpinner={setConfirmButtonSpinner}
        calculateLicenseResponse={props.calculateLicenseResponse}
        editCard={editCard}
        commitQuery={commitQuery}
        promoCode={props.promoCode}
        setPromoCode={props.setPromoCode}
        selectProduct={props.selectProduct}
        isCalculatedResponseError={props.isPromocodeError ?? false}
        isCalculateRequestFetching={props.isCalculateRequestFetching ?? false}
      />
    );

    return (
      <>{props.showPaymentScreen ? subscriptionPayment : paymentConfirmation}</>
    );
  }
);
