import React from "react";
import withWidth from "@material-ui/core/withWidth";
import { useDispatch, useSelector } from "react-redux";
import { withRoot } from "../withRoot";
import {
  RieventSSOTokenRequest,
  RieventSSOTokenResponse,
} from "../services/cmeLaunchService";
import { cmeLaunchAction } from "../actions/cmeLaunchActions";
import { CME_LAUNCH_REDIRECTING_TO_PROFILE_FORM } from "../actions";
import {
  awaitAllOutstandingEvents,
  sendCMEAmplitudeEventData,
} from "../utils/AmplitudeUtil";
import {
  CME_WEBLAUNCH_REDIRECT,
  CME_WEBLAUNCH_ATTEMPT,
  CME_WEBLAUNCH_ATTEMPT_EVENT_ID,
  CME_WEBLAUNCH_REDIRECT_EVENT_ID,
} from "../utils/analytics";

interface CmeLaunchState {
  rieventSSOTokenResponse: RieventSSOTokenResponse;
  cmeLaunchProgress: boolean;
  cmeLaunchRequiresProfileReview: boolean;
  cmeLaunchRedirectToken: string;
  initiatedFinalRedirect: boolean;
  sentWebLaunchAttemptEvent: boolean;
}

const CmeLaunchComponent = (props: any) => {
  const state = useSelector(
    (stateIn: any): CmeLaunchState => stateIn.cmeLaunch as CmeLaunchState
  );
  const { cancelToReferrerURL } = props;
  const { location: { search = "" } = {} } = props;
  let handoffUrl: URL;
  let hostName: string;
  const queryParams = new URLSearchParams(search);
  const handoffUrlString = queryParams.get("handoffUrl");
  const accessCode = queryParams.get("accessCode") ?? undefined;
  const workflowMode = queryParams.get("workflowMode") ?? undefined;
  const externalActivityId = queryParams.get("externalActivityId") ?? undefined;
  const skipProfileValidation =
    queryParams.get("skipProfileValidation")?.toLowerCase() === "true";
  const skipVendorSSO =
    queryParams.get("skipVendorSSO")?.toLowerCase() === "true";
  if (handoffUrlString) {
    handoffUrl = new URL(handoffUrlString as string);
    hostName = handoffUrl?.hostname;
  }
  const dispatch = useDispatch();

  const redirect404Page = () => {
    props.history.push("/404");
  };

  React.useEffect(() => {
    // Handoff URL must be provided
    if (!handoffUrlString) {
      redirect404Page();
      return;
    }

    // And it must point to a valid destination
    if (
      !handoffUrl?.hostname?.match(/\.rievent\.com$/gim) &&
      !handoffUrl?.hostname?.match(/\.epocrates\.com$/gim)
    ) {
      redirect404Page();
      return;
    }

    if (!state.sentWebLaunchAttemptEvent) {
      state.sentWebLaunchAttemptEvent = true;
      sendCMEAmplitudeEventData(
        CME_WEBLAUNCH_ATTEMPT,
        CME_WEBLAUNCH_ATTEMPT_EVENT_ID,
        skipVendorSSO,
        skipProfileValidation,
        accessCode,
        hostName,
        null,
        cancelToReferrerURL
      );
    }

    if (
      !state.rieventSSOTokenResponse &&
      !state.cmeLaunchProgress &&
      !state.cmeLaunchRedirectToken &&
      !state.cmeLaunchRequiresProfileReview &&
      (!skipVendorSSO || !skipProfileValidation) // If skipping both of these, don't bother calling cme-api
    ) {
      // Need to initiate SSO Token Request
      const request: RieventSSOTokenRequest = {
        claims: {
          accessCode,
          workflowMode,
          externalActivityId,
        },
        skipProfileValidation,
      };
      dispatch(cmeLaunchAction(request));
    } else if (
      (state.cmeLaunchRedirectToken || skipVendorSSO) &&
      !state.cmeLaunchRequiresProfileReview &&
      !state.cmeLaunchProgress
    ) {
      // Have sufficient state to redirect to Rievent now
      if (state.cmeLaunchRedirectToken && !skipVendorSSO) {
        handoffUrl.searchParams.set("riejwt", state.cmeLaunchRedirectToken);
      }

      // Do final redirect to Vendor LMS, but space it out so amplitude events all fire and appear in correct order
      if (!state.initiatedFinalRedirect) {
        state.initiatedFinalRedirect = true;
        (async () => {
          await new Promise((resolve) => setTimeout(resolve, 100));
          sendCMEAmplitudeEventData(
            CME_WEBLAUNCH_REDIRECT,
            CME_WEBLAUNCH_REDIRECT_EVENT_ID,
            skipVendorSSO,
            skipProfileValidation,
            accessCode,
            hostName,
            null,
            cancelToReferrerURL
          );
          await awaitAllOutstandingEvents();
          window.location.href = handoffUrl.toString();
        })();
      }
    }
  }, [state]);

  // Intentionally blank page
  return <></>;
};
export const CmeLaunchComponentWith = withWidth()(withRoot(CmeLaunchComponent));
