import { RequestError } from "../models/RequestError";
import { LOGIN_FAILED } from "../actions";

export const ResponseErrorObject = (responseObj?: any): any => {
  if (responseObj?.status === 401) {
    const errorObj: RequestError = new RequestError(LOGIN_FAILED, LOGIN_FAILED);
    return { status: responseObj?.status, data: errorObj };
  }
  return { status: responseObj?.status, data: {} };
};
