import { TransactionDetails } from "../services/purchaseService";
import { TableRowData } from "../components/OrderSummaryWindow";
import { convertPeriodToEnglish, trialPeriodToDays } from "./subscriptionUtils";
import { formatToDollar } from "./payment";
import { Product } from "src/services/subscriptionService";
import { RequestError, isRequestError } from "src/models/RequestError";
export function generateMainOrderEntries(
  product: Product,
  calculateResponse: TransactionDetails | RequestError | undefined
) {
  const entries = new Array<TableRowData>();
  if (calculateResponse && !isRequestError(calculateResponse)) {
    if (product.trial_period) {
      entries.push({
        label: `${convertPeriodToEnglish(
          product.billing_period
        )} subscription:`,
        value: formatToDollar(product.price?.amount),
      });

      entries.push({
        label: `${convertPeriodToEnglish(product.trial_period)} trial:`,
        value: "FREE",
        discountVariation: true,
      });
    } else {
      entries.push({
        label: `${product.short_description} subscription:`,
        value: formatToDollar(product.price?.amount),
      });
      let discountAmount = 0;
      if (calculateResponse?.promoDiscount) {
        discountAmount = calculateResponse?.promoDiscount;
        entries.push({
          label: `${calculateResponse.promoPercentageOff}% discount:`,
          value: `-${formatToDollar(calculateResponse?.promoDiscount)}`,
          discountVariation: true,
        });
      }

      const initialPrice = calculateResponse?.subtotal ?? 0;
      // for some reason Vindicia doesn't include the discount amount in the subtotal
      const calculatedSubtotal = initialPrice - discountAmount;
      entries.push({
        label: `Total before tax:`,

        value: formatToDollar(calculatedSubtotal),
      });
      entries.push({
        label: `Estimated tax collected:`,
        value: formatToDollar(calculateResponse?.tax),
      });
    }
  }
  return entries;
}

export function generateSummaryOrderEntries(
  product: Product,
  calculateResponse: TransactionDetails | RequestError | undefined
) {
  const entries = new Array<TableRowData>();
  if (calculateResponse && !isRequestError(calculateResponse)) {
    if (product.trial_period) {
      entries.push({
        label: `Starting today:`,
        value: formatToDollar(calculateResponse?.total),
      });
      const dateOptions: Intl.DateTimeFormatOptions = {
        month: "short",
        year: "numeric",
        day: "numeric",
      };
      const renewalDate = new Date();
      renewalDate.setDate(
        renewalDate.getDate() + trialPeriodToDays(product?.trial_period)
      );
      entries.push({
        label: `Starting ${renewalDate.toLocaleDateString(
          "en-US",
          dateOptions
        )}:`,
        value: formatToDollar(product.price?.amount),
      });
    } else {
      entries.push({
        label: `Order total:`,
        value: formatToDollar(calculateResponse?.total),
      });
    }
  }
  return entries;
}
