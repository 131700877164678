export const LOGIN_USER: any = "LOGIN_USER";
export const LOGIN_USER_INPROGRESS = "LOGIN_USER_INPROGRESS";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGIN_SYSTEM_ERROR = "LOGIN_SYSTEM_ERROR";
export const VERIFY_USER: any = "VERIFY_USER";
export const VERIFY_USER_INPROGRESS = "VERIFY_USER_INPROGRESS";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_ERROR = "VERIFY_USER_ERROR";
export const VERIFY_SYSTEM_ERROR = "VERIFY_SYSTEM_ERROR";

export const REQUIRE_USER_MANUAL_LOGIN = "REQUIRE_USER_MANUAL_LOGIN";

export const AUTH_CODE_REDEEM_USER: any = "AUTH_CODE_REDEEM_USER";
export const AUTH_CODE_REDEEM_USER_INPROGRESS =
  "AUTH_CODE_REDEEM_USER_INPROGRESS";
export const AUTH_CODE_REDEEM_USER_SUCCESS = "AUTH_CODE_REDEEM_USER_SUCCESS";
export const AUTH_CODE_REDEEM_USER_ERROR = "AUTH_CODE_REDEEM_USER_ERROR";
export const AUTH_CODE_REDEEM_SYSTEM_ERROR = "AUTH_CODE_REDEEM_SYSTEM_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_INPROGRESS = "UPDATE_USER_INPROGRESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_SYSTEM_ERROR = "UPDATE_SYSTEM_ERROR";

export const RETRIEVE_USER = "RETRIEVE_USER";
export const RETRIEVE_USER_INPROGRESS = "RETRIEVE_USER_INPROGRESS";
export const RETRIEVE_USER_SUCCESS = "RETRIEVE_USER_SUCCESS";
export const RETRIEVE_USER_ERROR = "RETRIEVE_USER_ERROR";
export const RETRIEVE_USER_SYSTEM_ERROR = "RETRIEVE_USER_SYSTEM_ERROR";

export const CME_LAUNCH_GET_TOKEN: any = "CME_LAUNCH_GET_TOKEN";
export const CME_LAUNCH_GET_TOKEN_INPROGRESS =
  "CME_LAUNCH_GET_TOKEN_INPROGRESS";
export const CME_LAUNCH_GET_TOKEN_PROFILE_REQUIRES_REVIEW =
  "CME_LAUNCH_GET_TOKEN_PROFILE_REQUIRES_REVIEW";
export const CME_LAUNCH_GET_TOKEN_SUCCESS = "CME_LAUNCH_GET_TOKEN_SUCCESS";
export const CME_LAUNCH_GET_TOKEN_ERROR = "CME_LAUNCH_GET_TOKEN_ERROR";
export const CME_LAUNCH_GET_TOKEN_SYSTEM_ERROR =
  "CME_LAUNCH_GET_TOKEN_SYSTEM_ERROR";

export const CME_LAUNCH_REDIRECTING_TO_PROFILE_FORM =
  "CME_LAUNCH_REDIRECTING_TO_PROFILE_FORM";

export const COUNTRIES = "COUNTRIES";
export const COUNTRIES_INPROGRESS = "COUNTRIES_INPROGRESS";
export const COUNTRIES_SUCCESS = "COUNTRIES_SUCCESS";
export const COUNTRIES_ERROR = "COUNTRIES_ERROR";
export const COUNTRIES_SYSTEM_ERROR = "COUNTRIES_SYSTEM_ERROR";

export const OCCUPATIONS = "OCCUPATIONS";
export const OCCUPATIONS_INPROGRESS = "OCCUPATIONS_INPROGRESS";
export const OCCUPATIONS_SUCCESS = "OCCUPATIONS_SUCCESS";
export const OCCUPATIONS_ERROR = "OCCUPATIONS_ERROR";
export const OCCUPATIONS_SYSTEM_ERROR = "OCCUPATIONS_SYSTEM_ERROR";

export const SPECIALTIES = "SPECIALTIES";
export const SPECIALTIES_INPROGRESS = "SPECIALTIES_INPROGRESS";
export const SPECIALTIES_SUCCESS = "SPECIALTIES_SUCCESS";
export const SPECIALTIES_ERROR = "SPECIALTIES_ERROR";
export const SPECIALTIES_SYSTEM_ERROR = "SPECIALTIES_SYSTEM_ERROR";

export const SUBSPECIALTIES = "SUBSPECIALTIES";
export const SUBSPECIALTIES_INPROGRESS = "SUBSPECIALTIES_INPROGRESS";
export const SUBSPECIALTIES_SUCCESS = "SUBSPECIALTIES_SUCCESS";
export const SUBSPECIALTIES_ERROR = "SUBSPECIALTIES_ERROR";
export const SUBSPECIALTIES_SYSTEM_ERROR = "SUBSPECIALTIES_SYSTEM_ERROR";

export const DEGREES = "DEGREES";
export const DEGREES_INPROGRESS = "DEGREES_INPROGRESS";
export const DEGREES_SUCCESS = "DEGREES_SUCCESS";
export const DEGREES_ERROR = "DEGREES_ERROR";
export const DEGREES_SYSTEM_ERROR = "DEGREES_SYSTEM_ERROR";
export const SET_DASHBOARD_SEARCH_PARAMS = "SET_DASHBOARD_SEARCH_PARAMS";
export const SET_SNACKBAR_MESSAGE = "SET_SNACKBAR_MESSAGE";
export const SUCCESS_SNACKBAR_MESSAGE = "SUCCESS_SNACKBAR_MESSAGE";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const TIMEOUT_SNACKBAR = "TIMEOUT_SNACKBAR";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const ACCOUNT_CREATION = "ACCOUNT_CREATION";
export const ACCOUNT_CREATION_INPROGRESS = "ACCOUNT_CREATION_INPROGRESS";
export const ACCOUNT_CREATION_SUCCESS = "ACCOUNT_CREATION_SUCCESS";
export const ACCOUNT_CREATION_ERROR = "ACCOUNT_CREATION_ERROR";

export const GET_NPI_LIST = "GET_NPI_LIST";
export const GET_NPI_LIST_PROGRESS = "GET_NPI_LIST_PROGRESS";
export const GET_NPI_LIST_SUCCESS = "GET_NPI_LIST_SUCCESS";
export const GET_NPI_LIST_ERROR = "GET_NPI_LIST_ERROR";

export const GET_USER = "GET_USER";
export const UPDATE_USER_DETAIL = "UPDATE_USER_DETAIL";

export const EMAIL_VALIDATION = "EMAIL_VALIDATION";
export const EMAIL_VALIDATION_INPROGRESS = "EMAIL_VALIDATION_INPROGRESS";
export const EMAIL_VALIDATION_SUCCESS = "EMAIL_VALIDATION_SUCCESS";

export const ZIP_CODE_VALIDATION = "ZIP_CODE_VALIDATION";
export const ZIP_CODE_VALIDATION_INPROGRESS = "ZIP_CODE_VALIDATION_INPROGRESS";
export const ZIP_CODE_VALIDATION_SUCCESS = "ZIP_CODE_VALIDATION_SUCCESS";
export const ZIP_CODE_VALIDATION_FAILED = "ZIP_CODE_VALIDATION_FAILED";

export const EMAIL_PREFERENCE_UPDATE = "EMAIL_PREFERENCE_UPDATE";
export const EMAIL_PREFERENCE_UPDATE_INPROGRESS =
  "EMAIL_PREFERENCE_UPDATE_INPROGRESS";
export const EMAIL_PREFERENCE_UPDATE_SUCCESS =
  "EMAIL_PREFERENCE_UPDATE_SUCCESS";
export const EMAIL_PREFERENCE_UPDATE_ERROR = "EMAIL_PREFERENCE_UPDATE_ERROR";
export const GET_EMAIL_PREFERENCE = "GET_EMAIL_PREFERENCE";
export const GET_EMAIL_PREFERENCE_INPROGRESS =
  "GET_EMAIL_PREFERENCE_INPROGRESS";
export const GET_EMAIL_PREFERENCE_SUCCESS = "GET_EMAIL_PREFERENCE_SUCCESS";
export const GET_EMAIL_PREFERENCE_ERROR = "GET_EMAIL_PREFERENCE_ERROR";
export const USER_SUBSCRIPTION_TYPE = "USER_SUBSCRIPTION_TYPE";
export const CLEAR_ZIP_CODE_VALIDATION_STATE =
  "CLEAR_ZIP_CODE_VALIDATION_STATE";

export const PROMPT_DIALOG = "PROMPT_DIALOG";
export const UPDATE_BUTTON = "UPDATE_BUTTON";
export const RESET_ACCOUNT_PROFILE_UPDATE_RESPONSE =
  "RESET_ACCOUNT_PROFILE_UPDATE_RESPONSE";

export const DEVICE_MANAGEMENT_ENABLED = "DEVICE_MANAGEMENT_ENABLED";
