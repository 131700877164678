import * as React from "react";
import { withRoot } from "../withRoot";
import { NpiEnter } from "./NpiEnter";
import { ShowProfile } from "./ShowProfile";

const CreateAccountNPIProfile = (props: any) => {
  let element;

  switch (props.profile) {
    case "showProfile":
      // eslint-disable-next-line react/jsx-props-no-spreading
      element = <ShowProfile {...props} />;
      break;
    case "npiEnter":
      // eslint-disable-next-line react/jsx-props-no-spreading
      element = <NpiEnter {...props} />;
      break;
    default:
      element = null;
      break;
  }

  return <div>{element}</div>;
};
export const CreateAccountNPIProfileWith = withRoot(CreateAccountNPIProfile);
