import {
  Box,
  Divider,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { OptimizelyContext, OptimizelyDecision } from "@optimizely/react-sdk";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { colors } from "src/config/colorConfig";
import { PageType, SubscriptionContext } from "src/context/SubscriptionContext";
import epocratesPlusIcon1x from "src/images/epoc_plus_logo@1x.png";
import arrowBackIcon from "src/images/icons/arrow_back_nav.png";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";
import { UserSelectSubscription } from "./UserSelectSubscription";
import {
  ProcessCalculateTax,
  ProcessCalculateTaxNoPromoCode,
} from "src/queries/PurchaseQueries";
import { Product } from "src/services/subscriptionService";
import { RetriveUserProducts } from "src/queries/GetUserProducts";
import { GetPrimaryPaymentMethod } from "src/queries/PaymentMethodDetailQuery";
import { LoadingEpocLogo } from "./custom/LoadingEpocLogo";
import { isRequestError } from "src/models/RequestError";
import {
  Card,
  CardContent,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core";
import { licenseTheme } from "src/styles/licenseTheme";
import { CollapsibleCard } from "./CollapsibleCard";
import { UserSubscriptionPurchaseContainer } from "./UserSubscriptionPurchaseContainer";
import { OrderSummaryWindow } from "./OrderSummaryWindow";
import { useDispatch } from "react-redux";
import {
  sendSuccessSnackBarMessage,
  sendSuccessSnackBarWithTitleMessage,
} from "src/actions/snackBarActions";
import { SnackBarType } from "src/utils/SnackBarType";
import { strings } from "src/utils/Strings";
import {
  generateMainOrderEntries,
  generateSummaryOrderEntries,
} from "../utils/orderSummary";
import { updateOptimizelyFlagsForSegment } from "src/utils/AmplitudeUtil";
import { CollapsibleOrderSummary } from "./CollapsibleOrderSummary";

interface UserSubscriptionLandingPageProps extends RouteComponentProps {
  deviceManagementDecision: OptimizelyDecision;
  vindicia: {
    setup: () => void;
    destroy: () => void;
    isValid: () => boolean;
    resetCompleteStatus: () => void;
    clearData: () => void;
  };
  vindiciaFieldsSpinner: boolean;
}
export interface PurchaseRequestInfo {
  userId: number;
  product: Product | undefined;
  campaignCode: string;
}

const PromoCodeErrorMsg = "Code is incorrect or invalid";

const TopBox = styled(Box)(({ theme }) => ({
  backgroundColor: `${colors["--ui-grey-ultra-light"]}`,
  height: "100vh",
}));

const BackArrowDiv = styled("div")(({ theme }) => ({
  display: "flex",
  color: colors["--primary-tealnight"],
  cursor: "pointer",
  position: "absolute",
  left: "8px",
}));
const LogoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  height: "64px",
}));
const BackTypography = styled(Typography)(({ theme }) => ({
  marginLeft: "8px",
  fontFamily: "Source Sans Pro",
  fontSize: "18px",
  lineHeight: "24px",
  fontWeight: 600,
  color: colors["--primary-tealnight"],
  [theme.breakpoints.only("xs")]: {
    display: "none",
  },
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: `${colors["--ui-grey-ultra-light"]} !important`,
  width: "100%",
}));
const ContentBox = styled(Box)(({ theme }) => ({
  width: "760px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0px",
    width: "100%",
  },
}));
const SelectWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    margin: "0px 16px",
  },
}));
export const TitleTypography = styled(Typography)(({ theme }) => ({
  margin: "24px 0px",
  fontFamily: "Source Sans Pro",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "32px",
  color: colors["--primary-midnight"],
  [theme.breakpoints.down("lg")]: {
    marginLeft: "16px",
  },
}));
// This support old styles
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiCardContent-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiCardHeader-root": {
      padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    },
  },
  cardContent: {
    padding: "16px",
  },
  preview: {
    marginTop: "78px",
    width: 320,
  },
  cardLoading: {
    height: 260,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "760px",
    height: "100%",
  },
  contentContainer: {
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    backgroundColor: `${colors["--ui-grey-ultra-light"]}`,
  },
  selectPlan: {
    margin: `${theme.spacing(2)} ${theme.spacing(8)}`,
    background: colors["--text-invert"],
    display: "flex",
    flexDirection: "column",
  },
}));
export const UserSubscriptionLandingPage = memo(
  (props: UserSubscriptionLandingPageProps) => {
    const {
      currentPage,
      setCurrentPage,
      showSelection,
      userNavigation,
      setUserNavigation,
      initialProductSku,
    } = useContext(SubscriptionContext);
    const { optimizely } = useContext(OptimizelyContext);
    let pageContent = <></>;
    const queryParams = new URLSearchParams(window.location.search);
    const [promoCode, setPromoCode] = useState<string>(
      queryParams.get("promocode") ?? ""
    );
    const querySKU = queryParams.get("upsellSku") ?? undefined;
    const userId = localStorage.getItem(CURRENT_USER_ID);
    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(
      initialProductSku.current
    );
    const productQueryResponse = RetriveUserProducts(
      Number(userId ?? -1),
      props.deviceManagementDecision?.enabled ?? false
    );
    const [vindiciaPaymentFail, setVindiciaPaymentFail] = useState(false);
    const [showPaymentScreen, setShowPaymentScreen] = useState(true);
    const [isPaymentRequired, setPaymentRequired] = useState(true);
    const [paymentMethodId, setPaymentMethodId] = useState<string>("");

    const classes = useStyles();
    const [promoCodeError, setPromoCodeError] = useState<boolean>(false);
    const dispatchRedux = useDispatch();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up(1200));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200));
    const primaryPaymentResponse = GetPrimaryPaymentMethod(
      Number(localStorage.getItem(CURRENT_USER_ID) ?? -1),
      props.deviceManagementDecision?.enabled
    );
    const processCalculateResponse = ProcessCalculateTax({
      userId: Number(localStorage.getItem(CURRENT_USER_ID) ?? -1),
      product: selectedProduct?.platform_sku ?? "",
      campaignCode: promoCode.trim().toUpperCase(),
      paymentId: primaryPaymentResponse?.data?.id,
    });
    useEffect(() => {
      if (processCalculateResponse.data) {
        if (!isRequestError(processCalculateResponse.data)) {
          setPromoCodeError(false);
          if (processCalculateResponse.data.promoPercentageOff > 0) {
            if (processCalculateResponse.data.total === 0) {
              setPaymentRequired(false);
            } else {
              setPaymentRequired(true);
            }
            dispatchRedux(
              sendSuccessSnackBarMessage(
                "",
                strings.promo_code_success,
                SnackBarType.SUCCESS.displayValue
              )
            );
          } else {
            setPaymentRequired(true);
          }
        } else {
          setPromoCodeError(true);
          setPaymentRequired(true);
          if (currentPage === PageType.PaymentBilling) {
            dispatchRedux(
              sendSuccessSnackBarWithTitleMessage(
                "Code invalid",
                strings.calculate_code_error_text,
                true,
                SnackBarType.ERROR.displayValue
              )
            );
          }
        }
      }
    }, [processCalculateResponse.dataUpdatedAt]);
    const processNoPromocodeResponse = ProcessCalculateTaxNoPromoCode(
      {
        userId: Number(localStorage.getItem(CURRENT_USER_ID) ?? -1),
        product: selectedProduct?.platform_sku ?? "",
        paymentId: primaryPaymentResponse?.data?.id,
      },
      promoCodeError
    );

    // If the user has a primary payment method, show the payment confirmation screen
    useEffect(() => {
      if (primaryPaymentResponse.data?.id) {
        setShowPaymentScreen(false);
      }
    }, [primaryPaymentResponse.data]);

    useEffect(() => {
      if (userId) {
        const decisions = optimizely?.decideAll(undefined, userId);
        updateOptimizelyFlagsForSegment(userId, decisions);
      }
    }, [userId]);

    const selectSubscriptionEditAction = useCallback(() => {
      setCurrentPage(PageType.SelectSubscription);
    }, []);

    const onClickBackArrow = useCallback(() => {
      const currentIndex = userNavigation.findIndex(
        (page) => page === currentPage
      );
      if (currentIndex > -1) {
        setCurrentPage(userNavigation[currentIndex - 1]);
        setUserNavigation(userNavigation.slice(0, -1));
      } else {
        window.history.back();
      }
    }, []);
    const responsiveViewOrderSummary = (
      <CollapsibleOrderSummary
        isLoading={
          processCalculateResponse.isFetching ||
          processCalculateResponse.isRefetching ||
          processNoPromocodeResponse.isFetching
        }
        orderTotal={
          processCalculateResponse.data &&
          !isRequestError(processCalculateResponse.data)
            ? processCalculateResponse.data.total
            : undefined
        }
      >
        {selectedProduct && (
          <OrderSummaryWindow
            mainTableArray={generateMainOrderEntries(
              selectedProduct,
              promoCodeError
                ? processNoPromocodeResponse.data
                : processCalculateResponse.data
            )}
            summaryTableArray={generateSummaryOrderEntries(
              selectedProduct,
              promoCodeError
                ? processNoPromocodeResponse.data
                : processCalculateResponse.data
            )}
            displayDiscountBar={true}
            hideDisplayTitle={true}
            setPromoCode={setPromoCode}
            promoCode={promoCode}
            validationError={promoCodeError ? PromoCodeErrorMsg : ""}
          />
        )}
      </CollapsibleOrderSummary>
    );
    const desktopViewOrderSummary = (
      <Card>
        {selectedProduct && (
          <CardContent className={classes.cardContent}>
            <OrderSummaryWindow
              mainTableArray={generateMainOrderEntries(
                selectedProduct,
                promoCodeError
                  ? processNoPromocodeResponse.data
                  : processCalculateResponse.data
              )}
              summaryTableArray={generateSummaryOrderEntries(
                selectedProduct,
                promoCodeError
                  ? processNoPromocodeResponse.data
                  : processCalculateResponse.data
              )}
              displayDiscountBar={true}
              setPromoCode={setPromoCode}
              promoCode={promoCode}
              validationError={promoCodeError ? PromoCodeErrorMsg : ""}
            />
          </CardContent>
        )}
      </Card>
    );

    switch (true) {
      case currentPage === PageType.SelectSubscription:
        pageContent = (
          <SelectWrapper>
            <UserSelectSubscription
              deviceManagementDecision={props.deviceManagementDecision}
              setSelectProduct={setSelectedProduct}
              selectProduct={selectedProduct}
              productQueryResponse={productQueryResponse}
              setPromoCode={setPromoCode}
              promoCode={promoCode}
            />
          </SelectWrapper>
        );
        break;
      case currentPage === PageType.PaymentBilling:
        pageContent = (
          <div className={classes.root}>
            {isDesktop && showSelection && !querySKU && (
              <>
                <CollapsibleCard
                  title="Select subscriptions"
                  expanded={false}
                  number={1}
                  editAction={selectSubscriptionEditAction}
                />
                <div style={{ padding: "8px" }} />
              </>
            )}
            <CollapsibleCard
              title="Payment & billing"
              expanded={true}
              number={isDesktop && showSelection && !querySKU ? 2 : 1}
            >
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                flexGrow={1}
              >
                <div className={classes.content}>
                  <Box className={classes.selectPlan}>
                    {selectedProduct && (
                      <UserSubscriptionPurchaseContainer
                        vindicia={props.vindicia}
                        product={selectedProduct}
                        vindiciaFieldsSpinner={props.vindiciaFieldsSpinner}
                        vindiciaPaymentFail={vindiciaPaymentFail}
                        setVindiciaPaymentFail={setVindiciaPaymentFail}
                        calculateLicenseResponse={
                          promoCodeError
                            ? processNoPromocodeResponse.data
                            : processCalculateResponse.data
                        }
                        showPaymentScreen={
                          showPaymentScreen && isPaymentRequired
                        }
                        setShowPaymentScreen={setShowPaymentScreen}
                        paymentMethodId={paymentMethodId}
                        setPaymentMethodId={setPaymentMethodId}
                        primaryPaymentResponse={primaryPaymentResponse}
                        promoCode={promoCode}
                        setPromoCode={setPromoCode}
                        selectProduct={selectedProduct}
                        isPromocodeError={promoCodeError}
                        isCalculateRequestFetching={
                          processCalculateResponse.isRefetching ||
                          processCalculateResponse.isFetching ||
                          processNoPromocodeResponse.isFetching
                        }
                      />
                    )}
                  </Box>
                </div>
              </Box>
            </CollapsibleCard>
          </div>
        );
        break;
      default:
        pageContent = (
          <UserSelectSubscription
            deviceManagementDecision={props.deviceManagementDecision}
            setSelectProduct={setSelectedProduct}
            selectProduct={selectedProduct}
            productQueryResponse={productQueryResponse}
            setPromoCode={setPromoCode}
            promoCode={promoCode}
          />
        );
    }
    return (
      <TopBox>
        <LogoBox>
          <BackArrowDiv onClick={onClickBackArrow}>
            <img src={arrowBackIcon} alt="Back Icon" />
            <BackTypography>Back</BackTypography>
          </BackArrowDiv>

          <div>
            <img
              src={epocratesPlusIcon1x}
              style={{ height: "32px", marginRight: "8px" }}
              alt="Epocrates Plus"
            />
          </div>
        </LogoBox>
        <StyledDivider />

        <div className={classes.contentContainer}>
          <ThemeProvider theme={licenseTheme}>
            {currentPage === PageType.PaymentBilling && (
              <>
                <ContentBox>
                  <TitleTypography>Purchase checkout</TitleTypography>
                  {isSmallScreen && responsiveViewOrderSummary}
                  {pageContent}
                </ContentBox>
                {isDesktop && (
                  <div className={classes.preview}>
                    {processCalculateResponse.isFetching ||
                    processCalculateResponse.isRefetching ||
                    processNoPromocodeResponse.isFetching ? (
                      <Card className={classes.cardLoading}>
                        <CardContent>
                          <LoadingEpocLogo />
                        </CardContent>
                      </Card>
                    ) : (
                      processCalculateResponse.isFetched &&
                      processCalculateResponse.data &&
                      desktopViewOrderSummary
                    )}
                  </div>
                )}
              </>
            )}
            {currentPage === PageType.SelectSubscription && (
              <ContentBox>
                <TitleTypography>Yearly subscription plans</TitleTypography>
                {pageContent}
              </ContentBox>
            )}
          </ThemeProvider>
        </div>
      </TopBox>
    );
  }
);
