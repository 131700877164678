export const colors = {
  "--primary": "#0f4c90",
  "--primary-midnight": "#06162d",
  "--primary-teal": "#0093c6",
  "--primary-orange": "#dd5546",
  "--primary-off-white": "#eff7fe",
  "--accent-jellyfish": "#7ee1e8",
  "--accent-salmon": "#f6816b",
  "--accent-violet": "#cda0ed",
  "--success": "#00dc8c",
  "--error": "#ff2044",
  "--text-invert": "#ffffff",
  "--white": "#ffffff",
  "--background-jellyfish-bg": "#d7fdff",
  "--background-salmon": "#ffd2c9",
  "--background-violet": "#f1e7fa",
  "--background-success": "#d2ffef",
  "--background-error": "#ffe0e8",
  "--ui-midnight": "#05152f",
  "--ui-slate": "#1c2f49",
  "--ui-slate-blue": "#5a769a",
  "--ui-gray": "#506c8e",
  "--ui-grey-semi": "#828a97",
  "--ui-grey-med": "#ccd4dc",
  "--ui-grey-light": "#eff2f8",
  "--ui-grey-ultra-light": "#f6f7fa",
  "--ui-link-pressed": "#005b7a",
  "--ui-link": "#00a7d3",
  "--scrim-scrim-25": "rgba(0, 0, 0, 0.25)",
  "--states-backgrounds-hover": "#fbfcfc",
  "--scrim-scrim-05": "rgba(0, 0, 0, 0.05)",
  "--scrim-scrim-50": "rgba(0, 0, 0, 0.5)",
  "--title": "#041437",
  "--failure": "#ff0000",
  "--price": "#041535",
  "--link-blue": "#009ccf",
  "--title-black": "#1e3554",
  "--background-hover": "#ebf1f5",
  "--background": "#137cbd",
  "--input-background-hover": "#106ba3",
  "--icon-background-color": "#f5f8fa",
  "--popup-title": "#35628B",
  "--ui-blue-dark": "#25446a",
  "--light-grey": "#5a7799",
  "--dark-blue-text": "#1e3553",
  "--ui-blue-light": "#00a6d5",
  "--prompt-dialog-title": "#294e75",
  "--pure-black": "#000000",
  "--pure-white": "#FFFFFF",
  "--primary-tealnight": "#0178A4",
  "--color-alert": "#CA0D0D",
  "--mid-off-white": "#D9EBFA",
};
