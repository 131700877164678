import * as React from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import emailValidation from "email-validator";
import { useDispatch, useSelector } from "react-redux";
import { withRoot } from "../withRoot";
import { TextField } from "./custom/TextField";
import { Password } from "./custom/PasswordField";
import { strings } from "../utils/Strings";
import { Button } from "./custom/Button";
import { DropDownMenu, negativeToEmpty } from "./custom/DropDownMenu";
import { getPasswordError } from "./custom/PasswordError";
import { hasNumberInString, isProperNameLength } from "../utils/validation";
import {
  occupationsAction,
  specialtiesAction,
} from "../actions/demographicsActions";
import { DemographicsResponse } from "../models/DemographicsResponse";
import {
  AccountCreationFormValues,
  isOccupation,
  Occupation,
} from "../models/AccountCreationFormValues";
import { CountryTuple } from "../models/CountryTuple";
import { IdTextTuple } from "../models/IdTextTuple";
import { CreateAccountSubFormWith as CreateAccountSubForm } from "./CreateAccountSubForm";
import {
  CREATE_ACCOUNT_CONTINUE_CLICK,
  CREATE_ACCOUNT_INITIAL_VIEW,
  CREATE_ACCOUNT_EMAIL_ADDRESS_CLICK,
  CREATE_ACCOUNT_FIRST_NAME_CLICK,
  CREATE_ACCOUNT_LAST_NAME_CLICK,
  CREATE_ACCOUNT_PASSWORD_CLICK,
} from "../utils/analytics";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import { httpConstants } from "../utils/httpConstants";
import {
  emailValidationAction,
  zipCodeValidationAction,
} from "../actions/accountCreationActions";
import { ValidationServiceResponse } from "../models/AccountValidationResponse";
import { ErrorValues } from "../models/ErrorValues";
import { isNpiVisible } from "src/utils/accountCreationUtil";
import { colors } from "src/config/colorConfig";

export interface AccountCreationState {
  accountCreation: AccountCreationValues;
}

interface AccountCreationValues {
  occupationsResponse: DemographicsResponse;
  emailValidationResponse: ValidationServiceResponse;
  zipCodeValidationResponse: ValidationServiceResponse;
  email_validation_progress: boolean;
  zipCode_validation_progress: boolean;
  specialties_progress: boolean;
  specialtiesResponse: DemographicsResponse;
}

interface FormProps {
  isParentFresh: boolean;
  formValues: AccountCreationFormValues;
  onContinue: (
    formValues: AccountCreationFormValues,
    isNotContinueMode: boolean,
    isStudent: boolean
  ) => void;
  inProgress: boolean;
  savedOccupation?: number;
  isButtonDisabled: boolean;
  setErrorState: (errorState: ErrorValues) => void;
  errorValues?: ErrorValues | null;
  showNpi: boolean;
  isGroupPurchaseNewUser?: boolean;
  email?: string;
  defaultCountry: CountryTuple;
}
const defaultProps = {
  savedOccupation: -1,
  errorValues: null,
};
const CreateAccountForm = (props: FormProps) => {
  const [isContinueMode, setIsContinueMode] = React.useState(true);
  const [isMoreFieldsVisible, setMoreFieldsVisible] = React.useState(true);
  const [isExtraValid, setIsExtraValid] = React.useState(false);
  const empty: IdTextTuple[] = [];
  const validationStates = React.useRef<Record<string, boolean>>({
    firstName: false,
    lastName: false,
    email: false,
    confirmEmail: false,
    password: false,
    occupation: false,
  });
  const [isFresh, setIsFresh] = React.useState(true);
  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState<string | null>(
    null
  );
  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState(props.email ?? "");
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [confirmEmail, setConfirmEmail] = React.useState("");
  const [confirmEmailError, setConfirmEmailError] = React.useState<
    string | null
  >(null);
  const [password, setPassword] = React.useState("");
  const [occupation, setOccupation] = React.useState<Occupation>({
    id: -1,
    text: "Select...",
  });
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [country, setCountry] = React.useState<CountryTuple>(
    props.defaultCountry
  );
  const [isStudent, setIsStudent] = React.useState(false);
  const [graduationYear, setGraduationYear] = React.useState(-1);
  const [zipCode, setZipCode] = React.useState("");
  const [isZipValid, setIsZipValid] = React.useState(false);

  const style = makeStyles((theme) => ({
    fieldDivider: {
      paddingTop: 17,
    },
    noteContent: {
      color: "var(--primary-midnight, #06162D)",
      textAlign: "left",
      marginTop: -12,
      marginBottom: 12,
      fontSize: "14px",
      fontWeight: 10,
      lineHeight: "16px",
    },
  }));

  const classes = style(props);
  const dispatch = useDispatch();
  const state = useSelector(
    (accountCreationState: AccountCreationState) =>
      accountCreationState.accountCreation
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // initailizing error state
    if (props?.setErrorState) {
      if (
        props.errorValues?.errorStatus !== httpConstants.INTERNAL_SERVER_ERROR
      ) {
        props.setErrorState({
          counter: 0,
          errorMessages: [],
          errorStatus: 0,
        });
      } else {
        props.setErrorState({
          counter: props.errorValues?.counter,
          errorMessages: [],
          errorStatus: 0,
        });
      }
    }
    if (isContinueMode) {
      sendAmplitudeEvent(CREATE_ACCOUNT_CONTINUE_CLICK, {}, null);
    }
    // no need to validate email for group user creation.
    if (props.isGroupPurchaseNewUser) {
      state.emailValidationResponse = {
        status: httpConstants.OK,
      };
    } else {
      dispatch(emailValidationAction({ email })); // email service api invoked when email validation action dispatched
    }

    if (isMoreFieldsVisible) {
      // zipcode service api invoked when zipcode validation action dispatched
      dispatch(
        zipCodeValidationAction({
          countryId: Number(country.id),
          zipCode,
        })
      );
    }
  };

  /**
   * client side validations
   * */
  const validateSingleInput = (
    data: string | number | Occupation,
    type: string
  ) => {
    let response = null;
    switch (type) {
      case "firstName":
      case "lastName":
        if (typeof data === "string") {
          if (hasNumberInString(data)) {
            response = strings.no_number_in_name;
          } else if (!isProperNameLength(data)) {
            response =
              type === "firstName"
                ? strings.invalid_first_name
                : strings.invalid_last_name;
          }
        }
        break;
      case "email":
        if (typeof data === "string" && !emailValidation.validate(data)) {
          response = strings.email_format_invalid;
        }
        break;
      case "confirmEmail":
        if (typeof data === "string" && !emailValidation.validate(data)) {
          response = strings.email_format_invalid;
        } else if (
          typeof data === "string" &&
          email.toLowerCase() !== data.toLowerCase()
        ) {
          response = strings.different_email_message;
        }
        break;
      case "password":
        if (typeof data === "string") response = getPasswordError(data, email);
        break;
      case "occupation":
        if (isOccupation(data) && data.id === -1) {
          response = strings.invalid_occupation;
        }
        break;
      default:
        response = null;
    }
    validationStates.current[type] = !response;
    return response;
  };

  const validateFirstName = (data: string) => {
    setFirstName(data);
    return validateSingleInput(data, "firstName");
  };

  const validateLastName = (data: string) => {
    setLastName(data);
    return validateSingleInput(data, "lastName");
  };

  const validateEmail = (data: string) => {
    setEmail(data);
    return validateSingleInput(data, "email");
  };

  const validateConfirmEmail = (data: string) => {
    setConfirmEmail(data);
    return validateSingleInput(data, "confirmEmail");
  };

  const validatePassword = (data: string) => {
    setPassword(data);
    return validateSingleInput(data, "password");
  };

  const handleOccupationChange = (id: number) => {
    const occupationObj = state?.occupationsResponse?.data.find(
      (item) => item.id === id
    );
    const data: Occupation = { id, text: occupationObj?.text ?? "" };
    setOccupation(data);
    dispatch(specialtiesAction(id));
    return validateSingleInput(data, "occupation");
  };

  const onCreateAccountOpen = () => {
    sendAmplitudeEvent(CREATE_ACCOUNT_INITIAL_VIEW, {}, null);
  };

  const onFirstNameFocus = () => {
    sendAmplitudeEvent(CREATE_ACCOUNT_FIRST_NAME_CLICK, {}, null);
  };

  const onLastNameFocus = () => {
    sendAmplitudeEvent(CREATE_ACCOUNT_LAST_NAME_CLICK, {}, null);
  };

  const onEmailFocus = () => {
    sendAmplitudeEvent(CREATE_ACCOUNT_EMAIL_ADDRESS_CLICK, {}, null);
  };

  const onPasswordFocus = () => {
    sendAmplitudeEvent(CREATE_ACCOUNT_PASSWORD_CLICK, {}, null);
  };

  React.useEffect(() => {
    setIsFresh(false);
    if (props.isParentFresh) {
      dispatch(occupationsAction());
      onCreateAccountOpen();
    } else {
      const { formValues } = props;
      validateFirstName(formValues.firstName);
      validateLastName(formValues.lastName);
      validatePassword(formValues.password);
      if (!props.isGroupPurchaseNewUser) {
        validateEmail(formValues.email);
        validateConfirmEmail(formValues.email);
        setCountry(formValues.country);
      }
      handleOccupationChange(formValues.occupation.id);
      setZipCode(formValues.zipCode ?? "");
      setMoreFieldsVisible(true);
      if (formValues.graduationYear !== undefined)
        setGraduationYear(formValues.graduationYear);
      setIsExtraValid(true);
      setIsContinueMode(true);
    }
  }, []);

  React.useEffect(() => {
    setIsContinueMode(
      props.showNpi ||
        props.savedOccupation !== occupation.id ||
        state.specialtiesResponse?.data.length !== 0
    );
  }, [
    props.savedOccupation,
    occupation.id,
    isStudent,
    state.specialtiesResponse,
  ]);

  React.useEffect(() => {
    if (!isFresh) {
      setFirstNameError(
        validateSingleInput(firstName, "firstName") as string | null
      );
    }
  }, [firstName]);

  React.useEffect(() => {
    if (!isFresh) {
      setLastNameError(
        validateSingleInput(lastName, "lastName") as string | null
      );
    }
  }, [lastName]);

  React.useEffect(() => {
    if (!isFresh) {
      setEmailError(validateSingleInput(email, "email") as string | null);
      setConfirmEmailError(
        validateSingleInput(confirmEmail, "confirmEmail") as string | null
      );
      validateSingleInput(password, "password");
    }
  }, [email, confirmEmail, password]);

  React.useEffect(() => {
    const { zipCodeValidationResponse, emailValidationResponse } = state;

    if (!isMoreFieldsVisible) {
      if (emailValidationResponse) {
        if (emailValidationResponse?.status === httpConstants.OK) {
          setMoreFieldsVisible(true);
          props.setErrorState({
            counter: props.errorValues?.counter ?? 0,
            errorMessages: [],
            errorStatus: 0,
          });
        } else if (
          emailValidationResponse.status !== httpConstants.OK &&
          emailValidationResponse.data
        ) {
          props.setErrorState({
            errorStatus: emailValidationResponse.status,
            errorMessages: [emailValidationResponse.data],
            counter: 0,
          });
        }
      }
    } else if (
      !isFresh &&
      zipCodeValidationResponse &&
      emailValidationResponse
    ) {
      // zip code is valid and email is valid
      if (
        emailValidationResponse?.status === httpConstants.OK &&
        zipCodeValidationResponse?.status === httpConstants.OK
      ) {
        const formValues: AccountCreationFormValues = {
          firstName,
          lastName,
          email,
          password,
          occupation,
          country,
          zipCode,
        };
        if (isStudent) {
          formValues.graduationYear = graduationYear;
        }
        props.onContinue(formValues, !isContinueMode, isStudent);
        props.setErrorState({
          counter: props.errorValues?.counter ?? 0,
          errorMessages: [],
          errorStatus: 0,
        });
      } else {
        // error occured
        let error: ErrorValues = {
          counter: 0,
          errorStatus: 0,
          errorMessages: [],
        };

        //email error
        if (
          emailValidationResponse.status !== httpConstants.OK &&
          emailValidationResponse.data
        ) {
          error = {
            ...error,
            errorStatus: emailValidationResponse.status,
            errorMessages: [emailValidationResponse.data],
          };
        }
        //zipcode error
        if (
          zipCodeValidationResponse.status !== httpConstants.OK &&
          zipCodeValidationResponse.data
        ) {
          error = {
            ...error,
            errorStatus: zipCodeValidationResponse.status,
            errorMessages: [
              ...error.errorMessages,
              zipCodeValidationResponse.data,
            ],
          };
        }

        props.setErrorState({
          errorStatus: error?.errorStatus ?? 500,
          errorMessages: error?.errorMessages ?? [],
          counter: 0,
        });
      }
    }
  }, [state.zipCodeValidationResponse, state.emailValidationResponse]);

  React.useEffect(() => {
    if (!isFresh) {
      setIsContinueMode(
        state.specialtiesResponse?.data?.length > 0 ||
          isNpiVisible(country?.iso3166alpha2, occupation?.text)
      );
    }
  }, [state.specialtiesResponse, country]);

  React.useEffect(() => {
    let hasDisabled = false;
    if (isMoreFieldsVisible && !isExtraValid) {
      hasDisabled = true;
    } else {
      Object.keys(validationStates.current).forEach((key) => {
        if (!validationStates.current[key]) {
          hasDisabled = true;
        }
      });
    }
    setIsButtonDisabled(hasDisabled);
  });

  return (
    <form onSubmit={handleSubmit} data-testid="createAccountForm">
      <TextField
        name="firstName"
        testId="firstName"
        label={strings.first_name}
        helpTestId="firstNameHelp"
        validation={validateFirstName}
        validationError={firstNameError}
        value={firstName}
        onClickField={onFirstNameFocus}
        isNewDesign={true}
      />
      <TextField
        name="lastName"
        testId="lastName"
        label={strings.last_name}
        helpTestId="lastNameHelp"
        validation={validateLastName}
        validationError={lastNameError}
        value={lastName}
        onClickField={onLastNameFocus}
        isNewDesign={true}
      />
      <TextField
        name="email"
        type="email"
        testId="email"
        helpTestId="emailHelp"
        label={strings.login_email}
        validation={validateEmail}
        validationError={emailError}
        value={email}
        onClickField={onEmailFocus}
        isNewDesign={true}
        disabled={props.isGroupPurchaseNewUser}
        noAutoComplete={true}
      />

      {props.isGroupPurchaseNewUser ? (
        <Box>
          <Typography className={classes.noteContent}>
            You can change your email later in your Account settings
          </Typography>
        </Box>
      ) : (
        <TextField
          name="confirmEmail"
          type="email"
          testId="confirmEmail"
          helpTestId="confirmEmailHelp"
          label={strings.confirm_email}
          validation={validateConfirmEmail}
          validationError={confirmEmailError}
          value={confirmEmail}
          disablePaste
          isNewDesign={true}
        />
      )}
      <Password
        labelTestId="passwordLabel"
        labelHtmlFor="outlined-adornment-password"
        labelTitle={strings.login_password}
        name="password"
        ariaLabel="toggle password visibility"
        iconTestId="showPasswordId"
        helpTestId="passwordHelp"
        validateInput={validatePassword}
        defaultValue={props.formValues.password}
        onClickEvent={onPasswordFocus}
        labelWidth={0}
        isNewDesign={true}
        testId="password"
        placeholerText={strings.login_password}
        noAutoComplete={true}
      />
      <div className={clsx(classes.fieldDivider)} />
      <DropDownMenu
        id="occupation"
        name="Occupation"
        testId="occupation"
        noBottomMargin
        validation={handleOccupationChange}
        value={negativeToEmpty(occupation.id)}
        minWidth="343px"
        maxWidth="343px"
        isRequiredField
        list={state?.occupationsResponse || { data: empty }}
        isNewDesign={true}
      />
      {isMoreFieldsVisible ? (
        <CreateAccountSubForm
          setValid={setIsExtraValid}
          occupation={occupation}
          setZipCode={setZipCode}
          setGraduationYear={setGraduationYear}
          setCountry={setCountry}
          setIsStudent={setIsStudent}
          zipCode={zipCode}
          graduationYear={graduationYear}
          country={country}
          isStudent={isStudent}
          setIsZipValid={setIsZipValid}
          suppressCountryDropDown={props.isGroupPurchaseNewUser}
        />
      ) : null}
      <Button
        name={isContinueMode ? strings.continue : strings.create_account}
        id="createAccountSubmit"
        type="submit"
        backgroundColor={colors["--primary-tealnight"]}
        height={48}
        fontFamily={"Source Sans Pro"}
        fontSize={18}
        hoverForeground={colors["--primary-tealnight"]}
        inProgress={
          props.inProgress ||
          state.email_validation_progress ||
          state.zipCode_validation_progress ||
          state.specialties_progress
        }
      />
    </form>
  );
};
CreateAccountForm.defaultProps = defaultProps;
export const CreateAccountFormWith = withRoot(CreateAccountForm);
