export const customVindiciaStyle = {
  input: {
    width: "calc(100% - 1px)",
    display: "block",
    height: "48px",
    "font-family": "HelveticaNeue",
    "font-size": "16px",
    color: "#506C8E",
    padding: "6px 12px",
    margin: "8px 0px 20px 0px",
    "line-height": "1.42857",
    border: "1.5px solid #506C8E",
    "border-radius": "4px",
    "box-shadow": "0px 1px 1px rgba(0,0,0,0.075) inset",
    "-webkit-transition":
      "border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s",
    transition:
      "border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s",
  },
  "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
    {
      "-webkit-box-shadow": "0 0 0 30px white inset !important",
    },
  "input::placeholder": {
    color: "#828a97",
    "line-height": 1.25,
  },
  ":focus": {
    "border-color": "#66afe9",
    outline: "0",
    "-webkit-box-shadow":
      "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)",
    "box-shadow":
      "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)",
  },
  ".valid": {
    "border-color": "#506C8E",
  },
  ".notValid": {
    "border-color": "#ff0000",
  },
  ".text-block": {
    padding: "10px",
    "background-color": "#ccc",
    width: "300px",
  },
};
