import { strings } from "../utils/Strings";

export const graduationYearCount = 8;
export const occupationsWithNPI = ["MD", "DO"];
export const npiProfileLimit = 7;
export const leftNavContent = [
  {
    title: strings.create_account_title_1,
    subtitle: strings.create_account_subtitle_1,
  },
  {
    title: strings.create_account_title_2,
    subtitle: strings.create_account_subtitle_2,
  },
  {
    title: strings.create_account_title_3,
    subtitle: strings.create_account_subtitle_3,
  },
];
export const SIGNUP_VIEW = "signup";
export const CLAIM_PROFILE_VIEW = "claimProfile";
export const NPI_VIEW = "npi";
export const getViewQueryStr = (view: string, referNext?: string) =>
  `?view=${view}` +
  (!!referNext ? `&refernext=${encodeURIComponent(referNext ?? "")}` : "");
export const euCountryCodes = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
  "GB",
];
export const usCountryCode = "US";
export const usTerritories = [
  usCountryCode,
  "AS",
  "GU",
  "PR",
  "VI",
  "MH",
  "MP",
  "PW",
];
export const isUsTerritoryEnabled = true;
