import { Dispatch, SetStateAction, useEffect, useState } from "react";
import clsx from "clsx";
import { strings } from "../utils/Strings";
import { globalDialogStyles } from "../styles/globalDialogStyles";
import { licenseStyles } from "../styles/licenseStyles";
import { IPrimaryPaymentInformation } from "../models/PrimaryPaymentMethodResponse";

interface LicensePaymentAddressFieldsPropsType {
  paymentMethodDetails?: IPrimaryPaymentInformation;
  setAddressValid: (val: boolean) => void;
  setZipcode?: Dispatch<SetStateAction<string | undefined>>;
}
export const LicensePaymentAddressFields: React.FC<
  LicensePaymentAddressFieldsPropsType
> = (props: LicensePaymentAddressFieldsPropsType) => {
  const classes = globalDialogStyles();
  const cls = licenseStyles();
  const [values, setValues] = useState({
    isTest: false,
    isValidAddress1: false,
    isValidCity: false,
    isValidState: false,
    isValidZipCode: false,
    disabledPayment: true,
  });
  const [stateValue, setStateValue] = useState("");
  const [isFormInteracted, setIsFormInteracted] = useState({
    address1: false,
    city: false,
    state: false,
    zipCode: false,
  });
  const isMobileScreen = window.innerWidth < 600;
  const address1Validation = () => {
    setIsFormInteracted({ ...isFormInteracted, address1: true });
    const data = (
      document.getElementById("vin_billing_address_line1") as HTMLInputElement
    ).value;
    if (data && data?.length > 0) {
      setValues({ ...values, isValidAddress1: true });
    } else {
      setValues({ ...values, isValidAddress1: false });
    }
  };
  const cityValidation = () => {
    setIsFormInteracted({ ...isFormInteracted, city: true });
    const data = (
      document.getElementById("vin_billing_address_city") as HTMLInputElement
    ).value;
    if (data && data?.length > 0) {
      setValues({ ...values, isValidCity: true });
    } else {
      setValues({ ...values, isValidCity: false });
    }
  };
  const stateValidation = () => {
    setIsFormInteracted({ ...isFormInteracted, state: true });
    const data = (
      document.getElementById(
        "vin_billing_address_district"
      ) as HTMLInputElement
    ).value;
    if (data?.length === 2) {
      setValues({ ...values, isValidState: true });
    } else {
      setValues({ ...values, isValidState: false });
    }
  };
  const stateValueChangeHandler = (val: string) => {
    let str = "";
    if (val.length > 2) {
      val = val.substring(0, 2);
    }
    for (let i = 0; i < val.length; i++) {
      const charCode = val.charCodeAt(i);
      if (charCode >= 97 && charCode <= 122) {
        str += String.fromCharCode(charCode - 32);
      } else if (charCode >= 65 && charCode <= 90) {
        str += val[i];
      }
    }
    setStateValue(str);
    stateValidation();
  };

  const zipCodeValidation = () => {
    setIsFormInteracted({ ...isFormInteracted, zipCode: true });
    const data = (
      document.getElementById(
        "vin_billing_address_postal_code"
      ) as HTMLInputElement
    ).value;
    if (data) {
      setValues({ ...values, isValidZipCode: true });
      if (props.setZipcode) {
        props.setZipcode(data);
      }
    } else {
      setValues({ ...values, isValidZipCode: false });
    }
  };

  useEffect(() => {
    props.setAddressValid(
      values.isValidAddress1 &&
        values.isValidCity &&
        values.isValidState &&
        values.isValidZipCode
    );
  }, [values]);

  useEffect(() => {
    let tempValues = values;
    if (props.paymentMethodDetails?.billingAddress1) {
      document
        ?.getElementById("vin_billing_address_line1")
        ?.setAttribute("value", props.paymentMethodDetails?.billingAddress1);
      tempValues = { ...tempValues, isValidAddress1: true };
    }
    if (props.paymentMethodDetails?.billingAddress2) {
      document
        ?.getElementById("vin_billing_address_line2")
        ?.setAttribute("value", props.paymentMethodDetails?.billingAddress2);
    }
    if (props.paymentMethodDetails?.city) {
      document
        ?.getElementById("vin_billing_address_city")
        ?.setAttribute("value", props.paymentMethodDetails?.city);
      tempValues = { ...tempValues, isValidCity: true };
    }
    if (props.paymentMethodDetails?.state) {
      setStateValue(props.paymentMethodDetails?.state);
      tempValues = { ...tempValues, isValidState: true };
    }
    if (props.paymentMethodDetails?.postalCode) {
      document
        ?.getElementById("vin_billing_address_postal_code")
        ?.setAttribute("value", props.paymentMethodDetails?.postalCode);
      tempValues = { ...tempValues, isValidZipCode: true };
    }
    setValues(tempValues);
  }, []);
  return (
    <>
      <div>
        <label
          className={clsx(classes.vindiciaLabel, cls.vindiciaLableMargin)}
          htmlFor="vin_billing_address_line1"
        >
          <span className={clsx(cls.asterisk, cls.primaryOrange)}>*</span>
          {strings.address}
          <div data-testid="vin_billing_address_line1">
            <input
              className={clsx(
                classes.customInputField,
                isFormInteracted.address1 && !values.isValidAddress1
                  ? classes.invalidInput
                  : ""
              )}
              type="text"
              name="vin_billing_address_line1"
              id="vin_billing_address_line1"
              placeholder={strings.address}
              onChange={address1Validation}
            />
          </div>
        </label>
      </div>
      <div>
        <label
          className={clsx(classes.vindiciaLabel, cls.vindiciaLableMargin)}
          htmlFor="vin_billing_address_line2"
        >
          {isMobileScreen ? strings.aptSuiteEtc : strings.addressLine2Optional}
          <div data-testid="vin_billing_address_line2">
            <input
              className={clsx(classes.customInputField)}
              type="text"
              name="vin_billing_address_line2"
              id="vin_billing_address_line2"
              placeholder={
                isMobileScreen
                  ? `(${strings.optional})`
                  : strings.addressLine2Optional
              }
            />
          </div>
        </label>
      </div>

      <div>
        <label
          className={clsx(classes.vindiciaLabel, cls.vindiciaLableMargin)}
          htmlFor="vin_billing_address_city"
        >
          <span className={clsx(cls.asterisk, cls.primaryOrange)}>*</span>
          {strings.city}
          <div data-testid="vin_billing_address_city">
            <input
              className={clsx(
                classes.customInputField,
                isFormInteracted.city && !values.isValidCity
                  ? classes.invalidInput
                  : ""
              )}
              type="text"
              name="vin_billing_address_city"
              id="vin_billing_address_city"
              placeholder={strings.city}
              onChange={cityValidation}
            />
          </div>
        </label>
      </div>
      <div className={clsx(cls.splitPmtField)}>
        <div className={clsx(cls.flexSpaceOnCardItems)}>
          <label
            className={clsx(classes.vindiciaLabel, cls.vindiciaLableMargin)}
            htmlFor="vin_billing_address_district"
          >
            <span className={clsx(cls.asterisk, cls.primaryOrange)}>*</span>
            {strings.state}
            <div data-testid="vin_billing_address_district">
              <input
                className={clsx(
                  classes.customInputField,
                  isFormInteracted.state && !values.isValidState
                    ? classes.invalidInput
                    : ""
                )}
                type="text"
                name="vin_billing_address_district"
                id="vin_billing_address_district"
                placeholder={strings.state}
                value={stateValue}
                onChange={(e) =>
                  stateValueChangeHandler(e.target.value as string)
                }
              />
            </div>
          </label>
        </div>
        <div className={clsx(cls.flexSpaceOnCardItems)}>
          <label
            className={clsx(classes.vindiciaLabel, cls.vindiciaLableMargin)}
            htmlFor="vin_billing_address_postal_code"
          >
            <span className={clsx(cls.asterisk, cls.primaryOrange)}>*</span>
            {strings.zip_code}
            <div>
              <input
                className={clsx(
                  classes.customInputField,
                  isFormInteracted.zipCode && !values.isValidZipCode
                    ? classes.invalidInput
                    : ""
                )}
                type="text"
                name="vin_billing_address_postal_code"
                placeholder={strings.zip_code}
                id="vin_billing_address_postal_code"
                onChange={zipCodeValidation}
              />
            </div>
          </label>
        </div>
      </div>
    </>
  );
};
