import axios, { AxiosResponse } from "axios";
import { config } from "../config/envConfig";
import { refreshAccess } from "src/utils/cookies";
import { IPaymentMethodVid } from "src/models/PrimaryPaymentMethodResponse";
import { CURRENT_USER_ID } from "src/utils/localStorageKeys";
import { isOfType } from "src/utils/typeguard";
import { RedeemRequestPayload } from "src/components/RedeemLicense";

const { purchaseUrl } = config.environment;
const instance = axios.create({
  baseURL: purchaseUrl,
  timeout: 30000,
  validateStatus(status) {
    return status >= 200 && status < 500;
  },
});

export interface LicensePurchaseDetails {
  subtotal: number;
  promoPercentageOff: number;
  promoDiscount: number;
  tax: number;
  total: number;
  msrpTotal: number;
  licenseCode?: string;
}
function isLicensePurchaseDetails(obj: unknown): obj is LicensePurchaseDetails {
  const purchaseDetails = obj as LicensePurchaseDetails;
  return isOfType<LicensePurchaseDetails>(purchaseDetails, [
    "subtotal",
    "total",
    "promoPercentageOff",
    "promoDiscount",
    "tax",
    "msrpTotal",
  ]);
}
export interface LicensePurchaseResponse {
  data: LicensePurchaseDetails;
  status: number;
}
function isLicensePurchaseResponse(
  obj: unknown
): obj is LicensePurchaseResponse {
  const purchaseDetailsResponse = obj as LicensePurchaseDetails;
  return (
    isOfType<LicensePurchaseResponse>(purchaseDetailsResponse, [
      "data",
      "status",
    ]) && isLicensePurchaseDetails(purchaseDetailsResponse?.data)
  );
}

export interface LicensePurchaseCalculatePayload {
  userId?: number;
  sku: string;
  quantity: number;
  institution?: string;
  paymentId?: string;
}

export const calculateLicensePurchase = async (
  request: LicensePurchaseCalculatePayload
): Promise<LicensePurchaseResponse> => {
  try {
    const response = await instance.post(
      "/v1/purchase/license/calculate",
      request,
      {
        headers: { accept: "application/json" },
      }
    );
    if (isLicensePurchaseResponse(response)) {
      return response;
    } else {
      throw new Error("Response is not of type LicensePurchaseResponse");
    }
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};

export type LicensePurchaseCommitPayload =
  Required<LicensePurchaseCalculatePayload>;

export const commitLicensePurchase = async (
  request: LicensePurchaseCommitPayload
): Promise<LicensePurchaseResponse> => {
  const cookie = await refreshAccess();
  try {
    const response = await instance.post(
      "/v1/purchase/license/commit",
      request,
      {
        headers: {
          Authorization: `${cookie}`,
          accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};

export const getPaymentMethodDetails = async (
  request: IPaymentMethodVid
): Promise<AxiosResponse> => {
  const cookie = await refreshAccess();
  const userId = localStorage.getItem(CURRENT_USER_ID);
  try {
    const response = await instance.put(
      `/v2/paymentmethods/user/${userId}`,
      request,
      {
        headers: {
          Authorization: `${cookie}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};
export const redeemLicenseCode = async (
  request: RedeemRequestPayload
): Promise<AxiosResponse> => {
  const userId = localStorage.getItem(CURRENT_USER_ID);
  const cookie = await refreshAccess();
  try {
    const response = await instance.post(
      `/v1/redeem/user/${userId}/license/${request.licenseCode}`,
      null,
      {
        headers: {
          Authorization: `${cookie}`,
          accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};
