import { put, call, CallEffect, PutEffect } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import * as types from "../actions";
import { loginErrorMessage } from "../utils/errormessage";
import { SnackBarType } from "../utils/SnackBarType";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import {
  getRieventSSOTokenService,
  RieventSSOTokenRequest,
  RieventSSOTokenResponse,
} from "../services/cmeLaunchService";
import {
  CME_WEBLAUNCH_FAIL,
  CME_WEBLAUNCH_FAIL_EVENT_ID,
} from "../utils/analytics";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";

export function* cmeLaunchSaga(authRequest: RieventSSOTokenRequest): Generator<
  | CallEffect<any>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  let amplitudeProperties: any = {};
  const userId = localStorage.getItem(CURRENT_USER_ID);
  try {
    yield put({ type: types.CME_LAUNCH_GET_TOKEN_INPROGRESS });

    const response = yield call(getRieventSSOTokenService, authRequest);
    let rieventSSOTokenResponse: RieventSSOTokenResponse;

    if (!response) {
      yield put({ type: types.CME_LAUNCH_GET_TOKEN_SYSTEM_ERROR });
      return;
    }

    if (typeof response.data === "object") {
      rieventSSOTokenResponse = response.data;
    } else {
      rieventSSOTokenResponse = {} as any;
    }

    if (response.status === 200) {
      yield put({ type: types.TIMEOUT_SNACKBAR });

      if (response.data.requiresReview) {
        yield put({
          type: types.CME_LAUNCH_GET_TOKEN_PROFILE_REQUIRES_REVIEW,
          rieventSSOTokenResponse,
        });
      } else {
        yield put({
          type: types.CME_LAUNCH_GET_TOKEN_SUCCESS,
          rieventSSOTokenResponse,
        });
      }
    } else if (response.status === 401) {
      amplitudeProperties = {};
      const statusCode = 401;
      const cmeLaunchFailureProblem = "RequireUserManualLogin";
      amplitudeProperties["Event ID"] = CME_WEBLAUNCH_FAIL_EVENT_ID;
      amplitudeProperties["Failure Reason"] = cmeLaunchFailureProblem;
      amplitudeProperties["Status Code"] = statusCode;
      sendAmplitudeEvent(CME_WEBLAUNCH_FAIL, amplitudeProperties, userId);
      yield put({ type: types.REQUIRE_USER_MANUAL_LOGIN });
    } else if (response.status >= 400 && response.status < 500) {
      amplitudeProperties = {};
      const statusCode = response.status;
      const cmeLaunchFailureProblem = "Error in CME sign-in";
      amplitudeProperties["Event ID"] = CME_WEBLAUNCH_FAIL_EVENT_ID;
      amplitudeProperties["Failure Reason"] = cmeLaunchFailureProblem;
      amplitudeProperties["Status Code"] = statusCode;
      sendAmplitudeEvent(CME_WEBLAUNCH_FAIL, amplitudeProperties, userId);
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message:
          loginErrorMessage(rieventSSOTokenResponse) || "Error in CME sign-in",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({
        type: types.CME_LAUNCH_GET_TOKEN_ERROR,
        rieventSSOTokenResponse,
      });
    } else {
      amplitudeProperties = {};
      const statusCode = response.status;
      const cmeLaunchFailureProblem = "System Error";
      amplitudeProperties["Event ID"] = CME_WEBLAUNCH_FAIL_EVENT_ID;
      amplitudeProperties["Failure Reason"] = cmeLaunchFailureProblem;
      amplitudeProperties["Status Code"] = statusCode;
      sendAmplitudeEvent(CME_WEBLAUNCH_FAIL, amplitudeProperties, userId);
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: loginErrorMessage(rieventSSOTokenResponse) || "System error",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({
        type: types.CME_LAUNCH_GET_TOKEN_SYSTEM_ERROR,
        rieventSSOTokenResponse,
      });
    }
  } catch (error) {
    yield put({ type: types.LOGIN_USER_ERROR, error });
  }
}
