import clsx from "clsx";
import { useEffect } from "react";
import { ITransaction } from "src/models/ITransaction";
import { colors } from "../config/colorConfig";
import "../styles/index.css";
import logo from "../images/epocrates-logo@3x.png";
import { ErrorScreentWithRoot as ErrorScreen } from "../components/ErrorScreen";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import { RouteComponentProps } from "react-router";
import { CUSTOMER_NAME, TRANSACTION } from "src/utils/localStorageKeys";

const transactionReceiptStyle = makeStyles((theme) => ({
  pdfView: {
    width: "100%",
    height: "100%",
  },
  progressDiv: {
    height: 300,
  },
  progress: {
    width: 20,
    height: 20,
    color: colors["--accent-violet"],
    position: "relative",
    top: "25%",
    left: "50%",
  },
}));

const pdfStyles = StyleSheet.create({
  body: {
    paddingBottom: 60,
    flexDirection: "column",
    flex: 1,
  },
  mainView: {
    margin: 32,
  },
  headerView: {
    flexDirection: "row",
    marginTop: 12,
    marginBottom: 6,
  },
  logo: {
    paddingLeft: 4,
    width: 180,
    height: 40,
  },

  section: {
    flex: 1,
  },
  companyAddress: {
    marginTop: 8,
    fontSize: 12,
  },

  lineStyle: {
    borderWidth: 0.25,
    borderColor: "rgb(0,0,0)",
  },
  subTitle: {
    marginTop: 20,
  },
  subHeader: {
    marginBottom: 12,
  },
  content: {
    lineHeight: 2,
    fontSize: 12,
    fontWeight: 200,
  },
  subView: {
    flexDirection: "row",
    alignItems: "center",
  },
  rightSideContext: {
    alignItems: "center",
  },
  leftSideContext: {
    flex: 1,
    paddingLeft: 32,
  },
});
export const TransactionReceipt = (props: RouteComponentProps): JSX.Element => {
  const queryParams = new URLSearchParams(props?.location?.search);
  const transactionId = queryParams.has("id") ? queryParams.get("id") : "";

  const errorScreen = <ErrorScreen code={404}></ErrorScreen>;
  const customerName = localStorage.getItem(CUSTOMER_NAME);
  const clearTransaction = () => {
    const storedTransactionsArray: ITransaction[] = JSON.parse(
      localStorage.getItem(TRANSACTION) ?? "[]"
    );
    if (storedTransactionsArray?.length > 0) {
      const index = storedTransactionsArray.findIndex(
        (transactionObj) => transactionObj.orderNumber === transactionId
      );
      storedTransactionsArray.splice(index, 1);
      localStorage.removeItem(TRANSACTION);
      if (storedTransactionsArray.length > 0) {
        localStorage.setItem(
          "TRANSACTION",
          JSON.stringify(storedTransactionsArray)
        );
      }
    }
  };
  useEffect(() => {
    window.addEventListener("unload", clearTransaction);
    return () => {
      window.removeEventListener("unload", clearTransaction);
    };
  });
  let transactionReceipt: ITransaction | undefined;
  const storedTransactions: ITransaction[] = JSON.parse(
    localStorage.getItem(TRANSACTION) ?? "[]"
  );
  if (storedTransactions?.length > 0) {
    transactionReceipt = storedTransactions.find(
      (transactionObj) => transactionObj.orderNumber === transactionId
    );
  }

  if (!transactionReceipt) {
    return <>{errorScreen}</>;
  }
  const classes = transactionReceiptStyle();
  const renderPdfDoc = (
    <>
      {transactionReceipt && (
        <Document>
          <Page size="A4" style={pdfStyles.body}>
            <View style={pdfStyles.mainView}>
              <View style={pdfStyles.headerView}>
                <View style={pdfStyles.section}>
                  <Image style={pdfStyles.logo} src={logo} />
                </View>
                <View style={pdfStyles.companyAddress}>
                  <Text style={{ paddingLeft: 40 }}>311 Arsenal St.</Text>
                  <Text> Watertown, MA. 02472</Text>
                </View>
              </View>

              <View style={pdfStyles.lineStyle} />
              <View style={pdfStyles.subTitle}>
                <Text
                  data-testid="transaction-sub-header"
                  style={pdfStyles.subHeader}
                >
                  Name: {customerName}
                </Text>
              </View>
              <View style={pdfStyles.subTitle}>
                <Text
                  data-testid="transaction-sub-header"
                  style={pdfStyles.subHeader}
                >
                  Transaction Receipt
                </Text>
                <View
                  data-testid="alert-dialog-planName"
                  style={pdfStyles.content}
                >
                  <View style={pdfStyles.subView}>
                    <Text>Amount:</Text>
                    <Text style={{ flex: 1, paddingLeft: 32 }}>
                      {transactionReceipt.payment?.productPrice}
                    </Text>
                    <Text style={pdfStyles.rightSideContext}>
                      Date Of Transaction:{" "}
                      {dayjs(new Date(transactionReceipt.date)).format(
                        "MM/DD/YYYY"
                      )}
                    </Text>
                  </View>

                  {transactionReceipt.payment?.discount &&
                  transactionReceipt.payment?.discount !== "$0.00" ? (
                    <>
                      {/* Discount block */}
                      <View style={pdfStyles.subView}>
                        <Text>Discount:</Text>
                        <Text
                          style={{ flex: 1, paddingLeft: 26, color: "red" }}
                        >
                          {transactionReceipt.payment?.discount}
                        </Text>
                        <Text style={{ marginRight: 10 }}>
                          Order Number: {transactionReceipt.orderNumber}
                        </Text>
                      </View>
                      {/* SubTotal block */}
                      <View style={pdfStyles.subView}>
                        <Text>Subtotal:</Text>
                        <Text style={{ flex: 1, paddingLeft: 28 }}>
                          {transactionReceipt.payment?.subtotal}
                        </Text>
                        <Text style={{ marginRight: 14 }}>
                          Item: {transactionReceipt.productName}
                        </Text>
                      </View>
                    </>
                  ) : (
                    <>
                      <View style={pdfStyles.subView}>
                        <Text
                          style={{ flex: 1, paddingLeft: 26, color: "red" }}
                        />
                        <Text style={{ marginRight: 10 }}>
                          Order Number: {transactionReceipt.orderNumber}
                        </Text>
                      </View>
                      <View style={pdfStyles.subView}>
                        <Text style={{ flex: 1, paddingLeft: 28 }} />
                        <Text style={{ marginRight: 14 }}>
                          Item: {transactionReceipt.productName}
                        </Text>
                      </View>
                    </>
                  )}

                  <View style={pdfStyles.subView}>
                    <Text>Sales Tax: </Text>
                    <Text style={{ flex: 1, paddingLeft: 20 }}>
                      {transactionReceipt.payment?.tax}
                    </Text>
                  </View>
                  <View style={pdfStyles.subView}>
                    <Text>Total: </Text>
                    <Text style={{ flex: 1, paddingLeft: 46 }}>
                      {transactionReceipt.payment?.total}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={pdfStyles.subTitle}>
                <Text style={pdfStyles.subHeader}>Payment Information</Text>
              </View>
              <View style={pdfStyles.content}>
                <Text>
                  Card Number:
                  {transactionReceipt.paymentMethod?.cardNumber
                    ? " XXXX XXXX XXXX " +
                      transactionReceipt.paymentMethod.cardNumber
                    : ""}
                </Text>

                <Text>
                  Expiration Date:{" "}
                  {transactionReceipt.paymentMethod?.expirationDate
                    ? dayjs(
                        transactionReceipt.paymentMethod.expirationDate
                      ).format("MM/YY")
                    : ""}
                </Text>
                <Text>
                  Name on Card:{" "}
                  {transactionReceipt.paymentMethod?.accountHolder}
                </Text>
              </View>
              <View style={pdfStyles.subTitle}>
                <Text style={pdfStyles.subHeader}>Billing Address</Text>
              </View>
              <View style={pdfStyles.content}>
                <Text>
                  Address: {transactionReceipt.paymentMethod?.billingAddress1}{" "}
                  {transactionReceipt.paymentMethod?.billingAddress2}
                </Text>
                <Text>City: {transactionReceipt.paymentMethod?.city}</Text>
                <Text>State: {transactionReceipt.paymentMethod?.state}</Text>
                <Text>
                  Zip Code: {transactionReceipt.paymentMethod?.postalCode}
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      )}
    </>
  );
  return (
    <PDFViewer className={clsx(classes.pdfView)}>{renderPdfDoc}</PDFViewer>
  );
};
