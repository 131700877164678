import { AccountCreationRequest } from "../models/AccountCreationRequest";
import * as types from "./index";
import { EmailValidationRequest } from "../models/EmailValidationRequest";
import { ZipCodeValidationRequest } from "../models/ZipCodeValidationRequest";

export const createUserAction = (accountRequest: AccountCreationRequest) => ({
  type: types.ACCOUNT_CREATION,
  accountRequest,
});

export const emailValidationAction = (
  validationRequest: EmailValidationRequest
) => ({
  type: types.EMAIL_VALIDATION,
  validationRequest,
});

export const zipCodeValidationAction = (
  validationRequest: ZipCodeValidationRequest
) => ({
  type: types.ZIP_CODE_VALIDATION,
  validationRequest,
});

export const clearZipCodeValidationAction = () => ({
  type: types.CLEAR_ZIP_CODE_VALIDATION_STATE,
});
