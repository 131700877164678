import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CssBaseline } from "@material-ui/core";
import { getCookie, setCookie } from "../utils/cookies";
import {
  authCodeRedeemUserAction,
  requireManualLoginAction,
} from "../actions/authenticationActions";
import { AuthorizationCodeRedeemRequest } from "../models/AuthorizationCodeRedeemRequest";

import { ProgressPage } from "../components/ProgressPage";
import { CURRENT_USER_ID, CURRENT_USER_NAME } from "../utils/localStorageKeys";

interface PrivateCmeRouteProps extends RouteProps {
  component: any;
  location?: any;
  history?: any;
}

interface AuthenticationReduxState {
  attemptingAuthorizationCodeRedemption: boolean;
  completedAuthorizationCodeRedemptionAttempt: boolean;
  completedAuthorizationCodeRedemptionAttemptFailed: boolean;
  tryEmailPasswordPrompt: boolean;
}

export const PrivateCmeRoute = (props: PrivateCmeRouteProps) => {
  const state = useSelector(
    (globalState: any): AuthenticationReduxState => globalState.privateCmeRouter
  );
  const [extReferrer, setExtReferrer] = React.useState("");
  const dispatch = useDispatch();
  const { component: Component, ...rest } = props;
  const queryParams = new URLSearchParams(props.location.search);
  const authorizationCode = queryParams.get("authorizationCode");
  const forceLogout = queryParams.get("forceLogout");
  const skipAuthorization = queryParams.get("skipAuth") ?? "false";

  React.useEffect(() => {
    if (
      !extReferrer &&
      document.referrer &&
      document.referrer !== window.location.href
    ) {
      // Latch in the initial referrer URL
      setExtReferrer(document.referrer);
    }
  }, []);

  if (forceLogout === "true") {
    setCookie("refreshToken", -1, "");
    setCookie("accessToken", -1, "");
    localStorage.removeItem(CURRENT_USER_ID);
    localStorage.removeItem(CURRENT_USER_NAME);
    setCookie("sessionTimer", -1, "");
    queryParams.delete("forceLogout");
    // eslint-disable-next-line no-param-reassign
    props.location.search = `?${queryParams.toString()}`;
  }

  React.useEffect(() => {
    const accessTokenCookie = getCookie("accessToken");
    if (
      authorizationCode &&
      !state.completedAuthorizationCodeRedemptionAttempt &&
      !state.attemptingAuthorizationCodeRedemption
    ) {
      const authorizationCodeRedeemRequest: AuthorizationCodeRedeemRequest = {
        authorizationCode,
      };
      dispatch(authCodeRedeemUserAction(authorizationCodeRedeemRequest));
    } else if (accessTokenCookie) {
      // In this case we already have an extant accessToken.  Which may or may not be in-date.
    } else if (
      !state.tryEmailPasswordPrompt &&
      !state.attemptingAuthorizationCodeRedemption &&
      forceLogout !== "true" &&
      skipAuthorization !== "true"
    ) {
      // Fire an event to put us in "needs manual email/password login"
      dispatch(requireManualLoginAction());
    }
  }, [state]);

  // If an authorization-code redemption is in-progress
  if (
    (authorizationCode || state.attemptingAuthorizationCodeRedemption) &&
    !state.completedAuthorizationCodeRedemptionAttempt
  ) {
    const progress = (
      <>
        <CssBaseline />
        <ProgressPage
          data-testid="progressValidationProfile"
          bodyTitle
          inProgress
        />
      </>
    );
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...rest} render={() => progress} />;
  }

  // At this point, authorizationCode query param either worked and we have an access token, or it didn't.
  // Strip it from the query params so it's passed around less, and doesn't trigger an accidental re-redemption
  // attempt later.
  if (authorizationCode) {
    queryParams.delete("authorizationCode");
    const newSearchString = queryParams.toString();
    console.log("Redirecting without auth to:", props.location.pathname);
    console.log("Redirecting without auth to:", newSearchString);
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Route {...rest}>
        <Redirect
          to={{
            pathname: props.location.pathname,
            search: `?${newSearchString}`,
          }}
        />
      </Route>
    );
  }

  // If a redirect to email/password prompt is indicated, do that
  if (state.tryEmailPasswordPrompt) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Route {...rest}>
        <Redirect
          to={{
            pathname: "/login",
            search: window.location.href
              ? `?refernext=${encodeURIComponent(window.location.href)}`
              : "",
            state: { from: props.location },
          }}
        />
        )
      </Route>
    );
  }

  // Else we are as authenticated as we will ever get; show the actual child component.
  // (Adding extReferrer (as cancelToReferrerURL) to properties of rendered component)
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(routeProps: any) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...routeProps} cancelToReferrerURL={extReferrer} />
      )}
    />
  );
};

PrivateCmeRoute.defaultProps = {
  location: undefined,
  history: undefined,
};
