import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { IconSpinner } from "@athena/epoc-frontend-library/dist";

export const LoadingEpocLogo = () => {
  const styles = makeStyles((theme) => ({
    iconSpinnerWrapper: {
      margin: "auto",
    },
  }));
  const classes = styles();
  return (
    <div className={clsx(classes.iconSpinnerWrapper)}>
      <IconSpinner />
    </div>
  );
};
