import { CssBaseline } from "@material-ui/core";
import { AppAltBackground } from "src/components/custom/AppAltBackground";
import React from "react";
import { QRFooter } from "src/components/custom/QRFooter";
import { RouteComponentProps } from "react-router-dom";
import { OtcEmailForm } from "src/components/OtcEmailForm";

export const OtcLoginWrap = (props: RouteComponentProps) => {
  const pageBody = (
    <>
      <CssBaseline />
      <OtcEmailForm {...props} />
      <QRFooter />
    </>
  );
  return <AppAltBackground pageBody={pageBody} />;
};
