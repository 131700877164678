import { PasswordValidation } from "../models/PasswordValidation";

function passwordIsCorrectMinLength(password: string, length: number): boolean {
  return password.length >= length;
}

function passwordIsCorrectLength(password: string): boolean {
  return passwordIsCorrectMinLength(password, 8);
}

function passwordContainsUpperAndLower(password: string): boolean {
  return (
    password.match(".*[a-z].*") !== null && password.match(".*[A-Z].*") !== null
  );
}

function passwordDoesNotContainEmail(password: string, email: string): boolean {
  const lowerCasePassword: string =
    password === null ? "" : password.toLowerCase();
  let containsEmail: boolean = email !== null && email !== "";
  if (containsEmail) {
    const indexOfAt: number = email?.indexOf("@");
    let userEmailName: string;
    if (indexOfAt !== -1) {
      userEmailName = email?.toLowerCase().substring(0, indexOfAt);
    } else {
      userEmailName = email?.toLowerCase();
    }
    containsEmail = lowerCasePassword?.indexOf(userEmailName) > -1;
  }
  return !containsEmail;
}

function passwordContainsNumber(password: string): boolean {
  return password.match(".*\\d.*") !== null;
}
export function isValidPassword(
  password: string,
  email: string
): PasswordValidation {
  const valid: PasswordValidation = {
    passwordNotEmpty: password !== null,
    passwordIsCorrectLength: passwordIsCorrectLength(password),
    passwordContainsUpperAndLower: passwordContainsUpperAndLower(password),
    passwordContainsNumber: passwordContainsNumber(password),
    passwordDoesNotContainEmail: passwordDoesNotContainEmail(password, email),
  };

  return valid;
}
export function hasNumberInString(str: string) {
  return str.match("\\d");
}
export function isProperNameLength(name: string) {
  return !(name.trim().length < 1 || name.trim().length > 200);
}

export function isNumber(n: string) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

export function ValidateZipCode(data: string) {
  return !/^(\d{5})([\s-+]?\d{4})?$/.test(data);
}
