import { Box, Checkbox, Link, Typography } from "@material-ui/core";
import { UseMutationResult } from "@tanstack/react-query";
import clsx from "clsx";
import moment from "moment";
import { useContext, useState } from "react";
import { SubscriptionContext } from "src/context/SubscriptionContext";
import {
  CommitRequestWrapper,
  TransactionDetails,
} from "src/services/purchaseService";
import { Product } from "src/services/subscriptionService";
import { CURRENT_USER_ID } from "src/utils/localStorageKeys";
import { colors } from "../config/colorConfig";
import { IPrimaryPaymentInformation } from "../models/PrimaryPaymentMethodResponse";
import { globalDialogStyles } from "../styles/globalDialogStyles";
import { licenseStyles } from "../styles/licenseStyles";
import { licenseTheme } from "../styles/licenseTheme";
import { strings } from "../utils/Strings";
import { OrderSummaryWindow } from "./OrderSummaryWindow";
import { Button } from "./custom/Button";
import { CardNumber } from "./custom/CardNumber";
import { LoadingEpocLogo } from "./custom/LoadingEpocLogo";
import { PurchaseRequestInfo } from "./UserSubscriptionLandingPage";
import { sendAmplitudeEvent } from "src/utils/AmplitudeUtil";
import { ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CONFIRM_PAYMENT_CLICK } from "src/utils/analytics";
import { useDispatch } from "react-redux";
import { sendSuccessSnackBarWithTitleMessage } from "src/actions/snackBarActions";
import { SnackBarType } from "src/utils/SnackBarType";
import {
  generateMainOrderEntries,
  generateSummaryOrderEntries,
} from "../utils/orderSummary";
import { RequestError, isRequestError } from "src/models/RequestError";

interface UserSubscriptionPaymentConfirmationProps {
  product: Product;
  paymentMethodDetails?: IPrimaryPaymentInformation;
  confirmButtonSpinner: boolean;
  setConfirmButtonSpinner: (val: boolean) => void;
  editCard: () => void;
  calculateLicenseResponse: TransactionDetails | undefined | RequestError;
  commitQuery: UseMutationResult<any, unknown, CommitRequestWrapper, unknown>;
  selectProduct: Product | undefined;
  isCalculatedResponseError: boolean;
  isCalculateRequestFetching: boolean;
  promoCode: string;
  setPromoCode: (code: string) => void;
}

export const UserSubscriptionPaymentConfirmation = (
  props: UserSubscriptionPaymentConfirmationProps
): JSX.Element => {
  const classes = globalDialogStyles();
  const clx = licenseStyles(licenseTheme);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [agreementNotChecked, setAgreementNotChecked] = useState(false);
  const { sourceType } = useContext(SubscriptionContext);

  const getAgreementText = () => {
    if (
      props.product.billing_period?.unit === "YEAR" &&
      props.product.billing_period?.quantity === 1
    ) {
      return `Your subscription will start today and will automatically renew annually until cancelled. You will be charged today and on each renewal date hereafter.
       Prices subject to change. You may cancel this subscription at any time by visiting Profile > Manage profile > Manage epocrates+ subscription on the 
       epocrates app or website. If you cancel your subscription within 14 days after an annual renewal date, we'll refund your subscription amount for that year.`;
    }
    if (
      props.product.billing_period?.unit === "YEAR" &&
      props.product.billing_period?.quantity === 2
    ) {
      return `Your subscription will start today and continues for two years. Your subscription will automatically renew for additional two-year terms until cancelled. 
       You will be charged today and on each renewal date hereafter. Prices subject to change. 
       You may cancel this subscription at any time by visiting Account Info > Manage Subscriptions on the epocrates app or website. 
       If you cancel your subscription within 14 days after any two-year renewal date, we'll refund your subscription amount for that year.`;
    }
    if (
      props.product.billing_period?.unit === "YEAR" &&
      props.product.billing_period?.quantity === 3
    ) {
      return `Your subscription will start today and continues for three years. Your subscription will automatically renew for additional three-year terms until cancelled. 
       You will be charged today and on each renewal date hereafter. Prices subject to change. 
       You may cancel this subscription at any time by visiting Account Info > Manage Subscriptions on the epocrates app or website. 
       If you cancel your subscription within 14 days after any two-year renewal date, we'll refund your subscription amount for that year.`;
    }
    if (
      props.product.billing_period?.unit === "YEAR" &&
      props.product.billing_period?.quantity === 1 &&
      props.product.trial_period
    ) {
      return `Your free 14-day trial starts today and will convert to an automatically renewing annual subscription unless cancelled prior to the end of the free trial.
       You will be charged on each renewal date hereafter. Prices subject to change. You may cancel this subscription at any time by visiting 
       Profile > Manage profile > Manage epocrates+ subscription on the epocrates app or website.`;
    }
    if (
      props.product.billing_period?.unit === "MONTH" &&
      props.product.billing_period?.quantity === 1
    ) {
      return `Your subscription will start today and will automatically renew monthly until cancelled. You will be charged today and on each renewal date hereafter.
       Prices subject to change. You may cancel this subscription at any time by visiting Profile > Manage profile > Manage epocrates+ subscription on the epocrates app or website`;
    }
  };
  const dispatchRedux = useDispatch();
  const handlePurchase = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (props.product.trial_period && !agreementChecked) {
      setAgreementNotChecked(true);
      dispatchRedux(
        sendSuccessSnackBarWithTitleMessage(
          "Checkbox required",
          strings.agreement_error_text,
          false,
          SnackBarType.ERROR.displayValue
        )
      );
      return;
    }
    if (
      props.calculateLicenseResponse &&
      !isRequestError(props.calculateLicenseResponse)
    ) {
      sendAmplitudeEvent(
        ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CONFIRM_PAYMENT_CLICK,
        {
          sku: props.product.platform_sku,
          "Amount Before Tax": props.calculateLicenseResponse.subtotal,
          Tax: props.calculateLicenseResponse.tax,
          "Promo discount": !props.isCalculatedResponseError
            ? props.promoCode ?? undefined
            : undefined,
          "Total Amount": props.calculateLicenseResponse.total,
          Currency: props.calculateLicenseResponse.currency,
        }
      );

      const purchasePayload: CommitRequestWrapper = {
        paymentDetails: props.calculateLicenseResponse,
        request: {
          userId: Number(localStorage.getItem(CURRENT_USER_ID) ?? -1),
          product: props.product.platform_sku,
          campaignCode: !props.isCalculatedResponseError
            ? props.promoCode ?? undefined
            : undefined,
          paymentId: props.paymentMethodDetails?.id ?? undefined,
          sourceType: sourceType,
        },
      };
      props.setConfirmButtonSpinner(true);
      await props.commitQuery.mutateAsync(purchasePayload);
    }
  };

  const paymentConfirmation = (
    <>
      {props.paymentMethodDetails?.id && (
        <div className={classes.popupPaymentDetailsResponsive}>
          <div className={classes.popupPaymentDetailsRow}>
            <p className={classes.popupPaymentDetailsRowLabel}>
              {strings.payment_method}
            </p>
            <div
              className={clsx(
                classes.popupPaymentDetailsRowValue,
                classes.popupPaymentDetailsCCNumber
              )}
            >
              <CardNumber cardNumber={props.paymentMethodDetails?.cardNumber} />{" "}
              {moment(props.paymentMethodDetails?.expirationDate).format(
                "MM/YY"
              )}
            </div>
            <div>
              <Link
                className={clsx(classes.supportLink)}
                href="#"
                rel="noreferrer"
                id="editCreditCard"
                data-testid="editCreditCard"
                tabIndex={0}
                onClick={() => props.editCard()}
              >
                Edit
              </Link>
            </div>
          </div>
          {props.paymentMethodDetails?.billingAddress1 ? (
            <div className={classes.popupPaymentDetailsRow}>
              <p className={classes.popupPaymentDetailsRowLabel}>
                {strings.billing_address}
              </p>
              <p className={classes.popupPaymentDetailsRowValue}>
                {props.paymentMethodDetails.billingAddress1}
                {props.paymentMethodDetails.billingAddress2
                  ? ` ${props.paymentMethodDetails.billingAddress2}`
                  : null}
                , {props.paymentMethodDetails.city},{" "}
                {props.paymentMethodDetails.state}{" "}
                {props.paymentMethodDetails.postalCode}
              </p>
              <div>
                <Link
                  className={clsx(classes.supportLink)}
                  href="#"
                  rel="noreferrer"
                  id="editCreditCard"
                  data-testid="editBillingAddress"
                  tabIndex={0}
                  onClick={() => props.editCard()}
                >
                  Edit
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );

  return (
    <>
      {props.confirmButtonSpinner || props.isCalculateRequestFetching ? (
        <div className={clsx(clx.billingWrapper)}>
          <LoadingEpocLogo />
        </div>
      ) : (
        <div className={clsx(classes.wrapperDiv)}>
          <Typography
            id="alert-dialog-subTitle1"
            classes={{
              root: clsx(clx.cardSubTitle, clx.primaryMidnight),
            }}
            data-testid="alert-dialog-subTitle1"
          >
            {strings.confirm_payment_billing_information}
          </Typography>
          {paymentConfirmation}
          {props.selectProduct && (
            <div className={clsx(classes.licenseSummary)}>
              <OrderSummaryWindow
                mainTableArray={generateMainOrderEntries(
                  props.selectProduct,
                  props.calculateLicenseResponse
                )}
                summaryTableArray={generateSummaryOrderEntries(
                  props.selectProduct,
                  props.calculateLicenseResponse
                )}
                displayDiscountBar={false}
                promoCode={props.promoCode}
                setPromoCode={props.setPromoCode}
              />
            </div>
          )}
          <Box
            className={clsx(classes.fontText)}
            sx={{
              border: `1px solid ${colors["--ui-grey-light"]}`,
              padding: "8px",
              marginTop: "32px",
              fontSize: "14px !important",
              borderRadius: "4px",
              width: { xs: "100%", lg: "712px" },
              maxWidth: "712px",
            }}
          >
            {getAgreementText()}
          </Box>
          {props.product.trial_period && (
            <div className={clsx(classes.flexContainer)}>
              <Checkbox
                name="checkAgreement"
                classes={{
                  colorSecondary: clsx(classes.checkBoxChecked),
                }}
                checked={agreementChecked}
                data-testid="checkAgreement"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAgreementChecked(event.target.checked);
                  if (event.target.checked) {
                    setAgreementNotChecked(false);
                  }
                }}
              />
              <Typography
                className={clsx(
                  classes.fontText,
                  classes.agreementText,
                  agreementNotChecked ? classes.errorText : ""
                )}
              >
                {strings.agreementCheckbox}
              </Typography>
            </div>
          )}
          <div className={clsx(classes.ButtonLink)}>
            <div className={clsx(classes.subFormButtonFields)}>
              <Button
                name={strings.confirm_payment}
                id="commitPurchase"
                type="button"
                width="264px"
                // disabled={!agreementChecked}
                backgroundColor={colors["--primary-tealnight"]}
                hoverForeground={colors["--primary-tealnight"]}
                onClick={handlePurchase}
                data-testid="commitPurchase"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
