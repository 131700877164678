import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { LoginPasswordPageBody } from "./custom/LoginPasswordPageBody";
import { colors } from "../config/colorConfig";
import { QRFooter } from "./custom/QRFooter";
import { AppAltBackground } from "./custom/AppAltBackground";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

export const ForgotPasswordSuccess = (props: any) => {
  const styles = makeStyles((theme) => ({
    title: {
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "32px",
    },
    errorCheckEmailMessage: {
      padding: "12px",
      borderBottom: `solid 1px ${colors["--ui-grey-med"]}`,
    },
    subTitle: {
      fontWeight: 600,
    },
    checkSpamMessage: {
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "32px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }));
  const classes = styles(props);
  const bodyTitle = "Forgot password";
  const inProgressContent = (
    <>
      <div style={{ alignSelf: "center" }}>
        <ClipLoader
          size="px"
          color={colors["--accent-violet"]}
          loading={props.inProgress}
        />
      </div>
    </>
  );
  const titleText = (
    <>
      <Typography
        data-testid="errorCheckEmailMessage"
        variant="body1"
        line-height={1.25}
        style={{ width: "100%" }}
        className={clsx(classes.title, classes.errorCheckEmailMessage)}
      >
        Please check{" "}
        <Typography
          data-testid="errorCheckEmail"
          component="span"
          line-height={1.25}
          className={clsx(classes.title, classes.subTitle)}
          style={{ width: "100%" }}
        >
          {props.email}
        </Typography>{" "}
        for instructions to reset your password.
      </Typography>
      <br />
      <Typography
        data-testid="errorCheckSpamMessage"
        variant="body2"
        className={clsx(classes.checkSpamMessage)}
      >
        Didn&apos;t get the email?
        <div>
          Check your spam folder or &nbsp;
          <NavLink
            data-testid="navlink"
            to="#"
            onClick={props.submitToAction}
            isActive={() => !props.forgotPasswordInProgress}
            style={{ fontWeight: 600 }}
          >
            resend
          </NavLink>
        </div>
      </Typography>
    </>
  );
  const pageBody = (
    <>
      <CssBaseline />
      <LoginPasswordPageBody
        data-testid="loginPasswordPageBody"
        title={bodyTitle}
        titleText={props.inProgress ? inProgressContent : titleText}
      />
      <QRFooter />
    </>
  );
  return <AppAltBackground pageBody={pageBody} />;
};
