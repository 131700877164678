import { Grid } from "@mui/material";
import { withRoot } from "src/withRoot";
import { Device } from "../models/Device";
import { DevicesCardWithRoot as DevicesCard } from "./DevicesCard";
import { strings } from "src/utils/Strings";
import _ from "lodash";
import { getCookie, setCookie } from "src/utils/cookies";
import { useHistory } from "react-router";
import { sendAmplitudeEvent } from "src/utils/AmplitudeUtil";
import {
  DEVICE_LANDING_PAGE_VIEW,
  PROFILE_SIGN_OUT_CONFIRMED,
  UNIVERSAL_LINKS_CLICK_THROUGH,
} from "src/utils/analytics";
import { useEffect } from "react";

interface Props {
  mobileDevicesList: Device[];
  webDevicesList: Device[];
  refetch: () => void;
}

export const DeviceStatus = {
  active: "active",
  inactive: "inactive",
  pendingManualSignout: "pending_ManualLogout",
};

const DevicesList = (props: Props) => {
  const history = useHistory();
  const { mobileDevicesList, webDevicesList } = props;
  const signedInMobileDevices = mobileDevicesList.reduce((count, device) => {
    return device.deviceStatus === DeviceStatus.active ? count + 1 : count;
  }, 0);
  const signedOutMobileDevices = mobileDevicesList.reduce((count, device) => {
    return device.deviceStatus !== DeviceStatus.active ? count + 1 : count;
  }, 0);
  const signedInWebDevices = webDevicesList.reduce((count, device) => {
    return device.deviceStatus === DeviceStatus.active ? count + 1 : count;
  }, 0);

  const onProfileSignOut = (deviceName: string) => {
    const properties = {
      "Epoc Device ID": getCookie("ebi"),
      Browser: deviceName,
      "Reason for Signout": "Device Manual Signout",
      Location: "Devices",
    };
    sendAmplitudeEvent(PROFILE_SIGN_OUT_CONFIRMED, properties, null);
  };

  useEffect(() => {
    const contextualLinkCookie = getCookie("contextuallink");
    if (webDevicesList.length > 0 || mobileDevicesList.length > 0) {
      const totalActiveDevices = signedInMobileDevices + signedInWebDevices;
      const totalInactiveDevices =
        mobileDevicesList.length + webDevicesList.length - totalActiveDevices;
      const inactiveWebDevices = totalInactiveDevices - signedOutMobileDevices;
      const properties = {
        "Active Device Count": totalActiveDevices,
        "Inactive Device Count": totalInactiveDevices,
        "Active App Device Count": signedInMobileDevices,
        "Inactive App Device Count": signedOutMobileDevices,
        "Active Web Device Count": signedInWebDevices,
        "Inactive Web Device Count": inactiveWebDevices,
      };
      sendAmplitudeEvent(DEVICE_LANDING_PAGE_VIEW, properties, null);
    } else {
      const properties = {
        "Active Device Count": 0,
        "Inactive Device Count": 0,
        "Active App Device Count": 0,
        "Inactive App Device Count": 0,
        "Active Web Device Count": 0,
        "Inactive Web Device Count": 0,
      };
      sendAmplitudeEvent(DEVICE_LANDING_PAGE_VIEW, properties, null);
    }
    if (contextualLinkCookie === "true") {
      setCookie("contextuallink", -1, "");
      const properties = {
        "Campaign Name": "Log-in security email",
        Destination: "device settings",
        Referral: "Device Management",
        Source: "email",
      };
      sendAmplitudeEvent(UNIVERSAL_LINKS_CLICK_THROUGH, properties, null);
    }
  }, []);

  const signOutDevice = (deviceId: string, deviceName: string) => {
    const currentDeviceId = getCookie("ebi");
    if (currentDeviceId === deviceId) {
      onProfileSignOut(deviceName);
      history.push("/logout?location=Devices");
    }
    props.refetch();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <DevicesCard
          title="Apps"
          listItems={mobileDevicesList}
          maxDevices={strings.max_device_threshold}
          signedInDevices={signedInMobileDevices}
          signOutDevice={signOutDevice}
        />
      </Grid>
      {webDevicesList.length > 0 && (
        <Grid item xs={12} sm={6}>
          <DevicesCard
            title="Web"
            listItems={webDevicesList}
            maxDevices={strings.max_device_threshold}
            signedInDevices={signedInWebDevices}
            signOutDevice={signOutDevice}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const DevicesWithRoot = withRoot(DevicesList);
