import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../../config/colorConfig";

interface IButtonLinkProps {
  onClick: () => void;
  testId: string;
  name: string;
  displayText: string;
  className?: string;
}
export const ButtonLink = (props: IButtonLinkProps): JSX.Element => {
  const buttonLinkStyle = makeStyles((theme) => ({
    cancelLink: {
      color: colors["--title-black"],
      "&:hover": {
        backgroundColor: colors["--text-invert"],
      },
    },
    cancel: {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: 500,
      color: colors["--primary-tealnight"],
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "underline",
      },
      padding: 0,
      minWidth: "auto",
      marginLeft: theme.spacing(6),
    },
  }));
  const classes = buttonLinkStyle();
  return (
    <Button
      disableRipple
      className={clsx(classes.cancelLink, classes.cancel, props.className)}
      onClick={props.onClick}
      data-testid={props.testId}
      name={props.name}
    >
      {props.displayText}
    </Button>
  );
};
