import axios, { AxiosPromise, AxiosResponse } from "axios";
import { config } from "../config/envConfig";
import { refreshAccess } from "../utils/cookies";
import { AccountUpdateRequest } from "../models/AccountUpdateRequest";
import { RequestError } from "src/models/RequestError";

export interface UpdateReq {
  updateRequest: AccountUpdateRequest;
}
export interface ProfileEmailResponse {
  status: number;
  data?: {
    valid: boolean;
  };
  error?: RequestError;
}
const { profileUrl } = config.environment;

export const accountProfileService = async ({
  isDeviceEnabled,
}: {
  isDeviceEnabled?: boolean;
}): Promise<AxiosPromise> => {
  const instance = axios.create({
    baseURL: profileUrl,
    timeout: 30000,
  });

  const cookie = await refreshAccess(isDeviceEnabled);
  const userId = localStorage.getItem("currentUserId");
  return instance
    .get(`/v1/user/${userId}/simple?_t=${+new Date()}`, {
      headers: {
        Authorization: `${cookie}`,
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      console.log(error);
      return error.response || { status: 500, data: {} };
    });
};

export const updateAccountProfileService = async (
  request: UpdateReq
): Promise<AxiosResponse> => {
  const instance = axios.create({
    baseURL: profileUrl,
    timeout: 30000,
  });
  const cookie = await refreshAccess();
  const userId = localStorage.getItem("currentUserId");
  return instance
    .patch(
      `/v1/user/${userId}`,
      request.updateRequest as AccountUpdateRequest,
      {
        headers: {
          Authorization: `${cookie}`,
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      console.log(error);
      return error.response || { status: 500, data: {} };
    });
};

type Allow = {
  valid: boolean;
};

export async function getAllowAnalytics(userId?: string): Promise<boolean> {
  let url = `${profileUrl}/v1/validate/allowAnalytics`;
  if (userId && userId != "-1" && !isNaN(Number(userId))) {
    url += "?userId=" + userId;
  }
  const response = await axios.get<Allow>(url);
  if (response.status === 200) {
    return response.data.valid;
  } else {
    console.log(response.data);
    return false;
  }
}
export const verifyEmailAddress = async (
  email: string
): Promise<ProfileEmailResponse> => {
  const instance = axios.create({
    baseURL: profileUrl,
    timeout: 30000,
    validateStatus(status) {
      return status >= 200 && status < 500;
    },
  });
  try {
    const response = await instance.get(`/v1/validate/email?email=${email}`, {
      headers: {
        accept: "application/json",
      },
    });
    return response;
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};
