import { CssBaseline } from "@material-ui/core";
import { AppAltBackground } from "src/components/custom/AppAltBackground";
import { QRFooter } from "src/components/custom/QRFooter";
import { RouteComponentProps } from "react-router-dom";
import { QRLogin } from "./QRLogin";

export const EmailLoginWrap = (props: RouteComponentProps) => {
  const pageBody = (
    <>
      <CssBaseline />
      <QRLogin {...props} />
      <QRFooter />
    </>
  );
  return <AppAltBackground pageBody={pageBody} />;
};
