import * as React from "react";
import clsx from "clsx";
import {
  List,
  ListItem,
  ListItemText,
  Link,
  makeStyles,
} from "@material-ui/core";
import { colors } from "../config/colorConfig";
import { strings } from "../utils/Strings";
import {
  CREATE_ACCOUNT_NPI_RESULT_VIEW,
  CREATE_ACCOUNT_NPI_RESULT_VIEW_EVENT_ID,
} from "../utils/analytics";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import { NpiTuple } from "../models/NpiLookupResponse";

export const ShowProfile = (prop: any) => {
  const claimProfileStyle = makeStyles((theme) => ({
    info: {
      fontSize: 16,
      lineHeight: 1.25,
      marginBottom: 24,
      color: colors["--ui-slate"],
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
      borderTop: `solid 1px${colors["--ui-grey-light"]}`,
    },
    listItem: {
      padding: "5px 0px",
      alignItems: "center",
      borderBottom: `solid 1px${colors["--ui-grey-light"]}`,
      "&:hover": {
        backgroundColor: colors["--states-backgrounds-hover"],
      },
      cursor: "pointer",
    },
    primary: {
      fontSize: 16,
      lineHeight: 1.25,
      color: colors["--ui-slate"],
    },
    secondary: {
      fontSize: 11,
      lineHeight: 1.09,
      color: colors["--ui-gray"],
      marginTop: theme.spacing(1),
    },
    link: {
      fontSize: 14,
      lineHeight: 1.43,
      color: colors["--primary-teal"],
      marginTop: theme.spacing(8),
    },
  }));
  const classes = claimProfileStyle();
  const list = prop.profiles;
  React.useEffect(() => {
    sendAmplitudeEvent(CREATE_ACCOUNT_NPI_RESULT_VIEW, {
      "Event ID": CREATE_ACCOUNT_NPI_RESULT_VIEW_EVENT_ID,
    });
  }, []);

  return (
    <>
      {list.length > 0 && (
        <List classes={{ root: clsx(classes.list) }}>
          {list.map((detail: NpiTuple, index: number) => (
            <ListItem
              onClick={() => prop.profileClickHandler(detail, index)}
              key={detail.number}
              classes={{ root: clsx(classes.listItem) }}
            >
              <ListItemText
                classes={{
                  primary: clsx(classes.primary),
                  secondary: clsx(classes.secondary),
                }}
                primary={`${detail.first_name} ${detail.last_name}`}
                secondary={`${detail.specialty}, NPI: ${detail.number}`}
              />
              <img
                data-testid="rightArrow"
                /* eslint-disable @typescript-eslint/no-var-requires */
                src={`${
                  require("../images/icons-streamline-regular-24-pt-keyboard-arrow-right.svg")
                    .default
                }`}
                /* eslint-enable @typescript-eslint/no-var-requires */
                alt=""
              />
            </ListItem>
          ))}
        </List>
      )}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        onClick={prop.linkClickHandler}
        classes={{ root: clsx(classes.link) }}
        component="button"
        underline="none"
      >
        {strings.claim_dont_see}
      </Link>
    </>
  );
};
