import axios, { AxiosError, AxiosResponse } from "axios";
import { getWebUserAgent } from "@athena/epoc-frontend-library/dist";
import { AccountCreationRequest } from "../models/AccountCreationRequest";
import { config } from "../config/envConfig";
import { httpConstants } from "../utils/httpConstants";
import { AccountCreationResponse } from "../models/AccountCreationResponse";
import { EmailValidationRequest } from "../models/EmailValidationRequest";
import { ZipCodeValidationRequest } from "../models/ZipCodeValidationRequest";
import {
  ValidationServiceResponse,
  ValidationError,
} from "../models/AccountValidationResponse";
import { store } from "../store/configureStore";
import { getCookie } from "../utils/cookies";

const { profileUrl } = config.environment;

export type CreateUserResponseType = AxiosResponse<AccountCreationResponse>;
export const createUserService = async (
  accountRequest: AccountCreationRequest
): Promise<CreateUserResponseType> => {
  const state = store.getState();
  const isDeviceManagementEnabled =
    state.accountProfile.device_management_enabled;
  const requestHeaders = isDeviceManagementEnabled
    ? {
        "Epoc-Platform": "Web",
        "Device-Id": getCookie("ebi"),
        "Device-Family": getWebUserAgent(),
      }
    : {};
  const instance = axios.create({
    baseURL: profileUrl,
    timeout: 30000,
    headers: requestHeaders,
  });
  const url = isDeviceManagementEnabled ? "/v2/user" : "/v1/user";

  return instance
    .post(url, accountRequest)
    .then((response: CreateUserResponseType) => response)
    .catch(
      (error: AxiosError) =>
        error.response ?? ({ status: 500, data: {} } as AxiosResponse)
    );
};

export type ValidationResponseType = AxiosResponse<ValidationServiceResponse>;
export const emailValidationService = async (
  request: EmailValidationRequest
): Promise<ValidationServiceResponse> => {
  const instance = axios.create({
    baseURL: profileUrl,
    timeout: 30000,
  });
  const { email } = request;
  const url = `/v2/validate/email?email=${email}`;
  return instance
    .get(url)
    .then((response) => ({
      status: response.status,
      data: response.data,
    }))
    .catch((error: AxiosError<ValidationError>) => {
      const status = error.response?.status;
      if (status === httpConstants.INVALID_RANGE) {
        return {
          status: status,
          data: error.response?.data,
        };
      } else {
        return {
          status: httpConstants.INTERNAL_SERVER_ERROR,
          data: {
            error: { code: "internalServerError", message: "System error" },
          },
        };
      }
    });
};

export const zipCodeValidationService = async (
  request: ZipCodeValidationRequest
): Promise<ValidationServiceResponse> => {
  const instance = axios.create({
    baseURL: profileUrl,
    timeout: 30000,
  });
  const { countryId, zipCode } = request;
  const url = `/v2/validate/postalCode?countryId=${countryId}&postalCode=${zipCode}`;
  return instance
    .get(url)
    .then((response) => ({
      status: response.status,
      data: response.data,
    }))
    .catch((error: AxiosError<ValidationError>) => {
      const status = error.response?.status;
      if (status === httpConstants.INVALID_RANGE) {
        return {
          status: status,
          data: error.response?.data,
        };
      } else {
        return {
          status: httpConstants.INTERNAL_SERVER_ERROR,
          data: {
            error: { code: "internalServerError", message: "System error" },
          },
        };
      }
    });
};
