import axios from "axios";
import { config } from "../config/envConfig";

export const DEFAULT_TIMEOUT = 30000;
const axiosConfig = {
  baseURL: config.environment.authenticationUrl,
  timeout: 30000,
};
const updateRefreshToken = (response: any) => {
  const refreshToken = response.config.headers.Authorization;
  if (refreshToken) {
    try {
      localStorage.setItem("jwtToken", refreshToken.split(" ")[1]);
    } catch (error) {
      // do nothing
      console.log(error);
    }
  }
};

export const axiosServiceGet = (path: string) => {
  const instance = axios.create(axiosConfig);
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "jwtToken"
  )}`;
  return instance
    .get(path)
    .then((response: any) => {
      updateRefreshToken(response);
      return response;
    })
    .catch((error: any) => {
      console.log(error);
      return error.response;
    });
};

export const axiosServicePost = (path: string, requestItem: any) => {
  const instance = axios.create(axiosConfig);
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "jwtToken"
  )}`;
  return instance
    .post(path, requestItem)
    .then((response: any) => {
      updateRefreshToken(response);
      return response;
    })
    .catch((error: any) => {
      console.log(error);
      return error.response;
    });
};

export const axiosServicePut = (path: string, requestItem: any) => {
  const instance = axios.create(axiosConfig);
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "jwtToken"
  )}`;
  return instance
    .put(path, requestItem)
    .then((response: any) => {
      updateRefreshToken(response);
      return response;
    })
    .catch((error: any) => {
      console.log(error);
      return error.response;
    });
};

export const axiosServiceDelete = (path: string) => {
  const instance = axios.create(axiosConfig);
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "jwtToken"
  )}`;
  return instance
    .delete(path)
    .then((response: any) => {
      updateRefreshToken(response);
      return response;
    })
    .catch((error: any) => {
      console.log(error);
      return error.response;
    });
};
