import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { QRLoginFormWith as QRLoginForm } from "src/components/QRLoginForm";
import { RouteComponentProps } from "react-router-dom";
import { ReactElement } from "react";
import { QROTCLoginPageBody } from "src/components/custom/QROTCLoginPageBody";

export const QRLogin = (props: RouteComponentProps): ReactElement => {
  const bodyWrapper = makeStyles((theme) => ({
    loginFormWrapper: {
      padding: "0 40px",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: "0 16px",
      },
    },
  }));
  const classes = bodyWrapper();
  return (
    <QROTCLoginPageBody isSmallWidth={true}>
      <div className={clsx(classes.loginFormWrapper)}>
        <QRLoginForm {...props} />
      </div>
    </QROTCLoginPageBody>
  );
};
