import { Button as MaterialButton, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../../config/colorConfig";
import { Spinner } from "./Spinner";

export const Button = (props: any) => {
  const { width, height } = props;
  const buttonStyle = makeStyles((theme: Theme) => ({
    wrapper: {
      position: "relative",
      width: props.width,
    },
    button: {
      background: props.backgroundColor ?? colors["--primary-midnight"],
      color: props.foregroundColor ?? colors["--text-invert"],
      height: height ?? "40px",
      width: width ?? "100%",
      maxWidth: props.maxWidth ?? "none",
      fontFamily: props.fontFamily ?? "auto",
      borderRadius: 3,
      border: props.border ?? "0",
      textTransform: "none",
      fontWeight: 600,
      fontSize: props.fontSize ?? 16,
      "&:hover": {
        backgroundColor: props.hoverForeground ?? colors["--ui-slate"],
        border: props.border ?? "0",
      },
      "&:disabled": {
        opacity: 0.3,
        backgroundColor: colors["--ui-grey-semi"],
        color: colors["--text-invert"],
      },
      margin: props.margins || undefined,
    },
    // some of this is duplicated from above, but on i'm not sure
    // the best way to share styling and overriding the differences
    buttonInProgress: {
      height: props.height ? props.height : "40px",
      width: props.width ? props.width : "100%",
      borderRadius: 3,
      textTransform: "none",
      fontWeight: 600,
      fontSize: 16,
      background: `${
        props.backgroundColor ?? colors["--primary-midnight"]
      } !important`,
      "&:disabled": {
        background: colors["--ui-slate"],
        color: colors["--text-invert"],
      },
    },
    progress: {
      width: 20,
      height: 20,
      color: colors["--accent-violet"],
      position: "absolute",
      top: "25%",
      left: "50%",
      marginTop: -2,
      marginLeft: -12,
    },
  }));

  const classes = buttonStyle();
  const loading = props.inProgress ? props.inProgress : false;
  const defaultButton = (
    <div style={props.padding ? props.padding : { paddingTop: "0px" }}>
      <MaterialButton
        variant={props.variant ?? "contained"}
        type={props.type}
        color="primary"
        id={props.id}
        name={props.name}
        data-testid={props.id}
        disabled={props.disabled}
        className={clsx(classes.button)}
        onClick={props.onClick}
        href={props.href}
      >
        {props.name}
      </MaterialButton>
    </div>
  );
  const progressButton = (
    <div
      className={clsx(classes.wrapper)}
      style={props.padding ? props.padding : { paddingTop: "0px" }}
    >
      <MaterialButton
        variant="contained"
        type={props.type}
        color="primary"
        id={props.id}
        name={props.name}
        data-testid={props.id}
        disabled
        className={clsx(classes.buttonInProgress)}
      />
      <Spinner
        size={24}
        thickness={1.5}
        id="buttonProgress"
        dataTestId="buttonProgress"
        width={20}
        height={20}
        top="25%"
        left="50%"
        marginTop={-2}
        marginLeft={-12}
      />
    </div>
  );

  return <>{loading ? progressButton : defaultButton}</>;
};
