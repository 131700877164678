import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { colors } from "../config/colorConfig";

interface OtcInputBoxProps {
  value: string;
  valueLength: 6;
  onChange: (value: string) => void;
}

const useStyles = makeStyles((theme) => ({
  otcInputContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "50px",
    border: `solid 1.5px ${colors["--ui-gray"]}`,
    borderRadius: "3px",
  },
  otcInputFieldWrap: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  otcDigit: {
    width: "18px",
    height: "15px",
    textAlign: "center",
    fontSize: "1.2rem",
    border: "none",
    outline: "none",
    borderBottom: `solid 1px ${colors["--ui-grey-semi"]}`,
    margin: "0 5px",
    cursor: "pointer",
  },
  hideDash: {
    borderBottom: "none",
    height: "30px",
  },
}));

export const OtcInputBox: React.FC<OtcInputBoxProps> = ({
  value,
  onChange,
  valueLength,
}) => {
  const classes = useStyles();
  const RE_DIGIT = new RegExp(/^\d+$/);

  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items: Array<string> = [];
    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  const handleClick = () => {
    if (!value || !value.replace(/\s/g, "").length) {
      document.getElementById("digit-1")?.focus();
    }
  };
  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToInput = (target: HTMLElement) => {
    if (!value) {
      target.focus();
    }
  };
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };
  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const target = e.target;
    focusToInput(target);
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);
    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }
    const nextInputEl = target.nextElementSibling as HTMLInputElement | null;
    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") {
      return;
    }
    targetValue = isTargetValueDigit ? targetValue : " ";
    const targetValueLength = targetValue.length;
    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, index) + targetValue + value.substring(index + 1);
      onChange(newValue);
      if (!isTargetValueDigit) {
        return;
      }
      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);
      target.blur();
    }
  };
  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;
    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }
    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }
    const targetValue = target.value;
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== "Backspace" || targetValue !== "") {
      return;
    }
    focusToPrevInput(target);
  };
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className={classes.otcInputContainer} onClick={handleClick}>
      <div className={classes.otcInputFieldWrap}>
        {valueItems.map((digit, index) => (
          <input
            key={index}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{1}"
            name={"digit-" + index}
            id={"digit-" + index}
            className={classes.otcDigit + (digit ? ` ${classes.hideDash}` : "")}
            maxLength={valueLength}
            value={digit}
            onChange={(e) => inputOnChange(e, index)}
            onKeyDown={inputOnKeyDown}
            onFocus={inputOnFocus}
            autoFocus
          />
        ))}
      </div>
    </div>
  );
};
