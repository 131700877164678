import {
  CME_LAUNCH_GET_TOKEN,
  CME_LAUNCH_GET_TOKEN_ERROR,
  CME_LAUNCH_GET_TOKEN_INPROGRESS,
  CME_LAUNCH_GET_TOKEN_PROFILE_REQUIRES_REVIEW,
  CME_LAUNCH_GET_TOKEN_SUCCESS,
  CME_LAUNCH_GET_TOKEN_SYSTEM_ERROR,
  CME_LAUNCH_REDIRECTING_TO_PROFILE_FORM,
  REQUIRE_USER_MANUAL_LOGIN,
} from "../actions";

export function cmeLaunch(state = [], action: any): any {
  const { rieventSSOTokenResponse, type } = action;
  switch (type) {
    case CME_LAUNCH_GET_TOKEN:
      return {
        ...state,
        rieventSSOTokenResponse: null,
        cmeLaunchProgress: true,
        cmeLaunchRequiresProfileReview: false,
      };
    case CME_LAUNCH_GET_TOKEN_INPROGRESS:
      return { ...state, cmeLaunchProgress: true };
    case REQUIRE_USER_MANUAL_LOGIN:
      return {
        ...state,
        cmeLaunchProgress: false,
        cmeLaunchRequiresProfileReview: false,
        rieventSSOTokenResponse: null,
        cmeLaunchRedirectToken: null,
      };
    case CME_LAUNCH_GET_TOKEN_PROFILE_REQUIRES_REVIEW:
      return {
        ...state,
        rieventSSOTokenResponse,
        cmeLaunchProgress: false,
        cmeLaunchRequiresProfileReview: true,
      };
    case CME_LAUNCH_REDIRECTING_TO_PROFILE_FORM:
      return {
        ...state,
        cmeLaunchProgress: false,
        cmeLaunchRequiresProfileReview: false,
        rieventSSOTokenResponse: null,
        cmeLaunchError: false,
        cmeLaunchRedirectToken: null,
      };
    case CME_LAUNCH_GET_TOKEN_SUCCESS:
      return {
        ...state,
        rieventSSOTokenResponse,
        cmeLaunchProgress: false,
        cmeLaunchRequiresProfileReview: false,
        cmeLaunchRedirectToken: rieventSSOTokenResponse.token,
      };
    case CME_LAUNCH_GET_TOKEN_ERROR:
      return {
        ...state,
        rieventSSOTokenResponse,
        cmeLaunchProgress: false,
        cmeLaunchRequiresProfileReview: false,
        cmeLaunchError: true,
      };
    case CME_LAUNCH_GET_TOKEN_SYSTEM_ERROR:
      return {
        ...state,
        rieventSSOTokenResponse,
        cmeLaunchProgress: false,
        cmeLaunchRequiresProfileReview: false,
      };
    default:
      return state;
  }
}
