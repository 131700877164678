import { Route, Redirect, withRouter } from "react-router-dom";
import { getCookie, refreshAccess } from "../utils/cookies";
import { useEffect, useState } from "react";
import { OverlaySpinner } from "src/components/custom/OverlaySpinner";
import { setLogOutUserFunction } from "src/utils/loginUtil";

const AccountRouter = (props: any) => {
  const { component: Component, ...rest } = props;
  const [__, setToken] = useState<string>("");
  const cookie = getCookie("accessToken");
  const refreshToken = getCookie("refreshToken");
  const currentUrl = window.location.href;
  const encodedUrl = encodeURIComponent(currentUrl);
  const logOutUserFunction = (queryParams: Record<string, string>) => {
    const keys = Object.keys(queryParams);
    if (keys.length > 0) {
      const paramList = [];
      for (const key of keys) {
        paramList.push(`${key}=${queryParams[key]}`);
      }
      const paramString = paramList.join("&");
      props.history.push(`/login?${paramString}`);
    } else {
      props.history.push("/login");
    }
  };

  setLogOutUserFunction(logOutUserFunction);

  // renew access token when it is deleted
  useEffect(() => {
    if (!cookie && refreshToken) {
      refreshAccess(props.deviceManagementDecision.enabled)
        .then((response: string) => {
          setToken(response); // re-render component
          return;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [cookie, refreshToken]);

  if (!cookie && refreshToken) {
    return <OverlaySpinner />;
  }

  if (cookie && refreshToken) {
    return <Route {...rest} render={() => <Component {...props} />} />;
  }

  return (
    <Redirect
      to={{
        pathname: "/login",
        search: `?refernext=${encodedUrl}`,
        state: { from: props.location },
      }}
    />
  );
};

export const AccountRoute = withRouter(AccountRouter);
