import React, { useEffect } from "react";
import {
  makeStyles,
  DialogContentText,
  Link,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../config/colorConfig";
import { strings } from "../utils/Strings";
import { Button } from "./custom/Button";
import { config } from "../config/envConfig";
import { CancelRequest } from "../models/CancelRequest";
import { SubscriptionDialog } from "./SubscriptionDialog";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import {
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FINAL_CONFIRMATION,
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FINAL_CONFIRMATION_NEVER_MIND,
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FINAL_CONFIRMATION_SUCCESS,
} from "../utils/analytics";
import diseasesImage from "../images/Diseases.svg";
import herbsImage from "../images/herbs.svg";
import idtxImage from "../images/idTx_logo.svg";
import labsImage from "../images/Labs.svg";
import { SubscriptionCancellationThanks } from "./SubscriptionCancellationThanks";
import { CURRENT_USER_ID } from "src/utils/localStorageKeys";
import { RetriveUserSubscription } from "src/queries/GetUserSubscription";
import { useMutation } from "@tanstack/react-query";
import { cancelSubscriptionService } from "src/services/subscriptionService";
import { LoadingEpocLogo } from "./custom/LoadingEpocLogo";

interface SubscriptionCancellationConfirmationProps {
  subscriptionId: string;
  open: boolean;
  cancelReasons: string;
  sku: string;
  setCancelConfirmation: (cancelConfirmation: boolean) => void;
  subscriptionEndDate: string;
  feedBack: string;
}

export const SubscriptionCancellationConfirmation = (
  props: SubscriptionCancellationConfirmationProps
): JSX.Element => {
  const dialogBoxStyles = makeStyles((theme) => ({
    contentAlignment: {
      margin: `${theme.spacing(3)}px ${theme.spacing(10)}px 0px ${theme.spacing(
        10
      )}px`,
    },
    popUpText: {
      fontSize: "24px",
      lineHeight: "28px",
    },
    subDate: {
      fontWeight: 500,
      color: colors["--ui-midnight"],
    },
    popUpSubText: {
      fontSize: "16px",
      color: colors["--ui-slate"],
    },

    cancelLink: {
      color: `${colors["--primary-teal"]}`,
      fontWeight: 500,
      fontSize: "16px",
      whiteSpace: "nowrap",
      "&:hover": {
        textDecoration: "none",
        color: `${colors["--primary-teal"]}`,
      },
    },
    cancelSubtext: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      color: colors["--primary-midnight"],
    },

    noCancel: {
      marginTop: `${theme.spacing(4)}px`,
      backgroundColor: `${colors["--text-invert"]}`,
      color: `${colors["--primary-midnight"]}`,
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 500,
      border: `solid 1px ${colors["--ui-slate"]}`,
      "&:disabled": {
        backgroundColor: `${colors["--ui-grey-semi"]}`,
        color: `${colors["--ui-grey-med"]}`,
        border: "none",
      },
    },
    switchColor: {
      "&.Mui-checked": {
        color: `${colors["--primary-teal"]}`,
      },
    },
    formControl: {
      height: 28,
    },
    ButtonRow: {
      display: "flex",
      alignItems: "left",
      marginTop: `${theme.spacing(4)}px`,
    },
    subFormButtonFields: {
      flexGrow: 0,
      minWidth: 180,
      marginRight: `${theme.spacing(2)}px`,
    },
    contactLink: {
      marginTop: `${theme.spacing(4)}px`,
      marginBottom: `${theme.spacing(4)}px`,
    },
    imagesInfo: {
      display: "flex",
      alignItems: "left",
      marginBottom: `${theme.spacing(2)}px`,
    },
    textInfo: {
      marginTop: `${theme.spacing(4)}px`,
      marginLeft: `${theme.spacing(3)}px`,
    },
    tilesTitle: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      color: colors["--primary-midnight"],
    },
    rectangle: {
      display: "flex",
      width: 56,
      height: 56,
      left: 79,
      top: 133,
      background: "#EFF7FE",
      borderRadius: 4,
    },
    inProgressCancel: {
      width: 500,
      height: 400,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingLogo: {
      width: 100,
      height: 100,
      marginBottom: "72px",
    },
  }));

  const userId = localStorage.getItem(CURRENT_USER_ID);

  const [thanksDialog, setThanksDialog] = React.useState(false);
  const userSubscriptionQuery = RetriveUserSubscription(
    Number(localStorage.getItem(CURRENT_USER_ID) ?? -1)
  );

  const cancelSubQuery = useMutation(cancelSubscriptionService, {
    onSuccess: () => {
      let reason = props.cancelReasons;
      if (reason) {
        // Removing semi colon from first and last characters
        if (reason.indexOf(";") === 0) {
          reason = reason.substring(1, reason.length);
        }
        if (reason.lastIndexOf(";") === reason.length - 1) {
          reason = reason.substring(0, reason.length - 1);
        }
      }
      sendAmplitudeEvent(
        ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FINAL_CONFIRMATION_SUCCESS,
        {
          Reason: reason,
          "Feedback Text": props.feedBack,
          sku: props.sku,
          userId,
        }
      );

      props.setCancelConfirmation(false);
      userSubscriptionQuery.refetch();
      setThanksDialog(true);
    },
    onError: (error) => {
      props.setCancelConfirmation(false);
    },
  });

  const handleClose = () => {
    props.setCancelConfirmation(false);
  };
  const handleNevermindEvent = () => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FINAL_CONFIRMATION_NEVER_MIND,
      {
        sku: props.sku,
        userId,
      }
    );
    props.setCancelConfirmation(false);
  };

  const handleConfirmation = (event: React.MouseEvent<HTMLButtonElement>) => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FINAL_CONFIRMATION,
      {
        sku: props.sku,
        subscriptionId: props.subscriptionId,
        userId,
      }
    );
    event.preventDefault();

    const request: CancelRequest = {
      userId: Number(userId),
      subscriptionId: props.subscriptionId,
    };
    cancelSubQuery.mutateAsync(request);
  };

  const classes = dialogBoxStyles();
  const { supportUrl } = config.environment;
  const subscriptionCancelConfirmation = (
    <SubscriptionDialog
      open={props.open}
      setClose={handleClose}
      handleCloseEvent={handleClose}
      width={600}
      isTitleLarge
      title={
        !cancelSubQuery.isLoading
          ? strings.confirm_cancellation
          : strings.process_cancellation
      }
    >
      {!cancelSubQuery.isLoading ? (
        <div className={clsx(classes.contentAlignment)}>
          <DialogContentText
            classes={{ root: clsx(classes.popUpText) }}
            id="alert-dialog-description"
            data-testid="alert-dialog-description"
          >
            <span className={clsx(classes.subDate)}>
              {" "}
              On {props.subscriptionEndDate}{" "}
            </span>
            you'll lose access to:
          </DialogContentText>
          <DialogContentText
            classes={{ root: clsx(classes.popUpSubText) }}
            id="alert-dialog-description"
          >
            <div className={clsx(classes.imagesInfo)}>
              <div className={clsx(classes.rectangle)}>
                <img src={diseasesImage} alt="" />
              </div>
              <div className={clsx(classes.textInfo)}>
                <Typography className={clsx(classes.tilesTitle)}>
                  Diseases
                </Typography>
              </div>
            </div>

            <div className={clsx(classes.imagesInfo)}>
              <div className={clsx(classes.rectangle)}>
                <img src={labsImage} alt="" />
              </div>
              <div className={clsx(classes.textInfo)}>
                <Typography className={clsx(classes.tilesTitle)}>
                  Labs
                </Typography>
              </div>
            </div>

            <div className={clsx(classes.imagesInfo)}>
              <div className={clsx(classes.rectangle)}>
                <img src={herbsImage} alt="" />
              </div>
              <div className={clsx(classes.textInfo)}>
                <Typography className={clsx(classes.tilesTitle)}>
                  Herbs & Supplements
                </Typography>
              </div>
            </div>

            <div className={clsx(classes.imagesInfo)}>
              <div className={clsx(classes.rectangle)}>
                <img src={idtxImage} alt="" />
              </div>
              <div className={clsx(classes.textInfo)}>
                <Typography className={clsx(classes.tilesTitle)}>
                  Infectious Disease Treatment
                </Typography>
              </div>
            </div>
          </DialogContentText>

          <div className={clsx(classes.ButtonRow)}>
            <div className={clsx(classes.subFormButtonFields)}>
              <Button
                name="Confirm Cancellation"
                id="confirmCancellation"
                type="button"
                data-testid="confirmCancellation"
                onClick={handleConfirmation}
              />
            </div>
            <div className={clsx(classes.subFormButtonFields)}>
              <Button
                name="Never Mind"
                id="nevermind"
                type="button"
                onClick={handleNevermindEvent}
                data-testid="nevermind"
                backgroundColor={`${colors["--text-invert"]}`}
                foregroundColor={`${colors["--ui-slate"]}`}
                hoverForeground={`${colors["--text-invert"]}`}
              />
            </div>
          </div>
          <div className={clsx(classes.contactLink)}>
            <span>{strings.cancel_subText}</span>
            <Link
              classes={{
                root: clsx(classes.cancelLink),
              }}
              href={supportUrl}
              data-testid="cancelSupportLink"
            >
              {strings.contact_support}
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <div className={clsx(classes.inProgressCancel)}>
              <LoadingEpocLogo />
            </div>
          </div>
        </div>
      )}
    </SubscriptionDialog>
  );
  return (
    <>
      {subscriptionCancelConfirmation}

      <SubscriptionCancellationThanks
        open={thanksDialog}
        setThanksDialog={setThanksDialog}
      />
    </>
  );
};
