import clsx from "clsx";
import { makeStyles, Paper, Box } from "@material-ui/core";
import { ReactElement, ReactNode } from "react";
import { ApplicationAltTitle } from "./ApplicationAltTitle";
import { LoadingEpocLogo } from "./LoadingEpocLogo";

interface QRSignInFormWrapProps {
  children: ReactNode;
  shouldShowLoadingLogo?: boolean;
  isSmallWidth?: boolean;
}

export const QROTCLoginPageBody = (
  props: QRSignInFormWrapProps
): ReactElement => {
  const bodyWrapper = makeStyles((theme) => ({
    box: {
      marginTop: "64px",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px ${theme.spacing(
        6
      )}px`,
      [theme.breakpoints.down("md")]: {
        padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(
          6
        )}px`,
      },
      flexGrow: 1,
    },
    paper: {
      padding: "40px 0",
      marginTop: "64px",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px 0",
      },
      [theme.breakpoints.up("md")]: {
        width: 811,
      },
      "&.is-small-width": {
        [theme.breakpoints.up("sm")]: {
          width: 440,
        },
      },
    },
    contentWrapper: {
      display: "flex",
    },
  }));
  const classes = bodyWrapper();
  return (
    <Box
      className={clsx(classes.box)}
      zIndex="drawer"
      data-testid="loginpasswordpagebody"
    >
      <ApplicationAltTitle />
      {props.shouldShowLoadingLogo ? (
        <LoadingEpocLogo />
      ) : (
        <Paper
          className={clsx(
            classes.paper,
            props.isSmallWidth ? "is-small-width" : ""
          )}
          elevation={0}
        >
          <div className={classes.contentWrapper}>{props.children}</div>
        </Paper>
      )}
    </Box>
  );
};
