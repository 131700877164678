export class SnackBarType {
  private static AllValues: { [name: string]: SnackBarType } = {};

  private static AllIndexs: { [id: number]: SnackBarType } = {};

  static readonly SUCCESS = new SnackBarType(1, "success");

  static readonly ERROR = new SnackBarType(2, "error");

  static readonly INFO = new SnackBarType(3, "info");

  static readonly OTHER = new SnackBarType(4, "other");

  private constructor(
    public readonly id: number,
    public readonly displayValue: string
  ) {
    SnackBarType.AllValues[displayValue] = this;
    SnackBarType.AllIndexs[id] = this;
  }

  public static parseEnum(data: string): SnackBarType {
    return SnackBarType.AllValues[data];
  }

  public static parseEnumByIndex(id: number): SnackBarType {
    return SnackBarType.AllIndexs[id];
  }
}
