import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { LICENSE_MAXIMUM } from "src/container/LicenseCodeForm";
import {
  LicensePurchaseCalculatePayload,
  LicensePurchaseResponse,
  calculateLicensePurchase,
} from "src/services/licenseService";

export const ProcessCalculateLicense = (
  licensePurchaseRequest: LicensePurchaseCalculatePayload
): UseQueryResult<LicensePurchaseResponse> =>
  useQuery<LicensePurchaseResponse>(
    [
      "licenseCalculate",
      {
        quantity: licensePurchaseRequest.quantity,
        paymentId: licensePurchaseRequest.paymentId,
      },
    ],
    () => calculateLicensePurchase(licensePurchaseRequest),
    {
      enabled:
        (licensePurchaseRequest.quantity > 0 &&
          licensePurchaseRequest.quantity <= LICENSE_MAXIMUM) ||
        licensePurchaseRequest.paymentId !== "",
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
