import React from "react";
import {
  makeStyles,
  Typography,
  Button as MaterialButton,
  Dialog,
  IconButton,
  Box,
} from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { colors } from "../../config/colorConfig";
import { Button } from "./Button";
import { ErrorMessageWith } from "../ErrorMessage";
import { ErrorValues } from "../../models/ErrorValues";

interface FormDialogProps {
  open: boolean;
  setOpen: () => void;
  title: string;
  children: React.ReactElement;
  submitDisabled: boolean;
  handleSubmit: () => void;
  buttonLabel: string;
  inProgress: boolean;
  errorValues: ErrorValues;
  btnId: string;
}

export const FormDialog = (props: FormDialogProps): JSX.Element => {
  const styles = makeStyles((theme) => ({
    boxContainer: {
      width: 407,
      backgroundColor: colors["--text-invert"],
      padding: theme.spacing(6),
      position: "relative",
      borderRadius: 3,
      boxShadow:
        "0 5px 10px -3px rgba(0, 0, 0, 0.1), 0 3px 16px 2px rgba(0, 0, 0, 0.06), 0 8px 12px 1px rgba(0, 0, 0, 0.07)",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    modalTitle: {
      fontSize: 16,
      fontWeight: 500,
      color: colors["--ui-blue-dark"],
      marginBottom: theme.spacing(6),
    },
    cancelLink: {
      color: colors["--title-black"],
      "&:hover": {
        backgroundColor: colors["--text-invert"],
      },
      "&:focus": {
        outline: "none",
      },
    },
    btnContainer: {
      display: "flex",
    },
    cancelIcon: {
      position: "absolute",
      padding: 0,
      right: 24,
      top: 24,
      minWidth: "auto",
    },
    cancel: {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: 500,
      color: colors["--ui-blue-light"],
      padding: 0,
      minWidth: "auto",
      marginLeft: theme.spacing(6),
    },
    closeIcon: {
      height: 20,
      width: 20,
      color: colors["--ui-grey-semi"],
    },
    errorText: {
      marginTop: theme.spacing(5),
      backgroundColor: colors["--background-error"],
      color: colors["--error"],
      lineHeight: 1.09,
    },
    errorTitle: {
      fontSize: 18,
      lineHeight: 1.29,
      letterSpacing: -0.2,
      fontWeight: 400,
      marginBottom: theme.spacing(2),
    },
  }));
  const classes = styles(props);
  const handleClose = () => props.setOpen();
  const handleClick = () => {
    props.handleSubmit();
  };
  return (
    <Dialog data-testid="modalWrapper" onClose={handleClose} open={props.open}>
      <Box className={clsx(classes.boxContainer)}>
        <Typography
          data-testid="modalTitle"
          className={clsx(classes.modalTitle)}
        >
          {props.title}
        </Typography>
        <IconButton
          aria-label="close"
          className={clsx(classes.cancelLink, classes.cancelIcon)}
          onClick={handleClose}
          data-testid="closeBtn"
        >
          <CloseIcon
            data-testid="closeIcon"
            className={clsx(classes.closeIcon)}
          />
        </IconButton>
        <Box>{props.children}</Box>
        <Box data-testid="btnContainer" className={clsx(classes.btnContainer)}>
          <Button
            id={props.btnId}
            name={props.buttonLabel}
            type="submit"
            width={160}
            fontFamily="Metric"
            disabled={props.submitDisabled}
            onClick={handleClick}
            inProgress={props.inProgress}
          />
          <MaterialButton
            disableRipple
            className={clsx(classes.cancelLink, classes.cancel)}
            onClick={handleClose}
            data-testid="cancel"
            name="Cancel"
          >
            Cancel
          </MaterialButton>
        </Box>
        {props.errorValues && props.errorValues.errorMessages?.length > 0 ? (
          <ErrorMessageWith errorValues={props.errorValues} />
        ) : null}
      </Box>
    </Dialog>
  );
};
