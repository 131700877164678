import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { store } from "./store/configureStore";
import "./styles/index.css";
import "./styles/style.css";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppWrapperWithStyles as AppWrapper } from "./container/AppWrapper";
import { HelmetProvider } from "react-helmet-async";
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { config } from "./config/envConfig";
import { CURRENT_USER_ID } from "./utils/localStorageKeys";

const optimizely = createInstance({
  sdkKey: config.environment.optimizelyKey,
  datafileOptions: {
    autoUpdate: true,
    updateInterval: 300000, // update every 5 minutes
  },
});
const queryClient = new QueryClient();
const userId = localStorage.getItem(CURRENT_USER_ID)?.toString();
const user = userId
  ? {
      id: userId,
      attributes: {
        userId: userId,
        platform: "Web",
      },
    }
  : {
      id: "-1",
    };
ReactDOM.render(
  <React.StrictMode>
    <OptimizelyProvider
      optimizely={optimizely}
      timeout={300000}
      isServerSide={true}
      user={user}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <Router history={createBrowserHistory()}>
              <AppWrapper />
            </Router>
          </HelmetProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </OptimizelyProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
