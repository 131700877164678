import * as React from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ErrorIcon from "@material-ui/icons/Error";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { strings } from "../utils/Strings";
import { httpConstants } from "../utils/httpConstants";
import { colors } from "../config/colorConfig";
import { withRoot } from "../withRoot";
import { ErrorValues } from "../models/ErrorValues";
import { RequestError } from "../models/RequestError";

interface Props {
  errorValues: ErrorValues;
}
const ErrorMessage = (props: Props) => {
  const errorMessageStyle = makeStyles((theme) => ({
    errorText: {
      backgroundColor: colors["--background-error"],
      color: colors["--error"],
      lineHeight: 1.09,
    },
    multiErrorText: {
      paddingLeft: 14,
      margin: 0,
    },
    fieldDivider: {
      paddingTop: 17,
    },
    errorTitle: {
      fontSize: 18,
      lineHeight: 1.29,
      letterSpacing: -0.2,
      fontWeight: 400,
      marginBottom: 8,
    },
  }));
  const classes = errorMessageStyle();

  return (
    <div>
      {props.errorValues?.errorStatus !== 0 && (
        <div className={clsx(classes.fieldDivider)}>
          <Alert
            icon={<ErrorIcon />}
            className={clsx(classes.errorText)}
            severity="error"
            variant="outlined"
          >
            <AlertTitle
              data-testid="error"
              classes={{
                root: clsx(classes.errorTitle),
              }}
            >
              Error
            </AlertTitle>
            {props.errorValues?.errorStatus === httpConstants.INVALID_RANGE ? (
              <div data-testid="errorMessages">
                {props.errorValues?.errorMessages?.length > 1 ? (
                  <div>
                    {" "}
                    <ul className={clsx(classes.multiErrorText)}>
                      {props.errorValues.errorMessages.map(
                        (error: RequestError) => (
                          <li>{error.error.message}</li>
                        )
                      )}
                    </ul>
                  </div>
                ) : (
                  <div>
                    {props.errorValues.errorMessages.map(
                      (error: RequestError) => (
                        <div data-testid="errorMsg">{error.error.message}</div>
                      )
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div data-testid="errorMessages">
                {props.errorValues.counter <= 1 ? (
                  <div data-testid="errorMessage">
                    {strings.create_account_error_message.concat("try again")}
                  </div>
                ) : (
                  <div data-testid="errorMessage">
                    {strings.create_account_error_message}
                    <a
                      className={clsx(classes.errorText)}
                      href="https://online.epocrates.com/feedback"
                      target="_blank"
                      data-testid="pleaseContactSupport"
                      rel="noreferrer"
                    >
                      <u>contact support</u>
                    </a>
                  </div>
                )}
              </div>
            )}
          </Alert>
        </div>
      )}
    </div>
  );
};
export const ErrorMessageWith = withRoot(ErrorMessage);
