export class RequestError {
  public error: ErrorMessage;

  constructor(code: string, message: string) {
    this.error = { code, message };
  }
}

export function isRequestError(obj: any): obj is RequestError {
  return obj?.error?.code && obj?.error?.message;
}

export interface ErrorMessage {
  code: string;
  message: string;
}
