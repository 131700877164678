import axios, { AxiosPromise, AxiosResponse } from "axios";
import {
  IUserPaymentMethodIdRequest,
  PaymentMethodAxiosResponse,
} from "src/models/IPaymentMethodResponse";
import { config } from "../config/envConfig";
import { refreshAccess } from "../utils/cookies";
import { APIPlatform, ITransaction } from "src/models/ITransaction";
import { IUserIdRequest } from "../models/IUserIdRequest";
import {
  SubscriptionQueryResponse,
  isSubscriptionQueryResponse,
} from "../models/SubscriptionResponse";
import {
  PlatformTypes,
  TransactionHistoryAxiosResponse,
} from "../models/TransactionHistoryAxiosResponse";
import { DEFAULT_TIMEOUT } from "../utils/AxiosUtil";
import { IPaymentRequest } from "src/models/IPaymentRequest";
import { CURRENT_USER_ID } from "src/utils/localStorageKeys";

export type BillingUnits = "DAY" | "WEEK" | "MONTH" | "YEAR";

export interface BillingPeriod {
  unit: BillingUnits;
  quantity: number;
}

export type APISubscriptionStatus =
  | "ACTIVE"
  | "CANCELLED"
  | "EXPIRED"
  | "GRACE_PERIOD";
export interface V2Subscription {
  id: string;
  // Subscription status - A subscription that is CANCELLED is still active but will not be renewed
  status: APISubscriptionStatus;
  // the date the access granted by current period will end.  The sub will renew on this date or expire
  // If in the past this is the date the sub did expire
  ends: Date;
  // The platform of the subscription
  platform: APIPlatform;
  // The renewal price for the subscription
  renewalPrice: {
    amount: number | null;
    currency: string | null;
  } | null;
  // The active product in the subscription
  activeProducts: { displayName: string; sku: string }[];
  // The billing period for the subscription.  Unit describes the unit of time while
  // quantity describeds how many units in a period (1 YEAR, 2 YEAR, etc)
  billingPeriod: BillingPeriod;
}

export interface Product {
  additional_description: string;
  best_value_priority: number;
  tag_description?: string;
  display_order: number;
  preselection_priority: number;
  platform_sku: string;
  short_description: string;
  price?: {
    amount: number;
    currency?: string;
  };
  billing_period: BillingPeriod;
  trial_period?: BillingPeriod;
}

const { purchaseUrl } = config.environment;

const purchaseInstance = axios.create({
  baseURL: purchaseUrl,
  timeout: DEFAULT_TIMEOUT,
  validateStatus(status) {
    return status >= 200 && status < 299;
  },
});

export const paymentInfoService = async (
  request: IUserIdRequest
): Promise<AxiosPromise> => {
  const instance = axios.create({
    baseURL: purchaseUrl,
    timeout: DEFAULT_TIMEOUT,
  });
  const cookie = await refreshAccess(request.isDeviceEnabled);

  return instance
    .get(
      `/v1/paymentmethods/primary/user/${request.userId}?_t=${+new Date()}`,
      {
        headers: {
          Authorization: `${cookie}`,
          accept: "application/json",
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      console.log(error);
      return error.response || { status: 500, data: {} };
    });
};
export interface IPaymentMethodVid {
  paymentMethodVid: string;
}
export const associatePaymentMethod = async (
  request: IUserPaymentMethodIdRequest
): Promise<PaymentMethodAxiosResponse> => {
  const instance = axios.create({
    baseURL: purchaseUrl,
    timeout: DEFAULT_TIMEOUT,
  });
  const cookie = await refreshAccess();

  const payload: IPaymentMethodVid = {
    paymentMethodVid: request.vid,
  };
  return instance
    .put(
      `/v1/paymentmethods/user/${request.userId}?_t=${+new Date()}`,
      payload,
      {
        headers: {
          Authorization: `${cookie}`,
          accept: "application/json",
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => error.response || { status: 500, data: {} });
};

export interface IServiceRequest {
  userId: number;
  product: string;
  campaignCode?: string;
  paymentId?: string;
}
export const dryrunService = async (
  request: IPaymentRequest
): Promise<AxiosPromise> => {
  const instance = axios.create({
    baseURL: purchaseUrl,
    timeout: DEFAULT_TIMEOUT,
  });
  const cookie = await refreshAccess();

  const payload: IServiceRequest = {
    userId: Number(request.userId),
    product: request.product,
    campaignCode: request.campaignCode ?? undefined,
    paymentId: request.paymentId,
  };
  return instance
    .post(`/v1/purchase/calculate`, payload, {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      console.log(error);
      return error.response || { status: 500, data: {} };
    });
};

export const commitPaymentService = async (
  request: IPaymentRequest
): Promise<AxiosPromise> => {
  const instance = axios.create({
    baseURL: purchaseUrl,
    timeout: 50000,
  });
  const cookie = await refreshAccess();

  const payload: IServiceRequest = {
    userId: Number(request.userId),
    product: request.product,
    paymentId: request.paymentId,
    campaignCode: request.campaignCode ?? undefined,
  };
  return instance
    .post(`/v1/purchase/commit`, payload, {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return {
          status: 500,
          data: { error: { code: error.code, message: error.message } },
        };
      } else {
        return error.response || { status: 500, data: {} };
      }
    });
};

export const getTransactionHistoryService = async (
  userId: number
): Promise<TransactionHistoryAxiosResponse> => {
  const axiosRequest = axios.create({
    baseURL: purchaseUrl,
    timeout: DEFAULT_TIMEOUT,
  });
  const accessToken = await refreshAccess();
  return axiosRequest
    .get(`/v1/transactions/${userId}?_t=${+new Date()}`, {
      headers: {
        Authorization: `${accessToken}`,
        accept: "application/json",
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return error.response || { status: 500, data: {} };
    });
};

export const getUserSubscriptionDetails = async (
  request: string
): Promise<SubscriptionQueryResponse> => {
  const axiosRequest = axios.create({
    baseURL: purchaseUrl,
    timeout: 30000,
    validateStatus(status) {
      return status >= 200 && status < 500;
    },
  });

  const accessToken = await refreshAccess();
  //To make delay to load
  await new Promise((resolve) => setTimeout(resolve, 1000));
  try {
    const responseData = await axiosRequest.get(
      `/v1/subscriptions/user/${request}?_t=${+new Date()}`,
      {
        headers: {
          Authorization: `${accessToken}`,
          accept: "application/json",
        },
      }
    );
    if (responseData && isSubscriptionQueryResponse(responseData)) {
      return responseData;
    } else {
      throw new Error("Response is not of type LicenseSubscriptionResponse");
    }
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};
// This need to be deleted
export const cancelSubscriptionService = async (request: any): Promise<any> => {
  const cookie = await refreshAccess();
  const { purchaseUrl } = config.environment;

  return purchaseInstance
    .delete(
      `/v1/subscriptions/subscription/${request.subscriptionId}/user/${request.userId}`,
      {
        headers: {
          Authorization: `${cookie}`,
          accept: "application/json",
        },
      }
    )
    .then((response: any) => response)
    .catch((error: any) => error.response || { status: 500, data: {} });
};
//New ReactQuery services
export const cancelSubscription = async (
  subscriptionId: string
): Promise<string> => {
  const cookie = await refreshAccess();
  const userId = localStorage.getItem(CURRENT_USER_ID);
  const response = await purchaseInstance.delete(
    `/v1/subscriptions/subscription/${subscriptionId}/user/${userId}`,
    {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    }
  );
  return response.data;
};

export const getTransactionTypes = async (
  userId: number,
  isDeviceEnabled?: boolean
): Promise<PlatformTypes> => {
  const axiosRequest = axios.create({
    baseURL: purchaseUrl,
    timeout: DEFAULT_TIMEOUT,
  });
  const accessToken = await refreshAccess(isDeviceEnabled);
  return axiosRequest
    .get(`/v1/transactions/${userId}/platforms`, {
      headers: {
        Authorization: `${accessToken}`,
        accept: "application/json",
      },
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      return error.response || { status: 500, data: {} };
    });
};
export const getUserV2Subscriptions = async (
  userId: number,
  isDeviceEnabled?: boolean
): Promise<V2Subscription[]> => {
  const cookie = await refreshAccess(isDeviceEnabled);

  const subResponse = await purchaseInstance.get(
    `/v2/subscriptions/user/${userId}?_t=${+new Date()}`,
    {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    }
  );
  return subResponse.data;
};
export const getTransactionHistory = async (
  userId: number
): Promise<ITransaction[]> => {
  const accessToken = await refreshAccess();
  const response = await purchaseInstance.get(
    `/v1/transactions/${userId}?_t=${+new Date()}`,
    {
      headers: {
        Authorization: `${accessToken}`,
        accept: "application/json",
      },
    }
  );
  return response.data;
};

export const getUserProducts = async (
  userId: number,
  isDeviceEnabled: boolean
): Promise<Product[]> => {
  const cookie = await refreshAccess(isDeviceEnabled);

  const productListResponse = await purchaseInstance.get(
    `/v1/products/platform/web/user/${userId}`,
    {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    }
  );
  if (productListResponse.data.length > 0) {
    return productListResponse.data.filter(
      (product: Product) =>
        product.platform_sku !== "PLUS_YEAR_GROUP_LICENSE_CODE"
    );
  }
  return productListResponse.data;
};

export const getProductsForPromoCode = async (
  userId: number,
  promoCode: string
): Promise<Product[]> => {
  if (!promoCode) {
    return [];
  }
  const accessToken = await refreshAccess();
  const response = await purchaseInstance.get(
    `/v1/products/campaign/${promoCode}/user/${userId}`,
    {
      headers: {
        Authorization: `${accessToken}`,
        accept: "application/json",
      },
      validateStatus(status) {
        return status >= 200 && status < 500;
      },
    }
  );
  if (response.status === 404) {
    return [];
  }
  return response.data;
};
