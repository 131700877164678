import { BillingPeriod } from "src/services/subscriptionService";

export function convertPeriodToEnglish(period: BillingPeriod) {
  const quantityToAlphabetic = {
    [1 as number]: "One ",
    //display to add space after 1
    [2 as number]: "Two ",
    [3 as number]: "Three ",
    [4 as number]: "Four ",
    [14 as number]: "Fourteen ",
  };
  if (period.quantity < 2) {
    return `${period.unit.substring(0, 1)}${period.unit
      .substring(1)
      .toLowerCase()}ly`;
  }
  return `${quantityToAlphabetic[period.quantity]} ${period.unit
    .substring(0)
    .toLowerCase()}`;
}

export function trialPeriodToDays(trialPeriod: BillingPeriod): number {
  if (!trialPeriod) {
    return 0;
  }
  switch (trialPeriod.unit) {
    case "DAY":
      return trialPeriod.quantity;
    case "WEEK":
      return trialPeriod.quantity * 7;
    case "MONTH":
      return trialPeriod.quantity * 30;
    case "YEAR":
      return trialPeriod.quantity * 365;
    default:
      return 0;
  }
}
