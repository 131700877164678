import { useMediaQuery } from "@material-ui/core";

export const Desktop = ({ children }: any) =>
  useMediaQuery((theme: any) => theme.breakpoints.up("lg")) ? children : null;

export const DesktopTablet = ({ children }: any) =>
  useMediaQuery((theme: any) => theme.breakpoints.up("sm")) ? children : null;

export const Tablet = ({ children }: any) =>
  useMediaQuery((theme: any) => theme.breakpoints.between("sm", "md"))
    ? children
    : null;

export const TabletMobile = ({ children }: any) =>
  useMediaQuery((theme: any) => theme.breakpoints.down("md")) ? children : null;

export const Mobile = ({ children }: any) =>
  useMediaQuery((theme: any) => theme.breakpoints.down("xs")) ? children : null;
