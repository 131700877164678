import * as React from "react";
import { useDispatch } from "react-redux";
import { withWidth } from "@material-ui/core";
import emailValidation from "email-validator";
import { ForgotPasswordRequest } from "../models/ForgotPasswordRequest";
import { ForgotPasswordComponent } from "../components/ForgotPassword";
import { ForgotPasswordSuccess } from "../components/ForgotPasswordSuccess";
import { withRoot } from "../withRoot";
import { config } from "../config/envConfig";
import {
  FORGOT_PASSWORD_RESET_ERROR_EVENT_ID,
  FORGOT_PASSWORD_RESET_ERROR,
  FORGOT_PASSWORD_EMAIL_SUBMIT,
  FORGOT_PASSWORD_EMAIL_SUBMIT_EVENT_ID,
} from "../utils/analytics";
import { sendAmplitudeEventData } from "../utils/AmplitudeUtil";
import { strings } from "../utils/Strings";
import { CURRENT_USER_NAME } from "../utils/localStorageKeys";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { sendSnackBarMessage } from "../actions/snackBarActions";
import { SnackBarType } from "../utils/SnackBarType";
import * as types from "../actions";
import { forgotPasswordService } from "../services/passwordResetService";
import { passwordResetErrorMessage } from "../utils/errormessage";

interface State {
  isVerified: boolean;
  isSubmitEnabled: boolean;
}
let successPageFlag = false;
let email = "";
const ForgotPassword = (props: any) => {
  const siteKey = config.environment.reCAPTCHA_site_key;
  const dispatch = useDispatch();
  const forgotPasswordQuery = useMutation(forgotPasswordService, {
    onSuccess: (response: AxiosResponse) => {
      if (response.status >= 200 && response.status <= 299) {
        successPageFlag = true;
        sendAmplitudeEventData(
          props.match.params.email || email,
          FORGOT_PASSWORD_EMAIL_SUBMIT,
          FORGOT_PASSWORD_EMAIL_SUBMIT_EVENT_ID
        );
      } else {
        dispatch({ type: types.TIMEOUT_SNACKBAR });
        dispatch(
          sendSnackBarMessage(
            "",
            passwordResetErrorMessage(response.data) ||
              "Something unexpected happened",
            SnackBarType.ERROR.displayValue
          )
        );
        successPageFlag = false;
        sendAmplitudeEventData(
          props.match.params.email || email,
          FORGOT_PASSWORD_RESET_ERROR,
          FORGOT_PASSWORD_RESET_ERROR_EVENT_ID,
          response?.data?.error?.code,
          response?.data?.error?.message
        );
      }
    },
  });
  const [values, setValues] = React.useState<State>({
    isVerified: config.environment.integrationTest || false,
    isSubmitEnabled: false,
  });
  const verifyCallback = (recaptchaToken: any) => {
    setValues({ ...values, isVerified: true });
  };

  const validateInput = (data: string) => {
    email = data;
    if (
      emailValidation.validate(email) &&
      (values.isVerified || props.isTestVerified)
    ) {
      if (!values.isSubmitEnabled) {
        setValues({ ...values, isSubmitEnabled: true });
      }
      return null;
    }
    if (values.isSubmitEnabled) {
      setValues({ ...values, isSubmitEnabled: false });
    }

    return strings.email_blank_message;
  };
  const handleSubmit = async (formEvent: any): Promise<void> => {
    formEvent.preventDefault();
    const forgotPasswordRequest: ForgotPasswordRequest = {
      email,
    };
    localStorage.setItem(CURRENT_USER_NAME, email);
    forgotPasswordRequest.email = email;
    if (props.onSubmit) {
      props.onSubmit(forgotPasswordRequest);
    }
    forgotPasswordQuery.mutate(forgotPasswordRequest);
  };
  const submitToAction = () => {
    const forgotPasswordRequest: ForgotPasswordRequest = {
      email,
    };
    forgotPasswordRequest.email = email;
    forgotPasswordQuery.mutate(forgotPasswordRequest);
  };
  const forgotPasswordComponent = (
    <ForgotPasswordComponent
      siteKey={siteKey}
      handleSubmit={handleSubmit}
      verifyCallback={verifyCallback}
      validation={validateInput}
      isEnabled={values.isSubmitEnabled}
      inProgress={forgotPasswordQuery.isLoading}
    />
  );
  const forgotpasswordsuccess = (
    <ForgotPasswordSuccess
      email={email}
      submitToAction={submitToAction}
      forgotPasswordInProgress={forgotPasswordQuery.isLoading}
      inProgress={forgotPasswordQuery.isLoading}
    />
  );

  return (
    <>{!successPageFlag ? forgotPasswordComponent : forgotpasswordsuccess}</>
  );
};

export const ForgotPasswordWith = withWidth()(withRoot(ForgotPassword));
