import React, { useEffect, useState } from "react";
import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  Snackbar,
  createStyles,
  withStyles,
  Theme,
  createTheme,
  MuiThemeProvider,
  Link,
  useMediaQuery,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import Typography from "@material-ui/core/Typography";
import { BrowserRouter } from "react-router-dom";
import { red } from "@material-ui/core/colors";
import clsx from "clsx";
import { colors } from "../config/colorConfig";
import { closeSnackBar } from "../actions/snackBarActions";
import { deviceManagementAction } from "../actions/profileActions";
import { App } from "./App";
import { appStyle } from "../styles/appStyle";
import { config } from "../config/envConfig";
import { initializeSegmentClient } from "../utils/AmplitudeUtil";
import { strings } from "../utils/Strings";
import { AlertTitle } from "@material-ui/lab";
import { ScrollToTopWithRouter as ScrollToTop } from "../components/custom/ScrollToTop";
import { CURRENT_USER_ID } from "src/utils/localStorageKeys";
import { getAllowAnalytics } from "src/services/accountProfileService";
import { licenseTheme } from "src/styles/licenseTheme";
import { useDecision } from "@optimizely/react-sdk";
import { deviceManagementEnabledFlag } from "src/utils/optimizelyFlag";
import successImage from "../images/icons-streamline-regular-24-pt-check-circle-filled-success.png";
import successImage2X from "../images/icons-streamline-regular-24-pt-check-circle-filled@2x-success.png";
import successImage3X from "../images/icons-streamline-regular-24-pt-check-circle-filled@3x-success.png";
import errorImage from "../images/check-circle-filled.png";
import errorImage2X from "../images/check-circle-filled@2x.png";
import errorImage3X from "../images/check-circle-filled@3x.png";
import infoImage from "../images/icons-streamline-regular-24-pt-check-circle-filled-info.png";
import infoImage2X from "../images/icons-streamline-regular-24-pt-check-circle-filled@2x-info.png";
import infoImage3X from "../images/icons-streamline-regular-24-pt-check-circle-filled@3x-info.png";
import { SubscriptionContextProvider } from "src/context/SubscriptionContext";

const styles = (theme: Theme) =>
  createStyles({
    success: {
      color: colors["--success"],
      backgroundColor: colors["--text-invert"],
    },
    error: {
      color: colors["--primary-orange"],
      backgroundColor: colors["--text-invert"],
    },
    info: {
      color: colors["--accent-jellyfish"],
      backgroundColor: colors["--text-invert"],
    },
    other: {
      color: colors["--primary-midnight"],
      backgroundColor: colors["--text-invert"],
    },
  });
const urlSupport = config.environment.supportUrl;
const theme2 = createTheme({
  palette: {
    primary: {
      main: "#0f4c90",
    },
    error: red,
    secondary: red,
  },
  typography: {
    fontFamily: ["Metric"].join(","),
  },
  spacing: 4,
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.23)",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#4A90E2",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "#4A90E2",
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#4A90E2",
        },
      },
    },
  },
});

declare global {
  interface Window {
    vindicia: {
      setup: () => void;
      destroy: () => void;
      isValid: () => boolean;
      resetCompleteStatus: () => void;
      clearData: () => void;
      frames: {
        cardNumber: {
          isLoaded: boolean;
        };
        cvn: {
          isLoaded: boolean;
        };
        expirationDate: {
          isLoaded: boolean;
        };
        name: {
          isLoaded: boolean;
        };
      };
    };
  }
}

const AppWrapper = (props: any) => {
  const currentURL = window.location.href;
  const dispatch = useDispatch();
  const [deviceManagementDecision] = useDecision(deviceManagementEnabledFlag);
  const containsLicense = currentURL.includes("license");
  let snackBarTheme = theme2;
  if (containsLicense) {
    snackBarTheme = licenseTheme;
  }
  const [allowAnalytics, setAllowAnalytics] = useState(false);
  const userProfile = useSelector(
    (reduxState: RootStateOrAny) =>
      reduxState.accountProfile?.accountProfileResponse?.userInformation
  );
  const [, setAnalytics] = useState<Analytics | null>(null);
  const [segmentLoaded, setSegmentLoaded] = useState<boolean>(false);
  const [, setUserId] = useState(localStorage.getItem(CURRENT_USER_ID));

  useEffect(() => {
    dispatch(deviceManagementAction(deviceManagementDecision.enabled ?? false));
  }, [deviceManagementDecision.enabled]);

  useEffect(() => {
    const idAsString = userProfile?.userId ? String(userProfile?.userId) : null;
    setUserId(idAsString);
    const callAllowAnalytics = async () => {
      setAllowAnalytics(await getAllowAnalytics(idAsString ?? undefined));
    };
    callAllowAnalytics();
  }, [userProfile]);

  useEffect(() => {
    const loadAnalytics = async () => {
      if (allowAnalytics) {
        const writeKey = config.environment.segmentApiKey;
        const [response] = await AnalyticsBrowser.load(
          { writeKey },
          {
            cookie: { domain: ".epocrates.com" },
          }
        );

        if (response) {
          setAnalytics(response);
          setSegmentLoaded(true);

          // necessary for deprecated AmplitudeUtil support
          initializeSegmentClient(response);
        }
      } else {
        setAnalytics(null);
        setSegmentLoaded(false);
        initializeSegmentClient(null);
      }
    };
    loadAnalytics();
  }, [segmentLoaded, allowAnalytics]);

  const barClass = appStyle(props);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeSnackBar());
  };
  const { classes, sendSnackBarMessage, snackBarMessage } = props;
  const classStyle = classes[sendSnackBarMessage.messageType] || classes.other;
  let bar;
  let icon: string;
  let icon2x: string;
  let icon3x: string;
  switch (sendSnackBarMessage.messageType) {
    case "error": {
      bar = clsx(barClass.ErrorDividerVertical);
      /* eslint-disable @typescript-eslint/no-var-requires */
      icon = errorImage;
      icon2x = errorImage2X;
      icon3x = errorImage3X;
      /* eslint-enable @typescript-eslint/no-var-requires */
      break;
    }
    case "info": {
      bar = clsx(barClass.InfoDividerVertical);
      /* eslint-disable @typescript-eslint/no-var-requires */
      icon = infoImage;
      icon2x = infoImage2X;
      icon3x = infoImage3X;
      /* eslint-enable @typescript-eslint/no-var-requires */
      break;
    }
    case "success": {
      bar = clsx(barClass.SuccessDividerVertical);
      /* eslint-disable @typescript-eslint/no-var-requires */
      icon = successImage;
      icon2x = successImage2X;
      icon3x = successImage3X;
      /* eslint-enable @typescript-eslint/no-var-requires */
      break;
    }
    default:
      bar = clsx(barClass.InfoDividerVertical);
      /* eslint-disable @typescript-eslint/no-var-requires */
      icon = infoImage;
      icon2x = infoImage2X;
      icon3x = infoImage3X;
    /* eslint-enable @typescript-eslint/no-var-requires */
  }
  const isSmallScreen = useMediaQuery((theme) =>
    snackBarTheme.breakpoints.down("xs")
  );
  const supportLink = (
    <Typography data-testid="message" className={clsx(classes.toastText)}>
      Please try again. Visit
      <Link
        style={{
          color: colors["--primary-teal"],
          fontWeight: 500,
          textTransform: "none",
          textDecoration: "none",
        }}
        href={urlSupport}
        className={clsx(classes.supportLink, classes.supportLink)}
        data-testid="cancel"
      >
        {strings.contact_support}
      </Link>
      &nbsp;if issue persists.
    </Typography>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={sendSnackBarMessage.isSnackBarOpen}
        autoHideDuration={sendSnackBarMessage.autoHideDuration}
        onClose={handleClose}
        style={{ zIndex: 2000 }}
        data-testid="snackbar"
      >
        <div>
          <MuiThemeProvider theme={snackBarTheme}>
            <MuiAlert
              style={{
                display: "flex",
                alignItems: "center",
                color: colors["--primary-midnight"],
                width: isSmallScreen ? "100%" : "600px",
              }}
              className={classStyle}
              severity={sendSnackBarMessage.messageType}
              elevation={6}
              data-testid="alert"
              onClose={handleClose}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              icon={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div data-testid="bar" className={bar}>
                    {" "}
                  </div>
                  <img
                    src={icon}
                    srcSet={`
                         ${icon2x} 2x,
                         ${icon3x} 3x
                   `}
                    className="IconsStreamlineregular24ptcheck-circle-filled"
                    alt=""
                  />{" "}
                </div>
              }
            >
              {sendSnackBarMessage.title ? (
                <AlertTitle
                  style={{
                    color: `${colors["--primary-midnight"]}`,
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                >
                  {sendSnackBarMessage.title}
                </AlertTitle>
              ) : (
                ""
              )}
              {sendSnackBarMessage.message && (
                <div>
                  <Typography
                    data-testid="message"
                    className={clsx(classes.toastText)}
                  >
                    {sendSnackBarMessage.message}
                  </Typography>
                </div>
              )}
              <div>{sendSnackBarMessage.link && supportLink}</div>
            </MuiAlert>
          </MuiThemeProvider>
        </div>
      </Snackbar>
      <BrowserRouter>
        <ScrollToTop>
          <SubscriptionContextProvider>
            <App />
          </SubscriptionContextProvider>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = (response: any) => ({ ...response });
export const AppWrapperWithStyles = connect(mapStateToProps)(
  withStyles(styles)(AppWrapper)
);
