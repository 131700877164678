import {
  EmailPreferenceActions,
  EmailPreferences,
} from "../models/EmailPreference";
import * as types from "./index";

export const updateEmailPreferenceAction = (
  emailPreferenceRequest: EmailPreferences
): EmailPreferenceActions => ({
  type: types.EMAIL_PREFERENCE_UPDATE,
  emailPreferenceRequest,
});

export const getEmailPreferenceAction = (): EmailPreferenceActions => ({
  type: types.GET_EMAIL_PREFERENCE,
});
