import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { withRoot } from "src/withRoot";
import { colors } from "src/config/colorConfig";
import React from "react";

interface Props {
  title: string;
  action?: React.ReactNode;
  children?: React.ReactNode;
}

const rightPaneStyle = makeStyles((theme) => ({
  pageWrapper: {
    margin: 0,
  },
  pageHeader: {
    borderBottom: 1,
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  actionWrapper: {
    alignSelf: "center",
    margin: 0,
  },
  cardWrapper: {
    marginBottom: theme.spacing(5),
  },
  divider: {
    backgroundColor: colors["--ui-grey-light"],
  },
}));

const ContentCard = (props: Props) => {
  const { title, action, children } = props;
  const classes = rightPaneStyle();

  return (
    <div className={clsx(classes.pageWrapper)}>
      <Card data-testid="cardWrapper" className={clsx(classes.cardWrapper)}>
        <CardHeader
          data-testid="cardHeaderWrapper"
          title={<span data-testid="cardTitle">{title}</span>}
          action={action}
          classes={{
            root: clsx(classes.cardHeader),
            action: clsx(classes.actionWrapper),
          }}
        />
        <Divider
          aria-hidden="true"
          classes={{
            root: clsx(classes.divider),
          }}
        />
        <CardContent>{children}</CardContent>
      </Card>
    </div>
  );
};

export const ContentCardWithRoot = withRoot(ContentCard);
