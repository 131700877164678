import * as types from "./index";
import { SubSpecialtiesRequest } from "../models/SubspecialtiesRequest";

export const countriesAction = () => ({
  type: types.COUNTRIES,
});
export const occupationsAction = () => ({
  type: types.OCCUPATIONS,
});
export const specialtiesAction = (occupationId: number) => ({
  type: types.SPECIALTIES,
  occupationId,
});
export const subSpecialtiesAction = (
  subSpecialtiesRequest: SubSpecialtiesRequest
) => ({
  type: types.SUBSPECIALTIES,
  subSpecialtiesRequest,
});
export const degreesAction = () => ({
  type: types.DEGREES,
});
