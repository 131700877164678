export enum APIPlatform {
  android = "android",
  iOS = "ios",
  web = "web",
}
export interface ITransaction {
  orderNumber: string;
  date: Date;
  productName: string;
  platform: APIPlatform;
  paymentMethod?: IPaymentMethod;
  payment?: {
    productPrice: string;
    discount: string;
    tax: string;
    subtotal: string;
    total: string;
    quantity?: string;
  };
}
export interface IPaymentMethod {
  id?: string;
  accountHolder: string;
  billingAddress1: string;
  billingAddress2?: string;
  city: string;
  state: string;
  postalCode: string;
  cardNumber: string;
  expirationDate: string;
}
