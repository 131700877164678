import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { getDevicesList } from "src/services/deviceService";
import { Device, DevicesListResponse } from "../models/Device";

export const RetriveDevicesList = (
  userId: number
): UseQueryResult<DevicesListResponse> =>
  useQuery<Record<string, Device[]>>(
    ["deviceListRequest", { userId }],
    () => getDevicesList(userId),
    {
      enabled: userId !== undefined && userId !== -1,
      retry: 3,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
