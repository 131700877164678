export const getQueryObjFromString = (str = "") =>
  str
    .replace("?", "")
    .split("&")
    .reduce((acc: Record<string, unknown>, value) => {
      const splits = value.split("=");
      const [key] = splits;
      const splitValue = splits[1];
      acc[key] = splitValue;
      return acc;
    }, {});

/**
 * Converts a URL search string like props.location.search into a string you can pass directly to
 * props.history.replace() that filters out unexpected query parameters
 */
export function filterQueryParameters(
  query: string,
  keepParameterKeys: string[] = []
): string {
  const params = new URLSearchParams(query);
  params.forEach((value, key) => {
    if (keepParameterKeys.indexOf(key) === -1) params.delete(key);
  });
  return `?${params.toString()}`;
}
