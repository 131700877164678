import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { IPrimaryPaymentInformation } from "src/models/PrimaryPaymentMethodResponse";
import { getPaymentMethodDetails } from "src/services/licenseService";
import {
  associateV2Payment,
  getPrimaryPaymentMethod,
} from "src/services/paymentMethodService";

export const GetPaymentMethodDetails = (
  vid: string
): UseQueryResult<AxiosResponse> =>
  useQuery<AxiosResponse>(
    ["getPaymentMethodDetails", { vid: vid }],
    () => getPaymentMethodDetails({ paymentMethodVid: vid }),
    {
      enabled: vid !== undefined && vid !== "",
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

export const SetV2AssociatePaymentMethod = (
  vid: string
): UseQueryResult<IPrimaryPaymentInformation> =>
  useQuery<IPrimaryPaymentInformation>(
    ["associateV2PaymentService", { vid: vid }],
    () => associateV2Payment({ paymentMethodVid: vid }),
    {
      enabled: vid !== undefined && vid !== "",
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
export const GetPrimaryPaymentMethod = (
  userId: number,
  isDeviceEnabled?: boolean
): UseQueryResult<IPrimaryPaymentInformation> =>
  useQuery<IPrimaryPaymentInformation>(
    ["getPrimaryPaymentMethod", userId],
    () => getPrimaryPaymentMethod(userId, isDeviceEnabled),
    {
      enabled: userId !== undefined && userId !== -1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
