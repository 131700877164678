import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as React from "react";
import { colors } from "./config/colorConfig";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createTheme({
  palette: {
    primary: {
      main: colors["--primary"],
    },
    error: {
      main: colors["--error"],
    },
    secondary: {
      main: colors["--error"],
    },
  },
  typography: {
    fontFamily: ["Metric"].join(","),
  },
  spacing: 4,
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: colors["--ui-gray"],
          borderWidth: 2,
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: colors["--ui-gray"],
          "@media (hover: none)": {
            borderColor: colors["--ui-gray"],
          },
        },
        "&$focused $notchedOutline": {
          borderColor: colors["--ui-gray"],
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: colors["--ui-slate"],
        },
        color: colors["--ui-slate"],
      },
    },
  },
});

export function withRoot(Component: (props: any) => React.JSX.Element) {
  // MuiThemeProvider makes the theme available down the React tree
  // thanks to React context.
  return function wr(_props: any) {
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {..._props} />
      </MuiThemeProvider>
    );
  };
}
