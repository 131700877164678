import * as React from "react";
import clsx from "clsx";
import { colors } from "../config/colorConfig";
import { strings } from "../utils/Strings";
import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import { Button } from "./custom/Button";
import { TextField } from "./custom/TextField";
import { OneTimeCodeQuery } from "src/queries/OneTimeCodeQuery";
import { ApplicationAltTitle } from "./custom/ApplicationAltTitle";
import { OtcLoginForm } from "./OtcLoginForm";
import { RouteComponentProps } from "react-router";
import { sendSnackBarMessage } from "src/actions/snackBarActions";
import { useDispatch } from "react-redux";
import { SnackBarType } from "src/utils/SnackBarType";
import { sendAmplitudeEvent } from "src/utils/AmplitudeUtil";
import {
  Authentication_OTC_Login_Screen_Cancel_Click,
  Authentication_OTC_Login_Screen_Send_Code_Click,
  Authentication_OTC_Login_Screen_View,
} from "src/utils/analytics";
import emailValidation from "email-validator";
import { withRoot } from "src/withRoot";

const OtcEmailFormComponent = (props: RouteComponentProps) => {
  const [email, setEmail] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const fetchOneTimeCode = OneTimeCodeQuery(email);
  const dispatch = useDispatch();
  const bodyWrapper = makeStyles((theme) => ({
    box: {
      marginTop: theme.spacing(16),
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      padding: `${theme.spacing(8)}px ${theme.spacing(12)}px ${theme.spacing(
        12
      )}px`,
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(7)}px ${theme.spacing(0)}px`,
      },
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      padding: "40px 0",
      [theme.breakpoints.down("sm")]: {
        padding: "24px 5px",
        width: "95%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "440px",
      },
      marginTop: theme.spacing(16),
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "90%",
      margin: "auto",
      [theme.breakpoints.down("md")]: {
        width: "95%",
      },
    },
    title: {
      color: colors["--title"],
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "32px",
      textAlign: "center",
      fontFamily: "Source Sans Pro",
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(2),
      },
    },
    otcEmailForm: {
      width: "100%",
    },
    enterEmailText: {
      color: colors["--ui-slate"],
      textAlign: "center",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily: "Source Sans Pro",
      lineHeight: "24px",
    },
    cancelButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "48px",
      width: "100%",
      fontWeight: 600,
      fontSize: 18,
      borderRadius: 3,
      textTransform: "none",
      cursor: "pointer",
      backgroundColor: colors["--text-invert"],
      textDecoration: "none",
      color: colors["--primary-midnight"],
      marginTop: "16px",
      fontFamily: "Source Sans Pro",
      "&:hover": {
        textDecoration: "none",
        color: "inherit",
      },
    },
  }));
  const validateInput = (data: string) => {
    setEmail(data.trim());
    setEmailError(null);
  };
  const validateEmail = (emailData: string) => {
    if (!emailValidation.validate(emailData)) {
      setEmailError(strings.email_blank_message);
      return false;
    } else {
      setEmailError(null);
      return true;
    }
  };
  const classes = bodyWrapper();

  React.useEffect(() => {
    sendAmplitudeEvent(Authentication_OTC_Login_Screen_View);
  }, []);

  const handleSubmit = async (
    formEvent: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    sendAmplitudeEvent(Authentication_OTC_Login_Screen_Send_Code_Click);
    formEvent.preventDefault();
    const validEmail = validateEmail(email);
    validEmail &&
      fetchOneTimeCode
        .refetch()
        .then((response) => {
          if (response.data?.status === 201) {
            setOpen(true);
          } else {
            dispatch(
              sendSnackBarMessage(
                "",
                response.data?.data.error.message,
                SnackBarType.ERROR.displayValue
              )
            );
          }
          return;
        })
        .catch((error) =>
          dispatch(
            sendSnackBarMessage(
              "",
              error.message,
              SnackBarType.ERROR.displayValue
            )
          )
        );
  };

  const onCancelClick = () => {
    sendAmplitudeEvent(Authentication_OTC_Login_Screen_Cancel_Click);
  };

  const bodyTitle = strings.sign_in_with_otp;
  const formBody = (
    <form
      onSubmit={handleSubmit}
      className={clsx(classes.otcEmailForm)}
      data-testid="form"
    >
      <TextField
        autoCapitalize="off"
        name="email"
        testId="email"
        helpTestId="emailHelp"
        label={strings.profile_email}
        validationMessage={strings.email_blank_message}
        validation={validateInput}
        validationError={emailError}
        isNewDesign={true}
        input_type_email
      />
      <Button
        name={strings.send_code}
        id="sendcode"
        type="submit"
        backgroundColor={colors["--primary-tealnight"]}
        height={48}
        fontFamily={"Source Sans Pro"}
        fontSize={18}
        hoverForeground={colors["--primary-tealnight"]}
      />
    </form>
  );
  const cancelButton = (
    <a
      className={clsx(classes.cancelButton)}
      href="/login"
      data-testid="cancelbuttonlink"
      rel="noreferrer"
      onClick={onCancelClick}
    >
      {strings.cancel}
    </a>
  );
  return open ? (
    <OtcLoginForm email={email} {...props} />
  ) : (
    <Box
      className={clsx(classes.box)}
      zIndex="drawer"
      data-testid="otploginformwrapper"
    >
      <ApplicationAltTitle />
      <Paper className={clsx(classes.paper)} elevation={0}>
        <div className={classes.contentWrapper}>
          <Typography
            data-testid="title"
            variant="h6"
            className={clsx(classes.title)}
          >
            {bodyTitle}
          </Typography>
          <p className={clsx(classes.enterEmailText)}>
            Enter your registered epocrates email
          </p>
          {formBody}
          {cancelButton}
        </div>
      </Paper>
    </Box>
  );
};

export const OtcEmailForm = withRoot(OtcEmailFormComponent);
