import { Route, Switch } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ForgotPasswordWith as ForgotPassword } from "./ForgotPassword";
import { PasswordResetPage } from "../components/PasswordResetPage";
import { DashboardComponentWith as DashboardComponent } from "../components/DashboardComponent";
import { PrivateRoute } from "./privateRoute";
import { PasswordResetSuccessPageWith as PasswordResetSuccessPage } from "../components/PasswordResetSuccessPage";
import { HealthCheckComponent } from "../components/HealthCheckComponent";
import { sendAmplitudeEventData } from "../utils/AmplitudeUtil";
import {
  CREATE_NEW_PASSWORD_ERROR,
  FORGOT_PASSWORD_RESET_ERROR_EVENT_ID,
} from "../utils/analytics";
import { CURRENT_USER_NAME } from "../utils/localStorageKeys";
import { PrivateCmeRoute } from "./privateCmeRoute";
import { CmeLaunchComponentWith as CmeLaunchComponent } from "../components/CmeLaunchComponent";
import { LogoutFormWith as LogoutForm } from "../components/LogoutForm";
import { CreateAccountWith as CreateAccount } from "./CreateAccount";
import { setCookie } from "../utils/cookies";
import { ErrorScreentWithRoot as ErrorScreen } from "../components/ErrorScreen";
import { httpConstants } from "../utils/httpConstants";
import { AccountRoute } from "./AccountRoute";
import { TransactionReceipt } from "src/components/TransactionReceipt";
import { LoginWrap } from "./LoginWrap";
import { LicenseCodeForm } from "./LicenseCodeForm";
import { EmailLoginWrap } from "./EmailLoginWrap";
import { OtcLoginWrap } from "./OtcLoginWrap";
import { RedeemLicenseHome } from "./RedeemLicenseHome";
import { DevicesRoute } from "./DevicesRoute";
import { UserLandingPage } from "./UserLandingPage";
import { useDecision } from "@optimizely/react-sdk";
import { deviceManagementEnabledFlag } from "src/utils/optimizelyFlag";
import { OverlaySpinner } from "src/components/custom/OverlaySpinner";

const globalErrorHandler = (error: any, info: { componentStack: string }) => {
  sendAmplitudeEventData(
    localStorage.getItem(CURRENT_USER_NAME) || "Rendering Error",
    CREATE_NEW_PASSWORD_ERROR,
    FORGOT_PASSWORD_RESET_ERROR_EVENT_ID,
    "internalError",
    error?.response?.data?.error?.message,
    JSON.stringify(error)
  );
  setCookie("contextuallink", -1, "");
};

function ErrorFallback() {
  // React is not displaying line breaks in props
  return <ErrorScreen code={httpConstants.INTERNAL_SERVER_ERROR} />;
}

const NoMatch = () => <ErrorScreen code={httpConstants.PAGE_NOT_FOUND} />;

export const App = () => {
  const [deviceManagementDecision, clientReady] = useDecision(
    deviceManagementEnabledFlag
  );

  return clientReady ? (
    <OverlaySpinner />
  ) : (
    <div
      style={{
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        position: "absolute",
      }}
    >
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={globalErrorHandler}
      >
        <Switch data-testid="switch">
          <Route path="/" exact component={LoginWrap} />
          <Route path="/login/email" component={EmailLoginWrap} />
          <Route path="/login/otc" component={OtcLoginWrap} />
          <Route path="/login" component={LoginWrap} />
          <Route path="/logout" component={LogoutForm} />
          <Route path="/healthcheck" component={HealthCheckComponent} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/createaccount" component={CreateAccount} />
          <AccountRoute
            path="/account/receipt"
            component={TransactionReceipt}
            deviceManagementDecision={deviceManagementDecision}
          />
          <DevicesRoute
            path="/account/devices"
            component={UserLandingPage}
            deviceManagementDecision={deviceManagementDecision}
          />
          <AccountRoute
            path="/account/subscription"
            component={UserLandingPage}
            deviceManagementDecision={deviceManagementDecision}
          />

          <AccountRoute
            path="/account/profile"
            component={UserLandingPage}
            deviceManagementDecision={deviceManagementDecision}
          />
          <AccountRoute
            path="/account"
            component={UserLandingPage}
            deviceManagementDecision={deviceManagementDecision}
          />
          <AccountRoute
            path="/license/redeem"
            component={RedeemLicenseHome}
            deviceManagementDecision={deviceManagementDecision}
          />
          <Route exact path="/license" component={LicenseCodeForm} />
          <Route
            path="/passwordresetsuccess"
            component={PasswordResetSuccessPage}
          />
          <Route path="/passwordreset" component={PasswordResetPage} />
          <PrivateRoute path="/dashboard" component={DashboardComponent} />
          <PrivateCmeRoute path="/cmelaunch" component={CmeLaunchComponent} />
          <Route component={NoMatch} />
        </Switch>
      </ErrorBoundary>
    </div>
  );
};
