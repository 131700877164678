import { put, call, CallEffect, PutEffect } from "redux-saga/effects";
import {
  authenticationService,
  authorizationCodeRedemptionService,
  verificationService,
} from "../services/authenticationService";
import * as types from "../actions";
import { AuthRequest } from "../models/AuthRequest";
import { AuthResponse } from "../models/AuthResponse";
import { loginErrorMessage } from "../utils/errormessage";
import { SnackBarType } from "../utils/SnackBarType";
import { VerifyRequest } from "../models/VerifyRequest";
import { VerifyResponse } from "../models/VerifyResponse";
import { AuthorizationCodeRedeemRequest } from "../models/AuthorizationCodeRedeemRequest";
import { AuthorizationCodeRedeemResponse } from "../models/AuthorizationCodeRedeemResponse";
import { setCookie } from "../utils/cookies";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";
import { AxiosResponse } from "axios";
import { Token } from "src/models/Token";

// TODO currently tokens from the login response are appended to the response from the CME token exchange API response
// if we have a CME redirect. we should consider refactoring and typing this process in cmeAuthenticationService.
interface CMELoginRedirect extends AxiosResponse {
  tokens: Token;
}

// Important there is star after function
export function* authenticationSaga(authRequest: AuthRequest): Generator<
  | CallEffect<any>
  | PutEffect<{
      type: string;
    }>,
  void,
  CMELoginRedirect
> {
  try {
    yield put({ type: types.LOGIN_USER_INPROGRESS });

    const response = yield call(authenticationService, authRequest);

    if (!response) {
      yield put({ type: types.LOGIN_SYSTEM_ERROR });
      return;
    }
    const authResponse: AuthResponse = response.data;
    authResponse.status = response.status;

    if (response.status === 200) {
      if (response.data.tokens) {
        authResponse.tokens = response.data.tokens;
      } else {
        authResponse.tokens = response.tokens;
      }
      yield put({ type: types.LOGIN_USER_SUCCESS, authResponse });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    } else if (response.status === 401 || response.status === 457) {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: loginErrorMessage(authResponse) || "Error logging in",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.LOGIN_USER_ERROR, authResponse });
    } else {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: loginErrorMessage(authResponse) || "System error",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.LOGIN_SYSTEM_ERROR, authResponse });
    }
  } catch (error) {
    yield put({ type: types.LOGIN_USER_ERROR, error });
  }
}

export function* verificationSaga(verifyRequest: VerifyRequest): Generator<
  | CallEffect<any>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  try {
    yield put({ type: types.VERIFY_USER_INPROGRESS });

    const response = yield call(verificationService, verifyRequest);

    if (!response) {
      yield put({ type: types.VERIFY_SYSTEM_ERROR });
      return;
    }
    const verifyResponse: VerifyResponse = response.data;
    verifyResponse.status = response.status;

    if (response.status === 200) {
      yield put({ type: types.VERIFY_USER_SUCCESS, verifyResponse });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    } else if (response.status === 401 || response.status === 457) {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: loginErrorMessage(verifyResponse) || "Error logging in",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.VERIFY_USER_ERROR, verifyResponse });
    } else {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: loginErrorMessage(verifyResponse) || "System error",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.VERIFY_SYSTEM_ERROR, verifyResponse });
    }
  } catch (error) {
    yield put({ type: types.VERIFY_USER_ERROR, error });
  }
}

export function* authorizationCodeRedemptionSaga(
  redeemRequest: AuthorizationCodeRedeemRequest
): Generator<
  | CallEffect<any>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  try {
    yield put({ type: types.AUTH_CODE_REDEEM_USER_INPROGRESS });

    const response = yield call(
      authorizationCodeRedemptionService,
      redeemRequest
    );

    if (!response) {
      yield put({ type: types.AUTH_CODE_REDEEM_SYSTEM_ERROR });
      return;
    }
    const authorizationCodeRedeemResponse: AuthorizationCodeRedeemResponse =
      response.data;
    authorizationCodeRedeemResponse.status = response.status;

    if (response.status === 200) {
      setCookie("accessToken", 24, authorizationCodeRedeemResponse.accessToken);
      setCookie(
        "currentUserId",
        24,
        authorizationCodeRedeemResponse.userId.toString()
      );
      setCookie("sessionTimer", 24, "sessionTimer");
      setCookie("refreshToken", 0, "");
      if (authorizationCodeRedeemResponse.userId) {
        localStorage.setItem(
          CURRENT_USER_ID,
          authorizationCodeRedeemResponse.userId.toString()
        );
      }
      yield put({
        type: types.AUTH_CODE_REDEEM_USER_SUCCESS,
        authorizationCodeRedeemResponse,
      });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    } else if (response.status === 401 || response.status === 457) {
      // Don't set snackbar message; go straight to email/password prompt
      yield put({
        type: types.AUTH_CODE_REDEEM_USER_ERROR,
        authorizationCodeRedeemResponse,
      });
      yield put({ type: types.REQUIRE_USER_MANUAL_LOGIN });
    } else {
      // Don't set snackbar message; we have a backup plan.
      yield put({
        type: types.AUTH_CODE_REDEEM_SYSTEM_ERROR,
        authorizationCodeRedeemResponse,
      });
    }
  } catch (error) {
    yield put({ type: types.AUTH_CODE_REDEEM_USER_ERROR, error });
  }
}
