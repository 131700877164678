import * as types from "../actions";

const initialState = {
  npis: null,
  progress: false,
  error: false,
};
export function npiLookup(state = initialState, action: any) {
  const { npis, type } = action;
  switch (type) {
    case types.GET_NPI_LIST_SUCCESS:
      return { ...state, npis, progress: false, error: false };
    case types.GET_NPI_LIST_PROGRESS:
      return { ...state, progress: true, error: false };
    case types.GET_NPI_LIST_ERROR:
      return { ...state, progress: false, error: true };
    default:
      return state;
  }
}
