import { FormControlLabel, Checkbox, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import React, { useContext, useEffect } from "react";
import withWidth from "@material-ui/core/withWidth";
import { colors } from "../config/colorConfig";
import { QRLoginPasswordPageBody } from "./custom/QRLoginPasswordPageBody";
import { Button } from "./custom/Button";
import { AuthRequest } from "../models/AuthRequest";
import { AuthResponse } from "../models/AuthResponse";
import { withRoot } from "../withRoot";
import { strings } from "../utils/Strings";
import { Password } from "./custom/PasswordField";
import { TextField } from "./custom/TextField";
import { closeSnackBar } from "../actions/snackBarActions";
import {
  FAIL_SIGN_IN,
  CLICK_EMAIL_ADDRESS,
  CLICK_PASSWORD,
  CLICK_FORGOT_PASSWORD,
  CLICK_SIGNUP_LINK,
  CLICK_SIGN_IN_EVENT_ID,
  FAIL_SIGN_IN_EVENT_ID,
  CLICK_EMAIL_ADDRESS_EVENT_ID,
  CLICK_PASSWORD_EVENT_ID,
  CLICK_FORGOT_PASSWORD_EVENT_ID,
  CLICK_SIGNUP_LINK_EVENT_ID,
  EMAIL_LOGIN_SCREEN_VIEW,
  SUCCESSFULLY_SIGN_IN,
  SUCCESSFULLY_SIGN_IN_EVENT_ID,
} from "../utils/analytics";
import {
  getLoginErrorType,
  getToolsAndLocation,
  sendAmplitudeEvent,
  sendAmplitudeEventDataBySource,
} from "../utils/AmplitudeUtil";
import { ToolsLocationForEvents } from "../models/ToolsLocationForEvents";
import { RouteComponentProps } from "react-router-dom";
import { finishLogin } from "src/utils/loginUtil";
import emailValidation from "email-validator";
import { useMutation } from "@tanstack/react-query";
import { authenticationService } from "src/services/authenticationService";
import * as types from "../actions";
import { SnackBarType } from "../utils/SnackBarType";
import { loginErrorMessage } from "../utils/errormessage";
import { OptimizelyContext } from "@optimizely/react-sdk";

interface LoginState {
  login: LoginResponse;
}

interface LoginResponse {
  authResponse: AuthResponse;
  login_progress: boolean;
  system_error: boolean;
  status: number;
}

interface SnackbarState {
  sendSnackBarMessage: SnackbarMessaage;
}

interface SnackbarMessaage {
  isSnackBarOpen: boolean;
}

let isCMELogin = false;
let webSource = "ECOMM";

const QRLoginForm = (props: RouteComponentProps) => {
  const { location: { search = "" } = {} } = props;
  const toolsLocation = React.useRef<ToolsLocationForEvents>({
    tools: null,
    location: null,
  });
  const queryParams = new URLSearchParams(search);
  const hasHandoffUrl: boolean = queryParams.has("handoffUrl");
  const hasDevice = search.includes("devices");
  const hasResumePreviousWorkflow: boolean = queryParams.has(
    "resumePreviousWorkflow"
  );
  let handoffUrl: string;
  let externalActivityId: string | null = "";
  let accessCode: string | null = "";
  let workflowMode: string | null = "";

  const redirect404Page = () => {
    props.history.push("/404");
  };

  // Having a handoffUrl implies we may be attempting a classic (TM) CME SSO.  Validate that.
  if (hasHandoffUrl) {
    webSource = "CME";
    handoffUrl = queryParams.get("handoffUrl") ?? "";
    try {
      const handOffHostUrl = new URL(handoffUrl);
      if (queryParams.has("externalActivityId")) {
        externalActivityId = queryParams.get("externalActivityId");
      }
      if (queryParams.has("accessCode")) {
        accessCode = queryParams.get("accessCode");
      }
      if (queryParams.has("workflowMode")) {
        workflowMode = queryParams.get("workflowMode");
      }
      if (
        handOffHostUrl.hostname?.includes(".rievent.com") ||
        handOffHostUrl.hostname?.includes(".epocrates.com")
      ) {
        isCMELogin = true;
      } else {
        redirect404Page();
      }
    } catch (exception) {
      redirect404Page();
    }
  } else {
    isCMELogin = false;
  }

  // current url referred next to device menagement
  if (hasDevice) {
    webSource = "Devices";
  }

  // Having hasCmeRedirect means we're attempting New CME-SSO (TM)
  if (hasResumePreviousWorkflow) {
    isCMELogin = true;
    webSource = queryParams.get("resumePreviousWorkflow") || "Unknown";
  }

  const loginStyle = makeStyles((theme) => ({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    control: {
      marginTop: theme.spacing(1),
    },
    title: {
      paddingTop: 10,
      color: colors["--title"],
    },
    textColor: {
      color: colors["--ui-slate"],
    },
    linkColor: {
      fontFamily: "Source Sans Pro",
      color: colors["--primary-tealnight"],
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 600,
    },
    failure: {
      color: "#ff0000",
    },
    image: {
      width: "100%",
      maxWidth: "120px",
      height: "auto",
      maxHeight: "41px",
    },
    forgotPasswordLink: {
      fontFamily: "Source Sans Pro",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      color: colors["--primary-tealnight"],
      textAlign: "center",
      margin: `${theme.spacing(4)}px 0 0`,
    },
    signInButton: {
      fontFamily: "Source Sans Pro",
      background: colors["--primary-tealnight"],
      fontSize: 18,
      fontWeight: 600,
    },
    rememberMeLabelRoot: {
      marginTop: theme.spacing(2),
    },
    rememberMeLabelText: {
      fontFamily: "Source Sans Pro",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "24px",
      color: colors["--ui-slate"],
    },
    signupText: {
      fontFamily: "Source Sans Pro",
      fontSize: 16,
      lineHeight: 1.5,
      textAlign: "center",
      marginTop: theme.spacing(4),
      marginBottom: 0,
      color: colors["--primary-midnight"],
    },
  }));

  const classes = loginStyle(props);
  const [stayLoggedIn, setStayLoggedIn] = React.useState(false);
  const dispatch = useDispatch();
  const state = useSelector((loginState: LoginState) => loginState.login);
  const snackBarState = useSelector(
    (snackbarState: SnackbarState) => snackbarState.sendSnackBarMessage
  );
  const [loginProgress, setLoginProgress] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const { optimizely } = useContext(OptimizelyContext);
  const onSignInOpen = () => {
    const properties = {
      "Event ID": CLICK_SIGN_IN_EVENT_ID,
      Source: webSource,
      Tools: toolsLocation.current.tools,
      Location: toolsLocation.current.location,
      "Current URL": window.location.href,
    };
    sendAmplitudeEvent(EMAIL_LOGIN_SCREEN_VIEW, properties, null);
  };

  useEffect(() => {
    const toolsLocationObj = getToolsAndLocation();
    toolsLocation.current.tools = toolsLocationObj?.tools;
    toolsLocation.current.location = toolsLocationObj?.location;
    onSignInOpen();
  }, []);

  useEffect(() => {
    if (state && state.authResponse && state.authResponse.status === 200) {
      finishLogin({
        authResponse: state.authResponse,
        stayLoggedIn,
        toolsLocation: toolsLocation.current,
        location: props.location,
        history: props.history,
        optimizely,
      });
    }
    if (state && state.authResponse && state.authResponse.status !== 200) {
      setLoginProgress(false);
    }
  }, [state.authResponse]);

  const authenticationMutation = useMutation(authenticationService, {
    onSuccess: (responseData) => {
      const authResponse: AuthResponse = responseData.data;
      authResponse.status = responseData.status;
      if (responseData.status === 200) {
        if (responseData.data.tokens) {
          authResponse.tokens = responseData.data.tokens;
        } else {
          authResponse.tokens = responseData.tokens;
        }
        dispatch({ type: types.LOGIN_USER_SUCCESS, authResponse });
        dispatch({ type: types.TIMEOUT_SNACKBAR });
      } else if (responseData.status === 401 || responseData.status === 457) {
        dispatch({
          type: types.SET_SNACKBAR_MESSAGE,
          message: loginErrorMessage(authResponse) || "Error logging in",
          messageType: SnackBarType.ERROR.displayValue,
        });
        dispatch({ type: types.LOGIN_USER_ERROR, authResponse });
      } else {
        dispatch({
          type: types.SET_SNACKBAR_MESSAGE,
          message: loginErrorMessage(authResponse) || "System error",
          messageType: SnackBarType.ERROR.displayValue,
        });
        dispatch({ type: types.LOGIN_SYSTEM_ERROR, authResponse });
      }
    },
    onError: (error) => {
      dispatch({ type: types.LOGIN_USER_ERROR, error });
    },
  });

  const onEmailFocus = () => {
    sendAmplitudeEventDataBySource(
      null,
      CLICK_EMAIL_ADDRESS,
      CLICK_EMAIL_ADDRESS_EVENT_ID,
      webSource
    );
  };
  const onPasswordFocus = () => {
    sendAmplitudeEventDataBySource(
      null,
      CLICK_PASSWORD,
      CLICK_PASSWORD_EVENT_ID,
      webSource
    );
  };

  const onForgotPasswordClick = () => {
    sendAmplitudeEventDataBySource(
      null,
      CLICK_FORGOT_PASSWORD,
      CLICK_FORGOT_PASSWORD_EVENT_ID,
      webSource
    );
  };

  const signupClick = () => {
    sendAmplitudeEventDataBySource(
      null,
      CLICK_SIGNUP_LINK,
      CLICK_SIGNUP_LINK_EVENT_ID,
      webSource
    );
  };

  const validateInput = (data: string) => {
    if (snackBarState?.isSnackBarOpen) {
      dispatch(closeSnackBar());
    }
    setUserEmail(data.trim());
    setEmailError(null);
  };

  const validateEmail = (emailData: string) => {
    if (!emailValidation.validate(emailData)) {
      setEmailError(strings.email_blank_message);
      return false;
    } else {
      setEmailError(null);
      return true;
    }
  };

  const validatePassword = () => {
    if (snackBarState?.isSnackBarOpen) {
      dispatch(closeSnackBar());
    }
    return null;
  };

  const handleSubmit = async (formEvent: React.FormEvent): Promise<void> => {
    setLoginProgress(true);
    if (snackBarState?.isSnackBarOpen) {
      dispatch(closeSnackBar());
    }
    formEvent.preventDefault();
    const validEmail = validateEmail(userEmail);
    const target = formEvent.target as HTMLFormElement;
    const authRequest: AuthRequest = {
      email: target.email.value.trim(),
      password: target.password.value,
      isCMELogin,
      cmeRequest: {
        accessCode,
        externalActivityId,
        workflowMode,
      },
    };
    if (validEmail) {
      authenticationMutation.mutate(authRequest);
    } else {
      setLoginProgress(false);
    }
  };

  React.useEffect(() => {
    if (state && state.authResponse && state.authResponse.status !== 200) {
      const {
        authResponse: { status, error: { code, message } = {} },
      } = state;
      const errorType = getLoginErrorType(code ?? "");
      const eventProperties = {
        "Event ID": FAIL_SIGN_IN_EVENT_ID,
        Email: userEmail,
        Code: code,
        Message: message,
        Source: webSource,
        HttpStatus: status,
        Method: "Email",
        "Error Type": errorType,
        Location: webSource,
      };
      sendAmplitudeEvent(FAIL_SIGN_IN, eventProperties);
    } else if (state && state.system_error) {
      setLoginProgress(false);
      const errorType = getLoginErrorType(500);
      const eventProperties = {
        "Event ID": FAIL_SIGN_IN_EVENT_ID,
        Email: userEmail,
        Code: "internalError",
        Message: "Internal error, please contact Epocrates support.",
        Source: webSource,
        "Error Type": errorType,
        Location: webSource,
      };
      sendAmplitudeEvent(FAIL_SIGN_IN, eventProperties);
    } else if (
      state &&
      state.authResponse &&
      state.authResponse.status === 200
    ) {
      const eventProperties = {
        "Event ID": SUCCESSFULLY_SIGN_IN_EVENT_ID,
        Email: userEmail,
        "QR Token": null,
        Source: webSource,
        HttpStatus: state.status,
        Method: "Email",
        Location: webSource,
      };
      sendAmplitudeEvent(SUCCESSFULLY_SIGN_IN, eventProperties);
    }
  }, [state.authResponse, state.system_error]);

  const onHandleChange = (event: React.ChangeEvent) => {
    const isChecked = (event.currentTarget as HTMLInputElement).checked;
    setStayLoggedIn(isChecked);
    localStorage.setItem("stay_logged_in", isChecked.toString());
  };

  const formBody = (
    <form
      className={clsx(classes.form)}
      onSubmit={handleSubmit}
      data-testid="form"
    >
      <TextField
        autoCapitalize="off"
        name="email"
        testId="email"
        helpTestId="emailHelp"
        label={strings.login_email}
        onClickField={onEmailFocus}
        validationMessage={strings.email_blank_message}
        validation={validateInput}
        validationError={emailError}
        isNewDesign={true}
      />

      <Password
        name="password"
        iconTestId="showPasswordId"
        ariaLabel="password"
        onClickEvent={onPasswordFocus}
        labelWidth={0}
        isNewDesign={true}
        placeholerText={strings.login_password}
        validateInput={validatePassword}
      />

      <FormControlLabel
        id="stayedSignedInId"
        data-testid="stayedSignedInId"
        name="stayedSignedInId"
        className={clsx(classes.textColor)}
        classes={{
          root: clsx(classes.rememberMeLabelRoot),
          label: clsx(classes.rememberMeLabelText),
        }}
        control={
          <Checkbox
            name="staySignedIn"
            color="primary"
            onChange={onHandleChange}
            style={{
              color: colors["--ui-gray"],
            }}
          />
        }
        style={{
          color: colors["--ui-slate"],
        }}
        label={strings.login_stay_signed_in}
      />

      <Button
        name={strings.sign_in_title}
        id="signin"
        type="submit"
        backgroundColor={colors["--primary-tealnight"]}
        height={48}
        fontFamily={"Source Sans Pro"}
        fontSize={18}
        disabled={state.login_progress}
        inProgress={loginProgress}
        hoverForeground={colors["--primary-tealnight"]}
      />
    </form>
  );

  const additionalInfo = (
    <>
      <div>
        <p className={clsx(classes.forgotPasswordLink)}>
          <a
            href="/forgotpassword"
            data-testid="forgotpassword"
            onClick={onForgotPasswordClick}
            className={clsx(classes.linkColor)}
          >
            {strings.login_forgot_password}
          </a>
        </p>
      </div>
      <p className={clsx(classes.signupText)} data-testid="donthaveanaccount">
        {strings.login_dont_have_account}{" "}
        <a
          href={`/createaccount${window.location.search}`}
          data-testid="signup"
          onClick={signupClick}
          className={clsx(classes.linkColor)}
          rel="noreferrer"
        >
          {strings.login_signup}
        </a>
      </p>
    </>
  );

  const bodyTitle = strings.login_signin_title;
  return (
    <QRLoginPasswordPageBody
      title={bodyTitle}
      data-testid="login-page-body-testid"
      progressButton={state.login_progress}
      formBody={formBody}
      additionalInfo={additionalInfo}
    />
  );
};

export const QRLoginFormWith = withWidth()(withRoot(QRLoginForm));
