import {
  Typography,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import { colors } from "../config/colorConfig";
import { licenseTheme } from "../styles/licenseTheme";
import { strings } from "../utils/Strings";
import { ReactElement, useEffect, useState } from "react";
import { TextField } from "./custom/TextField";
import { Button } from "./custom/Button";

const useStyles = makeStyles((theme) => ({
  previewTitle: {
    color: colors["--ui-gray"],
    fontSize: 20,
    fontWeight: 700,
  },
  tableRowNoBorder: {
    "& > *": {
      color: colors["--primary-midnight"],
      fontSize: 16,
      fontWeight: 400,
      borderBottom: "none",
      padding: "12px 0px 0px 0px",
    },
  },
  tableRowNoBorder1: {
    "& > *": {
      color: colors["--primary-midnight"],
      fontSize: 16,
      fontWeight: 400,
      padding: "12px 0px 12px 0px",
    },
  },
  lastTableRowNoBorder: {
    "& > *": {
      color: colors["--primary-midnight"],
      fontSize: 18,
      fontWeight: 700,
      borderBottom: "none",

      padding: "12px 0px 0px 0px",
    },
  },
  leftCellContent: {
    textAlign: "left",
  },
  rightCellContent: {
    textAlign: "right",
  },
  rightCellContentDiscount: {
    textAlign: "right",
    color: colors["--primary-orange"],
    fontWeight: 600,
  },
}));

export interface TableRowData {
  label: string;
  value: string;
  discountVariation?: boolean;
}

/**
 * @interface OrderSummaryProps
 * @description Interface for OrderSummary component props.
 * @property {TableRowData[]} mainTableArray - Array of TableRowData objects for the main table in the order summary. This is above the total line
 * @property {TableRowData[]} summaryTableArray - Array of TableRowData objects for the summary table in the order summary. This is below the total line
 * @property {boolean} displayDiscountBar - Flag indicating whether to display the discount bar in the order summary.
 * @property {function} [updatePromoCode] - Optional function to update the promo code. Must be provided if displayDiscountBar is true
 * @property {string} [currentPromoCode] - Optional current promo code.
 * @property {string} [validationError] - Optional validation error message.
 */
export interface OrderSummaryProps {
  mainTableArray: TableRowData[];
  summaryTableArray: TableRowData[];
  displayDiscountBar: boolean;
  setPromoCode?: (code: string) => void;
  promoCode?: string;
  validationError?: string;
  hideDisplayTitle?: boolean;
}

export const OrderSummaryWindow = (props: OrderSummaryProps): JSX.Element => {
  let currentPromoCode = props.promoCode || "";
  const [promoCodeEnabled, setPromoCodeEnabled] = useState<boolean>(
    currentPromoCode !== ""
  );

  const updatePromoCode = (data: string, eventType: unknown) => {
    const trimmedData = data.trim().toUpperCase();
    currentPromoCode = trimmedData;
    if (trimmedData !== "") {
      setPromoCodeEnabled(true);
    }
    if (props.promoCode === "" && trimmedData === "") {
      setPromoCodeEnabled(false);
    }
    if (trimmedData === "" && props.setPromoCode) {
      props.setPromoCode("");
    }
    return null;
  };

  const applyPromoCode = (data?: unknown, eventType?: unknown) => {
    if (props.setPromoCode) {
      props.setPromoCode(currentPromoCode);
    }
  };

  const validatePromoCode = (data: unknown, eventType: unknown) => {
    // the textField will clear the error on change and we don't want that if
    // we still have a validation error set.  It would be better if we had a text field
    // that had no internal valdiation logic
    if (props.validationError && currentPromoCode) {
      return props.validationError;
    }
    return null;
  };

  function RenderApplyButton() {
    return (
      <Button
        name="Apply"
        id="apply"
        type="button"
        variant="contained"
        backgroundColor={colors["--primary-tealnight"]}
        color={colors["--ui-grey-semi"]}
        width="56px"
        height="32px"
        disabled={!promoCodeEnabled}
        onClick={applyPromoCode}
        data-testid="apply"
      ></Button>
    );
  }
  const classes = useStyles(licenseTheme);

  function GenerateRow(rowData: TableRowData): ReactElement {
    if (!rowData) {
      return <></>;
    }
    return (
      <TableRow className={classes.tableRowNoBorder}>
        <TableCell className={classes.leftCellContent}>
          {rowData.label}
        </TableCell>
        {rowData?.discountVariation ? (
          <TableCell className={classes.rightCellContentDiscount}>
            {rowData.value}
          </TableCell>
        ) : (
          <TableCell className={classes.rightCellContent}>
            {rowData.value}
          </TableCell>
        )}
      </TableRow>
    );
  }
  const renderedMainRows: Array<ReactElement> = [];

  if (props.mainTableArray.length > 0) {
    const mainTableCopy = [...props.mainTableArray];
    // You must specify ! in the assingment to let the compiler know there will be at least one item in the array
    const lastMainRow = mainTableCopy.pop()!;
    mainTableCopy.forEach((item) => {
      renderedMainRows.push(GenerateRow(item));
    });
    renderedMainRows.push(
      <TableRow
        className={classes.tableRowNoBorder1}
        data-testid="tableMainRow"
      >
        <TableCell className={classes.leftCellContent}>
          {lastMainRow.label}
        </TableCell>
        {lastMainRow?.discountVariation ? (
          <TableCell className={classes.rightCellContentDiscount}>
            {lastMainRow.value}
          </TableCell>
        ) : (
          <TableCell className={classes.rightCellContent}>
            {lastMainRow.value}
          </TableCell>
        )}
      </TableRow>
    );
  }

  const renderedSummaryRows: Array<ReactElement> = [];
  if (props.summaryTableArray.length > 0) {
    const summaryTableCopy = [...props.summaryTableArray];

    const lastSummaryRow = summaryTableCopy.pop()!;
    summaryTableCopy.forEach((item) => {
      renderedSummaryRows.push(GenerateRow(item));
    });
    renderedSummaryRows.push(
      <TableRow
        className={classes.lastTableRowNoBorder}
        data-testid="tableSummaryRow"
      >
        <TableCell className={classes.leftCellContent}>
          {lastSummaryRow.label}
        </TableCell>
        {lastSummaryRow?.discountVariation ? (
          <TableCell className={classes.rightCellContentDiscount}>
            {lastSummaryRow.value}
          </TableCell>
        ) : (
          <TableCell className={classes.rightCellContent}>
            {lastSummaryRow.value}
          </TableCell>
        )}
      </TableRow>
    );
  }

  return (
    <div>
      {props.hideDisplayTitle ? (
        <></>
      ) : (
        <Typography className={classes.previewTitle} data-testid="orderSummary">
          {strings.order_summary}
        </Typography>
      )}
      {props.displayDiscountBar ? (
        <div>
          <TextField
            name="promoCode"
            testId="promoCode"
            helpTestId="promoCodeHelp"
            label={strings.Promo}
            value={props.promoCode?.trim().toUpperCase()}
            onChange={updatePromoCode}
            fontFamily={"Source Sans Pro"}
            isRequired={"false"}
            endButton={RenderApplyButton()}
            validationError={props.validationError}
            validation={validatePromoCode}
            data-testid="promoCode"
          />
        </div>
      ) : (
        <></>
      )}
      <TableContainer>
        <Table>
          <TableBody>
            {renderedMainRows}
            {renderedSummaryRows}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
