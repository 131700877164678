import * as React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../../config/colorConfig";
import { boxShadow } from "../../config/commonStyleConfig";

interface LeftRightPaneProps {
  children: React.ReactNode;
  className?: string;
}

interface TwoPanesRespProps {
  children: React.ReactNode;
}

export const LeftPane = (props: LeftRightPaneProps) => {
  const { className, children } = props;
  const styles = makeStyles((theme) => ({
    leftPaneWrapper: {
      height: "100%",
      boxShadow,
      backgroundColor: colors["--text-invert"],
      borderRadius: 4,
      padding: `${theme.spacing(4)}px 0`,
      [theme.breakpoints.down("md")]: {
        background: "none",
        boxShadow: "none",
        paddingLeft: 16,
      },
    },
    gridWrap: {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "0 !important",
      },
    },
  }));
  const classes = styles(props);
  return (
    <Grid item lg={4} md={12} xs={12} classes={{ root: classes.gridWrap }}>
      <div
        data-testid="leftPane"
        className={clsx(classes.leftPaneWrapper, className)}
      >
        {children}
      </div>
    </Grid>
  );
};

export const RightPane = (props: LeftRightPaneProps) => {
  const { className, children } = props;
  const styles = makeStyles((theme) => ({
    rightPaneWrapper: {
      marginTop: theme.spacing(6),
      minHeight: 500,
      [theme.breakpoints.down("md")]: {
        minHeight: "auto",
        marginTop: 0,
      },
    },
    gridWrap: {
      paddingBottom: `${theme.spacing(6)}px !important`,
      paddingTop: "0 !important",
      minHeight: "100vh",
      [theme.breakpoints.down("md")]: {
        paddingTop: "0 !important",
        minHeight: "72vh",
      },
    },
  }));
  const classes = styles(props);
  return (
    <Grid item lg={8} md={12} xs={12} classes={{ root: classes.gridWrap }}>
      <div
        data-testid="rightPane"
        className={clsx(classes.rightPaneWrapper, className)}
      >
        {children}
      </div>
    </Grid>
  );
};

LeftPane.defaultProps = {
  className: null,
};

RightPane.defaultProps = {
  className: null,
};

export const TwoPanesResp = (props: TwoPanesRespProps) => {
  const styles = makeStyles((theme) => ({
    twoPanesContainer: {
      margin: `0 -${theme.spacing(3)}px`,
    },
  }));
  const classes = styles(props);
  const { children } = props;
  return (
    <Grid
      data-testid="twoPanesResp"
      container
      spacing={6}
      className={clsx(classes.twoPanesContainer)}
    >
      {children}
    </Grid>
  );
};
