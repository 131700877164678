import { put, call, CallEffect, PutEffect } from "redux-saga/effects";
import {
  updateEmailPreferenceService,
  getEmailPreferenceService,
} from "../services/emailPreferenceService";
import * as types from "../actions";
import {
  EmailPreference,
  EmailPreferenceAxiosResponse,
} from "../models/EmailPreference";
import { SnackBarType } from "../utils/SnackBarType";
import { strings } from "../utils/Strings";
import { httpConstants } from "../utils/httpConstants";

export function* updateEmailPreferenceSaga(
  emailPreferenceRequest: EmailPreference[]
): Generator<
  | CallEffect<EmailPreferenceAxiosResponse>
  | PutEffect<{
      type: string;
    }>,
  void,
  EmailPreferenceAxiosResponse
> {
  try {
    yield put({ type: types.EMAIL_PREFERENCE_UPDATE_INPROGRESS });
    const response = yield call(
      updateEmailPreferenceService,
      emailPreferenceRequest
    );
    if (!response) {
      yield put({
        type: types.EMAIL_PREFERENCE_UPDATE_ERROR,
        responseStatus: httpConstants.INTERNAL_SERVER_ERROR,
      });
      return;
    }
    if (response.status === httpConstants.OK) {
      yield put({
        type: types.EMAIL_PREFERENCE_UPDATE_SUCCESS,
        responseStatus: response.status,
      });
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: strings.email_preference_update_success,
        messageType: SnackBarType.SUCCESS.displayValue,
      });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    } else {
      yield put({
        type: types.EMAIL_PREFERENCE_UPDATE_ERROR,
        responseStatus: response.status,
      });
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: strings.email_preference_update_failed,
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    }
  } catch (error) {
    yield put({
      type: types.EMAIL_PREFERENCE_UPDATE_ERROR,
      responseStatus: httpConstants.INTERNAL_SERVER_ERROR,
    });
  }
}

export function* getEmailPreferenceSaga(): Generator<
  | CallEffect<EmailPreferenceAxiosResponse>
  | PutEffect<{
      type: string;
    }>,
  void,
  EmailPreferenceAxiosResponse
> {
  try {
    let emailPreferences: EmailPreference[];
    yield put({ type: types.GET_EMAIL_PREFERENCE_INPROGRESS });
    const apiResponse: EmailPreferenceAxiosResponse = yield call(
      getEmailPreferenceService
    );
    if (!apiResponse) {
      yield put({
        type: types.GET_EMAIL_PREFERENCE_ERROR,
        responseStatus: httpConstants.INTERNAL_SERVER_ERROR,
      });
      return;
    }
    if (apiResponse.status === httpConstants.OK) {
      emailPreferences = apiResponse.data;
      yield put({
        type: types.GET_EMAIL_PREFERENCE_SUCCESS,
        emailPreferences,
        responseStatus: apiResponse.status,
      });
    } else {
      yield put({
        type: types.GET_EMAIL_PREFERENCE_ERROR,
        responseStatus: apiResponse.status,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_EMAIL_PREFERENCE_ERROR,
      responseStatus: httpConstants.INTERNAL_SERVER_ERROR,
    });
  }
}
