import React from "react";
import { makeStyles, CircularProgress, Theme } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../../config/colorConfig";

interface SpinnerProps {
  size?: number;
  thickness?: number;
  width?: number;
  height?: number;
  top?: string;
  left?: string;
  marginTop?: number;
  marginLeft?: number;
  id?: string;
  dataTestId?: string;
}
export const Spinner = (props: SpinnerProps) => {
  const buttonStyle = makeStyles((theme: Theme) => ({
    progress: {
      color: colors["--accent-violet"],
      position: "absolute",
      top: props.top ?? "30%",
      left: props.left ?? "45%",
      width: props.width ?? 0,
      height: props.height ?? 0,
      marginTop: props.marginTop ?? 0,
      marginLeft: props.marginLeft ?? 0,
    },
  }));

  const classes = buttonStyle();

  return (
    <CircularProgress
      size={props.size ?? 36}
      thickness={props.thickness ?? 2.5}
      id={props.id ?? ""}
      data-testid={props.dataTestId}
      className={clsx(classes.progress)}
    />
  );
};
