import {
  RESET_ACCOUNT_PROFILE_UPDATE_RESPONSE,
  COUNTRIES_ERROR,
  COUNTRIES_INPROGRESS,
  COUNTRIES_SUCCESS,
  COUNTRIES_SYSTEM_ERROR,
  OCCUPATIONS_ERROR,
  OCCUPATIONS_INPROGRESS,
  OCCUPATIONS_SUCCESS,
  OCCUPATIONS_SYSTEM_ERROR,
  PROMPT_DIALOG,
  RETRIEVE_USER_ERROR,
  RETRIEVE_USER_INPROGRESS,
  RETRIEVE_USER_SUCCESS,
  RETRIEVE_USER_SYSTEM_ERROR,
  SPECIALTIES,
  SPECIALTIES_ERROR,
  SPECIALTIES_INPROGRESS,
  SPECIALTIES_SUCCESS,
  SPECIALTIES_SYSTEM_ERROR,
  SUBSPECIALTIES,
  SUBSPECIALTIES_ERROR,
  SUBSPECIALTIES_INPROGRESS,
  SUBSPECIALTIES_SUCCESS,
  SUBSPECIALTIES_SYSTEM_ERROR,
  TIMEOUT_SNACKBAR,
  UPDATE_BUTTON,
  UPDATE_SYSTEM_ERROR,
  UPDATE_USER_ERROR,
  UPDATE_USER_INPROGRESS,
  UPDATE_USER_SUCCESS,
  DEVICE_MANAGEMENT_ENABLED,
} from "../actions";

const initialState = {
  userProfile_progress: false,
  verify_progress: false,
  validation_done: false,
  system_error: false,
  showSpecialty: false,
  showSubSpecialty: false,
  updateUserCompleted: false,
  device_management_enabled: false,
};

export const accountProfile = function (
  state = initialState,
  action: any
): any {
  const {
    countriesResponse,
    occupationsResponse,
    specialtiesResponse,
    subSpecialtiesResponse,
    accountProfileResponse,
    accountUpdateResponse,
    accountUpdateError,
    isUpdateButtonDisabled,
    promptDialogOpen,
    isDeviceManagementEnabled,
    type,
  } = action;
  switch (type) {
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        accountUpdateResponse,
        userProfile_progress: false,
        updateUserCompleted: true,
        accountUpdateError: null,
      };
    case UPDATE_USER_ERROR:
      return { ...state, accountUpdateError, userProfile_progress: false };
    case UPDATE_USER_INPROGRESS:
      return {
        ...state,
        userProfile_progress: true,
        updateUserCompleted: false,
      };
    case UPDATE_SYSTEM_ERROR:
      return { ...state, userProfile_progress: false, system_error: true };
    case RETRIEVE_USER_SUCCESS:
      return {
        ...state,
        accountProfileResponse,
        verify_progress: false,
        validation_done: true,
        accountUpdateError: null,
      };
    case RETRIEVE_USER_ERROR:
      return {
        ...state,
        accountProfileResponse,
        verify_progress: false,
        validation_done: true,
      };
    case RETRIEVE_USER_INPROGRESS:
      return { ...state, verify_progress: true, validation_done: false };
    case RETRIEVE_USER_SYSTEM_ERROR:
      return {
        ...state,
        verify_progress: false,
        system_error: true,
        validation_done: true,
      };
    case COUNTRIES_SUCCESS:
      return {
        ...state,
        countriesResponse,
        verify_progress: false,
        validation_done: true,
        accountUpdateError: null,
      };
    case COUNTRIES_ERROR:
      return {
        ...state,
        countriesResponse,
        verify_progress: false,
        validation_done: true,
      };
    case COUNTRIES_INPROGRESS:
      return { ...state, verify_progress: true, validation_done: false };
    case COUNTRIES_SYSTEM_ERROR:
      return {
        ...state,
        verify_progress: false,
        system_error: true,
        validation_done: true,
      };
    case OCCUPATIONS_SUCCESS:
      return {
        ...state,
        occupationsResponse,
        verify_progress: false,
        validation_done: true,
        accountUpdateError: null,
      };
    case OCCUPATIONS_ERROR:
      return {
        ...state,
        occupationsResponse,
        verify_progress: false,
        validation_done: true,
      };
    case OCCUPATIONS_INPROGRESS:
      return { ...state, verify_progress: true, validation_done: false };
    case OCCUPATIONS_SYSTEM_ERROR:
      return {
        ...state,
        verify_progress: false,
        system_error: true,
        validation_done: true,
      };
    case SPECIALTIES:
      return {
        ...state,
        specialties_progress: true,
      };
    case SPECIALTIES_SUCCESS:
      return {
        ...state,
        specialtiesResponse,
        verify_progress: false,
        validation_done: true,
        showSpecialty: !(
          !Array.isArray(specialtiesResponse.data) ||
          !specialtiesResponse.data.length ||
          (specialtiesResponse.data.length === 1 &&
            specialtiesResponse.data[0].text === "No Specialty")
        ),
        accountUpdateError: null,
        specialties_progress: false,
      };
    case SPECIALTIES_ERROR:
      return {
        ...state,
        specialtiesResponse,
        verify_progress: false,
        validation_done: true,
        showSpecialty: false,
        showSubSpecialty: false,
        specialties_progress: false,
      };
    case SPECIALTIES_INPROGRESS:
      return {
        ...state,
        verify_progress: true,
        validation_done: false,
        showSpecialty: false,
        showSubSpecialty: false,
      };
    case SPECIALTIES_SYSTEM_ERROR:
      return {
        ...state,
        verify_progress: false,
        system_error: true,
        validation_done: true,
        showSpecialty: false,
        showSubSpecialty: false,
        specialties_progress: false,
      };
    case SUBSPECIALTIES_SUCCESS:
      return {
        ...state,
        subSpecialtiesResponse,
        verify_progress: false,
        validation_done: true,
        showSubSpecialty: !(
          !Array.isArray(subSpecialtiesResponse.data) ||
          !subSpecialtiesResponse.data.length ||
          (subSpecialtiesResponse.data.length === 1 &&
            subSpecialtiesResponse.data[0].text === "No subspecialty")
        ),
        accountUpdateError: null,
        subSpecialties_progress: false,
      };
    case SUBSPECIALTIES:
      return {
        ...state,
        subSpecialties_progress: true,
      };
    case SUBSPECIALTIES_ERROR:
      return {
        ...state,
        subSpecialtiesResponse,
        verify_progress: false,
        validation_done: true,
        showSubSpecialty: false,
        subSpecialties_progress: false,
      };
    case SUBSPECIALTIES_INPROGRESS:
      return {
        ...state,
        verify_progress: true,
        validation_done: false,
        showSubSpecialty: false,
      };
    case SUBSPECIALTIES_SYSTEM_ERROR:
      return {
        ...state,
        verify_progress: false,
        system_error: true,
        validation_done: true,
        showSubSpecialty: false,
        subSpecialties_progress: false,
      };
    case TIMEOUT_SNACKBAR:
      return { ...state, autoHideDuration: 15000 };
    case PROMPT_DIALOG:
      return { ...state, promptDialogOpen };
    case UPDATE_BUTTON:
      return { ...state, isUpdateButtonDisabled };
    case RESET_ACCOUNT_PROFILE_UPDATE_RESPONSE:
      return {
        ...state,
        accountUpdateResponse: null,
        updateUserCompleted: false,
      };
    case DEVICE_MANAGEMENT_ENABLED:
      return {
        ...state,
        device_management_enabled: isDeviceManagementEnabled,
      };
    default:
      return state;
  }
};
