import React from "react";
import {
  makeStyles,
  DialogContentText,
  Link,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../config/colorConfig";
import { strings } from "../utils/Strings";
import { Button } from "./custom/Button";
import { config } from "../config/envConfig";
import { SubscriptionDialog } from "./SubscriptionDialog";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import {
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_NEVERMIND_QUESTIONS,
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CLOSE,
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CONTINUE,
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CONTACT_SUPPORT,
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FEEDBACK_VIEW,
} from "../utils/analytics";
import { SubscriptionCancellationFeedback } from "./SubscriptionCancellationFeedback";

interface SubscriptionCancelFirstFlowProps {
  subscriptionId: string;
  open: boolean;
  sku: string;
  setCancelFirstflowDialog: (cancelFirstflowDialog: boolean) => void;
  subscriptionEndDate: string;
}

export const SubscriptionCancellationFirstFlow = (
  props: SubscriptionCancelFirstFlowProps
): JSX.Element => {
  const dialogBoxStyles = makeStyles((theme) => ({
    contentAlignment: {
      margin: `${theme.spacing(4)}px ${theme.spacing(6)}px 0px ${theme.spacing(
        6
      )}px`,
    },
    popUpText: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "28px",
      color: colors["--ui-midnight"],
    },
    popUpSubText: {
      fontSize: "16px",
      color: colors["--ui-slate"],
    },

    cancelLink: {
      color: `${colors["--primary-teal"]}`,
      fontWeight: 500,
      fontSize: "16px",
      whiteSpace: "nowrap",
      "&:hover": {
        textDecoration: "none",
        color: `${colors["--primary-teal"]}`,
      },
    },
    cancelSubtext: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      color: colors["--primary-midnight"],
    },
    formGroup: {
      marginTop: `${theme.spacing(2)}px`,
    },
    noCancel: {
      marginTop: `${theme.spacing(4)}px`,
      backgroundColor: `${colors["--text-invert"]}`,
      color: `${colors["--primary-midnight"]}`,
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 500,
      border: `solid 1px ${colors["--ui-slate"]}`,
      "&:disabled": {
        backgroundColor: `${colors["--ui-grey-semi"]}`,
        color: `${colors["--ui-grey-med"]}`,
        border: "none",
      },
    },
    switchColor: {
      "&.Mui-checked": {
        color: `${colors["--primary-teal"]}`,
      },
    },
    formControl: {
      height: 28,
    },
    ButtonRow: {
      display: "flex",
      alignItems: "left",
      marginTop: `${theme.spacing(4)}px`,
    },
    subFormButtonFields: {
      flexGrow: 0,
      minWidth: 180,
      marginRight: `${theme.spacing(2)}px`,
    },
    contactLink: {
      marginTop: `${theme.spacing(4)}px`,
    },
    surveyDisclaimer: {
      fontStyle: "italic",
      marginBottom: `${theme.spacing(4)}px`,
    },
  }));

  const [continueProgress, setContinueProgress] = React.useState(false);
  const [cancelFeedBackModal, setCancelFeedBackModal] = React.useState(false);
  const cancelReason = React.useRef("");

  const handleClose = () => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CLOSE,
      {
        sku: props.sku,
      }
    );
    props.setCancelFirstflowDialog(false);
    setContinueProgress(false);
  };
  const handleNevermindEvent = () => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_NEVERMIND_QUESTIONS,
      {
        sku: props.sku,
      }
    );
    props.setCancelFirstflowDialog(false);
    setContinueProgress(false);
  };
  const handleCancelSupportEvent = () => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CONTACT_SUPPORT,
      {
        sku: props.sku,
      }
    );
    props.setCancelFirstflowDialog(false);
    setContinueProgress(false);
  };

  const handleContinue = (event: React.MouseEvent<HTMLButtonElement>) => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CONTINUE,
      {
        sku: props.sku,
      }
    );
    props.setCancelFirstflowDialog(false);
    setContinueProgress(true);
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FEEDBACK_VIEW,
      {
        sku: props.sku,
      }
    );
    setCancelFeedBackModal(true);
    setContinueProgress(false);
  };

  const handleSubmit = async (
    formEvent: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    formEvent.preventDefault();
    cancelReason.current = "";
    for (const element of formEvent.currentTarget.elements) {
      if (element instanceof HTMLInputElement) {
        if (element.checked) {
          cancelReason.current =
            cancelReason.current + ";" + strings.getString(element.name);
        }
      }
    }
  };
  const classes = dialogBoxStyles();
  const { supportUrl } = config.environment;
  const cancelFirstFlow = (
    <SubscriptionDialog
      open={props.open}
      setClose={handleClose}
      handleCloseEvent={handleClose}
      width={600}
      isTitleLarge
      title={strings.cancel_subscription}
    >
      <div className={clsx(classes.contentAlignment)}>
        <form onSubmit={handleSubmit}>
          <DialogContentText
            classes={{ root: clsx(classes.popUpText) }}
            id="alert-dialog-description"
            data-testid="alert-dialog-description"
          >
            {strings.surveyTitle}
          </DialogContentText>
          <DialogContentText
            classes={{ root: clsx(classes.popUpSubText) }}
            id="alert-dialog-description"
          >
            <span
              data-testid="alert-dialog-subtitle"
              className={clsx(classes.cancelSubtext)}
            >
              {strings.surveySubtitle}
            </span>
            <FormGroup className={clsx(classes.formGroup)}>
              <FormControlLabel
                className={clsx(classes.formControl)}
                control={
                  <Checkbox
                    name="surveyCheckbox1"
                    classes={{
                      colorSecondary: clsx(classes.switchColor),
                    }}
                    data-testid="surveyCheckbox1"
                  />
                }
                label={<Typography>{strings.surveyCheckbox1}</Typography>}
              />
              <FormControlLabel
                className={clsx(classes.formControl)}
                control={
                  <Checkbox
                    name="surveyCheckbox2"
                    classes={{
                      colorSecondary: clsx(classes.switchColor),
                    }}
                    data-testid="surveyCheckbox2"
                  />
                }
                label={<Typography>{strings.surveyCheckbox2}</Typography>}
              />
              <FormControlLabel
                className={clsx(classes.formControl)}
                control={
                  <Checkbox
                    name="surveyCheckbox3"
                    classes={{
                      colorSecondary: clsx(classes.switchColor),
                    }}
                    data-testid="surveyCheckbox3"
                  />
                }
                label={<Typography>{strings.surveyCheckbox3}</Typography>}
              />
              <FormControlLabel
                className={clsx(classes.formControl)}
                control={
                  <Checkbox
                    name="surveyCheckbox4"
                    classes={{
                      colorSecondary: clsx(classes.switchColor),
                    }}
                    data-testid="surveyCheckbox4"
                  />
                }
                label={<Typography>{strings.surveyCheckbox4}</Typography>}
              />
              <FormControlLabel
                className={clsx(classes.formControl)}
                control={
                  <Checkbox
                    name="surveyCheckbox5"
                    classes={{
                      colorSecondary: clsx(classes.switchColor),
                    }}
                    data-testid="surveyCheckbox5"
                  />
                }
                label={<Typography>{strings.surveyCheckbox5}</Typography>}
              />
              <FormControlLabel
                className={clsx(classes.formControl)}
                control={
                  <Checkbox
                    name="surveyCheckbox6"
                    classes={{
                      colorSecondary: clsx(classes.switchColor),
                    }}
                    data-testid="surveyCheckbox6"
                  />
                }
                label={<Typography>{strings.surveyCheckbox6}</Typography>}
              />
              <FormControlLabel
                className={clsx(classes.formControl)}
                control={
                  <Checkbox
                    name="surveyCheckbox7"
                    classes={{
                      colorSecondary: clsx(classes.switchColor),
                    }}
                    data-testid="surveyCheckbox7"
                  />
                }
                label={<Typography>{strings.surveyCheckbox7}</Typography>}
              />
            </FormGroup>
          </DialogContentText>

          <div className={clsx(classes.ButtonRow)}>
            <div className={clsx(classes.subFormButtonFields)}>
              <Button
                name="Continue"
                id="continue"
                type="submit"
                fontFamily="Metric"
                onClick={handleContinue}
                inProgress={continueProgress}
                data-testid="continue"
              />
            </div>
            <div className={clsx(classes.subFormButtonFields)}>
              <Button
                name="Never Mind"
                id="nevermind"
                type="button"
                fontFamily="Metric"
                onClick={handleNevermindEvent}
                backgroundColor={`${colors["--text-invert"]}`}
                foregroundColor={`${colors["--ui-slate"]}`}
                hoverForeground={`${colors["--text-invert"]}`}
                data-testid="nevermind"
              />
            </div>
          </div>
        </form>
        <div className={clsx(classes.contactLink)}>
          <span>{strings.cancel_subText}</span>
          <Link
            classes={{
              root: clsx(classes.cancelLink),
            }}
            onClick={() => {
              handleCancelSupportEvent();
            }}
            href={supportUrl}
            data-testid="cancelSupportLink"
          >
            {strings.contact_support}
          </Link>
        </div>
        <div className={clsx(classes.surveyDisclaimer)}>
          <span>{strings.surveyDisclaimer}</span>
        </div>
      </div>
    </SubscriptionDialog>
  );
  return (
    <>
      {cancelFirstFlow}
      <SubscriptionCancellationFeedback
        open={cancelFeedBackModal}
        setCancelFeedBackModal={setCancelFeedBackModal}
        subscriptionId={props.subscriptionId}
        cancelReasons={cancelReason.current}
        subscriptionEndDate={props.subscriptionEndDate}
        sku={props.sku}
      />
    </>
  );
};
