import {
  usTerritories,
  usCountryCode,
  euCountryCodes,
  isUsTerritoryEnabled,
} from "../config/accountCreationConfig";

export const isUSTerritory = (countryCode = "") => {
  return usTerritories.includes(countryCode);
};

export const isUSUser = (countryCode = "") => {
  return countryCode === usCountryCode;
};

export const isInternationalUser = (countryCode = "") =>
  isUsTerritoryEnabled ? !isUSTerritory(countryCode) : !isUSUser(countryCode);

export const isZipCodeEnabled = (countryCode = "") => isUSUser(countryCode);

export const isEUCountry = (countryCode = "") =>
  euCountryCodes.indexOf(countryCode) !== -1;

export const isNpiVisible = (countryCode = "", occupation = "") =>
  isUSUser(countryCode) && (occupation === "MD" || occupation === "DO");
