import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import clsx from "clsx";
import { default as dayjs } from "dayjs";
import { colors } from "../../config/colorConfig";
import {
  CLICK_PRIVACY_POLICY,
  CLICK_TERMS_OF_USE,
  CLICK_PRIVACY_POLICY_EVENT_ID,
  CLICK_TERMS_OF_USE_EVENT_ID,
} from "../../utils/analytics";
import { sendAmplitudeEventData } from "../../utils/AmplitudeUtil";
import { CURRENT_USER_NAME } from "../../utils/localStorageKeys";

const footer = () => {
  const footerStyle = makeStyles((theme) => ({
    box: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(3),
    },
    footerText: {
      fontFamily: "Source Sans Pro",
      fontSize: 16,
      color: colors["--text-invert"],
      paddingLeft: "8px",
      paddingRight: "8px",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    footerLinks: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        marginTop: "8px",
      },
    },
  }));
  const classes = footerStyle();
  const onPrivacyPolicyClick = () => {
    const email = localStorage.getItem(CURRENT_USER_NAME);
    sendAmplitudeEventData(
      email,
      CLICK_PRIVACY_POLICY,
      CLICK_PRIVACY_POLICY_EVENT_ID
    );
  };

  const onTermsOfUserClick = () => {
    const email = localStorage.getItem(CURRENT_USER_NAME);
    sendAmplitudeEventData(
      email,
      CLICK_TERMS_OF_USE,
      CLICK_TERMS_OF_USE_EVENT_ID
    );
  };
  return (
    <Box
      zIndex="app bar"
      color={colors["--text-invert"]}
      position="flex"
      bottom="0%"
      className={clsx(classes.box)}
    >
      <div className={clsx(classes.footerText)}>
        © {dayjs(new Date()).format("YYYY")} epocrates, Inc.&nbsp;&nbsp;&nbsp;
        <span className={clsx(classes.footerLinks)}>
          <a
            className={clsx(classes.footerText)}
            onClick={onTermsOfUserClick}
            data-testid="termsofuse"
            href="https://www.epocrates.com/termsofuse"
          >
            Terms of Use
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            className={clsx(classes.footerText)}
            onClick={onPrivacyPolicyClick}
            data-testid="privacy"
            href="https://www.epocrates.com/privacy"
          >
            Privacy Policy
          </a>
        </span>
      </div>
    </Box>
  );
};
export const QRFooter = React.memo(footer);
