import makeStyles from "@material-ui/core/styles/makeStyles";
import { colors } from "../config/colorConfig";

export const appStyle = makeStyles((theme) => ({
  ErrorDividerVertical: {
    width: "6px",
    height: "76px",
    borderRadius: "3px",
    backgroundColor: colors["--error"],
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
  },
  InfoDividerVertical: {
    width: "6px",
    height: "76px",
    borderRadius: "3px",
    backgroundColor: colors["--accent-jellyfish"],
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
  },
  SuccessDividerVertical: {
    width: "6px",
    height: "76px",
    borderRadius: "3px",
    backgroundColor: colors["--success"],
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
  },
  MuiListItemIcon_root: {
    minWidth: "20px !important",
  },
  MuiListItem_root: {
    padding: "0px !important",
  },
  MuiList_root: {
    padding: "0px !important",
  },
  MuiFormControl_root: {
    marginTop: "4px !important",
  },
}));
