export const CREATE_NEW_PASSWORD_SUBMIT_EVENT_ID = "taxo353.0";
export const FORGOT_PASSWORD_RESET_ERROR_EVENT_ID = "taxo354.0";
export const FORGOT_PASSWORD_EMAIL_SUBMIT_EVENT_ID = "taxo269.0";
export const FORGOT_PASSWORD_EMAIL_SENT_EVENT_ID = "taxo270.0";
export const CLICK_SIGN_IN_EVENT_ID = "taxo10.0";
export const FAIL_SIGN_IN_EVENT_ID = "taxo14.0";
export const SUCCESSFULLY_SIGN_IN_EVENT_ID = "taxo15.0";
export const CLICK_EMAIL_ADDRESS_EVENT_ID = "taxo11.0";
export const CLICK_PASSWORD_EVENT_ID = "taxo12.0";
export const CLICK_PRIVACY_POLICY_EVENT_ID = "taxo16.0";
export const CLICK_TERMS_OF_USE_EVENT_ID = "taxo17.0";
export const CLICK_FORGOT_PASSWORD_EVENT_ID = "taxo268.0";
export const CLICK_SIGNUP_LINK_EVENT_ID = "taxo277.0";
export const DOWNLOAD_STORE_BADGE_CLICK_EVENT_ID = "taxo877.0";
export const CME_WEBLAUNCH_ATTEMPT_EVENT_ID = "taxo883.0";
export const CME_WEBLAUNCH_REDIRECT_EVENT_ID = "taxo884.0";
export const CME_WEBLAUNCH_FAIL_EVENT_ID = "taxo885.0";
export const CME_PROFILE_UPDATE_FORM_VIEW_EVENT_ID = "taxo886.0";
export const CME_PROFILE_UPDATE_FORM_CLOSE_EVENT_ID = "taxo887.0";
export const CME_PROFILE_UPDATE_FORM_ERROR_EVENT_ID = "taxo888.0";
export const CREATE_ACCOUNT_CONTINUE_CLICK_EVENT_ID = "taxo848.0";
export const CREATE_ACCOUNT_GRADUATION_YEAR_CLICK_EVENT_ID = "taxo287.0";
export const CREATE_ACCOUNT_COUNTRY_CLICK_EVENT_ID = "taxo843.0";
export const CREATE_ACCOUNT_ZIP_CODE_CLICK_EVENT_ID = "taxo842.0";

export const CREATE_ACCOUNT_NPI_NUMBER_CLICK_EVENT_ID = "taxo286.0";
export const CREATE_ACCOUNT_NPI_RESULT_SELECT_EVENT_ID = "taxo291.0";
export const CREATE_ACCOUNT_NPI_RESULT_VIEW_EVENT_ID = "taxo292.0";
export const CREATE_ACCOUNT_SPECIALTY_VIEW_EVENT_ID = "taxo422.0";

export const ACCOUNT_PROFILE_UPDATE_CLICK_EVENT_ID = "taxo863.0";
export const ACCOUNT_PROFILE_UPDATE_SUCCESS_EVENT_ID = "taxo865.0";
export const ACCOUNT_PROFILE_UPDATE_FAIL_EVENT_ID = "taxo864.0";

export const CREATE_ACCOUNT_NPI_NUMBER_CLICK =
  "Authentication - Create Account - NPI Number - Click";
export const CREATE_ACCOUNT_NPI_RESULT_SELECT =
  "Authentication - Create Account - NPI Result - Select";
export const CREATE_ACCOUNT_NPI_RESULT_VIEW =
  "Authentication - Create Account - NPI Result - View";
export const CREATE_ACCOUNT_SPECIALTY_VIEW =
  "Authentication - Create Account - Specialty - View";
export const CREATE_ACCOUNT_INITIAL_VIEW_EVENT_ID = "taxo293.0";
export const CREATE_ACCOUNT_EMAIL_ADDRESS_CLICK_EVENT_ID = "taxo274.0";
export const CREATE_ACCOUNT_FIRST_NAME_CLICK_EVENT_ID = "taxo272.0";
export const CREATE_ACCOUNT_LAST_NAME_CLICK_EVENT_ID = "taxo273.0";
export const CREATE_ACCOUNT_PASSWORD_CLICK_EVENT_ID = "taxo276.0";
export const CREATE_ACCOUNT_PRIVACY_POLICY_CLICK_EVENT_ID = "taxo16.0";
export const CREATE_ACCOUNT_TERMS_OF_USE_CLICK_EVENT_ID = "taxo17.0";
export const CREATE_ACCOUNT_ERROR_EVENT_ID = "taxo282.0";
export const CREATE_ACCOUNT_SUCCESS_EVENT_ID = "taxo283.0";

export const CREATE_NEW_PASSWORD_SUBMIT =
  "Authentication - Reset Password - Create new password";
export const CREATE_NEW_PASSWORD_ERROR =
  "Authentication - Reset Password - Error";
export const FORGOT_PASSWORD_RESET_ERROR =
  "Authentication - Forgot Password - Reset - Error";
export const FORGOT_PASSWORD_EMAIL_SUBMIT =
  "Authentication - Forgot Password - Reset - Submit";
export const FORGOT_PASSWORD_EMAIL_SENT =
  "Authentication - Forgot Password - Reset - Email Sent";

export const FAIL_SIGN_IN = "Authentication - Sign In - Fail";
export const SUCCESSFULLY_SIGN_IN = "Authentication - Sign In - Success";
export const CLICK_EMAIL_ADDRESS =
  "Authentication - Sign In - Email Address - Click";
export const CLICK_PASSWORD = "Authentication - Sign In - Password - Click";
export const CLICK_PRIVACY_POLICY =
  "Authentication - Sign In - Privacy Policy - Click";
export const CLICK_SIGN_IN = "Authentication - Sign In - Open";
export const CLICK_TERMS_OF_USE =
  "Authentication - Sign In - Terms of Use - Click";
export const CLICK_FORGOT_PASSWORD =
  "Authentication - Sign In - Forgot Password - Click";
export const CLICK_SIGNUP_LINK = "Authentication - Sign In - Sign up - Click";
export const CANCEL_SIGN_IN = "Authentication - Sign In - Cancel - Click";
export const CANCEL_SIGN_IN_EVENT_ID = "taxo18.0";
export const CLICK_CREATE_ACCOUNT = "Web - Register - Click";

export const SOCKET_CONNECT_ERROR =
  "Authentication - Sign In - Connect Socket - Error";

export const DOWNLOAD_STORE_BADGE_CLICK = "Download - Store Badge - Click";

export const CME_WEBLAUNCH_ATTEMPT = "CME - Web Launch - Attempt";
export const CME_WEBLAUNCH_REDIRECT = "CME - Web Launch - Redirect";
export const CME_WEBLAUNCH_FAIL = "CME - Web Launch - Fail";
export const CME_PROFILE_UPDATE_FORM_VIEW = "CME - Profile Update Form - View";
export const CME_PROFILE_UPDATE_FORM_CLOSE =
  "CME - Profile Update Form - Close";
export const CME_PROFILE_UPDATE_FORM_ERROR =
  "CME - Profile Update Form - Error";

export const CREATE_ACCOUNT_CONTINUE_CLICK =
  "Authentication - Create Account - Continue - Click";
export const CREATE_ACCOUNT_GRADUATION_YEAR_CLICK =
  "Authentication - Create Account - Expected Graduation Year - Click";
export const CREATE_ACCOUNT_COUNTRY_CLICK =
  "Authentication - Create Account - Country - Click";
export const CREATE_ACCOUNT_ZIP_CODE_CLICK =
  "Authentication - Create Account - Zip Code - Click";
export const CREATE_ACCOUNT_INITIAL_VIEW =
  "Authentication - Create Account - Account Creation - View";
export const CREATE_ACCOUNT_EMAIL_ADDRESS_CLICK =
  "Authentication - Create Account - Email Address - Click";
export const CREATE_ACCOUNT_FIRST_NAME_CLICK =
  "Authentication - Create Account - First Name - Click";
export const CREATE_ACCOUNT_LAST_NAME_CLICK =
  "Authentication - Create Account - Last Name - Click";
export const CREATE_ACCOUNT_PASSWORD_CLICK =
  "Authentication - Create Account - Password - Click";
export const CREATE_ACCOUNT_PRIVACY_POLICY_CLICK =
  "Authentication - Create Account - Privacy Policy - Click";
export const CREATE_ACCOUNT_TERMS_OF_USE_CLICK =
  "Authentication - Create Account - Terms of Use - Click";
export const CREATE_ACCOUNT_ERROR = "Authentication - Create Account - Fail";
export const CREATE_ACCOUNT_SUCCESS =
  "Authentication - Create Account - Success";
export const EMAIL_PREFERENCE_UNSUBSCRIBE_ALL =
  "Account - Email Preferences - Unsubscribe from all - Click";
export const EMAIL_PREFERENCE_UNSUBSCRIBE_ALL_EVENT_ID = "taxo861.0";
export const EMAIL_PREFERENCE_UPDATE =
  "Account - Email Preferences - Update- Click";
export const EMAIL_PREFERENCE_UPDATE_EVENT_ID = "taxo862.0";
export const EMAIL_LOGIN_SCREEN_VIEW =
  "Authentication - Email Login Screen - View";

export const ACCOUNT_PROFILE_UPDATE_CLICK =
  "Account - Profile - Update - Click";
export const ACCOUNT_PROFILE_UPDATE_SUCCESS =
  "Account - Profile - Update - Success";
export const ACCOUNT_PROFILE_UPDATE_FAIL = "Account - Profile - Update - Fail";
export const ACCOUNT_CHANGE_PASSWORD_SUCCESS =
  "Account - Change Password - Success";
export const ACCOUNT_CHANGE_PASSWORD_SUCCESS_EVENT_ID = "Taxo811.0";
export const ACCOUNT_CHANGE_PASSWORD_FAILURE =
  "Account - Change Password - Failure";
export const ACCOUNT_CHANGE_PASSWORD_FAILURE_EVENT_ID = "Taxo812.0";

export const ACCOUNT_CHANGE_EMAIL_SUCCESS = "Account - Change Email - Success";
export const ACCOUNT_CHANGE_EMAIL_SUCCESS_EVENT_ID = "Taxo809.0";
export const ACCOUNT_CHANGE_EMAIL_FAILURE = "Account - Change Email - Failure";
export const ACCOUNT_CHANGE_EMAIL_FAILURE_EVENT_ID = "Taxo810.0";
export const ACCOUNT_BANNER_VIEW = "Account - Profile - Header Banner - View";
export const ACCOUNT_BANNER_VIEW_EVENT_ID = "Taxo1200.0";

export const ACCOUNT_BANNER_LEARN_MORE_CLICK =
  "Account - Profile - Header Banner - Learn More - Click";
export const ACCOUNT_BANNER_LEARN_MORE_CLICK_EVENT_ID = "Taxo1201.0";

export const ACCOUNT_BANNER_CLOSE_CLICK =
  "Account - Profile - Header Banner - Close - Click";
export const ACCOUNT_BANNER_CLOSE_CLICK_EVENT_ID = "Taxo1202.0";

export const ACCOUNT_NAVIGATION_TAB_CLICK = "Account - Navigation Tab - Click";
export const ACCOUNT_NAVIGATION_TAB_CLICK_EVENT_ID = "Taxo1203.0";

export const ACCOUNT_CHANGE_PASSWORD_CLICK =
  "Account - Profile - Change Password - Click";
export const ACCOUNT_CHANGE_PASSWORD_CLICK_EVENT_ID = "Taxo1207.0";

export const ACCOUNT_CHANGE_PASSWORD_SUBMIT_CLICK =
  "Account - Profile - Change Password - Change Password - Click";
export const ACCOUNT_CHANGE_PASSWORD_SUBMIT_CLICK_EVENT_ID = "Taxo1208.0";

export const ACCOUNT_CHANGE_PASSWORD_CANCEL_CLICK =
  "Account - Profile - Change Password - Cancel - Click";
export const ACCOUNT_CHANGE_PASSWORD_CANCEL_CLICK_EVENT_ID = "Taxo1209.0";

export const ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER =
  "Account - Profile - Updated Successfully Banner - View";
export const ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER_EVENT_ID = "Taxo1210.0";

export const ACCOUNT_CHANGE_EMAIL_CLICK =
  "Account - Profile - Change Email - Click";
export const ACCOUNT_CHANGE_EMAIL_CLICK_EVENT_ID = "Taxo1204.0";

export const ACCOUNT_CHANGE_EMAIL_SUBMIT_CLICK =
  "Account - Profile - Change Email - Change Email - Click";
export const ACCOUNT_CHANGE_EMAIL_SUBMIT_CLICK_EVENT_ID = "Taxo1205.0";

export const ACCOUNT_CHANGE_EMAIL_CANCEL_CLICK =
  "Account - Profile - Change Email - Cancel - Click";
export const ACCOUNT_CHANGE_EMAIL_CANCEL_CLICK_EVENT_ID = "Taxo1206.0";

export const CREATE_ACCOUNT_NPI_SAVE_SUCCESS =
  "Authentication - Create Account - NPI Save - Success";
export const CREATE_ACCOUNT_NPI_SAVE_SUCCESS_EVENT_ID = "Taxo446.0";

export const CREATE_ACCOUNT_NPI_SAVE_FAILURE =
  "Authentication - Create Account - NPI Save - Fail";
export const CREATE_ACCOUNT_NPI_SAVE_FAILURE_EVENT_ID = "Taxo447.0";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CLICK =
  "Account - Payment and Subscriptions - Cancel Subscription - Click";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CONFIRMATION_VIEW =
  "Account - Payment and Subscriptions - Cancel Subscription - Confirmation - View";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CONFIRMATION_CLOSE =
  "Account - Payment and Subscriptions - Cancel Subscription - Confirmation - Close - Click";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CONFIRMATION_NEVERMIND =
  "Account - Payment and Subscriptions - Cancel Subscription - Confirmation - Never mind - Click";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CONFIRMATION_YES =
  "Account - Payment and Subscriptions - Cancel Subscription - Confirmation - Yes - Click";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCELLATION_ERROR =
  "Account - Payment and Subscriptions - Cancel Subscription - Cancellation - Error";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCELLATION_SUCCESS =
  "Account - Payment and Subscriptions - Cancel Subscription - Cancelation - Success";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_CLICK =
  "Account - Payment and Subscriptions - Update Payment Method - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_NEVERMIND_QUESTIONS =
  "Account - Payment and Subscriptions - Cancel Subscription  - Cancellation Reason Question - Never Mind - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CLOSE =
  "Account - Payment and Subscriptions - Cancel Subscription  - Cancellation Reason Question - Close - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CONTINUE =
  "Account - Payment and Subscriptions - Cancel Subscription - Cancellation Reason Question - Continue - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CANCEL_CLICK_QUESTIONS_CONTACT_SUPPORT =
  "Account - Payment and Subscriptions - Cancel Subscription  - Cancellation Reason Question - Contact Support - Click";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FEEDBACK_VIEW =
  "Account - Payment and Subscriptions - Cancel Subscription - Cancellation Reason Question - Feedback - View";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FEEDBACK_CONTINUE =
  "Account - Payment and Subscriptions - Cancel Subscription - Cancellation Reason Question - Feedback - Continue - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FINAL_CONFIRMATION =
  "Account - Payment and Subscriptions - Cancel Subscription - Cancellation Reason Question - Final Confirmation - Cancel Subscription - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FINAL_CONFIRMATION_SUCCESS =
  "Account - Payment and Subscriptions - Cancel Subscription - Cancelation - Success";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_FINAL_CONFIRMATION_NEVER_MIND =
  "Account - Payment and Subscriptions - Cancel Subscription - Cancellation Reason Question - Final Confirmation - Never Mind - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_CLOSE_CANCEL =
  "Account - Payment and Subscriptions - Update Payment Method - Close or Cancel - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_SAVE_CHANGES =
  "Account - Payment and Subscriptions - Update Payment Method - Save Changes - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_VIEW =
  "Account - Payment and Subscriptions - Upgrade Subscription - View";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CONTINUE_CLICK =
  "Account - Payment and Subscriptions - Upgrade Subscription - Continue - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CONFIRM_PAYMENT_CLICK =
  "Account - Payment and Subscriptions - Upgrade Subscription - Payment Confirmation - Confirm Payment - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CLOSE_CLICK =
  "Account - Payment and Subscriptions - Upgrade Subscription - Close - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CONFIRM_CLOSE_CLICK =
  "Account - Payment and Subscriptions - Upgrade Subscription - Payment Confirmation - Close - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CONFIRM_BACK_CLICK =
  "Account - Payment and Subscriptions - Upgrade Subscription - Payment Confirmation - Back - Click";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CLICK_MONTHLY =
  "Account - Payment and Subscriptions - Upgrade Subscription - Select Monthly Option - Click";
export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CLICK_YEARLY =
  "Account - Payment and Subscriptions - Upgrade Subscription - Select Yearly Option - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UNSUCCESSFUL =
  "Account - Payment and Subscriptions - Upgrade Unsuccessful";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_CONTINUE_CLICK =
  "Account - Payment and Subscriptions - Continue - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_INVALID_PROMO_CODE =
  "Account - Payment and Subscriptions - Upgrade Continue - Invalid Promo Code";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_PROMO_CODE =
  "Account - Payment and Subscriptions - Upgrade Continue - Promo Code";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_VIEW =
  "Account - Payment and Subscriptions - View";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_DURATION_TOGGLE_CLICK =
  "Account - Payment and Subscriptions - Duration Toggle - Click";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_UNSUCCESSFUL =
  "Account - Payment and Subscriptions - Update Payment Method - Update Unsuccessful";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_SUCCESSFUL =
  "Account - Payment and Subscriptions - Update Payment Method - Update Successful";

export const ACCOUNT_PAYMENT_SUBSCRIPTION_PAGE_LOAD_ERROR =
  "Account - Payment and Subscriptions - Page load error";

export const SUBSCRIPTION_PAYMENT_UPGRADE_SCREEN_PAYMENT_FAIL =
  "Subscription Payment - Upgrade Screen - Payment Complete - Fail";

export const SUBSCRIPTION_PAYMENT_UPGRADE_SCREEN_PAYMENT_SUCCESS =
  "Subscription Payment - Upgrade Screen - Payment Complete - Success";

export const EPOC_CORE_WEB_OPTIMIZELY_CLIENT_ERROR =
  "epoc-core-web: Optimizely Client Error";

export const WEB_ERROR_TRACK = "Web - Error - Track";

export const GROUP_LICENSING_LEARN_MORE_CLICK =
  "Account - Payment and Subscriptions - Upgrade Subscription - Group Licensing - Learn More - Click";
export const GROUP_LICENSING_FORM_VIEW =
  "Group Licensing - Purchase Form - View";
export const GROUP_LICENSING_SELECT_SUBSCRIPTIONS_CLICK =
  "Group Licensing - Purchase Form - Select Subscription - Continue - Click";
export const GROUP_LICENSING_ORDER_INFORMATION_CLICK =
  "Group Licensing - Purchase Form - Order Information - Continue - Click";
export const GROUP_LICENSING_FORM_CREATE_ACCOUNT_CLICK =
  "Group Licensing - Purchase Form - Finish Creating Account - Click";
export const GROUP_LICENSING_REDEEM_CODE_CLICK =
  "Group Licensing - Purchase Form - Redeem Code - Click";
export const GROUP_LICENSING_PAYMENT_INFO_CLICK =
  "Group Licensing - Purchase Form - Enter Payment Info - Click";
export const GROUP_LICENSING_PAYMENT_CONFIRMATION_CLICK =
  "Group Licensing - Purchase Form - Confirm Payment - Click";
export const GROUP_LICENSING_PAYMENT_SUCCESS =
  "Group Licensing - Purchase Form - Purchase Successful";
export const GROUP_LICENSING_PAYMENT_FAILURE =
  "Group Licensing - Purchase Form - Purchase Failure";
export const GROUP_LICENSING_CONTACT_SOON_VIEW =
  "Group Licensing - Purchase Form - Custom Plan Request Confirmation - View";
export interface GroupLicenseSegmentPayload {
  "Purchase Type"?: "Group Licensing" | "Group License Redemption";
  "Purchase Quantity"?: number;
  "Institution Name"?: string;
  "Group Code"?: string;
  "Contact Email"?: string;
  "Contact Name"?: string;
  Price?: number;
  Failure?: boolean;
  Error?: string;
}
export const GROUP_LICENSING_REDEMPTION_FORM_VIEW =
  "Group Licensing - Redemption Form - View";
export const GROUP_LICENSING_REDEMPTION_FORM_REDEEM_CLICK =
  "Group Licensing - Redemption Form - Redeem - Click";

export const GROUP_LICENSING_REDEMPTION_FORM_REDEMPTION_SUCCESSFUL =
  "Group Licensing - Redemption Form - Redemption Successful";

export const GROUP_LICENSING_REDEMPTION_FORM_REDEMPTION_FAILURE =
  "Group Licensing - Redemption Form - Redemption Failure";
export const Authentication_Landing_Screen_OTC_Sign_In_Click =
  "Authentication - Landing Screen - OTC - Sign In - Click";
export const Authentication_Landing_Screen_Email_Sign_In_Click =
  "Authentication - Landing Screen - Email - Sign In - Click";
export const Authentication_OTC_Login_Screen_View =
  "Authentication - OTC Login Screen - View";
export const Authentication_OTC_Login_Screen_Send_Code_Click =
  "Authentication - OTC Login Screen - Send Code - Click";
export const Authentication_OTC_Login_Screen_Cancel_Click =
  "Authentication - OTC Login Screen - Cancel - Click";
export const Authentication_OTC_Code_Screen_View =
  "Authentication - OTC Code Screen - View";
export const Authentication_OTC_Code_Screen_Resend_Code_Click =
  "Authentication - OTC Code Screen - Resend Code - Click";
export const Authentication_OTC_Code_Screen_Sign_In_Click =
  "Authentication - OTC Code Screen - Sign In - Click";
export const Authentication_OTC_Code_Screen_Cancel_Click =
  "Authentication - OTC Code Screen - Cancel - Click";
export const API_Failure = "API - Failure";

export const DEVICE_LANDING_PAGE_VIEW = "Devices - Landing Page - View";
export const DEVICE_SIGNOUT_CANCEL = "Devices - Sign Out - Cancel - Click";
export const TOOLTIP_OPEN = "Tooltip - Open";
export const TOOLTIP_DISMISSAL_EVENT = "Tooltip Dismissal Event";
export const PROFILE_SIGN_OUT_CONFIRMATION_CLICK =
  "Profile - Sign Out - Confirmation - Click";
export const PROFILE_SIGN_OUT_CONFIRMED = "Profile - Sign Out - Confirmed";
export const EOL_EXTRA_ITEMS_CLICK = "EOL - Extra Items - Click";
export const UNIVERSAL_LINKS_CLICK_THROUGH = "Universal Links - Click Through";
