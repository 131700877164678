import * as types from "../actions";

export function dashboard(state = [], action: any) {
  switch (action.type) {
    case types.SET_DASHBOARD_SEARCH_PARAMS:
      return { ...state, dashboardSearchParams: action.dashboardSearchParams };
    default:
      return state;
  }
}
