import { put, call, CallEffect, PutEffect } from "redux-saga/effects";
import {
  CreateUserResponseType,
  createUserService,
  emailValidationService,
  ValidationResponseType,
  zipCodeValidationService,
} from "../services/accountCreationService";
import * as types from "../actions";
import { AccountCreationSagaRequest } from "../models/AccountCreationSagaRequest";
import { AccountCreationResponse } from "../models/AccountCreationResponse";
import { ValidationServiceResponse } from "../models/AccountValidationResponse";
import { httpConstants } from "../utils/httpConstants";
import { EmailValidationSagaRequest } from "../models/EmailValidationSagaRequest";
import { ZipCodeValidationSagaRequest } from "../models/ZipCodeValidationSagaRequest";

export function* accountCreationSaga(
  request: AccountCreationSagaRequest
): Generator<
  | CallEffect<CreateUserResponseType>
  | PutEffect<{
      type: string;
    }>,
  void,
  CreateUserResponseType
> {
  try {
    yield put({ type: types.ACCOUNT_CREATION_INPROGRESS });
    const response = yield call(createUserService, request.accountRequest);
    let accountResponse: AccountCreationResponse;
    if (!response) {
      yield put({ type: types.ACCOUNT_CREATION_ERROR });
      return;
    }
    if (response.status === 200 || response.status === 201) {
      accountResponse = response.data;
      yield put({ type: types.ACCOUNT_CREATION_SUCCESS, accountResponse });
    } else {
      yield put({
        type: types.ACCOUNT_CREATION_ERROR,
        accountError: response || [],
      });
    }
  } catch (error) {
    yield put({
      type: types.ACCOUNT_CREATION_ERROR,
      error,
      accountError: error,
    });
  }
}

export function* emailValidationSaga(
  request: EmailValidationSagaRequest
): Generator<
  | CallEffect<ValidationServiceResponse>
  | PutEffect<{
      type: string;
    }>,
  void,
  ValidationResponseType
> {
  try {
    const { validationRequest } = request;
    yield put({ type: types.EMAIL_VALIDATION_INPROGRESS });
    const response = yield call(emailValidationService, validationRequest);
    if (response.status === httpConstants.OK) {
      yield put({
        type: types.EMAIL_VALIDATION_SUCCESS,
        emailValidationResponse: response,
      });
    } else {
      yield put({
        type: types.EMAIL_VALIDATION_SUCCESS,
        emailValidationResponse: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.EMAIL_VALIDATION_SUCCESS,
      emailValidationResponse: {
        valid: false,
        status: httpConstants.INTERNAL_SERVER_ERROR,
        data: {
          error: { code: "internalServerError", message: "System error" },
        },
      },
    });
  }
}

export function* zipCodeValidationSaga(
  request: ZipCodeValidationSagaRequest
): Generator<
  | CallEffect<ValidationServiceResponse>
  | PutEffect<{
      type: string;
    }>,
  void,
  ValidationResponseType
> {
  try {
    const { validationRequest } = request;
    yield put({ type: types.ZIP_CODE_VALIDATION_INPROGRESS });
    const response = yield call(zipCodeValidationService, validationRequest);

    if (response.status === httpConstants.OK) {
      yield put({
        type: types.ZIP_CODE_VALIDATION_SUCCESS,
        zipCodeValidationResponse: { ...response, valid: true },
      });
    } else {
      yield put({
        type: types.ZIP_CODE_VALIDATION_FAILED,
        zipCodeValidationResponse: { ...response, valid: false },
      });
    }
  } catch (error) {
    yield put({
      type: types.ZIP_CODE_VALIDATION_FAILED,
      zipCodeValidationResponse: {
        valid: false,
        status: httpConstants.INTERNAL_SERVER_ERROR,
        data: {
          error: { code: "internalServerError", message: "System error" },
        },
      },
    });
  }
}
