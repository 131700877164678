import { useEffect, useState, useReducer, useRef, useContext } from "react";
import { Card, CardContent, Typography, Box } from "@material-ui/core";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { colors } from "../config/colorConfig";
import epocratesPlusLogo from "../images/brand-logo-group-plus.svg";
import { LicenseSelectSubscription } from "../components/LicenseSelectSubscription";
import { ProcessCalculateLicense } from "../queries/CalculateLicenseQuery";
import { licenseTheme } from "../styles/licenseTheme";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { getUserDetailsAction } from "../actions/profileActions";
import { LicenseCodeOrderInformationForm } from "../components/LicenseOrderInformationForm";
import {
  sendAmplitudeEvent,
  updateOptimizelyFlagsForSegment,
} from "../utils/AmplitudeUtil";
import { LicensePaymentSummary } from "../components/LicensePaymentSummary";
import { Button } from "../components/custom/Button";
import { config } from "../config/envConfig";
import { strings } from "../utils/Strings";
import importantNote from "../images/important-note.svg";
import {
  GROUP_LICENSING_CONTACT_SOON_VIEW,
  GROUP_LICENSING_FORM_CREATE_ACCOUNT_CLICK,
  GROUP_LICENSING_FORM_VIEW,
  GroupLicenseSegmentPayload,
  GROUP_LICENSING_REDEEM_CODE_CLICK,
} from "../utils/analytics";
import { LicensePurchaseContainer } from "../components/LicensePurchaseContainer";
import { sendSuccessSnackBarWithLinkMessage } from "../actions/snackBarActions";
import { SnackBarType } from "../utils/SnackBarType";
import { QueryStatus } from "@tanstack/react-query";
import { GuestSignInForm } from "src/components/GuestSignInForm";
import { LoadingEpocLogo } from "src/components/custom/LoadingEpocLogo";
import { LicensePurchaseCalculatePayload } from "src/services/licenseService";
import { UserInformation } from "src/models/AccountProfileResponse";
import { IPrimaryPaymentInformation } from "src/models/PrimaryPaymentMethodResponse";
import { CURRENT_USER_ID } from "src/utils/localStorageKeys";
import { OptimizelyContext } from "@optimizely/react-sdk";
import { CollapsibleOrderSummary } from "src/components/CollapsibleOrderSummary";
import { CollapsibleCard } from "src/components/CollapsibleCard";

export type GuestCheckoutRouterState = {
  data?: {
    pwdValue: string;
    email: string;
    isGroupPurchaseNewUser: boolean;
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    background: colors["--ui-grey-ultra-light"],
    height: "100vh",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(2, 0),
  },
  parentContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "nowrap",
    background: colors["--ui-grey-ultra-light"],
    gap: "16px",
    width: "100%",
  },
  purchaseContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
    },
  },
  subTitle: {
    fontSize: 24,
    fontFamily: "Source Sans Pro",
    lineHeight: "32px",
    margin: "24px 0",
    fontWeight: 600,
    [theme.breakpoints.down(1200)]: {
      margin: "16px 0 16px 16px",
    },
  },
  content: {
    width: "760px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      gap: 0,
    },
  },
  overViewInfo: {
    border: "none",
    boxShadow: "none",
    textAlign: "center",
  },
  preview: {
    width: 350,
    marginTop: "80px",
    [theme.breakpoints.down(1200)]: {
      display: "none",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    padding: "0 8px",
    width: "100%",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
    },
    margin: "0px !important",
  },
  supportLink: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.25,
    "&:hover": {
      color: colors["--primary-teal"],
      textDecoration: "none",
    },
    color: colors["--primary-teal"],
  },
  viewMoreLinkText: {
    color: "var(--primary-tealnight, #0178A4)",
    textAlign: "center",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  arrowDown: {
    width: 0,
    height: 0,
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderTop: "5px solid #0178A4",
    marginTop: "10px",
    marginLeft: "10px",
  },
  viewMoreLink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  viewPageContext: {
    margin: 20,
  },
  cardLoading: {
    width: 320,
    height: 260,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectPlan: {
    margin: `${theme.spacing(2)} ${theme.spacing(8)}`,
    background: colors["--text-invert"],
    display: "flex",
    flexDirection: "column",
  },
  dialogContainer: {
    display: "flex",
    width: "760px",
    padding: "24px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    borderRadius: "4px",
    background: "var(--pure-white, #FFF)",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
    },
  },
  dialogTitle: {
    color: "var(--primary-midnight, #06162D)",
    fontFamily: "PT Serif",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "40px",
  },
  dialogText: {
    color: "var(--primary-midnight, #06162D)",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  purchaseConfirmationNote: {
    borderRadius: "0px 4px 4px 0px",
    background: "var(--ui-grey-ultra-light, #F6F7FA)",
    display: "flex",
    width: "715px",
    padding: "16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      maxWidth: "715px",
    },
  },
  noteTitle: {
    color: "var(--primary-midnight, #06162D)",
    textAlign: "left",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "10px",
    lineWidth: "80px",
  },
  noteContent: {
    color: "var(--primary-midnight, #06162D)",
    textAlign: "left",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    padding: "5px 0px 0px 0px",
  },
  noteImage: {
    width: "16px",
    height: "16px",
    align: "left",
  },
  ol: {
    display: "grid",
    gap: "10px",
    paddingLeft: 10,
    listStylePosition: "inside",
  },
  collapsibleOrderSummaryContainer: {
    display: "none",
    [theme.breakpoints.down(1200)]: {
      display: "block",
    },
  },
}));

const SKU = "PLUS_YEAR_GROUP_LICENSE_CODE";
export const LICENSE_MAXIMUM = 30;
export const LICENSE_MINIMUM = 2;

const notesDataItems = [
  { text: "Transaction receipt" },
  { text: "Code redemption email to be forwarded to members of your group" },
];

type LicenseFormStep =
  | "quantitySelection"
  | "orderInformation"
  | "loginScreen"
  | "paymentInformation"
  | "thankYou"
  | "contactSoon";

type LicenseFormState = {
  step: LicenseFormStep;
  authenticatedProfile?: UserInformation;
  newEmail?: string;
  storePassword?: string;
  quantity?: number;
  institution: string;
  groupCode?: string;
  showLoginForm: boolean;
  profileStale: boolean;
};

type LicenseFormActionType =
  | "continue"
  | "cancel"
  | "updateProfile"
  | "editQuantitySelection"
  | "editOrderInformation"
  | "editPaymentInformation"
  | "calculateLicenseError"
  | "setPassword";

export type LicenseFormAction = {
  type: LicenseFormActionType;
  quantity?: number;
  institution?: string;
  groupCode?: string;
  needAuthentication?: boolean;
  calculateStatus?: QueryStatus;
  authenticatedProfile?: UserInformation;
  profileStale?: boolean;
  newEmail?: string;
  newPassword?: string;
  continueWithProfileEmail?: boolean;
  contactName?: string;
};

function reducer(
  state: LicenseFormState,
  action: LicenseFormAction
): LicenseFormState {
  switch (action.type) {
    case "continue":
      let segmentPayload: GroupLicenseSegmentPayload;
      switch (state.step) {
        case "quantitySelection":
          if (action.quantity && action.quantity >= LICENSE_MINIMUM) {
            if (
              action.calculateStatus === "success" ||
              action.quantity > LICENSE_MAXIMUM
            ) {
              return {
                ...state,
                step: "orderInformation",
                quantity: action.quantity,
              };
            } else {
              return {
                ...state,
                quantity: action.quantity,
              };
            }
          } else {
            return state;
          }
        case "orderInformation":
          if (action.institution && action.newEmail) {
            if (
              state.quantity &&
              state.quantity > LICENSE_MAXIMUM &&
              action.contactName
            ) {
              segmentPayload = {
                "Purchase Quantity": state.quantity,
                "Institution Name": action.institution,
                "Contact Email": action.newEmail,
                "Contact Name": action.contactName,
              };
              sendAmplitudeEvent(
                GROUP_LICENSING_CONTACT_SOON_VIEW,
                segmentPayload
              );
              return {
                ...state,
                step: "contactSoon",
                institution: action.institution,
                newEmail: action.newEmail,
              };
            }
            if (action.needAuthentication) {
              return {
                ...state,
                step: "loginScreen",
                showLoginForm: true,
                institution: action.institution,
                newEmail: action.newEmail,
              };
            }
            return {
              ...state,
              step: "paymentInformation",
              institution: action.institution,
              newEmail: action.newEmail,
            };
          }
          return state;
        case "loginScreen":
          return {
            ...state,
            showLoginForm: false,
            profileStale: true,
            step: "paymentInformation",
          };
        case "paymentInformation":
          if (action.groupCode) {
            return {
              ...state,
              step: "thankYou",
              groupCode: action.groupCode,
            };
          } else {
            return state;
          }
        default:
          return state;
      }
    case "editQuantitySelection":
      return { ...state, step: "quantitySelection" };
    case "editOrderInformation":
      return { ...state, step: "orderInformation" };
    case "editPaymentInformation":
      return { ...state, step: "paymentInformation" };
    case "calculateLicenseError":
      return { ...state, step: "quantitySelection" };
    case "cancel":
      switch (state.step) {
        case "loginScreen":
          if (action.continueWithProfileEmail) {
            return { ...state, step: "orderInformation", showLoginForm: false };
          }
          return {
            ...state,
            step: "orderInformation",
            showLoginForm: false,
            newEmail: action.authenticatedProfile?.email,
          };
        default:
          return state;
      }
    case "updateProfile":
      if (action.authenticatedProfile) {
        return {
          ...state,
          authenticatedProfile: action.authenticatedProfile,
          newEmail: undefined,
          profileStale: false,
        };
      } else return state;
    case "setPassword":
      if (action.newPassword) {
        return {
          ...state,
          storePassword: action.newPassword,
        };
      } else return state;
    default:
      throw Error("Unknown Action: " + action.type);
  }
}

export const LicenseCodeForm = (props: RouteComponentProps) => {
  const [state, dispatch] = useReducer(reducer, {
    step: "quantitySelection",
    institution: "",
    showLoginForm: false,
    profileStale: localStorage.getItem(CURRENT_USER_ID) ? true : false,
  });
  const { optimizely } = useContext(OptimizelyContext);
  const [vindiciaPaymentFail, setVindiciaPaymentFail] = useState(false);
  const [vindiciaFieldsSpinner, setVindiciaFieldsSpinner] = useState(true);
  const [paymentDetailRequest, setPaymentDetailRequest] = useState<string>("");
  const [paymentConfirmScreen, setPaymentConfirmScreen] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState<string>("");
  const [paymentId, setPaymentId] = useState<string>("");
  const pwdData = useRef<string>("");
  const isNewCreatedUser = useRef<boolean>(false);
  // need to keep state here for vindicia wrapper reload
  const [paymentDetailsResponse, setPaymentDetailsResponse] =
    useState<IPrimaryPaymentInformation>();

  const classes = useStyles(licenseTheme);

  const profile = useSelector(
    (reduxState: RootStateOrAny) =>
      reduxState.accountProfile?.accountProfileResponse
  );

  const request: LicensePurchaseCalculatePayload = {
    userId: state.authenticatedProfile?.userId,
    sku: SKU,
    //This value get updated on user enter license count
    quantity: state.quantity ?? 0,
    institution: state.institution,
    paymentId: paymentId,
  };
  const {
    data: licenseAmountDetails,
    isFetching,
    isFetched,
    refetch,
    status: calculateStatus,
  } = ProcessCalculateLicense(request);

  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatch({
      type: "updateProfile",
      authenticatedProfile: profile?.userInformation,
    });
  }, [profile?.userInformation]);

  useEffect(() => {
    if (state.profileStale) {
      dispatchRedux(getUserDetailsAction());
    }
  }, [dispatchRedux, state.profileStale]);

  useEffect(() => {
    switch (calculateStatus) {
      case "error":
        dispatchRedux(
          sendSuccessSnackBarWithLinkMessage(
            strings.calculate_failed_title,
            SnackBarType.ERROR.displayValue
          )
        );
        dispatch({
          type: "calculateLicenseError",
        });
        break;
      case "success":
        dispatch({
          type: "continue",
          quantity: state.quantity,
          calculateStatus,
        });
        break;
      default:
    }
  }, [calculateStatus, state.quantity, dispatchRedux]);

  const { vindicia } = window;

  // To control redndering until PMT fields loaded
  const waitForVindiciaFields = () => {
    const timeoutDuration = 1000;
    const intervalDuration = 2000;
    let vindiciaFieldsTimeout: ReturnType<typeof setTimeout> | null = null;
    let vindiciaFieldsInterval: ReturnType<typeof setInterval> | null = null;
    vindiciaFieldsTimeout = setTimeout(() => {
      setVindiciaFieldsSpinner(false);
      if (vindiciaFieldsInterval) {
        clearInterval(vindiciaFieldsInterval);
      }
    }, timeoutDuration);
    vindiciaFieldsInterval = setInterval(() => {
      if (
        window.vindicia.frames.cardNumber?.isLoaded &&
        window.vindicia.frames.expirationDate?.isLoaded &&
        window.vindicia.frames.cvn?.isLoaded
      ) {
        setVindiciaFieldsSpinner(false);
        if (vindiciaFieldsTimeout) {
          clearTimeout(vindiciaFieldsTimeout);
        }
        if (vindiciaFieldsInterval) {
          clearInterval(vindiciaFieldsInterval);
        }
      }
    }, intervalDuration);

    return () => {
      if (vindiciaFieldsInterval) {
        clearInterval(vindiciaFieldsInterval);
      }
      if (vindiciaFieldsTimeout) {
        clearTimeout(vindiciaFieldsTimeout);
      }
    };
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://secure.vindicia.com/pmt/vindicia.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    sendAmplitudeEvent(GROUP_LICENSING_FORM_VIEW);
    const userId = localStorage.getItem(CURRENT_USER_ID);
    if (userId && optimizely) {
      const decisions = optimizely.decideAll(undefined, userId);
      updateOptimizelyFlagsForSegment(userId, decisions);
    }
  }, []);
  const onFinishPurchase = () => {
    const segmentPayload: GroupLicenseSegmentPayload = {
      "Purchase Type": "Group Licensing",
      "Purchase Quantity": state.quantity,
      "Institution Name": state.institution,
    };
    if (isNewCreatedUser.current) {
      sendAmplitudeEvent(
        GROUP_LICENSING_FORM_CREATE_ACCOUNT_CLICK,
        segmentPayload
      );
      const groupLicenseUserData: GuestCheckoutRouterState = {
        data: {
          pwdValue: pwdData.current,
          isGroupPurchaseNewUser: true,
          email: state.authenticatedProfile?.email ?? "",
        },
      };

      props.history.push("/createaccount", groupLicenseUserData);
    } else {
      sendAmplitudeEvent(GROUP_LICENSING_REDEEM_CODE_CLICK, segmentPayload);
      window.location.href = `/license/redeem?code=${state.groupCode}`;
    }
  };

  const StepComponent: React.FC = () => {
    switch (state.step) {
      case "quantitySelection":
        return (
          <div className={classes.content}>
            <Box className={classes.dialogContainer}>
              <Typography className={classes.dialogText}>
                epocrates+ Group lets you purchase multiple epocrates+ Yearly
                subscriptions for you and your group. The more subscriptions you
                buy, the greater the discount.
              </Typography>
              <Typography className={classes.dialogText}>
                <strong>How it works: </strong>Select how many subscriptions
                you'd like, submit your one-time credit card payment and then
                you will then receive an email containing a unique redemption
                code that you can forward to the members of your group.
              </Typography>
            </Box>
            <CollapsibleCard
              title="Select subscriptions"
              expanded={true}
              number={1}
            >
              <LicenseSelectSubscription
                purchaseQuantity={state.quantity}
                formDispatch={dispatch}
                refetch={refetch}
                calculateStatus={calculateStatus}
              />
            </CollapsibleCard>
          </div>
        );
      case "orderInformation":
      case "loginScreen":
        return (
          <div className={classes.content}>
            <CollapsibleCard
              title="Select subscriptions"
              expanded={false}
              number={1}
              editAction={() => dispatch({ type: "editQuantitySelection" })}
            />
            <CollapsibleCard
              title="Order information"
              expanded={true}
              number={2}
            >
              <LicenseCodeOrderInformationForm
                dispatch={dispatch}
                emailAddress={
                  state.newEmail ?? state.authenticatedProfile?.email ?? ""
                }
                fullName={
                  state.authenticatedProfile
                    ? `${state.authenticatedProfile.firstName} ${state.authenticatedProfile.lastName}`
                    : ""
                }
                companyName={state.institution}
                profileEmail={profile?.userInformation.email}
                isQuantityBeyondMaximum={
                  state.quantity && state.quantity > LICENSE_MAXIMUM
                    ? true
                    : false
                }
              />
            </CollapsibleCard>
            <GuestSignInForm
              profileEmail={state.authenticatedProfile?.email}
              email={state.newEmail}
              loginScreenOption={state.showLoginForm}
              dispatch={dispatch}
              width="100%"
              height="100%"
            />
          </div>
        );
      case "paymentInformation":
        return (
          <div className={classes.content}>
            <CollapsibleCard
              title="Select subscriptions"
              expanded={false}
              number={1}
              editAction={() => dispatch({ type: "editQuantitySelection" })}
            />
            <CollapsibleCard
              title="Order information"
              expanded={false}
              number={2}
              editAction={() => dispatch({ type: "editOrderInformation" })}
            />
            <CollapsibleCard
              title="Payment & billing"
              expanded={true}
              number={3}
            >
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                flexGrow={1}
              >
                <div className={classes.content}>
                  <Box className={classes.selectPlan}>
                    <LicensePurchaseContainer
                      vindicia={vindicia}
                      userId={state.authenticatedProfile?.userId.toString()}
                      newEmail={state.newEmail}
                      profileEmail={state.authenticatedProfile?.email}
                      pwdData={pwdData}
                      isNewCreatedUser={isNewCreatedUser}
                      sku={SKU}
                      waitForVindiciaFields={waitForVindiciaFields}
                      vindiciaFieldsSpinner={vindiciaFieldsSpinner}
                      setVindiciaFieldsSpinner={setVindiciaFieldsSpinner}
                      vindiciaPaymentFail={vindiciaPaymentFail}
                      setVindiciaPaymentFail={setVindiciaPaymentFail}
                      purchaseQuantity={state.quantity ?? 0}
                      institution={state.institution}
                      calculateLicenseResponse={licenseAmountDetails}
                      paymentDetailRequest={paymentDetailRequest}
                      setPaymentDetailRequest={setPaymentDetailRequest}
                      paymentConfirmScreen={paymentConfirmScreen}
                      setPaymentConfirmScreen={setPaymentConfirmScreen}
                      paymentMethodId={paymentMethodId}
                      setPaymentMethodId={setPaymentMethodId}
                      formDispatch={dispatch}
                      setPaymentId={setPaymentId}
                      paymentDetailsResponse={paymentDetailsResponse}
                      setPaymentDetailsResponse={setPaymentDetailsResponse}
                    />
                  </Box>
                </div>
              </Box>
            </CollapsibleCard>
          </div>
        );
      case "thankYou":
        return (
          <Box className={classes.dialogContainer}>
            <Typography className={classes.dialogTitle}>
              {strings.group_purchase_confirmation_title}{" "}
            </Typography>
            <Typography className={classes.dialogText}>
              {strings.group_purchase_confirmation_content +
                (isNewCreatedUser.current
                  ? " Please finish creating your account in order to redeem your code."
                  : "")}
            </Typography>
            <Box className={classes.purchaseConfirmationNote}>
              <Typography className={classes.noteTitle}>
                <img
                  src={importantNote}
                  alt="Important Note"
                  className={classes.noteImage}
                />
                Important note
              </Typography>
              <Typography className={classes.noteContent}>
                {strings.group_purchase_confirmation_notes_title}
              </Typography>
              <Typography className={classes.noteContent}>
                <ol className={classes.ol}>
                  {notesDataItems.map((item) => (
                    <li>
                      <span>{item.text}</span>
                    </li>
                  ))}
                </ol>
              </Typography>
            </Box>
            <Button
              name={
                isNewCreatedUser.current
                  ? "Finish creating account"
                  : "Redeem code"
              }
              id="redeemCode"
              testId="redeemCode"
              backgroundColor={colors["--primary-tealnight"]}
              hoverForeground={colors["--primary-tealnight"]}
              width={264}
              onClick={onFinishPurchase}
              margins="8px 0 0 0"
            />
          </Box>
        );
      case "contactSoon":
        return (
          <Box className={classes.dialogContainer}>
            <Typography className={classes.dialogTitle}>
              We'll be in touch soon!
            </Typography>
            <Typography className={classes.dialogText}>
              We have received your request to purchase {state.quantity}{" "}
              subscriptions for your group. An epocrates sales representative
              will be contacting you via email in two business days or less and
              will create a custom plan for you and your group. Thank you!
            </Typography>
            <Button
              name="Go Home"
              id="goHome"
              testId="goHome"
              backgroundColor={colors["--primary-tealnight"]}
              hoverForeground={colors["--primary-tealnight"]}
              width={264}
              href={config.environment.baseEpocratesUrl}
            />
          </Box>
        );
    }
  };
  return (
    <ThemeProvider theme={licenseTheme}>
      <div className={classes.root}>
        <div className={classes.header}>
          <img src={epocratesPlusLogo} alt="Logo" />
        </div>
        <Box className={classes.parentContainer}>
          <Box className={classes.purchaseContainer}>
            <Typography variant="h2" className={classes.subTitle}>
              Purchase checkout
            </Typography>
            {/* render order preview cards for smaller screens 1000px and under */}
            <Box className={classes.collapsibleOrderSummaryContainer}>
              <CollapsibleOrderSummary
                isLoading={isFetching}
                orderTotal={licenseAmountDetails?.data.total}
              >
                <LicensePaymentSummary
                  purchaseQuantity={state.quantity || 0}
                  calculateLicenseResponse={licenseAmountDetails}
                  hideDisplayTitle={true}
                />
              </CollapsibleOrderSummary>
            </Box>
            {/* end section for rendering preview cards for screens 1000px and under */}
            <StepComponent />
          </Box>
          <div className={classes.preview}>
            {!isFetching && isFetched && state.quantity && (
              <Card>
                <CardContent>
                  <LicensePaymentSummary
                    purchaseQuantity={state.quantity}
                    calculateLicenseResponse={licenseAmountDetails}
                  />
                </CardContent>
              </Card>
            )}
            {isFetching && (
              <Card className={classes.cardLoading}>
                <CardContent>
                  <LoadingEpocLogo />
                </CardContent>
              </Card>
            )}
          </div>
        </Box>
      </div>
    </ThemeProvider>
  );
};
