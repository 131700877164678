import axios from "axios";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";
import { config } from "../config/envConfig";
import {
  EmailPreference,
  EmailPreferenceAxiosResponse,
} from "../models/EmailPreference";
import { refreshAccess } from "../utils/cookies";

const { profileUrl } = config.environment;

const instance = axios.create({
  baseURL: profileUrl,
  timeout: 30000,
});

export const updateEmailPreferenceService = async (
  request: EmailPreference[]
): Promise<EmailPreferenceAxiosResponse> => {
  const emailPreferenceRequest: EmailPreference[] = request;
  const userId = localStorage.getItem(CURRENT_USER_ID);
  const cookie = await refreshAccess();
  const url = `/v1/user/${userId}/emailPreferences`;
  return instance
    .patch(url, emailPreferenceRequest, {
      headers: {
        Authorization: `${cookie}`,
        accept: "application/json",
      },
    })
    .then((response: EmailPreferenceAxiosResponse) => response)
    .catch((error: Error) => {
      console.log(error);
      return { status: 500, data: [] };
    });
};

export const getEmailPreferenceService =
  async (): Promise<EmailPreferenceAxiosResponse> => {
    const userId = localStorage.getItem(CURRENT_USER_ID);
    const cookie = await refreshAccess();
    const url = `/v1/user/${userId}/emailPreferences`;
    return instance
      .get(url, {
        headers: {
          Authorization: `${cookie}`,
          accept: "application/json",
        },
      })
      .then((response: EmailPreferenceAxiosResponse) => response)
      .catch((error: Error) => {
        console.log(error);
        return { status: 500, data: [] };
      });
  };
