import * as React from "react";
import {
  makeStyles,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { colors } from "../../config/colorConfig";
import { isValidPassword } from "../../utils/validation";
import { strings } from "../../utils/Strings";

const PasswordError = (props: any) => {
  const { pwValidation } = props;
  const style = makeStyles((theme) => ({
    typography: {
      fontFamily: ["Metric"].join(","),
      fontSize: "14px",
      color: colors["--ui-slate"],
    },
    redIcon: {
      color: colors["--error"],
      fontSize: "12px",
    },
    greenIcon: {
      color: "green",
      fontSize: "12px",
    },
  }));
  const classes = style();
  const lengthIcon = pwValidation.passwordIsCorrectLength ? (
    <CheckIcon className={classes.greenIcon} />
  ) : (
    <ClearIcon className={classes.redIcon} />
  );
  const upperLowerIcon = pwValidation.passwordContainsUpperAndLower ? (
    <CheckIcon className={classes.greenIcon} />
  ) : (
    <ClearIcon className={classes.redIcon} />
  );
  const numberIcon = pwValidation.passwordContainsNumber ? (
    <CheckIcon className={classes.greenIcon} />
  ) : (
    <ClearIcon className={classes.redIcon} />
  );
  const emailIcon = pwValidation.passwordDoesNotContainEmail ? (
    <CheckIcon className={classes.greenIcon} />
  ) : (
    <ClearIcon className={classes.redIcon} />
  );
  return (
    <>
      <List style={{ padding: "0px" }}>
        <ListItem
          alignItems="center"
          style={{ padding: "0px" }}
          dense
          disableGutters
        >
          <ListItemIcon style={{ minWidth: "20px" }}>{lengthIcon}</ListItemIcon>
          <ListItemText
            className={classes.typography}
            primary={strings.password_min_chars}
          />
        </ListItem>
        <ListItem
          alignItems="center"
          style={{ padding: "0px" }}
          dense
          disableGutters
        >
          <ListItemIcon style={{ minWidth: "20px" }}>
            {upperLowerIcon}
          </ListItemIcon>
          <ListItemText
            className={classes.typography}
            primary={strings.password_upper_lower_case}
          />
        </ListItem>
        <ListItem
          alignItems="center"
          style={{ padding: "0px" }}
          dense
          disableGutters
        >
          <ListItemIcon style={{ minWidth: "20px" }}>{numberIcon}</ListItemIcon>
          <ListItemText
            className={classes.typography}
            primary={strings.password_one_number}
          />
        </ListItem>
        <ListItem
          alignItems="center"
          style={{ padding: "0px" }}
          dense
          disableGutters
        >
          <ListItemIcon style={{ minWidth: "20px" }}>{emailIcon}</ListItemIcon>
          <ListItemText
            className={classes.typography}
            primary={strings.password_no_email}
          />
        </ListItem>
      </List>
    </>
  );
};
export const getPasswordError = (data: string, email: string) => {
  let errorMessage = null;
  if (data.length > 0) {
    const passwordCheck = isValidPassword(data, email);
    if (
      !passwordCheck.passwordContainsUpperAndLower ||
      !passwordCheck.passwordContainsNumber ||
      !passwordCheck.passwordDoesNotContainEmail ||
      !passwordCheck.passwordIsCorrectLength ||
      !passwordCheck.passwordNotEmpty
    ) {
      errorMessage = <PasswordError pwValidation={passwordCheck} />;
    }
  } else {
    errorMessage = <PasswordError pwValidation={{}} />;
  }
  return errorMessage;
};
