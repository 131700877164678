import * as React from "react";
import clsx from "clsx";
import { makeStyles, Typography } from "@material-ui/core";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withRoot } from "../withRoot";
import { strings } from "../utils/Strings";
import { httpConstants } from "../utils/httpConstants";
import { colors } from "../config/colorConfig";
import { CreateAccountFormWith as CreateAccountForm } from "./CreateAccountForm";
import { updateUserDetailsAction } from "../actions/profileActions";
import { CreateAccountNPIProfileWith as CreateAccountNPIProfile } from "./CreateAccountNPIProfile";
import {
  getToolsAndLocation,
  sendAmplitudeEvent,
  sendAmplitudeEventData,
  updateUserPropertiesForSegment,
  getMarketingSource,
  ACCOUNT_TYPES,
  updateAccountTypeForSegment,
  updateOptimizelyFlagsForSegment,
} from "../utils/AmplitudeUtil";
import {
  CREATE_ACCOUNT_NPI_RESULT_SELECT,
  CREATE_ACCOUNT_NPI_RESULT_SELECT_EVENT_ID,
  CREATE_ACCOUNT_PRIVACY_POLICY_CLICK,
  CREATE_ACCOUNT_PRIVACY_POLICY_CLICK_EVENT_ID,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_SUCCESS_EVENT_ID,
  CREATE_ACCOUNT_TERMS_OF_USE_CLICK_EVENT_ID,
  CREATE_ACCOUNT_TERMS_OF_USE_CLICK,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_ERROR_EVENT_ID,
  CREATE_ACCOUNT_NPI_SAVE_FAILURE,
  CREATE_ACCOUNT_NPI_SAVE_FAILURE_EVENT_ID,
  CREATE_ACCOUNT_NPI_SAVE_SUCCESS,
  CREATE_ACCOUNT_NPI_SAVE_SUCCESS_EVENT_ID,
} from "../utils/analytics";
import { clearZipCodeValidationAction } from "../actions/accountCreationActions";
import { AccountCreationRequest } from "../models/AccountCreationRequest";
import { AccountCreationResponse } from "../models/AccountCreationResponse";
import { AccountCreationNpiFormValues } from "../models/AccountCreationNpiFormValues";
import { npiLookupAction } from "../actions/npiLookupAction";
import {
  CLAIM_PROFILE_VIEW,
  getViewQueryStr,
  npiProfileLimit,
  NPI_VIEW,
  SIGNUP_VIEW,
} from "../config/accountCreationConfig";
import { ErrorMessageWith as ErrorMessage } from "./ErrorMessage";
import { setAccesstoken, setCookie, setRefreshToken } from "../utils/cookies";
import {
  CURRENT_USER_ID,
  SHOW_WELCOME_BANNER,
  STAY_LOGGED_IN,
} from "../utils/localStorageKeys";
import {
  isNpiVisible,
  isInternationalUser,
  isZipCodeEnabled,
} from "../utils/accountCreationUtil";
import {
  filterQueryParameters,
  getQueryObjFromString,
} from "../utils/routeUtil";
import { AccountCreationFormValues } from "../models/AccountCreationFormValues";
import { DemographicsResponse } from "../models/DemographicsResponse";
import { NpiLookupDataWrap, NpiTuple } from "../models/NpiLookupResponse";
import { ErrorValues } from "../models/ErrorValues";
import { RequestError } from "../models/RequestError";
import { INVALID_NPI_ERROR } from "../models/ServiceErrorCodes";
import { config } from "../config/envConfig";
import { UserInformation } from "../models/AccountProfileResponse";
import { ToolsLocationForEvents } from "../models/ToolsLocationForEvents";
import { GuestCheckoutRouterState } from "../container/LicenseCodeForm";
import { createUserService } from "src/services/accountCreationService";
import { changePasswordService } from "src/services/changePasswordService";
import * as types from "../actions";
import { SnackBarType } from "../utils/SnackBarType";
import { OptimizelyContext } from "@optimizely/react-sdk";

interface NpiLookup {
  npiLookup: NpiLookupState;
}

interface NpiLookupState {
  npis: NpiLookupDataWrap;
  progress: boolean;
}

interface AccountCreationState {
  accountCreation: AccountCreationValues;
}

interface AccountError {
  status: number;
  data: RequestError[];
}

interface AccountCreationValues {
  accountResponse: AccountCreationResponse;
  specialtiesResponse: DemographicsResponse;
  account_progress: boolean;
  specialties_progress: boolean;
  account_error: AccountError;
}

interface QueryObj {
  view?: string;
}

interface EventObject {
  "Event Id": string;
  "First Name": string;
  "Last Name": string;
  "Email Address": string;
  Occupation: string;
  Country: string | undefined;
  "Postal Code": string | undefined;
  Specialty: string | undefined;
  Subspecialty: string | undefined;
  Tools: string | null;
  Location: string | null;
  "Conference Source"?: string; // Make "Conference Source" optional
}

const CreateAccountRightPane = (props: RouteComponentProps) => {
  const [isFresh, setIsFresh] = React.useState(true);
  const [currentView, setCurrentView] = React.useState(SIGNUP_VIEW);
  const [profiles, setProfiles] = React.useState<NpiTuple[]>([]);
  const [isStudent, setIsStudent] = React.useState(false);
  const [profileDetail, setProfileDetail] = React.useState({});
  const toolsLocation = React.useRef<ToolsLocationForEvents>({
    tools: null,
    location: null,
  });
  const marketingSource = React.useRef<string | null>(null);
  const routerState: GuestCheckoutRouterState =
    props.history?.location?.state &&
    typeof props.history.location.state === "object"
      ? props.history.location.state
      : {};
  // Including extra key inorder for it to be distinguishable while running unit tests
  const [formValues, setFormValues] = React.useState<AccountCreationFormValues>(
    {
      testFormValues: true,
    } as AccountCreationFormValues
  );
  const [npiFormValues, setNpiFormValues] =
    React.useState<AccountCreationNpiFormValues>(
      {} as AccountCreationNpiFormValues
    );
  const { optimizely } = React.useContext(OptimizelyContext);
  const dispatch = useDispatch();
  let isTermsTextVisible = false;
  const accountState = useSelector(
    (accountCreationState: AccountCreationState) =>
      accountCreationState.accountCreation
  );
  const { location: { search = "" } = {} } = props;
  const queryParams = new URLSearchParams(search);
  const referNextUrl = queryParams.get("refernext") ?? "";
  const oldPassword = routerState?.data?.pwdValue ?? ""; // This will be updated with the react state value.
  const onTermsOfUseClick = () => {
    sendAmplitudeEventData(
      null,
      CREATE_ACCOUNT_TERMS_OF_USE_CLICK,
      CREATE_ACCOUNT_TERMS_OF_USE_CLICK_EVENT_ID
    );
  };
  const onPrivacyPolicyClick = () => {
    sendAmplitudeEventData(
      null,
      CREATE_ACCOUNT_PRIVACY_POLICY_CLICK,
      CREATE_ACCOUNT_PRIVACY_POLICY_CLICK_EVENT_ID
    );
  };
  const leftPaneStyle = makeStyles((theme) => ({
    wrapper: {
      width: "50%",
      flexGrow: 0,
      flexShrink: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: 0,
        marginTop: "64px",
        position: "relative",
      },
    },
    innerWrap: {
      maxWidth: 440,
      background: colors["--text-invert"],
      padding: "40px",
      borderRadius: "4px",
      boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      height: "fit-content",
      [theme.breakpoints.down("sm")]: {
        margin: "10px",
        padding: "32px 16px",
        maxWidth: "440px",
      },
    },
    applicationAltTitle: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    logo: {
      textAlign: "center",
      marginBottom: theme.spacing(6),
    },
    title: {
      color: colors["--ui-midnight"],
      fontFamily: "Source Sans Pro",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "32px",
      marginBottom: "16px",
      [theme.breakpoints.between("xs", "md")]: {
        textAlign: "center",
      },
    },
    subTitle: {
      fontSize: 16,
      lineHeight: 1.25,
      marginBottom: 24,
      fontFamily: "Source Sans Pro",
      color: colors["--ui-slate"],
    },
    inputRoot: {
      padding: "14.5px 14px",
    },
    footerWrap: {
      position: "absolute",
      bottom: 0,
    },
    termsOfUseText: {
      lineHeight: "24px",
      marginTop: theme.spacing(4),
      color: colors["--ui-slate"],
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
    },
  }));
  const classes = leftPaneStyle();
  const npiState = useSelector((npi: NpiLookup) => npi.npiLookup);
  let element;
  let title;
  let subTitle;
  const linkClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setProfileDetail({});
    props.history.push(getViewQueryStr(NPI_VIEW, referNextUrl));
  };

  const createAccountMutation = useMutation(createUserService, {
    onSuccess: (responseData) => {
      if (responseData.status === 200 || responseData.status === 201) {
        const accountResponse = responseData.data;
        dispatch({ type: types.ACCOUNT_CREATION_SUCCESS, accountResponse });
      } else {
        dispatch({
          type: types.ACCOUNT_CREATION_ERROR,
          accountError: responseData || [],
        });
      }
    },
    onError: (error) => {
      dispatch({
        type: types.ACCOUNT_CREATION_ERROR,
        error,
        accountError: error,
      });
    },
  });

  const changePasswordMutation = useMutation(changePasswordService, {
    onSuccess: (responseData) => {
      if (responseData.status === httpConstants.OK) {
        setCookie("accessToken", 24, responseData.data.accessToken);
        setCookie("sessionTimer", 24, "sessionTimer");
        setCookie("refreshToken", 24, responseData.data.refreshToken);
        dispatch({ type: types.TIMEOUT_SNACKBAR });
        dispatch({
          type: types.SET_SNACKBAR_MESSAGE,
          message: strings.change_password_success,
          messageType: SnackBarType.SUCCESS.displayValue,
        });
      }
    },
  });

  const showNpi = isNpiVisible(
    formValues.country?.iso3166alpha2,
    formValues.occupation?.text
  );

  const profileClickHandler = (detail: NpiTuple, index: number) => {
    sendAmplitudeEvent(CREATE_ACCOUNT_NPI_RESULT_SELECT, {
      "Event ID": CREATE_ACCOUNT_NPI_RESULT_SELECT_EVENT_ID,
      Name: `${detail.first_name} ${detail.last_name}`,
      "Selected Index": index + 1,
      Specialty: detail.specialty,
      "Zip Code": detail.postal_code,
    });
    setProfileDetail(detail);
    props.history.push(getViewQueryStr(NPI_VIEW, referNextUrl), routerState);
  };
  const createInitialRequestObject = (data: AccountCreationFormValues) => {
    const accRequest: AccountCreationRequest = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      occupation: data.occupation.id,
      profileOccupation: data.occupation.id,
      addresses: {
        work: {
          country: data.country.iso3166alpha2 as string,
        },
      },
    };
    if (isZipCodeEnabled(data.country.iso3166alpha2)) {
      accRequest.addresses.work.zipcode = data.zipCode;
    }
    return accRequest;
  };
  const onSignupContinue = (
    data: AccountCreationFormValues,
    isSubmit: boolean,
    isStudentOccupation: boolean
  ) => {
    setFormValues(data);
    if (isSubmit) {
      const accRequest = createInitialRequestObject(data);
      dispatch(clearZipCodeValidationAction());
      if (!routerState?.data?.isGroupPurchaseNewUser) {
        createAccountMutation.mutate(accRequest);
      } else {
        const { email, password, ...updateRequestObject } = accRequest;
        dispatch(updateUserDetailsAction(updateRequestObject));
        changePasswordMutation.mutate({
          currentPassword: oldPassword,
          newPassword: accRequest.password,
        });
        props.history.push("/account/profile");
      }
    } else {
      setIsStudent(isStudentOccupation);
      if (isStudentOccupation) {
        props.history.push(
          getViewQueryStr(NPI_VIEW, referNextUrl),
          routerState
        );
      } else {
        props.history.push(
          getViewQueryStr(CLAIM_PROFILE_VIEW, referNextUrl),
          routerState
        );
      }
    }
  };
  const npiCreateAccount = (data: AccountCreationNpiFormValues) => {
    setNpiFormValues(data);
    const accRequest = createInitialRequestObject(formValues);
    accRequest.specialty = data.specialty.id;
    accRequest.profileSpecialty = data.specialty.id;
    accRequest.subSpecialty = data.subSpecialty.id;
    accRequest.profileSubSpecialty = data.subSpecialty.id;
    if (data.npiValue) {
      accRequest.npiNum = data.npiValue;
    }
    dispatch(clearZipCodeValidationAction());
    if (!routerState?.data?.isGroupPurchaseNewUser) {
      createAccountMutation.mutate(accRequest);
    } else {
      const { email, password, ...updateRequestObject } = accRequest;
      dispatch(updateUserDetailsAction(updateRequestObject));
      changePasswordMutation.mutate({
        currentPassword: oldPassword,
        newPassword: accRequest.password,
      });
      props.history.push("/account/profile");
    }
  };
  React.useEffect(() => {
    setIsFresh(false);
    const toolsLocationObj = getToolsAndLocation();
    toolsLocation.current.tools = toolsLocationObj?.tools;
    toolsLocation.current.location = toolsLocationObj?.location;
    marketingSource.current = getMarketingSource();
  }, []);
  React.useEffect(() => {
    const { specialtiesResponse } = accountState;
    if (specialtiesResponse && specialtiesResponse.status === 200) {
      if (isStudent) {
        if (specialtiesResponse.data.length) {
          setCurrentView(NPI_VIEW);
        } else {
          props.history.replace(
            filterQueryParameters(props.location.search, ["refernext"])
          );
          props.history.goBack();
        }
      } else if (!showNpi && !specialtiesResponse.data.length) {
        props.history.replace(
          filterQueryParameters(props.location.search, ["refernext"])
        );
        props.history.replace("?");
      } else {
        const { npis } = npiState;
        if (npis && npis.data) {
          if (npis.data.results?.length > 0 && showNpi) {
            setCurrentView(CLAIM_PROFILE_VIEW);
            setProfiles(npis.data.results);
          } else {
            setProfileDetail({});
            props.history.replace(
              getViewQueryStr(NPI_VIEW, referNextUrl),
              routerState
            );
          }
        }
      }
    }
  }, [npiState.npis]);

  const [errorValues, setErrorValues] = React.useState<ErrorValues>({
    counter: 0,
    errorMessages: [],
    errorStatus: 0,
  });

  React.useEffect(() => {
    if (
      accountState?.account_error?.data?.length > 0 ||
      accountState?.account_error?.status ===
        httpConstants.INTERNAL_SERVER_ERROR
    ) {
      let amplitudeErrorMsg = "";
      if (
        accountState?.account_error?.status !==
        httpConstants.INTERNAL_SERVER_ERROR
      ) {
        setErrorValues({
          counter: errorValues.counter,
          errorMessages: accountState.account_error.data,
          errorStatus: accountState.account_error.status,
        });
        amplitudeErrorMsg = (accountState.account_error?.data ?? [])
          .map(({ error }: RequestError) => error.message)
          .join(", ");
      } else {
        setErrorValues({
          counter: errorValues.counter + 1,
          errorMessages: [],
          errorStatus: accountState.account_error.status,
        });
        amplitudeErrorMsg = "Network Error";
      }
      if (npiFormValues.npiValue) {
        (accountState.account_error?.data ?? []).forEach(
          ({ error }: RequestError) => {
            if (error.code === INVALID_NPI_ERROR.error.code) {
              const eventProperties = {
                "Event ID": CREATE_ACCOUNT_NPI_SAVE_FAILURE_EVENT_ID,
                "Error message": INVALID_NPI_ERROR.error.message,
              };
              sendAmplitudeEvent(
                CREATE_ACCOUNT_NPI_SAVE_FAILURE,
                eventProperties,
                null
              );
            }
          }
        );
      }
      sendAmplitudeEvent(
        CREATE_ACCOUNT_ERROR,
        {
          "Event Id": CREATE_ACCOUNT_ERROR_EVENT_ID,
          "error message": amplitudeErrorMsg,
        },
        null
      );
    }
  }, [accountState.account_error]);

  React.useEffect(() => {
    const { accountResponse } = accountState;
    if (!isFresh && accountResponse) {
      const eventObj: EventObject = {
        "Event Id": CREATE_ACCOUNT_SUCCESS_EVENT_ID,
        "First Name": formValues.firstName,
        "Last Name": formValues.lastName,
        "Email Address": formValues.email,
        Occupation: formValues.occupation.text,
        Country: formValues.country.name,
        "Postal Code": formValues.zipCode,
        Specialty: npiFormValues.specialty ? npiFormValues.specialty.text : "",
        Subspecialty: npiFormValues.subSpecialty
          ? npiFormValues.subSpecialty.text
          : "",
        Tools: toolsLocation.current.tools,
        Location: toolsLocation.current.location,
      };
      if (marketingSource.current != null) {
        eventObj["Conference Source"] = marketingSource.current;
      }
      if (npiFormValues.npiValue) {
        sendAmplitudeEventData(
          null,
          CREATE_ACCOUNT_NPI_SAVE_SUCCESS,
          CREATE_ACCOUNT_NPI_SAVE_SUCCESS_EVENT_ID
        );
      }
      sendAmplitudeEvent(
        CREATE_ACCOUNT_SUCCESS,
        eventObj,
        String(accountResponse.userId)
      );
      const value: UserInformation = {
        userId: accountResponse.userId,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        occupation: formValues.occupation,
        specialty: npiFormValues.specialty,
        subSpecialty: npiFormValues.subSpecialty,
        profileOccupation: formValues.occupation,
        profileSpecialty: npiFormValues.specialty,
        profileSubSpecialty: npiFormValues.subSpecialty,
        npiNum: npiFormValues.npiValue,
        addresses: {
          work: {
            country: formValues.country.name ?? "",
            zipcode: formValues.zipCode,
          },
        },
        password: "",
      };

      updateUserPropertiesForSegment(accountResponse.userId.toString(), value);
      updateAccountTypeForSegment(
        accountResponse.userId.toString(),
        ACCOUNT_TYPES.EPOCRATES_ACCOUNT
      );
      const decisions = optimizely?.decideAll(
        undefined,
        accountResponse.userId.toString()
      );
      updateOptimizelyFlagsForSegment(
        accountResponse.userId.toString(),
        decisions
      );
      setAccesstoken(accountResponse.tokens.accessToken);
      setCookie("sessionTimer", 24, "sessionTimer");
      setRefreshToken(accountResponse.tokens.refreshToken);
      if (accountResponse.userId) {
        setCookie("currentUserId", 24, accountResponse.userId.toString());
        localStorage.setItem(
          CURRENT_USER_ID,
          accountResponse.userId.toString()
        );
        setCookie(STAY_LOGGED_IN, 24, "false");
        localStorage.setItem(STAY_LOGGED_IN, "false");
      }
      localStorage.setItem(SHOW_WELCOME_BANNER, JSON.stringify(true));
      if (referNextUrl) {
        window.location.href = referNextUrl;
      }
      if (!isInternationalUser(formValues.country?.iso3166alpha2)) {
        props.history.push("/account/subscription");
      } else {
        const eolURL = `${config.environment.eolURL}`;
        window.location.href = eolURL;
      }
    }
  }, [accountState.accountResponse]);
  React.useEffect(() => {
    if (isFresh) {
      if (props.location.search) {
        props.history.replace(
          filterQueryParameters(props.location.search, ["refernext"])
        );
      }
    } else {
      const queryObj: QueryObj = getQueryObjFromString(props.location.search);
      const view = queryObj.view ?? SIGNUP_VIEW;
      let request;
      switch (view) {
        case SIGNUP_VIEW:
          setCurrentView(SIGNUP_VIEW);
          break;
        case CLAIM_PROFILE_VIEW:
          request = {
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            limit: npiProfileLimit,
            postal_code: formValues.zipCode,
          };
          dispatch(npiLookupAction(request));
          break;
        case NPI_VIEW:
          if (!isStudent) {
            setCurrentView(NPI_VIEW);
          }
          break;
        default:
          setCurrentView(SIGNUP_VIEW);
      }
    }
  }, [props.location]);

  switch (currentView) {
    case SIGNUP_VIEW:
      element = (
        <CreateAccountForm
          isParentFresh={isFresh}
          formValues={formValues}
          onContinue={onSignupContinue}
          inProgress={
            accountState?.account_progress ||
            accountState?.specialties_progress ||
            npiState?.progress
          }
          savedOccupation={formValues.occupation?.id}
          isButtonDisabled={errorValues.counter > 1}
          setErrorState={setErrorValues}
          errorValues={errorValues}
          showNpi={showNpi}
          isGroupPurchaseNewUser={routerState?.data?.isGroupPurchaseNewUser}
          email={routerState?.data?.email}
          defaultCountry={
            routerState?.data?.isGroupPurchaseNewUser
              ? {
                  iso3166alpha2: "US",
                  id: 10,
                  name: "United States of America",
                }
              : {
                  iso3166alpha2: "",
                  id: -1,
                  name: "Select...",
                }
          }
        />
      );

      title = routerState?.data?.isGroupPurchaseNewUser
        ? strings.finish_your_account
        : strings.create_your_account;
      isTermsTextVisible = true;
      break;
    case CLAIM_PROFILE_VIEW:
      element = (
        <CreateAccountNPIProfile
          linkClickHandler={linkClickHandler}
          occupation={formValues.occupation?.id}
          profile="showProfile"
          profiles={profiles}
          profileClickHandler={profileClickHandler}
          isButtonDisabled={errorValues.counter > 1}
          errorState={setErrorValues}
          errorValues={errorValues}
        />
      );
      title = strings.claim_your_profile;
      subTitle = strings.claim_description;
      break;
    case NPI_VIEW:
      element = (
        <CreateAccountNPIProfile
          occupation={formValues.occupation?.id}
          profile="npiEnter"
          profileDetail={profileDetail}
          createAccountClickHandler={npiCreateAccount}
          inProgress={accountState?.account_progress}
          specialties={accountState?.specialtiesResponse}
          isStudent={isStudent}
          isButtonDisabled={errorValues.counter > 1}
          errorState={setErrorValues}
          errorValues={errorValues}
          showNpi={showNpi}
        />
      );
      title = strings.npi_screen_title;
      subTitle = strings.npi_screen_subTitle;
      break;
    default:
      element = null;
      title = "";
  }
  return (
    <div className={clsx(classes.wrapper)}>
      <div className={clsx(classes.innerWrap)}>
        <Typography
          data-testid="title"
          variant="h5"
          component="h2"
          classes={{ root: classes.title }}
        >
          {title}
        </Typography>
        {subTitle && (
          <Typography
            data-testid="subTitle"
            classes={{ root: classes.subTitle }}
          >
            {subTitle}
          </Typography>
        )}
        {element}
        {errorValues?.errorStatus !== 0 && (
          <ErrorMessage errorValues={errorValues} />
        )}
        {isTermsTextVisible && (
          <Typography
            classes={{ root: clsx(classes.termsOfUseText) }}
            variant="body2"
          >
            By clicking Continue, you certify that you agree to the{" "}
            <a
              href="https://www.epocrates.com/termsofuse"
              target="_blank"
              data-testid="termsOfUse"
              onClick={onTermsOfUseClick}
              rel="noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="https://www.epocrates.com/privacy"
              target="_blank"
              data-testid="privacyPolicy"
              onClick={onPrivacyPolicyClick}
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and are NOT a citizen of nor located in the EU.
          </Typography>
        )}
      </div>
    </div>
  );
};
export const CreateAccountRightPaneWith = withRouter(
  withRoot(CreateAccountRightPane)
);
