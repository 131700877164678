import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthorizationDevicesRoute } from "src/components/AuthorizationDevicesRoute";
import { AccountRoute } from "./AccountRoute";
import { ManageAccountWithRoot } from "./ManageAccount";
import { OptimizelyDecision } from "@optimizely/react-sdk";

interface MatchParams {
  name: string;
}
interface DevicesRouterProps extends RouteComponentProps<MatchParams> {
  path: string;
  component: (props: any) => React.ReactElement;
  deviceManagementDecision: OptimizelyDecision;
}

const DevicesRouter = (props: DevicesRouterProps) => {
  const { component: Component, deviceManagementDecision, ...rest } = props;
  const queryParams = new URLSearchParams(props.location.search);
  const authorizationCode = queryParams.get("authorizationCode");
  const state = useSelector((globalState: any) => globalState);
  if (!authorizationCode) {
    return (
      <AccountRoute
        path="/account/devices"
        component={Component}
        deviceManagementDecision={deviceManagementDecision}
      />
    );
  }
  return (
    <AuthorizationDevicesRoute
      {...props}
      authorizationCode={authorizationCode}
      state={state.privateCmeRouter}
      component={ManageAccountWithRoot}
    />
  );
};

export const DevicesRoute = withRouter(DevicesRouter);
