import axios from "axios";
import {
  EntitlementAxiosResponse,
  EntitlementResponse,
} from "src/models/UserEntitlement";
import { config } from "../config/envConfig";
import { refreshAccess } from "../utils/cookies";
import {
  updateAccountTypeForSegment,
  ACCOUNT_TYPES,
} from "../utils/AmplitudeUtil";

const { entitlementUrl } = config.environment;

export const userEntitlementService =
  async (): Promise<EntitlementAxiosResponse> => {
    const instance = axios.create({
      baseURL: entitlementUrl,
      timeout: 30000,
    });

    const cookie = await refreshAccess();
    const userId = localStorage.getItem("currentUserId");
    return instance
      .get(`/user/${userId}/entitlements`, {
        headers: {
          Authorization: `${cookie}`,
        },
      })
      .then((response: EntitlementAxiosResponse) => {
        if (response.status >= 200 && response.status < 299) {
          updateAccountTypeForSegment(
            userId ?? "",
            response.data.entitlements?.features?.includes("epoc_plus")
              ? ACCOUNT_TYPES.EPOCRATES_PLUS_ACCOUNT
              : ACCOUNT_TYPES.EPOCRATES_ACCOUNT
          );
        }
        return response;
      })
      .catch((error: Error) => ({
        status: 500,
        data: {} as EntitlementResponse,
        error,
      }));
  };
