import React from "react";
import { SubscriptionDialog } from "./SubscriptionDialog";
import { makeStyles, Typography, Link, Theme } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "src/config/colorConfig";
import { config } from "src/config/envConfig";
import { strings } from "src/utils/Strings";
import ErrorIcon from "@material-ui/icons/Error";

interface ContactSupportForRefundProps {
  showModal: boolean;
  setShowModal: (isOpen: boolean) => void;
}

export const ContactSupportForRefund = (
  props: ContactSupportForRefundProps
) => {
  const { showModal, setShowModal } = props;
  const styles = makeStyles((theme: Theme) => ({
    modalBodyTitle: {
      fontSize: 24,
      fontWeight: 500,
      color: `${colors["--primary-midnight"]}`,
    },
    modalBodyRoot: {
      padding: `0 ${theme.spacing(6)}px ${theme.spacing(6)}px`,
    },
    modalBodyText: {
      fontSize: 16,
      lineHeight: 1.25,
      color: colors["--ui-slate"],
      marginTop: theme.spacing(8),
    },
    imagePadding: {
      padding: theme.spacing(6),
      display: "flex",
    },
    modalHeaderTitle: {
      padding: `0 ${theme.spacing(2)}px 0 `,
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
      color: `${colors["--primary-midnight"]}`,
    },
    supportLink: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.25,
      "&:hover": {
        color: colors["--primary-teal"],
        textDecoration: "none",
      },
      color: colors["--primary-teal"],
    },
    errorIcon: {
      color: colors["--accent-jellyfish"],
      width: 24,
      height: 24,
    },
  }));
  const classes = styles();
  return (
    <SubscriptionDialog
      open={showModal}
      setClose={() => setShowModal(false)}
      title={""}
      titleWithImage={
        <React.Fragment>
          <span className={clsx(classes.imagePadding)}>
            <ErrorIcon
              data-testid="dialog-title-image"
              className={clsx(classes.errorIcon)}
            />
            <Typography
              data-testid="dialog-title"
              className={clsx(classes.modalHeaderTitle)}
            >
              {strings.contact_support}
            </Typography>
          </span>
        </React.Fragment>
      }
      isTitleLarge={true}
      width={475}
    >
      <div
        data-testid="contact-support-dialog-body"
        className={clsx(classes.modalBodyRoot)}
      >
        <Typography
          data-testid="contact-support-dialog-title"
          className={clsx(classes.modalBodyTitle)}
        >
          {strings.contact_support_for_refund}
        </Typography>
        <Typography
          data-testid="contact-support-dialog-content"
          className={clsx(classes.modalBodyText)}
        >
          Please contact our{" "}
          <Link
            target="_blank"
            className={clsx(classes.supportLink)}
            href={`${config.environment.eolURL}feedback`}
          >
            Support Team
          </Link>{" "}
          to initiate your refund process if you no longer live in the United
          States of America
        </Typography>
      </div>
    </SubscriptionDialog>
  );
};
