import React, { useEffect } from "react";

import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles } from "@material-ui/core";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import clsx from "clsx";
import { AppBackground } from "./custom/AppBackground";
import { LoginPasswordPageBody } from "./custom/LoginPasswordPageBody";
import { Button } from "./custom/Button";
import { TextField } from "./custom/TextField";
import { Footer } from "./custom/Footer";
import { strings } from "../utils/Strings";
import { QRFooter } from "./custom/QRFooter";
import { AppAltBackground } from "./custom/AppAltBackground";
import { colors } from "src/config/colorConfig";

export const ForgotPasswordComponent = (props: any) => {
  const style = makeStyles((theme) => ({
    form: {
      width: "100%",
    },
    textField: {
      width: "100%",
    },
  }));
  useEffect(() => {
    loadReCaptcha(props.siteKey);
  });
  const classes = style(props);
  const formBody = (
    <form className={clsx(classes.form)} onSubmit={props.handleSubmit}>
      <TextField
        name="email"
        testId="email"
        type="text"
        label={strings.login_email}
        helpTestId="emailHelp"
        validation={props.validation}
        isNewDesign={true}
      />
      <ReCaptcha
        sitekey={props.siteKey}
        action="main"
        size="normal"
        data-theme="dark"
        render="explicit"
        verifyCallback={props.verifyCallback}
      />
      <Button
        name="Submit"
        id="emailSubmit"
        type="submit"
        disabled={!props.isEnabled}
        inProgress={props.inProgress}
        padding={{ paddingTop: "20px" }}
        backgroundColor={colors["--primary-tealnight"]}
        height={48}
        fontFamily={"Source Sans Pro"}
        fontSize={18}
        hoverForeground={colors["--primary-tealnight"]}
      />
    </form>
  );
  const bodyTitle = "Forgot password";

  const pageBody = (
    <>
      <CssBaseline />
      <LoginPasswordPageBody title={bodyTitle} formBody={formBody} />
      <QRFooter />
    </>
  );
  return <AppAltBackground pageBody={pageBody} />;
};
