import {
  makeStyles,
  CssBaseline,
  withWidth,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import clsx from "clsx";
import { setCookie } from "../utils/cookies";
import { withRoot } from "../withRoot";
import { strings } from "../utils/Strings";
import { LoginPasswordPageBody } from "./custom/LoginPasswordPageBody";
import { Button } from "./custom/Button";
import "../styles/style.css";
import { config } from "../config/envConfig";
import { AppAltBackground } from "./custom/AppAltBackground";
import { QRFooter } from "./custom/QRFooter";
import { colors } from "src/config/colorConfig";

const PasswordResetSuccessPage = (props: any) => {
  useEffect(() => {
    setCookie("sessionTimer", 24, "sessionTimer");
  });

  const style = makeStyles((theme) => ({
    form: {
      width: "100%",
    },
    description: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(4),
      color: colors["--primary-midnight"],
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
  }));

  const handleSubmit = async (formEvent: any): Promise<void> => {
    formEvent.preventDefault();

    if (config.environment.legacyLoginToggle) {
      const url = `${config.environment.baseEpocratesUrl}/account.do`;
      window.location.replace(url);
    } else {
      props.history.push("/login");
    }
  };

  const classes = style(props);

  const form = (
    <form className={clsx(classes.form)} onSubmit={handleSubmit}>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        data-testid="continue"
        color="primary"
        id="continue"
        name="Back to Login"
        backgroundColor={colors["--primary-tealnight"]}
        height={48}
        fontFamily={"Source Sans Pro"}
        fontSize={18}
        hoverForeground={colors["--primary-tealnight"]}
      />
    </form>
  );
  const titleText = (
    <>
      <Typography
        className={classes.description}
        data-testid="title-header"
        variant="body1"
        style={{ width: "100%" }}
      >
        Please sign in to continue
      </Typography>
    </>
  );
  const pageBody = (
    <>
      <CssBaseline />
      <LoginPasswordPageBody
        data-testid="loginPasswordPageBody"
        title={strings.password_reset_success_message}
        formBody={form}
        titleText={titleText}
      />
      <QRFooter />
    </>
  );

  return <AppAltBackground pageBody={pageBody} />;
};

export const PasswordResetSuccessPageWith = withWidth()(
  withRoot(PasswordResetSuccessPage)
);
