import { isOfType } from "../utils/typeguard";

export interface LicensePurchaseResponse {
  data: LicensePurchaseDetails;
  status: number;
}
export function isLicensePurchaseResponse(
  obj: unknown
): obj is LicensePurchaseResponse {
  const purchaseDetailsResponse = obj as LicensePurchaseDetails;
  return (
    isOfType<LicensePurchaseResponse>(purchaseDetailsResponse, ["data"]) &&
    isLicensePurchaseDetails(purchaseDetailsResponse?.data)
  );
}

export interface LicensePurchaseDetails {
  subtotal: number;
  promoPercentageOff: number;
  promoDiscount: number;
  tax: number;
  total: number;
  msrpTotal: number;
  licenseCode?: string;
}
export function isLicensePurchaseDetails(
  obj: unknown
): obj is LicensePurchaseDetails {
  const purchaseDetails = obj as LicensePurchaseDetails;
  return isOfType<LicensePurchaseDetails>(purchaseDetails, [
    "subtotal",
    "total",
    "promoPercentageOff",
    "promoDiscount",
    "tax",
    "msrpTotal",
  ]);
}
