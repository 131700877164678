import { ChangeEvent, FormEvent, useState, Dispatch } from "react";
import { Checkbox, Link, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../config/colorConfig";
import {
  CLICK_EMAIL_ADDRESS,
  CLICK_EMAIL_ADDRESS_EVENT_ID,
  CLICK_FORGOT_PASSWORD,
  CLICK_FORGOT_PASSWORD_EVENT_ID,
  CLICK_PASSWORD,
  CLICK_PASSWORD_EVENT_ID,
  FAIL_SIGN_IN,
  FAIL_SIGN_IN_EVENT_ID,
  SUCCESSFULLY_SIGN_IN,
  SUCCESSFULLY_SIGN_IN_EVENT_ID,
  CANCEL_SIGN_IN,
  CANCEL_SIGN_IN_EVENT_ID,
} from "../utils/analytics";
import { strings } from "../utils/Strings";
import { Button } from "./custom/Button";
import { SubscriptionDialog } from "./SubscriptionDialog";
import { Password } from "./custom/PasswordField";
import { TextField } from "./custom/TextField";
import { Divider } from "./custom/Divider";
import { globalDialogStyles } from "src/styles/globalDialogStyles";
import { useMutation } from "@tanstack/react-query";
import { authenticationAsyncService } from "src/services/authenticationService";
import {
  CURRENT_USER_NAME,
  CURRENT_USER_ID,
  STAY_LOGGED_IN,
} from "../utils/localStorageKeys";
import { setCookie } from "src/utils/cookies";
import { useDispatch } from "react-redux";
import { sendSuccessSnackBarMessage } from "src/actions/snackBarActions";
import { SnackBarType } from "src/utils/SnackBarType";
import { LicenseFormAction } from "src/container/LicenseCodeForm";
import { getUserDetailsAction } from "src/actions/profileActions";
import {
  sendAmplitudeEvent,
  sendAmplitudeEventDataBySource,
  getMarketingSource,
} from "src/utils/AmplitudeUtil";

interface GuestSignInFormProps {
  profileEmail?: string;
  email?: string;
  loginScreenOption: boolean;
  dispatch: Dispatch<LicenseFormAction>;
  width?: string;
  height?: string;
}

interface EventObject {
  "Event ID": string;
  "Email ID": string;
  "QR Token": string | null;
  Source: string;
  Location: string | null;
  Method: string;
  "Conference Source"?: string;
}

export const GuestSignInForm = (props: GuestSignInFormProps): JSX.Element => {
  const dialogBoxStyles = makeStyles((theme) => ({
    signInForm: {
      width: props.width ?? 440,
      height: props.width ?? 580,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
    contentAlignment: {
      margin: theme.spacing(10),
      gap: 30,
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
      color: `${colors["--primary-midnight"]}`,
      textAlign: "center",
      lineHeight: "32px",
      margin: ` 0 ${theme.spacing(6)}px ${theme.spacing(2)}px ${theme.spacing(
        6
      )}px`,
    },
    checkBoxChecked: {
      color: `${colors["--ui-gray"]} !important`,
    },
    forgotPassword: {
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px 0`,
      textAlign: "center",
    },
    forgotPasswordLink: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.25,
      "&:hover": {
        color: colors["--primary-teal"],
        textDecoration: "none",
      },
      color: colors["--primary-teal"],
    },
    linePadding: {
      margin: `${theme.spacing(3)}px ${theme.spacing(6)}px ${theme.spacing(
        4
      )}px  ${theme.spacing(6)}px`,
    },
    checkBoxContainer: {
      marginBottom: theme.spacing(3),
    },
    checkBoxTitle: {
      fontSize: 16,
      fontWeight: 400,
      paddingLeft: theme.spacing(1),
    },
    cancelDiv: {
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px 0`,
      textAlign: "center",
    },
    cancelLink: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.25,
      "&:hover": {
        color: colors["--primary-midnight"],
        textDecoration: "none",
      },
      color: colors["--primary-midnight"],
    },
  }));

  const [stayLoggedIn, setStayLoggedIn] = useState(false);

  const dispatchRedux = useDispatch();

  const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStayLoggedIn(event?.currentTarget?.checked);
    localStorage.setItem(
      "stay_logged_in",
      event?.currentTarget?.checked.toString()
    );
  };

  const loginQuery = useMutation(authenticationAsyncService, {
    onSuccess: (responseData) => {
      if (responseData.status === 401 || responseData.status === 457) {
        const eventProperties = {
          "Event ID": FAIL_SIGN_IN_EVENT_ID,
          Email: props.email,
          Code: responseData.status,
          Message:
            responseData.status === 401 ? "unauthorized" : "error in login",
          Source: "Group Licensing",
          HttpStatus: responseData.status,
          "Error Type":
            responseData.status === 401 ? "unauthorized" : "error in login",
        };
        sendAmplitudeEvent(FAIL_SIGN_IN, eventProperties);
        dispatchRedux(
          sendSuccessSnackBarMessage(
            "Login failed",
            strings.login_error_message,
            SnackBarType.ERROR.displayValue
          )
        );
      }
      if (responseData.status >= 200 && responseData.status <= 299) {
        //user reducer to update status
        const authResponse = responseData.data;
        localStorage.setItem(CURRENT_USER_NAME, authResponse.email);
        const userId = authResponse.userId?.toString();
        localStorage.setItem(CURRENT_USER_ID, userId);
        localStorage.setItem(STAY_LOGGED_IN, stayLoggedIn.toString());
        setCookie("sessionTimer", 24, "sessionTimer");
        setCookie("stayLoggedIn", 24, stayLoggedIn.toString());
        setCookie("accessToken", 24, authResponse.tokens.accessToken);
        setCookie("refreshToken", 24, authResponse.tokens.refreshToken);
        setCookie("currentUserId", 24, userId);
        const properties: EventObject = {
          "Event ID": SUCCESSFULLY_SIGN_IN_EVENT_ID,
          "Email ID": authResponse.email,
          "QR Token": null,
          Method: "Email",
          Source: "Group Licensing",
          Location: "EOL",
        };
        const conferenceSource = getMarketingSource();
        if (conferenceSource != null) {
          properties["Conference Source"] = conferenceSource;
        }
        sendAmplitudeEvent(SUCCESSFULLY_SIGN_IN, properties, userId);
        dispatchRedux(getUserDetailsAction());
        props.dispatch({
          type: "continue",
          profileStale: true,
        });
        dispatchRedux(
          sendSuccessSnackBarMessage(
            "Sign in successful",
            `You’ve successfully signed into ${props.email}`,
            SnackBarType.SUCCESS.displayValue
          )
        );
      }
    },
    onError: () => {
      dispatchRedux(
        sendSuccessSnackBarMessage(
          "Login failed",
          strings.system_error,
          SnackBarType.ERROR.displayValue
        )
      );
    },
  });
  const onEmailFocus = () => {
    sendAmplitudeEventDataBySource(
      null,
      CLICK_EMAIL_ADDRESS,
      CLICK_EMAIL_ADDRESS_EVENT_ID,
      "Group Licensing"
    );
  };
  const onPasswordFocus = () => {
    sendAmplitudeEventDataBySource(
      null,
      CLICK_PASSWORD,
      CLICK_PASSWORD_EVENT_ID,
      "Group Licensing"
    );
  };
  const onForgotPasswordClick = () => {
    sendAmplitudeEventDataBySource(
      null,
      CLICK_FORGOT_PASSWORD,
      CLICK_FORGOT_PASSWORD_EVENT_ID,
      "Group Licensing"
    );
  };
  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const userEmail = formData.get("email");
    const password = formData.get("password");
    if (typeof userEmail === "string" && typeof password === "string") {
      loginQuery.mutateAsync({
        email: userEmail,
        password,
      });
    }
  };

  const onCancel = () => {
    const eventProperties = {
      "Event ID": CANCEL_SIGN_IN_EVENT_ID,
      Source: "Group Licensing",
      Location: "EOL",
    };
    sendAmplitudeEvent(CANCEL_SIGN_IN, eventProperties);
    props.dispatch({
      type: "cancel",
    });
  };
  const onContinue = () => {
    props.dispatch({
      type: "cancel",
    });
  };

  const validateInput = (data: string) => {
    if (!data) {
      return strings.email_blank_message;
    }
    return null;
  };
  const validatePasswordInput = (data: string) => {
    if (!data) {
      return strings.password_blank_message;
    }
    return null;
  };
  const classes = dialogBoxStyles();
  const globalClasses = globalDialogStyles();

  return (
    <SubscriptionDialog
      open={props.loginScreenOption}
      setClose={onCancel}
      handleCloseEvent={onCancel}
      handlesOwnTitle={true}
      width={600}
      isTitleLarge
      title={strings.login_signin_title}
    >
      <div className={clsx(classes.signInForm)}>
        <div className={clsx(classes.contentAlignment)}>
          <form onSubmit={onSubmit}>
            <Typography className={clsx(classes.title)}>
              Sign in to continue <br />
              with this account
            </Typography>
            <TextField
              autoCapitalize="off"
              name="email"
              testId="email"
              required
              label={strings.login_email}
              isNewDesign={true}
              value={props.email}
              variant="outlined"
              onClickField={onEmailFocus}
              validationMessage={strings.email_blank_message}
              validation={validateInput}
            />
            <Password
              labelTestId="passwordLabel"
              labelHtmlFor="outlined-adornment-password"
              isNewDesign={true}
              labelTitle={strings.login_password}
              name="password"
              testId="password"
              ariaLabel="toggle password visibility"
              iconTestId="showPasswordId"
              onClickEvent={onPasswordFocus}
              validateInput={validatePasswordInput}
            />
            <div
              className={clsx(
                globalClasses.flexContainer,
                classes.checkBoxContainer
              )}
            >
              <Checkbox
                name="staySignedIn"
                classes={{
                  colorSecondary: clsx(classes.checkBoxChecked),
                }}
                onChange={onHandleChange}
                data-testid="staySignedIn"
              />
              <Typography className={clsx(classes.checkBoxTitle)}>
                Stay signed in
              </Typography>
            </div>
            <div>
              <Button
                name="Sign in"
                id="signIn"
                backgroundColor={colors["--primary-tealnight"]}
                hoverForeground={colors["--primary-tealnight"]}
                height="52px"
                type="submit"
                inProgress={loginQuery.isLoading}
                data-testid="signIn"
              />
            </div>
          </form>
          <div className={clsx(classes.forgotPassword)}>
            <Link
              className={clsx(classes.forgotPasswordLink)}
              href="/forgotPassword"
              rel="forgotPassword"
              id="forgotPassword"
              data-testid="forgotPassword"
              onClick={onForgotPasswordClick}
            >
              Forgot your password
            </Link>
          </div>
          {props.profileEmail && (
            <>
              <div className={clsx(classes.linePadding)}>
                <Divider>or</Divider>
              </div>

              <Button
                name={`Continue as ${props.profileEmail}`}
                id="cancelGuestNewLogin"
                border="1px solid #06162d"
                height="52px"
                backgroundColor={colors["--white"]}
                hoverForeground={colors["--white"]}
                foregroundColor={colors["--primary-midnight"]}
                type="button"
                onClick={onContinue}
                data-testid="cancelLogin"
              />
            </>
          )}
          <div className={clsx(classes.cancelDiv)}>
            <Link
              className={clsx(classes.cancelLink)}
              href="#"
              rel="cancel"
              id="cancel"
              onClick={onCancel}
            >
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </SubscriptionDialog>
  );
};
