import * as React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialButton from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { withRoot } from "../withRoot";
import { strings } from "../utils/Strings";
import { CheckBox } from "./custom/CheckBox";
import { Button } from "./custom/Button";
import { sendAmplitudeEventData } from "../utils/AmplitudeUtil";
import {
  EMAIL_PREFERENCE_UNSUBSCRIBE_ALL,
  EMAIL_PREFERENCE_UNSUBSCRIBE_ALL_EVENT_ID,
} from "../utils/analytics";
import {
  getEmailPreferenceAction,
  updateEmailPreferenceAction,
} from "../actions/emailPreferenceActions";
import {
  EmailPreferenceState,
  EmailPreferenceResponse,
  EmailPreference,
  EmailPreferences,
} from "../models/EmailPreference";
import { colors } from "../config/colorConfig";
import { httpConstants } from "../utils/httpConstants";

interface EmailPreferenceProps {
  emailPreferenceValues: EmailPreferenceResponse;
}

const AccountEmailPreference = (props: EmailPreferenceProps) => {
  const [emailState, setEmailState] = React.useState<EmailPreferenceResponse>(
    props.emailPreferenceValues
  );
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [isInprogress, setIsInprogress] = React.useState(false);
  const emailPreferenceResponseState = useSelector(
    (emailPreferenceState: EmailPreferenceState) =>
      emailPreferenceState.emailPreference
  );
  const dispatch = useDispatch();
  const styles = makeStyles((theme) => ({
    wrapper: {
      flexGrow: 0,
      flexShrink: 0,
      padding: "24px 24px 24px",
      [theme.breakpoints.down("md")]: {
        minHeight: "auto",
        marginTop: 0,
      },
    },
    title: {
      width: "113px",
      height: "22px",
      fontFamily: "Metric",
      marginBottom: "21px",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: 1.38,
      color: colors["--title-black"],
    },
    unsubscribeLink: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: 1.25,
      color: colors["--link-blue"],
      textTransform: "none",
      paddingLeft: theme.spacing(4),
      "&:focus": {
        outline: 0,
        border: 0,
      },
      "&:hover": {
        background: "none",
      },
    },
    emailPreferenceFormCheckBox: {
      display: "table",
    },
    emailPreferences: {
      display: "table-cell",
    },
    emailPreferencesLabel: {
      fontFamily: "Metric",
      fontSize: "16px",
      display: "block",
      lineHeight: 1.25,
      alignItems: "flex-start",
    },
    inlineDisplay: {
      display: "flex",
      padding: "24px 24px 24px",
    },
  }));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const emailPrefArray = emailState?.emailPreferences?.map((item) => {
        const emailPref: EmailPreference = item;
        if (emailPref.id === event.target.name) {
          emailPref.optIn = event.target.checked;
        }
        return {
          ...emailPref,
        };
      });

      setEmailState({ ...emailState, emailPreferences: emailPrefArray });
      setIsButtonDisabled(false);
    }
  };
  React.useEffect(() => {
    dispatch(getEmailPreferenceAction());
  }, []);
  React.useEffect(() => {
    if (emailPreferenceResponseState) {
      setIsInprogress(emailPreferenceResponseState.email_preference_progress);
      if (
        emailPreferenceResponseState.responseStatus &&
        emailPreferenceResponseState.responseStatus !== httpConstants.OK
      ) {
        setIsButtonDisabled(false);
      }
    }
  }, [emailPreferenceResponseState]);

  React.useEffect(() => {
    if (
      emailPreferenceResponseState &&
      emailPreferenceResponseState.emailPreferences
    ) {
      setEmailState({
        ...emailState,
        emailPreferences: emailPreferenceResponseState.emailPreferences,
      });
    }
  }, [emailPreferenceResponseState?.emailPreferences]);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const updateEmailPreference = () => {
    const emailPreferenceRequest: EmailPreferences = {
      emailPreferences: emailState?.emailPreferences ?? [],
    };
    dispatch(updateEmailPreferenceAction(emailPreferenceRequest));
    setIsButtonDisabled(true);
  };

  const unsubscribeFromAll = () => {
    const optedInEmailPrefLength: number =
      emailState?.emailPreferences?.filter((emailPref) => emailPref.optIn)
        .length ?? 0;
    if (optedInEmailPrefLength > 0) {
      sendAmplitudeEventData(
        null,
        EMAIL_PREFERENCE_UNSUBSCRIBE_ALL,
        EMAIL_PREFERENCE_UNSUBSCRIBE_ALL_EVENT_ID
      );

      const unsubscribeEmailPreferences = emailState?.emailPreferences?.map(
        (obj: EmailPreference) => ({
          ...obj,
          optIn: false,
        })
      );
      setEmailState({
        ...emailState,
        emailPreferences: unsubscribeEmailPreferences,
      });
      setIsButtonDisabled(false);
    }
  };

  const classes = styles(props);

  let element = null;

  if (emailState) {
    if (emailState.emailPreferences) {
      element = (
        <>
          <form onSubmit={handleSubmit} className={clsx(classes.wrapper)}>
            <Typography
              data-testid="emailPreferenceTitle"
              className={clsx(classes.title)}
            >
              {strings.emailPreferences}
            </Typography>
            {emailState?.emailPreferences &&
              emailState.emailPreferences.map(({ id, displayText }) => (
                <FormControlLabel
                  className={clsx(classes.emailPreferenceFormCheckBox)}
                  control={
                    <div className={clsx(classes.emailPreferences)}>
                      <CheckBox
                        name={id}
                        labelPlacement="start"
                        checked={
                          emailState?.emailPreferences?.find(
                            (obj) => obj.id === id
                          )?.optIn ?? false
                        }
                        onChange={handleChange}
                      />
                    </div>
                  }
                  label={
                    <Typography className={clsx(classes.emailPreferencesLabel)}>
                      {displayText}
                    </Typography>
                  }
                />
              ))}
            <div className={clsx(classes.inlineDisplay)}>
              <Button
                name="Update"
                id="update"
                type="submit"
                fontFamily="Metric"
                onClick={updateEmailPreference}
                disabled={isButtonDisabled}
                inProgress={isInprogress}
              />
              <MaterialButton
                id="unsubscribe"
                data-testid="unsubscribe"
                onClick={unsubscribeFromAll}
                classes={{ root: clsx(classes.unsubscribeLink) }}
                disableRipple
              >
                {strings.unsubscribe}
              </MaterialButton>
            </div>
          </form>
        </>
      );
    } else if (
      props.emailPreferenceValues &&
      props.emailPreferenceValues.responseStatus !== httpConstants.OK
    ) {
      element = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ClipLoader
            data-testid="progressValidationSpinner"
            size="45px"
            color={colors["--accent-violet"]}
            loading
          />
        </div>
      );
    }
  } else {
    element = <> Something went wrong please try again </>;
  }
  return <> {element} </>;
};

export const AccountEmailPreferenceWithRoot = withRoot(AccountEmailPreference);
