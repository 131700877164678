import { List, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Device } from "../models/Device";
import infoIcon from "../images/info-icon.svg";
import infoIconHover from "../images/info-icon-hover.svg";
import infoIconFocused from "../images/info-icon-focused.svg";
import infoIconDisabled from "../images/info-icon-disabled.svg";
import { colors } from "src/config/colorConfig";
import { DeviceListItem } from "./DeviceListItem";
import {
  ClickAwayListener,
  Theme,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useState } from "react";
import { withRoot } from "src/withRoot";
import { strings } from "../utils/Strings";
import { sendAmplitudeEvent } from "src/utils/AmplitudeUtil";
import { TOOLTIP_OPEN, TOOLTIP_DISMISSAL_EVENT } from "src/utils/analytics";
import { fontConstants } from "src/utils/fontConstants";
interface Props {
  title: string;
  listItems: Device[];
  maxDevices: number | string;
  signedInDevices: number;
  signOutDevice: (deviceId: string, deviceName: string) => void;
}

const DevicesCard = (props: Props) => {
  const { title, listItems, maxDevices, signedInDevices, signOutDevice } =
    props;
  const [open, setOpen] = useState(false);
  const [tooltipState, setTooltipState] = useState("enabled");

  const onToolTipOpen = () => {
    const properties = {
      "Tooltip Text": strings.devices_info_tooltip_content,
      "Tooltip Interaction": "Open",
      Location: "Devices",
    };
    sendAmplitudeEvent(TOOLTIP_OPEN, properties, null);
  };

  const onToolTipClose = (reason: string) => {
    const properties = {
      "Dismissal Reason": reason,
      "Tooltip Text": strings.devices_info_tooltip_content,
      "Tooltip Interaction": "Close",
      Location: "Devices",
    };
    sendAmplitudeEvent(TOOLTIP_DISMISSAL_EVENT, properties, null);
  };

  const toolTipIcon = () => {
    switch (tooltipState) {
      case "enabled":
        return infoIcon;
      case "hover":
        return infoIconHover;
      case "focused":
        return infoIconFocused;
      case "disabled":
        return infoIconDisabled;
    }
  };

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => {
    setOpen(true);
    setTooltipState("focused");
    onToolTipOpen();
  };
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const devicesCardStyle = makeStyles(() => ({
    tooltipPopper: {
      borderRadius: 4,
      marginLeft: 14,
      marginBottom: "40px",
    },
    arrow: {
      color: `${colors["--primary-off-white"]} !important`,
      marginBottom: "4px",
    },
    tooltipLabelWrapper: {
      boxShadow: `0 2px 6px -1px rgba(0, 0, 0, 0.1), 0 1px 12px 0 rgba(0, 0, 0, 0.06)`,
      backgroundColor: `${colors["--primary-off-white"]} !important`,
      color: `${colors["--primary-midnight"]} !important`,
      fontSize: "16px !important",
      fontFamily: `${fontConstants.METRIC} !important`,
      padding: "8px 16px !important",
      fontWeight: "normal !important" as any,
      margin: "12px !important",
    },
    boxContainer: {
      padding: "0px",
    },
  }));
  const classes = devicesCardStyle();

  const DeviceErrorComponent = (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Typography
        fontFamily="Metric"
        fontSize={16}
        fontWeight={500}
        color={colors["--primary-midnight"]}
        pl="2px"
      >
        {strings.app_device_error_title}
      </Typography>
      <Typography
        fontFamily="Metric"
        fontSize={14}
        fontWeight={400}
        color={colors["--primary-midnight"]}
        whiteSpace={"pre-wrap"}
        pl="2px"
      >
        {strings.app_device_error_description}
      </Typography>
    </Box>
  );
  return (
    <Box className={classes.boxContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          fontFamily="Metric"
          fontSize={20}
          fontWeight={500}
          color={colors["--primary-midnight"]}
          pl="2px"
        >
          {title}
        </Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            fontFamily="Metric"
            fontSize={14}
            fontWeight={400}
            color={colors["--primary-midnight"]}
            data-testid="signed-in-devices-count"
          >
            {signedInDevices}/{maxDevices} Devices
          </Typography>
          <a>
            {isTablet ? (
              <ClickAwayListener
                mouseEvent={false}
                onClickAway={() => {
                  handleTooltipClose();
                  setTooltipState("enabled");
                  if (open) {
                    onToolTipClose("user closed tooltip");
                  }
                }}
              >
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    arrow
                    placement="top"
                    classes={{
                      popper: classes.tooltipPopper,
                      tooltip: classes.tooltipLabelWrapper,
                      arrow: classes.arrow,
                    }}
                    onClose={() => {
                      handleTooltipClose();
                      setTooltipState("enabled");
                      onToolTipClose("user closed tooltip");
                    }}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={strings.devices_info_tooltip_content}
                  >
                    <img
                      alt="info icon"
                      src={toolTipIcon()}
                      onClick={handleTooltipOpen}
                    ></img>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            ) : (
              <Tooltip
                arrow
                placement="top"
                classes={{
                  popper: classes.tooltipPopper,
                  tooltip: classes.tooltipLabelWrapper,
                  arrow: classes.arrow,
                }}
                onOpen={() => {
                  setTooltipState("hover");
                  onToolTipOpen();
                }}
                onClose={() => {
                  setTooltipState("enabled");
                  onToolTipClose("user closed tooltip");
                }}
                title={strings.devices_info_tooltip_content}
              >
                <img alt="info icon" src={toolTipIcon()}></img>
              </Tooltip>
            )}
          </a>
        </Box>
      </Box>
      <List>
        {listItems.length > 0
          ? listItems.map((device) => (
              <DeviceListItem {...device} signOutDevice={signOutDevice} />
            ))
          : DeviceErrorComponent}
      </List>
    </Box>
  );
};
export const DevicesCardWithRoot = withRoot(DevicesCard);
