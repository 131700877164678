import { Box, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { UseQueryResult } from "@tanstack/react-query";
import clsx from "clsx";
import { History } from "history";
import { Product, V2Subscription } from "src/services/subscriptionService";
import { licenseStyles } from "src/styles/licenseStyles";
import { strings } from "../utils/Strings";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";
import plusLogo from "./../images/epocrates_plus.png";
import { IPrimaryPaymentInformation } from "src/models/PrimaryPaymentMethodResponse";
import { colors } from "../config/colorConfig";
import { Button } from "./custom/Button";
import React, { memo, useContext, useEffect, useState } from "react";
import { SubscriptionTabNav } from "./SubscriptionTabNav";
import { CardNumber } from "./custom/CardNumber";
import { ButtonLink } from "./custom/ButtonLink";
import { default as dayjs } from "dayjs";
import {
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CLICK,
  ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CONFIRMATION_VIEW,
  ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_CLICK,
  ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CLICK_MONTHLY,
  ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CLICK_YEARLY,
  ACCOUNT_PAYMENT_SUBSCRIPTION_VIEW,
  ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_CLOSE_CANCEL,
  GROUP_LICENSING_LEARN_MORE_CLICK,
  ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CONTINUE_CLICK,
} from "../utils/analytics";
import { PageType, SubscriptionContext } from "src/context/SubscriptionContext";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import { config } from "../config/envConfig";
import { TransactionHistory } from "./TransactionHistory";
import { SubscriptionCancellationFirstFlow } from "./SubscriptionCancellationFirstFlow";
import { GetTransactionHistory } from "src/queries/PurchaseQueries";
import { SubscriptionDialog } from "./SubscriptionDialog";
import { GetPrimaryPaymentMethod } from "src/queries/PaymentMethodDetailQuery";
import { TransactionDetails } from "src/services/purchaseService";
import { OptimizelyDecision } from "@optimizely/react-sdk";
import { PurchaseRequestInfo } from "./UserSubscriptionLandingPage";
import { UserSubscriptionPurchaseContainer } from "./UserSubscriptionPurchaseContainer";
import { useTheme } from "@mui/material";

export const UserSubscriptionRightPaneV2 = (props: {
  history: History;
  isPremium: boolean;
  internationalUser: boolean;
  userSubscriptionQuery: UseQueryResult<V2Subscription[]>;
  userProductsQuery: UseQueryResult<Product[]>;
  primaryPaymentResponse: IPrimaryPaymentInformation | undefined;
  isPrimaryPaymentFetched: boolean;
  deviceManagementDecision: OptimizelyDecision;
  vindicia: {
    setup: () => void;
    destroy: () => void;
    isValid: () => boolean;
    resetCompleteStatus: () => void;
    clearData: () => void;
  };
  vindiciaFieldsSpinner: boolean;
}) => {
  const rightPaneStyle = makeStyles((theme) => ({
    pageWrapper: {
      margin: `${theme.spacing(4)}px 0`,
      padding: `${theme.spacing(6)}px`,
      backgroundColor: `${colors["--pure-white"]}`,
      width: 838,
      borderRadius: 4,
      boxShadow: theme.shadows[1],
      [theme.breakpoints.between("xs", "md")]: {
        width: "100%",
      },

      [theme.breakpoints.only("xs")]: {
        margin: 0,
        padding: `${theme.spacing(2)}px`,
        height: props.isPremium ? 1300 : 980,
        width: "100%",
      },
    },
    titleBoxWrapper: {
      height: "80px",
      [theme.breakpoints.down("xs")]: {
        padding: `${theme.spacing(4)}px`,
      },
    },
    navBoxWrapper: {
      height: "160px",
      margin: "12px 0px",
      [theme.breakpoints.only("xs")]: {
        height: "264px",
      },
    },
    planBoxWrapper: {
      height: "168px",
      [theme.breakpoints.only("xs")]: {
        height: "488px",
      },
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      padding: "0px",
      gap: 24,
      [theme.breakpoints.only("xs")]: {
        flexDirection: "column",
        width: "100%",
        padding: "0 16px",
      },
    },
    planItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "16px",
      gap: 8,
      width: 248,
      height: 168,
      backgroundColor: colors["--ui-grey-ultra-light"],
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      flexGrow: 1,
      [theme.breakpoints.between("sm", "md")]: {
        width: 216,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    planBoxHeaderText: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
    },
    planBoxSubText: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      textAlign: "center",
      paddingBottom: 8,
    },
    paymentSubscriptionWrapper: {
      display: "flex",
      gap: 64,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: 0,
        padding: "0 16px",
      },
    },
    paymentInfoWrapper: {
      marginRight: theme.spacing(20),
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
    },
    subscriptionInfo: {
      margin: `${theme.spacing(2)}px 0`,
      marginTop: theme.spacing(8),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(6),
      },
    },
    infoTitle: {
      color: colors["--primary-midnight"],
      fontWeight: 700,
    },
    paymentDetailsContainer: {
      display: "flex",
    },
    cardExpiry: {
      color: colors["--ui-slate"],
    },
    planTypeLabel: {
      fontWeight: 500,
      color: colors["--ui-slate"],
    },
    noMargin: {
      marginLeft: "0 !important",
    },
    paymentInfoLinkWrapper: {
      marginTop: theme.spacing(6),
    },
    mobileAppStoreLink: {
      fontSize: 16,
      fontWeight: 500,
      color: colors["--primary-teal"],
      "&:hover": {
        color: colors["--primary-teal"],
        textDecoration: "none",
      },
    },
  }));
  const userId = localStorage.getItem(CURRENT_USER_ID) ?? -1;
  const {
    setCurrentPage,
    setShowSelection,
    setUserNavigation,
    userNavigation,
    isPlusUser,
    initialProductSku,
  } = useContext(SubscriptionContext);
  const classes = rightPaneStyle();
  const commonClasses = licenseStyles();
  const [monthlyProduct, setMonthlyProduct] = React.useState<Product>();
  const [productSku, setProductSku] = React.useState("");
  const formatPrice = (price: number) => "$" + price.toFixed(2);
  const [cancelWorkflow, setCancelWorkFlow] = React.useState(false);
  const [cancelFirstflowDialog, setCancelFirstflowDialog] =
    React.useState(false);

  const nextSubscriptionDate = React.useRef("");
  const dummyProduct: Product = {
    additional_description: "startup product",
    best_value_priority: 1,
    display_order: 1,
    preselection_priority: 1,
    short_description: "startup product short description",
    platform_sku: "",
    billing_period: {
      unit: "DAY",
      quantity: 1,
    },
  };
  const {
    data: transactionHistoryResponse,
    isFetched: isTransactionHistoryFetched,
    dataUpdatedAt: transactionHistoryUpdatedAt,
  } = GetTransactionHistory(Number(userId));
  const primaryPaymentResponse = GetPrimaryPaymentMethod(
    Number(localStorage.getItem(CURRENT_USER_ID) ?? -1),
    props.deviceManagementDecision?.enabled
  );
  const [updatePaymentDialog, setUpdatePaymentDialog] = React.useState(false);
  const { vindicia } = window;
  const [vindiciaPaymentFail, setVindiciaPaymentFail] = useState(false);
  const [showPaymentScreen, setShowPaymentScreen] = useState(true);
  const [paymentMethodId, setPaymentMethodId] = useState<string>("");
  const queryParams = new URLSearchParams(window.location.search);
  const querySKU = queryParams.get("upsellSku") ?? undefined;
  const [promoCode, setPromoCode] = useState<string>(
    queryParams.get("promocode") ?? ""
  );
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (!isPlusUser) {
      if (
        querySKU &&
        props.userProductsQuery.isFetched &&
        props.userProductsQuery.data &&
        props.userProductsQuery.data.length > 0
      ) {
        const productExists = props.userProductsQuery.data.find(
          (product: Product) => product.platform_sku === querySKU
        );
        if (productExists) {
          initialProductSku.current = productExists;

          setCurrentPage(PageType.PaymentBilling);
        }
      }
    }
  }, [querySKU, props.userProductsQuery.dataUpdatedAt]);

  useEffect(() => {
    if (
      props.userProductsQuery.data &&
      props.userProductsQuery.data.length > 0
    ) {
      const monthlyProduct = props.userProductsQuery.data.filter(
        (product: Product) => product.billing_period.unit === "MONTH"
      );
      if (monthlyProduct.length > 0) setMonthlyProduct(monthlyProduct[0]);
    }
  }, [props.userProductsQuery.isSuccess]);

  useEffect(() => {
    if (props.userSubscriptionQuery.data) {
      if (props.userSubscriptionQuery.data.length > 0) {
        setProductSku(
          props.userSubscriptionQuery.data[0].activeProducts[0].sku
        );
        sendAmplitudeEvent(ACCOUNT_PAYMENT_SUBSCRIPTION_VIEW, {
          sku: props.userSubscriptionQuery.data[0].activeProducts[0].sku,
          subscriptionId: props.userSubscriptionQuery.data[0].id,
        });
      } else {
        sendAmplitudeEvent(ACCOUNT_PAYMENT_SUBSCRIPTION_VIEW, {
          sku: "Free",
          subscriptionId: "",
        });
      }
    }
  }, [props.userSubscriptionQuery.isSuccess]);

  const onYearlyClick = () => {
    setShowSelection(true);
    setCurrentPage(PageType.SelectSubscription);
    sendAmplitudeEvent(ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CLICK_YEARLY);
    setUserNavigation([...userNavigation, PageType.SelectSubscription]);
  };

  const onMonthlyClick = () => {
    initialProductSku.current = monthlyProduct;
    setShowSelection(false);
    setCurrentPage(PageType.PaymentBilling);
    setUserNavigation([...userNavigation, PageType.PaymentBilling]);
    sendAmplitudeEvent(ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CLICK_MONTHLY, {
      sku: monthlyProduct?.platform_sku,
      Currency: monthlyProduct?.price?.currency,
    });
    sendAmplitudeEvent(ACCOUNT_PAYMENT_SUBSCRIPTION_UPGRADE_CONTINUE_CLICK, {
      sku: monthlyProduct?.platform_sku,
      "Amount Before Tax": monthlyProduct?.price?.amount,
      Currency: monthlyProduct?.price?.currency,
    });
  };

  const defaultCalculateRequest: PurchaseRequestInfo = {
    userId: Number(localStorage.getItem(CURRENT_USER_ID) ?? -1),
    product: {
      additional_description: "startup product",
      best_value_priority: 1,
      display_order: 1,
      preselection_priority: 1,
      short_description: "startup product short description",
      platform_sku: "",
      billing_period: {
        unit: "DAY",
        quantity: 1,
      },
    },
    campaignCode: "",
  };
  const expDateFormat = (expDate: string): string => {
    if (expDate) {
      return dayjs(expDate).format("MM/YY");
    }
    return "";
  };

  const subscriptionRenewText =
    props.userSubscriptionQuery?.data?.[0]?.status === "ACTIVE"
      ? `Renews on: ${dayjs(
          props.userSubscriptionQuery?.data?.[0]?.ends
        ).format("MM/DD/YYYY")}`
      : `${
          props.userSubscriptionQuery?.data?.[0]?.status === "GRACE_PERIOD"
            ? "Grace period "
            : ""
        }Ends on: ${dayjs(props.userSubscriptionQuery?.data?.[0]?.ends).format(
          "MM/DD/YYYY"
        )}`;
  const onCancelClick = () => {
    sendAmplitudeEvent(ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CLICK);
    if (
      config.environment.isCancelFlowEnabled &&
      config.environment.isCancelFlowEnabled === "true"
    ) {
      setCancelWorkFlow(true);
      setCancelFirstflowDialog(true);
    }
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_CANCEL_SUBSCRIPTION_CONFIRMATION_VIEW
    );
  };

  const openUpdatePaymentDialog = () => {
    sendAmplitudeEvent(ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_CLICK);
    setUpdatePaymentDialog(true);
  };

  const closeUpdatePaymentDialog = () => {
    sendAmplitudeEvent(
      ACCOUNT_PAYMENT_SUBSCRIPTION_UPDATE_PAYMENT_CLOSE_CANCEL
    );
    setUpdatePaymentDialog(false);
  };
  const paymentAndSubscriptionInfo = (
    <div className={clsx(classes.paymentSubscriptionWrapper)}>
      <div
        data-testid="paymentInfoWrapper"
        className={clsx(classes.subscriptionInfo, classes.paymentInfoWrapper)}
      >
        <Typography
          data-testid="paymentInfoTitle"
          className={clsx(classes.infoTitle)}
          variant="h6"
        >
          Payment Information
        </Typography>
        <Box className={clsx(classes.paymentDetailsContainer)}>
          <CardNumber cardNumber={props.primaryPaymentResponse?.cardNumber} />
          <Typography
            data-testid="cardExpiry"
            className={clsx(classes.cardExpiry)}
          >
            {expDateFormat(props.primaryPaymentResponse?.expirationDate ?? "")}
          </Typography>
        </Box>
        <ButtonLink
          onClick={openUpdatePaymentDialog}
          testId="paymentUpdate"
          name="paymentUpdate"
          displayText={
            props.primaryPaymentResponse &&
            Object.keys(props.primaryPaymentResponse).length > 0
              ? strings.update_payment_method
              : strings.add_payment_method
          }
          className={classes.noMargin}
          data-testid="paymentUpdate"
        />
      </div>
      <div
        className={clsx(classes.subscriptionInfo)}
        data-testid="subscriptionInfoWrapper"
      >
        <Typography
          data-testid="subscriptionInfoTitle"
          className={clsx(classes.infoTitle)}
          variant="h6"
        >
          Subscription
        </Typography>
        <Typography
          data-testid="subscriptionPlan"
          className={clsx(classes.planTypeLabel)}
        >
          {"epocrates+ " +
            props.userSubscriptionQuery?.data?.[0]?.activeProducts[0]
              .displayName}
        </Typography>
        <Typography data-testid="renewal">{subscriptionRenewText}</Typography>

        {(props.userSubscriptionQuery?.data?.[0]?.status === "ACTIVE" ||
          props.userSubscriptionQuery?.data?.[0]?.status ===
            "GRACE_PERIOD") && (
          <>
            <Typography data-testid="total">
              {"Total: " +
                formatPrice(
                  props.userSubscriptionQuery?.data?.[0]?.renewalPrice
                    ?.amount ?? 179.99
                )}
            </Typography>
            <ButtonLink
              onClick={onCancelClick}
              testId="cancel"
              name="Cancel"
              displayText={strings.cancel_subscription}
              className={classes.noMargin}
            />
          </>
        )}
        <SubscriptionDialog
          open={updatePaymentDialog}
          setClose={closeUpdatePaymentDialog}
          title={
            props.primaryPaymentResponse &&
            Object.keys(props.primaryPaymentResponse).length > 0
              ? strings.update_payment_method
              : strings.add_payment_method
          }
          isTitleLarge
          width={isDesktop ? 320 : undefined}
          removeTabIndex
          isSpinnerActive={props.vindiciaFieldsSpinner}
          minWidth={isDesktop ? 520 : 300}
        >
          <UserSubscriptionPurchaseContainer
            vindicia={vindicia}
            product={dummyProduct}
            vindiciaFieldsSpinner={props.vindiciaFieldsSpinner}
            vindiciaPaymentFail={vindiciaPaymentFail}
            setVindiciaPaymentFail={setVindiciaPaymentFail}
            calculateLicenseResponse={{} as TransactionDetails}
            showPaymentScreen={showPaymentScreen}
            setShowPaymentScreen={setShowPaymentScreen}
            paymentMethodId={paymentMethodId}
            setPaymentMethodId={setPaymentMethodId}
            primaryPaymentResponse={primaryPaymentResponse}
            setUpdatePaymentDialog={setUpdatePaymentDialog}
            selectProduct={undefined}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
          />
        </SubscriptionDialog>
      </div>
    </div>
  );
  return (
    <div data-testid="pageWrapper" className={clsx(classes.pageWrapper)}>
      {/* add three row box Container */}
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box className={clsx(classes.titleBoxWrapper)}>
          <div>
            <img
              data-testid="logo"
              width={200}
              height={35}
              src={plusLogo}
              alt=""
            />
          </div>
          {!props.isPremium && (
            <div>
              <Typography
                data-testid="titleText"
                className={clsx(
                  commonClasses.fontWeight400,
                  commonClasses.fontSize24
                )}
                style={{ paddingTop: "10px" }}
              >
                Unlock premium content
              </Typography>
            </div>
          )}
        </Box>
        <Box className={clsx(classes.navBoxWrapper)}>
          <SubscriptionTabNav />
        </Box>
        {!props.isPremium &&
        props.userProductsQuery.data &&
        props.userProductsQuery.data.length > 0 ? (
          <Box
            className={clsx(classes.planBoxWrapper)}
            data-testid="planBoxWrapper"
          >
            <div className={clsx(classes.planItem)}>
              <Typography
                className={classes.planBoxHeaderText}
                data-testid="yearlyHeader"
              >
                Yearly
              </Typography>
              <Typography
                className={classes.planBoxSubText}
                data-testid="yearlyDescription"
              >
                {strings.yearly_additional_description}
              </Typography>
              <Button
                name="View pricing options"
                id="yearlyContinue"
                testId="yearlyContinue"
                backgroundColor={colors["--primary-tealnight"]}
                hoverForeground={colors["--primary-tealnight"]}
                width="200px"
                onClick={onYearlyClick}
              />
            </div>
            <div className={clsx(classes.planItem)}>
              <Typography
                className={classes.planBoxHeaderText}
                data-testid="monthlyHeader"
              >
                Monthly
              </Typography>
              <Typography
                className={classes.planBoxSubText}
                data-testid="monthlyDescription"
              >
                {monthlyProduct?.additional_description}
              </Typography>
              <Button
                name="Continue with monthly"
                id="monthlyContinue"
                testId="monthlyContinue"
                backgroundColor={colors["--primary-tealnight"]}
                hoverForeground={colors["--primary-tealnight"]}
                width="200px"
                onClick={onMonthlyClick}
              />
            </div>
            <div className={clsx(classes.planItem)}>
              <Typography
                className={classes.planBoxHeaderText}
                data-testid="groupPlusHeader"
              >
                epocrates+ Group
              </Typography>
              <Typography
                className={classes.planBoxSubText}
                data-testid="groupPlusDescription"
              >
                {strings.epocrates_group_additional_description}
              </Typography>
              <Button
                name="Learn more"
                id="epocratesGroupLearnMore"
                testId="epocratesGroupLearnMore"
                backgroundColor={colors["--primary-tealnight"]}
                hoverForeground={colors["--primary-tealnight"]}
                width="200px"
                onClick={() => {
                  sendAmplitudeEvent(GROUP_LICENSING_LEARN_MORE_CLICK);
                  window.open("/plus/group", "_blank");
                }}
              />
            </div>
          </Box>
        ) : null}
        {
          <div>
            {props.userSubscriptionQuery?.data?.[0]?.platform === "web" &&
              paymentAndSubscriptionInfo}
            {isTransactionHistoryFetched && (
              <TransactionHistory
                userId={userId.toString()}
                transactionHistoryResponse={transactionHistoryResponse}
                transactionHistoryUpdatedAt={transactionHistoryUpdatedAt}
              />
            )}
          </div>
        }
        {cancelWorkflow && (
          <SubscriptionCancellationFirstFlow
            open={cancelFirstflowDialog}
            setCancelFirstflowDialog={setCancelFirstflowDialog}
            subscriptionId={props.userSubscriptionQuery?.data?.[0]?.id ?? ""}
            subscriptionEndDate={nextSubscriptionDate.current}
            sku={productSku}
          />
        )}
      </Box>
    </div>
  );
};
