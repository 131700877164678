import { Dispatch, MutableRefObject, useRef, useState } from "react";
import { PaymentMethodStateResponse } from "../models/IPaymentMethodResponse";
import { VindiciaError } from "../models/VindiciaError";
import { LicenseBillingAndPayment } from "./LicenseBillingAndPayment";
import { strings } from "../utils/Strings";
import { LicensePaymentConfirmation } from "./LicensePaymentConfirmation";
import {
  LicensePurchaseResponse,
  getPaymentMethodDetails,
} from "../services/licenseService";
import { LicenseFormAction } from "../container/LicenseCodeForm";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { sendSuccessSnackBarWithLinkMessage } from "../actions/snackBarActions";
import { SnackBarType } from "../utils/SnackBarType";
import {
  IPrimaryPaymentInformation,
  isPrimaryPaymentInformation,
} from "../models/PrimaryPaymentMethodResponse";
import {
  GROUP_LICENSING_PAYMENT_INFO_CLICK,
  GroupLicenseSegmentPayload,
} from "../utils/analytics";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import { createUserService } from "src/services/accountCreationService";
import { config } from "src/config/envConfig";
import { AccountCreationRequest } from "src/models/AccountCreationRequest";
import {
  CURRENT_USER_ID,
  CURRENT_USER_NAME,
  STAY_LOGGED_IN,
} from "src/utils/localStorageKeys";
import { getCookie, setCookie } from "src/utils/cookies";
import { getUserDetailsAction } from "src/actions/profileActions";
import { isValidPassword } from "src/utils/validation";

export interface VindiciaPaymentResponse {
  detail: {
    isValid: boolean;
    vid: string;
  };
}
export interface PaymentMethodState {
  paymentMethod: PaymentMethodStateResponse;
}
export interface PMTResponse {
  detail: {
    isValid: boolean;
    vid: string;
  };
}

interface LicensePurchaseContainerProps {
  vindicia: {
    setup: () => void;
    destroy: () => void;
    isValid: () => boolean;
    resetCompleteStatus: () => void;
    clearData: () => void;
  };
  userId?: string;
  newEmail?: string;
  profileEmail?: string;
  sku: string;
  vindiciaFieldsSpinner: boolean;
  setVindiciaFieldsSpinner: (bool: boolean) => void;
  vindiciaPaymentFail: boolean;
  setVindiciaPaymentFail: (vindiciaPaymentFail: boolean) => void;
  waitForVindiciaFields: () => void;
  calculateLicenseResponse: LicensePurchaseResponse | undefined;
  purchaseQuantity: number;
  institution: string;
  paymentDetailRequest: string;
  setPaymentDetailRequest: (paymentDetailRequest: string) => void;
  setPaymentConfirmScreen: (bool: boolean) => void;
  paymentConfirmScreen: boolean;
  paymentMethodId: string;
  setPaymentMethodId: (paymentMethodId: string) => void;
  formDispatch: Dispatch<LicenseFormAction>;
  setPaymentId: (paymentId: string) => void;
  paymentDetailsResponse?: IPrimaryPaymentInformation;
  setPaymentDetailsResponse: (
    paymentDetailsResponse: IPrimaryPaymentInformation
  ) => void;
  pwdData: MutableRefObject<string>;
  isNewCreatedUser: MutableRefObject<boolean>;
}

export const LicensePurchaseContainer = (
  props: LicensePurchaseContainerProps
): JSX.Element => {
  const [submitProgress, setSubmitProgress] = useState(false);
  const [confirmButtonSpinner, setConfirmButtonSpinner] = useState(false);
  const [fullName, setFullName] = useState("Guest Account");
  const [zipcode, setZipcode] = useState<string>();
  const pmtVid = useRef("");

  const dispatch = useDispatch();

  const associatePaymentMethodQuery = useMutation(getPaymentMethodDetails, {
    onSuccess: (responseData) => {
      setSubmitProgress(false);
      if (responseData.status >= 200 && responseData.status <= 299) {
        if (isPrimaryPaymentInformation(responseData.data)) {
          props.setPaymentDetailsResponse(responseData.data);
          props.setPaymentId(responseData.data.id ?? "");
          props.setPaymentConfirmScreen(true);
        }
      }
    },
    onError: (error) => {
      setSubmitProgress(false);
    },
  });

  const createAccountThenAssociatePmtMutation = useMutation(createUserService, {
    onSuccess: (responseData) => {
      if (responseData.status === 201) {
        //Remove any existing cookies
        props.isNewCreatedUser.current = true;
        const authResponse = responseData.data;
        const userId = authResponse.userId?.toString();
        localStorage.setItem(CURRENT_USER_ID, userId);
        localStorage.setItem(STAY_LOGGED_IN, "true");
        setCookie("sessionTimer", 24, "sessionTimer");
        setCookie("stayLoggedIn", 24, "true");
        setCookie("accessToken", 24, authResponse.tokens.accessToken);
        setCookie("refreshToken", 24, authResponse.tokens.refreshToken);
        setCookie("currentUserId", 24, userId);
        dispatch(getUserDetailsAction());
        associatePaymentMethodQuery.mutate({
          paymentMethodVid: pmtVid.current,
        });
      }
    },
  });

  let segmentPayload: GroupLicenseSegmentPayload = {
    "Purchase Type": "Group Licensing",
    "Purchase Quantity": props.purchaseQuantity,
    "Institution Name": props.institution,
    Failure: false,
  };

  const usePmtSuccess = (pmtResponse: PMTResponse) => {
    if (pmtResponse?.detail?.isValid) {
      pmtVid.current = pmtResponse.detail.vid;
      if (props.newEmail && props.newEmail !== props.profileEmail) {
        //need to clear userId since payment association can (and probably will) fire before create account finishes
        setCookie("currentUserId", -1, "");
        setCookie("sessionTimer", -1, "");
        setCookie("refreshToken", -1, "");

        let password = Math.random().toString(36);
        const validation = isValidPassword(password, props.newEmail);
        if (!validation.passwordDoesNotContainEmail) {
          password = Math.random().toString(36);
        }
        if (!validation.passwordContainsNumber) {
          password += "8";
        }
        if (!validation.passwordContainsUpperAndLower) {
          password += "Ul";
        }
        if (!validation.passwordIsCorrectLength) {
          password += password;
        }
        const firstName = fullName.substring(0, fullName.indexOf(" "));
        const lastName = fullName.substring(fullName.indexOf(" ") + 1);
        const newAccount: AccountCreationRequest = {
          email: props.newEmail,
          firstName: firstName ? firstName : "Guest",
          lastName: lastName ? lastName : "Guest",
          addresses: { work: { country: "US", zipcode } },
          password,
          occupation: 58, // Healthcare IT
          profileOccupation: 58, // Healthcare IT
        };
        props.pwdData.current = password;
        createAccountThenAssociatePmtMutation.mutate(newAccount);
        localStorage.setItem(CURRENT_USER_NAME, props.newEmail);
      } else {
        associatePaymentMethodQuery.mutate({
          paymentMethodVid: pmtResponse?.detail?.vid,
        });
      }
      sendAmplitudeEvent(GROUP_LICENSING_PAYMENT_INFO_CLICK, segmentPayload);
    } else {
      setSubmitProgress(false);
    }
  };

  const paymentFailed = (error: VindiciaError) => {
    setSubmitProgress(false);

    segmentPayload = {
      ...segmentPayload,
      Failure: true,
      Error: JSON.stringify(error),
    };
    sendAmplitudeEvent(GROUP_LICENSING_PAYMENT_INFO_CLICK, segmentPayload);

    dispatch(
      sendSuccessSnackBarWithLinkMessage(
        strings.payment_failed_title,
        SnackBarType.ERROR.displayValue
      )
    );
  };

  const subscriptionPayment = (
    <LicenseBillingAndPayment
      vindicia={props.vindicia}
      userId={props.userId?.toString()}
      paymentMethodDetails={props.paymentDetailsResponse}
      waitForVindiciaFields={props.waitForVindiciaFields}
      vindiciaFieldsSpinner={props.vindiciaFieldsSpinner}
      setVindiciaFieldsSpinner={props.setVindiciaFieldsSpinner}
      submitProgress={submitProgress}
      setSubmitProgress={setSubmitProgress}
      paymentSuccess={usePmtSuccess}
      paymentFailed={paymentFailed}
      isFetching={associatePaymentMethodQuery.isLoading}
      setFullName={setFullName}
      setZipcode={setZipcode}
    />
  );
  const paymentConfirmation = (
    <LicensePaymentConfirmation
      productSku={props.sku}
      paymentMethodDetails={props.paymentDetailsResponse}
      userId={props.userId}
      confirmButtonSpinner={confirmButtonSpinner}
      setConfirmButtonSpinner={setConfirmButtonSpinner}
      calculateLicenseResponse={props.calculateLicenseResponse}
      setPaymentConfirmScreen={props.setPaymentConfirmScreen}
      purchaseQuantity={props.purchaseQuantity}
      institution={props.institution}
      paymentDetailRequest={props.paymentDetailRequest}
      setPaymentDetailRequest={props.setPaymentDetailRequest}
      formDispatch={props.formDispatch}
    />
  );

  return (
    <>
      {!props.paymentConfirmScreen ? subscriptionPayment : paymentConfirmation}
    </>
  );
};
