import axios from "axios";
import { config } from "../config/envConfig";

export interface NpiPayload {
  first_name: string;
  last_name: string;
  limit: number;
  postal_code: string;
}

const { npiUrl } = config.environment;

export const npiService = (request: any) => {
  const instance = axios.create({
    baseURL: npiUrl,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const body = request as NpiPayload;

  return instance
    .post("/npi-lookup", { ...body })
    .then((response) => response)
    .catch((error) => error.response || { status: 500, data: {} });
};
