import { Tabs, Tab, Box, Divider } from "@mui/material";
import { strings } from "../utils/Strings";
import { colors } from "../config/colorConfig";
import herbSupplementsIcon from "../images/icons/herbs_supplements.svg";
import icd10Icon from "../images/icons/icd10_icon.svg";
import idtxIcon from "../images/icons/IDTX.svg";
import labsIcon from "../images/icons/labs.svg";
import diseasesIcon from "../images/icons/diseases.svg";
import { ReactNode, useState } from "react";
import { styled } from "@mui/system";

const StyledDiv = styled("div")({
  display: "flex",
  alignItems: "center",
});
const ContentBox = styled(Box)(({ theme }) => ({
  padding: "16px 0px",
  alignContent: "center",
  fontFamily: "Source Sans Pro",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "24px",
  [theme.breakpoints.only("xs")]: {
    width: "358px",
  },
}));
const StyleTabs = styled(Tabs)(({ theme }) => ({
  paddingRight: "12px",
  "& .MuiTabs-indicator": {
    backgroundColor: `${colors["--primary-tealnight"]}`,
  },
}));
const StyledTab = styled(Tab)(({ theme }) => ({
  outline: "none !important",
  padding: "0px !important",
  fontFamily: "Source Sans Pro",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "20px",
  textAlign: "center",
  textTransform: "none",
  borderBottomColor: `${colors["--primary-tealnight"]} `,

  "&.Mui-selected": {
    color: `${colors["--primary-tealnight"]} !important`,
  },
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: `${colors["--ui-grey-light"]} !important`,
  marginRight: "8px",
  [theme.breakpoints.only("xs")]: {
    width: "358px",
  },
}));

export const SubscriptionTabNav = () => {
  const [value, setValue] = useState(0);

  const [toolBarContext, setToolBarContext] = useState<ReactNode>(
    <ul>
      <li>
        Access 1000+ peer-reviewed disease guidelines for diagnostics, risk
        factors, and tailored treatments. Stay informed on follow-up procedures,
        and potential complications.{" "}
      </li>
      <li>Patient education resources to enhance healthcare knowledge.</li>
    </ul>
  );

  /**
   * This function is called whenever the user clicks on a tab in the toolbar.
   * It's given the React SyntheticEvent and the new value of the tab that was
   * selected. It updates the state of the component by calling setValue() with
   * the new value.
   *
   * It also calls setToolBarContext() and passes the value of the selected tab
   * as a string.
   *
   * @param {React.SyntheticEvent} event The event that was triggered when the
   * user selected a tab.
   * @param {number} newValue The new value of the tab that was selected.
   */
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 1:
        setToolBarContext(
          <ul>
            <li>
              <span>
                Explore hundreds of lab tests effortlessly with clear normal
                reference ranges and insightful abnormal result interpretations.
              </span>
            </li>
            <li>
              Understand holistic connections between lab results, drugs, and
              contributory conditions.
            </li>
          </ul>
        );
        break;
      case 2:
        setToolBarContext(
          <ul>
            <li>
              <span>
                Access trustworthy details on dietary supplements and natural
                medications, including clear guidance on usage dosage.
              </span>
            </li>
            <li>
              Stay informed about potential risks, adverse reactions, and gain
              insights into drug interactions.
            </li>
          </ul>
        );
        break;
      case 3:
        setToolBarContext(
          <ul>
            <li>
              <span>
                Access evidence-based recommendations for 400+ infections,
                including bacterial, viral, fungal, and parasitic.
              </span>
            </li>
            <li>
              IDTX provides empirically proven treatments for effective
              responses to various infections.
            </li>
          </ul>
        );
        break;
      case 4:
        setToolBarContext(
          <ul>
            <li>
              <span>
                ICD-10, the globally recognized disease coding system, ensures
                universal compliance for accurate medical documentation.
              </span>
            </li>
            <li>
              Swiftly navigate and find precise disease codes, streamlining the
              documentation process.
            </li>
          </ul>
        );
        break;
      default:
        setToolBarContext(
          <ul>
            <li>
              Access 1000+ peer-reviewed disease guidelines for diagnostics,
              risk factors, and tailored treatments. Stay informed on follow-up
              procedures, and potential complications.{" "}
            </li>
            <li>
              Patient education resources to enhance healthcare knowledge.
            </li>
          </ul>
        );
    }
  };
  return (
    <Box data-testid="tabWrapper">
      <StyleTabs
        value={value}
        aria-label="subscription tabs "
        onChange={handleChange}
      >
        <StyledTab
          style={{ width: "140px" }}
          label={
            <StyledDiv>
              <img src={diseasesIcon} alt="" />
              {strings.DISEASES}
            </StyledDiv>
          }
        />
        <StyledTab
          style={{ width: "110px" }}
          label={
            <StyledDiv>
              <img src={labsIcon} alt="" />
              {strings.LABS}
            </StyledDiv>
          }
        />
        <StyledTab
          style={{ width: "220px" }}
          label={
            <StyledDiv>
              <img src={herbSupplementsIcon} alt="" />
              {strings.HERBS_AND_SUPPLEMENTS}
            </StyledDiv>
          }
        />
        <StyledTab
          style={{ width: "120px" }}
          label={
            <StyledDiv>
              <img src={idtxIcon} alt="" />
              {strings.IDTX}
            </StyledDiv>
          }
        />
        <StyledTab
          style={{ width: "128px" }}
          label={
            <StyledDiv>
              <img src={icd10Icon} alt="" />
              {strings.ICD_10}
            </StyledDiv>
          }
        />
      </StyleTabs>
      <StyledDivider />
      <ContentBox>{toolBarContext}</ContentBox>
    </Box>
  );
};
