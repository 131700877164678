import axios from "axios";
import { AuthRequest } from "../models/AuthRequest";
import { config } from "../config/envConfig";

export const cmeAuthenticationService = (
  accessToken: any,
  authRequest: AuthRequest,
  tokens?: any
): any => {
  const { cmeApiUrl } = config.environment;

  const instance = axios.create({
    baseURL: cmeApiUrl,
    timeout: 30000,
  });

  // claims
  return instance
    .get("/tokenexchange/rievent", {
      params: {
        claims: JSON.stringify(authRequest.cmeRequest),
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response: any) => {
      console.log(response);
      response.tokens = tokens;
      return response;
    })
    .catch((error: any) => {
      console.log(error);
      return error.response || { status: 500, data: {} };
    });
};
