import React from "react";
import emailValidation from "email-validator";
import { useDispatch } from "react-redux";
import { Typography, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { strings } from "../utils/Strings";
import { FormDialog } from "./custom/FormDialog";
import { TextField } from "./custom/TextField";
import { colors } from "../config/colorConfig";
import { sendSuccessSnackBarMessage } from "../actions/snackBarActions";
import { SnackBarType } from "../utils/SnackBarType";
import * as types from "../actions";
import {
  sendAmplitudeEventData,
  sendAmplitudeEvent,
  updateUserPropertiesForSegment,
} from "../utils/AmplitudeUtil";
import {
  ACCOUNT_CHANGE_EMAIL_CANCEL_CLICK,
  ACCOUNT_CHANGE_EMAIL_CANCEL_CLICK_EVENT_ID,
  ACCOUNT_CHANGE_EMAIL_FAILURE,
  ACCOUNT_CHANGE_EMAIL_FAILURE_EVENT_ID,
  ACCOUNT_CHANGE_EMAIL_SUBMIT_CLICK,
  ACCOUNT_CHANGE_EMAIL_SUBMIT_CLICK_EVENT_ID,
  ACCOUNT_CHANGE_EMAIL_SUCCESS,
  ACCOUNT_CHANGE_EMAIL_SUCCESS_EVENT_ID,
  ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER,
  ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER_EVENT_ID,
} from "../utils/analytics";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";
import { ErrorValues } from "../models/ErrorValues";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { updateAccountProfileService } from "../services/accountProfileService";
import { getUserDetailsAction } from "../actions/profileActions";
import { httpConstants } from "../utils/httpConstants";

interface ChangeEmailDialogProps {
  open: boolean;
  setChangeEmailModal: (open: boolean) => void;
  email: string;
}

const defaultError = {
  counter: 0,
  errorMessages: [],
  errorStatus: 0,
};

export const ChangeEmailDialog: React.FC<ChangeEmailDialogProps> = (
  props: ChangeEmailDialogProps
) => {
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [confirmEmailError, setConfirmEmailError] = React.useState<
    string | null
  >(null);
  const [email, setEmail] = React.useState<string>("");
  const [confirmEmail, setConfirmEmail] = React.useState<string>("");
  const [errorValues, setErrorValues] =
    React.useState<ErrorValues>(defaultError);

  const dispatch = useDispatch();
  const styles = makeStyles((theme: Theme) => ({
    modalSubtitle: {
      marginBottom: theme.spacing(1),
      fontSize: 14,
      lineHeight: 1.14,
      color: colors["--light-grey"],
    },
    currentEmail: {
      fontSize: 16,
      lineHeight: 1.25,
      color: colors["--dark-blue-text"],
      textTransform: "lowercase",
      marginBottom: theme.spacing(5.5),
    },
  }));
  const classes = styles();
  const userId = localStorage.getItem(CURRENT_USER_ID);

  const changeEmailQuery = useMutation(updateAccountProfileService, {
    onSuccess: (response: AxiosResponse) => {
      if (response.status >= 200 && response.status <= 299) {
        dispatch({ type: types.TIMEOUT_SNACKBAR });
        dispatch(
          sendSuccessSnackBarMessage(
            "",
            strings.change_email_success,
            SnackBarType.SUCCESS.displayValue
          )
        );
        sendAmplitudeEvent(
          ACCOUNT_CHANGE_EMAIL_SUCCESS,
          {
            "Event ID": ACCOUNT_CHANGE_EMAIL_SUCCESS_EVENT_ID,
            "New Email Address": email,
          },
          userId
        );
        sendAmplitudeEvent(
          ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER,
          {
            "Event ID": ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER_EVENT_ID,
            "Banner Type": "Email",
          },
          userId
        );
        updateUserPropertiesForSegment(userId, { email: email });
        props.setChangeEmailModal(false);
        setEmail("");
        setConfirmEmail("");
        dispatch(getUserDetailsAction());
      } else {
        let { counter } = errorValues;
        if (response.status === httpConstants.INTERNAL_SERVER_ERROR) {
          counter += 1;
        }
        setErrorValues({
          counter,
          errorMessages: [response.data[0]],
          errorStatus: response.status,
        });
        sendAmplitudeEvent(
          ACCOUNT_CHANGE_EMAIL_FAILURE,
          {
            "Event ID": ACCOUNT_CHANGE_EMAIL_FAILURE_EVENT_ID,
            Description: response.data?.error?.message ?? "Network Error",
          },
          userId
        );
      }
    },
  });
  const closeDialog = () => {
    sendAmplitudeEventData(
      null,
      ACCOUNT_CHANGE_EMAIL_CANCEL_CLICK,
      ACCOUNT_CHANGE_EMAIL_CANCEL_CLICK_EVENT_ID
    );
    setEmail("");
    setConfirmEmail("");
    props.setChangeEmailModal(false);
    setErrorValues(defaultError);
  };
  const getDisabledState = () => {
    if (
      email.toLowerCase() === confirmEmail.toLowerCase() &&
      !emailError &&
      !confirmEmailError
    ) {
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    sendAmplitudeEventData(
      null,
      ACCOUNT_CHANGE_EMAIL_SUBMIT_CLICK,
      ACCOUNT_CHANGE_EMAIL_SUBMIT_CLICK_EVENT_ID
    );
    const requestObj = {
      updateRequest: {
        email,
      },
    };
    changeEmailQuery.mutate(requestObj);
  };

  const validateSingleInput = (data: string, type: string) => {
    let response = null;
    switch (type) {
      case strings.input_type_email:
        if (!emailValidation.validate(data as string)) {
          response = strings.email_format_invalid;
        }
        break;
      case strings.input_type_confirmEmail:
        if (!emailValidation.validate(data as string)) {
          response = strings.email_format_invalid;
        } else if (email.toLowerCase() !== data.toLowerCase()) {
          response = strings.different_email_message;
        }
        break;
      default:
        response = null;
    }
    return response;
  };

  const validateEmail = (data: string) => {
    setEmail(data);
    const validationMsg = validateSingleInput(data, strings.input_type_email);
    setEmailError(validationMsg);
    if (errorValues) {
      setErrorValues(defaultError);
    }
    return validationMsg;
  };

  const validateConfirmEmail = (data: string) => {
    setConfirmEmail(data);
    const validationMsg = validateSingleInput(
      data,
      strings.input_type_confirmEmail
    );
    setConfirmEmailError(validationMsg);
    if (errorValues) {
      setErrorValues(defaultError);
    }
    return validationMsg;
  };

  React.useEffect(() => {
    setEmailError(validateSingleInput(email, strings.input_type_email));
    setConfirmEmailError(
      validateSingleInput(confirmEmail, strings.input_type_confirmEmail)
    );
  });
  if (!props.open) {
    return null;
  }
  return (
    <div data-testid="dialogWrapper">
      <FormDialog
        title={strings.change_email_modal_title}
        buttonLabel={strings.change_email_modal_title}
        open={props.open}
        setOpen={closeDialog}
        submitDisabled={getDisabledState()}
        handleSubmit={handleSubmit}
        inProgress={changeEmailQuery.isLoading}
        errorValues={errorValues}
        btnId="changeEmailBtn"
      >
        <>
          <div>
            <Typography
              data-testid="modalSubtitle"
              variant="subtitle1"
              className={clsx(classes.modalSubtitle)}
            >
              Current email
            </Typography>
            <Typography
              data-testid="currentEmail"
              variant="body1"
              className={clsx(classes.currentEmail)}
            >
              {props.email}
            </Typography>
          </div>
          <div>
            <TextField
              name="newEmail"
              type="email"
              testId="newEmail"
              data-testid="newEmail"
              autoCapitalize="off"
              label={strings.new_email_label}
              helpTestId="newEmailHelp"
              validation={validateEmail}
              validationError={emailError}
              value={email}
            />
            <TextField
              name="confirmNewEmail"
              type="email"
              testId="confirmNewEmail"
              data-testid="confirmNewEmail"
              autoCapitalize="off"
              label={strings.new_confirm_email_label}
              helpTestId="confirmNewEmailHelp"
              validation={validateConfirmEmail}
              validationError={confirmEmailError}
              value={confirmEmail}
              disablePaste
            />
          </div>
        </>
      </FormDialog>
    </div>
  );
};
