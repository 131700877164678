import { ManageAccountWithRoot as ManageAccount } from "./ManageAccount";
import { useDecision } from "@optimizely/react-sdk";
import { RouteComponentProps } from "react-router";
import { deviceManagementEnabledFlag } from "src/utils/optimizelyFlag";
import { OverlaySpinner } from "../components/custom/OverlaySpinner";

/**
 * manage account wrapper for passing latest value of device optimizely flag to manage account component
 * @props react router props
 * */
interface ManageAccountOptimizelyWrapperProps extends RouteComponentProps {
  vindicia: {
    setup: () => void;
    destroy: () => void;
    isValid: () => boolean;
    resetCompleteStatus: () => void;
    clearData: () => void;
  };
  vindiciaFieldsSpinner: boolean;
}
const ManageAccountOptimizelyWrapper = (
  props: ManageAccountOptimizelyWrapperProps
) => {
  const [deviceManagementDecision, clientReady] = useDecision(
    deviceManagementEnabledFlag
  );
  return clientReady ? (
    <OverlaySpinner />
  ) : (
    <>
      <ManageAccount
        {...props}
        deviceManagementDecision={deviceManagementDecision}
        vindiciaFieldsSpinner={props.vindiciaFieldsSpinner}
        vindicia={props.vindicia}
      />
    </>
  );
};

export { ManageAccountOptimizelyWrapper };
