import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { PlatformTypes } from "src/models/TransactionHistoryAxiosResponse";
import { getTransactionTypes } from "src/services/subscriptionService";

export const TransactionTypes = (
  userId: number,
  isDeviceEnabled?: boolean
): UseQueryResult<PlatformTypes> =>
  useQuery<PlatformTypes>(
    ["transactionUserId", { userId }],
    () => getTransactionTypes(userId, isDeviceEnabled),
    {
      enabled: userId !== undefined && userId !== -1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );
