import axios, { AxiosResponse } from "axios";
import _ from "lodash";

import { refreshAccess } from "../utils/cookies";
import { Device, UpdateDevice, DevicesListResponse } from "../models/Device";
import { config } from "../config/envConfig";

const { profileUrl } = config.environment;

const MAX_INACTIVE_PENDING_USERS_TO_DISPLAY = 3;

export const sortDeviceData = (
  deviceData: Record<string, Device[]>,
  platform: string
) => {
  const devices = deviceData[platform] ?? [];
  const activeDevices = devices.filter(
    (data: Device) => data.deviceStatus === "active"
  );
  activeDevices.sort((prev, next) => {
    const prevDate = prev.lastAccessedAt ? new Date(prev.lastAccessedAt) : "";
    const nextDate = next.lastAccessedAt ? new Date(next.lastAccessedAt) : "";
    if (prevDate > nextDate) {
      return -1;
    } else if (prevDate < nextDate) {
      return 1;
    } else {
      return 0;
    }
  });

  const inActiveDevices = devices.filter(
    (data: Device) => data.deviceStatus !== "active"
  );
  inActiveDevices.sort((prev, next) => {
    const prevDate = prev.lastAccessedAt ? new Date(prev.lastAccessedAt) : "";
    const nextDate = next.lastAccessedAt ? new Date(next.lastAccessedAt) : "";
    if (prevDate > nextDate) {
      return -1;
    } else if (prevDate < nextDate) {
      return 1;
    } else {
      return 0;
    }
  });

  const maxInactiveDevices = inActiveDevices.slice(
    0,
    MAX_INACTIVE_PENDING_USERS_TO_DISPLAY
  );

  return activeDevices.concat(maxInactiveDevices);
};

export const instance = axios.create({
  baseURL: profileUrl,
  timeout: 30000,
});

export const getDevicesList = async (
  userId: number
): Promise<DevicesListResponse> => {
  try {
    const res: AxiosResponse = await instance.get(
      `/v1/users/${userId}/devices`
    );
    const data: Device[] = res.data;
    const filteredData = _.groupBy(data, (item) =>
      item.platform === "Web" ? "Web" : "Apps"
    );
    const webSortedData = sortDeviceData(filteredData, "Web");
    filteredData.Web = webSortedData;

    const appSortedData = sortDeviceData(filteredData, "Apps");
    filteredData.Apps = appSortedData;

    return filteredData;
  } catch (error) {
    console.log("Error fetching device details: ", error);
    throw error;
  }
};

export const updateDevicesList = async (request: UpdateDevice) => {
  const devicesInfo = request.devices.map((device) => {
    return {
      device_id: device.deviceId,
      platform: device.platform,
      device_family: device.deviceFamily,
      device_status: device.deviceStatus,
    };
  });
  const cookie = await refreshAccess();
  try {
    const res: AxiosResponse = await instance.put(
      `/v1/users/${request.userId}/devices`,
      devicesInfo,
      {
        headers: {
          Authorization: `${cookie}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log("Error updating device details: ", error);
    throw error;
  }
};
