import { Typography, CssBaseline, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef } from "react";
import withWidth from "@material-ui/core/withWidth";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { colors } from "../config/colorConfig";
import { ResetRequest } from "../models/ResetRequest";
import { withRoot } from "../withRoot";
import { isValidPassword } from "../utils/validation";
import { Button } from "./custom/Button";
import { LoginPasswordPageBody } from "./custom/LoginPasswordPageBody";
import {
  passwordResetService,
  validateResetCodeService,
} from "../services/passwordResetService";
import { sendAmplitudeEventData } from "../utils/AmplitudeUtil";
import { Password } from "./custom/PasswordField";
import {
  CREATE_NEW_PASSWORD_ERROR,
  CREATE_NEW_PASSWORD_SUBMIT,
  CREATE_NEW_PASSWORD_SUBMIT_EVENT_ID,
} from "../utils/analytics";
import { CURRENT_USER_NAME } from "../utils/localStorageKeys";
import { ValidateResetCodeRequest } from "../models/ValidateResetCodeRequest";
import { ErrorPage } from "./ErrorPage";
import { passwordResetErrorMessage } from "../utils/errormessage";
import { ProgressPage } from "./ProgressPage";
import { QRFooter } from "./custom/QRFooter";
import { AppAltBackground } from "./custom/AppAltBackground";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import {
  sendSnackBarMessage,
  sendSuccessSnackBarMessage,
} from "../actions/snackBarActions";
import { SnackBarType } from "../utils/SnackBarType";
import { strings } from "../utils/Strings";
import * as types from "../actions";
import { httpConstants } from "../utils/httpConstants";

const PasswordResetForm = (props: any) => {
  const { history } = props;
  const style = makeStyles((theme) => ({
    avatar: {
      backgroundColor: theme.palette.secondary.main,
    },
    failure: {
      color: colors["--failure"],
    },
    textField: {
      width: "100%",
    },
    input: {
      paddingBottom: theme.spacing(5),
    },
    typography: {
      fontFamily: ["Metric"].join(","),
      fontSize: "14px",
      color: colors["--ui-slate"],
    },
    redIcon: {
      color: colors["--error"],
      fontSize: "12px",
    },
    greenIcon: {
      color: "green",
      fontSize: "12px",
    },
    description: {
      paddingBottom: theme.spacing(4),
      color: colors["--primary-midnight"],
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    emailParam: {
      fontWeight: 600,
    },
  }));
  const [values, setValues] = React.useState({
    disabled: true,
  });
  const confirmPasswordRef = useRef("");
  const newPasswordRef = useRef("");
  const validationRef = useRef({
    passwordNotEmpty: false,
    passwordIsCorrectLength: false,
    passwordContainsUpperAndLower: false,
    passwordContainsNumber: false,
    passwordDoesNotContainEmail: true,
  });

  const [isValidationError, setIsValidationError] = React.useState(false);
  const [validationErrorMessage, setValidationErrorMessage] =
    React.useState("");

  const queryParams = new URLSearchParams(props?.location?.search);
  const emailParam: any = queryParams.has("email")
    ? queryParams.get("email")
    : "";
  const resetCodeParam: any = queryParams.has("token")
    ? queryParams.get("token")
    : "";
  const classes = style(props);
  const dispatch = useDispatch();
  const passwordResetQuery = useMutation(passwordResetService, {
    onSuccess: (response: AxiosResponse) => {
      if (response.status >= 200 && response.status <= 299) {
        dispatch({ type: types.TIMEOUT_SNACKBAR });
        dispatch(
          sendSuccessSnackBarMessage(
            "",
            strings.change_password_success,
            SnackBarType.SUCCESS.displayValue
          )
        );
        sendAmplitudeEventData(
          emailParam,
          CREATE_NEW_PASSWORD_SUBMIT,
          CREATE_NEW_PASSWORD_SUBMIT_EVENT_ID
        );
        props.history.push("/passwordresetsuccess");
      } else {
        dispatch({ type: types.TIMEOUT_SNACKBAR });
        dispatch(
          sendSnackBarMessage(
            "",
            passwordResetErrorMessage(response.data) ||
              "Something unexpected happened",
            SnackBarType.ERROR.displayValue
          )
        );
        sendAmplitudeEventData(
          emailParam,
          CREATE_NEW_PASSWORD_ERROR,
          CREATE_NEW_PASSWORD_SUBMIT_EVENT_ID,
          response?.data.error?.code,
          response?.data.error?.message
        );
      }
    },
  });
  const passwordValidateQuery = useMutation(validateResetCodeService, {
    onSuccess: (response: AxiosResponse) => {
      if (response.status !== httpConstants.OK) {
        setValidationErrorMessage(passwordResetErrorMessage(response.data));
        setIsValidationError(true);
      }
    },
  });

  useEffect(() => {
    async function validateData() {
      const validateResetCodeRequest: ValidateResetCodeRequest = {
        resetCode: resetCodeParam,
      };
      localStorage.setItem(CURRENT_USER_NAME, emailParam);
      passwordValidateQuery.mutate(validateResetCodeRequest);
    }
    validateData();
  }, []);
  const handleSubmit = async (formEvent: any): Promise<void> => {
    formEvent.preventDefault();
    const resetRequest: ResetRequest = {
      email: emailParam,
      resetCode: resetCodeParam,
      newPassword: formEvent.target.elements.newPassword.value,
    };
    passwordResetQuery.mutate(resetRequest);
  };
  const notMatchedPassword = "Passwords do not match";
  const setButtonState = (isDisable: boolean) => {
    if (values.disabled !== isDisable) {
      setValues({ ...values, disabled: isDisable });
    }
  };
  const validationError = () => {
    const lengthIcon = validationRef.current.passwordIsCorrectLength ? (
      <CheckIcon className={classes.greenIcon} />
    ) : (
      <ClearIcon className={classes.redIcon} />
    );
    const upperLowerIcon = validationRef.current
      .passwordContainsUpperAndLower ? (
      <CheckIcon className={classes.greenIcon} />
    ) : (
      <ClearIcon className={classes.redIcon} />
    );
    const numberIcon = validationRef.current.passwordContainsNumber ? (
      <CheckIcon className={classes.greenIcon} />
    ) : (
      <ClearIcon className={classes.redIcon} />
    );
    const emailIcon = validationRef.current.passwordDoesNotContainEmail ? (
      <CheckIcon className={classes.greenIcon} />
    ) : (
      <ClearIcon className={classes.redIcon} />
    );
    return (
      <>
        <List style={{ padding: "0px" }}>
          <ListItem
            alignItems="center"
            style={{ padding: "0px" }}
            dense
            disableGutters
          >
            <ListItemIcon style={{ minWidth: "20px" }}>
              {lengthIcon}
            </ListItemIcon>
            <ListItemText
              className={classes.typography}
              primary="Must be minimum of 8 characters"
            />
          </ListItem>
          <ListItem
            alignItems="center"
            style={{ padding: "0px" }}
            dense
            disableGutters
          >
            <ListItemIcon style={{ minWidth: "20px" }}>
              {upperLowerIcon}
            </ListItemIcon>
            <ListItemText
              className={classes.typography}
              primary="Must contain upper & lower case"
            />
          </ListItem>
          <ListItem
            alignItems="center"
            style={{ padding: "0px" }}
            dense
            disableGutters
          >
            <ListItemIcon style={{ minWidth: "20px" }}>
              {numberIcon}
            </ListItemIcon>
            <ListItemText
              className={classes.typography}
              primary="Must contain at least one number"
            />
          </ListItem>
          <ListItem
            alignItems="center"
            style={{ padding: "0px" }}
            dense
            disableGutters
          >
            <ListItemIcon style={{ minWidth: "20px" }}>
              {emailIcon}
            </ListItemIcon>
            <ListItemText
              className={classes.typography}
              primary="Cannot contain your email"
            />
          </ListItem>
        </List>
      </>
    );
  };

  const handleOnNewPasswordChange = (newPassword: string) => {
    newPasswordRef.current = newPassword;
    let errorMessage = null;
    if (newPassword.length > 0) {
      const passwordCheck = isValidPassword(newPassword, emailParam);
      validationRef.current = passwordCheck;
      if (
        !passwordCheck.passwordContainsUpperAndLower ||
        !passwordCheck.passwordContainsNumber ||
        !passwordCheck.passwordDoesNotContainEmail ||
        !passwordCheck.passwordIsCorrectLength ||
        !passwordCheck.passwordNotEmpty
      ) {
        setButtonState(true);
        errorMessage = validationError();
      } else if (
        confirmPasswordRef.current.length > 0 &&
        newPassword !== confirmPasswordRef.current
      ) {
        setButtonState(true);
      } else if (confirmPasswordRef.current.length > 0) {
        setButtonState(false);
      } else {
        setButtonState(true);
      }
    } else {
      setButtonState(true);
      errorMessage = validationError();
    }

    return errorMessage;
  };

  const handleOnConfirmPasswordChange = (confirmPassword: string) => {
    confirmPasswordRef.current = confirmPassword;
    if (confirmPassword.length > 0) {
      const passwordCheck = isValidPassword(newPasswordRef.current, emailParam);
      validationRef.current = passwordCheck;
      if (newPasswordRef.current !== confirmPassword) {
        setButtonState(true);
        return notMatchedPassword;
      }
      if (
        newPasswordRef.current.length > 0 &&
        passwordCheck.passwordContainsUpperAndLower &&
        passwordCheck.passwordContainsNumber &&
        passwordCheck.passwordDoesNotContainEmail &&
        passwordCheck.passwordIsCorrectLength &&
        passwordCheck.passwordNotEmpty
      ) {
        setButtonState(false);
        return "";
      }
    }
    setButtonState(true);
    return notMatchedPassword;
  };

  const formBody = (
    <form onSubmit={handleSubmit} data-testid="form">
      <Password
        labelTestId="newPasswordLabel"
        labelHtmlFor="outlined-adornment-newPassword"
        labelTitle="New Password"
        name="newPassword"
        ariaLabel="toggle newPassword visibility"
        iconTestId="showNewPasswordId"
        validateInput={handleOnNewPasswordChange}
        labelWidth={0}
        isNewDesign={true}
        placeholerText="New Password"
      />
      <div style={{ paddingTop: "20px" }}>
        <Password
          labelTestId="confirmPasswordLabel"
          labelHtmlFor="outlined-adornment-confirmPassword"
          labelTitle="Confirm Password"
          name="confirmPassword"
          ariaLabel="toggle confirmPassword visibility"
          iconTestId="confirmPasswordButtonId"
          validateInput={handleOnConfirmPasswordChange}
          labelWidth={0}
          isNewDesign={true}
          placeholerText="Confirm Password"
        />
      </div>

      <Button
        name="Continue"
        id="continue"
        type="submit"
        disabled={values.disabled}
        inProgress={passwordResetQuery.isLoading}
        padding={{ paddingTop: "20px" }}
        backgroundColor={colors["--primary-tealnight"]}
        height={48}
        fontFamily={"Source Sans Pro"}
        fontSize={18}
        hoverForeground={colors["--primary-tealnight"]}
      />
    </form>
  );
  const bodyTitle = "Reset password";
  const titleText = (
    <>
      <Typography
        className={classes.description}
        data-testid="page-header"
        variant="body1"
        style={{ width: "100%" }}
      >
        Reset your password for the account associated with
        <Typography
          data-testid="errorCheckEmail"
          component="span"
          line-height={1.25}
          className={classes.description}
          style={{ fontWeight: 600 }}
        >
          {" "}
          {emailParam}
        </Typography>
      </Typography>
    </>
  );
  const errorBody = (
    <>
      <ErrorPage
        data-testid="validationErrorPage"
        errorMessage={validationErrorMessage ?? "Reset code is invalid."}
        history={history}
        bodyTitle="Invalid link"
      />
    </>
  );
  const pageBody = (
    <>
      <CssBaseline />
      <LoginPasswordPageBody
        title={bodyTitle}
        data-testid="loginpasswordpagebody"
        titleText={titleText}
        progressButton={passwordValidateQuery.isLoading}
        formBody={formBody}
      />
      <QRFooter />
    </>
  );
  const progress = (
    <>
      <CssBaseline />
      <ProgressPage
        data-testid="progressValidationPage"
        bodyTitle
        inProgress={passwordValidateQuery.isLoading}
      />
      <QRFooter />
    </>
  );
  const body = !isValidationError ? pageBody : errorBody;
  const prebody = passwordValidateQuery.isSuccess ? body : progress;
  return <AppAltBackground pageBody={prebody} />;
};

export const PasswordResetPage = withWidth()(withRoot(PasswordResetForm));
