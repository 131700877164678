import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_INPROGRESS,
  LOGIN_USER_ERROR,
  LOGIN_SYSTEM_ERROR,
} from "../actions";

export function login(state = [], action: any): any {
  const { authResponse, type } = action;
  switch (type) {
    case LOGIN_USER_SUCCESS:
      return { ...state, authResponse, login_progress: false };
    case LOGIN_USER_ERROR:
      return { ...state, authResponse, login_progress: false };
    case LOGIN_USER_INPROGRESS:
      return { ...state, login_progress: true };
    case LOGIN_SYSTEM_ERROR:
      return { ...state, login_progress: false, system_error: true };
    default:
      return { ...state, login_progress: false, system_error: false };
  }
}
