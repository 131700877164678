import * as React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme, withWidth } from "@material-ui/core";
import { withRoot } from "../withRoot";
import { DashboardListItems } from "./DashboardListItems";

const drawerWidth = 240;

const dashboardStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      height: "100vh",
      overflow: "auto",
    },
    chartContainer: {
      marginLeft: -22,
    },
    tableContainer: {
      height: 320,
    },
    h5: {
      marginBottom: theme.spacing(2),
    },
  })
);

interface State {
  open: boolean;
}

const DashboardComponent = (props: any) => {
  const [values, setValues] = React.useState<State>({
    open: true,
  });

  const handleDrawerOpen = () => {
    setValues({ open: true });
  };

  const handleDrawerClose = () => {
    setValues({ open: false });
  };

  const classes = dashboardStyle(props);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={classNames(
          classes.appBar,
          values.open && classes.appBarShift
        )}
      >
        <Toolbar disableGutters={!values.open} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            data-testid="drawerOpenButton"
            onClick={handleDrawerOpen}
            className={classNames(
              classes.menuButton,
              values.open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            data-testid="title"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          {/* <IconButton color="inherit">
							<Badge badgeContent={4} color="secondary">
								<NotificationsIcon />
							</Badge>
						</IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        data-testid="drawer"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !values.open && classes.drawerPaperClose
          ),
        }}
        open={values.open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton
            data-testid="drawerCloseButton"
            onClick={handleDrawerClose}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <DashboardListItems />
      </Drawer>
    </div>
  );
};

export const DashboardComponentWith = withWidth()(withRoot(DashboardComponent));
