import * as types from "./index";
import { UserProfileRequest } from "../models/UserProfileRequest";
import {
  AccountUpdateRequest,
  ResetAccountUpdate,
} from "../models/AccountUpdateRequest";

export const retrieveUserAction = () => ({
  type: types.RETRIEVE_USER,
});
export const pushUserAction = (userProfileRequest: UserProfileRequest) => ({
  type: types.UPDATE_USER,
  userProfileRequest,
});

export const getUserDetailsAction = (isDeviceEnabled?: boolean) => ({
  type: types.GET_USER,
  isDeviceEnabled: isDeviceEnabled,
});

export const updateUserDetailsAction = (
  updateRequest: AccountUpdateRequest
) => ({
  type: types.UPDATE_USER_DETAIL,
  updateRequest,
});

export const promptDialog = (value: boolean, path: string) => ({
  type: types.PROMPT_DIALOG,
  promptDialogOpen: { open: value, nextLocation: path },
});

export const updateButtonDisabled = (value: boolean) => ({
  type: types.UPDATE_BUTTON,
  isUpdateButtonDisabled: value,
});

export const resetUpdateProfileResponse = (): ResetAccountUpdate => ({
  type: types.RESET_ACCOUNT_PROFILE_UPDATE_RESPONSE,
});

export const deviceManagementAction = (isDeviceManagementEnabled: boolean) => ({
  type: types.DEVICE_MANAGEMENT_ENABLED,
  isDeviceManagementEnabled,
});
