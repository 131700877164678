import { takeLatest, all } from "redux-saga/effects";
import { EmailPreference } from "../models/EmailPreference";
import {
  authenticationSaga,
  authorizationCodeRedemptionSaga,
  verificationSaga,
} from "./authenticationSaga";
import * as types from "../actions";
import {
  countriesSaga,
  degreesSaga,
  occupationsSaga,
  specialtiesSaga,
  subSpecialtiesSaga,
} from "./demographicsSaga";
import { getUserDetailSaga, updateUserDetailSaga } from "./accountProfileSaga";
import { cmeLaunchSaga } from "./cmeLaunchSaga";
import {
  accountCreationSaga,
  emailValidationSaga,
  zipCodeValidationSaga,
} from "./accountCreationSaga";
import { npiLookupSaga } from "./npiLookupSaga";

import {
  updateEmailPreferenceSaga,
  getEmailPreferenceSaga,
} from "./emailPreferenceSaga";

interface EmailPreferencesTaskAction extends Array<EmailPreference> {
  type: "EMAIL_PREFERENCE_UPDATE";
}

export function* watchActions() {
  yield all([
    takeLatest(types.LOGIN_USER, authenticationSaga),
    takeLatest(types.ACCOUNT_CREATION, accountCreationSaga),
    takeLatest(types.VERIFY_USER, verificationSaga),
    takeLatest(types.AUTH_CODE_REDEEM_USER, authorizationCodeRedemptionSaga),
    takeLatest(types.CME_LAUNCH_GET_TOKEN, cmeLaunchSaga),
    takeLatest(types.COUNTRIES, countriesSaga),
    takeLatest(types.OCCUPATIONS, occupationsSaga),
    takeLatest(types.SPECIALTIES, specialtiesSaga),
    takeLatest(types.SUBSPECIALTIES, subSpecialtiesSaga),
    takeLatest(types.GET_USER, getUserDetailSaga),
    takeLatest(types.UPDATE_USER_DETAIL, updateUserDetailSaga),
    takeLatest(types.DEGREES, degreesSaga),
    takeLatest(types.GET_NPI_LIST, npiLookupSaga),
    takeLatest(types.EMAIL_VALIDATION, emailValidationSaga),
    takeLatest(types.ZIP_CODE_VALIDATION, zipCodeValidationSaga),
    takeLatest<EmailPreferencesTaskAction>(
      types.EMAIL_PREFERENCE_UPDATE,
      updateEmailPreferenceSaga
    ),
    takeLatest(types.GET_EMAIL_PREFERENCE, getEmailPreferenceSaga),
  ]);
}
