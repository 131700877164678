import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { ITransaction } from "src/models/ITransaction";
import { RequestError } from "src/models/RequestError";
import {
  PurchaseRequest,
  TransactionDetails,
  calculateTax,
  getTransactionHistory,
} from "src/services/purchaseService";

export const ProcessCalculateTax = (
  paymentRequest: PurchaseRequest
): UseQueryResult<TransactionDetails | RequestError> =>
  useQuery<TransactionDetails | RequestError>(
    [
      "calculateTaxService",
      {
        userId: paymentRequest.userId,
        product: paymentRequest.product,
        campaignCode: paymentRequest.campaignCode,
        paymentId: paymentRequest.paymentId,
      },
    ],
    () => calculateTax(paymentRequest),
    {
      enabled: paymentRequest.product !== "" && paymentRequest.userId !== -1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

export const ProcessCalculateTaxNoPromoCode = (
  paymentRequest: PurchaseRequest,
  promocodeError: boolean
): UseQueryResult<TransactionDetails | RequestError> =>
  useQuery<TransactionDetails | RequestError>(
    [
      "calculateTaxServiceNoPromoCode",
      {
        userId: paymentRequest.userId,
        product: paymentRequest.product,
        paymentId: paymentRequest.paymentId,
        promocodeError,
      },
    ],
    () => calculateTax(paymentRequest),
    {
      enabled:
        paymentRequest.product !== "" &&
        paymentRequest.userId !== -1 &&
        promocodeError,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
export const GetTransactionHistory = (
  userId: number
): UseQueryResult<ITransaction[]> =>
  useQuery<ITransaction[]>(
    ["transactionHistory", userId],
    () => getTransactionHistory(userId),
    {
      enabled: userId !== -1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
