import { put, call, CallEffect, PutEffect } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import * as types from "../actions";
import { SnackBarType } from "../utils/SnackBarType";
import { DemographicsResponse } from "../models/DemographicsResponse";
import {
  degreesService,
  demographicsService,
} from "../services/demographicsService";
import { CountriesResponse } from "../models/CountriesResponse";

// Important there is star after function
export function* countriesSaga(): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  try {
    yield put({ type: types.COUNTRIES_INPROGRESS });

    const path = "/v1/demographics/countries";
    const response = yield call(demographicsService, path);

    if (!response) {
      yield put({ type: types.COUNTRIES_SYSTEM_ERROR });
      return;
    }
    const countriesResponse: CountriesResponse = {
      data: response.data,
      status: response.status,
      done: false,
    };

    if (response.status === 200) {
      yield put({ type: types.COUNTRIES_SUCCESS, countriesResponse });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    } else if (response.status === 457) {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: "Countries not found",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.COUNTRIES_ERROR, countriesResponse });
    } else {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: "System error",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.LOGIN_SYSTEM_ERROR, countriesResponse });
    }
  } catch (error) {
    yield put({ type: types.COUNTRIES_ERROR, error });
  }
}

export function* occupationsSaga(): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  try {
    yield put({ type: types.OCCUPATIONS_INPROGRESS });

    const path = "/v1/demographics/occupations";
    const response = yield call(demographicsService, path);

    if (!response) {
      yield put({ type: types.OCCUPATIONS_SYSTEM_ERROR });
      return;
    }
    const occupationsResponse: DemographicsResponse = {
      data: response.data,
      status: response.status,
      done: false,
    };

    if (response.status === 200) {
      yield put({ type: types.OCCUPATIONS_SUCCESS, occupationsResponse });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    } else if (response.status === 457) {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: "Occupations not found",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.OCCUPATIONS_ERROR, occupationsResponse });
    } else {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: "System error",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.OCCUPATIONS_SYSTEM_ERROR, occupationsResponse });
    }
  } catch (error) {
    yield put({ type: types.OCCUPATIONS_ERROR, error });
  }
}

export function* specialtiesSaga(request: any): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  try {
    yield put({ type: types.SPECIALTIES_INPROGRESS });

    const path = `/v1/demographics/${request.occupationId}/specialties`;
    const response = yield call(demographicsService, path);

    if (!response) {
      yield put({ type: types.SPECIALTIES_SYSTEM_ERROR });
      return;
    }
    const specialtiesResponse: DemographicsResponse = {
      data: response.data,
      status: response.status,
      done: false,
    };

    if (response.status === 200) {
      yield put({ type: types.SPECIALTIES_SUCCESS, specialtiesResponse });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    } else if (response.status === 457) {
      if (response.data.error.message === "Specialties not found") {
        specialtiesResponse.data = [];
        specialtiesResponse.status = 200;
        yield put({ type: types.SPECIALTIES_SUCCESS, specialtiesResponse });
        yield put({ type: types.TIMEOUT_SNACKBAR });
      } else {
        yield put({
          type: types.SET_SNACKBAR_MESSAGE,
          message: "Specialties not found",
          messageType: SnackBarType.ERROR.displayValue,
        });
        yield put({ type: types.SPECIALTIES_ERROR, specialtiesResponse });
      }
    } else {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: "System error",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.SPECIALTIES_SYSTEM_ERROR, specialtiesResponse });
    }
  } catch (error) {
    yield put({ type: types.SPECIALTIES_ERROR, error });
  }
}

export function* subSpecialtiesSaga(request: any): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  try {
    yield put({ type: types.SUBSPECIALTIES_INPROGRESS });

    const path = `/v1/demographics/${request.subSpecialtiesRequest.occupationId}/${request.subSpecialtiesRequest.specialtyId}/subspecialties`;
    const response = yield call(demographicsService, path);

    if (!response) {
      yield put({ type: types.SUBSPECIALTIES_SYSTEM_ERROR });
      return;
    }
    const subSpecialtiesResponse: DemographicsResponse = {
      data: response.data,
      status: response.status,
      done: false,
    };

    if (response.status === 200) {
      yield put({ type: types.SUBSPECIALTIES_SUCCESS, subSpecialtiesResponse });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    } else if (response.status === 457) {
      if (response.data.error.message === "Sub Specialties not found") {
        subSpecialtiesResponse.data = [];
        subSpecialtiesResponse.status = 200;
        yield put({
          type: types.SUBSPECIALTIES_SUCCESS,
          subSpecialtiesResponse,
        });
        yield put({ type: types.TIMEOUT_SNACKBAR });
      } else {
        yield put({
          type: types.SET_SNACKBAR_MESSAGE,
          message: "Sub specialties not found",
          messageType: SnackBarType.ERROR.displayValue,
        });
        yield put({ type: types.SUBSPECIALTIES_ERROR, subSpecialtiesResponse });
      }
    } else {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: "System error",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({
        type: types.SUBSPECIALTIES_SYSTEM_ERROR,
        subSpecialtiesResponse,
      });
    }
  } catch (error) {
    yield put({ type: types.SUBSPECIALTIES_ERROR, error });
  }
}

export function* degreesSaga(): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      type: string;
    }>,
  void,
  AxiosResponse
> {
  try {
    yield put({ type: types.DEGREES_INPROGRESS });

    const response = yield call(degreesService);

    if (!response) {
      yield put({ type: types.DEGREES_SYSTEM_ERROR });
      return;
    }
    const degreesResponse: DemographicsResponse = {
      data: response.data.degrees,
      status: response.status,
      done: false,
    };

    if (response.status === 200) {
      yield put({ type: types.DEGREES_SUCCESS, degreesResponse });
      yield put({ type: types.TIMEOUT_SNACKBAR });
    } else if (response.status === 401) {
      yield put({ type: types.REQUIRE_USER_MANUAL_LOGIN });
    } else if (response.status >= 400 && response.status < 500) {
      if (response.data.error.message === "Degrees not found") {
        degreesResponse.data = [];
        degreesResponse.status = 200;
        yield put({ type: types.DEGREES_SUCCESS, degreesResponse });
        yield put({ type: types.TIMEOUT_SNACKBAR });
      } else {
        yield put({
          type: types.SET_SNACKBAR_MESSAGE,
          message: "Degrees not found",
          messageType: SnackBarType.ERROR.displayValue,
        });
        yield put({ type: types.DEGREES_ERROR, degreesResponse });
      }
    } else {
      yield put({
        type: types.SET_SNACKBAR_MESSAGE,
        message: "System error",
        messageType: SnackBarType.ERROR.displayValue,
      });
      yield put({ type: types.DEGREES_SYSTEM_ERROR, degreesResponse });
    }
  } catch (error) {
    yield put({ type: types.DEGREES_ERROR, error });
  }
}
