import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../config/colorConfig";
import { strings } from "../utils/Strings";
import { Button } from "./custom/Button";

import { SubscriptionDialog } from "./SubscriptionDialog";
import epocratesImage from "../images/epocrates_plus.png";

interface SubscriptionCancellationThanksProps {
  open: boolean;
  setThanksDialog: (thanksDialog: boolean) => void;
}

export const SubscriptionCancellationThanks = (
  props: SubscriptionCancellationThanksProps
): JSX.Element => {
  const dialogBoxStyles = makeStyles((theme) => ({
    contentAlignment: {
      margin: `${theme.spacing(3)}px ${theme.spacing(10)}px 0px ${theme.spacing(
        10
      )}px`,
    },
    popUpText: {
      fontSize: "24px",
      lineHeight: "28px",
    },
    subDate: {
      fontWeight: 500,
      color: colors["--ui-midnight"],
    },
    popUpSubText: {
      fontSize: "16px",
      color: colors["--ui-slate"],
    },

    cancelLink: {
      color: `${colors["--primary-teal"]}`,
      fontWeight: 500,
      fontSize: "16px",
      whiteSpace: "nowrap",
      "&:hover": {
        textDecoration: "none",
        color: `${colors["--primary-teal"]}`,
      },
    },
    cancelSubtext: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      color: colors["--primary-midnight"],
    },

    noCancel: {
      marginTop: `${theme.spacing(4)}px`,
      backgroundColor: `${colors["--text-invert"]}`,
      color: `${colors["--primary-midnight"]}`,
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 500,
      border: `solid 1px ${colors["--ui-slate"]}`,
      "&:disabled": {
        backgroundColor: `${colors["--ui-grey-semi"]}`,
        color: `${colors["--ui-grey-med"]}`,
        border: "none",
      },
    },
    switchColor: {
      "&.Mui-checked": {
        color: `${colors["--primary-teal"]}`,
      },
    },
    formControl: {
      height: 28,
    },
    ButtonRow: {
      display: "flex",
      alignItems: "left",
      marginTop: `${theme.spacing(4)}px`,
    },
    subFormButtonFields: {
      flexGrow: 0,
      minWidth: 180,
      marginRight: `${theme.spacing(2)}px`,
    },
    contactLink: {
      marginTop: `${theme.spacing(4)}px`,
      marginBottom: `${theme.spacing(4)}px`,
    },
    imagesInfo: {
      display: "flex",
      alignItems: "left",
      marginBottom: `${theme.spacing(2)}px`,
    },
    textInfo: {
      marginTop: `${theme.spacing(4)}px`,
      marginLeft: `${theme.spacing(3)}px`,
    },
    tilesTitle: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      color: colors["--primary-midnight"],
    },
    rectangle: {
      display: "flex",
      width: 56,
      height: 56,
      left: 79,
      top: 133,
      background: "#EFF7FE",
      borderRadius: 4,
    },
    inProgressCancel: {
      width: 500,
      height: 400,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "-56px",
    },
    loadingLogo: {
      width: 250,
      height: 48,
      marginTop: `${theme.spacing(3)}px`,
    },
    thanksInfo: {
      width: 322,
      height: 20,
      fontSize: "14px",
      lineHeight: "20px",
      textAlign: "center",
    },
    thanksReturn: {
      marginTop: "36px",
    },
  }));

  const handleClose = () => {
    props.setThanksDialog(false);
  };

  const returnSubscription = () => {
    props.setThanksDialog(false);
  };

  const classes = dialogBoxStyles();

  return (
    <SubscriptionDialog
      open={props.open}
      setClose={handleClose}
      handleCloseEvent={handleClose}
      width={600}
      isTitleLarge
      title={strings.subscription_cancelled}
    >
      <div>
        <div className={clsx(classes.inProgressCancel)}>
          <div data-testid="thanksInfo">Thank you for using</div>
          <img
            className={clsx(classes.loadingLogo)}
            src={epocratesImage}
            alt=""
          />
          {/* <Typography className={clsx(classes.thanksInfo)}>
            {" "}
            you will receive and email confirmation your subscription has been
            cancelled successfully
          </Typography> */}
          <div className={clsx(classes.thanksReturn)}>
            <Button
              name="Return to epocrates"
              id="returnToEpocreates"
              type="button"
              onClick={returnSubscription}
              data-testid="returnToEpocreates"
            />
          </div>
        </div>
      </div>
    </SubscriptionDialog>
  );
};
