import clsx from "clsx";
import { Box, Modal, Typography, makeStyles } from "@material-ui/core";
import { colors } from "../config/colorConfig";
import { Button } from "./custom/Button";
import close from "../images/close.svg";
import { ButtonBase } from "@mui/material";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSignAllOutClick: () => void;
  title: string;
  description: string;
}

export const SignOutModal = (prop: Props) => {
  const isMobileScreen = window.innerWidth < 600;
  const { open, handleClose, handleSignAllOutClick, title, description } = prop;
  const signOutModalStyle = makeStyles((theme) => ({
    buttonBox: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 452,
      backgroundColor: colors["--white"],
      borderRadius: "4px",
      boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      [theme.breakpoints.down("xs")]: {
        width: 340,
      },
    },
    signOutWrapper: {
      padding: "32px",
      [theme.breakpoints.down("xs")]: {
        padding: "28px",
      },
      width: "100%",
    },
    headerWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 10,
    },
    modalHeading: {
      fontSize: "24px",
      fontWeight: 600,
      fontFamily: "Source Sans Pro",
      lineHeight: "32px",
      width: "90%",
    },
    modalDescription: {
      marginTop: "16px",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Source Sans Pro",
      lineHeight: "24px",
    },
    buttonWrapper: {
      marginTop: "48px",
      display: "flex",
      flexDirection: "row",
      gap: isMobileScreen ? 0 : 8,
    },
  }));
  const classes = signOutModalStyle();

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={clsx(classes.buttonBox)}>
        <div className={clsx(classes.signOutWrapper)}>
          <div className={clsx(classes.headerWrapper)}>
            <Typography
              id="modal-modal-title"
              className={clsx(classes.modalHeading)}
            >
              {title}
            </Typography>
            <ButtonBase onClick={handleClose}>
              <img data-testid="close" src={close} alt="close sign out modal" />
            </ButtonBase>
          </div>
          <Typography
            id="modal-modal-description"
            className={clsx(classes.modalDescription)}
          >
            {description}
          </Typography>
          <div className={clsx(classes.buttonWrapper)}>
            <Button
              name="Cancel"
              variant="outlined"
              width={isMobileScreen ? 140 : 182}
              height={48}
              fontSize={18}
              fontWeight={600}
              backgroundColor="transparent"
              foregroundColor={colors["--primary-midnight"]}
              hoverForeground="transparent"
              border={`1.5px solid ${colors["--primary-midnight"]}`}
              fontFamily="Source Sans Pro"
              onClick={handleClose}
              margins="0 8px 0 0 "
            />
            <Button
              name="Yes, sign out"
              variant="outlined"
              width={isMobileScreen ? 140 : 182}
              height={48}
              fontSize={18}
              fontWeight={600}
              backgroundColor={colors["--primary-tealnight"]}
              foregroundColor={colors["--text-invert"]}
              hoverForeground={colors["--primary-tealnight"]}
              fontFamily="Source Sans Pro"
              onClick={handleSignAllOutClick}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};
