import { makeStyles, Paper, Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ApplicationAltTitle } from "./custom/ApplicationAltTitle";
import { colors } from "../config/colorConfig";
import { AppAltBackground } from "../components/custom/AppAltBackground";
import { QRFooter } from "../components/custom/QRFooter";
import { licenseTheme } from "../styles/licenseTheme";
import { TextField } from "./custom/TextField";
import { Button } from "./custom/Button";
import { MouseEvent, useEffect, useState, useRef } from "react";
import { strings } from "../utils/Strings";
import { useMutation } from "@tanstack/react-query";
import { redeemLicenseCode } from "../services/licenseService";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { sendSuccessSnackBarMessage } from "../actions/snackBarActions";
import { SnackBarType } from "../utils/SnackBarType";
import { GetUserSubscriptions } from "../queries/GetUserSubscriptions";
import { Subscription, ProductInfo } from "../models/SubscriptionResponse";
import { RouteComponentProps } from "react-router-dom";
import dayjs from "dayjs";
import { getUserDetailsAction } from "../actions/profileActions";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import {
  GROUP_LICENSING_REDEMPTION_FORM_REDEEM_CLICK,
  GROUP_LICENSING_REDEMPTION_FORM_REDEMPTION_FAILURE,
  GROUP_LICENSING_REDEMPTION_FORM_REDEMPTION_SUCCESSFUL,
  GROUP_LICENSING_REDEMPTION_FORM_VIEW,
  GroupLicenseSegmentPayload,
} from "../utils/analytics";
import { LoadingEpocLogo } from "./custom/LoadingEpocLogo";
import epocratesPlusIcon from "../images/epocrates-plus-tick-icon.svg";

interface RedeemLicenseProp {
  history: RouteComponentProps["history"];
}
export interface RedeemRequestPayload {
  licenseCode: string;
}
const redemptionSKU = "PLUS_YEAR_GROUP_LICENSE_CODE_REDEMPTION";

export const RedeemLicense = (props: RedeemLicenseProp) => {
  const bodyWrapper = makeStyles((theme) => ({
    box: {
      marginTop: theme.spacing(16),
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      padding: `${theme.spacing(8)}px ${theme.spacing(12)}px ${theme.spacing(
        12
      )}px`,
      [theme.breakpoints.down("md")]: {
        padding: `${theme.spacing(8)}px ${theme.spacing(4)}px ${theme.spacing(
          12
        )}px`,
      },
    },
    paper: {
      padding: "20px 20px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: 300,
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px 0",
        height: 240,
      },
      [theme.breakpoints.up("md")]: {
        width: 350,
        height: 240,
      },
      marginTop: theme.spacing(32),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    successPaper: {
      padding: "20px 20px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: 340,
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px 0",
        height: 340,
      },
      [theme.breakpoints.up("md")]: {
        width: 350,
        height: 320,
      },
      marginTop: theme.spacing(24),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    subscriptionExistsPaper: {
      padding: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: 340,
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px 0",
        height: 340,
      },
      [theme.breakpoints.up("md")]: {
        width: 350,
        height: 451,
      },
      marginTop: theme.spacing(24),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "8px",
    },
    subscriptionExistsTitle: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "32px",
      paddingBottom: theme.spacing(2),
    },
    loadingDiv: {
      justifyContent: "center",
    },

    title: {
      paddingBottom: theme.spacing(16),
      color: colors["--title"],
      fontWeight: 300,
      fontSize: 24,
      textAlign: "center",
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    subTitleDiv: {
      margin: `0px ${theme.spacing(2)}px`,
      textAlign: "center",
    },
    subTitle: {
      fontSize: 24,
      lineHeight: "32px",
    },
    loadingLogo: {
      width: 50,
      height: 50,
    },
    successTitle: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: "32px",
      paddingBottom: theme.spacing(4),
    },
    successSubTitle: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "24px",
      paddingBottom: theme.spacing(1),
    },
    successSubTitle1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "24px",
      paddingBottom: theme.spacing(2),
    },
    openApp: {
      display: "flex",
      flexDirection: "column",
      margin: `${theme.spacing(4)}px 0px`,
      gap: "16px",
    },
    epocImage: {
      paddingBottom: theme.spacing(2),
    },
  }));
  const classes = bodyWrapper(licenseTheme);
  const [redeemCode, setRedeemCode] = useState<string>("");
  const [validRedeemCode, setValidRedeemCode] = useState(false);
  const userId = localStorage.getItem(CURRENT_USER_ID) ?? "";
  const [redeemCodeError, setRedeemCodeError] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>("");
  const [isMobilePlatform, setIsMobilePlatform] = useState(false);
  const [isPlusSubscriber, setIsPlusSubscriber] = useState(false);
  const [email, setEmail] = useState<string>("");
  const redeemQueryErrorCount = useRef<number>(0);
  const dispatch = useDispatch();

  const subscriptionQuery = GetUserSubscriptions(userId);
  const redeemQuery = useMutation(redeemLicenseCode, {
    onSuccess: (responseData) => {
      if (!(responseData.status >= 200 && responseData.status <= 299)) {
        setRedeemCodeError(true);
        redeemQueryErrorCount.current = redeemQueryErrorCount.current + 1;
      } else {
        //On success on redeem call
        const segmentPayload: GroupLicenseSegmentPayload = {
          "Group Code": redeemCode,
          "Purchase Type": "Group License Redemption",
        };
        sendAmplitudeEvent(
          GROUP_LICENSING_REDEMPTION_FORM_REDEMPTION_SUCCESSFUL,
          segmentPayload
        );
        //refetch the subscription data
        subscriptionQuery.refetch();
      }
    },
    onError: (error) => {
      setRedeemCodeError(true);
    },
  });
  const formatRedeemCode = (data: string): string => {
    const inputValue = data.replace(/-/g, "");
    let formattedValue = "";
    for (let i = 0; i < inputValue.length; i += 4) {
      formattedValue += inputValue.slice(i, i + 4) + "-";
    }
    if (formattedValue.endsWith("-")) {
      formattedValue = formattedValue.slice(0, -1);
    }
    return formattedValue;
  };
  const validateRedeemCode = (data: string) => {
    if (data) {
      setRedeemCode(formatRedeemCode(data));
      const licenseWithoutHyphens = data.replace(/-/g, "");
      if (licenseWithoutHyphens.length === 16) {
        setValidRedeemCode(true);
      } else {
        setValidRedeemCode(false);
        return strings.license_redeem_string;
      }
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    redeemQueryErrorCount.current = 0;
    const formData = new FormData(event.currentTarget);
    const redeemField = formData.get("redeemCode");
    if (typeof redeemField === "string") {
      const segmentPayload: GroupLicenseSegmentPayload = {
        "Group Code": redeemField,
      };
      sendAmplitudeEvent(
        GROUP_LICENSING_REDEMPTION_FORM_REDEEM_CLICK,
        segmentPayload
      );
      const licenseWithoutHyphens = redeemField.replace(/-/g, "");
      setRedeemCode(licenseWithoutHyphens);
      setRedeemCodeError(false);
      redeemQuery.mutateAsync({ licenseCode: licenseWithoutHyphens });
    }
  };

  const userProfileState = useSelector(
    (accountProfileState: RootStateOrAny) => accountProfileState.accountProfile
  );
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const redeemQueryParam = queryParams.get("code");
    sendAmplitudeEvent(GROUP_LICENSING_REDEMPTION_FORM_VIEW);
    if (redeemQueryParam) {
      setRedeemCode(redeemQueryParam);
      validateRedeemCode(redeemQueryParam);
    }
    if (/iPhone/i.test(navigator.userAgent)) {
      setIsMobilePlatform(true);
    } else if (/Mobi|Android/i.test(navigator.userAgent)) {
      setIsMobilePlatform(true);
    } else {
      setIsMobilePlatform(false);
    }
    dispatch(getUserDetailsAction());
  }, []);

  useEffect(() => {
    if (userProfileState.accountProfileResponse?.userInformation) {
      setEmail(userProfileState.accountProfileResponse.userInformation.email);
    }
  }, [userProfileState.accountProfileResponse?.userInformation]);

  useEffect(() => {
    if (redeemQuery.isSuccess) {
      if (subscriptionQuery?.data?.data) {
        const subscription = subscriptionQuery.data.data.find(
          (licenseSub: Subscription) =>
            licenseSub.activeProducts.find(
              (product: ProductInfo) => product.id === redemptionSKU
            )
        );
        if (subscription?.ends) {
          setSubscriptionEndDate(dayjs(subscription.ends).format("MM/DD/YY"));
        }
      }
    } else {
      const activePlusSubscription = subscriptionQuery?.data?.data.find(
        (licenseSub: Subscription) =>
          licenseSub.active &&
          licenseSub.activeProducts.find(
            (product: ProductInfo) => product.metadata.PRODUCT_FAMILY === "PLUS"
          )
      );
      if (activePlusSubscription) {
        setSubscriptionEndDate(
          dayjs(activePlusSubscription.ends).format("MM/DD/YY")
        );
        setIsPlusSubscriber(true);
      }
    }
  }, [subscriptionQuery?.data?.data]);

  useEffect(() => {
    if (
      (redeemCodeError && redeemQueryErrorCount.current > 1) ||
      subscriptionQuery.error
    ) {
      const segmentPayload: GroupLicenseSegmentPayload = {
        "Group Code": redeemCode,
        "Purchase Type": "Group License Redemption",
      };
      sendAmplitudeEvent(
        GROUP_LICENSING_REDEMPTION_FORM_REDEMPTION_FAILURE,
        segmentPayload
      );
      dispatch(
        sendSuccessSnackBarMessage(
          strings.license_code_error_title_text,
          strings.license_code_error_text,
          SnackBarType.ERROR.displayValue
        )
      );
    } else if (redeemCodeError && redeemQueryErrorCount.current <= 1) {
      setRedeemCodeError(false);
      redeemQuery.mutateAsync({ licenseCode: `-${redeemCode}` });
    }
  }, [redeemCodeError, subscriptionQuery.error]);

  const clickToOpenWeb = () => {
    window.location.href = "https://online.epocrates.com";
  };
  const clickToOpenApp = () => {
    window.location.href = "https://online.epocrates.com/contextuallink/home";
  };
  const clickToOpenAccount = () => {
    props.history.push("/account/subscription");
  };
  let body;
  switch (true) {
    // Loading Redeem screen
    case redeemQuery.isLoading || subscriptionQuery.isFetching:
      body = (
        <Paper className={clsx(classes.paper)} elevation={0}>
          <div className={clsx(classes.loadingDiv)}>
            <LoadingEpocLogo />
          </div>
        </Paper>
      );
      break;
    // Subscription exists screen
    case isPlusSubscriber:
      body = (
        <Paper
          className={clsx(
            classes.subscriptionExistsPaper,
            classes.contentWrapper
          )}
          elevation={0}
        >
          <div className={classes.subTitleDiv}>
            <img
              src={epocratesPlusIcon}
              alt="Epocrates Plus"
              className={classes.epocImage}
            />
            <Typography className={classes.subscriptionExistsTitle}>
              Good news
            </Typography>
            <Typography className={classes.successSubTitle}>
              {email} is already subscribed to epocrates+
            </Typography>
            <Typography className={classes.successSubTitle1}>
              A subscription code may be redeemed after the current subscription
              period ends. The current subscription will expire on{" "}
              {subscriptionEndDate}. Manage your subscription settings in your
              Account or go to epocrates Web
            </Typography>
          </div>
          <form>
            <div className={classes.openApp}>
              <Button
                name="Go to Account"
                id="account"
                testId="account"
                type="button"
                border={`solid 1px ${colors["--primary-midnight"]}`}
                backgroundColor={colors["--white"]}
                hoverForeground={colors["--white"]}
                foregroundColor={colors["--primary-midnight"]}
                onClick={clickToOpenAccount}
              />
              <Button
                name="Go to epocrates Web"
                id="ContinueWeb"
                testId="ContinueWeb"
                type="button"
                border={`solid 1px ${colors["--primary-midnight"]}`}
                backgroundColor={colors["--primary-tealnight"]}
                hoverForeground={colors["--primary-tealnight"]}
                onClick={clickToOpenWeb}
              />
            </div>
          </form>
        </Paper>
      );
      break;
    // Success Redeem screen
    case redeemQuery.isSuccess && subscriptionQuery.isFetched:
      body = (
        <Paper className={clsx(classes.successPaper)} elevation={0}>
          <div className={classes.contentWrapper}>
            <div className={classes.subTitleDiv}>
              <Typography className={classes.successTitle}>Success!</Typography>
              <Typography className={classes.successSubTitle}>
                Your account has been upgraded to epocrates+
              </Typography>
              <Typography className={classes.successSubTitle1}>
                You now have access to all of our features. Your subscription
                will expire on {subscriptionEndDate}
              </Typography>
            </div>
            <form data-testid="updateAccountForm">
              {isMobilePlatform ? (
                <div className={classes.openApp}>
                  <Button
                    name="Open the app"
                    id="selectContinue"
                    testId="selectContinue"
                    type="button"
                    backgroundColor={colors["--primary-tealnight"]}
                    hoverForeground={colors["--primary-tealnight"]}
                    onClick={clickToOpenApp}
                  />
                </div>
              ) : (
                <div className={classes.openApp}>
                  <Button
                    name="Continue on epocrates Web"
                    id="ContinueWeb"
                    testId="selectContinue"
                    type="button"
                    border={`solid 1px ${colors["--primary-midnight"]}`}
                    backgroundColor={colors["--white"]}
                    hoverForeground={colors["--white"]}
                    foregroundColor={colors["--primary-midnight"]}
                    onClick={clickToOpenWeb}
                  />
                </div>
              )}
            </form>
          </div>
        </Paper>
      );
      break;
    default:
      // Intial Redeem screen
      body = (
        <Paper className={clsx(classes.paper)} elevation={0}>
          <div className={classes.contentWrapper}>
            <div className={classes.subTitleDiv}>
              <Typography variant="subtitle1" className={classes.subTitle}>
                Redeem an epocrates+ subscription code
              </Typography>
            </div>
            <form data-testid="updateAccountForm" onSubmit={handleSubmit}>
              <div>
                <TextField
                  name="redeemCode"
                  testId="redeemCode"
                  helpTestId="redeemCodeHelp"
                  label="Enter Redeem Code"
                  validation={validateRedeemCode}
                  isNewDesign={true}
                  value={redeemCode}
                />
              </div>
              <div>
                <Button
                  name="Redeem"
                  id="selectContinue"
                  testId="selectContinue"
                  type="submit"
                  disabled={!validRedeemCode}
                  backgroundColor={colors["--primary-tealnight"]}
                  hoverForeground={colors["--primary-tealnight"]}
                />
              </div>
            </form>
          </div>
        </Paper>
      );
  }

  const pageBody = (
    <>
      <Box
        className={clsx(classes.box)}
        zIndex="drawer"
        data-testid="redeemForm"
      >
        <ApplicationAltTitle />
        {body}
      </Box>
      <QRFooter />
    </>
  );
  return <AppAltBackground pageBody={pageBody} />;
};
