import { setCookie } from "../utils/cookies";
import {
  CURRENT_USER_NAME,
  CURRENT_USER_ID,
  STAY_LOGGED_IN,
} from "../utils/localStorageKeys";
import {
  sendAmplitudeEvent,
  getMarketingSource,
  updateOptimizelyFlagsForSegment,
} from "../utils/AmplitudeUtil";
import {
  SUCCESSFULLY_SIGN_IN,
  SUCCESSFULLY_SIGN_IN_EVENT_ID,
} from "../utils/analytics";
import { config } from "../config/envConfig";
import { AuthResponse } from "../models/AuthResponse";
import { ToolsLocationForEvents } from "../models/ToolsLocationForEvents";
import { History, Location } from "history";
import { ReactSDKClient } from "@optimizely/react-sdk";

interface LocationStateType {
  from: {
    pathname: string;
    search: string;
  };
}

interface LoginOptions {
  authResponse: AuthResponse;
  stayLoggedIn: boolean;
  toolsLocation: ToolsLocationForEvents;
  location: Location;
  history: History;
  loginMethod?: string;
  qrToken?: string;
  optimizely?: ReactSDKClient | null;
}

interface EventObject {
  "Event ID": string;
  "Email ID": string;
  Source: string;
  Tools: string | null;
  Location: string | null;
  Method: string;
  "QR Token": string | null;
  "Conference Source"?: string;
}

let logOutUser: ((queryParams: Record<string, string>) => void) | null = null;

export const finishLogin = ({
  authResponse,
  stayLoggedIn,
  toolsLocation,
  location,
  history,
  loginMethod,
  qrToken,
  optimizely,
}: LoginOptions): void => {
  const queryParams = new URLSearchParams(history.location.search);

  const referNextUrl = queryParams.get("refernext") ?? "";
  const cmeHandoffUrl = queryParams.get("handoffUrl") ?? "";
  const hasResumePreviousWorkflow: boolean = queryParams.has(
    "resumePreviousWorkflow"
  );
  let webSource = "ECOMM";
  if (cmeHandoffUrl) {
    webSource = "CME";
  }
  if (hasResumePreviousWorkflow) {
    webSource = queryParams.get("resumePreviousWorkflow") ?? "Unknown";
  }
  if (authResponse.email) {
    localStorage.setItem(CURRENT_USER_NAME, authResponse.email);
  }
  const userId = authResponse?.userId?.toString();
  localStorage.setItem(CURRENT_USER_ID, userId);
  localStorage.setItem(STAY_LOGGED_IN, stayLoggedIn.toString());
  setCookie("sessionTimer", 24, "sessionTimer");
  setCookie("stayLoggedIn", 24, stayLoggedIn.toString());
  setCookie("accessToken", 24, authResponse.tokens.accessToken);
  setCookie("refreshToken", 24, authResponse.tokens.refreshToken);
  setCookie("currentUserId", 24, userId);
  const properties: EventObject = {
    "Event ID": SUCCESSFULLY_SIGN_IN_EVENT_ID,
    "Email ID": authResponse.email,
    Source: webSource,
    Tools: toolsLocation.tools,
    Location: toolsLocation.location,
    Method: loginMethod ?? "Email",
    "QR Token": qrToken ?? null,
  };
  const conferenceSource = getMarketingSource();
  if (conferenceSource != null) {
    properties["Conference Source"] = conferenceSource;
  }
  if (optimizely) {
    const decisions = optimizely.decideAll(undefined, userId);
    updateOptimizelyFlagsForSegment(userId, decisions);
  }
  sendAmplitudeEvent(SUCCESSFULLY_SIGN_IN, properties, userId);
  if (location?.state && hasResumePreviousWorkflow) {
    const locationState = location.state as LocationStateType;
    history.push(locationState?.from?.pathname + locationState?.from?.search);
  } else if (cmeHandoffUrl) {
    const url = new URL(cmeHandoffUrl);
    url.searchParams.set("riejwt", authResponse.token);
    window.location.href = url.toString();
  } else if (referNextUrl) {
    window.location.href = referNextUrl;
  } else {
    const eolURL = `${config.environment.eolURL}`;
    window.location.href = eolURL;
  }
};

export const setLogOutUserFunction = (
  logoutFn: (queryParams: Record<string, string>) => void
) => {
  if (!logOutUser) {
    logOutUser = logoutFn;
  }
};

export const getLogOutUserFunction = () => {
  return logOutUser;
};
