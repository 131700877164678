import * as React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { withRoot } from "../withRoot";
import { CreateAccountLeftNavWith as CreateAccountLeftNav } from "../components/CreateAccountLeftNav";
import { CreateAccountRightPaneWith as CreateAccountRightPane } from "../components/CreateAccountRightPane";
import { strings } from "../utils/Strings";
import { AppAltBackground } from "src/components/custom/AppAltBackground";
import { QRFooter } from "src/components/custom/QRFooter";
import { ApplicationAltTitle } from "src/components/custom/ApplicationAltTitle";
import { Helmet } from "react-helmet-async";

const CreateAccount = (props: any) => {
  const createAccountStyle = makeStyles((theme) => ({
    wrapper: {
      display: "flex",
      width: "85%",
      margin: "64px auto 24px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "32px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "95%",
      },
    },
    applicationAltTitle: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
  }));
  const classes = createAccountStyle(props);
  const pageBody = (
    <>
      <div className={clsx(classes.wrapper)}>
        <Helmet>
          <meta
            name="description"
            content={strings.create_account_meta_description}
          />
        </Helmet>
        <div className={clsx(classes.applicationAltTitle)}>
          <ApplicationAltTitle />
        </div>
        <CreateAccountLeftNav />
        <CreateAccountRightPane />
      </div>
      <QRFooter />
    </>
  );
  return <AppAltBackground pageBody={pageBody} />;
};
export const CreateAccountWith = withRoot(CreateAccount);
