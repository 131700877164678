import { CssBaseline } from "@material-ui/core";
import { AppAltBackground } from "src/components/custom/AppAltBackground";
import { QRFooter } from "src/components/custom/QRFooter";
import { RouteComponentProps } from "react-router-dom";
import { QRSignInFormWrap } from "./QRSignInFormWrap";
export const LoginWrap = (props: RouteComponentProps) => {
  const pageBody = (
    <>
      <CssBaseline />
      <QRSignInFormWrap isQrLoginEnabled={true} {...props} />
      <QRFooter />
    </>
  );
  return <AppAltBackground pageBody={pageBody} />;
};
