import React, { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Desktop, TabletMobile } from "./custom/responsive";
import errorIllustrator1x from "../images/Error-Illustration@1x.png";
import errorIllustrator2x from "../images/Error-Illustration@2x.png";
import errorIllustrator3x from "../images/Error-Illustration@3x.png";
import logo from "../images/brand-logo-primary-midnight.svg";
import { withRoot } from "../withRoot";
import { colors } from "../config/colorConfig";
import { httpConstants } from "../utils/httpConstants";
import { config } from "../config/envConfig";
import { sendAmplitudeEvent } from "src/utils/AmplitudeUtil";
import { WEB_ERROR_TRACK } from "src/utils/analytics";

interface ErrorScreenProps {
  code: number;
}

const ErrorScreen = (props: ErrorScreenProps) => {
  const styles = makeStyles((theme) => ({
    redirectButton: {
      width: 139,
      padding: `${theme.spacing(3)}px 0`,
      borderRadius: 3,
      fontSize: 16,
      fontWeight: 600,
      border: "none",
      marginRight: 10,
      color: colors["--text-invert"],
      backgroundColor: colors["--primary-midnight"],
      [theme.breakpoints.down("md")]: {
        marginTop: 42,
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
    errorScreenContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      paddingTop: 130,
      paddingBottom: 130,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        paddingTop: 62,
      },
    },
    messageContainer: {
      height: 400,
      marginRight: 50,
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
    },
    errorCode: {
      marginBottom: 30,
      fontSize: 38,
      fontWeight: 10,
      lineHeight: 1.05,
      color: colors["--ui-midnight"],
    },
    errorMessage: {
      marginBottom: 48,
      fontSize: 16,
      lineHeight: 1.25,
    },
    brandLogo: {
      marginTop: 28,
    },
    errorSection: {
      marginTop: 62,
      [theme.breakpoints.down("md")]: {
        marginTop: 32,
      },
    },
    imgContainer: {
      width: "400px",
      height: "400px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
        height: "200px",
      },
    },
    errorImg: {
      width: "100%",
    },
  }));
  const classes = styles(props);
  let errorMessage;
  let buttons;
  function backToHome() {
    const url = `${config.environment.eolURL}`;
    window.location.href = url;
  }

  useEffect(() => {
    const eventProperties = {
      "Error code": 404,
      "Current URL": window.location.href,
    };
    sendAmplitudeEvent(WEB_ERROR_TRACK, eventProperties);
  }, []);
  switch (props.code) {
    case httpConstants.PAGE_NOT_FOUND:
      buttons = (
        <div>
          <button
            onClick={backToHome}
            className={clsx(classes.redirectButton)}
            type="button"
            data-testid="homeButton"
          >
            Go To Home
          </button>
        </div>
      );
      errorMessage = (
        <>
          <Typography data-testid="errorText">
            Page could not be found.
            <br />
            Try locating it from the home page.
          </Typography>
        </>
      );
      break;
    case httpConstants.INTERNAL_SERVER_ERROR:
    default:
      buttons = (
        <div className="button">
          <button
            onClick={() => {
              window.location.reload();
            }}
            className={clsx(classes.redirectButton)}
            type="button"
            data-testid="reloadButton"
          >
            Reload
          </button>
          <button
            onClick={backToHome}
            className={clsx(classes.redirectButton)}
            type="button"
            data-testid="homeButton"
          >
            Go To Home
          </button>
        </div>
      );
      errorMessage = (
        <>
          <Typography data-testid="errorText">
            Unexpected browser error.
            <br />
            Please reload the page.
          </Typography>
        </>
      );
      break;
  }

  const errorSection = (
    <>
      <Typography className={clsx(classes.errorCode)} data-testid="errorTitle">
        {props.code ? props.code : httpConstants.INTERNAL_SERVER_ERROR} Error
      </Typography>
      <div className={clsx(classes.errorMessage)}>{errorMessage}</div>
    </>
  );
  return (
    <div
      className={clsx(classes.errorScreenContainer)}
      data-testid="errorWrapper"
    >
      <div className={clsx(classes.messageContainer)}>
        <img className={clsx(classes.brandLogo)} src={logo} alt="" />
        <div className={clsx(classes.errorSection)}>{errorSection}</div>
        <Desktop>{buttons}</Desktop>
      </div>
      <div
        className={clsx(classes.imgContainer)}
        data-testid="errorImgWraapper"
      >
        <img
          src={errorIllustrator1x}
          srcSet={`${errorIllustrator2x} 2x,${errorIllustrator3x} 3x`}
          className={clsx(classes.errorImg)}
          alt=""
        />
      </div>
      <TabletMobile>{buttons}</TabletMobile>
    </div>
  );
};
export const ErrorScreentWithRoot = withRoot(ErrorScreen);
