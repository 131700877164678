import { Analytics } from "@segment/analytics-next";
import { UserInformation } from "src/models/AccountProfileResponse";
import { getCookie, setCookie } from "./cookies";
import { OptimizelyDecision } from "@optimizely/react-sdk";

interface LibraryEventProperties {
  "Header Name"?: string;
  "Item Name"?: string;
}

let outstandingEvents = 0;

function decrementOutstandingEvents() {
  if (outstandingEvents > 0) {
    outstandingEvents -= 1;
  }
}

/**
 * @deprecated Use Segment Analytics directly instead
 */
export async function awaitAllOutstandingEvents(
  maxTimeoutMs = 5000
): Promise<void> {
  const started = new Date().getTime();
  let now = started;
  while (now - started < maxTimeoutMs && outstandingEvents > 0) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => {
      setTimeout(resolve, 20);
    });
    now = new Date().getTime();
  }
}

let currentAmplitudeUser: string | null = null;
let analytics: Analytics | null;

/**
 * @deprecated Use Segment Analytics directly instead
 */
export function initializeSegmentClient(init: Analytics | null): void {
  analytics = init;
}

/**
 * @deprecated Use Segment Analytics directly instead
 */
export function initializeAmplitudeForUser(user: string | null): void {
  if (currentAmplitudeUser !== user) {
    analytics?.identify(
      user,
      {},
      {
        integrations: { Amplitude: { session_id: getSessionId() } },
        anonymousId: getAjsId(),
      }
    );
  }
  currentAmplitudeUser = user;
}

/**
 * @deprecated Use Segment Analytics directly instead
 */
export function resetAmplitude(): void {
  currentAmplitudeUser = null;
}

function getSessionId(): string {
  let sessionId = getCookie("amplitudeSessionId");
  if (!sessionId) {
    sessionId = Date.now().toString();
    setCookie("amplitudeSessionId", 0.5, sessionId);
  }
  return sessionId;
}

function getAjsId(): string {
  return getCookie("ajs_anonymous_id");
}

/**
 * @deprecated Use Segment Analytics directly instead
 */
export const sendAmplitudeEvent = async (
  eventName: string,
  data?: any,
  user?: string | null
): Promise<void> => {
  //add prev page url
  data = {
    ...data,
    "Previous Page URL": document.referrer,
    "Current URL": window.location.href,
    Business: "Regular",
  };
  if (!analytics) {
    setTimeout(sendAmplitudeEvent, 500, eventName, data, user);
  } else {
    if (user || user === null) {
      initializeAmplitudeForUser(user);
    }
    outstandingEvents += 1;

    analytics.track(
      eventName,
      data ?? {}, // Testing fix for integrations payload getting dropped on the way to segment. This function claims to take an undefined, but maybe that's not totally true.
      { integrations: { Amplitude: { session_id: getSessionId() } } },
      decrementOutstandingEvents
    );
  }
};

/**
 * @deprecated Use Segment Analytics directly instead
 */
export const sendAmplitudeEventData = (
  email: string | null,
  eventName: string,
  eventId: string,
  type?: string | null,
  message?: string | null,
  source?: string | null,
  metadata?: string | null
): void => {
  const properties: any = {};
  properties["Event ID"] = eventId;
  properties["Email ID"] = email;
  if (type) {
    properties["Error Type"] = type;
  }
  if (message) {
    properties["Error Message"] = message;
  }
  if (source) {
    properties.Source = source;
  }
  if (metadata) {
    properties.Metadata = metadata;
  }
  sendAmplitudeEvent(
    eventName,
    properties,
    email ? localStorage.getItem("currentUserId") : null
  );
};

/**
 * @deprecated Use Segment Analytics directly instead
 */
export const sendAmplitudeEventDataBySource = (
  userId: string | null,
  eventName: string,
  eventId: string,
  source?: string
): void => {
  sendAmplitudeEventData(userId, eventName, eventId, null, null, source);
};

/**
 * @deprecated Use Segment Analytics directly instead
 */
export const sendCMEAmplitudeEventData = (
  eventName: string,
  eventId: string,
  skipVendorSSO?: boolean | null,
  skipProfileValidation?: boolean | null,
  activityCode?: string | null,
  vendorHost?: string | null,
  problem?: string | null,
  referrerUrl?: string | null
): void => {
  const properties: any = {};
  properties["Event ID"] = eventId;
  if (typeof skipVendorSSO === "boolean") {
    properties.skipVendorSSO = skipVendorSSO;
  }
  if (typeof skipProfileValidation === "boolean") {
    properties.skipProfileValidation = skipProfileValidation;
  }
  if (vendorHost) {
    properties["Vendor Host"] = vendorHost;
  }
  if (activityCode) {
    properties["Activity Code"] = activityCode;
  }
  if (problem) {
    properties.Problem = problem;
  }
  if (referrerUrl) {
    properties["Referrer Host"] = referrerUrl;
  }

  sendAmplitudeEvent(
    eventName,
    properties,
    localStorage.getItem("currentUserId") ?? ""
  );
};

/**
 * @deprecated Use Segment Analytics directly instead
 */
export const sendLibraryAmplitudeData = (
  eventName: string,
  eventId: string,
  eventProperties: LibraryEventProperties
): void => {
  const properties = {
    ...eventProperties,
    "Event ID": eventId,
  };
  sendAmplitudeEvent(
    eventName,
    properties,
    localStorage.getItem("currentUserId") ?? ""
  );
};

export type UpdateableUserProperties = Partial<UserInformation>;
interface UserProperties extends OptimizelyFlags {
  "First Name"?: string;
  "Last Name"?: string;
  Occupation?: string;
  Specialty?: string;
  Subspecialty?: string;
  "Zip Code"?: string;
  email?: string;
  "Profile State"?: string;
  "Profile Country"?: string;
  "Last Login Date"?: string;
  "Account Type"?: ACCOUNT_TYPES;
  "Subscription Type"?: SUBSCRIPTION_TYPES;
  userId: string;
  timezone?: string;
}

export enum ACCOUNT_TYPES {
  EPOCRATES_ACCOUNT = "Epocrates",
  EPOCRATES_PLUS_ACCOUNT = "Epocrates Plus",
}

enum SUBSCRIPTION_TYPES {
  FREE_SUBSCRIPTION = "Free",
  PAID_SUBSCRIPTION = "Paid",
}

type optimizelyFlagKey = `optimizely@${string}`;
interface OptimizelyFlags {
  [key: optimizelyFlagKey]: string;
}

export function updateUserPropertiesForSegment(
  userId: string | null,
  userInfo: UpdateableUserProperties
): void {
  // Protect against NULL userIds.  This shouldn't happen but if it does we don't want to clear the existing userId session
  if (!userId) {
    return;
  }
  if (!analytics) {
    setTimeout(updateUserPropertiesForSegment, 500, userId, userInfo);
  } else {
    // getTimezoneOffset = UTC - time at timezone so we want to negate the answer on the hour portion
    const timeBehindUTC = new Date().getTimezoneOffset();
    const hoursOffset = timeBehindUTC / 60;
    const minuteOffset = timeBehindUTC % 60;
    const timeZoneString = `${hoursOffset > 0 ? "-" : "+"}${Math.abs(
      hoursOffset
    )
      .toString()
      .padStart(2, "0")}:${minuteOffset.toString().padStart(2, "0")}`;

    const userProfileAttributes: UserProperties = {
      "First Name": userInfo.firstName,
      "Last Name": userInfo.lastName,
      Occupation: userInfo.occupation?.text,
      Specialty: userInfo.specialty?.text ?? "No Specialty",
      Subspecialty: userInfo.subSpecialty?.text ?? "No subspecialty",
      "Zip Code": userInfo.addresses?.work?.zipcode,
      email: userInfo.email,
      "Profile State": userInfo.addresses?.work?.state,
      "Profile Country": userInfo.addresses?.work?.country,
      "Last Login Date": new Date().toISOString().split("T")[0], //format yyyy-mm-dd
      userId: userId,
      timezone: timeZoneString,
    };
    updateIdentity(userProfileAttributes);
  }
}
function updateIdentity(userInfo: UserProperties): void {
  if (currentAmplitudeUser !== userInfo.userId) {
    //initialize amplitude for userid if it isn't
    initializeAmplitudeForUser(userInfo.userId);
  }
  analytics?.identify(currentAmplitudeUser, userInfo, {
    integrations: { Amplitude: { session_id: getSessionId() } },
  });
}

export function updateAccountTypeForSegment(
  userId: string,
  accountType: ACCOUNT_TYPES
): void {
  updateIdentity({ userId, "Account Type": accountType });
}

export function updateOptimizelyFlagsForSegment(
  userId: string,
  optimizelyFlags: { [key: string]: OptimizelyDecision } | undefined
) {
  const decisions = Object.entries(
    optimizelyFlags ?? {}
  ).reduce<OptimizelyFlags>((acc, [flag, decision]) => {
    acc[`optimizely@${flag}`] = decision.variationKey ?? "Unknown";
    return acc;
  }, {});
  updateIdentity({ userId, ...decisions });
}

export const getToolsAndLocation = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const tools = queryParams.get("tools") ?? null;
  let location = queryParams.get("location") ?? null;
  if (location === null) {
    location =
      document.referrer?.indexOf("epocrates.com") >= 0 ? "ECOMM" : null;
  }
  return { tools, location };
};

export const getMarketingSource = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("mktgsrc") ?? null;
};

export const getLoginErrorType = (code: string | number) => {
  try {
    if (code === "userDeactivated") return "user is deactivated";
    if (code === "incorrectOtc") return "OTC is incorrect";
    if (code === "otcExpired") return "OTC is expired";
    if (code === "attemptsExhausted") return "login attempts are exhausted";
    if (Number(code) === 401) return "Unauthorized";
    if (Number(code) === 403) return "Device registration failed";
    if (Number(code) >= 500) return "Device registration failed";
  } catch (e) {
    return "internal server code error";
  }
};
