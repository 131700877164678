import { ListItem, ListItemText } from "@mui/material";
import MaterialButton from "@material-ui/core/Button";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Theme, makeStyles } from "@material-ui/core";
import { Device } from "../models/Device";
import { DeviceStatus } from "./Devices";
import clsx from "clsx";
import signedInIcon from "../images/signed-in.svg";
import signedOutIcon from "../images/signed-out.svg";
import { colors } from "src/config/colorConfig";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SignOutModal } from "./SignOutModal";
import { strings } from "src/utils/Strings";
import { useMutation } from "@tanstack/react-query";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";
import { updateDevicesList } from "src/services/deviceService";
import { sendSuccessSnackBarWithLinkMessage } from "src/actions/snackBarActions";
import { SnackBarType } from "src/utils/SnackBarType";
import {
  DEVICE_SIGNOUT_CANCEL,
  PROFILE_SIGN_OUT_CONFIRMATION_CLICK,
} from "src/utils/analytics";
import { sendAmplitudeEvent } from "src/utils/AmplitudeUtil";

interface Props extends Device {
  signOutDevice: (deviceId: string, deviceName: string) => void;
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const formatDate = (time: string) => {
  const zone_name = dayjs.tz.guess();
  return dayjs(time).tz(zone_name).format("MM/DD/YY, HH:mm A z");
};

export const DeviceListItem = (props: Props) => {
  const {
    deviceFamily: name,
    deviceStatus,
    lastAccessedAt,
    signOutDevice,
  } = props;
  const isSignedIn = deviceStatus === DeviceStatus.active;
  const deviceName = props.platform === "Web" ? name.split(" ").at(0) : name;
  const time = lastAccessedAt;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const onDeviceSignoutCancel = () => {
    const properties = {
      "Section Name": props.platform,
    };
    sendAmplitudeEvent(DEVICE_SIGNOUT_CANCEL, properties, null);
  };

  const onDeviceSignoutConfirmationClick = () => {
    const properties = {
      "Section Name": props.platform,
      Browser: deviceName,
      Location: "Devices",
    };
    sendAmplitudeEvent(PROFILE_SIGN_OUT_CONFIRMATION_CLICK, properties, null);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    onDeviceSignoutCancel();
  };
  const updateDevicesMutation = useMutation(updateDevicesList, {
    onSuccess: (__) => {
      signOutDevice(props.deviceId, deviceName ?? "");
      setOpen(false);
    },
    onError: (__) => {
      const category = SnackBarType.ERROR.displayValue;
      const title = strings.error_title;
      dispatch(sendSuccessSnackBarWithLinkMessage(title, category));
      setOpen(false);
    },
  });

  const deviceListItemStyles = makeStyles((theme: Theme) => ({
    listItem: {
      padding: `${theme.spacing(2)}px 2px !important`,
      borderColor: colors["--ui-grey-light"] + " !important",
      "& .MuiListItemSecondaryAction-root": {
        right: theme.spacing(1),
        top: "auto",
      },
    },
    listItemText: {
      "& .MuiTypography-root": {
        fontFamily: "Metric",
        color: colors["--ui-slate"],
      },
      "& .MuiListItemText-primary": {
        fontWeight: 500,
      },
    },
    statusIcon: {
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
    signOutBtn: {
      fontFamily: "Metric",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.25,
      color: colors["--link-blue"],
      textTransform: "none",
      padding: 0,
      "&:focus": {
        outline: 0,
        border: 0,
      },
      "&:hover": {
        background: "none",
      },
    },
    btnLabel: {
      justifyContent: "flex-end",
    },
    divider: {
      [`${theme.breakpoints.down("xs")}`]: {
        "&:last-child": {
          border: "none",
        },
      },
    },
  }));

  const classes = deviceListItemStyles();

  const handleSignOut = () => {
    onDeviceSignoutConfirmationClick();
    const userId = Number(localStorage.getItem(CURRENT_USER_ID) ?? -1);
    const devicelist = [
      {
        deviceId: props.deviceId,
        platform: props.platform,
        deviceFamily: props.deviceFamily,
        deviceStatus: DeviceStatus.pendingManualSignout,
        lastAccessedAt: props.lastAccessedAt,
        loggedOutAt: props.loggedOutAt,
      },
    ];
    updateDevicesMutation.mutate({ userId, devices: devicelist });
  };

  return (
    <>
      <ListItem
        secondaryAction={
          isSignedIn && (
            <MaterialButton
              onClick={() => handleOpen()}
              classes={{
                root: clsx(classes.signOutBtn),
                label: clsx(classes.btnLabel),
              }}
              disableRipple
            >
              Sign Out
            </MaterialButton>
          )
        }
        divider={true}
        classes={{
          root: clsx(classes.listItem),
          divider: clsx(classes.divider),
        }}
        data-testid={isSignedIn ? "signed-in-device" : "signed-out-device"}
      >
        <ListItemText
          primary={deviceName}
          secondary={
            <>
              <img
                className={clsx(classes.statusIcon)}
                src={isSignedIn ? signedInIcon : signedOutIcon}
                alt=""
              />
              {`${isSignedIn ? "Accessed" : "Signed out"} ${
                time && formatDate(time)
              }`}
            </>
          }
          className={clsx(classes.listItemText)}
        />
      </ListItem>
      <SignOutModal
        open={open}
        handleClose={handleClose}
        handleSignAllOutClick={handleSignOut}
        title={strings.sign_out_device_confirmation}
        description={strings.sign_in_device_intimation}
      />
    </>
  );
};
