import {
  Avatar,
  Box,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import {
  useEffect,
  ReactElement,
  useState,
  Dispatch,
  MouseEvent,
  useRef,
} from "react";
import { TextField } from "src/components/custom/TextField";
import { Button } from "src/components/custom/Button";
import { licenseTheme } from "src/styles/licenseTheme";
import { colors } from "src/config/colorConfig";
import emailValidation from "email-validator";
import { strings } from "src/utils/Strings";
import clsx from "clsx";
import { VerifyEmailQuery } from "src/queries/VerifyEmailQuery";
import { LicenseFormAction } from "src/container/LicenseCodeForm";
import {
  getToolsAndLocation,
  sendAmplitudeEvent,
} from "src/utils/AmplitudeUtil";
import {
  CLICK_SIGN_IN,
  CLICK_SIGN_IN_EVENT_ID,
  GroupLicenseSegmentPayload,
  GROUP_LICENSING_ORDER_INFORMATION_CLICK,
} from "src/utils/analytics";
import { ToolsLocationForEvents } from "src/models/ToolsLocationForEvents";
interface LicenseCodeOrderInformationFormProps {
  emailAddress: string;
  fullName?: string;
  companyName: string;
  dispatch: Dispatch<LicenseFormAction>;
  profileEmail: string;
  isQuantityBeyondMaximum: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    alignSelf: "stretch",
    padding: "0 24px 24px 24px",
  },
  form: {
    width: "100%",
  },
  required: {
    color: "var(--primary-orange, #DD5546)",
  },
  profileAndNote: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: "100%",
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    width: "100%",
  },
  profileLabelAndContent: {
    gap: "8px",
    width: "100%",
  },
  infoIcon: {
    width: "14px",
    height: "14px",
    top: -2,
  },
  avatarCircleBgColor: {
    marginLeft: 4,
    backgroundColor: "var(--primary-tealnight, #0178A4)",
  },
  avatarCircleColor: {
    marginRight: 4,
    backgroundColor: colors["--white"],
    border: `solid 2px ${colors["--ui-gray"]}`,
  },
  avatarCircle: {
    width: 14,
    height: 14,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  informationBox: {
    marginTop: -12,
  },
  informationI: {
    fontSize: 12,
    color: "white",
  },
  informationI2: {
    fontSize: 12,
    color: colors["--ui-gray"],
  },
  tooltipContent: {
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 24,
    color: "var(--primary-midnight, #06162D)",
    width: 203,
    height: 48,
  },
  textFieldContainer: {
    width: "488px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
}));

export const LicenseCodeOrderInformationForm = (
  props: LicenseCodeOrderInformationFormProps
): ReactElement => {
  const classes = useStyles(licenseTheme);
  const { dispatch, profileEmail } = props;
  const [companyName, setCompanyName] = useState(props.companyName);
  const [isDifferentEmail, setIsDifferentEmail] = useState(false);
  const [enableButton, setEnableButton] = useState(true);
  const [validateOnChange, setValidateOnChange] = useState(true);
  const [email, setEmail] = useState(props.profileEmail ?? "");
  const [displayText, setDisplayText] = useState("");
  const [buttonDisplayText, setButtonDisplayText] = useState("Continue");
  const [contactName, setContactName] = useState(props.fullName ?? "");

  const toolsLocation = useRef<ToolsLocationForEvents>({
    tools: null,
    location: null,
  });
  const validateUserEditedEmail = (email: string, eventType?: string) => {
    if (eventType === "change") {
      setValidateOnChange(true);
    }
    if (email.length > 0 && (validateOnChange || eventType === "change")) {
      if (!emailValidation.validate(email)) {
        setEnableButton(false);
        return strings.email_format_invalid;
      } else {
        setEnableButton(true);
        setEmail(email);
      }
      if (props.profileEmail !== email) {
        setIsDifferentEmail(true);
      } else {
        setIsDifferentEmail(false);
      }
    }
  };
  const validateCompanyName = (name: string, eventType?: string) => {
    if (eventType === "change") {
      setValidateOnChange(true);
    }
    if (name.length > 0 && (validateOnChange || eventType === "change")) {
      if (!/^[ A-Za-z\s,./&-]*$/.test(name)) {
        setEnableButton(false);
        return strings.name_format_invalid;
      } else {
        setEnableButton(true);
        setCompanyName(name.trim());
      }
    }
  };
  // React query to verify email
  const emailQueryResponse = VerifyEmailQuery(
    !props.isQuantityBeyondMaximum ? email : ""
  );

  useEffect(() => {
    if (emailQueryResponse.isFetched) {
      if (emailQueryResponse.data?.data?.valid) {
        setDisplayText(strings.guest_account_information);
        setButtonDisplayText("Continue");
      } else {
        setDisplayText(strings.existing_account_not_logged_in);
        if (props.profileEmail !== email) {
          setButtonDisplayText(
            strings.existing_account_not_logged_in_button_text
          );
        }
      }
    }
  }, [emailQueryResponse]);
  useEffect(() => {
    const toolsLocationObj = getToolsAndLocation();
    toolsLocation.current.tools = toolsLocationObj?.tools;
    toolsLocation.current.location = toolsLocationObj?.location;
  }, []);
  function submitOrderInformation(event: MouseEvent<HTMLFormElement>) {
    event.preventDefault();
    const segmentPayload: GroupLicenseSegmentPayload = {
      "Institution Name": companyName,
    };
    sendAmplitudeEvent(GROUP_LICENSING_ORDER_INFORMATION_CLICK, segmentPayload);
    let loginScreenOption = false;
    if (email && email !== profileEmail) {
      loginScreenOption = !emailQueryResponse.data?.data?.valid;
      const eventProperties = {
        "Event ID": CLICK_SIGN_IN_EVENT_ID,
        Source: "Group Licensing",
        Tools: toolsLocation.current.tools,
        Location: toolsLocation.current.location,
        "Current URL": window.location.href,
        isWebsocketEnabled: false,
      };
      sendAmplitudeEvent(CLICK_SIGN_IN, eventProperties, null);
    }

    if (email && companyName) {
      if (contactName) {
        dispatch({
          type: "continue",
          institution: companyName,
          needAuthentication: loginScreenOption,
          newEmail: email,
          contactName,
        });
      } else {
        dispatch({
          type: "continue",
          institution: companyName,
          needAuthentication: loginScreenOption,
          newEmail: email,
        });
      }
    }
  }

  return (
    <Box className={classes.container}>
      <form onSubmit={submitOrderInformation} className={classes.form}>
        <Typography variant="subtitle1">
          <span className={classes.required}>* </span>Company name
        </Typography>
        <Box className={classes.textFieldContainer}>
          <TextField
            name="companyName"
            required={true}
            label="Enter company name"
            isNewDesign={true}
            testId="companyName"
            value={companyName}
            variant="outlined"
            validation={validateCompanyName}
          />
        </Box>
        <Box className={classes.profileAndNote}>
          <Box className={classes.profile}>
            <Box className={classes.profileLabelAndContent}>
              {props.isQuantityBeyondMaximum && (
                <>
                  <Typography variant="subtitle1">
                    <span className={classes.required}>* </span>Contact name
                  </Typography>
                  <Box className={classes.textFieldContainer}>
                    <TextField
                      name="contactName"
                      required={true}
                      label="Enter contact name"
                      isNewDesign={true}
                      testId="contactName"
                      value={props.fullName}
                      variant="outlined"
                      onChange={(val: string) => setContactName(val.trim())}
                    />
                  </Box>
                </>
              )}
              <div className={classes.profileAndNote}>
                <Typography variant="subtitle1">
                  <span className={classes.required}>* </span>Email
                </Typography>
                {!props.isQuantityBeyondMaximum && (
                  <Tooltip
                    title={
                      <Box className={clsx(classes.tooltipContent)}>
                        <Typography>{strings.email_tooltip}</Typography>
                      </Box>
                    }
                    placement="right"
                    arrow
                  >
                    <Avatar
                      className={clsx(
                        classes.avatarCircle,
                        classes.avatarCircleBgColor
                      )}
                    >
                      <Typography
                        variant="subtitle1"
                        className={classes.informationI}
                      >
                        i
                      </Typography>
                    </Avatar>
                  </Tooltip>
                )}
              </div>
              <Box className={classes.textFieldContainer}>
                <TextField
                  name="email"
                  testId="updatedEmail"
                  required={true}
                  label="Enter email"
                  isNewDesign={true}
                  value={props.emailAddress}
                  validation={validateUserEditedEmail}
                  variant="outlined"
                  greyInputUntilActive={true}
                />
              </Box>
              {!props.isQuantityBeyondMaximum &&
                isDifferentEmail &&
                enableButton && (
                  <Box className={classes.informationBox}>
                    <Typography>
                      <Avatar
                        className={clsx(
                          classes.avatarCircle,
                          classes.avatarCircleColor
                        )}
                      >
                        <Typography
                          variant="subtitle1"
                          className={classes.informationI2}
                        >
                          i
                        </Typography>
                      </Avatar>
                      {displayText}
                    </Typography>
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
        <Button
          name={buttonDisplayText}
          id="orderContinue"
          testId="orderContinue"
          type="submit"
          backgroundColor={colors["--primary-tealnight"]}
          hoverForeground={colors["--primary-tealnight"]}
          disabled={
            companyName === "" ||
            (props.isQuantityBeyondMaximum && contactName === "") ||
            !enableButton
          }
          width={264}
          margins="24px 0 0 0"
        />
      </form>
    </Box>
  );
};
