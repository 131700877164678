import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "./custom/TextField";
import { DropDownMenu } from "./custom/DropDownMenu";
import { Button } from "./custom/Button";
import { strings } from "../utils/Strings";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import { subSpecialtiesAction } from "../actions/demographicsActions";
import {
  CREATE_ACCOUNT_NPI_NUMBER_CLICK,
  CREATE_ACCOUNT_NPI_NUMBER_CLICK_EVENT_ID,
  CREATE_ACCOUNT_SPECIALTY_VIEW_EVENT_ID,
  CREATE_ACCOUNT_SPECIALTY_VIEW,
} from "../utils/analytics";
import { httpConstants } from "../utils/httpConstants";
import { DemographicsResponse } from "../models/DemographicsResponse";
import { IdTextTuple } from "../models/IdTextTuple";
import { colors } from "src/config/colorConfig";

interface AccountCreation {
  accountCreation: AccountCreationState;
}

interface AccountCreationState {
  specialtiesResponse: DemographicsResponse;
  subSpecialtiesResponse: DemographicsResponse;
  subSpecialties_progress: boolean;
}

const isSubspecialtiesVisible = (subSpecialties: DemographicsResponse) =>
  subSpecialties?.data.length > 0 &&
  (subSpecialties?.data.length != 1 ||
    subSpecialties?.data[0].text !== strings.no_subspecialty);

export const NpiEnter = (props: any) => {
  const dispatch = useDispatch();
  const state = useSelector((state: AccountCreation) => state.accountCreation);

  const occupationId = props.occupation;

  const [npiValue, setNpiValue] = React.useState(props?.profileDetail?.number);
  const [specialty, setSpecialty] = React.useState({
    id: props?.profileDetail?.specialtyId,
    text: props?.profileDetail?.specialty,
  });
  const [subSpecialty, setSubSpecialty] = React.useState({
    id: null,
    text: "",
  });
  const [specialties, setSpecialties] = React.useState({ data: [] });
  const [subSpecialties, setSubSpecialties] =
    React.useState<DemographicsResponse>({
      data: [] as IdTextTuple[],
    } as DemographicsResponse);
  const [specialtyError, setSpecialtyError] = React.useState({
    value: "",
  });
  const [validation, setValidation] = React.useState({
    errorMessage: "",
    submitEnable: true,
  });

  const npiClickHandler = (e: any) => {
    sendAmplitudeEvent(CREATE_ACCOUNT_NPI_NUMBER_CLICK, {
      "Event ID": CREATE_ACCOUNT_NPI_NUMBER_CLICK_EVENT_ID,
    });
  };

  const validateNPIInput = (inputData: any) => {
    if (
      inputData &&
      (String(inputData).length !== 10 || Number.isNaN(inputData))
    ) {
      setValidation({ ...validation, submitEnable: false });
      return strings.invalid_npi;
    }
    setNpiValue(inputData);
    setValidation({ ...validation, submitEnable: true });
    return null;
  };

  const validateSpecialtyInput = (value: any) => {
    if (value === "") {
      setSpecialty({ id: null, text: "" });
    } else {
      const specialtyObj: any = specialties?.data.find(
        (item: any) => item.id === value
      );
      setSpecialty({ id: value, text: specialtyObj?.text || "" });
      setSubSpecialty({ id: null, text: "" });
    }
    return false;
  };

  const validateSubSpecialtyInput = (subSpecialty: any) => {
    const subSpecialtyObj: any = subSpecialties?.data.find(
      (item: any) => item.id === subSpecialty
    );
    setSubSpecialty({ id: subSpecialty, text: subSpecialtyObj?.text || "" });
    return false;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (props?.errorState) {
      if (
        props.errorValues?.errorStatus !== httpConstants.INTERNAL_SERVER_ERROR
      ) {
        props.errorState({
          counter: 0,
          errorMessages: [],
          errorStatus: 0,
        });
      } else {
        props.errorState({
          counter: props.errorValues?.counter,
          errorMessages: [],
          errorStatus: 0,
        });
      }
    }
    if (specialty.id === "") {
      setSpecialtyError({
        ...specialtyError,
        value: "Speciality is not set",
      });
    } else {
      props.createAccountClickHandler({ specialty, subSpecialty, npiValue });
    }
  };

  React.useEffect(() => {
    sendAmplitudeEvent(CREATE_ACCOUNT_SPECIALTY_VIEW, {
      "Event ID": CREATE_ACCOUNT_SPECIALTY_VIEW_EVENT_ID,
    });
    setSpecialties(props.specialties);
  }, []);

  React.useEffect(() => {
    if (
      state &&
      state.subSpecialtiesResponse &&
      state.subSpecialtiesResponse.status === 200 &&
      !state.subSpecialtiesResponse.done
    ) {
      setSubSpecialties(state.subSpecialtiesResponse);
      state.subSpecialtiesResponse.done = true;
    }
  }, [state.subSpecialtiesResponse]);

  React.useEffect(() => {
    if (specialty.id) {
      dispatch(
        subSpecialtiesAction({ occupationId, specialtyId: specialty.id })
      );
    }
  }, [specialty.id]);
  return (
    <form data-testid="form" onSubmit={handleSubmit}>
      {props.showNpi && (
        <TextField
          name="npi"
          testId="npi"
          label="NPI (Optional)"
          helpTestId="npiHelp"
          onClickField={npiClickHandler}
          validation={validateNPIInput}
          value={npiValue || ""}
          isRequired="false"
        />
      )}
      {specialties?.data.length > 0 && (
        <DropDownMenu
          id="specialty"
          name="Specialty"
          testId="specialty"
          helpTestId="specialtyHelp"
          validation={validateSpecialtyInput}
          list={specialties}
          value={specialty.id ? specialty.id : ""}
          isRequired="true"
          noBottomMargin
          isRequiredField
          isNewDesign={true}
        />
      )}
      {isSubspecialtiesVisible(subSpecialties) && (
        <DropDownMenu
          id="subSpecialty"
          name="Subspecialty"
          testId="subSpecialty"
          helpTestId="subSpecialtyHelp"
          validation={validateSubSpecialtyInput}
          value={subSpecialty.id}
          list={subSpecialties}
          isRequired="false"
          noBottomMargin
        />
      )}
      <Button
        name={strings.create_account}
        id={"create-account"}
        backgroundColor={colors["--primary-tealnight"]}
        height={48}
        fontFamily={"Source Sans Pro"}
        fontSize={18}
        hoverForeground={colors["--primary-tealnight"]}
        type="submit"
        data-testid="create-account"
        inProgress={state.subSpecialties_progress || props.inProgress}
      />
    </form>
  );
};
