import {
  AUTH_CODE_REDEEM_USER,
  REQUIRE_USER_MANUAL_LOGIN,
  AUTH_CODE_REDEEM_USER_SUCCESS,
  AUTH_CODE_REDEEM_USER_ERROR,
  AUTH_CODE_REDEEM_USER_INPROGRESS,
  LOGIN_USER_SUCCESS,
  AUTH_CODE_REDEEM_SYSTEM_ERROR,
} from "../actions";

export function privateCmeRouter(state = {}, action: any): any {
  const { type } = action;
  switch (type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        tryEmailPasswordPrompt: false,
        completedAuthorizationCodeRedemptionAttemptFailed: false,
      };
    case REQUIRE_USER_MANUAL_LOGIN:
      return {
        ...state,
        completedAuthorizationCodeRedemptionAttempt: true,
        tryEmailPasswordPrompt: true,
      };
    case AUTH_CODE_REDEEM_USER:
      return {
        ...state,
        attemptingAuthorizationCodeRedemption: true,
        completedAuthorizationCodeRedemptionAttempt: false,
        completedAuthorizationCodeRedemptionAttemptFailed: false,
      };
    case AUTH_CODE_REDEEM_USER_SUCCESS:
      return {
        ...state,
        attemptingAuthorizationCodeRedemption: false,
        completedAuthorizationCodeRedemptionAttempt: true,
      };
    case AUTH_CODE_REDEEM_USER_ERROR:
      return {
        ...state,
        attemptingAuthorizationCodeRedemption: false,
        completedAuthorizationCodeRedemptionAttempt: true,
        completedAuthorizationCodeRedemptionAttemptFailed: true,
      };
    case AUTH_CODE_REDEEM_SYSTEM_ERROR:
      return {
        ...state,
        attemptingAuthorizationCodeRedemption: false,
        completedAuthorizationCodeRedemptionAttempt: true,
        completedAuthorizationCodeRedemptionAttemptFailed: true,
      };
    case AUTH_CODE_REDEEM_USER_INPROGRESS:
      return {
        ...state,

        attemptingAuthorizationCodeRedemption: true,
        completedAuthorizationCodeRedemptionAttempt: false,
      };
    default:
      return state;
  }
}
