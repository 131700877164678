import {
  makeStyles,
  Typography,
  Link,
  Box,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { APIPlatform, ITransaction } from "src/models/ITransaction";
import { colors } from "../config/colorConfig";
import dayjs from "dayjs";
import pdfDownload from "../images/file-download.png";
import { CardNumber } from "./custom/CardNumber";
import { TRANSACTION } from "src/utils/localStorageKeys";
import { TransactionTypes } from "src/queries/TransactionTypesQuery";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  useMediaQuery,
  useTheme,
} from "@mui/material";
interface TransactionHistoryProps {
  userId: string;
  transactionHistoryResponse: ITransaction[] | undefined;
  transactionHistoryUpdatedAt: number;
}
const transactionHistoryStyle = makeStyles((theme) => ({
  transactionWrapper: {
    margin: `${theme.spacing(1)}px`,
  },
  progressDiv: {
    height: 300,
  },
  tableWrapper: {
    width: "100%",
  },

  Title: {
    margin: `${theme.spacing(8)}px ${theme.spacing(0)}px ${theme.spacing(
      4
    )}px ${theme.spacing(0)}px`,
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    align: "left",
    color: `${colors["--primary-midnight"]}`,
  },
  mobileAppStoreLink: {
    fontSize: 16,
    fontWeight: 500,
    color: colors["--primary-teal"],
    "&:hover": {
      color: colors["--primary-teal"],
      textDecoration: "none",
    },
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  },
  backdropClass: {
    position: "absolute",
    zIndex: 100,
  },
  progress: {
    width: 20,
    height: 20,
    color: colors["--accent-violet"],
    position: "relative",
    top: "25%",
    left: "50%",
  },
  totalContainer: {
    display: "inline-flex",
  },
  totalItem: {
    marginLeft: 12,
  },
  pdfImage: {
    width: 24,
    height: 24,
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  pdfPage: {
    height: 800,
    width: 800,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  iapHelpText: {
    fontWeight: 600,
    fontSize: 16,
    color: colors["--ui-gray"],
  },
  tableContainer: {
    position: "relative",
    borderTop: `solid 1px ${colors["--ui-grey-light"]}`,
    borderBottom: `solid 1px ${colors["--ui-grey-light"]}`,
  },
  rowSeparator: {
    position: "relative",
    borderTop: `solid 1px ${colors["--ui-grey-light"]}`,
  },
  tableDataFont: {
    fontWeight: 400,
    fontFamily: "Metric",
    fontSize: 16,
    color: `${colors["--primary-midnight"]} !important`,
  },
  mobileContentAlign: {
    textAlign: "center",
  },
  headerLabel: {
    fontWeight: 500,
    fontFamily: "Metric",
    fontSize: 16,
    color: `${colors["--ui-gray"]} !important`,
  },
}));

export const TransactionHistory = (
  props: TransactionHistoryProps
): JSX.Element => {
  const StyledTableCell = withStyles({
    root: {
      borderBottom: "none !important",
      padding: "10px !important",
    },
  })(TableCell);

  const StyledTableRow = withStyles({
    root: {
      borderBottom: "none",
    },
  })(TableRow);
  const classes = transactionHistoryStyle();
  const [webTransactionHistory, setWebTransactionHistory] =
    useState<ITransaction[]>();
  const [isIAPTransaction, setIAPTransaction] = useState<boolean>(false);
  const transactionTypesQuery = TransactionTypes(Number(props.userId));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const displayAppLink = (appStoreLink: string, displayText: string) => {
    return (
      <Link
        target="_blank"
        data-testid="appStoreLink"
        className={clsx(classes.mobileAppStoreLink)}
        href={appStoreLink}
      >
        {displayText}
      </Link>
    );
  };
  const downloadLink = (transactionNo: string): string => {
    return `/account/receipt?id=${transactionNo}`;
  };
  const openReceipt = (transaction: ITransaction) => {
    const transactionObj = localStorage.getItem(TRANSACTION);
    if (transactionObj) {
      const storedTransactions: ITransaction[] = JSON.parse(transactionObj);
      storedTransactions?.length > 0
        ? storedTransactions.push(transaction)
        : new Array(transaction);
      localStorage.setItem(TRANSACTION, JSON.stringify(storedTransactions));
    } else {
      localStorage.setItem(TRANSACTION, JSON.stringify(new Array(transaction)));
    }
  };

  const getTotal = (transaction: ITransaction) => {
    return (
      <Box className={clsx(classes.totalContainer)}>
        <div>
          {transaction.platform === APIPlatform.web
            ? transaction.payment?.total ?? "0.00"
            : "--"}
        </div>
        {transaction.platform === APIPlatform.web &&
          transaction.payment?.total && (
            <div className={clsx(classes.totalItem)}>
              <a
                data-testid="transactionReceipt"
                href={downloadLink(transaction.orderNumber)}
                target="_blank"
                onClick={() => openReceipt(transaction)}
                rel="noreferrer"
              >
                <img
                  data-testid="pdfLogo"
                  className={clsx(classes.pdfImage)}
                  src={pdfDownload}
                  alt="download transaction receipt"
                />
              </a>
            </div>
          )}
      </Box>
    );
  };
  const headerColumn = [
    { id: "date", label: "Date", width: "20%" },
    { id: "productName", label: "Plan", width: "30%" },
    { id: "paymentMethod.cardNumber", label: "Payment Method", width: "30%" },
    { id: "payment.total", label: "Total", width: "20%" },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (
      transactionTypesQuery?.data &&
      (transactionTypesQuery.data.includes(APIPlatform.android) ||
        transactionTypesQuery.data.includes(APIPlatform.iOS))
    ) {
      setIAPTransaction(true);
    }
  }, [transactionTypesQuery.isFetched]);

  useEffect(() => {
    if (props.transactionHistoryResponse) {
      //Filtering out the web transacations
      const filteredTrasacations: ITransaction[] | undefined =
        props.transactionHistoryResponse.filter(
          (transaction: ITransaction) => transaction.platform === "web"
        );
      if (
        filteredTrasacations &&
        filteredTrasacations.length < props.transactionHistoryResponse.length
      ) {
        setIAPTransaction(true);
      }
      setWebTransactionHistory(filteredTrasacations);
    }
  }, [props.transactionHistoryUpdatedAt]);
  const formatDisplayValue = (
    headerColumn: string,
    transaction: ITransaction
  ) => {
    switch (headerColumn) {
      case "Date":
        return dayjs(new Date(transaction.date)).format("MM/DD/YYYY");
      case "Plan":
        if (
          transaction.payment?.quantity &&
          parseInt(transaction.payment.quantity) > 1
        ) {
          return `${transaction.productName} (${transaction.payment.quantity} Subs)`;
        } else {
          return transaction.productName;
        }
      case "Payment Method":
        if (transaction.paymentMethod?.cardNumber) {
          return (
            <CardNumber
              cardNumber={`XXXXXXXXXXXX ${transaction.paymentMethod.cardNumber}`}
            />
          );
        } else {
          return "--";
        }
      case "Total":
        return getTotal(transaction);
      default:
        return "";
    }
  };
  return (
    <div className={clsx(classes.transactionWrapper)}>
      {webTransactionHistory && webTransactionHistory.length > 0 && (
        <div>
          <div>
            <Typography
              id="alert-dialog-subTitle1"
              data-testid="transactionTitle"
              className={clsx(classes.Title)}
            >
              Transaction History
            </Typography>
          </div>
          <div
            data-testid="transactionDataTable"
            className={clsx(classes.tableWrapper)}
          >
            <TableContainer
              className={!isMobile ? clsx(classes.tableContainer) : ""}
            >
              <Table>
                {!isMobile && (
                  <TableHead>
                    <StyledTableRow data-testid="headerRow">
                      {headerColumn.map((column) => (
                        <StyledTableCell
                          className={clsx(classes.headerLabel)}
                          key={column.id}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                )}
                <TableBody>
                  {(rowsPerPage > 0
                    ? webTransactionHistory.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : []
                  ).map((row: ITransaction) => (
                    <StyledTableRow
                      data-testid="dataRow"
                      className={isMobile ? clsx(classes.rowSeparator) : ""}
                      key={row.orderNumber}
                    >
                      {headerColumn.map((column) =>
                        isMobile ? (
                          <StyledTableCell style={{ display: "block" }}>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                className={clsx(classes.headerLabel)}
                                style={{ width: "50%" }}
                              >
                                {column.label}{" "}
                              </div>
                              <div
                                style={{ width: "50%", alignItems: "center" }}
                              >
                                <Typography
                                  className={clsx(
                                    classes.tableDataFont,
                                    classes.mobileContentAlign
                                  )}
                                >
                                  {formatDisplayValue(column.label, row)}
                                </Typography>
                              </div>
                            </div>
                          </StyledTableCell>
                        ) : (
                          // Desktop view
                          <StyledTableCell
                            className={clsx(classes.tableDataFont)}
                            style={{ width: column.width }}
                          >
                            {formatDisplayValue(column.label, row)}
                          </StyledTableCell>
                        )
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{
                "& .MuiTablePagination-selectLabel": {
                  paddingTop: "16px",
                  lineHeight: "20px",
                  fontSize: 16,
                  fontFamily: "Metric",
                },
                "& .MuiTablePagination-displayedRows": {
                  paddingTop: "16px",
                },

                boxShadow: 0,
                border: 0,
              }}
              rowsPerPageOptions={[4, 8, 12]}
              component="div"
              count={webTransactionHistory?.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      )}

      {isIAPTransaction && (
        <div>
          <Typography
            id="iap-transations"
            data-testid="iapTransations"
            className={clsx(classes.iapHelpText)}
          >
            Did you subscribe to epocrates+ with our app?
          </Typography>
          <Typography>
            Manage your subscription and view transaction history in the{" "}
            {displayAppLink(
              "https://support.apple.com/en-us/HT204088",
              "Apple App Store"
            )}{" "}
            or{" "}
            {displayAppLink(
              "https://play.google.com/store/account/subscriptions",
              "Google Play Store"
            )}
          </Typography>
        </div>
      )}
    </div>
  );
};
