import { link } from "fs";
import {
  SET_SNACKBAR_MESSAGE,
  CLOSE_SNACKBAR,
  SUCCESS_SNACKBAR_MESSAGE,
} from "./index";

export const sendSnackBarMessage = (
  title: string,
  message: string,
  messageType: string
) => ({
  type: SET_SNACKBAR_MESSAGE,
  title,
  message,
  messageType,
});

export const closeSnackBar = () => ({
  type: CLOSE_SNACKBAR,
});

export const sendSuccessSnackBarMessage = (
  title: string,
  message: string,
  messageType: string
) => ({
  type: SUCCESS_SNACKBAR_MESSAGE,
  title,
  message,
  link: false,
  messageType,
});
export const sendSuccessSnackBarWithLinkMessage = (
  title: string,
  messageType: string
) => ({
  type: SET_SNACKBAR_MESSAGE,
  title,
  link: true,
  messageType,
});
export const sendSuccessSnackBarWithTitleMessage = (
  title: string,
  message: string,
  link: boolean,
  messageType: string
) => ({
  type: SET_SNACKBAR_MESSAGE,
  title,
  message,
  link: link,
  messageType,
});
