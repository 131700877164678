import jwt_decode, { JwtPayload } from "jwt-decode";
import { getWebUserAgent } from "@athena/epoc-frontend-library/dist";
import {
  CURRENT_USER_ID,
  STAY_LOGGED_IN,
  CURRENT_USER_NAME,
  SESSION_BANNER,
  TRANSACTION,
  CUSTOMER_NAME,
  IS_NOTIFICATION_SYNCED,
} from "./localStorageKeys";
import { VerifyRequest } from "../models/VerifyRequest";
import { verificationService } from "../services/authenticationService";
import { getDevicesList } from "../services/deviceService";
import {
  sendSuccessSnackBarMessage,
  sendSuccessSnackBarWithLinkMessage,
} from "../actions/snackBarActions";
import { SnackBarType } from "../utils/SnackBarType";
import { strings } from "./Strings";
import { store } from "src/store/configureStore";
import { PROFILE_SIGN_OUT_CONFIRMED } from "./analytics";
import { sendAmplitudeEvent } from "./AmplitudeUtil";
import { getLogOutUserFunction } from "../utils/loginUtil";

const invalidTokenError = "Invalid token";
// ToDo: For the session cookie we need to get back on the time duration to clear the cookie.
const refreshTokenValidity = 60;
const maxActiveDeviceLimit = 4;

export function generateProfileSignoutConfirmedAmplitude(reason: string) {
  const properties = {
    "Epoc Device ID": getCookie("ebi"),
    Browser: getWebUserAgent().split(" ").at(0),
    "Reason for Signout": reason,
    Location: "Devices",
  };
  sendAmplitudeEvent(PROFILE_SIGN_OUT_CONFIRMED, properties, null);
}

export function setCookie(cname: string, hours: number, cvalue?: string) {
  const d = new Date();
  d.setTime(d.getTime() + refreshTokenValidity * hours * 60 * 60 * 1000); // (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  if (window !== undefined) {
    const host = window.location.hostname;
    if (host?.includes(strings.epocrates_domain)) {
      const domain = `domain=${strings.epocrates_domain}`;
      if (localStorage.getItem(STAY_LOGGED_IN) === "true" || hours === -1) {
        document.cookie = `${cname}=${cvalue};${expires};path=/;${domain}`;
      } else {
        document.cookie = `${cname}=${cvalue};path=/;${domain}`;
      }
    } else {
      if (localStorage.getItem(STAY_LOGGED_IN) === "true" || hours === -1) {
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
      } else {
        document.cookie = `${cname}=${cvalue};path=/`;
      }
    }
  }
}

export function getCookie(cname: string) {
  const name = `${cname}=`;
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

export function checkCookie() {
  const user = getCookie("sessionTimer");
  if (user !== "") {
    return user;
  }
  return null;
}

async function getActiveDevices() {
  try {
    const userId = Number(localStorage.getItem(CURRENT_USER_ID) ?? -1);
    if (userId === -1) {
      return 0;
    }
    const response = await getDevicesList(userId);
    const webDevices = response.Web;
    const activeDevices = webDevices.filter(
      (device) => device.deviceStatus === "active"
    );
    return activeDevices.length;
  } catch (e) {
    throw e;
  }
}

export async function refreshAccess(isDeviceEnabled?: boolean) {
  try {
    const cookie = await renewAccessToken(isDeviceEnabled);
    return cookie;
  } catch (e) {
    try {
      const logOutUserFunction = getLogOutUserFunction();
      const activeDevices = await getActiveDevices();
      let logOutreason = "";
      if (activeDevices === maxActiveDeviceLimit) {
        const message = strings.device_forced_sign_out;
        const category = SnackBarType.INFO.displayValue;
        const title = strings.sign_out_title;
        store.dispatch(sendSuccessSnackBarMessage(title, message, category));
        logOutreason = "Automatic Signout";
      } else {
        logOutreason = "Device Manual Signout";
      }

      // log out user when token api fails
      if (logOutUserFunction) {
        if (!window.location.href.includes("login")) {
          const queryParams = {
            refernext: window.location.href,
            location: "devices",
          };
          generateProfileSignoutConfirmedAmplitude(logOutreason);
          clearCookies();
          logOutUserFunction(queryParams);
        }
      } else {
        generateProfileSignoutConfirmedAmplitude(logOutreason);
        clearCookies();
        window.location.href = `/login?refernext=${window.location.href}&location=devices`;
      }
    } catch (e) {
      const category = SnackBarType.ERROR.displayValue;
      const title = strings.error_title;
      store.dispatch(sendSuccessSnackBarWithLinkMessage(title, category));
    }
    throw e;
  }
}

async function fetchAccessToken(
  userId: string,
  refreshToken: string,
  isDeviceEnabled?: boolean
) {
  const verifyRequest: VerifyRequest = { userId, refreshToken };
  const request = { verifyRequest };
  const response = await verificationService(request, isDeviceEnabled);
  if (response?.data?.token) {
    setCookie("sessionTimer", 24, "sessionTimer");
    setCookie("accessToken", 24, response.data.token);
    setCookie("currentUserId", 24, userId);
    setCookie(
      STAY_LOGGED_IN,
      24,
      localStorage.getItem(STAY_LOGGED_IN)?.toString()
    );
    return response.data.token;
  }
  throw new Error(invalidTokenError);
}
export async function renewAccessToken(isDeviceEnabled?: boolean) {
  const refreshToken = getCookie("refreshToken");
  const accessToken = getCookie("accessToken") || null;
  if (refreshToken) {
    const userId = localStorage.getItem(CURRENT_USER_ID);
    if (userId && refreshToken) {
      if (accessToken) {
        const decoded = jwt_decode<JwtPayload>(accessToken);
        const expirationSeconds = decoded.exp;
        const nowSeconds = new Date().getTime() / 1000.0;
        if (expirationSeconds) {
          if (expirationSeconds - nowSeconds < 10.0) {
            // This is expired or expiring imminently.
            // We have the inputs to attempt refresh, so try it.
            const token = await fetchAccessToken(
              userId,
              refreshToken,
              isDeviceEnabled
            );
            return token;
          }
          return accessToken;
        }
      } else {
        const token = await fetchAccessToken(
          userId,
          refreshToken,
          isDeviceEnabled
        );
        return token;
      }
    }
    throw new Error(invalidTokenError);
  }
  return accessToken;
}
export function setAccesstoken(accessToken: string) {
  setCookie("accessToken", 24, accessToken);
}

export function setRefreshToken(refreshToken: string) {
  setCookie("refreshToken", 24, refreshToken);
}

export function clearCookies() {
  setCookie("refreshToken", -1, "");
  setCookie("accessToken", -1, "");
  setCookie("stayLoggedIn", -1, "");
  localStorage.removeItem(CURRENT_USER_ID);
  localStorage.removeItem(CURRENT_USER_NAME);
  localStorage.removeItem(CUSTOMER_NAME);
  localStorage.removeItem(STAY_LOGGED_IN);
  localStorage.removeItem(SESSION_BANNER);
  localStorage.removeItem(TRANSACTION);
  localStorage.removeItem(IS_NOTIFICATION_SYNCED);
  setCookie("sessionTimer", -1, "");
  setCookie("currentUserId", -1, "");
  setCookie("contextuallink", -1, "");
}
