import React, { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface ScrollToTopProps extends RouteComponentProps {
  children: React.ReactNode;
}
export const ScrollToTop = (props: ScrollToTopProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname, props.location.search]);
  return <>{props.children}</>;
};
export const ScrollToTopWithRouter = withRouter(ScrollToTop);
