import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getWebUserAgent } from "@athena/epoc-frontend-library/dist";
import { AuthRequest } from "../models/AuthRequest";
import { ResponseErrorObject } from "../utils/responseErrorObject";
import { config } from "../config/envConfig";
import * as cmeService from "./cmeAuthenticationService";
import { VerifyRequest } from "../models/VerifyRequest";
import { AuthorizationCodeRedeemRequest } from "../models/AuthorizationCodeRedeemRequest";
import { getCookie } from "../utils/cookies";
import { store } from "../store/configureStore";
import { userEntitlementService } from "../services/userEntitlementService";
import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

export const authenticationService = (request: any) => {
  const state = store.getState();
  const { authenticationUrl } = config.environment;
  const isDeviceManagementEnabled =
    state.accountProfile.device_management_enabled;

  const instance = axios.create({
    baseURL: authenticationUrl,
    timeout: 60000,
  });

  const axiosConfig: AxiosRequestConfig = {
    headers: isDeviceManagementEnabled
      ? {
          "Epoc-Platform": "Web",
          "Device-Id": getCookie("ebi"),
          "Device-Family": getWebUserAgent(),
        }
      : {},
  };

  const authRequest: AuthRequest = request as AuthRequest;

  const url = isDeviceManagementEnabled ? `/v2/login` : `/v1/login`;

  return instance
    .post(url, request, axiosConfig)
    .then((response: any) => {
      queryClient.fetchQuery(["userEntitlements"], userEntitlementService); // update account type
      if (authRequest.isCMELogin) {
        const authResponse = response;
        const cmeResponse = cmeService.cmeAuthenticationService(
          authResponse.data.tokens.accessToken,
          authRequest,
          authResponse.data.tokens
        );
        return cmeResponse;
      }
      return response;
    })
    .catch((error: any) => {
      const data = ResponseErrorObject(error.response);
      return data;
    });
};

export const verificationService = (
  request: any,
  isDeviceEnabled?: boolean
) => {
  const state = store.getState();
  const isDeviceManagementEnabled =
    state.accountProfile.device_management_enabled || isDeviceEnabled;
  const { authenticationUrl } = config.environment;

  const requestHeaders = isDeviceManagementEnabled
    ? {
        "Epoc-Platform": "Web",
        "Device-Id": getCookie("ebi"),
        "Device-Family": getWebUserAgent(),
      }
    : {};

  const instance = axios.create({
    baseURL: authenticationUrl,
    timeout: 60000,
  });

  const verifyRequest: VerifyRequest = request.verifyRequest as VerifyRequest;

  const axiosConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...requestHeaders,
    },
  };

  const url =
    isDeviceManagementEnabled || isDeviceEnabled
      ? `/v2/user/${verifyRequest.userId}/token`
      : `/v1/user/${verifyRequest.userId}/token`;

  return instance
    .post(
      url,
      { refreshToken: request.verifyRequest.refreshToken },
      axiosConfig
    )
    .then((response: any) => response)
    .catch((error: any) => {
      const data = ResponseErrorObject(error.response);
      return data;
    });
};

export const authorizationCodeRedemptionService = (request: any) => {
  const { authenticationUrl } = config.environment;

  const instance = axios.create({
    baseURL: authenticationUrl,
    timeout: 60000,
  });

  const authCodeRedeemRequest: AuthorizationCodeRedeemRequest =
    request.authorizationCodeRedeemRequest as AuthorizationCodeRedeemRequest;

  return instance
    .post("/v1/authorizationcode/redeem", authCodeRedeemRequest)
    .then((response: any) => response)
    .catch((error: any) => {
      const data = ResponseErrorObject(error.response);
      return data;
    });
};

export const oneTimeCodeService = async (
  email: string
): Promise<AxiosResponse> => {
  const { authenticationUrl } = config.environment;
  const instance = axios.create({
    baseURL: authenticationUrl,
    timeout: 30000,
  });

  return instance
    .post("/v1/otc/request", { email })
    .then((response: AxiosResponse) => response)
    .catch((error) => error.response ?? { status: 500, data: {} });
};

export const verifyOneTimeCodeService = async (
  email: string,
  otc: string
): Promise<AxiosResponse> => {
  const state = store.getState();
  const { authenticationUrl } = config.environment;
  const isDeviceManagementEnabled =
    state.accountProfile.device_management_enabled;
  const instance = axios.create({
    baseURL: authenticationUrl,
    timeout: 30000,
  });

  const axiosConfig: AxiosRequestConfig = {
    headers: isDeviceManagementEnabled
      ? {
          "Epoc-Platform": "Web",
          "Device-Id": getCookie("ebi"),
          "Device-Family": getWebUserAgent(),
        }
      : {},
  };

  const url = isDeviceManagementEnabled ? `/v2/login/otc` : `/v1/login/otc`;

  return instance
    .post(url, { email, otc }, axiosConfig)
    .then((response: AxiosResponse) => response)
    .catch((error) => error.response ?? { status: 500, data: {} });
};

export const authenticationAsyncService = async (
  request: AuthRequest
): Promise<AxiosResponse> => {
  const state = store.getState();
  const { authenticationUrl } = config.environment;
  const isDeviceManagementEnabled =
    state.accountProfile.device_management_enabled;
  const instance = axios.create({
    baseURL: authenticationUrl,
    timeout: 30000,
    validateStatus(status) {
      return status >= 200 && status < 500;
    },
  });

  const axiosConfig: AxiosRequestConfig = {
    headers: isDeviceManagementEnabled
      ? {
          "Epoc-Platform": "Web",
          "Device-Id": getCookie("ebi"),
          "Device-Family": getWebUserAgent(),
        }
      : {},
  };

  const url = isDeviceManagementEnabled ? `/v2/login` : `/v1/login`;

  try {
    const response = await instance.post(url, request, axiosConfig);
    return response;
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};
