import { io, Socket, SocketOptions, ManagerOptions } from "socket.io-client";
import { config } from "../config/envConfig";

export type SocketOptionsType = Partial<ManagerOptions & SocketOptions>;

const TOKEN_ATTR = "qrToken";

const addTokenAsQueryIfExists = (socketOptions: SocketOptionsType) => {
  const qrToken = localStorage.getItem(TOKEN_ATTR);
  if (qrToken) {
    socketOptions.query = {
      token: qrToken,
    };
  }
  return socketOptions;
};

export const saveToken = (token: string): void => {
  localStorage.setItem(TOKEN_ATTR, token);
};

export const removeToken = (): void => {
  localStorage.removeItem(TOKEN_ATTR);
};

export const connectSocket = (): Socket => {
  const socketUrl = config.environment.gatewayUrl;
  let socketOptions: SocketOptionsType = {
    path: "/authentication/socket.io",
    transports: ["websocket"],
  };
  socketOptions = addTokenAsQueryIfExists(socketOptions);
  return io(socketUrl, socketOptions);
};
