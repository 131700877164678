import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
  Product,
  getUserProducts,
  getProductsForPromoCode,
} from "src/services/subscriptionService";

export const RetriveUserProducts = (
  userId: number,
  isDeviceEnabled: boolean
): UseQueryResult<Product[]> =>
  useQuery<Product[]>(
    ["userIdRequest", { userId }],
    () => getUserProducts(userId, isDeviceEnabled),
    {
      enabled: userId !== undefined && userId !== -1,
      retry: 3,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

export const RetrivePromoCodeProducts = (
  userId: number,
  promoCode: string
): UseQueryResult<Product[]> =>
  useQuery<Product[]>(
    ["promoCode", { promoCode }],
    () => getProductsForPromoCode(userId, promoCode),
    {
      enabled: userId !== undefined && userId !== -1,
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
