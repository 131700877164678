import { isOfType } from "../utils/typeguard";
import { IReducerAction } from "./IReducerAction";

export interface ProductInfo {
  id: string;
  metadata: ProductMetadata;
  entitlements: string[];
  price: string;
}
export interface ProductMetadata {
  PRODUCT_FAMILY: string;
  SHORT_DESCRIPTION: string;
  NEW_USER_PRODUCT: string;
  AVAILABILITY: string;
  DISPLAY_ORDER: string;
  BEST_VALUE_PRIORITY: string;
  PRESELECTION_PRIORITY: string;
  iap_sku: string;
}
export interface Subscription {
  activeProducts: ProductInfo[];
  active: string;
  status: SubscriptionStatus;
  ends?: Date;
  id: string;
  platform: string;
  planType: string;
  amount?: number;
  currency?: string;
  renewal?: string;
}
export interface SubscriptionResponse {
  data: Subscription[];
  status: number;
  planType: string;
  trail: boolean;
  platform: string;
  subscriptionStatus: SubscriptionStatus;
  currentSubscriptionId: string;
  end: string;
  productDescription: string;
  productId: string;
  productPrice: string;
}
export enum SubType {
  standard = "STANDARD",
  monthly = "MONTHLY",
  yearly = "YEARLY",
}
export enum SubscriptionStatus {
  pendingActivation = "Pending Activation",
  active = "Active",
  pendingCancel = "Pending Cancel",
  cancelled = "Cancelled",
  expired = "Expired",
  processing = "Processing",
  deleted = "Deleted",
  dryrun = "Dryrun",
  unknown = "Unknown",
  upgraded = "Upgraded",
  legacySuspended = "Legacy Suspended",
  pendingPause = "Pending Pause",
  paused = "Paused",
  pendingResume = "Pending Resume",
}
export interface IUserSubTypeAction extends IReducerAction {
  response: IUserSubTypeState;
}

export interface IUserSubTypeState {
  plusUser: boolean | null;
  subscriptionType: SubType;
}
export interface SubscriptionQueryResponse {
  data: Subscription[];
  status: number;
}
export function isSubscriptionQueryResponse(
  obj: unknown
): obj is SubscriptionQueryResponse {
  const purchaseDetailsResponse = obj as SubscriptionQueryResponse;
  return (
    isOfType<SubscriptionQueryResponse>(purchaseDetailsResponse, ["data"]) &&
    Array.isArray(purchaseDetailsResponse?.data) &&
    purchaseDetailsResponse.data.every((subscription) =>
      isSubscriptionDetails(subscription)
    )
  );
}
function isSubscriptionDetails(obj: unknown): obj is Subscription {
  const subscription = obj as Subscription;
  return isOfType<Subscription>(subscription, [
    "activeProducts",
    "active",
    "ends",
  ]);
}
