import * as React from "react";
import clsx from "clsx";
import { colors } from "../config/colorConfig";
import { strings } from "../utils/Strings";
import { makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  OneTimeCodeQuery,
  VerifyOneTimeCodeQuery,
} from "src/queries/OneTimeCodeQuery";
import {
  sendSnackBarMessage,
  sendSuccessSnackBarMessage,
} from "src/actions/snackBarActions";
import { SnackBarType } from "src/utils/SnackBarType";
import { useEffect } from "react";
import { AuthResponse } from "src/models/AuthResponse";
import { finishLogin } from "../utils/loginUtil";
import {
  getToolsAndLocation,
  sendAmplitudeEvent,
} from "src/utils/AmplitudeUtil";
import { RouteComponentProps } from "react-router";
import { OtcInputBox } from "./OtcInputBox";
import {
  API_Failure,
  Authentication_OTC_Code_Screen_Cancel_Click,
  Authentication_OTC_Code_Screen_Resend_Code_Click,
  Authentication_OTC_Code_Screen_Sign_In_Click,
  Authentication_OTC_Code_Screen_View,
  FAIL_SIGN_IN,
} from "src/utils/analytics";
import { QROTCLoginPageBody } from "./custom/QROTCLoginPageBody";
import { OptimizelyContext } from "@optimizely/react-sdk";

interface OtcLoginFormProps extends RouteComponentProps {
  email: string;
}

export const OtcLoginForm = (props: OtcLoginFormProps) => {
  const [otcValue, setOtcValue] = React.useState(""); // State to store the OTP value
  const [isAuthSuccess, setIsAuthSuccess] = React.useState(false);
  const { optimizely } = React.useContext(OptimizelyContext);
  const verifyOneTimeCode = VerifyOneTimeCodeQuery(props.email, otcValue);
  const fetchOneTimeCode = OneTimeCodeQuery(props.email);
  const dispatch = useDispatch();

  const handleOtpChange = (newOtpValue: string) => {
    setOtcValue(newOtpValue);
  };
  const bodyWrapper = makeStyles((theme) => ({
    title: {
      color: colors["--title"],
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "32px",
      textAlign: "center",
      fontFamily: "Source Sans Pro",
      marginBottom: theme.spacing(3),
    },
    otcForm: {
      width: "100%",
    },
    otcTip: {
      color: colors["--primary-midnight"],
      textAlign: "center",
      fontFamily: "Source Sans Pro",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      marginBottom: "16px",
      marginTop: "4px",
    },
    enterOtcText: {
      color: colors["--ui-slate"],
      textAlign: "center",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily: "Source Sans Pro",
      lineHeight: "24px",
    },
    enterOtcTextEmail: {
      fontWeight: 600,
    },
    cancelButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "48px",
      width: "100%",
      fontWeight: 600,
      fontSize: 18,
      borderRadius: 3,
      textTransform: "none",
      cursor: "pointer",
      backgroundColor: colors["--text-invert"],
      textDecoration: "none",
      color: colors["--primary-midnight"],
      fontFamily: "Source Sans Pro",
      "&:hover": {
        textDecoration: "none",
        color: "inherit",
      },
    },
    otcFormButtons: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "48px",
      width: "100%",
      fontWeight: 600,
      fontSize: 18,
      borderRadius: 3,
      textTransform: "none",
      cursor: "pointer",
      textDecoration: "none",
      fontFamily: "Source Sans Pro",
      "&:hover": {
        textDecoration: "none",
      },
    },
    resendCode: {
      backgroundColor: colors["--text-invert"],
      color: colors["--primary-tealnight"],
    },
    signIn: {
      backgroundColor: colors["--primary-tealnight"],
      color: colors["--text-invert"],
      marginBottom: "16px",
    },
    loginFormWrapper: {
      padding: "0 24px",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: "0 16px",
      },
    },
  }));
  const classes = bodyWrapper();
  useEffect(() => {
    dispatch(
      sendSuccessSnackBarMessage(
        strings.one_time_code_sent,
        strings.one_time_code_sent_message,
        SnackBarType.SUCCESS.displayValue
      )
    );
    sendAmplitudeEvent(Authentication_OTC_Code_Screen_View);
  }, []);
  const handleSubmit = async () => {
    sendAmplitudeEvent(Authentication_OTC_Code_Screen_Sign_In_Click);
    verifyOneTimeCode
      .refetch()
      .then((response) => {
        if (response.data?.status === 200) {
          setIsAuthSuccess(true);
          const data = response.data?.data;
          const authResponse: AuthResponse = {
            userId: data.userId,
            email: data.email,
            tokens: {
              accessToken: data.tokens.accessToken,
              refreshToken: data.tokens.refreshToken,
            },
            status: 200,
            error: { code: "", message: "" },
            token: "",
          };
          finishLogin({
            authResponse,
            stayLoggedIn: true,
            toolsLocation: getToolsAndLocation(),
            location: props.location,
            history: props.history,
            loginMethod: "OTC",
            optimizely,
          });
        } else if (response.data?.status === 457) {
          let errorMessage = "";
          if (
            response.data?.data.error.code == "incorrectOtc" ||
            response.data?.data.error.code == "attemptsExhausted"
          ) {
            errorMessage = "Code Invalid";
          } else {
            errorMessage = "Code Expired";
          }
          const eventProperties = {
            "Error message": errorMessage,
            Method: "OTC",
          };
          sendAmplitudeEvent(FAIL_SIGN_IN, eventProperties);
          dispatch(
            sendSnackBarMessage(
              errorMessage,
              response.data?.data.error.message,
              SnackBarType.ERROR.displayValue
            )
          );
        } else {
          const eventProperties = {
            "Error message": response.data?.data.error.message,
            "Response Code": response.data?.status,
            URL: window.location.href,
          };
          sendAmplitudeEvent(API_Failure, eventProperties);
          dispatch(
            sendSnackBarMessage(
              "",
              "OTC System Error",
              SnackBarType.ERROR.displayValue
            )
          );
        }
        setOtcValue("");
        return;
      })
      .catch((error) =>
        dispatch(
          sendSnackBarMessage(
            "",
            error.message,
            SnackBarType.ERROR.displayValue
          )
        )
      );
  };

  const resendOtp = async () => {
    if (otcValue) {
      setOtcValue("");
    }
    sendAmplitudeEvent(Authentication_OTC_Code_Screen_Resend_Code_Click);
    fetchOneTimeCode
      .refetch()
      .then((response) => {
        if (response.data?.status === 201) {
          dispatch(
            sendSuccessSnackBarMessage(
              strings.one_time_code_resent,
              strings.one_time_code_resent_message,
              SnackBarType.SUCCESS.displayValue
            )
          );
        }
        return;
      })
      .catch((error) =>
        dispatch(
          sendSnackBarMessage(
            "",
            error.message,
            SnackBarType.ERROR.displayValue
          )
        )
      );
  };

  const onCancelClick = () => {
    sendAmplitudeEvent(Authentication_OTC_Code_Screen_Cancel_Click);
  };

  const bodyTitle = strings.sign_in_with_otp;
  const formBody = (
    <div className={clsx(classes.otcForm)}>
      <OtcInputBox
        value={otcValue}
        valueLength={6}
        onChange={handleOtpChange}
      />
      <p className={clsx(classes.otcTip)}>{strings.otc_tip}</p>
      <div
        className={clsx(classes.otcFormButtons, classes.signIn)}
        onClick={handleSubmit}
      >
        {strings.sign_in_title}
      </div>

      <div
        className={clsx(classes.otcFormButtons, classes.resendCode)}
        onClick={resendOtp}
      >
        {strings.resend_otc}
      </div>
    </div>
  );
  const cancelButton = (
    <a
      className={clsx(classes.cancelButton)}
      href="/login"
      data-testid="cancelbuttonlink"
      rel="noreferrer"
      onClick={onCancelClick}
    >
      {strings.cancel}
    </a>
  );
  return (
    <QROTCLoginPageBody
      shouldShowLoadingLogo={isAuthSuccess}
      isSmallWidth={true}
    >
      <div className={clsx(classes.loginFormWrapper)}>
        <Typography
          data-testid="title"
          variant="h6"
          className={clsx(classes.title)}
        >
          {bodyTitle}
        </Typography>
        <p className={clsx(classes.enterOtcText)}>
          Enter the code we sent to{" "}
          <span className={clsx(classes.enterOtcTextEmail)}>{props.email}</span>
        </p>
        {formBody}
        {cancelButton}
      </div>
    </QROTCLoginPageBody>
  );
};
