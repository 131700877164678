import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { UserSubscriptionLandingPage } from "src/components/UserSubscriptionLandingPage";
import { PageType, SubscriptionContext } from "src/context/SubscriptionContext";
import { ManageAccountOptimizelyWrapper as ManageAccountWrapper } from "./ManageAccountOptimizelyWrapper";
import { OptimizelyContext, useDecision } from "@optimizely/react-sdk";
import { deviceManagementEnabledFlag } from "src/utils/optimizelyFlag";
import { updateOptimizelyFlagsForSegment } from "src/utils/AmplitudeUtil";

export const UserLandingPage = (props: RouteComponentProps) => {
  const [deviceManagementDecision] = useDecision(deviceManagementEnabledFlag);
  const { currentPage } = useContext(SubscriptionContext);
  const { optimizely } = useContext(OptimizelyContext);
  let pageContent = <></>;
  const [vindiciaFieldsSpinner, setVindiciaFieldsSpinner] = useState(true);
  const { vindicia } = window;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://secure.vindicia.com/pmt/vindicia.js";
    script.async = true;
    document.body.appendChild(script);
    const waitForVindiciaFields = () => {
      const timeoutDuration = 1000;
      const intervalDuration = 1000;
      let vindiciaFieldsTimeout: ReturnType<typeof setTimeout> | null = null;
      let vindiciaFieldsInterval: ReturnType<typeof setInterval> | null = null;
      vindiciaFieldsTimeout = setTimeout(() => {
        setVindiciaFieldsSpinner(false);
        if (vindiciaFieldsInterval) {
          clearInterval(vindiciaFieldsInterval);
        }
      }, timeoutDuration);
      vindiciaFieldsInterval = setInterval(() => {
        if (
          window.vindicia.frames.cardNumber?.isLoaded &&
          window.vindicia.frames.expirationDate?.isLoaded &&
          window.vindicia.frames.cvn?.isLoaded
        ) {
          setVindiciaFieldsSpinner(false);
          if (vindiciaFieldsTimeout) {
            clearTimeout(vindiciaFieldsTimeout);
          }
          if (vindiciaFieldsInterval) {
            clearInterval(vindiciaFieldsInterval);
          }
        }
      }, intervalDuration);

      return () => {
        if (vindiciaFieldsInterval) {
          clearInterval(vindiciaFieldsInterval);
        }
        if (vindiciaFieldsTimeout) {
          clearTimeout(vindiciaFieldsTimeout);
        }
      };
    };
    waitForVindiciaFields();
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      const decisions = optimizely?.decideAll(undefined, userId);
      updateOptimizelyFlagsForSegment(userId, decisions);
    }
  }, []);

  switch (true) {
    case currentPage === PageType.SelectSubscription:
    case currentPage === PageType.PaymentBilling:
      pageContent = (
        <UserSubscriptionLandingPage
          {...props}
          deviceManagementDecision={deviceManagementDecision}
          vindiciaFieldsSpinner={vindiciaFieldsSpinner}
          vindicia={vindicia}
        />
      );
      break;
    default:
      pageContent = (
        <ManageAccountWrapper
          {...props}
          vindiciaFieldsSpinner={vindiciaFieldsSpinner}
          vindicia={vindicia}
        />
      );
  }
  return <>{pageContent}</>;
};
