import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../../config/colorConfig";

const renderHTML = (rawHTML: unknown) =>
  React.createElement("div", {
    dangerouslySetInnerHTML: { __html: rawHTML },
  });
interface CardNumberProps {
  cardNumber?: string;
}

export const CardNumber = (props: CardNumberProps) => {
  const styles = makeStyles((theme) => ({
    cardNumber: {
      color: colors["--ui-slate"],
      marginRight: 15,
    },
  }));
  const classes = styles(props);
  if (!props.cardNumber) {
    return null;
  }
  return (
    <Typography data-testid="cardNumber" className={clsx(classes.cardNumber)}>
      {renderHTML(
        props.cardNumber
          .match(/.{1,4}/g)
          ?.join(" ")
          ?.replace(/X/g, "&#9679;")
      )}
    </Typography>
  );
};
