import { combineReducers } from "redux";
import { login } from "./loginReducer";
import { dashboard } from "./dashboardReducer";
import { sendSnackBarMessage } from "./sendSnackBarMessageReducer";
import { cmeLaunch } from "./cmelaunchReducer";
import { privateCmeRouter } from "./privateCmeRouteReducer";
import { accountCreation } from "./accountCreationReducer";
import { npiLookup } from "./npiLookupReducer";
import { accountProfile } from "./accountProfileReducer";
import { emailPreference } from "./emailPreferenceReducer";

export const rootReducer = combineReducers({
  cmeLaunch,
  privateCmeRouter,
  login,
  dashboard,
  sendSnackBarMessage,
  accountCreation,
  npiLookup,
  accountProfile,
  emailPreference,
});

export type RootState = ReturnType<typeof rootReducer>;
