import axios, { AxiosResponse } from "axios";
import { getWebUserAgent } from "@athena/epoc-frontend-library/dist";
import {
  ChangePasswordAxiosResponse,
  ChangePasswordRequest,
} from "src/models/ChangePassword";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";
import { config } from "../config/envConfig";
import { getCookie, refreshAccess } from "../utils/cookies";
import { store } from "../store/configureStore";
import { sendAmplitudeEvent } from "../utils/AmplitudeUtil";
import {
  ACCOUNT_CHANGE_PASSWORD_SUCCESS_EVENT_ID,
  ACCOUNT_CHANGE_PASSWORD_SUCCESS,
  ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER_EVENT_ID,
  ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER,
  ACCOUNT_CHANGE_PASSWORD_FAILURE_EVENT_ID,
  ACCOUNT_CHANGE_PASSWORD_FAILURE,
} from "../utils/analytics";
import { strings } from "../utils/Strings";

export interface ChangePasswordReq {
  changePasswordRequest: ChangePasswordRequest;
}

const { authenticationUrl } = config.environment;

export const changePasswordService = async (
  request: ChangePasswordRequest
): Promise<ChangePasswordAxiosResponse> => {
  const state = store.getState();
  const isDeviceManagementEnabled =
    state.accountProfile.device_management_enabled;
  const instance = axios.create({
    baseURL: authenticationUrl,
    timeout: 30000,
  });

  const requestHeaders = isDeviceManagementEnabled
    ? {
        "Epoc-Platform": "Web",
        "Device-Id": getCookie("ebi"),
        "Device-Family": getWebUserAgent(),
      }
    : {};

  const cookie = await refreshAccess();
  const userId =
    state.accountProfile.accountProfileResponse?.userInformation?.userId ??
    localStorage.getItem(CURRENT_USER_ID);
  const url = isDeviceManagementEnabled
    ? `/v2/password/${userId}`
    : `/v1/password/${userId}`;

  return instance
    .post(url, request, {
      headers: {
        Authorization: `${cookie}`,
        ...requestHeaders,
      },
    })
    .then((response: AxiosResponse) => {
      if (response.status >= 200 && response.status <= 299) {
        sendAmplitudeEvent(
          ACCOUNT_CHANGE_PASSWORD_SUCCESS,
          {
            "Event ID": ACCOUNT_CHANGE_PASSWORD_SUCCESS_EVENT_ID,
          },
          userId
        );
        sendAmplitudeEvent(
          ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER,
          {
            "Event ID": ACCOUNT_PROFILE_UPDATE_SUCCESS_BANNER_EVENT_ID,
            "Banner Type": "Password",
          },
          userId
        );
        return response;
      } else {
        let errorDescription;
        if (
          response.data?.error?.code ===
          strings.current_password_mismatch_error_code
        ) {
          errorDescription = strings.password_mismatch_error;
        } else {
          errorDescription = response.data?.error?.message;
        }
        sendAmplitudeEvent(
          ACCOUNT_CHANGE_PASSWORD_FAILURE,
          {
            "Event ID": ACCOUNT_CHANGE_PASSWORD_FAILURE_EVENT_ID,
            Description: errorDescription,
          },
          userId
        );
        return response;
      }
    })
    .catch((error) => {
      sendAmplitudeEvent(
        ACCOUNT_CHANGE_PASSWORD_FAILURE,
        {
          "Event ID": ACCOUNT_CHANGE_PASSWORD_FAILURE_EVENT_ID,
          Description: error.message ?? "An unknown error occurred",
        },
        userId
      );
      return error.response || { status: 500, data: {} };
    });
};
