import {
  COUNTRIES_ERROR,
  COUNTRIES_INPROGRESS,
  COUNTRIES_SUCCESS,
  COUNTRIES_SYSTEM_ERROR,
  OCCUPATIONS_ERROR,
  OCCUPATIONS_INPROGRESS,
  OCCUPATIONS_SUCCESS,
  OCCUPATIONS_SYSTEM_ERROR,
  SPECIALTIES_ERROR,
  SPECIALTIES_INPROGRESS,
  SPECIALTIES_SUCCESS,
  SPECIALTIES_SYSTEM_ERROR,
  SUBSPECIALTIES_ERROR,
  SUBSPECIALTIES_INPROGRESS,
  SUBSPECIALTIES_SUCCESS,
  SUBSPECIALTIES_SYSTEM_ERROR,
  ACCOUNT_CREATION_INPROGRESS,
  ACCOUNT_CREATION_SUCCESS,
  ACCOUNT_CREATION_ERROR,
  EMAIL_VALIDATION_SUCCESS,
  EMAIL_VALIDATION_INPROGRESS,
  ZIP_CODE_VALIDATION_SUCCESS,
  ZIP_CODE_VALIDATION_INPROGRESS,
  CLEAR_ZIP_CODE_VALIDATION_STATE,
  ZIP_CODE_VALIDATION_FAILED,
} from "../actions";

const initialState = {
  account_progress: false,
  specialties_progress: false,
  subSpecialties_progress: false,
  account_error: [],
  verify_progress: false,
  validation_done: false,
  system_error: false,
  showSpecialty: false,
  showSubSpecialty: false,
  updateUserCompleted: false,
  email_validation_progress: false,
  email_validation_error: false,
  zipCode_validation_progress: false,
  zipCode_validation_error: false,
};

export function accountCreation(state = initialState, action: any): any {
  const {
    countriesResponse,
    occupationsResponse,
    specialtiesResponse,
    subSpecialtiesResponse,
    accountResponse,
    emailValidationResponse,
    zipCodeValidationResponse,
    accountError,
    type,
  } = action;
  switch (type) {
    case COUNTRIES_SUCCESS:
      return {
        ...state,
        countriesResponse,
        verify_progress: false,
        validation_done: true,
      };
    case COUNTRIES_ERROR:
      return {
        ...state,
        countriesResponse,
        verify_progress: false,
        validation_done: true,
      };
    case COUNTRIES_INPROGRESS:
      return { ...state, verify_progress: true, validation_done: false };
    case COUNTRIES_SYSTEM_ERROR:
      return {
        ...state,
        verify_progress: false,
        system_error: true,
        validation_done: true,
      };
    case OCCUPATIONS_SUCCESS:
      return {
        ...state,
        occupationsResponse,
        verify_progress: false,
        validation_done: true,
      };
    case OCCUPATIONS_ERROR:
      return {
        ...state,
        occupationsResponse,
        verify_progress: false,
        validation_done: true,
      };
    case OCCUPATIONS_INPROGRESS:
      return { ...state, verify_progress: true, validation_done: false };
    case OCCUPATIONS_SYSTEM_ERROR:
      return {
        ...state,
        verify_progress: false,
        system_error: true,
        validation_done: true,
      };
    case SPECIALTIES_SUCCESS:
      return {
        ...state,
        specialtiesResponse,
        specialties_progress: false,
        validation_done: true,
        showSpecialty: !(
          !Array.isArray(specialtiesResponse.data) ||
          !specialtiesResponse.data.length ||
          (specialtiesResponse.data.length === 1 &&
            specialtiesResponse.data[0].text === "No Specialty")
        ),
      };
    case SPECIALTIES_ERROR:
      return {
        ...state,
        specialtiesResponse,
        specialties_progress: false,
        validation_done: true,
        showSpecialty: false,
        showSubSpecialty: false,
      };
    case SPECIALTIES_INPROGRESS:
      return {
        ...state,
        specialties_progress: true,
        validation_done: false,
        showSpecialty: false,
        showSubSpecialty: false,
      };
    case SPECIALTIES_SYSTEM_ERROR:
      return {
        ...state,
        specialties_progress: false,
        system_error: true,
        validation_done: true,
        showSpecialty: false,
        showSubSpecialty: false,
      };
    case SUBSPECIALTIES_SUCCESS:
      return {
        ...state,
        subSpecialtiesResponse,
        verify_progress: false,
        validation_done: true,
        subSpecialties_progress: false,
        showSubSpecialty: !(
          !Array.isArray(subSpecialtiesResponse.data) ||
          !subSpecialtiesResponse.data.length ||
          (subSpecialtiesResponse.data.length === 1 &&
            subSpecialtiesResponse.data[0].text === "No subspecialty")
        ),
      };
    case SUBSPECIALTIES_ERROR:
      return {
        ...state,
        subSpecialtiesResponse,
        verify_progress: false,
        validation_done: true,
        showSubSpecialty: false,
        subSpecialties_progress: false,
      };
    case SUBSPECIALTIES_INPROGRESS:
      return {
        ...state,
        subSpecialties_progress: true,
        verify_progress: true,
        validation_done: false,
        showSubSpecialty: false,
      };
    case SUBSPECIALTIES_SYSTEM_ERROR:
      return {
        ...state,
        verify_progress: false,
        system_error: true,
        validation_done: true,
        showSubSpecialty: false,
        subSpecialties_progress: false,
      };
    case ACCOUNT_CREATION_SUCCESS:
      return { ...state, accountResponse, account_progress: false };
    case ACCOUNT_CREATION_ERROR:
      return {
        ...state,
        accountResponse,
        account_progress: false,
        account_error: accountError,
      };
    case ACCOUNT_CREATION_INPROGRESS:
      return { ...state, account_progress: true };
    case EMAIL_VALIDATION_SUCCESS:
      return {
        ...state,
        emailValidationResponse,
        email_validation_progress: false,
      };
    case EMAIL_VALIDATION_INPROGRESS:
      return {
        ...state,
        email_validation_progress: true,
        emailValidationResponse: null,
      };
    case ZIP_CODE_VALIDATION_SUCCESS:
      return {
        ...state,
        zipCodeValidationResponse,
        zipCode_validation_progress: false,
      };
    case ZIP_CODE_VALIDATION_INPROGRESS:
      return {
        ...state,
        zipCode_validation_progress: true,
        zipCodeValidationResponse: null,
      };
    case ZIP_CODE_VALIDATION_FAILED:
      return {
        ...state,
        zipCodeValidationResponse,
        zipCode_validation_progress: false,
        zipCode_validation_error: true,
        system_error: true,
      };
    case CLEAR_ZIP_CODE_VALIDATION_STATE:
      return {
        ...state,
        zipCodeValidationResponse: null,
      };
    default:
      return state;
  }
}
