import { QRCodeContent } from "src/components/QRCodeContent";
import clsx from "clsx";
import { makeStyles, Paper, Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { ReactElement, useEffect, useState } from "react";
import { QRSignInFormWith } from "src/components/QRSignInForm";
import { QROTCLoginPageBody } from "../components/custom/QROTCLoginPageBody";

interface QRSignInFormWrapProps extends RouteComponentProps {
  isQrLoginEnabled: boolean;
}
export const QRSignInFormWrap = (
  props: QRSignInFormWrapProps
): ReactElement => {
  const [isAuthSuccess, setIsAuthSuccess] = useState(false);
  const [shouldHideQR, setShouldHideQR] = useState(!props.isQrLoginEnabled);
  const bodyWrapper = makeStyles((theme) => ({
    loginFormWrapper: {
      width: "55%",
      padding: "0 40px",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: "0 16px",
      },
    },
    loginFormWrapperQrHidden: {
      padding: "0 24px",
    },
  }));
  const classes = bodyWrapper();
  useEffect(
    () => setShouldHideQR(!props.isQrLoginEnabled),
    [props.isQrLoginEnabled]
  );
  return (
    <QROTCLoginPageBody
      shouldShowLoadingLogo={isAuthSuccess}
      isSmallWidth={shouldHideQR}
    >
      <>
        <div
          className={clsx(
            classes.loginFormWrapper,
            shouldHideQR ? classes.loginFormWrapperQrHidden : ""
          )}
        >
          <QRSignInFormWith {...props} />
        </div>
        {shouldHideQR ? null : (
          <QRCodeContent
            setShouldHideQR={setShouldHideQR}
            setIsAuthSuccess={setIsAuthSuccess}
            {...props}
          />
        )}
      </>
    </QROTCLoginPageBody>
  );
};
