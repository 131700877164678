import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Password } from "./custom/PasswordField";
import { FormDialog } from "./custom/FormDialog";
import { getPasswordError } from "./custom/PasswordError";
import { strings } from "../utils/Strings";
import { ErrorValues } from "../models/ErrorValues";
import { httpConstants } from "../utils/httpConstants";
import {
  ACCOUNT_CHANGE_PASSWORD_CANCEL_CLICK,
  ACCOUNT_CHANGE_PASSWORD_CANCEL_CLICK_EVENT_ID,
  ACCOUNT_CHANGE_PASSWORD_SUBMIT_CLICK,
  ACCOUNT_CHANGE_PASSWORD_SUBMIT_CLICK_EVENT_ID,
} from "../utils/analytics";
import { sendAmplitudeEventData } from "../utils/AmplitudeUtil";
import { useMutation } from "@tanstack/react-query";
import { sendSuccessSnackBarMessage } from "../actions/snackBarActions";
import { SnackBarType } from "../utils/SnackBarType";
import * as types from "../actions";
import { changePasswordService } from "../services/changePasswordService";
import {
  ChangePasswordAxiosResponse,
  ChangePasswordRequest,
} from "src/models/ChangePassword";
import { CURRENT_USER_ID } from "../utils/localStorageKeys";
import { setCookie } from "../utils/cookies";

interface ValidationFieldsType {
  currentPassword: string;
  confirmNewPassword: string;
}
interface FormFieldsType extends ValidationFieldsType {
  newPassword: string;
}
interface FormFieldsErrorFlags {
  currentPassword: boolean;
  confirmNewPassword: boolean;
  newPassword: boolean;
}
interface ChangePasswordFormProps {
  validatePassword: (value: string, field: keyof FormFieldsType) => void;
  formData: FormFieldsType;
  validationError: ValidationFieldsType;
}

interface ChangePasswordDialogProps {
  open: boolean;
  setChangePasswordModal: (open: boolean) => void;
  email: string;
}

const defaultErrorRef: FormFieldsErrorFlags = {
  currentPassword: false,
  confirmNewPassword: false,
  newPassword: false,
};

const defaultInputRef: FormFieldsType = {
  currentPassword: "",
  confirmNewPassword: "",
  newPassword: "",
};

const defaultError = {
  counter: 0,
  errorMessages: [],
  errorStatus: 0,
};

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const styles = makeStyles(() => ({
    formMargin: {
      marginTop: 30,
    },
  }));
  const classes = styles();
  return (
    <>
      <Password
        labelTestId="currentPasswordLabel"
        labelHtmlFor="outlined-adornment-password"
        labelTitle="Current password"
        name="currentPassword"
        ariaLabel="toggle password visibility"
        iconTestId="showPasswordId"
        helpTestId="currentPasswordHelp"
        validateInput={(value: string) =>
          props.validatePassword(value, "currentPassword")
        }
        labelWidth={115}
        value={props.formData.currentPassword}
        validationError={props.validationError?.currentPassword}
        inputTestId="currentPasswordInput"
        noAutoComplete={true}
      />
      <Password
        labelTestId="newPasswordLabel"
        labelHtmlFor="outlined-adornment-password"
        labelTitle="New password"
        name="newPassword"
        ariaLabel="toggle password visibility"
        iconTestId="showPasswordId"
        helpTestId="newPasswordHelp"
        validateInput={(value: string) =>
          props.validatePassword(value, "newPassword")
        }
        labelWidth={100}
        value={props.formData.newPassword}
        inputTestId="newPasswordInput"
        customClass={classes.formMargin}
        noAutoComplete={true}
      />
      <Password
        labelTestId="confirmNewPasswordLabel"
        labelHtmlFor="outlined-adornment-password"
        labelTitle="Confirm new password"
        name="confirmNewPassword"
        ariaLabel="toggle password visibility"
        iconTestId="showPasswordId"
        helpTestId="confirmPasswordHelp"
        validateInput={(value: string) =>
          props.validatePassword(value, "confirmNewPassword")
        }
        labelWidth={150}
        value={props.formData.confirmNewPassword}
        validationError={props.validationError?.confirmNewPassword}
        inputTestId="confirmNewPasswordInput"
        customClass={classes.formMargin}
        noAutoComplete={true}
      />
    </>
  );
};

export const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = (
  props: ChangePasswordDialogProps
) => {
  const formData = React.useRef<FormFieldsType>(defaultInputRef);
  const validFormData = React.useRef<FormFieldsErrorFlags>(defaultErrorRef);
  const [inlineErrorMsg, setInlineErrorMsg] =
    React.useState<ValidationFieldsType>({
      currentPassword: "",
      confirmNewPassword: "",
    });
  const [errorValues, setErrorValues] =
    React.useState<ErrorValues>(defaultError);

  const dispatch = useDispatch();
  const styles = makeStyles(() => ({
    formWrapper: {
      marginTop: 22,
      marginBottom: 22,
    },
  }));
  const classes = styles();
  const changePasswordQuery = useMutation(changePasswordService, {
    onSuccess: (response: ChangePasswordAxiosResponse) => {
      if (response.status >= 200 && response.status <= 299) {
        dispatch({ type: types.TIMEOUT_SNACKBAR });
        dispatch(
          sendSuccessSnackBarMessage(
            "",
            strings.change_password_success,
            SnackBarType.SUCCESS.displayValue
          )
        );
        setCookie("accessToken", 24, response.data.accessToken);
        setCookie("sessionTimer", 24, "sessionTimer");
        setCookie("refreshToken", 24, response.data.refreshToken);
        props.setChangePasswordModal(false);
        validFormData.current = defaultErrorRef;
        formData.current = defaultInputRef;
      } else {
        if (
          response?.data?.error?.code ===
          strings.current_password_mismatch_error_code
        ) {
          setInlineErrorMsg({
            ...inlineErrorMsg,
            currentPassword: strings.password_mismatch_error,
          });
        } else {
          let { counter } = errorValues;
          if (response?.status === httpConstants.INTERNAL_SERVER_ERROR) {
            counter += 1;
          }
          setErrorValues({
            counter,
            errorMessages: response.data?.error
              ? [{ error: response.data?.error }]
              : [],
            errorStatus: response.status,
          });
        }
      }
    },
  });
  const validateSingleInput = (data: string, type: string) => {
    let response = null;
    switch (type) {
      case "password":
        response = getPasswordError(data, props.email);
        break;
      default:
        response = null;
    }
    return response;
  };
  const validatePassword = (data: string, name: keyof FormFieldsType) => {
    formData.current[name] = data;
    setInlineErrorMsg({
      currentPassword: "",
      confirmNewPassword: "",
    });
    setErrorValues(defaultError);
    if (name === "currentPassword" || name === "confirmNewPassword") {
      let isValid = true;
      if (data === "") {
        isValid = false;
      }
      validFormData.current[name] = isValid;
      return null;
    }
    const inputValid = validateSingleInput(data, "password");
    validFormData.current[name] = !inputValid;
    return validateSingleInput(data, "password");
  };

  const handleSubmit = async () => {
    sendAmplitudeEventData(
      null,
      ACCOUNT_CHANGE_PASSWORD_SUBMIT_CLICK,
      ACCOUNT_CHANGE_PASSWORD_SUBMIT_CLICK_EVENT_ID
    );
    if (formData.current.newPassword !== formData.current.confirmNewPassword) {
      setInlineErrorMsg({
        ...inlineErrorMsg,
        confirmNewPassword: strings.confirm_password_mismatch,
      });
    } else {
      const changePasswordRequest: ChangePasswordRequest = {
        currentPassword: formData.current.currentPassword,
        newPassword: formData.current.newPassword,
      };
      await changePasswordQuery.mutateAsync(changePasswordRequest);
    }
  };
  const getDisabledState = () => {
    const { currentPassword, newPassword, confirmNewPassword } =
      validFormData.current;
    if (currentPassword && newPassword && confirmNewPassword) {
      return false;
    }
    return true;
  };

  const closeDialog = () => {
    sendAmplitudeEventData(
      null,
      ACCOUNT_CHANGE_PASSWORD_CANCEL_CLICK,
      ACCOUNT_CHANGE_PASSWORD_CANCEL_CLICK_EVENT_ID
    );
    validFormData.current = defaultErrorRef;
    formData.current = defaultInputRef;
    setInlineErrorMsg({
      currentPassword: "",
      confirmNewPassword: "",
    });
    props.setChangePasswordModal(false);
  };

  return (
    <>
      <FormDialog
        title={strings.change_password_modal_title}
        buttonLabel={strings.change_password_modal_title}
        open={props.open}
        setOpen={closeDialog}
        submitDisabled={getDisabledState()}
        handleSubmit={handleSubmit}
        inProgress={changePasswordQuery.isLoading}
        errorValues={errorValues}
        btnId="changePasswordBtn"
      >
        <div data-testid="formWrapper" className={clsx(classes.formWrapper)}>
          <ChangePasswordForm
            formData={formData.current}
            validatePassword={validatePassword}
            validationError={inlineErrorMsg}
          />
        </div>
      </FormDialog>
    </>
  );
};
