import { QRCode } from "react-qrcode-logo";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../config/colorConfig";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import logo from "../images/circle-logo.svg";
import { connectSocket, saveToken, removeToken } from "../utils/socket";
import { config } from "../config/envConfig";
import { RouteComponentProps } from "react-router-dom";
import { finishLogin } from "../utils/loginUtil";
import { AuthResponse } from "../models/AuthResponse";
import {
  getToolsAndLocation,
  sendAmplitudeEvent,
} from "../utils/AmplitudeUtil";
import { Socket } from "socket.io-client";
import RefreshIcon from "@material-ui/icons/Refresh";
import Skeleton from "@material-ui/lab/Skeleton";
import { SOCKET_CONNECT_ERROR } from "../utils/analytics";
import { OptimizelyContext } from "@optimizely/react-sdk";

interface MessageWithToken {
  token: string;
}

interface UserInfo {
  userId: number;
  email: string;
  accessToken: string;
  refreshToken: string;
}

interface TokenWithUserInfo extends MessageWithToken {
  userInfo: UserInfo;
}

const REFRESH_TIME = 600000;

interface QRCodeContentProps extends RouteComponentProps {
  setShouldHideQR: (shouldHideQR: boolean) => void;
  setIsAuthSuccess: (setIsAuthSuccess: boolean) => void;
}

export const QRCodeContent = (props: QRCodeContentProps): ReactElement => {
  const bodyWrapper = makeStyles((theme) => ({
    qrCodeImageWrapper: {
      display: "flex",
      justifyContent: "center",
      marginBottom: 12,
      position: "relative",
      "& canvas": {
        display: "block",
      },
    },
    qrRefreshBackdrop: {
      position: "absolute",
      top: 0,
      left: "50%",
      marginLeft: -96,
      display: "flex",
      background: "rgba(255, 255, 255, 0.75)",
      width: 192,
      height: 192,
      alignItems: "center",
      justifyContent: "center",
    },
    qrBlur: {
      filter: "blur(4px)",
    },
    refreshLink: {
      width: 100,
      height: 100,
      borderRadius: "50%",
      background: colors["--primary-midnight"],
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    refreshLinkIcon: {
      margin: "0 auto",
      width: 32,
    },
    refreshLinkSVG: {
      width: "100%",
      display: "block",
      height: "100%",
    },
    refreshLinkText: {
      color: colors["--white"],
      margin: 0,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "16px",
    },
    qrPlaceholder: {
      width: 192,
      height: 192,
      padding: 6,
    },
    qrPlaceholderInner: {
      width: "100%",
      height: "100%",
    },
    signInText: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: "32px",
      color: colors["--primary-midnight"],
      marginBottom: theme.spacing(1),
    },
    scanText: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      color: colors["--primary-midnight"],
      marginBottom: theme.spacing(1),
    },
    bottomText: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      color: colors["--ui-gray"],
      marginBottom: 0,
    },
    qrcodeWrapper: {
      width: "40%",
      padding: "0 40px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      "& p": {
        textAlign: "center",
        fontFamily: "Source Sans Pro",
      },
    },
    iconSpinnerWrapper: {
      margin: "auto",
    },
  }));
  const classes = bodyWrapper();
  const [token, setToken] = useState("");
  const [isTimeoutInProgress, setIsTimeoutInProgress] = useState(false);
  const socket = useRef<Socket>();
  const { optimizely } = useContext(OptimizelyContext);

  function forceDisconnect() {
    if (socket.current?.connected) {
      socket.current?.emit("forceDisconnect", {
        token: localStorage.getItem("qrToken"),
      });
      socket.current?.close();
      removeToken();
    }
  }
  function webSocketConnection() {
    setToken("");
    socket.current = connectSocket();
    socket.current.on("connect_error", () => {
      props.setShouldHideQR(true);
      sendAmplitudeEvent(
        SOCKET_CONNECT_ERROR,
        {
          Method: "QR",
        },
        null
      );
    });
    socket.current.on("connection", function (msg: MessageWithToken) {
      setToken(msg.token);
      saveToken(msg.token);
      props.setShouldHideQR(false);
      setIsTimeoutInProgress(true);
      window.setTimeout(() => {
        forceDisconnect();
        setIsTimeoutInProgress(false);
      }, REFRESH_TIME);
    });

    socket.current.on("authSuccess", (msg: TokenWithUserInfo) => {
      removeToken();
      socket.current?.close();
      props.setIsAuthSuccess(true);
      const userInfo = msg.userInfo;
      const authResponse: AuthResponse = {
        token: msg.token,
        userId: userInfo.userId,
        email: userInfo.email,
        tokens: {
          accessToken: userInfo.accessToken,
          refreshToken: userInfo.refreshToken,
        },
        status: 200,
        error: { code: "", message: "" },
      };
      finishLogin({
        authResponse,
        stayLoggedIn: true,
        toolsLocation: getToolsAndLocation(),
        location: props.location,
        history: props.history,
        loginMethod: "QR",
        qrToken: msg.token,
        optimizely,
      });
    });
  }
  const onFocus = () => {
    if (!isTimeoutInProgress) {
      webSocketConnection();
    }
  };

  const onVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      onFocus();
    }
  };

  useEffect(() => {
    webSocketConnection();
    window.addEventListener("beforeunload", forceDisconnect);
    return () => {
      window.removeEventListener("beforeunload", forceDisconnect);
      forceDisconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  });
  return (
    <div className={clsx(classes.qrcodeWrapper)}>
      <div className={clsx(classes.qrCodeImageWrapper)}>
        {token ? (
          <>
            <div className={clsx(!isTimeoutInProgress ? classes.qrBlur : "")}>
              <QRCode
                value={`${config.environment.baseEpocratesUrl}/contextuallink/qrLogin?token=${token}`}
                fgColor={colors["--primary-midnight"]}
                logoImage={logo}
                size={176}
                quietZone={8}
                logoWidth={50}
                logoHeight={50}
              />
            </div>
            {!isTimeoutInProgress ? (
              <div className={clsx(classes.qrRefreshBackdrop)}>
                <a
                  onClick={() => webSocketConnection()}
                  className={clsx(classes.refreshLink)}
                >
                  <div>
                    <p className={clsx(classes.refreshLinkIcon)}>
                      <RefreshIcon
                        classes={{ root: classes.refreshLinkSVG }}
                        htmlColor={colors["--white"]}
                      />
                    </p>
                    <p className={clsx(classes.refreshLinkText)}>
                      Refresh QR code
                    </p>
                  </div>
                </a>
              </div>
            ) : null}
          </>
        ) : (
          <div className={clsx(classes.qrPlaceholder)}>
            <div className={clsx(classes.qrPlaceholderInner)}>
              <Skeleton variant="rect" width={176} height={176} />
            </div>
          </div>
        )}
      </div>

      <p className={clsx(classes.signInText)}>Sign in with QR Code</p>
      <p className={clsx(classes.scanText)}>
        Scan this with your phone’s camera to log into epocrates Web instantly.
      </p>
      <p className={clsx(classes.bottomText)}>Requires the epocrates app</p>
    </div>
  );
};
