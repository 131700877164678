import { ThemeProvider } from "@material-ui/core";
import { licenseTheme } from "src/styles/licenseTheme";
import { RouteComponentProps } from "react-router-dom";
import { RedeemLicense } from "src/components/RedeemLicense";

export const RedeemLicenseHome = (props: RouteComponentProps) => {
  return (
    <ThemeProvider theme={licenseTheme}>
      <RedeemLicense history={props.history} />
    </ThemeProvider>
  );
};
