import * as React from "react";
import {
  Dialog,
  Grid,
  Button as MaterialButton,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { colors } from "../config/colorConfig";
import { strings } from "../utils/Strings";
import { withRoot } from "../withRoot";
import { Button } from "./custom/Button";

export interface PromptDialogInfo {
  open: boolean;
  nextLocation: string;
}

interface ContinueWithoutSaveDialogProps {
  info: PromptDialogInfo;
  isUpdateProgress: boolean;
  setClose: () => void;
  handleContinueWithoutSave: () => void;
  handleContinueWithSave: (e: React.MouseEvent<HTMLFormElement>) => void;
}

const ContinueWithoutSaveDialog = (props: ContinueWithoutSaveDialogProps) => {
  const promptDialogStyle = makeStyles((theme) => ({
    wrapper: {
      display: "flex",
      maxWidth: 407,
      padding: `${theme.spacing(6)}px ${theme.spacing(6)}px ${theme.spacing(
        6
      )}px`,
    },
    dialogTitle: {
      fontSize: 16,
      fontWeight: 500,
      color: colors["--prompt-dialog-title"],
      paddingBottom: theme.spacing(6),
    },
    dialogContent: {
      fontSize: 16,
      color: colors["--ui-slate"],
      lineHeight: 1.25,
      marginBottom: theme.spacing(6),
    },
    closeIcon: {
      fontSize: 20,
      position: "absolute",
      opacity: 0.75,
      top: 2,
      left: 2,
      "&:hover": {
        opacity: 1,
      },
    },
    continueWithoutSave: {
      height: 40,
      fontSize: 16,
      fontWeight: 500,
      marginLeft: theme.spacing(3),
      textTransform: "none",
      borderRadius: 3,
      color: colors["--primary-midnight"],
      "&:disabled": {
        backgroundColor: `${colors["--ui-grey-semi"]}`,
        color: `${colors["--ui-grey-med"]}`,
      },
      [theme.breakpoints.down("xs")]: {
        margin: `${theme.spacing(3)}px ${theme.spacing(0)}px ${theme.spacing(
          0
        )}px`,
      },
    },
    wrapperButton: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    paperScroll: {
      maxWidth: "100%",
      width: 407,
    },
  }));
  const classes = promptDialogStyle(props);
  return (
    <Dialog
      classes={{
        paperScrollPaper: clsx(classes.paperScroll),
      }}
      open={props.info.open}
    >
      <div className={clsx(classes.wrapper)} data-testid="promptDialogWrapper">
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <Typography
              className={clsx(classes.dialogTitle)}
              data-testid="title"
            >
              {strings.change_not_saved}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              disabled={props.isUpdateProgress}
              onClick={props.setClose}
            >
              <CloseIcon
                className={clsx(classes.closeIcon)}
                data-testid="promptCloseIcon"
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={clsx(classes.dialogContent)}
              data-testid="body"
            >
              {strings.save_or_cancel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form
              onSubmit={(e: React.MouseEvent<HTMLFormElement>) =>
                props.handleContinueWithSave(e)
              }
            >
              <div className={clsx(classes.wrapperButton)}>
                <Button
                  name="Save and Continue"
                  id="continueWithSave"
                  type="submit"
                  width="160px"
                  fontFamily="Metric"
                  data-testid="continueWithSave"
                  disabled={!props.info.open}
                  inProgress={props.isUpdateProgress}
                />
                <MaterialButton
                  className={clsx(classes.continueWithoutSave)}
                  onClick={props.handleContinueWithoutSave}
                  data-testid="continueWithoutSave"
                  disabled={props.isUpdateProgress}
                >
                  Continue Without Saving
                </MaterialButton>
              </div>
            </form>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export const ContinueWithoutSaveDialogWithRoot = withRoot(
  ContinueWithoutSaveDialog
);
