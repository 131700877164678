import React from "react";
import withWidth from "@material-ui/core/withWidth";
import { withRoot } from "../withRoot";
import { clearCookies } from "../utils/cookies";

// Eliminate all traces of user's authentication cookies and redirect to /
const LogoutForm = (props: any) => {
  React.useEffect(() => {
    clearCookies();
    props.history.push("/");
  }, []);

  // Blank, nothing to show
  return <></>;
};

export const LogoutFormWith = withWidth()(withRoot(LogoutForm));
