import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { SubscriptionQueryResponse } from "../models/SubscriptionResponse";
import { getUserSubscriptionDetails } from "../services/subscriptionService";

export const GetUserSubscriptions = (
  request: string
): UseQueryResult<SubscriptionQueryResponse> =>
  useQuery<SubscriptionQueryResponse>(
    ["userIdQuery", { request }],
    () => getUserSubscriptionDetails(request),
    {
      enabled: request !== "",
      retry: 4,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
