import { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  accordionSummaryClasses,
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { colors } from "src/config/colorConfig";
import SvgIcon from "@mui/material/SvgIcon";
import { Box, Typography } from "@mui/material";
import { LoadingEpocLogo } from "./custom/LoadingEpocLogo";
import { formatToDollar } from "src/utils/payment";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  "&:focus": {
    outline: "none",
    border: "none",
    boxShadow: "none",
  },
  background: colors["--ui-grey-ultra-light"],
  [`& .${accordionSummaryClasses.content}`]: {
    margin: 0,
    height: "30px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  background: colors["--ui-grey-ultra-light"],
  padding: "0 16px 16px 16px",
}));

const PriceTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Source Sans Pro",
  fontSize: "20px",
  lineHeight: "24px",
  fontWeight: 700,
  color: colors["--primary-midnight"],
  marginLeft: "auto",
}));

const ShowHideTypography = styled(Typography)({
  fontSize: "18px",
  fontFamily: "Source Sans Pro",
  lineHeight: "24px",
  color: colors["--primary-tealnight"],
  fontWeight: 600,
});

interface CollapsibleOrderSummaryProps {
  children?: JSX.Element;
  isLoading: boolean;
  orderTotal: number | undefined;
}

export const CollapsibleOrderSummary: React.FC<CollapsibleOrderSummaryProps> = (
  props: CollapsibleOrderSummaryProps
) => {
  const { children, isLoading, orderTotal } = props;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {isLoading && (
        <Box
          sx={{ display: "flex", alignContent: "center", marginBottom: "16px" }}
        >
          <LoadingEpocLogo />
        </Box>
      )}
      {orderTotal && orderTotal !== undefined && children && !isLoading && (
        <Accordion expanded={expanded} onChange={handleExpandClick}>
          <AccordionSummary aria-controls="order-summary-content">
            <Box sx={{ display: "flex" }}>
              <ShowHideTypography>
                {expanded ? "Hide order summary" : "Show order summary"}
              </ShowHideTypography>
              <SvgIcon sx={{ transform: expanded ? "rotate(180deg)" : "none" }}>
                <path id="Vector" d="M7 10L12 15L17 10H7Z" fill="#0178A4" />
              </SvgIcon>
            </Box>
            <PriceTypography>{formatToDollar(orderTotal)}</PriceTypography>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
